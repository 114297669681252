import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../../service/token.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  currentUrl: string | undefined;
  public userrole: any;
  public usertype: any;

  constructor(private tokenStorage: TokenService, private route: Router) { }

  ngOnInit():any {
    // return false
    // this.userrole = sessionStorage.user_Role;
    // console.log(this.userrole, "UserRole");
    this.usertype = this.tokenStorage._get_userRole();
    // console.log(this.usertype, "UserRole");
    this.currentUrl = this.route.url;
    $(document).ready(function () {

      $(".sidebar-dropdown > a").click(function () {
        $(".sidebar-submenu").slideUp(200);
        if (
          $(this)
            .parent()
            .hasClass("active")
        ) {
          $(".sidebar-dropdown").removeClass("active");
          $(this)
            .parent()
            .removeClass("active");
        } else {
          $(".sidebar-dropdown").removeClass("active");
          $(this)
            .next(".sidebar-submenu")
            .slideDown(200);
          $(this)
            .parent()
            .addClass("active");
        }
      });

      // $("#close-sidebar").click(function () {
      //   $(".page-wrapper").removeClass("toggled");
      // });
      // $("#show-sidebar").click(function () {
      //   $(".page-wrapper").addClass("toggled");
      // });

      $('#menuburger').click(function () {
        $("body").toggleClass("toggled-active");
      });
      $('#close-sidebar').click(function () {
        $("body").removeClass("toggled-active");
      });

    });
  }

}
