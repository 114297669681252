<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Clients"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Signed loa</h2>
          <button class="btn_to_btitle"><a routerLink="/superadmin/clients">back</a></button>
        </div>
      </div>
      <div class="pagecontent_wrapper">
        <div class="apx_clientloa">
          <a routerLink="/superadmin/client-file-status">
            <div class="apx_client-lister-details apxclients_active" *ngFor="let items of clientList">
              <div class="apx_client-file-contents">
                <div class="apx_client-content-grid d-flex flex-filescontent">
                  <img src="../../../assets/images/clients/files.png" alt="icon" />
                  <div class="apxfile_index_names">
                    <p>{{ items.clientFileName }}</p>
                    <span>{{ items.clientFileNumber }}</span>
                  </div>
                </div>
                <div class="apx_client-content-grid"><label>Assigned Manager</label>
                  <span>{{ items.assiManager }}</span>
                </div>
                <div class="apx_client-content-grid"><label>Negotiator</label>
                  <span class="negoapx_title">{{ items.neogtiator }}</span>
                </div>
                <div class="apx_client-content-grid"><label>Assit. Negotiator</label>
                  <span>{{ items.assNeogtiator }}</span>
                </div>
              </div>
              <div class="apx_client-file-status">
                <div class="apx_clients-file-grid">
                  <label>File Status</label>
                  <span class="fileapxstatus">{{ items.clientFileStatus }}</span>
                </div>
                <div class="apx_clients-file-grid">
                  <label>Assigned Date</label>
                  <span>{{ items.assignDate }}</span>
                </div>

              </div>
            </div>
          </a>
        </div>


        <form [formGroup]="loaFormGroup" (ngSubmit)="add_signed_loa()">
          <div class="apex-white_wrap">
            <div class="apex-title-lilblue">
              <h3>Assign Negotiator / Assit. Negotiator / CLOSURE</h3>
            </div>
            <div class="apexforms-wraps">
              <div class="apx_assign-details">
              <h2>Previously Assigned Detail</h2>
              <div class="assit_details_content">
                <label >UserType</label>
                <span>{{previouslyAssignedLead.userType }}</span>
              </div>
              <div class="assit_details_content">
                <label>UserName</label>
                <span>{{previouslyAssignedLead.userfullname }}</span>
              </div>
              <div class="assit_details_content">
                <label>ForeClosureDate</label>
                <span>{{currentclientdata.forclosureDate}}</span>
              </div>
            </div>
              <div class="apexforms-group" [formGroup]="formGroup"
                *ngFor="let formGroup of loaFormGroup.controls.clientAssingDetail.controls;let i = index;">
                <div class="row">
                
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms apexinput_froms-horz">
                      <label>Assign Negotiator *</label>
                      <!-- <select formControlName="userId" (change)="add_select(5)">
                        <option *ngFor="let negotiator of clientAssignNego" [value]=negotiator.userId>
                          {{negotiator.userName}}</option>
                      </select> -->
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="userId"  (selectionChange)="add_select(5)">
                          <!-- <mat-option>--Select--</mat-option> -->
                          <input type="text" (keyup)="onSearchNego($event)"  (keydown)="$event.stopPropagation()">
                          
                          <mat-option *ngFor="let item of clientAssignNego" [value]=item.userId  >
                            {{item.userName}}
                          </mat-option>
                        </mat-select>
                        
                      </mat-form-field>
           
                       <p style="color:red" *ngIf="submitted && !formvalid">Assign Negotiator Required</p>
                    </div>
                    <!-- <div class="invalid-feedback" *ngIf="formvalid==false && submitted==true">Assign Negotiator Required</div> -->
                    <!-- <div *ngIf="loaFormGroup.submitted" class="alert alert-danger">
                           <span>gfjg</span>>
                      </div> -->
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms apexinput_froms-horz">
                      <label>Assign Manager
                      </label>
                      <!-- <select formControlName="userId" (change)="add_select(6)" required>
                        <option *ngFor="let asstnegotiator of clientAssitNego" [value]=asstnegotiator.userId>
                          {{asstnegotiator.userName}}</option>
                      </select> -->
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="userId" (selectionChange)="add_select(2)">
                          <!-- <mat-option>--Select--</mat-option> -->
                          <input type="text" (keyup)="onSearchManager($event)" (keydown)="$event.stopPropagation()">
                          <mat-option *ngFor="let item of clientAssignManager" [value]=item.userId>
                            {{item.userName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- <div style="color:red" *ngIf="submitted && !formvalid">Assign NegotiatorAss Required</div> -->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms apexinput_froms-horz">
                      <label>Assign Broker</label>
                      <!-- <select formControlName="userId" (change)="add_select(6)" required>
                        <option *ngFor="let asstnegotiator of clientAssitNego" [value]=asstnegotiator.userId>
                          {{asstnegotiator.userName}}</option>
                      </select> -->
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="userId" (selectionChange)="add_select(3)">
                          <!-- <mat-option>--Select--</mat-option> -->
                          <input type="text" (keyup)="onSearchBroker($event)" (keydown)="$event.stopPropagation()">
                          <mat-option *ngFor="let item of clientAssignBroker" [value]=item.userId>
                            {{item.userName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- <div style="color:red" *ngIf="submitted && !formvalid">Assign NegotiatorAss Required</div> -->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms apexinput_froms-horz">
                      <label>Assign Agent</label>
                      <!-- <select formControlName="userId" (change)="add_select(6)" required>
                        <option *ngFor="let asstnegotiator of clientAssitNego" [value]=asstnegotiator.userId>
                          {{asstnegotiator.userName}}</option>
                      </select> -->
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="userId" (selectionChange)="add_select(4)">
                          <!-- <mat-option>--Select--</mat-option> -->
                          <input type="text" (keyup)="onSearchAgent($event)" (keydown)="$event.stopPropagation()">
                          <mat-option *ngFor="let item of clientAssignAgent" [value]=item.userId>
                            {{item.userName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- <div style="color:red" *ngIf="submitted && !formvalid">Assign NegotiatorAss Required</div> -->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms apexinput_froms-horz">
                      <label>Assign Assit. Negotiator</label>
                      <!-- <select formControlName="userId" (change)="add_select(6)" required>
                        <option *ngFor="let asstnegotiator of clientAssitNego" [value]=asstnegotiator.userId>
                          {{asstnegotiator.userName}}</option>
                      </select> -->
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="userId" (selectionChange)="add_select(6)">
                          <!-- <mat-option>--Select--</mat-option> -->
                          <input type="text" (keyup)="onSearchAssNego($event)" (keydown)="$event.stopPropagation()">
                          <mat-option *ngFor="let item of clientAssitNego" [value]=item.userId>
                            {{item.userName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- <div style="color:red" *ngIf="submitted && !formvalid">Assign NegotiatorAss Required</div> -->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms apexinput_froms-horz">
                      <label>Assign Closer</label>
                      <!-- <select formControlName="userId" (change)="add_select(7)">
                        <option *ngFor="let closure of clientAssignCloser" [value]=closure.userId> {{closure.userName}}
                        </option>
                      </select> -->
                      <mat-form-field appearance="fill">
                        <mat-select formControlName="userId" (selectionChange)="add_select(7)">
                          <!-- <mat-option>--Select--</mat-option> -->
                          <input type="text" (keyup)="onSearchAssCloser($event)" (keydown)="$event.stopPropagation()">
                          <mat-option *ngFor="let item of clientAssignCloser" [value]=item.userId>
                            {{item.userName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <!-- <div style="color:red" *ngIf="submitted && !formvalid">Assign NegotiatorAss Required</div> -->
                    </div>
                  </div>
         

                </div>
              </div>



              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms apexinput_froms-horz">
                      <label>Foreclosure Date</label>
                      <!-- <input type="text" formControlName="forClosureDate" placeholder="First Name" value="Denzel"
                        name="forClosureDate" /> -->
                      <dp-date-picker theme="dp-material" placeholder="Select Date" formControlName="forClosureDate" [config]="datePickerConfig"
                        required="true">
                      </dp-date-picker>
                      <!-- <div style="color:red" *ngIf="submitted && !formvalid">Date Required</div> -->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <div class="apexinput-froms apexinput_froms-horz">
                      <label>Notes</label>
                      <textarea name="notes" formControlName="notes"
                        class="width-100">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</textarea>
                      <!-- <div style="color:red" *ngIf="submitted && !formvalid">Notes Required</div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="apxbtn_finalcenter">
                <!-- <button class="btn_grey" routerLink="/superadmin/clients">Cancel</button> -->
                <!-- <a routerLink="/superadmin/clients"><button class="btn_dblue"></a> -->
                <button class="btn_dblue" type="submit">ASSIGN</button>
              </div>
            </div>
          </div>

        </form>


      </div>
    </div>
  </main>

</div>
