import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
@Component({
  selector: 'app-negotiators-edit',
  templateUrl: './negotiators-edit.component.html',
  styleUrls: ['./negotiators-edit.component.css']
})
export class NegotiatorsEditComponent implements OnInit {
  addNegotiator: any = {};
  getNegotiator: any = {};
  id: any = {};
  updateNegotiator: Boolean = false;
  message: any;
  constructor(private toast: ToastService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }
  titleDetail = ['Title', 'Title-1']
  statusActive = ['Active', 'InActive'];

  ngOnInit(): void {
    this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // let id = this._manager_service.getId();
    if (this.id !== null || undefined) {
      this.updateNegotiator = true;
      this._getNegotiator_byId(this.id)
    }
  }

  _create_Negotiator() {
    if (this.updateNegotiator) {
      ;
      console.log(this.addNegotiator)
      let boolValue = (/true/i).test(this.addNegotiator.active);
      this.addNegotiator['active'] = boolValue;
      this.addNegotiator.createdBy = "SuperAdmin";
      this._manager_service._update_Negotiator(this.addNegotiator).subscribe((data: any) => {
        console.log("update", data)
        if (data.reponse == true && data.message == "Invaild Domain Mail") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id")
        } else {
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/negotiators-list'])
        }

      })
    }
    else {
      let boolValue = (/true/i).test(this.addNegotiator.active);
      this.addNegotiator['active'] = boolValue;
      this.addNegotiator.createdBy = "SuperAdmin";
      this._manager_service._add_Negotiator(this.addNegotiator).subscribe((data: any) => {
        console.log("add", data)
        if (data.reponse == true && data.message == "Invaild Domain Mail") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id")
        } else {
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/negotiators-list'])
        }
      })
    }
  }

  _getNegotiator_byId(id: number) {
    this._manager_service._get_asstNegotiatorByid(id).subscribe((data: any) => {
      console.log(data)
      this.addNegotiator = data['getData'];
    })


  }




}
