import { email, unique, alpha, compare, prop, propArray, propObject, required } from "@rxweb/reactive-form-validators"




export class docFolder {
  @prop()
  folderName?: string;
}


export class docFiles {
  @prop()
  documentName?: string;
  @prop()
  docmentUrl?: string;
  @prop()
  deletedReason?: string;
}


export class autoDocModel {
  @propObject(docFolder)
  autogenerateddocfolder?: docFolder;

  @propArray(docFiles)
  autogenerateddocfiles?: docFiles[];


  @prop()
  titleName?: 'sample';

}


// "autogenerateddocfolder": {


// }


// "autogenerateddocfiles": {

//   "documentName": "1.xlxs",
//     "docmentUrl": "C:/Users/CIPL0058/Desktop/1.xlxs",
//       "deletedReason": ""
// }

