import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import { DatePipe } from '@angular/common';

import * as moment from 'moment';
declare var jQuery: any;

@Component({
  selector: 'app-task-complete-list',
  templateUrl: './task-complete-list.component.html',
  styleUrls: ['./task-complete-list.component.css']
})
export class TaskCompleteListComponent implements OnInit {
  public style: any;
  public clienttabData: any = [];
  public clienttabDataBack: any = [];
  public templatetabData: any = [];
  public templatetabDataBack: any = [];
  addtasktemplatefrm: any = FormGroup;
  public clientTask: any = [];
  public DbclientTask: any = [];
  public clientTaskBack: any = [];
  public assignedClientData: any = [];
  public assignedClientDataBack: any = [];
  public clientDataId: any = 0;
  public typeId: any = 0;
  public closerId: any = 0;
  public assignuserData: any = [];
  public assignuserDataBack: any = [];
  public filterData: any;
  myuserType: any;
  userTypeId: any;
  public filestabData: any = [];
  public filestabDataBack: any = [];
  usertype: any
  usertypeId: any;
  getLeadTaskList: any = [];
  getLeadTaskListAll: any = [];
  userInfo: any
  userInfoList: any
  userTypeIds: any
  currentLoggedID: any
  taskStatusChangeList: any;
  userloggedIn: any
  taskEditform: any = {};
  currentid: any;

  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    min: moment(new Date())
  };
  updateTaskstatus: any;
  taskclientTaskId: any;
  taskclienleadId: any;
  taskEditformAssignedDate: any
  taskEditformTaskStatuse: any
  modelTaskpopUP: boolean | false;
  updateAssignedDate: any;
  //YYYY-MM-DD
  max = moment(new Date()).format('MM/DD/YYYY');
  //task template modal body
  taskDetailList: any = [];
  //task template modal body

  //task filter
  // public filestatusData: any = [];
  //public negotiatorData: any = [];
  // public closerData: any = [];
  submitted = false;
  order: string = 'managersFirstname';
  p: number = 1;
  reverse: boolean = false;
  filterObj: any = {};
  tasklistCompletedcode: any
  constructor(private datePipe: DatePipe, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) {

  }
  tabs = ['file status', 'tasks', 'client & property', 'Documents', 'contacts', 'Closing Statements', 'Short Sales', 'SS Proposal', 'Hardship']
  tab: any;
  moment: any = moment;

  selectedTab(e: any) {
    this.tab = e
  }
  ngOnInit(): void {
    this.tasklistCompletedcode = 'overall-task';
    this.getAssignedUserTypeByClient();
    //task template modal body
    this.userTypeId = this.tokenStorage._get_userTypeId();
    this.currentid = sessionStorage.getItem('userTypeId');

    this.getAllClientTask();

    let userstate = window.history.state;
    console.log(userstate['obj'], "currentdata");
    let userid = userstate['obj'];
    this.myuserType = this.tokenStorage._get_userRole();

    this.userTypeId = this.tokenStorage._get_userTypeId();
    this.usertypeId = this.tokenStorage._get_userTypeId();
    this.userInfo = this.tokenStorage._get_userInfo();

    this.userInfoList = JSON.parse(this.userInfo);
    this.userTypeIds = this.userInfoList.UserTypeId;
    this.currentLoggedID = this.userInfoList.CurrentLoggedUserId;
    console.log(this.currentLoggedID, 'log');
    // this.getGetfileStatus();
    // this.getGetNegotiatorName();
    // this.getGetCloserName();
    this.getclientFileList();
    this.getclienttabList();
    this.getTemplatetabList(this.userTypeId);


    this.addtasktemplatefrm = this.formBuilder.group({
      clientId: ['', Validators.required],
      templateId: ['', Validators.required],
      CompletionDate: ['', Validators.required],
      createdBy: sessionStorage.getItem('userTypeId')
    });
    this._getallLeadTask();
    this._getClientAndLeadTask();

  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  getAllClientTask() {
    this.usertype = this.tokenStorage._get_userRole();
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "hi cuser ")
    // this._manager_service.get_allClientTask(CurrentUserId).subscribe((res: any) => {
    this._manager_service.get_taskList(CurrentUserId).subscribe((res: any) => {
      console.log(res, "taskList-Template")
      //this.leadData = res.listData;
      if (res.listData == null) {
        this.clientTask = [];
        this.DbclientTask = []
        this.clientTaskBack = [];
      }
      else {
        this.clientTask = res.listData.filter((item: any) => item.taskStatus == 'Completed');;
        this.clientTaskBack = this.clientTask;
        this.DbclientTask = this.clientTask;
      }
      console.log("this.clientTask ", this.clientTask);
    })
  }

  addtasktemplateformSubmit() {

    this.submitted = true;
    this.addtasktemplatefrm.value.clientId = parseInt(this.addtasktemplatefrm.value.clientId);
    this.addtasktemplatefrm.value.templateId = parseInt(this.addtasktemplatefrm.value.templateId);
    let fullList: any = [];
    console.log(this.taskDetailList);
    var date = new Date();
    this.taskDetailList.forEach((element: any, i: any) => {
      fullList.push({
        "clientId": this.clientDataId,
        "day": element.day,
        "description": element.description,
        "tabId": parseInt(element.assignedTabId),
        "priority": element.priority,
        "assignedUserId": element.assingnuserid,
        "assignedUserTypeId": element.assignUserTypeid,
        "completionDate": moment(new Date(date.setDate(date.getDate() + parseInt(element.day)))).format('MM/DD/YYYY'),
        "createdBy": sessionStorage.getItem('userTypeId')
      })
    });

    console.log('fullList', fullList);

    // return false;
    this._manager_service.add_TaskFromTaskTemplate(fullList).subscribe((addtasktemp: any) => {
      console.log(addtasktemp, "add task template");
      if (addtasktemp.reponse == true) {
        this.toastr.showSuccess('Task Template Created');
        this.route.navigate(['/superadmin/task-list']);
        this.addtasktemplatefrm.reset();
        this.taskDetailList = [];
        this.getAllClientTask();
        jQuery('#filterbyss').modal('hide');
      } else {
        this.toastr.showError('ERROR OCCURED');
      }
    });
  }

  getclienttabList() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "hi cuser ")
    this._manager_service.get_Clientfileno(CurrentUserId).subscribe((res: any) => {
      this.clienttabData = res.listData;
      this.clienttabDataBack = res.listData;
    })
  }

  onselectClient(assignClientVal: any) {
    this.getAssignedUserTypeByClient();
    console.log(assignClientVal, "assignedClientId");
    this.clientDataId = parseInt(assignClientVal);
    console.log(this.clientDataId, "Hello1xxxxx");
    this.getAssignedUser(null);
  }


  getAssignedUserTypeByClient() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_AssignedUserTypeByClient(CurrentUserId).subscribe((res: any) => {
      this.assignedClientData = res.listData;
      this.assignedClientDataBack = res.listData;
    })
  }

  getAssignedUser(id: any) {

    let userObject = {
      "clientid": this.clientDataId,
      "userTypeID": parseInt(id) ? parseInt(id) : this.typeId,
      "CurrentUserId": parseInt(this.currentid)
    }
    this._manager_service.get_AssignedUser(userObject).subscribe((res: any) => {
      this.assignuserData = res.listData;
      this.assignuserDataBack = res.listData;

    }, err => {
      console.log("err getAssignedUser", err);
      this.assignuserData = [];
      this.assignuserDataBack = [];
    })
  }


  onselectAssign(assignedVal: any) {
    console.log(assignedVal, "assigned");
    this.typeId = parseInt(assignedVal);
    this.getAssignedUser(this.typeId);
    console.log(this.typeId, "Hello2");

  }

  getAssignedUserss(id: any, i: number) {
    let userObject = {
      "clientid": this.clientDataId,
      "userTypeID": parseInt(id),
      "CurrentUserId": parseInt(this.currentid)
    }
    this._manager_service.get_AssignedUser(userObject).subscribe((res: any) => {
      this.assignuserData[i] = res.listData;
      this.assignuserDataBack[i] = res.listData;
    })
  }

  onselectAssignss(assignedVal: any, i: number) {
    this.getAssignedUserss(assignedVal.target.value, i);
    console.log(assignedVal, "assigned");
    this.typeId = parseInt(assignedVal.target.value)
    console.log(this.typeId, "Hello2");
    this.taskDetailList[i]['assignUserTypeid'] = parseInt(assignedVal.target.value);

  }

  onselectAssignuser(assignedVals: any, i: number) {
    //this.getAssignedUserss(assignedVals.target.value, i);
    console.log(assignedVals, "assigned");
    this.taskDetailList[i]['assingnuserid'] = parseInt(assignedVals.target.value);
  }

  getTemplatetabList(data: number) {
    this._manager_service.get_tasktemplates(data).subscribe((res: any) => {
      this.templatetabData = res.listData;
      this.templatetabDataBack = res.listData;
    })
  }

  onAssign(assignlastVal: any) {
    this.closerId = parseInt(assignlastVal)
    console.log(this.closerId, "Hello3");
  }

  onreset() {
    var self = this;
    self.clientTask = self.clientTaskBack;
  }

  viewmore(nextIndex: any) {

    this.route.navigateByUrl('/superadmin/task-list-view', { state: { obj: nextIndex } })
  }
  completionDateChange(e: any) {
    console.log(e, 'dataa')
    console.log(this.getLeadTaskList.assignedDate, 'aa')
    this.updateAssignedDate = this.getLeadTaskList.assignedDate;
  }
  changeTaskstatus(e: any) {
    console.log(e.target.value, 'taskstaus')
    this.updateTaskstatus = e.target.value;
  }


  taskEdit(data: any) {
    this.taskEditform = data;
    this.taskEditformAssignedDate = data.completionDate;
    this.taskEditformTaskStatuse = data.taskStatus;
    this.taskclientTaskId = data.clientTaskId;
    this.taskclienleadId = data.leadId;

    console.log(this.taskEditformAssignedDate, 'data')
    console.log(data, 'datafullresp')
    console.log(this.taskEditformTaskStatuse, 'status')

    console.log(this.taskEditform, 'tasl')
    this.modelTaskpopUP = true;
  }
  submitDate(e: any) {

    this.updateAssignedDate = e;
    console.log(e, 'dataLister')
    //console.log(this.taskEditformAssignedDate, 'dataLister')
  }
  modelTaskSubmit() {
    this.taskStatusChangeList = {
      'clientTaskId': this.taskclientTaskId,
      'leadId': this.taskclienleadId,
      'taskStatus': this.taskEditformTaskStatuse,
      'completionDate': this.updateAssignedDate,
      // 'completionDate': this.taskEditformAssignedDate,
      'createdBy': this.currentLoggedID
    }
    this._manager_service.updateLeadTask(this.taskStatusChangeList).subscribe((resp: any) => {
      console.log(resp, 'listTaskLead')
      if (resp.reponse == true) {
        this._getallLeadTask();
        this.modelTaskpopUP = false;
        this.toastr.showSuccess(resp.message);
      } else {
        this.toastr.showError(resp.message);
      }

    })
    console.log(this.taskStatusChangeList, 'FinalUpdate')
  }
  taskStatusChange(listItem: any) {
    console.log(listItem, 'listItem statusChanges')
  }

  _getallLeadTask() {
    //console.log(this.usertypeId,'ID96666')
    this._manager_service.getAllLeadTask(this.currentLoggedID).subscribe((resp: any) => {
      if (resp.reponse == true) {
        this.getLeadTaskList = resp.listData;
      }
    })
  }

  _getClientAndLeadTask() {
    //console.log(this.usertypeId,'ID96666')
    this._manager_service.getClientAndLeadTask(this.currentLoggedID).subscribe((resp: any) => {
      this.getLeadTaskListAll = resp.listData;
    })
  }



  getclientFileList() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this.userloggedIn = this.tokenStorage._get_userRole();
    console.log(CurrentUserId, "hi cuser ")
    this._manager_service.get_Clientfileno(CurrentUserId).subscribe((res: any) => {
      this.filestabData = res.listData;
      this.filestabDataBack = res.listData;
      //this.getAssignedUserTypeByClient(res.listData[0].Clientid);
      console.log(this.getLeadTaskList, 'listLeadTask')

    })
  }

  onSearchfileName(e: any) {
    this.clienttabData = this.clienttabDataBack.filter((item: any) => {
      return item.fileNo.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
    })
  }

  onSearchfileNo(e: any) {
    this.filestabData = this.filestabDataBack.filter((item: any) => item.fileNo.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearchTypeName(e: any) {
    this.assignedClientData = this.assignedClientDataBack.filter((item: any) => item.userTypeName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearchUserName(e: any) {
    this.assignuserData = this.assignuserDataBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearchTemplate(e: any) {
    this.templatetabData = this.templatetabDataBack.filter((item: any) => item.templateName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }


  //task template modal body




  editTaskTemplate(editData: any) {
    this._manager_service.get_tasktemplatedetail(editData).subscribe((res: any) => {
      this.taskDetailList = res.listData[0].taskchilddetails;
      console.log("hhhhhhhh", this.taskDetailList)
    })
  }


  OnTaskSelect(assignval: any) {
    console.log("taskselect", assignval);
    this.editTaskTemplate(assignval);
  }

  dateEvent(assigndate: any, i: any) {
    console.log(assigndate);
    console.log("assigndate", assigndate);
    this.taskDetailList[i]['date'] = assigndate;
  }
  //task template modal body

  getTaskCompletionStatus(data: any) {
    // console.log("data ==>getTaskCompletionStatus", data);
    return new Date().setHours(0, 0, 0, 0) > new Date(data.completionDate).setHours(0, 0, 0, 0) && data.taskStatus != 'Completed' ? true : false;
  }

  addDays(day: any): Date {
    var date = new Date();
    date.setDate(date.getDate() + parseInt(day));
    return date;
  }

  filterTaskList() {
    var self = this;
    !self.filterObj.assignedUserTypeId ? delete self.filterObj.assignedUserTypeId : '';
    !self.filterObj.clientID ? delete self.filterObj.clientID : '';
    !self.filterObj.assignedUserid ? delete self.filterObj.assignedUserid : '';
    self.clientTask = self.clientTaskBack.filter((x: any) => {
      for (let key in self.filterObj) {
        if (x[key] === undefined || x[key] != self.filterObj[key])
          return false;
      }
      return true;
    });
    console.log("self.clientTask ", self.clientTask);
  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.clientTask = self.DbclientTask.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }
  tasklistCompletedFun(tabName: any) {
    //alert('step1');
    if (tabName == '1') {
      this.tasklistCompletedcode = 'overall-task'
    } else if (tabName == '2') {
      this.tasklistCompletedcode = 'task_list'
    } else if (tabName == '3') {
      this.tasklistCompletedcode = 'task_client'
    }
  }

}
