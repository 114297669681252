import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from 'src/app/service/dropdown.service';
import { FileuploadService } from 'src/app/service/fileupload.service';
@Component({
  selector: 'app-negotiators-list',
  templateUrl: './negotiators-list.component.html',
  styleUrls: ['./negotiators-list.component.css']
})
export class NegotiatorsListComponent implements OnInit {

  order: string = 'managersFirstname';
  p: number = 1;
  reverse: boolean = false;
  negotiatorList: any = [];
  deleteData: any;
  userListOptions: any;
  statusListOptions: any;
  filterRequest: any = {};
  constructor(private managerService: ManagerService, private dropDownService: DropdownService, private fileupload: FileuploadService) {


  }

  ngOnInit(): void {
    this.getfilter_details();
    this._getNegotiator_records();
  }

  _getNegotiator_records() {
    this.managerService._get_All_Negotiator().subscribe((data: any) => {
      console.log(data)
      this.negotiatorList = data.listData;

    })
  }


  setId(data: any) {
    this.deleteData = data;
  }
  _delete_negotiator() {

    this.managerService._delete_Negotiator(this.deleteData).subscribe(deleteData => {
      console.log(deleteData)
      this._getNegotiator_records()
    })


  }

  getFilterData() {
    console.log(this.filterRequest)
    this.filterRequest.status = parseInt(this.filterRequest.status);
    this.dropDownService._getAll_negotiators_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.negotiatorList = filteredData.listData;
    });
  }

  //**Get Details for Filter */
  getfilter_details() {
    this.dropDownService._getAll_negotiators_UserList().subscribe((userList: any) => {
      console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
    })
    this.dropDownService._getAll_negotiators_StatusList().subscribe((statusList: any) => {
      console.log(statusList.listData, "sji")
      this.statusListOptions = statusList.listData;
    })
  }
  //**End */


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  download_excel() {
    this.fileupload.download_negotiator();
  }

}
