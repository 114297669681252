<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Lead"></app-header>

    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap" *ngIf="usertype != 'Agent' && usertype != 'Broker'">
          <h2>Add / Edit Lead</h2>
          <button class="btn_to_btitle"><a routerLink="/superadmin/lead-list">back</a></button>
        </div>
        <div class="apxpage-title_wrap" *ngIf="usertype == 'Broker'">
          <h2>Rejected Lead Detail</h2>
          <button class="btn_to_btitle"><a routerLink="/superadmin/lead-rejected-list">back</a></button>
        </div>
        <div class="apxpage-title_wrap" *ngIf="usertype == 'Agent'">
          <h2>Assigned Lead Detail</h2>
          <button class="btn_to_btitle"><a routerLink="/superadmin/dashboard">back</a></button>
        </div>
      </div>
      <div class="apex-white_wrap broker-apx" *ngIf="usertype == 'Broker'">

        <div class="apex-title-lilblue">
          <h3>Rejected Reason</h3>
        </div>
        <div class="apexforms-group lead-group ">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="apexinput-froms mb-0">
                <label>Note</label>
                <textarea class="bg_blur width-100"
                  placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form  #leadForm="ngForm" [formGroup]="nestedFormGroup" (ngSubmit)="nestedFormGroup.valid && addLead()" novalidate>
        <div class="pagecontent_wrapper">
          <div class="apex-white_wrap newheight-apx" [ngClass]="usertype == 'Agent' ? 'dash-edit-bg': ''">
            <div class="apex-title-lilblue">
              <h3>Lead details</h3>
            </div>
            <!-- cccccc -->
            <div [formGroup]="nestedFormGroup.controls.homeOwner">
              <div class="apexforms-group lead-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner First Name*</label>

                      <input type="text" placeholder="" formControlName="firstName" class="form-control">
                      <!-- <p>Form valid {{nestedFormGroup.valid}}</p>
                      <p>Form invalid {{nestedFormGroup.invalid}}</p>
                      <p>Form pending {{nestedFormGroup.pending}}</p>  -->
                      <p class="form-text text-danger"
                        *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.firstName.errors">
                        FirstName Required</p>
                      <!-- <div *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.firstName.invalid" class="invalid-feedback"> -->
                      <!-- <div  class="form-text text-danger"  *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.firstName.">First Name is required</div> -->
                      <!-- </div> -->

                    </div>



                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner Last Name *</label>
                      <input type="text" placeholder="" name="lastName" formControlName="lastName">
                      <div class="form-text text-danger"
                        *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.lastName.errors">
                        LastName Required</div>
                    </div>
                  </div>
                  <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner</label>
                      <input type="text" placeholder="">
                    </div>
                  </div> -->
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Property Address *</label>
                      <textarea class="hgt_reduce" name="address" [(ngModel)]="mailngaddress" placeholder=""
                        formControlName="address"></textarea>
                      <div class="form-text text-danger"
                        *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.address.errors">Address
                        Required</div>
                    </div>


                    <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-8">
                      <div class="apexinput-froms">
                        <label>list for sale address</label>
                        <select (input)="getpropertyid($event)">
                          <option value="">----Select Address---</option>

                          <option *ngFor="let addresslist of realtorPropertyAdrress" [value]=addresslist.price_raw>
                            {{addresslist.address}}</option>
                        </select>
                      </div>
                    </div> -->

                  </div>
                  <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>APN</label>
                      <input type="text"    name="apn" formControlName="apn">

                    </div>
                  </div> -->
                </div>
              </div>
              <div class="apexforms-group lead-group">
                <div class="row">


                  <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner country</label>
                      <select name="countryName" formControlName=countryId
                        (input)="select_dropdown($event,'state',true)">
                        <option value="">----Select County---</option>

                        <option *ngFor="let country of countrylist" [value]="country.countryId">
                          {{ country.countryName }}
                        </option>
                      </select>
                    </div>



                    <div class="apexinput-froms">
                      <label>Zip Code</label>
                      <input type="text" placeholder="" name="zipCode" formControlName="zipCode">
                    </div>
                  </div> -->




                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">


                    <div class="apexinput-froms">
                      <label>Property City *</label>
                      <!-- <select name="cityName" formControlName="cityId">
                        <option selected disabled>----Select City---</option>
                        <option *ngFor="let city of citylist" [value]="city.cityId">
                          {{ city.cityName }}
                        </option>
                      </select> -->
                      <input type="text" name="cityName" formControlName="cityName">
                      <div class="form-text text-danger"
                        *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.cityName.errors">City
                        Required</div>
                    </div>

                  </div>


                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Property State</label>
                      <select name="stateName" formControlName="stateName" required >
                        <option  disabled>----Select State---</option>
                        <!-- <option>tn</option> -->
                        <option *ngFor="let state of statelist" [value]="state.stateName">
                          {{ state.stateName }}
                        </option>
                      </select>
                      <div class="form-text text-danger"
                        *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.stateName.errors">State
                        Required</div>
                    </div>


                  </div>


                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Property Zipcode *</label>
                      <input type="text" name="zipcode" formControlName="zipCode">
                      <div class="form-text text-danger"
                        *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.zipCode.errors">zipcode
                        Required</div>
                    </div>
                  </div>

                </div>
                <!-- ccc -->
              </div>



            </div>


            <div class="apexforms-group lead-group">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Phone Number 1</label>
                    <input type="text" prefix="" mask="(000) 000-0000" value="" name="phoneNumber1" formControlName="phoneNumber1">
                    <!-- <div class="form-text text-danger"
                      *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.phone1.errors">phone
                      Required</div> -->
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Phone Number 2</label>
                    <input type="text" prefix="" mask="(000) 000-0000" value="" name="phoneNumber2" formControlName="phoneNumber2">
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Phone Number 3</label>
                    <input type="text" prefix="" mask="(000) 000-0000" value="" name="phoneNumber3" formControlName="phoneNumber3">
                  </div>
                </div>
              </div>
            </div>


            <div class="apexforms-group lead-group">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Phone Number 4</label>
                    <input type="text" prefix="" mask="(000) 000-0000" value="" name="phoneNumber4" formControlName="phoneNumber4">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Email 1 * </label>
                    <input type="text" placeholder="" value="" name="email1" formControlName="email1" required>
                  		<!-- <div class="form-text text-danger" *ngIf="nestedFormGroup.controls.email1.errors && nestedFormGroup.controls.email1.touched">enter correct email format</div> -->
                      <div class="form-text text-danger"
                      *ngIf="leadForm.submitted && nestedFormGroup.controls.email1.errors">Email 1 
                      Required</div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Email 2</label>
                    <input type="text" placeholder="" value="" name="email2" formControlName="email2">

                  		<div class="form-text text-danger" *ngIf="nestedFormGroup.controls.email2.errors">enter correct email format</div>

                  </div>
                </div>

              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                  <div class="row lg-l">
                    <div class="apexinput-froms col-sm-6">
                      <label>Baths</label>
                      <!-- <select name="noofBaths" formControlName="noofBaths">
                        <option>--Select--</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                      </select> -->
                      <input type="text" prefix="" thousandSeparator="," mask="separator" formControlName="noofBaths">
                    </div>
                    <div class="apexinput-froms col-sm-6">
                      <label>Lot Size</label>
                      <input type="text" prefix="" thousandSeparator="," mask="separator" formControlName="lotSize">
                    </div>
                  </div>

                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Estimated Value </label>
                    <input type="text" prefix="$" thousandSeparator="," mask="separator" placeholder="" value=""
                      name="estimatedValue" formControlName="estimatedValue">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Assessed Value</label>
                    <input type="text" prefix="$" thousandSeparator="," mask="separator" placeholder="" value=""
                      name="asseddedValue" formControlName="asseddedValue">
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">


                  <div class="apexinput-froms">
                    <label>Foreclosure Stage</label>
                    <select name="foreClosureStage" formControlName="foreClosureStage">
                      <option>--Select--</option>
                      <option> Pre fore closure</option>
                    </select>
                  </div>



                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Recording Date</label>
                    <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                    <dp-date-picker placeholder="" theme="dp-material" [config]="datePickerConfig" name="recordingDate"
                      formControlName="recordingDate">
                    </dp-date-picker>
                    <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.recordingDate.errors">RecordingDate Required</p> -->
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Sale Date</label>
                    <!-- <input type="text" placeholder="27-Aug-2020" value="" name="" /> -->
                    <dp-date-picker placeholder="" theme="dp-material" [config]="datePickerConfig" name="salesDate"
                      formControlName="salesDate">
                    </dp-date-picker>
                    <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.salesDate.errors">Sale Required</p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Default As Of</label>
                    <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                    <dp-date-picker placeholder="" theme="dp-material" [config]="datePickerConfig" name="defaultAsOf"
                      formControlName="defaultAsOf">
                    </dp-date-picker>
                    <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.defaultAsOf.errors">defaultAsOf Required</p> -->
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>FC Estimated Loan Position</label>
                    <input type="text" prefix="$" thousandSeparator="," mask="separator" value=""
                      name="fcEstimatedLoanPosition" formControlName="fcEstimatedLoanPosition">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>FC Loan Date</label>
                    <dp-date-picker placeholder="" theme="dp-material" formControlName="fcLoanDate"
                      [config]="datePickerConfig" name="fcLoanDate">
                    </dp-date-picker>
                    <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.fcLoanDate.errors">FCLoanDate Required</p> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>FC Loan Amount</label>
                    <input type="text" prefix="$" thousandSeparator="," mask="separator" value="" name="fcLoanAmount"
                      formControlName="fcLoanAmount">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>First Loan Date</label>
                    <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                    <dp-date-picker placeholder="" theme="dp-material" formControlName="firstLoanDate"
                      [config]="datePickerConfig" name="firstLoanDate">
                    </dp-date-picker>
                    <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.firstLoanDate.errors">FirstLoanDate Required</p> -->
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>First Loan Amount</label>
                    <input type="text" prefix="$" thousandSeparator="," mask="separator" placeholder="" value=""
                      name="firstLoanAmount" formControlName="firstLoanAmount">
                  </div>
                </div>

              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Second Loan Amount</label>
                    <input type="text" prefix="$" thousandSeparator="," mask="separator" placeholder="" value=""
                      name="secondLoanAmount" formControlName="secondLoanAmount">
                  </div>
                </div>

                <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>County</label>
                    <select name="countryName" formControlName=countryId (input)="select_dropdown($event,'state',true)">
                      <option selected disabled>----Select County---</option>
                      <option *ngFor="let country of countrylist" [value]="country.countryId">
                        {{ country.countryName }}
                      </option>
                    </select>
                  </div>
                </div> -->



                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                  <div class="apexinput-froms">
                    <label>Notes</label>
                    <input type="text" name="notes" formControlName="notes">
                  </div>



                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">



                  <div class="apexinput-froms">
                    <label>Spouse</label>
                    <input type="text" name="spouse" formControlName="spouse">
                  </div>



                </div>



              </div>
            </div>


            <div class="apexforms-group lead-group">
              <div class="row">

                   <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                  <div class="apexinput-froms">
                    <label>Mailing Address </label>
                    <!-- <textarea placeholder="" name="address" formControlName="address">
                  </textarea>
                    <div class="form-text text-danger"
                      *ngIf="leadForm.submitted && nestedFormGroup.controls.address.errors">Address Required
                    </div> -->
                    <span class="label_sub">Is the Property address the same as Mailing Address?</span>
                    <div class="mail_add">
                      <div class="radio">
                        <label>
                          <input type="radio" name="gender" value="Yes" (click)="setradio('Yes')" ngModel>
                          Yes
                        </label>
                      </div>
                      <div class="radio">
                        <label>
                          <input type="radio" name="gender" value="No" (click)="setradio('No')" ngModel>
                          No
                        </label>
                      </div>
                    </div>

                    <div *ngIf="isSelected('Yes')">
                      <!-- <textarea name="address" class="hgt_reduce" [(ngModel)]="mailngaddress"
                        formControlName="address">  </textarea>
 -->

                    </div>


                    <!---->
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                  <div class="apexinput-froms">
                    <label>Referred by</label>
                    <input type="text" name="referedBy" formControlName="referedBy">
                  </div>

                </div>


                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>APN</label>
                    <input type="text" name="apn" formControlName="apn">

                  </div>
                </div>


 <!-- <div *ngIf="isSelected('No')"> -->
                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngIf="isSelected('No')">
                        <div class="apexinput-froms">
                          <label>Mailing address</label>
                          <textarea name="address" class="hgt_reduce" formControlName="address"> </textarea>

                        </div>
                        <!-- <div class="apexinput-froms">
                          <label>Mailing City *</label>
                          <select name="cityId" formControlName="cityId">
                            <option [ngValue]="null" selected disabled>----Select City---</option>
                            <option *ngFor="let city of citylist" [value]="city.cityId">
                              {{ city.cityName }}
                            </option>
                          </select>
                          <div class="form-text text-danger"
                            *ngIf="leadForm.submitted && nestedFormGroup.controls.stateId.errors">State
                            Required</div>
                        </div> -->
                        <!-- <div class="apexinput-froms">
                          <label>Zip Code</label>
                          <input type="text" placeholder="" value="" name="zipCode" formControlName="zipCode">
                        </div> -->
                      </div>

                    <!-- </div> -->

<div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngIf="isSelected('No')">
                  <div class="apexinput-froms">
                    <label>City </label>
                    <!-- <select name="cityName" formControlName="cityId">
                      <option [ngValue]="null" selected disabled>----Select City---</option>
                      <option *ngFor="let city of citylist" [value]="city.cityId">
                        {{ city.cityName }}
                      </option>
                    </select> -->
                    <input type="text" name="cityName" formControlName="cityName">

                  </div>

                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngIf="isSelected('No')">

                  <div class="apexinput-froms">
                    <label>State </label>
                    <select name="stateName" formControlName=stateName>
                      <option selected disabled>----Select State---</option>
                      <option *ngFor="let state of statelist" [value]=state.stateName>
                        {{ state.stateName }}
                      </option>
                    </select>
                    <div class="form-text text-danger"
                      *ngIf="leadForm.submitted && nestedFormGroup.controls.stateName.errors">State Required</div>
                  </div>

                </div>

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngIf="isSelected('No')">
                  <div class="apexinput-froms">
                    <label>Zip Code</label>
                    <input type="text" placeholder="" value="" name="zipCode" formControlName="zipCode">
                  </div>
                </div>


              </div>
            </div>





            <div *ngIf="updateForm">
              <div class="apex-title-lilblue">
                <h3>Assign Lead</h3>
              </div>
              <div class="prev_assigned_detail">
                <h4>Previous Assigned Detail</h4>
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="apexinput-froms">
                      <label>Assigned To</label>
                      <span *ngIf="userTypeid == 2">Manager</span>
                      <span *ngIf="userTypeid == 4">Agent</span>
                      <span *ngIf="userTypeid == 3">Borker</span>

                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="apexinput-froms">
                      <label>User Name</label>
                      <span>{{userType}}</span>
                    </div>
                  </div>

                </div>
              </div>
              <div class="apexforms-group lead-group prev-lead-group" [formGroup]="formGroup"
                *ngFor="let formGroup of nestedFormGroup.controls.leadChildAssign.controls;let i = index;">

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="apexinput-froms">
                      <label>Re-Assign To</label>
                      <select formControlName="userTypeId" (input)="select_dropdown($event,'userType',true)">
                        <option selected disabled>----Select UserType---</option>
                        <option *ngFor="let usertypelist of userTypes" [value]="usertypelist.userTypeId">
                          {{ usertypelist.userTypeName }}
                        </option>

                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="apexinput-froms">
                      <label *ngIf="usertype != 'Agent'">Manager Name</label>
                      <label *ngIf="usertype == 'Agent'">Agent Name</label>
                      <select formControlName="userId">
                        <option selected disabled>---Select User-----</option>
                        <option *ngFor="let userlist of  userLists" [value]="userlist.userId">
                          {{ userlist.userName }}
                        </option>


                      </select>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div class="col-xl-12 rej-accpt-btn mt-0" *ngIf="agentBrokerDetails == 'agentMindteam'">
              <button (click)="getrejectLead()" class=" btn reject-btn">Reject</button>
              <button class="btn accept-btn" (click)="acceptLead()">Accept</button>
            </div>

            <div class="apxbtn_finalcenter apx-mt-15" *ngIf="agentBrokerDetails != 'agentMindteam'">
              <button class="btn_grey" type="reset" routerLink="/superadmin/lead-list">Cancel</button>
              <!-- <button class="btn_grey" type="reset" (click)="cancelReset()">Cancel</button> -->
              <button class="btn_dblue" type="submit">save</button>
            </div>

          </div>
        </div>
      </form>

    </div>

  </main>
</div>

<!-- The Modal -->
<div class="modal dash-edit-reject" *ngIf="openPopReject == true"
  style="display: block;background: rgb(25 19 19 / 0.2);" id="rejectby">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header reject_border">
        <button type="button" (click)="closetabModal()" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Reject Lead</h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form name="form" (ngSubmit)="rejectForm.form.valid && onsubimtReject(rejectedNotes.value)"
          #rejectForm="ngForm">
          <div class="filterforms_setting">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="apxgroup-filter">
                  <label>Reason for Reject</label>
                  <textarea name="rejectedNotes" [(ngModel)]="Rejectmodel.rejectedNotes" #rejectedNotes="ngModel"
                    [ngClass]="{ 'is-invalid': rejectForm.submitted && rejectedNotes.invalid }" required></textarea>
                  <div *ngIf="rejectForm.submitted && rejectedNotes.invalid" class="invalid-feedback">
                    <div *ngIf="rejectedNotes.errors">Enter the Reason</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="apxbtn_finalcenter can-update">
              <button (click)="closetabModal()" type="reset" class="btn_grey">Cancel</button>
              <button class="btn_dblue" type="submit">Update</button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>
