import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../service/auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/service/token.service';
import { truncate } from 'lodash';

@Component({
  selector: 'app-login-authentication',
  templateUrl: './login-authentication.component.html',
  styleUrls: ['./login-authentication.component.css'],
})
export class LoginAuthenticationComponent implements OnInit {
  public otpDatavalue: any;

  public userdata: any;
  state: any;
  EmailId: any;
  PasswordId: any;
  getOTPvalue: any;
  getOTPResendvalue: any;
  otp: string | undefined;
  otpExpire = false;
  userRouteInfo: any;
  brokerSignedInfo: Boolean = false;
  agentSignedInfo: Boolean = false;
  // config = {
  //   length: 6,
  //   placeholder: '',
  //   inputStyles: {
  //     'width': '50px',
  //     'height': '50px'
  //   }
  // };
  //timer
  // countDown: any;
  // counter = 180;
  // tick = 1000;

  constructor(
    private auth: AuthServiceService,
    private toast: ToastrService,
    private tokenStorage: TokenService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.userdata = window.history.state;
    console.log(this.userdata, 'asd');
    this.EmailId = this.userdata.loginAuth.email;
    this.PasswordId = this.userdata.loginAuth.password;
    console.log(this.EmailId, 'Email');
    console.log(this.PasswordId, 'PSd');

    this.userRouteInfo = sessionStorage.getItem('userInfo');
    console.log(this.userRouteInfo, 'useringooo');
  }
  onOtpChange(otp: any) {
    this.otpDatavalue = otp;
  }

  getOtpVerify() {
    this.getOTPvalue = {
      email: this.EmailId,
      password: this.PasswordId,
      otpValue: this.otpDatavalue,
    };
    // console.log(this.getOTPvalue, 'APIFinal')
    this.auth._otpVerify(this.getOTPvalue).subscribe((login_Response: any) => {
      // console.log(login_Response, 'resOP')
      if (login_Response.response == true) {
        this.tokenStorage._save_token(login_Response.token);
        this.tokenStorage._save_userRole(
          login_Response.userInfoBackEnd.UserTypeName
        );
        this.tokenStorage._set_email(login_Response.userInfoBackEnd.EmailId);
        this.tokenStorage._set_userTypeId(
          login_Response.userInfoBackEnd.CurrentLoggedUserId
        );
        sessionStorage.setItem(
          'userInfo',
          JSON.stringify(login_Response.userInfoBackEnd)
        );
        sessionStorage.setItem(
          'propic',
          login_Response.userInfoBackEnd.ProfilePicpath
        );
        sessionStorage.setItem(
          'proname',
          login_Response.userInfoBackEnd.FullName
        );

        if (
          login_Response.userInfoBackEnd.UserTypeName == 'Agent' ||
          login_Response.userInfoBackEnd.UserTypeName == 'Broker'
        ) {
          this.userRouteInfo =
            login_Response.userInfoBackEnd['AgentAgreementDetails'] ||
            login_Response.userInfoBackEnd['BrokerAgreementSigned'];
          console.log(
            this.userRouteInfo,
            this.userRouteInfo.IsSigned,
            'getting signed details'
          );
          if (this.userRouteInfo && this.userRouteInfo.IsSigned == false) {
            
            this.route.navigate(['/superadmin/login-agreement']);
          } 
          else if(this.userRouteInfo && this.userRouteInfo.IsSigned == truncate && login_Response.userInfoBackEnd.ChangePasswordFlag == true){
            this.route.navigate(['/superadmin/change-password'])
         }
         else {
            this.route.navigate(['/superadmin/dashboard']);
          }
        } 
        else if(this.userRouteInfo && this.userRouteInfo.IsSigned == truncate && login_Response.userInfoBackEnd.ChangePasswordFlag == true){
          this.route.navigate(['/superadmin/change-password'])
       }
        
        else {
          this.route.navigate(['/superadmin/dashboard']);
        }

        this.toast.success(login_Response.message);
      } else {
        this.toast.error(login_Response.message);
      }
    });
  }
  auth_otpresencvalue() {
    this.getOtpResend();
    // alert('aasd')
  }
  getOtpResend() {
    this.getOTPResendvalue = {
      email: this.EmailId,
      password: this.PasswordId,
    };
    //timer
    // let rsjx = timer(0, this.tick)
    // this.countDown = Observable.rsjx
    //   .take(this.counter)
    //   .map(() => --this.counter)

    this.otpExpire = true;
    this.auth._otpResend(this.getOTPResendvalue).subscribe((data: any) => {
      console.log(data, 'otpResend');
      if (data.response == true) {
        this.toast.success(data.message);
      } else {
        this.toast.error(data.message);
      }
    });
  }
}
