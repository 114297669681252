import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/service/manager.service';
import { TokenService } from '../../service/token.service';
import { DropdownService } from '../../service/dropdown.service';
import { ToastService } from '../../service/toast.service';
import { Observable } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

import { analyzeAndValidateNgModules } from '@angular/compiler';
import { NotificationService } from 'src/app/service/notification.service';
import { interval } from 'rxjs';
import * as moment from 'moment';
import { NotesService } from "../../service/notes.service"
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  currentUrl: string | undefined;
  getCurrentList: any;
  public clientTask: any = [];
  public userListBack: any = [];
  public state$: Observable<{ [key: string]: string; }> | undefined;
  filterRequest: any = {};
  searchKey: any;
  userListOptions: any;
  homeOwnerList: any;
  homeOwnerListData: any = [];
  statusList: any;
  showCross: boolean = false;
  usertypeId: any;
  assignedBy: any;
  updateTaskstatus: any;
  taskclientTaskId: any;
  taskclienleadId: any;
  taskEditformAssignedDate: any
  taskEditformTaskStatuse: any
  modelTaskpopUP: boolean | false;
  checkagentListReject: any
  updateAssignedDate: any;


  //chart
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };


  public mbarChartLabels: string[] = ['open files', 'closed files', 'projected closed files', 'files in Escrow', 'short sale in Approvals'];
  public mbarChartLabelsuperadmin: string[] = ['open files', 'closed files', 'projected closed files', 'files in Escrow', 'short sale in Approvals'];
  public mbarChartLabelBroker: string[] = ['open files', 'closed files', 'projected closed files', 'files in Escrow', 'short sale in Approvals'];
  public barChartType: any = "bar";
  public barChartLegend: boolean = true;



  public DbclientTask: any = [];
  public clientTaskBack: any = [];

  public barChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(218, 27, 27, 1)',
      borderColor: 'rgba(105,159,177,1)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    },
    {
      backgroundColor: 'rgba(20, 37, 113, 1)',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,20,96,1)'
    }
  ];


  public barChartData: any[] = [];

  //chart

  datePickerConfig = { 'format': 'MM/DD/YYYY', disableKeypress: true };
  public RecentLead: any;
  public recentList: any;
  public dashboardHOList: any = [];
  public usertype: any;
  public dashboardAGList: any;
  public dashboardBKList: any;
  public leadData: any = [];
  public userTypes: any;
  public userLists: any;
  public Rejectmodel: any = {};
  public RejectmodelBulk: any = {};
  order: string = 'leadowner';
  getDropdownUser: any;
  p: number = 1;
  reverse: boolean = false;
  masterSelected: boolean;
  checkedList: any;
  checkedListAgent: any;
  getUserlist: any
  openPopReject = false;
  openPopRejectBulk = false;
  public acceptObj = {};
  public rejectObj = {};
  public rejectBuldValue: any;
  public rejectObjId: any;
  public checkChangeId = {}
  public acceptorRejectList: any;
  getLeadTaskList: any = []
  userInfo: any
  userInfoList: any
  userTypeIdss: any
  currentLoggedID: any
  taskEditform: any = {};
  taskStatusChangeList: any
  checkagentList: any;

  //superadmin list begins
  public dashboardSAList: any = {};
  //superadmin list ends

  //manager list begins
  public dashboardMAList: any;
  //manager list ends

  //closure list begins
  public dashboardClosureList: any;
  //closure list ends

  //Ass Negotiator Begins
  public dashboardAsstNegotiatorList: any;
  //Ass Negotiator Ends


  //Negotiator Begins
  public dashboardNegotiatorList: any;
  importantMsg: any;
  //Negotiator Ends
  leadListBroker: any = [];
  DbleadListBroker: any = [];
  getListagentBroker: any;
  sendAssignedLead: any;
  userLeadId: any

  //Dashboard count List
  listOfManager: any = [];
  listOfBroker: any = [];
  listOfAgent: any = [];
  listOfHomeowner: any = [];
  listOfNegotiator: any = [];
  listOfAssNegotiator: any = [];
  listOfCloser: any = [];

  listOfsuperadminloasigned: any = [];
  listOfsuperadminAssignedLead: any = [];
  listOfsuperadminRejectedLead: any = [];
  listOfsuperadminOpenFiles: any = [];
  listOfsuperadminClosedFiles: any = [];
  listOfsuperadminprojectClosedFiles: any = [];
  listOfsuperadminClosedpreviousMonth: any = [];
  listOfsuperadminFilesinEscrow: any = [];
  listOfsuperadminShortsale: any = [];
  listOfsuperadminfileswithforeclosure: any = [];
  listOfsuperadminassignedtobrokers: any = [];

  listOfcommonloasigned: any = [];
  listOfcommonAssignedLead: any = [];
  listOfcommonRejectedLead: any = [];
  listOfcommonOpenFiles: any = [];
  listOfcommonClosedFiles: any = [];
  listOfcommonprojectClosedFiles: any = [];
  listOfcommonClosedpreviousMonth: any = [];
  listOfcommonFilesinEscrow: any = [];
  listOfcommonShortsale: any = [];
  listOfcommonfileswithforeclosure: any = [];
  listOfcommonassignedtobrokers: any = [];
  listofcommonduetoTask: any = [];
  listofcommonClosedFileFromJan: any = [];
  listofcommonLeadstobeAssigned: any = [];
  listofcommonAgents: any = [];

  //Dashboard count List


  //DashboardSuperadminChartList
  public dashboardSuperadminChartList: any;

  //DashboardBrokerChartList
  public dashboardBrokerChartList: any;


  profilename: string | null;
  notesList: any;
  constructor(private notificationservice: NotificationService,
    private notesService: NotesService,

    private toastr: ToastService, private _dropdown: DropdownService, private tokenStorage: TokenService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) {
    this.masterSelected = false;

  }

  ngOnInit(): any {
    //  return false;
    this.forclosureDateNotification();
    this.getCheckedItemList();
    this.getImportantNotification();
    this.getAllClientTask();
    // return false;
    this.currentUrl = this.route.url;
    // //console.log(this.currentUrl, 'url')

    interval(300000).subscribe(x => {

      this.senderNotification();
      this.receiverNotification();
    });
    this.getManager_count();
    this.getBroker_count();
    this.getAgent_count();
    this.getHomeowner_count();
    this.getNegotiator_count();
    this.getAssNegotiator_count();
    this.getCloser_count();

    this.getsuperadminloasigned_count();
    this.getsuperadminAssignedLead_count();
    this.getsuperadminRejectedLead_count();
    this.getsuperadmiOpenFiles_count();
    this.getsuperadminClosedFiles_count();
    this.getsuperadminprojectClosedFiles_count();
    this.getsuperadminClosedPreviousMonth_count();
    this.getsuperadminFilesinEscrow_count();
    this.getsuperadminshortsale_count();
    this.getsuperadminfileswithforeclosure_count();
    this.getsuperadminassignedtoBrokers_count();

    this.getcommonloasigned_count();
    this.getcommonAssignedLead_count();
    this.getcommonRejectedLead_count();
    this.getcommonOpenFiles_count();
    this.getcommonClosedFiles_count();
    this.getcommonprojectClosedFiles_count();
    this.getcommonClosedPreviousMonth_count();
    this.getcommonFilesinEscrow_count();
    this.getcommonshortsale_count();
    this.getcommonfileswithforeclosure_count();
    this.getcommonassignedtoBrokers_count();
    this.getcommonduetoTask();
    this.getcommonClosedFileFromJan_count();
    this.getcommonLeadstobeAssigned_count();
    this.getcommonAgents_count();


    // this.getCurrentList = window.history.state;
    // //console.log(this.getCurrentList.obj);
    // this.clientTask = this.getCurrentList.obj;

    this._getLeadListForBroker();



    this.usertype = this.tokenStorage._get_userRole();
    this.usertypeId = this.tokenStorage._get_userTypeId();

    this.userInfo = this.tokenStorage._get_userInfo();
    this.profilename = sessionStorage.getItem('proname')
    this.userInfoList = JSON.parse(this.userInfo);

    this.currentLoggedID = this.userInfoList.CurrentLoggedUserId;
    // //console.log(this.currentLoggedID, 'log');


    this.userTypeIdss = this.userInfoList.UserTypeId
    // //console.log(this.userTypeIdss, '99999999999ID')

    // //console.log(this.usertype, "UserRole");
    this.getAgentsForBroker();
    this.getDashboarSuperAdmin();
    this.getLeadDashboard();
    this.getDashboardManager();
    this.getDashboardClosure();
    this.getDashboardAgent();
    this.getDashboardBroker();
    this.getDashboardHomeOwner();
    this.getLeadlist();
    this.getfilter_details();
    this.getAsstNegotiator();
    this.getNegotiator();
    this.getDashboard();
    this.getDashboardchartBroker();
    this._getallLeadTask();

    var self = this;

    this.usertypes_Dropdown();
    //this.id = this.activated_route.snapshot.queryParamMap.get('id');
  }



  getAgentsForBroker() {
    this._manager_service.GetAgentsForBroker(this.usertypeId).subscribe((resp: any) => {
      this.getListagentBroker = resp.listData;
      this.userListBack = resp.listData;
      // //console.log(this.getListagentBroker, 'List agentfor broker')
    })
  }
  _getLeadListForBroker() {
    this._manager_service._getLeadForBroker().subscribe((resp: any) => {
      // //console.log(resp, 'ListLeadBorker')
      this.leadListBroker = resp.listData;
      this.DbleadListBroker = resp.listData;
      // if (resp.reponse == 'true') {
      //   this.leadListBroker = resp.listData;
      // }
    })
  }

  //Superadmin dashboardList Begins
  getDashboarSuperAdmin() {
    this._manager_service.get_DashboardSuperadmin().subscribe((DashboarSA: any) => {
      //console.log(DashboarSA, "superadminDashoardData")
      this.dashboardSAList = DashboarSA.getData;
    })
  }

  getLeadDashboard() {
    this._manager_service.get_LeadDashboard().subscribe((DashboardSALead: any) => {
      //console.log(DashboardSALead, "superadminDashoardLeadData")
      this.RecentLead = DashboardSALead.listData;
      this.recentList = this.RecentLead.slice(0, 8);
    })
  }
  showMore() {
    this.recentList = this.RecentLead;
  }

  //Superadmin dashboardList Ends

  //manager dashboardList Begins
  getDashboardManager() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_DashboardManager(CurrentUserId).subscribe((DashboardMA: any) => {
      //console.log(DashboardMA, "ManagerDashoardData")
      this.dashboardMAList = DashboardMA.getData;

    })
  }

  //manager dashboardList Ends

  //HomeOwner dashboardList Begins
  getDashboardHomeOwner() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_DashboardHomeOwner(CurrentUserId).subscribe((DashboardHO: any) => {
      //console.log(DashboardHO, "")
      this.dashboardHOList = DashboardHO.listData;
    })
  }
  //HomeOwner dashboardList Ends
  gototabs(clientData: any) {
    sessionStorage.setItem('clientData', JSON.stringify(clientData));
    this.route.navigateByUrl('/superadmin/client-file-status')
  }
  //closure dashboardList Begins
  getDashboardClosure() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_DashboardClosure(CurrentUserId).subscribe((DashboardClosure: any) => {
      //console.log(DashboardClosure, "closureDashboardData")
      this.dashboardClosureList = DashboardClosure.getData;
    })
  }

  //closure dashboardList Ends


  //AsstNegotiator Begins
  getAsstNegotiator() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_AsstNegotiator(CurrentUserId).subscribe((DashboardAssNego: any) => {
      //console.log(DashboardAssNego, "DashboardAssNegoData")
      this.dashboardAsstNegotiatorList = DashboardAssNego.getData;
    })

  }
  //AsstNegotiator Ends


  //Negotiator Begins
  getNegotiator() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_Negotiator(CurrentUserId).subscribe((DashboardNego: any) => {
      //console.log(DashboardNego, "DashboardNegoData")
      this.dashboardNegotiatorList = DashboardNego.getData;
      this.barChartData = [
        { data: [this.dashboardNegotiatorList.openFilesPreMonthCount, this.dashboardNegotiatorList.closedFilePreMonth, this.dashboardNegotiatorList.projectedClosedFilePreMonCount, this.dashboardNegotiatorList.fileInEscrowPreMonCount, this.dashboardNegotiatorList.shortSaleApprovalsPreMonCount], label: 'Previous month' },
        { data: [this.dashboardNegotiatorList.openFilesCurrentMonthCount, this.dashboardNegotiatorList.closedFilesCurrentMonCount, this.dashboardNegotiatorList.projectedClosedFileCurrentMonCount, this.dashboardNegotiatorList.fileInEscrowCurrentMonCount, this.dashboardNegotiatorList.shortSaleApprovalsCurrentMonCount], label: 'Current month' }
      ];
    })

  }
  //Negotiator Ends

  //Agent Service
  getDashboardAgent() {
    let currentUserId: any;
    currentUserId = this.tokenStorage._get_userTypeId();
    //console.log(currentUserId, 'currentuser')
    this._manager_service.get_dashboardAgent().subscribe((DashboardAG: any) => {
      //console.log(DashboardAG, 'AG');
      this.dashboardAGList = DashboardAG.getData;
    })
  }
  //Broker Service
  getDashboardBroker() {
    this._manager_service.get_dashboardBroker().subscribe((DashboardBK: any) => {
      //console.log(DashboardBK, 'BK');
      this.dashboardBKList = DashboardBK.getData;
    })
  }

  getallLeadList_agent() {
    this._manager_service.getall_listleadAgent().subscribe((AgentLeadList: any) => {
      //console.log(AgentLeadList, 'LeadListAG');
    })
  }
  //** GET LEAD LIST */
  getLeadlist() {
    this.showCross = false;
    this.searchKey = "";
    this._manager_service.get_All_Lead().subscribe((leadlist: any) => {
      this.leadData = leadlist.listData;
      //console.log(this.leadData, 'listList Leadlist')
    })
  }
  //**END */
  //**SORT */
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  //**END */
  //**CheckBox in lead list */
  checkUncheckAll() {
    for (var i = 0; i < this.leadData.length; i++) { this.leadData[i].isSelected = this.masterSelected; } this.getCheckedItemList()
    for (var i = 0; i < this.leadListBroker.length; i++) { this.leadListBroker[i].isSelected = this.masterSelected; } this.getCheckedItemList()
  }

  isAllSelected() {
    this.masterSelected = this.leadData.every(function (item: any) { return item.isSelected == true; }); this.getCheckedItemList();
    this.masterSelected = this.leadListBroker.every(function (item: any) { return item.isSelected == true; }); this.getCheckedItemList();
  }

  agentList(e: any) {
    //console.log(e, 'selectDropdown')
    //let value = e.target.value
    this.userLeadId = e;
  }


  acceptLead(id: number) {
    this.acceptObj = {
      "acceptorReject": 1,
      "leadId": id,
      "updatedBy": this.currentLoggedID
    }
    this._manager_service.getacceptLead(this.acceptObj).subscribe((acceptLead: any) => {
      //      //console.log(this.acceptObj, 'accLead')

      if (acceptLead.reponse == true) {
        this._getLeadListForBroker();
        this.getLeadlist();
        this.toastr.showSuccess("Lead/s Accepted Successfully and Moved to the Leads Center")
      } else {
        this.toastr.showError("ERROR Occured")
      }
      //console.log(acceptLead, 'accLead')
    })

  }
  // rejectLead(id: number) {
  //   // this.rejectObj = {
  //   //   "acceptorReject": 0,
  //   //   "leadId": id
  //   // }
  //   //console.log(this.rejectObj)
  //   this._manager_service.getrejectLead(this.rejectObj).subscribe((rejectLead: any) => {
  //     // if (rejectLead.reponse == true) {
  //     //   this.toastr.showSuccess("Lead Rejected Successfully")
  //     // } else {
  //     //   this.toastr.showError("ERROR Occured")
  //     // }
  //   })
  // }

  getrejectLead(id: number, assignId: number) {
    //return id\
    this.rejectObjId = id
    this.openPopReject = true;
    this.assignedBy = assignId;
    //console.log(id, 'idREj');
    //console.log(assignId, 'assignId');
  }
  onsubimtReject(value: any) {

    this.rejectObj = {
      "acceptorReject": 0,
      "leadId": this.rejectObjId,
      "assignedBy": this.assignedBy,
      "rejectedNotes": value,
      "updatedBy": this.currentLoggedID
    }
    //console.log(this.rejectObj);
    this._manager_service.getrejectLead(this.rejectObj).subscribe((rejectLead: any) => {

      if (rejectLead.reponse == true) {
        this._getLeadListForBroker();
        this.getLeadlist();
        this.openPopReject = false;
        this.toastr.showSuccess(rejectLead.reponse.message)
      } else {
        this.toastr.showError("ERROR Occured")
      }
    })

  }
  closetabModal() {
    this.openPopReject = false;
  }

  getCheckedItemList() {
    //console.log(this.getDropdownUser, this.getUserlist, "checkbox")
    let assignlead: any = []
    this.checkedList = [];
    this.checkagentList = [];
    this.checkagentListReject = [];
    if (this.usertype == 'Agent') {
      //  alert('Agent Startss');
      for (var i = 0; i < this.leadListBroker.length; i++) {
        if (this.leadListBroker[i].isSelected) {
          //console.log(this.leadListBroker[i], "newdata")
          this.checkagentList.push({
            "leadId": this.leadListBroker[i].leadId,
            "updatedBy": this.currentLoggedID,
            "acceptorReject": 1
          })
          this.checkagentListReject.push({
            "leadId": this.leadListBroker[i].leadId,
            "updatedBy": this.currentLoggedID,
            "rejectedNotes": this.rejectBuldValue,
            "acceptorReject": 0
          })

        }

      }

    } else {
      for (var i = 0; i < this.leadListBroker.length; i++) {

        if (this.leadListBroker[i].isSelected) {
          //console.log(this.leadListBroker[i], "newdata")
          this.checkagentList.push({
            "leadId": this.leadListBroker[i].leadId,
            "userId": Number(this.userLeadId),
            "userTypeId": 4,
            "isReassigned": true,
            "createdBy": this.currentLoggedID
          })

        }

      }
      //console.log(this.checkagentList['length'], 'ListAgent')
      this.checkagentList = this.checkagentList;
      // //console.log(this.checkedList)

    }

  }

  //**END */
  onsubimtRejectBulk(value: any) {
    this.rejectBuldValue = value;
    //console.log(this.rejectBuldValue, 'rejectNotes')
    this.checkagentListReject = this.checkagentListReject.filter((item: any) => {
      item.rejectedNotes = value
      return item;
    })
    //console.log('this.checkagentListReject', this.checkagentListReject);

    this.bulkreject();
  }
  //BulkAcceptorReject
  bulkAccept() {


    if (this.checkagentList.length <= 0) {
      this.toastr.showError(" please select atleast one lead to assign")
    } else {
      //console.log(this.checkagentList, 'bulkAccpt')
      this.acceptorRejectList = 1;
      this._manager_service.getacceptBulkLead(this.checkedListAgent).subscribe((acceptBulk: any) => {

        //console.log(acceptBulk, 'AccetpBulkAPI');
        if (acceptBulk.reponse == true) {
          this._getLeadListForBroker();
          this.getLeadlist();
          this.toastr.showSuccess("Lead Accepted Successfully")
        } else {
          this.toastr.showError("ERROR Occured")
        }

      })
    }
    //console.log(this.checkedListAgent, 'AccetpBulk');
  }
  assign_lead() {
    //console.log(this.checkedList);
    this._manager_service._assign_Lead(this.checkedList).subscribe((data: any) => {
      this.toastr.showSuccess("Lead Assigned")
    })
  }
  agenttypes_Dropdown(item: any) {
    //console.log(item, 'asd')
  }
  //**Usertype Dropdown */
  usertypes_Dropdown() {
    this._dropdown._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      //  //console.log(this.userTypes,"ji")
    })


    this._dropdown._get_Userlist(4).subscribe((getuserlist: any) => {
      this.userLists = getuserlist.listData;
      //console.log(this.userLists, "dsdsdsf")
    })

  }

  _sentassignedLeadtoAgent() {
    if (this.checkagentList.length <= 0) {
      this.toastr.showError(" please select atleast one lead to assign")
    } else {
      this.checkagentList = this.checkagentList.filter((item: any) => {
        item.userId = Number(this.userLeadId);
        return item;
      })
      this._manager_service.assignedLeadtoAgent(this.checkagentList).subscribe((data: any) => {
        //console.log(data, '_sentassignedLeadtoAgent')
        if (data.reponse == true) {
          this._getLeadListForBroker();
          this.toastr.showSuccess(data.message);
        } else {
          this.toastr.showError(data.message);
        }
      })
    }
  }
  //**END */

  bulkrejectMsg() {
    if (this.checkagentList.length <= 0) {
      this.toastr.showError(" please select atleast one lead to assign")
    } else {
      this.openPopRejectBulk = true;
    }

  }
  closetabModalbulk() {
    this.openPopRejectBulk = false;
  }
  bulkreject() {


    if (this.checkagentList.length <= 0) {
      this.toastr.showError(" please select atleast one lead to assign")
    } else {
      //console.log(this.checkagentListReject, 'rejectBulkValue')
      this._manager_service.getrejectBulkLead(this.checkagentListReject).subscribe((rejectBulk: any) => {
        //console.log(rejectBulk, 'rejectBulkAPI');

        if (rejectBulk.reponse == true) {
          this.getLeadlist();
          this._getLeadListForBroker();
          this.openPopRejectBulk = false;
          this.toastr.showSuccess("Lead Rejected Successfully")
        } else {
          this.toastr.showError(rejectBulk.message)
        }

      })
    }
    //console.log(this.checkedListAgent, 'rejectBulk');
  }

  // events
  public chartClicked(e: any): void {
    //console.log(e);
  }

  public chartHovered(e: any): void {
    //console.log(e);
  }

  public randomize(): void {
    let data = [
      Math.round(Math.random() * 100),
      Math.round(Math.random() * 100),
      Math.round(Math.random() * 100),
      (Math.random() * 100),
      Math.round(Math.random() * 100),
      (Math.random() * 100),
      Math.round(Math.random() * 100)];
    let clone = JSON.parse(JSON.stringify(this.barChartData));
    clone[0].data = data;
    this.barChartData = clone;
  }
  //Filter
  getFilterData() {
    //console.log(this.filterRequest);
    this.filterRequest['homeOwnerId'] = parseInt(this.filterRequest.homeOwnerId);
    this.filterRequest['assignedUserTypeId'] = parseInt(this.filterRequest.assignedUserTypeId);
    this.filterRequest['status'] = parseInt(this.filterRequest.status);
    this._dropdown._getAll_lead_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.leadData = filteredData.listData;
    });
  }
  //**Get Details for Filter */
  getfilter_details() {
    this._dropdown._getAll_lead_UserList().subscribe((userList: any) => {
      //console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
      //console.log(this.userListOptions, 'Loistrtrtrtrtrtrtr')
    })
    this._dropdown._getAll_lead_homeOwner().subscribe((homeowner: any) => {
      //console.log(homeowner.listData, "sji")
      this.homeOwnerList = homeowner.listData;
      this.homeOwnerListData = homeowner.listData;
    })

    this._dropdown._getAll_lead_StatusList().subscribe((statuslist: any) => {
      //console.log(statuslist.listData, "sji")
      this.statusList = statuslist.listData;
    })
  }
  agentBrokerModule(agentBroker: any) {
    this.route.navigateByUrl('/superadmin/lead-list-edit/', { state: { obj: agentBroker, dashboard: 'agentMindteam' } })
  }

  _getallLeadTask() {
    this._manager_service.getAllLeadTask(this.currentLoggedID).subscribe((resp: any) => {
      if (resp.reponse == true) {
        this.getLeadTaskList = resp.listData;
      }
      //console.log(this.getLeadTaskList, 'listLeadTask')
    })
  }
  taskStatusChange(listItem: any) {
    //console.log(listItem, 'listItem statusChanges')
    this.taskStatusChangeList = {
      'clientTaskId': listItem.clientTaskId,
      'leadId': listItem.leadId,
      'taskStatus': listItem.taskStatus,
      'createdBy': this.currentLoggedID
    }
    //console.log(this.taskStatusChangeList, 'listTask')
    this._manager_service.updateLeadTask(this.taskStatusChangeList).subscribe((resp: any) => {
      //console.log(resp, 'listTaskLead')
      if (resp.reponse == true) {
        this.toastr.showSuccess(resp.message);
      } else {
        this.toastr.showError(resp.message);
      }

    })
  }

  getImportantNotification() {
    this.notificationservice.getImpMsg().subscribe((impdata: any) => {
      // $("#impnotify").modal('show');
      this.importantMsg = impdata.listData;
      if (this.importantMsg.length > 0 && this.usertype != 'SuperAdmin') {
        $("#notificatonpopup").modal('show');
      } else {

        $("#notificatonpopup").modal('hide');
      }
      //console.log(impdata, "notify imp data")
    })
  }

  notifyreaded(readId: number) {
    this.notificationservice.updateReadSinglenotify({ 'notifydetailsId': readId, 'isReaded': true, "isImportant": false, }).subscribe((readSinglenotify: any) => {
      //console.log(readSinglenotify, 'singleData');
      this.getImportantNotification();
    })

  }





  //**sender receiver notification */
  senderNotification() {

    this.notificationservice.senderAutoNotify().subscribe((data: any) => {
      if (data.listData.length > 0) {
        // this.toastr.showSuccess("notification from sender , kindly check notification")
      }

    }, err => {
      // this.toastr.showError("something went wrong")
    })
  }

  receiverNotification() {
    this.notificationservice.receiverAutoNotify().subscribe((data: any) => {
      if (data.listData.length > 0) {
        // this.toastr.showSuccess("notification from receiver , kindly check notification")
      }

    }, err => {
      // this.toastr.showError("something went wrong")
    })
 
  }

  forclosureDateNotification() {
    this.notificationservice.foreClosureAlert().subscribe((data: any) => {
      // this.toastr.showSuccess("foreclosure Date Alert")

    })
  }
  onSearchHomeOwner(e: any) {
    this.homeOwnerList = this.homeOwnerListData.filter((item: any) => item.ownerName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  // _getassignedLeadForBroker() {
  //   this._manager_service._getAssignedLeadForBroker(this.currentLoggedID).subscribe((resp: any) => {
  //     //console.log(resp, 'brokerListLEAD')
  //   })
  // }

  // managerList() {
  //   this.route.navigate(['/superadmin/dashboardList'])
  // }
  // brokerList() {
  //   this.route.navigate(['/superadmin/dashboardList'])
  // }


  getManager_count() {
    this._manager_service._get_All_manager().subscribe((data: any) => {
      this.listOfManager = data.listData;
      //console.log(data.listData);
    })
  }

  getBroker_count() {
    this._manager_service._get_All_Broker().subscribe((data: any) => {
      this.listOfBroker = data.listData;
    })
  }

  getAgent_count() {
    this._manager_service._get_All_agent().subscribe((data: any) => {
      this.listOfAgent = data.listData;
    })
  }

  getHomeowner_count() {
    this._manager_service._get_All_HomeOwners().subscribe((data: any) => {
      this.listOfHomeowner = data.listData;
    })
  }

  getNegotiator_count() {
    this._manager_service._get_All_Negotiator().subscribe((data: any) => {
      this.listOfNegotiator = data.listData;
    })
  }

  getAssNegotiator_count() {
    this._manager_service._get_All_asstNegotiator().subscribe((data: any) => {
      this.listOfAssNegotiator = data.listData;
    })
  }

  getCloser_count() {
    this._manager_service._get_All_Closers().subscribe((data: any) => {
      this.listOfCloser = data.listData;
    })
  }

  getsuperadminloasigned_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminLOASigned(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminloasigned = data.listData;
    })
  }

  getsuperadminAssignedLead_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminAssignedLead(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminAssignedLead = data.listData;
    })
  }

  getsuperadminRejectedLead_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminRejectedLead(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminRejectedLead = data.listData;
    })
  }

  getsuperadmiOpenFiles_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminOpenFiles(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminOpenFiles = data.listData;
    })
  }

  getsuperadminClosedFiles_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminClosedFiles(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminClosedFiles = data.listData;
    })
  }

  getsuperadminprojectClosedFiles_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminProjectClosedFiles(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminprojectClosedFiles = data.listData;
    })
  }

  getsuperadminClosedPreviousMonth_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminClosedPreviousMonth(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminClosedpreviousMonth = data.listData;
    })
  }

  getsuperadminFilesinEscrow_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminFilesinEscrow(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminFilesinEscrow = data.listData;
    })
  }


  getsuperadminshortsale_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminshortsale(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminShortsale = data.listData;
    })
  }

  getsuperadminfileswithforeclosure_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminfileswithforeclosure(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminfileswithforeclosure = data.listData;
    })
  }

  getsuperadminassignedtoBrokers_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForSuperAdminAssigntoBrokers(CurrentUserId).subscribe((data: any) => {
      this.listOfsuperadminassignedtobrokers = data.listData;
      //console.log(this.listOfsuperadminassignedtobrokers, 'suuuuuuuuuuuuu')
    })
  }

  //commonList
  getcommonloasigned_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonloasigned(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonloasigned = data.listData;
    })
  }

  getcommonAssignedLead_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonAssignedLead(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonAssignedLead = data.listData;
    })
  }

  getcommonRejectedLead_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonRejectedLead(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonRejectedLead = data.listData;
    })
  }

  getcommonOpenFiles_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonOpenFiles(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonOpenFiles = data.listData;
    })
  }

  getcommonClosedFiles_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonClosedFiles(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonClosedFiles = data.listData;
    })
  }

  getcommonprojectClosedFiles_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonprojectClosedFiles(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonprojectClosedFiles = data.listData;
    })
  }

  getcommonClosedPreviousMonth_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonClosedPreviousMonth(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonClosedpreviousMonth = data.listData;
    })
  }

  getcommonFilesinEscrow_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonFilesInEscrow(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonFilesinEscrow = data.listData;
    })
  }

  getcommonshortsale_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonshortsale(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonShortsale = data.listData;
    })
  }

  getcommonfileswithforeclosure_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonfileswithforeclosure(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonfileswithforeclosure = data.listData;
    })
  }

  getcommonassignedtoBrokers_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonassignedtoBrokers(CurrentUserId).subscribe((data: any) => {
      this.listOfcommonassignedtobrokers = data.listData;
      //console.log(this.listOfcommonassignedtobrokers, '123123')
    })
  }

  getcommonduetoTask() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonDueForTasks(CurrentUserId).subscribe((data: any) => {
      this.listofcommonduetoTask = data.listData;
    })
  }

  getcommonClosedFileFromJan_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonClosedFileFromJan(CurrentUserId).subscribe((data: any) => {
      this.listofcommonClosedFileFromJan = data.listData;
    })
  }

  getcommonLeadstobeAssigned_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonLeadstobeAssigned(CurrentUserId).subscribe((data: any) => {
      this.listofcommonLeadstobeAssigned = data.listData;
    })
  }

  getcommonAgents_count() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.getDetailsForcommonAgents(CurrentUserId).subscribe((data: any) => {
      this.listofcommonAgents = data.listData;
    })
  }
  //**new changes */

  taskEdit(data: any) {
    this.taskEditform = data;
    this.taskEditformAssignedDate = data.completionDate;
    this.taskEditformTaskStatuse = data.taskStatus;
    this.taskclientTaskId = data.clientTaskId;
    this.taskclienleadId = data.leadId;

    //console.log(this.taskEditformAssignedDate, 'data')
    //console.log(data, 'datafullresp')
    //console.log(this.taskEditformTaskStatuse, 'status')

    //console.log(this.taskEditform, 'tasl')
    // this.taskStatusChangeList = {
    //   'clientTaskId': data.clientTaskId,
    //   'leadId': data.leadId,
    //   'taskStatus': data.taskStatus,
    //   //'completionDate': this.selectedDate,
    //   'createdBy': this.currentLoggedID
    // }
    //console.log(this.taskStatusChangeList, 'listTask')
    this.modelTaskpopUP = true;
  }
  submitDate(e: any) {

    this.updateAssignedDate = e;
    //console.log(e, 'dataLister')
    //console.log(this.taskEditformAssignedDate, 'dataLister')
  }
  changeTaskstatus(e: any) {
    //console.log(e.target.value, 'taskstaus')
    this.updateTaskstatus = e.target.value;
  }
  modelTaskSubmit() {
    this.taskStatusChangeList = {
      'clientTaskId': this.taskclientTaskId,
      'leadId': this.taskclienleadId,
      'taskStatus': this.taskEditformTaskStatuse,
      'completionDate': this.updateAssignedDate,
      // 'completionDate': this.taskEditformAssignedDate,
      'createdBy': this.currentLoggedID
    }
    this._manager_service.updateLeadTask(this.taskStatusChangeList).subscribe((resp: any) => {
      //console.log(resp, 'listTaskLead')
      if (resp.reponse == true) {
        this._getallLeadTask();
        this.modelTaskpopUP = false;
        this.toastr.showSuccess(resp.message);
      } else {
        this.toastr.showError(resp.message);
      }

    })
    //console.log(this.taskStatusChangeList, 'FinalUpdate')
  }
  onSearchUsers(e: any) {
    this.userLists = this.userListBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
  }

  hidePop() {
    $('.modal').modal('hide');
  }

  onSearch(e: any) {
    //console.log("value ", e);
    var self = this;
    self.leadListBroker = self.DbleadListBroker.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

  getTaskCompletionStatus(data: any) {
    // //console.log("data ==>getTaskCompletionStatus", data);
    return new Date().setHours(0, 0, 0, 0) > new Date(data.completionDate).setHours(0, 0, 0, 0) && data.taskStatus != 'Completed' ? true : false;
  }

  getAllClientTask() {
    this.usertype = this.tokenStorage._get_userRole();
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    //console.log(CurrentUserId, "hi cuser ")
    // this._manager_service.get_allClientTask(CurrentUserId).subscribe((res: any) => {
    this._manager_service.get_taskList(CurrentUserId).subscribe((res: any) => {
      //console.log(res, "taskList-Template")
      //this.leadData = res.listData;
      if (res.listData == null) {
        this.clientTask = [];
        this.DbclientTask = [];
        this.clientTaskBack = [];
      }
      else {
        this.clientTask = res.listData.filter((item: any) => item.taskStatus != 'Completed');
        this.clientTaskBack = this.clientTask;
        this.DbclientTask = this.clientTask;
      }
      //console.log("this.clientTask ", this.clientTask);
    })
  }


  //Dashboard chart for superadmin
  getDashboard() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_DashboardChartSuperadmin(CurrentUserId).subscribe((DashboardSuperadminchart: any) => {
      //console.log(DashboardSuperadminchart, "DashboardSuperAdminChartData")
      this.dashboardSuperadminChartList = DashboardSuperadminchart.getData;
      this.barChartData = [
        { data: [this.dashboardSuperadminChartList.openFilesPreMonthCount, this.dashboardSuperadminChartList.closedFilePreMonth, this.dashboardSuperadminChartList.projectedClosedFilePreMonCount, this.dashboardSuperadminChartList.fileInEscrowPreMonCount, this.dashboardSuperadminChartList.shortSaleApprovalsPreMonCount], label: 'Previous month' },
        { data: [this.dashboardSuperadminChartList.openFilesCurrentMonthCount, this.dashboardSuperadminChartList.closedFilesCurrentMonCount, this.dashboardSuperadminChartList.projectedClosedFileCurrentMonCount, this.dashboardSuperadminChartList.fileInEscrowCurrentMonCount, this.dashboardSuperadminChartList.shortSaleApprovalsCurrentMonCount], label: 'Current month' }
      ];
    })

  }
  //Dashboard chart for superadmin

  //Dashboard chart for Broker
  getDashboardchartBroker() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_DashboardChartBroker(CurrentUserId).subscribe((DashboardBrokerchart: any) => {
      //console.log(DashboardBrokerchart, "DashboardBrokerChartData")
      this.dashboardBrokerChartList = DashboardBrokerchart.getData;
      this.barChartData = [
        { data: [this.dashboardBrokerChartList.openFilesPreMonthCount, this.dashboardBrokerChartList.closedFilePreMonth, this.dashboardBrokerChartList.projectedClosedFilePreMonCount, this.dashboardBrokerChartList.fileInEscrowPreMonCount, this.dashboardBrokerChartList.shortSaleApprovalsPreMonCount], label: 'Previous month' },
        { data: [this.dashboardBrokerChartList.openFilesCurrentMonthCount, this.dashboardBrokerChartList.closedFilesCurrentMonCount, this.dashboardBrokerChartList.projectedClosedFileCurrentMonCount, this.dashboardBrokerChartList.fileInEscrowCurrentMonCount, this.dashboardBrokerChartList.shortSaleApprovalsCurrentMonCount], label: 'Current month' }
      ];
    })

  }
  //Dashboard chart for Broker


  //Negotiator Ends
}
