<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header *ngIf="usertype != 'Broker' && usertype != 'Agent'" headerMainTitle="Clients"></app-header>
    <app-header *ngIf="usertype == 'Broker' || usertype == 'Agent'" headerMainTitle="Short Sale Files"></app-header>

    <div class="container-fluid contentpage_wrapper">
      <div class="pagecontent_wrapper">
        <!-- <div class="apex-white_wrap" *ngIf="usertype != 'Negotiator' && usertype != 'AsstNegotiator'"> -->
        <!-- <div class="filterapx_wrap">
            <div class="filter_setting_forms">
              <h3>Filter By</h3>
              <div class="apxsilsetting_group">
                <label>Created Date</label>
                <input type="text" value="25-Aug-2020">
              </div>
              <div class="apxsilsetting_group">
                <label>File Status</label>
                <input type="text" value="All">
              </div>
              <div class="apxsilsetting_group">
                <label>Assigned Manager</label>
                <input type="text" value="Steve Job">
              </div>
              <div class="apxsilsetting_group">
                <label>Negotiator</label>
                <input type="text" value="Matthew McConaughey">
              </div>
            </div>
            <div class="filter_setting">
              <ul>
                <li data-toggle="modal" data-target="#filterby" class="filter_apx"><img
                    src="../../../assets/images/filter.png" alt="filter"></li>
                <li class="setting_reset"><img src="../../../assets/images/setting_reset.png" alt="filter"></li>
              </ul>
            </div>
          </div> -->
        <!-- </div> -->
        <!-- /Super Admin -->
        <div class="apex-white_wrap pd-0" *ngIf="usertype != 'Agent' && usertype != 'Broker' ">
          <div class="apx_clients_wrapper">
            <div class="apx_clients_title">
              <h2>List of Clients</h2>
  
            </div>

            <div class="client_tab">
              <div class="client_head">
                <button [ngClass]="isOpen == true ? 'active_tab': ''" (click)="isOpenview()">Opened</button>
                <button [ngClass]="isCancel == true ? 'active_tab': ''" (click)="isCancelview()">Cancelled
                  Transaction</button>
                <button [ngClass]="isCompleted == true ? 'active_tab': ''" (click)="isCompletedview()" >Closed /
                  Completed</button>
                  <button data-toggle="modal" data-target="#leadscratch" > Create Client</button>

                <div class="sidebar-search lead_glo global-search">
                  <div>
                    <div class="input-group">
                      <i class="fa fa-search" aria-hidden="true"></i>
                      <input type="search" placeholder="Search..." (input)="onSearch($event)" #searchword>
                    </div>
                  </div>
                </div>
              </div>
              <div class="apexinput-froms">
                <div *ngIf="isOpen" class="apx_clients-filemangement">
                  <div class="apx_clients-filemangement" *ngIf="clientList != null; else elseBlock">
                    <div class="apx_client-lister-details" *ngFor="let items of clientList">
                      <div class="apx_client-file-contents" (click)="goto_tabs(items)">
                        <div class="apx_client-content-grid d-flex flex-filescontent">
                          <img src="../../../assets/images/clients/files.png" alt="icon" />
                          <div class="apxfile_index_names">
                            <p>{{ items.fileNo }} ({{ items.homeOwnerName}})</p>
                            <span>{{ items.homeOwnerAddress }}</span>
                          </div>
                        </div>
                        <!-- <div class="apx_client-content-grid"><label>Assigned User</label>
                    <span>{{ items.assignedUsers[items.assignedUsers.length-1].userfullname }}</span>
                  </div> -->

                        <div class="apx_client-content-grid" *ngIf="items.manager"><label> Manager</label>
                          <span class="negoapx_title">{{ items.manager }}</span>
                        </div>

                        <div class="apx_client-content-grid" *ngIf="items.broker"><label> Broker</label>
                          <span class="negoapx_title">{{ items.broker }}</span>
                        </div>

                        <div class="apx_client-content-grid" *ngIf="items.agent"><label> Agent</label>
                          <span class="negoapx_title">{{ items.agent }}</span>
                        </div>
                        <!-- <div *ngIf="usertype != 'HomeOwner'"> -->
                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.negotiator' && items.negotiator != ''"><label>
                              Negotiator</label>
                            <span class="negoapx_title">{{ items.negotiator }}</span>
                          </div>

                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.negotiatorAssistant' && items.negotiatorAssistant != ''"><label>
                              NegotiatorAss</label>
                            <span>{{ items.negotiatorAssistant }}</span>
                          </div>

                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.closer' && items.closer != ''" ><label> Closer</label>
                            <span>{{ items.closer }}</span>
                          </div>
                        <!-- </div> -->

                        <!-- <div class="apx_client-content-grid"><label>LOA CompletedBy</label>
                    <span>{{ items.loAcompletedBy }}</span>
                  </div> -->

                      </div>
                      <div class="apx_client-file-status">
                        <div class="apx_clients-file-grid">
                          <label>File Status</label>
                          <span class="fileapxstatus">{{ items.docStatus }}</span>
                        </div>
                        <!-- <div class="apx_clients-file-grid">
                    <label>Created Date</label>
                    <span>{{ items.assignedDate }}</span>
                  </div> -->
                        <div class="apx_clients-file-grid">
                          <label>Foreclosure Date</label>
                          <span
                            [ngStyle]="{'color': getTaskCompletionStatus(items)?'red':'' }">{{ items.forclosureDate }}</span>
                        </div>
                        <div class="apx_clients-file-grid">
                          <a *ngIf="!items.isAssigned" (click)="assignuser_list(items)">Assign</a>
                          <a *ngIf="items.isAssigned" (click)="assignuser_list(items)">ReAssign</a>

                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                <ng-template #elseBlock>
                  <div class="nodata_found_components">
                    <h2>No Data found</h2>
                  </div>
                </ng-template>
              </div>
              <div class="apexinput-froms">
                <div *ngIf="isCancel" class="apx_clients-filemangement">
                  <div class="apx_clients-filemangement" *ngIf="cancelledClientList != null; else elseBlock">
                    <div class="apx_client-lister-details" *ngFor="let items of cancelledClientList">
                      <button class="re-open" (click)="reOpen('cancel', items.clientId)">Reopen File</button>
                      <div class="apx_client-file-contents" (click)="goto_tabs(items)">
                        <div class="apx_client-content-grid d-flex flex-filescontent">
                          <img src="../../../assets/images/clients/files.png" alt="icon" />
                          <div class="apxfile_index_names">
                            <p>{{ items.fileNo }} ({{ items.homeOwnerName}})</p>
                            <span>{{ items.homeOwnerAddress }}</span>
                          
                          </div>
                        </div>
                        <!-- <div class="apx_client-content-grid"><label>Assigned User</label>
                    <span>{{ items.assignedUsers[items.assignedUsers.length-1].userfullname }}</span>
                  </div> -->

                        <div class="apx_client-content-grid" *ngIf="items.manager"><label>Manager</label>
                          <span class="negoapx_title">{{ items.manager }}</span>
                        </div>

                        <div class="apx_client-content-grid" *ngIf="items.broker"><label>Broker</label>
                          <span class="negoapx_title">{{ items.broker }}</span>
                        </div>

                        <div class="apx_client-content-grid" *ngIf="items.agent"><label>Agent</label>
                          <span class="negoapx_title">{{ items.agent }}</span>
                        </div>
                        <!-- <div *ngIf="usertype != 'HomeOwner'"> -->
                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.negotiator' && items.negotiator != ''"><label>
                              Negotiator</label>
                            <span class="negoapx_title">{{ items.negotiator }}</span>
                          </div>

                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.negotiatorAssistant' && items.negotiatorAssistant != ''"><label>
                              NegotiatorAss</label>
                            <span>{{ items.negotiatorAssistant }}</span>
                          </div>

                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.closer' && items.closer != ''"><label>Closer</label>
                            <span>{{ items.closer }}</span>
                          </div>
                        <!-- </div> -->


                        <!-- <div class="apx_client-content-grid"><label>LOA CompletedBy</label>
                    <span>{{ items.loAcompletedBy }}</span>
                  </div> -->

                      </div>
                      <div class="apx_client-file-status">
                        <div class="apx_clients-file-grid">
                          <label>File Status</label>
                          <!-- <span class="fileapxstatus">{{ items.docStatus }}</span> -->
                            <span class="fileapxstatus">Cancelled</span>
                        </div>
                        <!-- <div class="apx_clients-file-grid">
                    <label>Created Date</label>
                    <span>{{ items.assignedDate }}</span>
                  </div> -->
                        <div class="apx_clients-file-grid">
                          <label>Foreclosure Date</label>
                          <span
                            [ngStyle]="{'color': getTaskCompletionStatus(items)?'red':'' }">{{ items.forclosureDate }}</span>
                        </div>
                        <!-- <div class="apx_clients-file-grid">
                    <a *ngIf="!items.isAssigned" (click)="assignuser_list(items)">Assign</a>
                    <a *ngIf="items.isAssigned" (click)="assignuser_list(items)">ReAssign</a>
                  </div> -->
                      </div>
                    </div>
                  </div>

                  <ng-template #elseBlock>
                    <div class="nodata_found_components">
                      <h2>No Data found</h2>
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="apexinput-froms">

                <div *ngIf="isCompleted"  class="apx_clients-filemangement">
                  <div class="apx_clients-filemangement" *ngIf="closedClientList != null; else elseBlock">
                    <div class="apx_client-lister-details" *ngFor="let items of closedClientList">
                      <button class="re-open" (click)="reOpen('complete' ,items.clientId)">Reopen File</button>
                      <div class="apx_client-file-contents" (click)="goto_tabs(items)">
                        <div class="apx_client-content-grid d-flex flex-filescontent">
                          <img src="../../../assets/images/clients/files.png" alt="icon" />
                          <div class="apxfile_index_names">
                            <p>{{ items.fileNo }} ({{ items.homeOwnerName}})</p>
                            <span>{{ items.homeOwnerAddress }}</span>
                            
                          </div>
                        </div>
                        <!-- <div class="apx_client-content-grid"><label>Assigned User</label>
                    <span>{{ items.assignedUsers[items.assignedUsers.length-1].userfullname }}</span>
                  </div> -->
 
                        <div class="apx_client-content-grid" *ngIf="items.manager"><label>Manager</label>
                          <span class="negoapx_title">{{ items.manager }}</span>
                        </div>

                        <div class="apx_client-content-grid" *ngIf="items.broker"><label>Broker</label>
                          <span class="negoapx_title">{{ items.broker }}</span>
                        </div>

                        <div class="apx_client-content-grid" *ngIf="items.agent"><label>Agent</label>
                          <span class="negoapx_title">{{ items.agent }}</span>
                        </div>
                        <!-- <div *ngIf="usertype != 'HomeOwner'"> -->
                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.negotiator' && items.negotiator != ''"><label>
                              Negotiator</label>
                            <span class="negoapx_title">{{ items.negotiator }}</span>
                          </div>

                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.negotiatorAssistant' && items.negotiatorAssistant != ''" ><label>
                              NegotiatorAss</label>
                            <span>{{ items.negotiatorAssistant }}</span>
                          </div>

                          <div class="apx_client-content-grid" *ngIf="usertype != 'HomeOwner' && 'items.closer' && items.closer != ''"><label>Closer</label>
                            <span>{{ items.closer }}</span>
                          </div>
                        <!-- </div> -->
                        <!-- <div class="apx_client-content-grid"><label>LOA CompletedBy</label>
                    <span>{{ items.loAcompletedBy }}</span>
                  </div> -->

                      </div>
                      <div class="apx_client-file-status">
                        <div class="apx_clients-file-grid">
                          <label>File Status</label>
                          <!-- <span class="fileapxstatus">{{ items.docStatus }}</span> -->
                           <span class="fileapxstatus">Closed</span>
                        </div>
                        <!-- <div class="apx_clients-file-grid">
                    <label>Created Date</label>
                    <span>{{ items.assignedDate }}</span>
                  </div> -->
                        <div class="apx_clients-file-grid">
                          <label>Foreclosure Date</label>
                          <span
                            [ngStyle]="{'color': getTaskCompletionStatus(items)?'red':'' }">{{ items.forclosureDate }}</span>
                        </div>
                        <!-- <div class="apx_clients-file-grid">
                    <a *ngIf="!items.isAssigned" (click)="assignuser_list(items)">Assign</a>
                    <a *ngIf="items.isAssigned" (click)="assignuser_list(items)">ReAssign</a>
                  </div> -->
                      </div>
                      
                    </div>
                  </div>

                  <ng-template #elseBlock>
                    <div class="nodata_found_components">
                      <h2>No Data found</h2>
                    </div>
                  </ng-template>
                </div>
              </div>

            </div>
            <!--hided old-->

            <!--hided old-->
          </div>


        </div>

        <!-- Agent/BRoker module -->
        <div class="apex-white_wrap pd-0" *ngIf="usertype == 'Agent' || usertype == 'Broker' ">
          <div class="apx_clients_wrapper">
            <div class="apx_clients_title">
              <h2>List of clients</h2>
              <div class="sidebar-search lead_glo global-search">
                <div>
                  <div class="input-group">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input type="search" placeholder="Search..." (input)="onSearchAss($event)" #searchword>
                  </div>
                </div>
              </div>
              <!-- <div class="client_search">
                <span><img src="../../../assets/images/clients/search.png" alt="search"></span>
                <input type="search" placeholder="Search by Name,Email..." />
              </div> -->
            </div>
            <div class="apx_clients-filemangement" *ngIf="clientassigned != null; else elseBlock">
              <div class="apx_client-lister-details" *ngFor="let items of clientassigned">
                <div (click)="goto_tabs(items)" class="apx_client-file-contents">
                  <div class="apx_client-content-grid d-flex flex-filescontent">
                    <img src="../../../assets/images/clients/files.png" alt="icon" />
                    <div class="apxfile_index_names">
                      <p>{{ items.fileNo }} {{ items.homeOwnerName }}</p>

                      <span>{{ items.homeOwnerAddress }}</span>
                    </div>
                  </div>
                  <div class="apx_client-content-grid"><label>Assigned by</label>
                    <span>{{ items.assignedUsers[0].assignedBy || "--"}}</span>
                  </div>
                  <div class="apx_client-content-grid"><label></label>
                    <span class="negoapx_title">{{ items.neogtiator }}</span>
                  </div>
                  <div class="apx_client-content-grid"><label></label>
                    <span>{{ items.assNeogtiator }}</span>
                  </div>
                </div>
                <div class="apx_client-file-status">

                  <div class="apx_clients-file-grid">
                    <label>Completed Date</label>
                    <span>{{ items.loAcompletedDate }}</span>
                  </div>
                  <div class="apx_clients-file-grid">
                    <label>Completed by</label>
                    <span>{{ items.loAcompletedBy }}</span>
                  </div>
                  <div class="apx_clients-file-grid">
                    <a data-toggle="modal" (click)="contactUsList(items)" data-target="#contact-modal">Contact
                      Us</a>

                  </div>
                </div>
              </div>
            </div>

            <ng-template #elseBlock>
              <div class="nodata_found_components">
                <h2>No Data found</h2>
              </div>
            </ng-template>
          </div>
        </div>

      
      </div>
    </div>
  </main>

</div>



<!-- The Modal for filter -->
<!-- <div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      Modal Header
      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel">Cancel</button>
        </div>
      </div>

      Modal body
      <div class="modal-body">
        <div class="filterforms_setting">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>First Name</label>
                <input type="text" placeholder="First name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Last Name</label>
                <input type="text" placeholder="Last name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Status</label>
                <select>
                  <option value="">All</option>
                  <option value="">All-1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->



<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <img src="../../../assets/images/danger.png" alt="danger" />
          <p>Are you sure want to delete the data?</p>
          <div class="filterbt_btnn btn_deletecard">
            <button class="btnfilter-cancel">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>


<div class="modal" id="leadscratch">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <i class="fa fa-user-circle" aria-hidden="true"></i>
          <div class="main_del">
          <a (click)="fromLeadData('fromlead','')">From Lead</a>
          <a (click)="fromLeadData('fromscratch','')">From Scratch</a>

         <div *ngIf="fromLead == true">
          <!-- <select class="docclient_drop"
          [ngModelOptions]="{standalone: true}" (change)="fromLeadData('fromdropdown',$event)">
          <option selected disabled>Select Lead</option>
          <option  *ngFor="let uniqueleadlist of leadUniqueList" [value]=uniqueleadlist.leadId >
            {{uniqueleadlist.uniqueLeadId}}</option>

        </select> -->
        <mat-form-field appearance="fill" *ngIf="fromLead == true">
        <mat-select [(ngModel)]="leadId" (selectionChange)="fromLeadData('fromdropdown',leadId)">
          <input type="text" placeholder="Search lead"  (keyup)="onSearchTitle($event)" (keydown)="$event.stopPropagation()">

          <mat-option *ngFor="let uniqueleadlist of leadUniqueList" [value]="uniqueleadlist.leadId" >
            {{uniqueleadlist.uniqueLeadId}}
          </mat-option>
        </mat-select>
      </mat-form-field>
         </div>



 </div>
          <div class="filterbt_btnn btn_deletecard">
            <!-- <button class="btnfilter-cancel">Yes</button> -->
            <button data-dismiss="modal" (click)="fromLead=false" class="btnfilter-apply">cancel</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
<!-- ContactUs -->
<div class="modal" id="contact-modal">
  <div class="modal-dialog modal-dialog-centered  modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <div class="contact-modal-head">
          <h4 class="modal-title">Contact US</h4>

          <button type="button" class="Cls-btn-g" data-dismiss="modal">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0)">
                <path
                  d="M8.93782 8.00465L15.7964 1.14611C16.0611 0.890455 16.0684 0.468636 15.8128 0.203925C15.5571 -0.0607856 15.1353 -0.0681257 14.8706 0.187527C14.865 0.192876 14.8596 0.198342 14.8542 0.203925L7.99559 7.06247L1.13705 0.203886C0.872335 -0.0517667 0.450516 -0.0444266 0.194863 0.220284C-0.054543 0.478513 -0.054543 0.887878 0.194863 1.14611L7.05341 8.00465L0.194863 14.8632C-0.0652798 15.1234 -0.0652798 15.5452 0.194863 15.8054C0.455045 16.0655 0.876864 16.0655 1.13705 15.8054L7.99559 8.94683L14.8541 15.8054C15.1189 16.061 15.5407 16.0537 15.7963 15.789C16.0457 15.5307 16.0457 15.1214 15.7963 14.8632L8.93782 8.00465Z"
                  fill="#A5A8A9" />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body contact-modal-body">

        <div class="contact-negotial cd-bk">
          <h2 class="contact-detail">Negotiator Contact Detail</h2>
          <div class="det-fl">
            <div class="contact-detail-inner">
              <p class="detail-label">Email Address</p>
              <h4 class="detail-txt-g">{{negotiatorEmail || '--'}}</h4>
            </div>

            <div class="contact-detail-inner">
              <p class="detail-label">Phone Number</p>
              <h4 class="detail-txt-g">{{phoneNumber || '--'}}</h4>
            </div>
          </div>
        </div>

        <div class="Apex-reso-g cd-bk">
          <h2 class="contact-detail">Apex resolution contact Detail</h2>
          <div class="det-fl">
            <div class="contact-detail-inner">
              <p class="detail-label">Email Address</p>
            </div>

            <div class="contact-detail-inner">
              <p class="detail-label">Phone Number</p>
            </div>


            <div class="contact-detail-inner">
              <p class="detail-label">Address</p>
            </div>
          </div>
          <div class="det-fl" *ngFor="let items of contactDetails">
            <div class="contact-detail-inner">
              <h4 class="detail-txt-g">{{items.email || '--'}}</h4>
            </div>

            <div class="contact-detail-inner">
              <h4 class="detail-txt-g">{{items.phone || '--'}}</h4>

            </div>


            <div class="contact-detail-inner">
              <h4 class="detail-txt-g addr-par">{{items.address || '--'}} </h4>
            </div>
          </div>

        </div>


      </div>
    </div>
  </div>
</div>
