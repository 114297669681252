

<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage notification">
    </app-header>

    <div class="container-fluid contentpage_wrapper">
      <div class="pagecontent_wrapper col-12 col-md-12 col-lg-12 col-xl-12">

        <div class="apex-white_wrap">


          <form (ngSubmit)="notifyform.form.valid && addOrUpdateNotification(notifyform)"  name="form"
            #notifyform="ngForm">
            <div class="apex-title-lilblue">
              <h3>send notification</h3>
            </div>
            <div class="apexforms-group align-decode full-size-part">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tvt-section">
                  <div class="apexinput-froms">
                    <label class="font-weight-600 mb-15">Task<sup>*</sup></label>
                    <textarea name="messagetoSend" [(ngModel)]="messagetoSend" #description="ngModel"
                      [ngClass]="{'is-invalid': notifyform.submitted && description.invalid}" required></textarea>
                    <div *ngIf="notifyform.submitted && description.invalid" class="invalid-feedback">
                      <div *ngIf="description.errors?.required">Task is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group align-decode full-size-part">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tvt-section">
                  <div class="apexinput-froms " id="via_popups">
                    <input name="isImportant" style="width:16px;" [(ngModel)]="isImportant" type="checkbox">

                    <label class="font-weight-600 send_via_pop">Send via popup</label>
                    <label class="font-weight-600">Send to</label>
                    <ul class="unstyled centered">
                      <li *ngFor="let list of checklist">
                        <input class="styled-checkbox" [(ngModel)]="list.isselected" [id]="list.userTypeId"
                          type="checkbox" (change)="selectRole(list)" [name]="list.userName">
                        <label [for]="list.userTypeId">{{ list.userName }}</label>
                      </li>
                    </ul>
                    <p style="color:#dc3545; font-size:14px;margin-top:10px;"
                      *ngIf="notifyform.submitted && !userselected">Note: Please select any user</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="apxbtn_finalcenter can-update">
              <button class="btn_grey" type="reset"  routerLink="/superadmin/dashboard">Cancel</button>
              <button class="btn_dblue" type="submit">Send</button>
              <!-- <button class="btn_dblue" data-toggle="modal" data-target="#snd_msg">Send important Mesaage</button> -->
            </div>
          </form>
          <div class="apxtable_grid_table">
            <div class="table-responsive apx-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> # </th>
                    <th> Task </th>
                    <th> Created On </th>
                    <th> Sent Via popup</th>
                    <th> Send To </th>

                    <th> Action </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of noficationList | paginate: { itemsPerPage: 45, currentPage: p }; let i = index">
                    <td>{{ (6 *(p-1))+ i +1 }}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.createOn}}</td>
                    <td >{{item.isImportant?'yes':'--'}}</td>

                    <td>{{item.userTypeName}}</td>
                    <td>
                      <div class="apx-actions">
                        <span class="pencil">
                          <button class="btn-create-manage notes-btn" (click)="editModel(item)" data-toggle="modal"
                            data-target="#snd_msg"><i class="fas fa-pencil-alt"></i></button>
                        </span>
                        <span class="trash"><i class="far fa-trash-alt" (click)="deleteNotification(item)"></i></span>
                        <span class="pencil"> <button class="btn-create-manage notes-btn" data-toggle="modal" (click)="viewUser(item)"
                          data-target="#view_user"> <i class="fa fa-eye" ></i></button></span>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
              <!-- <div class="nodata_found_components no_data_found" *ngIf="!userSpend.lastLoginDateTime">
                    <div colspan="6">No Data found</div>
                </div> -->
            </div>
          </div>
          <div class="apxtable_pagination_wrapper">
            <div class="apxtable_pagination">
              <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
          </div>
        </div>

      </div>

    </div>


    <!-- Modal popup -->

    <!-- The Modal -->
    <div class="modal" id="snd_msg">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">

          <!-- Modal body -->
          <div class="modal-body">

            <div class="send_chatbox">
              <form (ngSubmit)="notifyEditform.form.valid && updateNotification(notifyEditform)" name="form"
                #notifyEditform="ngForm">
                <div class="apex-white_wrap">
                  <div class="apex-title-lilblue">
                    <h3>send notification</h3>
                  </div>
                  <div class="apexforms-group align-decode full-size-part">
                    <div class="row">

                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tvt-section">
                        <div class="apexinput-froms">
                          <label class="font-weight-600 mb-15">Task</label>
                          <textarea name="messagetoSend" [(ngModel)]="editObj.description" #description="ngModel"
                            [ngClass]="{'is-invalid': notifyEditform.submitted && description.invalid}"
                            required></textarea>
                          <div *ngIf="notifyEditform.submitted && description.invalid" class="invalid-feedback">
                            <div *ngIf="description.errors?.required">Task is required</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="apexforms-group align-decode full-size-part">
                    <div class="row">

                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tvt-section">
                        <div class="apexinput-froms ">
                          <input name="isImportant" style="width:16px;" [(ngModel)]="editObj.isImportant"
                            type="checkbox" class="styled-checkbox">
                          <label class="font-weight-600 send_via_pop">Send via popup</label>
                          <label class="font-weight-600">Send to</label>
                          <ul class="unstyled centered">
                            <!-- <input type="checkbox" [checked]="true"> test -->
                            <li *ngFor="let list of checklist">
                              <input *ngIf="editObj.userTypeId == list.userTypeId" class="styled-checkbox"
                                [id]="list.userTypeId" type="checkbox" (change)="selectRole(list)"
                                [checked]="editObj.userTypeId == list.userTypeId" [name]="list.userName">
                              <label *ngIf="editObj.userTypeId == list.userTypeId"
                                [for]="list.userTypeId">{{ list.userName }}</label>
                            </li>
                          </ul>
                          <!-- <p style="color:#dc3545; font-size:14px;margin-top:10px;">Note: Please select any user</p> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="apxbtn_finalcenter can-update">
                    <!-- <button data-dismiss="modal" class="btn_grey">Cancel</button> -->
                    <button class="btn_dblue" type="submit">Update</button>
                  </div>
                </div>
              </form>
            </div>

          </div>



        </div>
      </div>
    </div>
    <div class="modal" id="view_user">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">

          <!-- Modal body -->
          <div class="modal-body">

            <div class="send_chatbox">
              <form (ngSubmit)="notifyEditform.form.valid&&updateNotification(notifyEditform)" name="form"
                #notifyEditform="ngForm">
                <div class="apex-white_wrap">
                  <div class="apex-title-lilblue">
                    <h3>User Details - ({{userTypeName}})</h3>

                  </div>

                  <div class="apexforms-group align-decode full-size-part">
                    <div class="row">

                      <div class="apxtable_grid_table">
                        <div class="table-responsive apx-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th> # </th>
                                <th> Name </th>
                                <th> Updated On </th>


                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let item of userDetail; let i = index">
                                <td>{{i +1 }}</td>
                                <td>{{item.userName}}</td>
                                <td>{{item.updatedOn}}</td>

                              </tr>

                            </tbody>
                          </table>
                      <span class="fr"><button class="btnfilter-cancel" data-dismiss="modal" type="reset">Close</button></span>
                          <!-- <div class="nodata_found_components no_data_found" *ngIf="!userSpend.lastLoginDateTime">
                                <div colspan="6">No Data found</div>
                            </div> -->
                        </div>
                      </div>


                    </div>
                  </div>

                </div>
              </form>
            </div>

          </div>



        </div>
      </div>
    </div>
    <!--// Modal popup -->
  </main>

</div>
