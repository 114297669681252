import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ManagerService } from 'src/app/service/manager.service';
import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Location } from '@angular/common';

import { ComponentCanDeactivate } from 'src/app/can-deactivate.guard';

@Component({
  selector: 'app-taskdetails',
  templateUrl: './taskdetails.component.html',
  styleUrls: ['./taskdetails.component.css']
})
export class TaskdetailsComponent implements OnInit, ComponentCanDeactivate{

@ViewChild(NgForm) LeadTask:NgForm

  taskForm: any = FormGroup;
  submitted = false;
  // public userassignedid: any;
  public filestabData: any = [];
  public specificfileData: any = [];
  public assignedClientData: any = [];
  public assigntabData: any = [];
  public assignuserData: any = [];
  public clientDataId: any;
  public LeadDataId: any;

  userloggedIn: any
  myuserType: any;
  currentid: any;
  userInfoList: any
  userInfo: any
  getListagentBroker: any;
  leadlandLister: any
  public taskTabModel: any = {};
  userTypeId: any
  usertypeId: any
  currentLoggedUserId: any
  agentids: any
  agentId: any
  myleadId: any
  clientTaskId: any;
  id: any = {};
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    min: moment(new Date())
  };
  updateTask: Boolean = false;
  routeedittasktab: any = false;
  pageType: any;
  //YYYY-MM-DD
  max = moment(new Date()).format('MM/DD/YYYY');
  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private _manager_service: ManagerService, private tokenStorage: TokenService, private route: Router, private activated_route: ActivatedRoute, private toastr: ToastService, public location: Location) { }

  ngOnInit(): void {

    this.routeedittasktab = sessionStorage.getItem('edittasktab');

    this.myuserType = this.tokenStorage._get_userRole();
    this.usertypeId = this.tokenStorage._get_userTypeId();
    this.currentid = sessionStorage.getItem('userTypeId');
    this.userInfo = this.tokenStorage._get_userInfo();

    this.userInfoList = JSON.parse(this.userInfo);

    this.userTypeId = this.userInfoList.UserTypeId;
    this.currentLoggedUserId = this.userInfoList.CurrentLoggedUserId;
    console.log(this.userTypeId, 'userTypeId')

    //edit
    this.id = this.activated_route.snapshot.queryParamMap.get('id');
    this.pageType = this.activated_route.snapshot.queryParamMap.get('type');
    if (this.id !== null || undefined) {
      // this.taskForm.clientTaskId = this.id;
      // console.log(this.taskForm.clientTaskId, "jeeevithaaaaaa");
      this.taskForm = true;
      this.updateTask = true;
      //clientTask
      if (this.pageType == 'lead') {
        this._getTaskLeadByid(this.id)
      } else {
        this._getTask_byId(this.id);
      }

      //LeadTask
      //this._getTaskLeadByid(this.id)
    }

    //if (this.LeadDataId !== NaN) {
    //this._getTask_byId(this.id)
    //} else {
    // this._getTaskLeadByid(this.id)
    //}


    //edit

    // {
    console.log("max ", this.datePickerConfig);
    //   "clientId": 1,
    //     "assignedUserId": 3,
    //       "assignedTabs": "2",
    //         "priority": "high",
    //           "description": "jhgdelkQS",
    //             "userTypeId": 5,
    //               "createdBy": "test"
    // }
    this.taskForm = this.formBuilder.group({
      clientId: ['', Validators.required],
      assignedUserId: ['', Validators.required],
      tabId: ['', Validators.required],
      priority: ['', Validators.required],
      description: ['', Validators.required],
      userTypeId: ['', Validators.required],
      completionDate: ['', Validators.required],
      taskStatus: [''],
      clientTaskId: [''],
      createdBy: sessionStorage.getItem('userTypeId')
    });
    this.getclientFileList();
    this.getGetfiletab();
    this.getAssignedUserTypeByClient();
    this._getLandByIdLead();
    this.getAgentsForBroker();
    //this.getAssignedUserType();

  }

  get f() { return this.taskForm.controls; }


  onSubmitLeadTask(type?: any) {
    // alert(this.updateTask+'-----'+this.pageType)
    console.log("type ", type, this.updateTask);
    console.log("this.taskTabModel ", this.taskTabModel);
    // this.taskTabModel['completionDate'] = this.datePipe.transform(new Date(this.taskTabModel.completionDate._d), 'MM/dd/yyyy');
    this.taskTabModel['completionDate'] = moment(new Date(this.taskTabModel.completionDate)).format('MM/DD/YYYY');
    console.log(this.taskTabModel, 'TaskTabModel');
    if (this.pageType == 'lead' && this.updateTask == true) {
      // alert(1)
      this.updateLeadTask()
    } else if (this.pageType == null && this.updateTask == true) {
      // updateClient Task
      // alert(2)
      this.onSubmit(true);
    }
    else if (this.pageType == null && this.updateTask == false) {
      this.GetaddOrUpdateLeadTask()
    }
  }


  onSubmit(isBrokerAgent?: Boolean) {


    // alert(this.updateTask)
    if (isBrokerAgent) {
      this.taskForm.value = this.taskTabModel;
      console.log('tsdkformbrokrt/agent', this.taskForm.value)
    }
    this.submitted = true;
    // alert(this.updateTask)
    // stop here if form is invalid
    if (this.taskForm.invalid) {
      return;
    }
    // let CurrentUserId: any;
    // CurrentUserId = this.tokenStorage._get_userTypeId();
    // this.taskForm.value.leadId = parseInt(CurrentUserId);
    this.taskForm.value['createdOn'] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    this.taskForm.value.clientId = parseInt(this.taskForm.value.clientId);
    this.taskForm.value.assignedUserId = parseInt(this.taskForm.value.assignedUserId);
    this.taskForm.value.tabId = parseInt(this.taskForm.value.tabId);
    this.taskForm.value.userTypeId = parseInt(this.taskForm.value.userTypeId);
    this.taskForm.value.clientTaskId = parseInt(this.id);

    // this.route.navigate(['/superadmin/task-list']);


    if (this.updateTask == false || undefined || null) {
      console.log(this.taskForm.value, this.taskTabModel)
      // alert(1)
      // this.taskForm.value.taskStatus = "Yet to start";
      delete this.taskForm.value.clientTaskId;
      this._manager_service.add_clienttask(this.taskForm.value).subscribe((taskinfo: any) => {
        console.log(taskinfo, "add DATA")
        this.taskForm.markAsUntouched();
            if (this.routeedittasktab == 'true') {
      this.route.navigateByUrl('superadmin/client-file-status');
    } else {
      this.route.navigateByUrl('/superadmin/task-list', { state: { obj: this.taskForm.value.assignedUserId } })
    }
        if (taskinfo.reponse == true) {
          this.toastr.showSuccess('Task Created');
        } else {
          this.toastr.showError('ERROR OCCURED');
        }
      })
    }

    //update
    if (this.updateTask == true) {
      // alert(2)
      // console.log(this.tas,"clienttask")
      this._manager_service._get_taskupdateByid(this.taskForm.value).subscribe((data: any) => {
        //console.log(this.taskForm.clientTaskId, "jeeevithaaaaaa");
        this.taskForm.markAsUntouched();
        console.log(data, "updatedata");
            if (this.routeedittasktab == 'true') {
      this.route.navigateByUrl('superadmin/client-file-status');
    } else {
      this.route.navigateByUrl('/superadmin/task-list', { state: { obj: this.taskForm.value.assignedUserId } })
    }
        if (data.reponse == true) {
          this.toastr.showSuccess('Task Updated');
        } else {
          this.toastr.showError('ERROR OCCURED');
        }
      })
    }
    //update

  }

  onReset() {
    this.submitted = false;
    this.taskForm.reset();
  }
  /*New chnages*/
  _getLandByIdLead() {
    this._manager_service.getLandByIdLead(this.currentLoggedUserId).subscribe((data: any) => {
      console.log(data, 'dataaa');
      this.leadlandLister = data.listData;
      console.log(this.leadlandLister, "dataList")
    })
  }
  onselectClient(e: any) {
    this.myleadId = e.target.value;
    this.clientDataId = e.target.value
  }
  getAgentsForBroker() {
    this._manager_service.GetAgentsForBroker(this.usertypeId).subscribe((resp: any) => {
      this.getListagentBroker = resp.listData;
      console.log(this.getListagentBroker, 'List agentfor broker');
    });
  }
  onChangeTask(event: any) {
    console.log(event, 'listsel');
    console.log(event.target.value, 'listsel9999');
    console.log(this.currentLoggedUserId, 'CurrentLoggedId');
    // this.agentId = event.target.value;

    this.agentids = event.target.value;
    this.taskTabModel['leadId'] = Number(this.myleadId);
    if (this.agentids == 0) {
      //alert('0');
      this.taskTabModel['assignedUserId'] = Number(this.currentLoggedUserId);
      this.taskTabModel['userTypeId'] = Number(this.userTypeId);

    } else {
      // alert('1');
      this.taskTabModel['assignedUserId'] = Number(this.agentids);
    }
    console.log(this.agentids, 'listagentIDs');

    //this.userTypeId = AssignName;
    console.log(this.agentId, 'assigeName');
    //console.log(this.userTypeId, 'assigeName')
  }

  GetaddOrUpdateLeadTask() {
    console.log(this.taskTabModel, 'TaskTabModel-----');

    if (this.updateTask) {
      this.updateLeadTask();
    } else {
      if (this.myuserType == 'Agent') {
        this.taskTabModel['assignedUserId'] = Number(this.currentLoggedUserId);
      }
      this.taskTabModel['createdBy'] = String(this.currentLoggedUserId);
      this.taskTabModel['assignedUserId'] = parseInt(this.taskTabModel['assignedUserId']);
      //this.taskTabModel['userTypeId'] = String(this.userTypeId);
      console.log(this.taskTabModel, 'TaskTabModel-----');
      this._manager_service
        .addOrUpdateLeadTask(this.taskTabModel)
        .subscribe((resp: any) => {
          console.log(resp, 'DataLeadTask');
          if (resp.reponse) {
            this.toastr.showSuccess(resp.message);
            this.route.navigate(['/superadmin/task-list']);
          } else {
            this.toastr.showError(resp.message);
          }
        });
    }




  }



  updateLeadTask() {
    if (this.myuserType == 'Agent') {
      this.taskTabModel['assignedUserId'] = Number(this.currentLoggedUserId);
    }
    this.taskTabModel['createdBy'] = String(this.currentLoggedUserId);
    console.log('updateLeadTask==>', this.taskTabModel);
    this.taskTabModel["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm');
    this._manager_service
      .updateLeadTask(this.taskTabModel)
      .subscribe((resp: any) => {
        console.log(resp, 'DataLeadTask');
        if (resp.reponse) {
          this.toastr.showSuccess(resp.message);
          this.LeadTask.form.markAsUntouched();
          this.route.navigate(['/superadmin/task-list']);
        } else {
          this.LeadTask.form.markAsUntouched();
          this.toastr.showError(resp.message);
        }
      });
  }


  /**New Changes */
  getclientFileList() {
    let CurrentUserId: any;

    CurrentUserId = this.tokenStorage._get_userTypeId();
    this.userloggedIn = this.tokenStorage._get_userRole();
    console.log(this.userloggedIn, "dropdownValyeee")
    console.log(CurrentUserId, "hi cuser ")
    this._manager_service.get_Clientfileno(CurrentUserId).subscribe((res: any) => {
      this.filestabData = res.listData;
      console.log("this.filestabData ", this.filestabData);
      //this.getAssignedUserTypeByClient(res.listData[0].Clientid);
    })
  }

  getGetfiletab() {
    this._manager_service.get_Getfiletab().subscribe((res: any) => {
      this.specificfileData = res.listData;
    })
  }

  getAssignedUserTypeByClient() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manager_service.get_AssignedUserTypeByClient(CurrentUserId).subscribe((res: any) => {
      this.assignedClientData = res.listData;
    })
  }

  // getAssignedUserType() {
  //   this._manager_service.get_AssignedUserType().subscribe((res: any) => {
  //     this.assigntabData = res.listData;
  //     this.getAssignedUser(res.listData[0].userTypeId);
  //     // this.userassignedid = res.listData[0].userTypeId
  //     // console.log(res.listData[0].userTypeId, "test");

  //   })
  // }

  // getAssignedUser(id: number) {
  //   this._manager_service.get_AssignedUser(id).subscribe((res: any) => {
  //     this.assignuserData = res.listData;

  //   })
  // }

  getAssignedUser(id: any) {

    let userObject = {
      "clientid": parseInt(this.clientDataId),
      "userTypeID": parseInt(id),
      "CurrentUserId": parseInt(this.currentid)
    }
    console.log("userObject ", userObject);

    this._manager_service.get_AssignedUser(userObject).subscribe((res: any) => {
      this.assignuserData = res.listData;
    })
  }

  onselectAssign(assignedVal: any) {
    this.getAssignedUser(assignedVal.target.value);
    console.log(assignedVal, "assigned");
  }


  _getTask_byId(id: number) {

    this._manager_service._get_taskByid(id).subscribe((data: any) => {
      console.log(data, "ClientData123");
      var Obj: any = data['getData'];
      Obj.completionDate = moment(new Date(Obj.completionDate)).format('MM/DD/YYYY');
      console.log(Obj.completionDate, "objjj")
      console.log(data['getData'])
      this.clientDataId = parseInt(data.getData.clientId)
      console.log(this.clientDataId, "clientid")
      console.log(this.currentid, "currentid")
      //this.taskForm = data['getData'];
      this.getAssignedUser(data.getData.userTypeId);
      // this.getAssignedUser(assignedVal.target.value);
      data.getData.taskStatus = data.getData.taskStatus ? data.getData.taskStatus : "--";
      //this.taskForm.patchValue(data.getData);
      this.taskForm.patchValue(Obj);
      this.taskTabModel = Obj;
      console.log(Obj, "obj response")
      console.log(this.taskTabModel, "response");

    })
  }

  _getTaskLeadByid(id: number) {

    this._manager_service._get_taskLeadByid(id).subscribe((data: any) => {
      console.log('_getTaskLeadByid', data);
      var Obj: any = data['getData'];
      Obj.completionDate = moment(new Date(Obj.completionDate)).format('MM/DD/YYYY');
      console.log(Obj.completionDate, "objjj")
      console.log(data['getData'])
      this.clientDataId = parseInt(data.getData.clientId)
      console.log(this.clientDataId, "clientid")
      console.log(this.currentid, "currentid")
      //this.taskForm = data['getData'];
      this.getAssignedUser(data.getData.userTypeId);
      // this.getAssignedUser(assignedVal.target.value);
      data.getData.taskStatus = data.getData.taskStatus ? data.getData.taskStatus : "--";
      //this.taskForm.patchValue(data.getData);
      this.taskForm.patchValue(Obj);
      Obj.assignedUserId = this.myuserType == 'Agent' ? 0 : Obj.assignedUserId;
      this.taskTabModel = Obj;
      console.log(Obj, "obj response")
      console.log(this.taskTabModel, "response");

    })
  }

  ngOnDestroy() {
    sessionStorage.removeItem('edittasktab');
    this.routeedittasktab = false;
  }
  canDeactivate():any {
    
    return !this.taskForm.touched;
}

   


}
