import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';

@Component({
  selector: 'app-numberoffile-report',
  templateUrl: './numberoffile-report.component.html',
  styleUrls: ['./numberoffile-report.component.css']
})
export class NumberoffileReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;
  public reportName: any = 'numberoffile';


  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true
  };

  //DropDown
  public numberoffileData: any = [];

  //list
  public numberofFileListData: any = [];
  public numberofFileobjlst: any = {};

  //obj
  public numberofFileOBj = {
    "fileStatusId": 0,
    "fromDate": '',
    "toDate": ''
  }
  statusIDData = '0';
  fileFromDateData = '';
  fileToDateData = '';
  noofReportData = '';


  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    this.numberofFileGetAllReport();
    this.numberofFileDropdown();
  }

  numberofFileDropdown() {
    this._manager_service.numberoffileReport_dropdown().subscribe((nofresponse: any) => {
      this.numberoffileData = nofresponse.listData;
      console.log(this.numberoffileData, "ffdataaaaa")
    })
  }


  numberofFileGetAllReport() {
    this._manager_service.numberoffileReport_getAllReport(this.numberofFileOBj).subscribe((res: any) => {
      // this.numberofFileobjlst = (res.getData != null) ? res.getData : [];
      this.numberofFileListData = (res.listData != null) ? res.listData : [];
      // console.log(this.numberofFileobjlst, "filesperClosureobjlst");
      console.log(this.numberofFileListData, "filesperClosureListData")
    })
  }

  numberoffileFilter() {
    this.numberofFileOBj.fileStatusId = this.statusIDData ? parseInt(this.statusIDData) : 0;
    this.numberofFileOBj.fromDate = this.fileFromDateData;
    this.numberofFileOBj.toDate = this.fileToDateData;
    this.numberofFileGetAllReport();
  }

  numberoffileReset() {
    this.numberofFileOBj.fileStatusId = 0;
    this.numberofFileOBj.fromDate = '';
    this.numberofFileOBj.toDate = '';
    this.statusIDData = '0';
    this.fileFromDateData = '';
    this.fileToDateData = '';
    this.numberofFileGetAllReport();
  }


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }

  downloadExcel() {

    this._file_upload.numberofFile_report();
  }
  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  nofsearchReport() {
    let searchnumberofFileOBj = {
      "fileStatusId": 0,
      "fromDate": '',
      "toDate": '',
      "word": this.noofReportData
    }
    this._manager_service.numberoffilsearchReport(searchnumberofFileOBj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.numberofFileListData = [];
      }
      else {
        this.numberofFileListData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }

}
