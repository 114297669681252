<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="configuration settings"></app-header>
    <div class="container-fluid contentpage_wrapper">

      <div class="pagecontent_wrapper">
        <form #notificationform="ngForm" (submit)="changeConfig(notificationform)">
          <div class="apex-white_wrap newheight-apx">
            <div class="apex-title-lilblue">
              <h3>set configuration</h3>
            </div>
            <div class="col-12">
              <div class="two_factor_wrapper">
                <label>Two Factor Authentication</label>
                <!-- <div class="onoffswitch">
                  <input type="checkbox" class="onoffswitch-checkbox" [(ngModel)]="myConfigSetting.twoFactorAuth" name="twofactor"
                    class="onoffswitch-checkbox" id="myonoffswitch" tabindex="0" checked>
                  <label class="onoffswitch-label" for="myonoffswitch"></label>
                </div> -->
                <div class="onoffswitch">
                  <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" [(ngModel)]="myConfigSetting.twoFactorAuth"
                   name="twofactor" id="myonoffswitch" tabindex="0" (click)="resetType()" checked>
                  <label class="onoffswitch-label" for="myonoffswitch">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                  </label>
              </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
              <div class="apexinput-froms apex_bg_white block_lablels">
                <label>Set two factor authentication type</label>
                <div class="radio_flex">
                  <div class="radiobuttons">
                    <div class="rdio rdio-primary radio-inline">
                      <input name="optradio" value="sms" [(ngModel)]="myConfigSetting.authType" id="radio380" type="radio" (click)="changeConfig('sms')" [attr.disabled]="myConfigSetting.twoFactorAuth == false ? true: null">
                      <label for="radio380">SMS</label>
                    </div>
                  </div>
                  <div class="radiobuttons">
                    <div class="rdio rdio-primary radio-inline">
                      <input name="optradio" value="email" [(ngModel)]="myConfigSetting.authType" id="radio400" type="radio" (click)="changeConfig('email')" [attr.disabled]="myConfigSetting.twoFactorAuth == false? true: null">
                      <label for="radio400">Email</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apxbtn_finalcenter mt_60">
              <!-- <button class="btn_grey" routerLink="/superadmin/dashboard">Cancel</button> -->
              <button type="reset" class="btn_grey" routerLink="/superadmin/dashboard">Back</button>
              <button class="btn_dblue" type="submit">Update</button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </main>
</div>
