<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Reports">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>All open files / short sales</h2>
        </div>
      </div>
      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap">
          <div class="filterapx_wrap report_wrap_fil">
            <div class="filter_setting_forms">
              <h3>Filter By</h3>
              <div class="apxsilsetting_group">
                <label>User Type</label>
                <!-- <input type="text" value="George"> -->
                <!-- <select class="form-control" [(ngModel)]="userTypeId" (change)="getUserListById($event)">
                    <option value="0">--All--</option>
                    <option *ngFor="let item of userType" [value]="item.userTypeId">{{item.userTypeName}}
                    </option>
                  </select> -->

                <mat-form-field appearance="fill">
                  <!-- <mat-label>--Select--</mat-label>-->
                  <mat-select [(ngModel)]="userTypeId" (selectionChange)="getUserListById($event.value)"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option>--Select--</mat-option>
                    <input type="text" (keyup)="onSearchUsersType($event)" (keydown)="$event.stopPropagation()">
                    <mat-option *ngFor="let item of userType" [value]="item.userTypeId">
                      {{item.userTypeName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- <input type="text" value="George" [(ngModel)]="firstname"> -->
              </div>


              <div class="apxsilsetting_group">
                <label>User Name</label>
                <!-- <select class="form-control" [(ngModel)]="userIdData">
                    <option value="0">--All--</option>
                    <option *ngFor="let item of userList" [value]="item.userId">
                      {{item.userName}}
                    </option>
                  </select> -->
                <mat-form-field appearance="fill">
                  <mat-select [(ngModel)]="userIdData" [ngModelOptions]="{standalone: true}">
                    <input type="text" (keyup)="onSearchUsers($event)" (keydown)="$event.stopPropagation()">
                    <mat-option value="0">--All--</mat-option>
                    <mat-option *ngFor="let item of userList" [value]="item.userId">
                      {{item.userName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="apxsilsetting_group ">
                <label class="report_date">From Date</label>
                <dp-date-picker theme="dp-material" [(ngModel)]="userUsageOBj.fromdate" [config]="datePickerConfig"
                  required="true">
                </dp-date-picker>
                <!-- <input type="text" value="Bailey" [(ngModel)]="lastname"> -->
              </div>
              <div class="apxsilsetting_group">
                <label class="report_date">To date</label>
                <dp-date-picker theme="dp-material" [(ngModel)]="userUsageOBj.todate" [config]="datePickerConfig"
                  required="true">
                </dp-date-picker>
              </div>
              <!-- <div class="apxsilsetting_group">
                  <label>Open/Close</label>
                  <select class="form-control" [(ngModel)]="openClose">
                    <option value="0">--All--</option>
                    <option value="1">--Close--</option>
                    <option value="2">--Open--</option>
                  </select>

                </div>
                <div class="apxsilsetting_group">
                  <label>From Date</label>
                  <dp-date-picker theme="dp-material" [(ngModel)]="trendFromDateData" [config]="datePickerConfig"
                    required="true">
                  </dp-date-picker>

                </div>
                <div class="apxsilsetting_group">
                  <label>To date</label>
                  <dp-date-picker theme="dp-material" [(ngModel)]="trendToDateData" [config]="datePickerConfig"
                    required="true">
                  </dp-date-picker>
                </div> -->
            </div>
            <div class="filter_setting">
              <ul>
                <!-- <li class="filter_apx"><img src="../../../assets/images/filter.png" alt="filter" (click)="filterList()">
                  </li> -->
                <li class="filter_apx"><img src="../../../assets/images/filter.png" alt="filter"
                    (click)="userUsageFilter()">
                </li>
                <li class="setting_reset"><img src="../../../assets/images/setting_reset.png" alt="filter"
                    (click)="userUsageReset()"></li>
              </ul>
            </div>
          </div>

        </div>
        <div class="apxtable_grids_wrapper">
          <div class="apxtable_grid_head">
            <h2>List of Reports</h2>
            <select class="form-control sel-frm-control" (change)="onselectReport($event)">
              <option value="">--All--</option>
              <option value="report-short-sales">All open files/short sales </option>
              <option value="closed-file-report">All closed files</option>
              <option value="numberoffile">Number of file per stage</option>
               <option value="openclose-report">Open/closed files per</option>
              <option value="filesperfor-closure-report">Files per foreclosure date in the next 30 days
              </option>
              <option value="trend-report">Trend Report</option>
              <option value="user-usage-report">User usage report</option>
              <!-- <option value="user-usage-date-report">User usage report - date range search ability
              </option> -->
              <option value="success-rate-report">Success rate report</option>
              <option value="financial-reportt">Financial Report</option>
              <option value="lead-success-rate-report">Lead Success rate report</option>
<option value="user-report">User report</option>
<option value="file-data-report">File data report</option>
              <option value="cancel-file-report">Report for cancel file</option>
            </select>
          </div>
          <div class="apxtable_grid_table">
            <div class="table-responsive apx-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      Last Login Date Time
                    </th>
                    <th>
                      Hours Spent Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ userSpend.lastLoginDateTime || '--'}}</td>
                    <td>{{ userSpend.hoursSpentDetails || '--'}}</td>
                  </tr>

                </tbody>
              </table>
              <div class="nodata_found_components no_data_found" *ngIf="!userSpend.lastLoginDateTime">
                <div colspan="6">No Data found</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </main>

</div>



<!-- The Modal -->
<div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel">Cancel</button>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="filterforms_setting">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>First Name</label>
                <input type="text" placeholder="First name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Last Name</label>
                <input type="text" placeholder="Last name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Status</label>
                <select>
                  <option value="">All</option>
                  <option value="">All-1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>



<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <img src="../../../assets/images/danger.png" alt="danger" />
          <p>Are you sure want to delete the data?</p>
          <div class="filterbt_btnn btn_deletecard">
            <button class="btnfilter-cancel">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
