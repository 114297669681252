import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../service/auth-service.service';
import { TokenService } from '../../service/token.service';
import { ToastrService } from 'ngx-toastr';
import { first, take } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { alphaNumericValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
// import { ManagerService } from 'src/app/service/manager.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class  LoginComponent implements OnInit {
  loginData: any = {};
  login_Response: any = {};
  isSignedArrays: any;
  isSigned: any;
  isSignedbBroker: any;
  public state: any;
  userRouteInfo: any;
  termCheck=false;
  constructor(
    private auth: AuthServiceService,
    private toast: ToastrService,
    private tokenStorage: TokenService,
    private route: Router,
    private spinner: NgxSpinnerService

  ) { }
   
  ngOnInit(): void {
  
    this.spinner.hide()
   }

  _login_func() {
    console.log(this.loginData);
    this.auth.setUserLoggedIn(true)
    this.loginData.email = this.loginData.email.trim(),
    this.loginData.password = this.loginData.password.trim(),
    this.loginData.IsTermsChecked = this.loginData.IsTermsChecked?this.loginData.IsTermsChecked:false,
    
    
    this.auth
      ._login_service(this.loginData)
      .pipe(first())
      .subscribe(
        (data: any) => {
          //debugger;
          if (data.response == true) {
            this.login_Response = data;
            console.log(data, 'login_resp')

            // console.log(this.login_Response.userInfoBackEnd)
            if (
              this.login_Response.response == true &&
              this.login_Response.userInfoBackEnd
            ) {

              // console.log(this.login_Response.userInfoBackEnd['BrokerAgreementSigned'].IsSigned, 'userRoutedetails');
              this.tokenStorage._save_token(this.login_Response.token);
              this.tokenStorage._save_term(this.login_Response.IsTermsChecked);
              this.tokenStorage._save_userRole(
                this.login_Response.userInfoBackEnd.UserTypeName
              );
              this.tokenStorage._set_email(
                this.login_Response.userInfoBackEnd.EmailId
              );
              this.tokenStorage._set_userTypeId(
                this.login_Response.userInfoBackEnd.CurrentLoggedUserId
              );
              sessionStorage.setItem(
                'userInfo',
                JSON.stringify(this.login_Response.userInfoBackEnd)
              );
              sessionStorage.setItem(
                'propic',
                this.login_Response.userInfoBackEnd.ProfilePicpath
              );
              sessionStorage.setItem(
                'proname',
                this.login_Response.userInfoBackEnd.FullName
              );

              if (this.login_Response.userInfoBackEnd.UserTypeName == 'Agent' || this.login_Response.userInfoBackEnd.UserTypeName == 'Broker'
               || this.login_Response.userInfoBackEnd.UserTypeName == 'Negotiator' ||  this.login_Response.userInfoBackEnd.UserTypeName == 'Manager' ||  this.login_Response.userInfoBackEnd.UserTypeName == 'AsstNegotiator' ||  this.login_Response.userInfoBackEnd.UserTypeName == 'Closer' ||   this.login_Response.userInfoBackEnd.UserTypeName == 'HomeOwner' ) {
                // alert(1)
                this.userRouteInfo =
                  this.login_Response.userInfoBackEnd['BrokerAgreementSigned'] ||
                  this.login_Response.userInfoBackEnd['AgentAgreementDetails'];
                // console.log(this.userRouteInfo.IsSigned, 'userRoutedetails');
                if (this.userRouteInfo && this.userRouteInfo.IsSigned == false) {
                  this.toast.success(this.login_Response.message);
                  this.route.navigate(['/superadmin/login-agreement']);
                }
                else if (this.login_Response.userInfoBackEnd.ChangePasswordFlag == true) {
                  this.route.navigate(['/superadmin/change-password'])
                }
                else if(this.login_Response.userInfoBackEnd['IsTermsChecked']==false){
                 this.termCheck=true;
                 
               }
                else if(this.login_Response.userInfoBackEnd['IsTermsChecked']==true 
               ){
                  
                  this.route.navigate(['/superadmin/dashboard']);
                  this.toast.success(this.login_Response.message);
                }


                else {
                  this.toast.success(this.login_Response.message);
                  this.route.navigate(['/superadmin/dashboard']);

                }



              } 
              else if(this.login_Response.userInfoBackEnd['IsTermsChecked']==false){
                this.termCheck=true;
                
              }
               else if(this.login_Response.userInfoBackEnd['IsTermsChecked']==true){
                 
                 this.route.navigate(['/superadmin/dashboard']);
                 this.toast.success(this.login_Response.message);
               }
            
              else {
                this.route.navigate(['/superadmin/dashboard']);
                this.toast.success(this.login_Response.message);
              }
          

            } else if (this.login_Response.otpFlag == true) {
              this.toast.success(this.login_Response.message);
              this.route.navigateByUrl('/two-factor-authentication', {
                state: { loginAuth: this.loginData },
              });

              if (this.login_Response.otpFlag == false && this.login_Response.userInfoBackEnd.ChangePasswordFlag == true) {
                this.route.navigate(['/superadmin/change-password']);
              }


            }

            else if (this.login_Response.userInfoBackEnd.ChangePasswordFlag == true) {
              this.route.navigate(['/superadmin/change-password']);
            }

          } else {
            this.toast.error(data.message);
          }

        },
        (err) => {
          this.toast.error('something went wrong');
        }
      );
  }

  change(e:any){
    console.log("e",e)
    if(e==true){
      this.termCheck=false
    }
    else{
      this.termCheck=true
    }
    
  }
}
