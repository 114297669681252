import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';

@Component({
  selector: 'app-filesperfor-closure-report',
  templateUrl: './filesperfor-closure-report.component.html',
  styleUrls: ['./filesperfor-closure-report.component.css']
})
export class FilesperforClosureReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;
  public reportName: any = 'filesperfor-closure-report';

  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    max: moment(new Date()),
  };
  //DropDown
  public filesperClosureData: any = [];

  //list
  public filesperClosureListData: any = [];
  public filesperClosureobjlst: any = {};

  //obj
  public filesperClosureOBj = {
    "clientId": 0,
    "fromdate": '',
    "todate": '',
    "updatedy": this.tokenStorage._get_userTypeId()
  }

  clientId = '0'
  filesperclosureReport = ''
  FromDateData = '';
  ToDateData = '';
  // closureData = ''
  usertype: any;
  shortsaleDData: any;
  
  shortsaleDataBack: any=[];
  searchfilesperClosureListData: any;
  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    this.usertype = this.tokenStorage._get_userRole();
    this.filesperClosureGetAllReport();
    this.filesperClosureDropdown();

  }

  filesperClosureDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.shortsale_dropdown(CurrentUserId).subscribe((response: any) => {
      this.shortsaleDData = response.listData;
      this.shortsaleDataBack = response.listData;
      console.log(this.shortsaleDData, "sssssaaaaa")
    })
  }



  filesperClosureGetAllReport() {

    var userTypeId:any=sessionStorage.getItem('userTypeId');
    var sendData={};
    if(userTypeId==4){
      var data = {
        "clientId": this.clientId?parseInt(this.clientId):0,
        "fromdate": this.FromDateData?this.FromDateData:"",
        "todate": this.ToDateData?this.ToDateData:"",
        "currentUserId":this.tokenStorage._get_userTypeId(),
        "updatedy": this.tokenStorage._get_userTypeId()

        
      }
      sendData=data;
      this._manager_service.filesperClosure_getAllReportBroker(sendData).subscribe((res: any) => {
        // this.filesperClosureobjlst = (res.listData != null) ? res.listData : [];
        this.filesperClosureListData = (res.listData != null) ? res.listData : [];
        this.searchfilesperClosureListData = (res.listData != null) ? res.listData : [];
        //console.log(this.filesperClosureobjlst, "filesperClosureobjlst");
        console.log(this.filesperClosureListData, "filesperClosureListData")
      })
    }
    else{
      var dataone = {
        "clientId": this.clientId?parseInt(this.clientId):0,
        "fromdate": this.FromDateData?this.FromDateData:"",
        "todate": this.ToDateData?this.ToDateData:"",
        "updatedy": this.tokenStorage._get_userTypeId()
        
      }
      sendData=dataone;
      this._manager_service.filesperClosure_getAllReport(sendData).subscribe((res: any) => {
        // this.filesperClosureobjlst = (res.listData != null) ? res.listData : [];
        this.filesperClosureListData = (res.listData != null) ? res.listData : [];
        this.searchfilesperClosureListData = (res.listData != null) ? res.listData : [];
        //console.log(this.filesperClosureobjlst, "filesperClosureobjlst");
        console.log(this.filesperClosureListData, "filesperClosureListData")
      })
    }


  }


  filesperClosureFilter() {
    // this.filesperClosureOBj.foreClosureDate = this.closureData;
    this.filesperClosureOBj.clientId = parseInt(this.clientId);
    this.filesperClosureOBj.fromdate = this.FromDateData;
    this.filesperClosureOBj.todate = this.ToDateData;
    console.log(this.filesperClosureOBj, "list");
    this.filesperClosureGetAllReport();
  }

  filesperClosureReset() {
    // this.filesperClosureOBj.foreClosureDate = ''
    this.filesperClosureOBj.clientId = 0,
      this.clientId = '0',
      this.FromDateData = '';
    this.ToDateData = '';
    // this.closureData = ''
    console.log(this.filesperClosureOBj, "list");
    this.filesperClosureGetAllReport();
  }

  downloadExcel() {
    var userTypeId:any=this.tokenStorage._get_userTypeId();
    var sendData={};
    if(userTypeId==4){
      var data = {
        "clientId": this.clientId?parseInt(this.clientId):0,
        "fromdate": this.FromDateData?this.FromDateData:"",
        "todate": this.ToDateData?this.ToDateData:"",
        "currentUserId":this.tokenStorage._get_userTypeId(),
        "updatedy": this.tokenStorage._get_userTypeId()

        
      }
      sendData=data;
      this._file_upload.filesperClosure_report_broker(sendData);
    }
    else{
      var dataone = {
        "clientId": this.clientId?parseInt(this.clientId):0,
        "fromdate": this.FromDateData?this.FromDateData:"",
        "todate": this.ToDateData?this.ToDateData:"",
        "updatedy": this.tokenStorage._get_userTypeId()
        
      }
      sendData=dataone;
      this._file_upload.filesperClosure_report(sendData);
    }


  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }

  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  fileperclosuresearchReport() {
    let searchfilesperClosureOBj = {
      "clientId": 0,
      "updatedy": this.tokenStorage._get_userTypeId(),
      "word": this.filesperclosureReport
    }
    this._manager_service.filesperClosuresearchReport(searchfilesperClosureOBj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.filesperClosureListData = [];
      }
      else {
        this.filesperClosureListData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }
  onSearchTitle(e: any) {
    this.shortsaleDData = this.shortsaleDataBack.filter((item: any) => item.clientName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.filesperClosureListData = self.searchfilesperClosureListData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }
}
