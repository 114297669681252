
/**ACCEPTED LEAD */
import { Component, OnInit, ViewChild, ViewRef } from '@angular/core';

import { FormGroup, FormArray, FormBuilder, NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
//**service */
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
declare var $: any;
import { DatePipe } from '@angular/common';

//**MODEL */
import { leadmodel, HomeOwner, leadAssign } from '../../models/lead.model';


@Component({
  selector: 'app-lead-list-edit',
  templateUrl: './lead-list-edit.component.html',
  styleUrls: ['./lead-list-edit.component.css'],

})
export class LeadListEditComponent implements OnInit {
  // leads? :leadmodel;

  @ViewChild(NgForm) leadForm: NgForm;
  @ViewChild(NgForm) rejectForm: NgForm;

  nestedFormGroup: any = FormGroup;
  datePickerConfig = { 'format': 'M/D/YYYY', disableKeypress: true };
  id: any;
  updateForm: Boolean = false;
  countrylist: any;
  statelist: any;
  citylist: any;
  userTypes: any;
  userLists: any;
  usertype: any;
  homeUserId: any;
  state: any;
  mailngaddress: any;
  getLeadData: any;
  userType: any;
  userTypeid: any;
  userFullName: any;
  //Mailing Selected Link
  private selectedLink: string = "Yes";
  myuserTypeId: any;
  //AcceptorReject
  public CurrentGetidLead: number | undefined;
  public acceptObj = {};
  public rejectObj = {};
  public Rejectmodel: any = {};
  openPopReject = false;

  agentBrokerDetails: any;
  agentBromodule: any;
  realtorPropertyAdrress: any;
  constructor(
    private datePipe: DatePipe,
    private route: Router,
    private tokenStorage: TokenService,
    private _manager_service: ManagerService,
    private activated_route: ActivatedRoute,
    private dropDown: DropdownService,
    private formBuilder: RxFormBuilder,
    private toastr: ToastService) { }


  //Mailing Selected Link
  setradio(e: string): void {


    this.selectedLink = e;

    console.log(this.selectedLink)
    if (this.selectedLink == 'No') {
      // alert("no")
      this.mailngaddress = '';
    } else {
      // alert("yes")

    }

  }



  isSelected(name: string): boolean {
    if (!this.selectedLink) { // if no radio button is selected, always return false so every nothing is shown
      this.mailngaddress = '';
      return false;
    }

    return (this.selectedLink === name); // if current radio button is selected, return true, else return false
  }

  //Mailing Selected Link

  ngOnInit() {
    
    console.log("nestedFormGroup", this.nestedFormGroup);

    this.getrealtorValueaddress();
    this.myuserTypeId = this.tokenStorage._userTypeId();
    // this.agentBromodule = window.history.state;
    // console.log(this.agentBromodule.obj,)
    // this.agentBrokerDetails = window.history.state.agentBroker;

    // this.nestedFormGroup.reset();
    this.usertype = this.tokenStorage._get_userTypeId();

    let leads = new leadmodel();
    leads.homeOwner = new HomeOwner();

    let leadchild = new leadAssign();
    leads.leadChildAssign = new Array<leadAssign>();

    // leads.leadChildAssign.push(leadchild);

    /**GET ROUTE PARAM ID */

    this.activated_route.params.subscribe((param: any) => {
      this.id = param['id'];
      if (param['id'] != null || undefined) {
        this.updateForm = true;
        this.getLead_byid(param['id'])
        this.getupdateLeadLastOpenedDetails(param['id'])
      }
    });
    // console.log(this.leads)

    this.nestedFormGroup = this.formBuilder.formGroup(leads);
    console.log(this.nestedFormGroup.value, "formvalue")

    this.getCountry();

    console.log(this.agentBrokerDetails, 'asssssssss898898')
    //this.getLead_byid(this.id);
    console.log(this.state, '9999999999999999999999999')
  }
  /**END */

  getupdateLeadLastOpenedDetails(leadid: any) {
    let leadObj = {
      "currentUserId": parseInt(this.myuserTypeId),
      "leadId": parseInt(leadid)
    }
    console.log(leadObj, 'dummyLead')
    this._manager_service.getUpdateLeadLastOpenedDetails(leadObj).subscribe((data: any) => {
      console.log(data, 'LeadList');
    })
  }

  getrealtorValueaddress() {
    var that = this;
    const settings = {
      "async": true,
      "crossDomain": true,
      "url": "https://realtor.p.rapidapi.com/properties/list-for-sale?city=New%20York%20City&offset=0&limit=200&state_code=NY&sort=relevance",
      "method": "GET",
      "headers": {
        "x-rapidapi-key": "37f4c01965msh15aa09999ddf207p1b6652jsn9a8087ec2fb5",
        "x-rapidapi-host": "realtor.p.rapidapi.com"
      }
    };

    $.ajax(settings).done(function (response: any) {
      console.log('realtor', response);
      that.realtorPropertyAdrress = response.listings;
    });

  }
  getpropertyid(e: any) {
    console.log(e.target.value);
    sessionStorage.setItem('propertyid', e.target.value)
  }
  get leadChilds() { return <FormArray>this.nestedFormGroup.controls.leadChildAssign; }
  /** GET LEAD BY ID */
  getLead_byid(id: number) {
    this._manager_service.getLead_Byid(id).subscribe((getLead: any) => {
      console.log("getLead ", getLead);

      //getting null value
      // this.nestedFormGroup.setValue(getLead.getData)
      this.getLeadData = getLead.getData.leadChildAssign;
      if (this.getLeadData && this.getLeadData.length > 0) {
        this.userType = this.getLeadData[this.getLeadData.length - 1].userfullName;
        this.userTypeid = this.getLeadData[this.getLeadData.length - 1].userTypeId;

      }

      // this.getupdateLeadLastOpenedDetails(getLead.getData.leadId);
      console.log(this.getLeadData, "leadDataaaass")
      // this.select_dropdown(1, "state", false);
      // this.select_dropdown(getLead.getData.homeOwner.stateId, 'city', false);
      this.homeUserId = parseInt(getLead.getData.homeOwner.homeUserId);
      this.nestedFormGroup.patchValue({ "phone1": getLead.getData.homeOwner.phone1, "phone2": getLead.getData.homeOwner.phone2, "phone3": getLead.getData.homeOwner.phone3, 'notes': getLead.getData.homeOwner.notes, 'spouse': getLead.getData.homeOwner.spouse, 'referedBy': getLead.getData.homeOwner.referedBy, 'stateName': getLead.getData.stateName });
      this.nestedFormGroup.controls.homeOwner.patchValue({ 'apn': getLead.getData.apn, 'stateName': getLead.getData.homeOwner.stateName });
      this.nestedFormGroup.controls.homeOwner.patchValue(getLead.getData.homeOwner);
      this.nestedFormGroup.patchValue(getLead.getData);
      this.leadChilds.push(this.formBuilder.group({ "userTypeId": getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userTypeId, "userId": getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userId }))
      this.select_dropdown(getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userTypeId, 'userType', false)
      //  this.nestedFormGroup.value.leadChildAssign.slice(0,1);
      console.log(this.nestedFormGroup.value, "dddddddd")
      console.log(getLead.getData)
    })
    this.CurrentGetidLead = Number(id)
    //console.log(id, '=<id')
  }
  /**END */


  /**Add ,Update LEAD  */
  addLead() {

    console.log(this.id)
    console.log('addLead', this.nestedFormGroup.value)
    var reqObj = this.nestedFormGroup.value;
    reqObj['leadId'] = parseInt(this.id) || 0;
    reqObj["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')
    reqObj.homeOwner.stateId = parseInt(reqObj.homeOwner.stateId);
    reqObj.homeOwner.emailId = reqObj.email1;
    reqObj.homeOwner.phone1 = reqObj.phone1;
    reqObj.homeOwner.phone2 = reqObj.phone2;
    reqObj.homeOwner.phone3 = reqObj.phone3;
    reqObj.homeOwner.phone4 = reqObj.phone4;
    reqObj.homeOwner['notes'] = reqObj.notes;
    reqObj.homeOwner['spouse'] = reqObj.spouse;
    reqObj.homeOwner['referedBy'] = reqObj.referedBy;
    reqObj.homeOwner.cityId = parseInt(reqObj.homeOwner.cityId);
    reqObj.cityId = parseInt(reqObj.cityId);
    reqObj.stateId = parseInt(reqObj.stateId);
    reqObj.countryId = parseInt(reqObj.countryId);
    reqObj['createdBy'] = this.usertype;
    reqObj['lotSize'] = parseInt(reqObj.lotSize) || 0;
    // reqObj.leadChildAssign[0].userTypeId = parseInt(reqObj.leadChildAssign[0].userTypeId);
    // reqObj.leadChildAssign[0].userId = parseInt(reqObj.leadChildAssign[0].userId);
    // if( reqObj.leadChildAssign[0].userTypeId == 0 &&   reqObj.leadChildAssign[0].userId == 0){
    //   reqObj.leadChildAssign = [];
    // }

    //** if Update by id */
    if (this.id) {
      console.log(reqObj, "assign from Req")
      this.updateForm = true;
      // reqObj.leadChildAssign = [];
      if (reqObj.leadChildAssign && reqObj.leadChildAssign.length > 0) {
        reqObj.leadChildAssign[0].userTypeId = parseInt(reqObj.leadChildAssign[0].userTypeId);
        reqObj.leadChildAssign[0].userId = parseInt(reqObj.leadChildAssign[0].userId);
      }
      reqObj.homeOwner['homeUserId'] = this.homeUserId;
      // alert("update");
      this._manager_service.update_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {

          this.toastr.showSuccess("Lead Updated");
          this.nestedFormGroup.markAsUntouched();
          this.route.navigate(['\lead-list'])
        } else { 
          this.nestedFormGroup.markAsUntouched();
          this.toastr.showError(data.message); }
      })
    }
    //** else Add  */
    else {
      for (let i = 0; i < reqObj.leadChildAssign.length; i++) {
        reqObj.leadChildAssign[i].userTypeId = parseInt(reqObj.leadChildAssign[i].userTypeId);
        reqObj.leadChildAssign[i].userId = parseInt(reqObj.leadChildAssign[i].userId);
      }
      // alert("create")
      this.updateForm = false;
      this._manager_service.add_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {
          this.toastr.showSuccess("Lead created");
          this.nestedFormGroup.markAsUntouched();
          this.route.navigate(['\lead-list'])
        } else {this.nestedFormGroup.markAsUntouched();
          this.toastr.showError(data.message);
        }
      })
    }
  }
  /** END */




  /**select dropdown */
  /**get Country */
  getCountry() {
    console.log(this.nestedFormGroup.value)
    this.dropDown._getAll_country().subscribe((data: any) => {
      this.countrylist = data.listData;
      console.log(this.countrylist, "compo")
      this.select_dropdown(1, 'state', false);
      // this.select_dropdown(1, 'city', false);


    })

    this.dropDown._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      console.log(this.userTypes, "ji")
    })

  }


  /** END */
  select_dropdown(id: any, selecteddata: any, htmlupdate?: Boolean) {

    let targetid;
    if (htmlupdate) {
      targetid = parseInt(id.target.value);

    } else {
      targetid = id;
    }



    // console.log(ids)
    if (selecteddata == 'state') {
      this.dropDown._getAll_state(targetid).subscribe((stateList: any) => {

        this.statelist = stateList.listData;
        console.log(stateList)
      })
    }
    // else if (selecteddata == 'city') {
    //   this.dropDown._getAll_city(targetid).subscribe((cityList: any) => {

    //     this.citylist = cityList.listData;
    //     console.log(this.citylist)
    //   })
    // }

    else if (selecteddata == 'userType') {
      this.dropDown._get_Userlist(targetid).subscribe((getuserlist: any) => {
        this.userLists = getuserlist.listData;
        console.log(this.userLists, "dsdsdsf")
      })


    }
  }

  /**end Dropdown */


  //AcceptorReject
  acceptLead() {
    this.acceptObj = {
      "acceptorReject": 1,
      "leadId": this.CurrentGetidLead
    }
    console.log(this.acceptObj, 'Acept')
    this._manager_service.getacceptLead(this.acceptObj).subscribe((acceptLead: any) => {
      if (acceptLead.reponse == true) {

        this.toastr.showSuccess("Lead Accepted Successfully")
        this.route.navigate(['superadmin/dashboard'])
      } else {
        this.toastr.showError("ERROR Occured")
      }
      console.log(acceptLead, 'accLead')
    })

  }

  //RejectLead
  getrejectLead() {
    //return id\
    this.openPopReject = true;
  }
  onsubimtReject(value: any) {
    this.rejectObj = {
      "acceptorReject": 0,
      "leadId": this.CurrentGetidLead,
      "rejectedNotes": value
    }
    console.log(this.rejectObj, 'rejL');
    this._manager_service.getrejectLead(this.rejectObj).subscribe((rejectLead: any) => {
      if (rejectLead.reponse == true) {
        this.openPopReject = false;
        this.toastr.showSuccess("Lead Rejected Successfully")
        this.route.navigate(['superadmin/dashboard'])
      } else {
        this.toastr.showError("ERROR Occured")
      }
    })

  }
  closetabModal() {
    this.openPopReject = false;
  }
  cancelReset() {
    this.nestedFormGroup.reset();
  }
  canDeactivate() { 
    return !this.nestedFormGroup.touched;
  }


}
