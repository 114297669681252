import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';



export interface CanComponentDeactivate {
  confirm(): boolean;
}
@Injectable()
export class CheckFormService implements 
CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {

      if(!component.confirm()) {
       
          if (confirm('You have unsaved changes! If you leave, your changes will be lost.')) {
          return true;
        } else {
          return false;
        }
      }
  }
}

