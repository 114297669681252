//modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './service/auth-guard.service';
import { PageNotFoundComponent } from './superadmin/page-not-found/page-not-found.component';
import { SuperadminModule } from './superadmin/superadmin.module';

// components
const routes: Routes = [
  { path: '', redirectTo: '/superadmin/dashboard', pathMatch: 'full' },
  { path: 'superadmin', loadChildren: './superadmin/superadmin.module#SuperadminModule' },
  { path: '**', component: PageNotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule, SuperadminModule]
})
export class AppRoutingModule { }
