export class clientfilestatus{

           fileStatusId?:number;
            clientId?:number;
            documentsCollectedFromAgent:number = 0;
            documentsCollectedFromHomeOwner:number= 0;
            listedForSale:number= 0;
            offerAcccepted:number= 0;
            bankRejectedoffer:number=0;
            bankNegotiatorAssgin:number=0;
            packageSubmittedtoBank:number= 0;
            lienHolder1:number= 0;
            lienHolder2:number= 0;
            lienHolder3:number= 0;
            lienHolder4:number= 0;
            lienHolder5:number= 0;
            bpocompleted:number= 0;
            fileSubmittedtoSetup:number= 0;
            departMent:number= 0;
            submittedwithApproval:number= 0;
            offerDenied:number= 0;
            shortsaleApproved:number= 0
            inEscrow:number= 0;
            closed:number= 0;
            forClosureDate="";
            notes: string="";
            isActive:Boolean=true;
            isDeleted:Boolean= true;
            fileLienHolder1:number= 0;
            fileLienHolder2:number= 0;
            fileLienHolder3:number= 0;
            fileLienHolder4:number= 0;
            fileLienHolder5:number= 0;
            orderLienHolder1:number= 0;
            orderLienHolder2:number= 0;
            orderLienHolder3:number= 0;
            orderLienHolder4:number= 0;
            orderLienHolder5:number= 0;
            completeLienHolder1:number= 0;
            completeLienHolder2:number= 0;
            completeLienHolder3:number= 0;
            completeLienHolder4:number= 0;
            completeLienHolder5:number= 0;
            approvalLienHolder1:number= 0;
            approvalLienHolder2:number= 0;
            approvalLienHolder3:number= 0;
            approvalLienHolder4:number= 0;
            approvalLienHolder5:number= 0;
            offerLienHolder1:number= 0;
            offerLienHolder2:number= 0;
            offerLienHolder3:number= 0;
            offerLienHolder4:number= 0;
            offerLienHolder5:number= 0;
            shortsaleLienHolder1:number= 0;
            shortsaleLienHolder2:number= 0;
            shortsaleLienHolder3:number= 0;
            shortsaleLienHolder4:number= 0;
            shortsaleLienHolder5:number= 0;
            negotiatorLienHolder1:number= 0;
            negotiatorLienHolder2:number= 0;
            negotiatorLienHolder3:number= 0;
            negotiatorLienHolder4:number= 0;
            negotiatorLienHolder5:number= 0;
            rejectedLienHolder1:number= 0;
            rejectedLienHolder2:number= 0;
            rejectedLienHolder3:number= 0;
            rejectedLienHolder4:number= 0;
            rejectedLienHolder5:number= 0;


    
}