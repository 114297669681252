<!-- (ngSubmit)=addEdit.form.valid&&_create_broker() #addEdit="ngForm" novalidate -->
<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Users"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Add / edit Broker detail</h2>
          <button class="btn_to_btitle">back</button>
        </div>
      </div>

      <form (ngSubmit)=addEdit.form.valid&&_create_broker() #addEdit="ngForm">
        <div class="pagecontent_wrapper">
          <div class="apex-white_wrap">
            <div class="apex-title-lilblue">
              <h3>basic details</h3>
            </div>

            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>First Name</label>
                    <input type="text" name="firstName" [(ngModel)]="addBroker.firstName" #firstName="ngModel"
                      placeholder="First Name" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && firstName.invalid}" required />
                    <div *ngIf="addEdit.submitted && firstName.invalid" class="invalid-feedback">
                      <div *ngIf="firstName.errors?.required">Firstname is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Last Name</label>
                    <input type="text" name="lastName" [(ngModel)]="addBroker.lastName" #lastName="ngModel"
                      placeholder="Last Name" value="" [ngClass]="{'is-invalid': addEdit.submitted && lastName.invalid}"
                      required />
                    <div *ngIf="addEdit.submitted && lastName.invalid" class="invalid-feedback">
                      <div *ngIf="lastName.errors?.required">Lastname is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Email Address</label>
                    <input type="email" name="emailId" [(ngModel)]="addBroker.emailId" #emailId="ngModel"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Email Address" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && emailId.invalid}" required />
                    <div *ngIf="addEdit.submitted && emailId.invalid" class="invalid-feedback">
                      <div *ngIf=" emailId.errors?.required">Email is required</div>
                      <div *ngIf="emailId.errors?.pattern">Email must be a valid email address</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Phone Number</label>
                    <input type="text" name="phone" [(ngModel)]="addBroker.phone" #phone="ngModel"
                      placeholder="Phone Number" minlength="10" maxlength="15" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && phone.invalid}" required />
                    <div *ngIf="addEdit.submitted && phone.invalid" class="invalid-feedback">
                      <div *ngIf="phone.errors?.required">Phone Number is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Company Name</label>
                    <input type="text" [(ngModel)]="addBroker.companyName" #companyName="ngModel" name="companyName"
                      placeholder="Company name" [ngClass]="{'is-invalid': addEdit.submitted && companyName.invalid}"
                      required>
                    <div *ngIf="addEdit.submitted && companyName.invalid" class="invalid-feedback">
                      <div *ngIf="companyName.errors?.required">CompanyName is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Status</label>
                    <select name="active" id="active" [(ngModel)]="addBroker.active" #active="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && active.invalid}" required>
                      <option [value]=true>Active</option>
                      <option [value]=false>InActive</option>
                    </select>
                    <div *ngIf="addEdit.submitted && active.invalid" class="invalid-feedback">
                      <div *ngIf="active.errors?.required">Status is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apxbtn_finalcenter">
              <button type="reset" class="btn_grey">Cancel</button>
              <button class="btn_dblue">save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</div>
