import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { FileuploadService } from '../../service/fileupload.service';
import { TokenService } from '../../service/token.service';
import { ManagerService } from 'src/app/service/manager.service';
import { numeric } from '@rxweb/reactive-form-validators';
import { ToastService } from '../../service/toast.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-video',
  templateUrl: './manage-video.component.html',
  styleUrls: ['./manage-video.component.css']
})
export class ManageVideoComponent implements OnInit {
  //**YOUTUBE VIDEO VARIABLE */
  player: any;
  id: any;


  filterTitleId: any
  filterDropdownlist: any
  filterDropdownlistBack: any = [];
  //**END */
  // @ViewChild('myVideo') myVideo: ElementRef | any;
  usertype: any;
  datePickerConfig = {
    format: 'DD-MM-YYYY', disableKeypress: true
  };
  videoUrlPath: any;
  uploadVideo: boolean = true;
  idvideoPath: number | undefined;
  videopause: any = document.getElementById('manageVideo');
  videoContentList: any = [];
  DbvideoContentList: any = [];
  universityList: any = [];
  videoslist: any = [];
  userInfo: any
  userInfoList: any
  currentLoggedID: any
  activeTitle: string = 'videos';
  constructor(private fileuploadservice: FileuploadService, private tokenStorage: TokenService, private toast: ToastService, private _manager_service: ManagerService, private activeRoute: ActivatedRoute, private route: Router) { }
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      console.log("params ", params);
      this.activeTitle = params.type ? params.type : 'videos'
    });

    //**youtube run on oninit */

    // this.ut.playerService.api.subscribe((videoData:any) => {
    // console.log(videoData, 1);
    // data.playVideo();
    // console.log(data)
    // console.log(data.getPlayerState())
    // data.stopVideo();

    // setInterval(()=> data.playVideo(), 1000);
    // });
    /*********end ******/
    this.userInfo = this.tokenStorage._get_userInfo();

    this.userInfoList = JSON.parse(this.userInfo);
    this.currentLoggedID = this.userInfoList.CurrentLoggedUserId;

    this.usertype = this.tokenStorage._get_userRole();
    console.log(this.usertype, 'usertype123')
    console.log(this.currentLoggedID, 'currentLoggedId')

    this.getAllVideos();
    this.filterListDropdown();
    // this.manageVideo_player();
  }


  //**YOUTUBE FUNCTIONS */
  savePlayer(player: any) {
    this.player = player;
    console.log("player instance", player);
  }
  onStateChange(event: any) {
    console.log("player state", event.data);
  }
  //**END */
  getAllVideos() {
    this.fileuploadservice.getAllVideosDetails().subscribe((videsContent: any) => {
      this.universityList = videsContent.listData;
      let videolist: any = [];

      if (this.activeTitle == 'videos') {
        videolist = this.universityList.filter((item: any) => {
          if (item.extension == 'video') {
            item.videoReturnUrl = item.videoReturnUrl ? item.videoReturnUrl.slice(-11) : '';
            return item;
          }
        });
      } else {
        videolist = this.universityList.filter((item: any) => item.extension != 'video');
      }
      // for(var i=0;i< videsContent.listData.length ;i++){
      //   if(videsContent.listData[i].extension == 'video'){
      //   videolist.push({
      //     'videoId': videsContent.listData[i]. videoId,
      //     'videolist' : videsContent.listData[i].videolist,
      //     'title':videsContent.listData[i].title,
      //     'description':videsContent.listData[i].description,
      //     'uploadeddate':videsContent.listData[i].uploadeddate,
      //     'broker':videsContent.listData[i].broker,
      //     'agent':videsContent.listData[i].agent,
      //     'homeOwner':videsContent.listData[i].homeOwner,
      //     'videoReturnUrl':  videsContent.listData[i].videoReturnUrl.slice(-11)


      //   })
      // }
      // }
      console.log(videolist, "newlu cretainggggggggg")
      this.videoContentList = videolist;
      this.DbvideoContentList = videolist;
    })
  }

  deleteVideo(videoObj: any) {
    this.fileuploadservice.deleteVideoDetails(videoObj).subscribe((deleteObj: any) => {
      console.log(deleteObj);
      this.toast.showSuccess("video content deleted Successfully")
      this.getAllVideos();

    }, err => {
      this.toast.showError("error Occured")
    })
  }


  //AgentModule
  // manageVideo_player() {
  //   this._manager_service.getall_manageVideos(7).subscribe((data: any) => {
  //     console.log(data, 'vid<=');
  //     //this.manageVideourl =
  //   })
  // }

  videoPaly(id: number, videoUrl: any) {
    //this.myVideo.nativeElement.play();
    console.log(id, 'nu')
    console.log(videoUrl, 'videoUrl')
    this.idvideoPath = id;
    this.videoUrlPath = videoUrl;

    window.open(this.videoUrlPath, '_blank')

  }
  onChangeFilter(videoId: any) {
    console.log(videoId, "aaa")
    this.filterTitleId = {
      'videoId': Number(videoId)
    }

  }
  filterByTitle() {
    console.log(this.filterTitleId, 'lisrFinal')
    this._manager_service.getManageVideoWithFilter(this.filterTitleId).subscribe((data: any) => {
      console.log(data, 'asss');
      this.videoContentList = data.listData;
      this.DbvideoContentList = data.listData;
    })
  }

  filterListDropdown() {
    this._manager_service.getgetManageVideoDropdown(this.currentLoggedID).subscribe((resp: any) => {
      this.filterDropdownlist = resp.listData;
      this.filterDropdownlistBack = resp.listData;
      console.log(this.filterDropdownlist, 'List')
    })
  }
  filterByVideoId() {
    this.filterByTitle();
  }
  resetVideoList() {
    this.getAllVideos();
  }

  onSearchTitle(e: any) {
    this.filterDropdownlist = this.filterDropdownlistBack.filter((item: any) => item.title.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.videoContentList = self.DbvideoContentList.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {

        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

  viewInList(title: any) {
    // if(title == 'files'){
    this.activeTitle = title;
    let videolist: any = [];
    this.route.navigate(['/superadmin/manage-video'], { queryParams: { type: title } })
    // this.fileuploadservice.getAllVideosDetails().subscribe((videsContent: any) => {

    // for(var i=0;i< videsContent.listData.length ;i++){

    //     if(videsContent.listData[i].extension == 'pdf'){
    //       videolist.push({
    //         'videoId': videsContent.listData[i]. videoId,
    //         'videolist' : videsContent.listData[i].videolist,
    //         'title':videsContent.listData[i].title,
    //         'description':videsContent.listData[i].description,
    //         'uploadeddate':videsContent.listData[i].uploadeddate,
    //         'broker':videsContent.listData[i].broker,
    //         'agent':videsContent.listData[i].agent,
    //         'homeOwner':videsContent.listData[i].homeOwner,
    //         'videoReturnUrl':""


    //       })
    //     }

    // }
    if (title == 'videos') {
      this.uploadVideo = true;
      videolist = this.universityList.filter((item: any) => {
        if (item.extension == 'video') {
          item.videoReturnUrl = item.videoReturnUrl ? item.videoReturnUrl.slice(-11) : '';
          return item;
        }
      });
    } else {
      this.uploadVideo = false;
      videolist = this.universityList.filter((item: any) => item.extension != 'video');
    }
    this.videoContentList = videolist;
    console.log("this.videoContentList ", this.videoContentList);
    // console.log(this.videoContentList)
    this.DbvideoContentList = videolist;
    // })
    // }else{
    //   this.fileuploadservice.getAllVideosDetails().subscribe((videsContent: any) => {
    //     let videolist:any=[];


    //     for(var i=0;i< videsContent.listData.length ;i++){
    //       if(videsContent.listData[i].extension == 'video'){
    //       videolist.push({
    //         'videoId': videsContent.listData[i]. videoId,
    //         'videolist' : videsContent.listData[i].videolist,
    //         'title':videsContent.listData[i].title,
    //         'description':videsContent.listData[i].description,
    //         'uploadeddate':videsContent.listData[i].uploadeddate,
    //         'broker':videsContent.listData[i].broker,
    //         'agent':videsContent.listData[i].agent,
    //         'homeOwner':videsContent.listData[i].homeOwner,
    //         'videoReturnUrl':  videsContent.listData[i].videoReturnUrl.slice(-11)


    //       })
    //     }
    //     }
    //     console.log(videolist,"newlu cretainggggggggg")
    //      this.videoContentList = videolist;

    //      this.DbvideoContentList = videolist;
    //   })
    // }
  }

}
