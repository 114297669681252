import { Component, OnInit } from '@angular/core';
import { FileuploadService } from 'src/app/service/fileupload.service';
import { Router, ActivatedRoute } from "@angular/router";


import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';
@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.css']
})
export class UserReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;
  public reportName: any = 'user-report';
  userTypeId:any='0';
   sucessReportObj:any = {
    "userTypeId": 0,
    "openClose": 0,
    "fromDate": '',
    "todate": '',
    "createdBy": this.tokenStorage._get_userTypeId()
  }
  public UserData: any = [];
  public userData: any = [];
  public successobj: any = {}
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    max: moment(new Date()),
  };
  sucessReport = '';
  userId = '0';
  FromDateData = '';
  ToDateData = '';
  openClose = '0';
  userList: any;
  userListBack: any;
  userType: any;
  userTypeBack: any;
  searchuserData: any;
  pageNum: any;
  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    
    this.userGetAllreport();
    this.userDropdown();
    this.getUserType();

  }
  
  getUserListById(userTypeId: number) {
    this._manager_service.getUserList(userTypeId).subscribe((response: any) => {
      this.userList = response.listData;
      this.userListBack = response.listData;
    })
  }

  onSearchUsersType(e: any) {
    this.userType = this.userTypeBack.filter((item: any) => item.userTypeName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  getUserType() {
    this._manager_service.getUserType().subscribe((response: any) => {
      this.userType = response.listData;
      this.userTypeBack = response.listData;
      console.log(this.userType, "userType")
    })
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }
  userDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentData")
    this._manager_service.successRate_dropdown(CurrentUserId).subscribe((res: any) => {
      this.UserData = res;
      console.log(res, "userdata")
    })
  }

  userGetAllreport() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this.sucessReportObj={
"userTypeId":parseInt(this.userTypeId),
"fromDate":this.FromDateData?this.FromDateData:"",
"toDate":this.ToDateData?this.ToDateData:"",
"curentUserId":parseInt(CurrentUserId)
    }
    this._manager_service.user_getAll(this.sucessReportObj,CurrentUserId).subscribe((res: any) => {
      this.userData=  (res.listData.length!=[])?res.listData:[];

      if(res && res.listData){
        this.userData= res.listData;
        this.searchuserData= res.listData;
      }
      
      
      console.log(this.successobj, "sucessobj");
      console.log(this.userData, "sucessRate")


    })
  }

  successFilter() {
      let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this.sucessReportObj={
"userTypeId":parseInt(this.userTypeId),
"fromDate":this.FromDateData?this.FromDateData:"",
"todate":this.ToDateData?this.ToDateData:"",
"curentUserId":CurrentUserId
    }


    console.log(this.sucessReportObj, "list");
    this.userGetAllreport();
  }

  successReset() {
    this.sucessReportObj.userTypeId = 0;
    this.sucessReportObj.openClose = 0;
    this.sucessReportObj.fromDate = '';
    this.sucessReportObj.todate = '';
    this.userTypeId = '0';
    this.openClose = '0';
    this.FromDateData = '';
    this.ToDateData = '';
    console.log(this.sucessReportObj, "list");
    this.userGetAllreport();
  }

  downloadExcel() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this.sucessReportObj={
"userTypeId":parseInt(this.userTypeId),
"fromDate":this.FromDateData?this.FromDateData:"",
"todate":this.ToDateData?this.ToDateData:"",
"curentUserId":parseInt(CurrentUserId)
    }
    this._file_upload.user_rate_report(this.sucessReportObj);
  }

  onselectReport(assignVal: any) {
    // debugger
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  sucessRatesearchReport() {
    
    let searchsucessReportObj = {
      "userTypeId": 0,
      "openClose": 0,
      "fromDate": '',
      "todate": '',
      "createdBy": this.tokenStorage._get_userTypeId(),
      "word": this.sucessReport
    }
    this._manager_service.sucess_searchReport(searchsucessReportObj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.userData = [];
      }
      else {
        this.userData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }

page(page:any){
  this.pageNum=null;
this.pageNum= page;
  console.log("a",this.userData);
  console.log("b",this.searchuserData);
}

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    // debugger
    console.log("Userdata", this.userData);

    self.userData = self.searchuserData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k] && this.pageNum==this.pageNum) {
        console.log(a[k])
        console.log("VALEU",a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        
      console.log("el;se");
      
        // self.successRateData=this.successRateData
        return false;
      }
    }));
  }
}
