import { email, unique, alpha, compare, prop, propArray, propObject, required } from "@rxweb/reactive-form-validators"


// "clientsslenderinfo"
export class lender {
  @prop() lenderName?: "string";
  @prop()
  wayofPay?: "string";
  @prop()
  loanNo?: "string";
  @prop()
  loanBalance?: 0;
  @prop()
  pitia?: 0;
  @prop()
  amountBehind?: 0;
  @prop()
  monthsLate?: "string";
  @prop()
  repName?: "string";
  @prop()
  phone?: "string";
  @prop()
  extno?: "string";
  @prop()
  faxno?: "string";
  @prop()
  cellNo?: "string";
  @prop()
  address?: "string";
  @prop()
  stateName?: "";
  @prop()
  cityName?: "";
  @prop()
  zipCode?: "string";
  @prop()
  email?: "string";
  @prop()
  notes?: "string";
  @prop()
  lenderInfoId?: number = 0;
}

//    "ownerAttornermodel"
export class attornerModel {
  @prop()
  name?: "string";
  @prop()
  firstName?: "string";
  @prop()
  phone?: "string";
  @prop()
  cellNo?: "string";
  @prop()
  faxNo?: "string";
  @prop()
  notes?: "string";
}

// "clientListinginfomodel"
export class clientListinginfomodel {
  @prop()
  listingDate?: "string";
  @prop()
  listingPrice?: 0;
  @prop()
  listingAgent?: "string";
  @prop()
  agency?: "string";
  @prop()
  address?: "string";
  @prop()
  email?: "string";
  @prop()
  phone?: "string";
  @prop()
  cellNo?: "string";
  @prop()
  faxNo?: "string";
  @prop()
  extNo?: "string";
}

//"buyerinfomodel"
export class buyerinfomodel {
  @prop()
  wayofPay?: "string";
  @prop()
  buyerName?: "string";
  @prop()
  coBuyerName?: "string";
  @prop()
  buyerPhone?: "string";
  @prop()
  email?: "string";
  @prop()
  offerAmount?: 0;
  @prop()
  contractDate?: "string";
  @prop()
  closingDate?: "string";
}

// "clientSSBuyerAgentorBrokerinfo"
export class clientssBuyerAgentBrokerninfo {
  @prop()
  name?: "string";
  @prop()
  agency?: "string";
  @prop()
  realEstateLieNo?: "string";
  @prop()
  phone?: "string";
  @prop()
  extNo?: "string";
  @prop()
  cellNo?: "string";
  @prop()
  faxNo?: "string";
  @prop()
  email?: "string";
}

// "clientsstitleinfo"
export class clientsstitleinfo {
  @prop()
  name?: "string";
  @prop()
  ordered?: "string";
  @prop()
  updated?: "string";
  @prop()
  respresentative?: "string";
  @prop()
  company?: "string";
  @prop()
  phone?: "string";
  @prop()
  extNo?: "string";
  @prop()
  cellNo?: "string";
  @prop()
  faxNo?: "string";
  @prop()
  email?: "string";
}

//"clientbuyerloanoffice"
export class clientbuyerloanoffice {
  @prop()
  name?: "string";
  @prop()
  company?: "string";
  @prop()
  phone?: "string";
  @prop()
  extNo?: "string";
  @prop()
  cellNo?: "string";
  @prop()
  faxNo?: "string";
  @prop()
  email?: "string";
  @prop()
  relationshiptoSeller?: "string";
  @prop()
  notes?: "string";
}

// "clientSSOwnerattorney"
export class clientSSOwnerattorney {
  @prop()
  attorneyName?: "string";
  @prop()
  formName?: "string";
  @prop()
  phone?: "string";
  @prop()
  extNo?: "string";
  @prop()
  cellNo?: "string";
  @prop()
  faxNo?: "string";
  @prop()
  email?: "string";
  @prop()
  address?: "string";
  @prop()
  stateId?: 1;
  @prop()
  cityId?: 1;
  @prop()
  zipCode?: "string";
}

//paralegalinfo
export class paralegalinfo {

  @prop()
  name?: "string";
  @prop()
  email?: "string";
  @prop()
  officeNo?: "string";
  @prop()
  extNo?: "string";
  @prop()
  cellNo?: "string";
  @prop()
  faxNo?: "string";
}

//escrowinfo
export class escrowinfo {
  @prop()
  representativeName?: "string";
  @prop()
  company?: "string";
  @prop()
  phone?: "string";
  @prop()
  extNo?: "string";
  @prop()
  faxNo?: "string";
  @prop()
  email?: "string";
}
//listinghistory
export class listinghistory {
  @prop()
  sno?: "string";
  @prop()
  mlSno?: "string";
  @prop()
  listingPrice?: 0;
  @prop()
  listingDate?: "string";
  @prop()
  notes?: "string";
}



//MainModel

export class clientShortSale {

  createdBy: string;
  clientId: number;
  shortSaleId: number;
  buyerInfoDocsUrl: string;
  cmaAnalysisDocsUrl: string;


  @propArray(lender)
  clientsslenderinfo?: lender[];

  @propArray(attornerModel)
  ownerAttornermodel?: attornerModel[];

  // @propArray(clientListinginfomodel)
  // clientListinginfomodel?: clientListinginfomodel[];

  @propArray(buyerinfomodel)
  buyerinfomodel?: buyerinfomodel[];

  // @propArray(clientssBuyerAgentBrokerninfo)
  // clientSSBuyerAgentorBrokerinfo?: clientssBuyerAgentBrokerninfo[];

  @propArray(clientsstitleinfo)
  clientsstitleinfo?: clientsstitleinfo[];

  @propArray(clientbuyerloanoffice)
  clientbuyerloanoffice?: clientbuyerloanoffice[];

  @propArray(clientSSOwnerattorney)
  clientSSOwnerattorney?: clientSSOwnerattorney[];



  @propObject(paralegalinfo)
  paralegalinfo?: paralegalinfo;

  @propObject(escrowinfo)
  escrowinfo?: escrowinfo

  @propObject(listinghistory)
  listinghistory?: listinghistory;

  @propObject(clientListinginfomodel)
  clientListinginfomodel?: clientListinginfomodel;

  @propObject(clientssBuyerAgentBrokerninfo)
  clientSSBuyerAgentorBrokerinfo?: clientssBuyerAgentBrokerninfo;


}
