import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import { ToastService } from '../../service/toast.service';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { DatePipe } from '@angular/common';
declare var $: any;

//Service
import { TokenService } from '../../service/token.service';
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { AuthServiceService } from 'src/app/service/auth-service.service';

@Component({
  selector: 'app-login-agreement',
  templateUrl: './login-agreement.component.html',
  styleUrls: ['./login-agreement.component.css']
})
export class LoginAgreementComponent implements OnInit {

  @ViewChild('downloadPanelContent', { static: false })

  downloadPanelContent: ElementRef | any;
  datePickerConfig = {
    format: 'MM-DD-YYYY', disableKeypress: true
  };
  public usertype: any;
  public usertypeId: any;
  public userinfo: any;
  public allAgreementList: any = {


  };
  public currentUserId: any;
  public CurrentLoggedUserId: any;
  public UserGUID: any;
  points = [];
  public formdata = new FormData();
  signatureImage: any;
  signatureImage1: any;
  base64image1: any = '';
  getAgentType: any;
  citylist: any;
  statelist: any;
  agentCompanyUser: boolean;
  imgIsEmpty: any = [];
  formSubmited: boolean = false;
  constructor(private tokenStorage: TokenService,
    private _manager_service: ManagerService,
    private route: Router,
    private toastr: ToastService,
    private dropDown: DropdownService,
    private auth: AuthServiceService,
    private datePipe: DatePipe) { }

  ngOnInit() {

    this.getAgentType = this.tokenStorage._get_agentType();
    this.usertype = this.tokenStorage._get_userRole();
    console.log(this.usertype, 'Role');
    let id: any;
    this.usertypeId = sessionStorage.getItem('userTypeId');
    this.userinfo = this.tokenStorage._get_userInfo();
    // this.getAllcity();
    this.UserGUID = JSON.parse(this.userinfo)
    this.CurrentLoggedUserId = this.userinfo.CurrentLoggedUserId
    //this.currentUserId = this.currentUserId: this.usertypeId
    //this.allAgreementList.push(this.currentUserId)
    if (this.usertype == 'Agent') {
      if (this.UserGUID.AgentAgreementDetails['AgentType'] == 'Company User') {
        this.agentCompanyUser = true;
        this.getBrokerAgreementList();
      }
    }

    this.getUserAgreementList();
    this.getAllstate();
  }

  getInsertLeadList() {
    this._manager_service.getInsertLead(this.allAgreementList).subscribe((resp: any) => {
      console.log(resp, 'insetData');
    })
  }


  getBrokerAgreementList() {
    this._manager_service.getAllAgreement(this.usertypeId).subscribe((data: any) => {
      this.allAgreementList['brokerFullName'] = data.getData.brokerFullName;
      this.allAgreementList['brokerPhoneNumber'] = data.getData.brokerPhoneNumber;
      this.allAgreementList['brokerRealEstateLicenseNo'] = data.getData.brokerRealEstateLicenseNo;
      this.allAgreementList['brokerEmailAddress'] = data.getData.brokerEmailAddress;
      this.allAgreementList['brokerMailingAddress'] = data.getData.brokerAgreementAddress;
      this.allAgreementList['brokerEmailAddress'] = data.getData.brokerEmailAddress;
      this.allAgreementList['brokerMailingAddress'] = data.getData.brokerAgreementAddress;
      this.allAgreementList['brokersignatureData'] = data.getData.brokersignatureData;

      this.allAgreementList['brokerState'] = data.getData.brokerState;
      this.allAgreementList['brokerCity'] = data.getData.brokerCity;
      this.allAgreementList['brokerZipcode'] = data.getData.brokerZipcode;
    })
  }
  getUserAgreementList() {
    this._manager_service.getUserAgreement(this.usertypeId).subscribe((resp: any) => {
      console.log(resp, 'asds');
      this.allAgreementList['executeDate'] = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
      console.log(this.allAgreementList['executeDate'])
      // this.allAgreementList = resp.getData;;
      this.allAgreementList['currentUserId'] = resp.getData.userId;

      this.allAgreementList['userUniqId'] = resp.getData.userUniqId;
      this.allAgreementList['user_Name'] = resp.getData.fullName
      this.allAgreementList['user_Email'] = resp.getData.emailAddress
      this.allAgreementList['user_Phone'] = resp.getData.phoneNumber
      this.allAgreementList['user_Email'] = resp.getData.emailAddress
      this.allAgreementList['user_AgreementAddress'] = resp.getData.agreementAddress
      this.allAgreementList['user_RENo'] = resp.getData.realEstateLicenseNo
      this.allAgreementList['user_Zipcode'] = resp.getData.zipcode
      this.allAgreementList['user_City'] = resp.getData.city
      this.allAgreementList['user_State'] = resp.getData.state
      this.allAgreementList['apexLicenseNo'] = resp.getData.apexLicenceNo
      this.allAgreementList['executeCity'] = resp.getData.city
      this.allAgreementList['executestate'] = resp.getData.state
      this.allAgreementList['user_MailingAddress'] = resp.getData.agreementAddress

      this.allAgreementList['apexName'] = resp.getData.fullName
      this.allAgreementList['agentBrokerstateName'] = resp.getData.state;

      this.allAgreementList['agentBrokerLicense'] = resp.getData.realEstateLicenseNo
    })
  }


  loginagreementSubmit() {
    this.getInsertLeadList();
    console.log(this.allAgreementList, 'frmpppp')
    console.log(this.signatureImage, 'img')
    console.log(this.signatureImage1, 'img1')
  }

  showImage(_data: any) {
    this.signatureImage = _data;
    console.log(this.signatureImage, 'img')

  }
  showImage1(_data1: any) {
    let base64data: any;
    this.signatureImage1 = _data1;

    const reader = new FileReader();
    reader.onloadend = () => {
      base64data = reader.result;
      // console.log(base64data, "hi");
      this.base64image1 = base64data;
    }
    reader.readAsDataURL(_data1);
    // console.log(this.base64image1, "baseb4");
    console.log("this.base64image1 ", this.base64image1);
    console.log(this.signatureImage1, 'img1');


  }
  getAllstate() {
    this.dropDown._getAll_state(1).subscribe((stateList: any) => {

      this.statelist = stateList.listData;
      console.log(stateList, 'state')
    })
  }
  getAllcity() {
    this.dropDown._getAll_city(1).subscribe((cityList: any) => {

      this.citylist = cityList.listData;
      console.log(this.citylist, 'city')
    })
  }

  onsubimtLoginAgreemnet(agreementform: any): any {
    console.log("form ", agreementform);
    if (!agreementform.form.valid || this.imgIsEmpty.length == 0) {

      this.formSubmited = true;
      return false;
    }
    this.allAgreementList['user_SignatureData'] = this.base64image1;
    // this.allAgreementList['user_signatureImage'] = this.signatureImage1;
    this.formdata.append('', this.signatureImage1)
    this.allAgreementList['user_signatureImage'] = this.formdata;

    // this.allAgreementList.
    console.log(this.formdata, "list")
    console.log(this.allAgreementList)
    this._manager_service.getInsertLead(this.allAgreementList).subscribe((data: any) => {
      console.log(data, "im data")
      if (data.reponse == true) {
        this.toastr.showSuccess("Form Submited Successfully")
        console.log(this.UserGUID, "im 2data")
        if (this.UserGUID.ChangePasswordFlag == true) {
          // alert("1")
          this.route.navigate(['/superadmin/change-password']);
        } else {
          this.route.navigate(['/superadmin/dashboard'])
        }
        this.formSubmited = false;
      } else {
        this.toastr.showError("ERROR Occured")
      }

    })


  }
  clearsign() {
    let x: any;
    // var x = document.getElementsByClassName("btn btn-default").type="button";
    x = document.getElementsByClassName("btn btn-default");
    console.log(x)
  }


  logout() {
    this.auth.logout(this.UserGUID.UserGUID);
    // this.route.navigateByUrl('/login');
  }

}
