<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Upload">
    </app-header>

    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Upload New Video</h2>
          <button class="btn_to_btitle"><a routerLink="/superadmin/manage-video">back</a></button>
        </div>
      </div>

      <div class="pagecontent_wrapper col-12 col-md-12 col-lg-12 col-xl-12">
        <form  #managefrm="ngForm" (ngSubmit)=" uploadFormVideo(managefrm)" name="form">
          <div class="apex-white_wrap">
            <div class="apex-title-lilblue">
              <h3>Upload Video</h3>
            </div>
            <div class="apexforms-group align-decode">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms ">
                    <label> Video Title<sup>*</sup> </label>
                    <input type="text" placeholder="Video Title" [(ngModel)]="managevideos.title" value="" name="title"
                      #title="ngModel" [ngClass]="{'is-invalid': managefrm.submitted && title.invalid}" required />
                    <div *ngIf="managefrm.submitted && title.invalid" class="invalid-feedback">
                      <div *ngIf="title.errors?.required">Title is required</div>

                    </div>
                  </div>

                  <div class="newfeatures_wrp university_upload">
                    <div class="loa_wrappers">
                      <div class="loaradio_selector">
                        <div class="radio_flex">
                          <div class="radiobuttons">
                            <div class="rdio rdio-primary radio-inline">
                              <input name="extension" [(ngModel)]="managevideos.extension" (change)=loaoptionDocu($event) value="video"
                                id="loa_send" type="radio" checked />
                              <label for="loa_send">Video Url</label>
                            </div>
                          </div>
                          <div class="radiobuttons">
                            <div class="rdio rdio-primary radio-inline">
                              <input name="extension" [(ngModel)]="managevideos.extension" (change)=loaoptionDocu($event) value="pdf"
                                id="upload_loa" type="radio"  />
                              <label for="upload_loa">Upload File</label>
                            </div>
                          </div>
                          <div *ngIf="managefrm.submitted && !managevideos.extension" class="invalid-feedback">
                            <div>Extension is required</div>
      
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="apexinput-froms" *ngIf="managevideos.extension == 'video'">
                    <label> Video Url </label>
                    <input type="text" [(ngModel)]="managevideos.videoReturnUrl" placeholder="https://youtu.be/zzyzyzy"
                      value="" name="videoReturnUrl" #videoReturnUrl="ngModel"
                      [ngClass]="{'is-invalid': managefrm.submitted && videoReturnUrl.invalid}" required />
                    <div *ngIf="managefrm.submitted && videoReturnUrl.invalid" class="invalid-feedback">
                      <div *ngIf="videoReturnUrl.errors?.required">VideoLink is required</div>
                    </div>
                  </div>


                  <div class="apexinput-froms" *ngIf="managevideos.extension == 'pdf'">
                    <label> Upload File </label>
                    <input type="file"  [(ngModel)]="managevideos.fileUrl" name="fileUrl"  [ngClass]="{'is-invalid': managefrm.submitted && fileUrl.invalid}" #fileUrl="ngModel"  (input)="uploadVideo($event)" accept=".pdf" required/>
                    <div *ngIf="managefrm.submitted && fileUrl.invalid" class="invalid-feedback">
                      <div *ngIf="fileUrl.errors?.required">File  is required</div>
                    </div>
                  </div>


                  <div class="apexinput-froms tvt-checkbox">
                    <label> To Whom<sup>*</sup></label>
                    <ul class="unstyled centered">
                      <li>
                        <input class="styled-checkbox" name="broker" [(ngModel)]="managevideos.broker"
                          id="styled-checkbox-1" type="checkbox" >
                        <label for="styled-checkbox-1">Broker</label>
                      </li>
                      <li>
                        <input class="styled-checkbox" name="agent" [(ngModel)]="managevideos.agent"
                          id="styled-checkbox-2" type="checkbox" >
                        <label for="styled-checkbox-2">Agent </label>
                      </li>
                      <li>
                        <input class="styled-checkbox" name="homeOwner" [(ngModel)]="managevideos.homeOwner"
                          id="styled-checkbox-3" type="checkbox" >
                        <label for="styled-checkbox-3">Homeowner </label>
                      </li>
                      <li>
                        <input class="styled-checkbox" name="negotiator" [(ngModel)]="managevideos.negotiator"
                          id="styled-checkbox-45" type="checkbox" >
                        <label for="styled-checkbox-45">Negotiator </label>
                      </li>
                    </ul>
                    <p style="color:#dc3545; font-size:13px;"
                      *ngIf="managefrm.submitted && !managevideos.broker && !managevideos.agent && !managevideos.homeOwner && !managevideos.negotiator ">
                      Note* please Select Atleast One</p>
                      <!-- <p style="color:#dc3545; font-size:13px;"
                      *ngIf=" managefrm.submitted && (managevideos.broker || managevideos.agent || managevideos.homeOwner || managevideos.negotiator) ">
                      Note* please Select Atleast One</p> -->
                  </div>

                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 tvt-section">
                  <div class="apexinput-froms">
                    <label>Task<sup>*</sup></label>
                    <textarea [(ngModel)]="managevideos.description" #description="ngModel" name="description"
                      [ngClass]="{'is-invalid': managefrm.submitted && description.invalid}" required placeholder="Task"></textarea>
                    <div *ngIf="managefrm.submitted && description.invalid" class="invalid-feedback">
                      <div *ngIf="description.errors?.required">Task is required</div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="apexforms-group align-decode">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <!-- <img> -->
                    <div class="remove-upload-sec">
                      <div *ngIf="uploadVideoSrc !== '' ">
                        <!-- <video width="120" height="140" [src]="uploadVideoSrc" controls>
                          Your browser does not support HTML5 video.
                        </video> -->
                      </div>
                      <!-- <a class="btn rem-vdo" (click)="removeVideo()">Remove Video</a> -->
                      <!-- <input hidden type="file" #uploader (input)="uploadVideo($event)" accept="video/mp4" /> -->
                      <!-- <button class="btn up-vdo" type="button" (click)="uploader.click()">Upload Video</button> -->

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="apxbtn_finalcenter can-update">
              <!-- <button class="btn_grey" routerLink="/superadmin/manage-video">Cancel</button> -->
              <button class="btn_dblue" type="submit">Submit</button>
            </div>
          </div>
        </form>


        <div *ngIf="updateForm && managevideos.extension == 'pdf'">


          <a
          [href]="managevideos.videoReturnUrl" target="_blank" [download]="managevideos.fileName">{{managevideos.fileName}} download</a>


        </div>


      </div>
    </div>
  </main>

</div>
