import { email,unique,alpha,compare,prop, propArray, propObject, required } from "@rxweb/reactive-form-validators"






  export class others{
      @prop()
    otherfee: number;
    @prop()
    otherfeetitle:string;
  }


  export class Misc{
    @prop()
    misc: number ;
    @prop()
    miscTitle:string;
  
  }


export class comissionbreakdownModel{
  @propArray(others)
  otherfee ?:others[];
   
  @propArray(Misc)
  referalorMisc ?:Misc[];
   
    @prop()
    brekDownId:number=0;
    @prop()
    salesPrice: number=0;
    @prop()
    sharingValue: number =0.1;
    @prop()
    sSnegotitorFee: number=0;
    @prop()
    subTotal: number=0;
    @prop()
    negoCommission: number;
    @prop()
    negoSplit: number;
    @prop()
    netAmount: number=0;
    @prop()
    otherFeeTotal:string;
    @prop()
    fee:string='per'
  


}
