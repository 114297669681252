import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';
@Component({
  selector: 'app-financial-report',
  templateUrl: './financial-report.component.html',
  styleUrls: ['./financial-report.component.css']
})
export class FinancialReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;

  public trendOBj = {
    "userId": 0,
    "openClose": 0,
    "fromDate": '',
    "todate": '',
    "createdBy": this.tokenStorage._get_userTypeId()
  }

finanData:any=[
{
  label:"Total Commission",
  // totalCommissionCurrentMonthCount:null,
  // totalCommissionPreviousMonthCount:null,
  // totalCommissionSameMonthLastYear:null,
  // totalCommissionYeartoDate:null,
  // totalCommissionProjectedAtCurrentPace:null,
},
{
  label:"Negotiator Commission",
},{
  label:"Other Fees"
},
{
  label:"Company $"
}

]

  //DropDown
  public trentDData: any = [];

  //list
   financialReportData: any = [];
  public trenobjlst: any = {};
  public reportName: any = 'financial-reportt';

  //filter



  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    max: moment(new Date()),
  };


  userId = '0';
  trendFromDateData = '';
  trendToDateData = '';
  openClose = '0';
  trendReport = ''
  searchfinancialReportData: any=[];
  fakefinancialReportData: any=[];
  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
  
    this.financialGetAllReport();
    this.trendRateDropdown();


  }


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }


  trendRateDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.trendRate_dropdown(CurrentUserId).subscribe((trendresponse: any) => {
      this.trentDData = trendresponse;
      console.log(this.trentDData, "trendataaaaa")
    })
  }


  financialGetAllReport() {

    this._manager_service.financialReport(this.trendOBj).subscribe((res: any) => {
      console.log(res, "res")
          if(res){
            this.financialReportData = res.listData;
            console.log("finan repoert" , this.financialReportData);
        

            this.fakefinancialReportData=[
              {
                category:"Total Commission",
                CurrentMonthCount:this.financialReportData[0].totalCommissionCurrentMonthCount,
                PreviousMonthCount:this.financialReportData[0].totalCommissionPreviousMonthCount,
                SameMonthLastYear:this.financialReportData[0].totalCommissionSameMonthLastYear,
             
                YeartoDate:this.financialReportData[0].totalCommissionYeartoDate,
                ProjectedAtCurrentPace:this.financialReportData[0].totalCommissionProjectedAtCurrentPace,
              LastYearTotal:this.financialReportData[0].totalCommissionLastYearTotal,
              },
              {
                category:"Negotiater Commission",
                CurrentMonthCount:this.financialReportData[0].negotiatorCommissionCurrentMonthCount,
                PreviousMonthCount:this.financialReportData[0].negotiatorCommissionPreviousMonthCount,
                SameMonthLastYear:this.financialReportData[0].negotiatorCommissionSameMonthLastYear,
             
                YeartoDate:this.financialReportData[0].negotiatorCommissionYeartoDate,
                ProjectedAtCurrentPace:this.financialReportData[0].negotiatorCommissionProjectedAtCurrentPace,
              LastYearTotal:this.financialReportData[0].negotiatorCommissionLastYearTotal,
              },
              {
                category:"Other Fees	",
                CurrentMonthCount:this.financialReportData[0].otherFeesCurrentMonthCount,
                PreviousMonthCount:this.financialReportData[0].otherFeesPreviousMonthCount,
                SameMonthLastYear:this.financialReportData[0].otherFeesSameMonthLastYear,
             
                YeartoDate:this.financialReportData[0].otherFeesYeartoDate,
                ProjectedAtCurrentPace:this.financialReportData[0].otherFeesProjectedAtCurrentPace,
              LastYearTotal:this.financialReportData[0].otherFeesLastYearTotal,
              },
              {
                category:"Company $	",
                CurrentMonthCount:this.financialReportData[0].companyCurrentMonthCount,
                PreviousMonthCount:this.financialReportData[0].companyPreviousMonthCount,
                SameMonthLastYear:this.financialReportData[0].companySameMonthLastYear,
             
                YeartoDate:this.financialReportData[0].companyYeartoDate,
                ProjectedAtCurrentPace:this.financialReportData[0].companyProjectedAtCurrentPace,
              LastYearTotal:this.financialReportData[0].companyLastYearTotal,
              },
            
            ];
          
            console.log("fake " , this.fakefinancialReportData)
            // this.finanData.push({
            //   totalCommissionCurrentMonthCount: this.financialReportData[0].totalCommissionCurrentMonthCount,
            //   totalCommissionPreviousMonthCount: this.financialReportData[0].totalCommissionPreviousMonthCount,
            //   totalCommissionSameMonthLastYear: this.financialReportData[0].totalCommissionSameMonthLastYear,
            //   totalCommissionYeartoDate: this.financialReportData[0].totalCommissionYeartoDate,
            //   totalCommissionProjectedAtCurrentPace: this.financialReportData[0].totalCommissionProjectedAtCurrentPace,
            //   totalCommissionLastYearTotal: this.financialReportData[0].totalCommissionLastYearTotal,
            // }),
            // this.finanData.push({
            //   negotiatorCommissionCurrentMonthCount: this.financialReportData[0].negotiatorCommissionCurrentMonthCount,
            //   negotiatorCommissionPreviousMonthCount: this.financialReportData[0].negotiatorCommissionPreviousMonthCount,
            //   negotiatorCommissionSameMonthLastYear: this.financialReportData[0].negotiatorCommissionSameMonthLastYear,
            //   negotiatorCommissionYeartoDate: this.financialReportData[0].negotiatorCommissionYeartoDate,
            //   negotiatorCommissionProjectedAtCurrentPace: this.financialReportData[0].negotiatorCommissionProjectedAtCurrentPace,
            //   closedLastYearTotal: this.financialReportData[0].closedLastYearTotal,
            // })
            // this.finanData.push({
            //   otherFeesCurrentMonthCount: this.financialReportData[0].otherFeesCurrentMonthCount,
            //   otherFeesPreviousMonthCount: this.financialReportData[0].otherFeesPreviousMonthCount,
            //   otherFeesSameMonthLastYear: this.financialReportData[0].otherFeesSameMonthLastYear,
            //   otherFeesYeartoDate: this.financialReportData[0].otherFeesYeartoDate,
            //   otherFeesProjectedAtCurrentPace: this.financialReportData[0].otherFeesProjectedAtCurrentPace,
            //   cancelledLastYearTotal: this.financialReportData[0].cancelledLastYearTotal,
            // })
            // this.finanData.push({
            //   companyCurrentMonthCount: this.financialReportData[0].companyCurrentMonthCount,
            //   companyPreviousMonthCount: this.financialReportData[0].companyPreviousMonthCount,
            //   companySameMonthLastYear: this.financialReportData[0].companySameMonthLastYear,
            //   companyYeartoDate: this.financialReportData[0].companyYeartoDate,
            //   companyProjectedAtCurrentPace: this.financialReportData[0].companyProjectedAtCurrentPace,
            //   companyLastYearTotal: this.financialReportData[0].companyLastYearTotal,
            // })
            this.searchfinancialReportData = this.fakefinancialReportData; 
          }
      // this.trenobjlst = (res.getData != null) ? res.getData : [];
  
      
      console.log(this.finanData, "trenobjlst");
      console.log(this.financialReportData, "financialReportData")
    })
  }

  trendFilter() {
    this.trendOBj.userId = parseInt(this.userId);
    console.log(this.trendOBj.userId, "hello")
    this.trendOBj.fromDate = this.trendFromDateData;
    this.trendOBj.todate = this.trendToDateData;
    this.trendOBj.openClose = parseInt(this.openClose);
    console.log(this.trendOBj, "list");
    this.financialGetAllReport();

  }

  trendReset() {
    this.trendOBj.userId = 0;
    this.trendOBj.openClose = 0;
    this.trendOBj.fromDate = '';
    this.trendOBj.todate = '';
    this.userId = '0';
    this.openClose = '0';
    this.trendFromDateData = '';
    this.trendToDateData = '';
    this.financialGetAllReport();
  }


  downloadExcel() {
    this._file_upload.financial_report_excel();
  }
  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  trendsearchReport() {
    let searchtrendOBj = {
      "userId": 0,
      "openClose": 0,
      "fromDate": '',
      "todate": '',
      "createdBy": this.tokenStorage._get_userTypeId(),
      "word": this.trendReport
    }
    this._manager_service.trendRatesearchReport(searchtrendOBj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.financialReportData = [];
      }
      else {
        this.financialReportData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }


  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.fakefinancialReportData = self.searchfinancialReportData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
 
      }
      else {
        // self.successRateData=this.successRateData
        return false;
      }
    }));
  }
}
