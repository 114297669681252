import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { Router, ActivatedRoute } from '@angular/router';
//**FORM BUILDER*/
import { FormGroup, FormArray, FormBuilder, FormControl } from "@angular/forms";
import { RxFormBuilder, file } from '@rxweb/reactive-form-validators';

import { taskmodel } from '../../models/task_model';
@Component({
  selector: 'app-client-assign-task',
  templateUrl: './client-assign-task.component.html',
  styleUrls: ['./client-assign-task.component.css']
})
export class ClientAssignTaskComponent implements OnInit {
  addTaskmodel = new taskmodel();
  addTaskgroup: any = FormGroup;
  filetabList: any;
  clientAddressList: any;
  assignedUserTypeList: any;
  assignedUserList: any;
  datePickerConfig = { format: 'MM/DD/YYYY', disableKeypress: true };

  constructor(private _manager_service: ManagerService,
    private route: Router,
    private activated_route: ActivatedRoute,
    private dropdownService: DropdownService,
    private formBuilder: RxFormBuilder) { }

  ngOnInit(): void {
    this.dropDownList();

    this.addTaskgroup = this.formBuilder.group(this.addTaskmodel);
  }






  dropDownList() {
    this.dropdownService._getAll_filetab().subscribe((filetab: any) => {
      console.log(filetab, "filetbssszzzzzzzzzzz")
      this.filetabList = filetab.listData;
    }),
      this.dropdownService._getAll_clientAddress().subscribe((clientAddress: any) => {
        console.log(clientAddress, "ClientAdresss");
        this.clientAddressList = clientAddress.listData;
      }),
      this.dropdownService._getassigned_userType().subscribe((assignedUserType: any) => {
        console.log(assignedUserType, "assignedUserType");
        this.assignedUserTypeList = assignedUserType.listData;
      }),
      this.dropdownService._getassigned_user().subscribe((assignedUser: any) => {
        console.log(assignedUser, "assignedUser");
        this.assignedUserList = assignedUser.listData;
      })
  }


  assign_task() {
    console.log(this.addTaskgroup.value)
  }
}
