 <div class="col-12 col-md-12 ">
<div class="row">
    <div class="col-md-4 offset-md-4">
        The page was not found !! 
        <br>
        <br>
        <br>

        <button class="home-button" routerLink="/superadmin/dashboard">Go To Home Page</button>
    </div>
</div>
</div>