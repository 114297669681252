<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header *ngIf="usertype == 'SuperAdmin'" headerMainTitle="Manage University"></app-header>
    <app-header *ngIf="usertype != 'SuperAdmin'" headerMainTitle="Tutorial Videos"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>University List</h2>
          <div class="sidebar-search lead_glo global-search">
            <div>
              <div class="input-group">
                <i class="fa fa-search" aria-hidden="true"></i>
                <input type="search" placeholder="Search..." (input)="onSearch($event)" #searchword>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="pagecontent_wrapper">
        <form>
          <div class="apex-white_wrap calender-icon-rmve">
            <div class="filterapx_wrap">
              <div class="filter_setting_forms">
                <h3>Filter By</h3>
                <div class="apxsilsetting_group">
                  <label>Title</label>
                  <mat-form-field appearance="fill">
                    <mat-select (selectionChange)="onChangeFilter($event.value)">
                      <input type="text" (keyup)="onSearchTitle($event)" (keydown)="$event.stopPropagation()">
                      <mat-option disabled selected value="0">Select title</mat-option>
                      <mat-option *ngFor="let item of filterDropdownlist" [value]="item.videoId">
                        {{item.title}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </div>
              <div class="filter_setting">
                <ul>
                  <li (click)="filterByVideoId()" class="filter_apx"><img src="../../../assets/images/filter.png"
                      alt="filter"></li>
                  <li type="reset" (click)="resetVideoList()" class="setting_reset"><img
                      src="../../../assets/images/setting_reset.png" alt="filter"></li>
                </ul>
              </div>
            </div>

          </div>
        </form>


      </div> -->

      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap tut-vidoe-drop">
          <div class="hdng-btn">
            <!-- <h6>List of Videos</h6> -->

            <button class="btn brd-btn btn-create-manage " style="margin: 2px;" [ngStyle]="{'background': activeTitle == 'videos' ? '#085589': '', 'color': activeTitle == 'videos' ? '#FFFFFF': ''}" (click)="viewInList('videos')">List Of Videos</button>

            <button class="btn brd-btn btn-create-manage" style="margin:0px;" [ngStyle]="{'background': activeTitle != 'videos' ? '#085589' : '', 'color': activeTitle != 'videos' ? '#FFFFFF': ''}" (click)="viewInList('files')">List Of Files</button>

            <button
              *ngIf="usertype != 'Agent' && usertype != 'Broker' && usertype != 'HomeOwner' && usertype != 'Negotiator'"
              [routerLink]="['/superadmin/manage-upload']" class="btn brd-btn btn-create-manage"><svg
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M16.0509 7.932V4.86328H14.6904C14.7797 6.43761 15.3954 7.58831 16.0509 7.932Z"
                  fill="#085589" />
                <path
                  d="M14.6015 1.41016C13.6517 1.98398 13.0219 2.96598 12.8965 4.06848H13.896C13.9241 3.13994 14.1655 2.23041 14.6015 1.41016Z"
                  fill="#085589" />
                <path d="M16.0509 1C15.3954 1.34368 14.7797 2.49439 14.6904 4.06871H16.0509V1Z" fill="#085589" />
                <path
                  d="M14.6015 7.52161C14.1655 6.70135 13.9241 5.79182 13.896 4.86328H12.8965C13.0219 5.96579 13.6517 6.94779 14.6015 7.52161Z"
                  fill="#085589" />
                <path d="M18.206 4.86328H16.8454V7.932C17.5009 7.58831 18.1166 6.43761 18.206 4.86328Z"
                  fill="#085589" />
                <path
                  d="M20 4.06848C19.8746 2.96598 19.2447 1.98398 18.295 1.41016C18.731 2.23041 18.9723 3.13994 19.0005 4.06848H20Z"
                  fill="#085589" />
                <path
                  d="M18.295 7.52161C19.2447 6.94779 19.8746 5.96579 20 4.86328H19.0005C18.9723 5.79182 18.731 6.70135 18.295 7.52161Z"
                  fill="#085589" />
                <path d="M16.8454 1V4.06871H18.206C18.1166 2.49439 17.5009 1.34368 16.8454 1Z" fill="#085589" />
                <path d="M0 10.5861H1.19178V9.39453H0V10.5861Z" fill="#085589" />
                <path d="M0 11.3789H1.19178V12.5705H0V11.3789Z" fill="#085589" />
                <path d="M0 18.5316H1.19178V17.3398H0V18.5316Z" fill="#085589" />
                <path d="M0 15.3516H1.19178V16.5433H0V15.3516Z" fill="#085589" />
                <path d="M0 13.3652H1.19178V14.557H0V13.3652Z" fill="#085589" />
                <path d="M9.93103 11.3789H11.1228V12.5705H9.93103V11.3789Z" fill="#085589" />
                <path d="M9.93103 13.3652H11.1228V14.557H9.93103V13.3652Z" fill="#085589" />
                <path d="M9.93103 15.3516H11.1228V16.5433H9.93103V15.3516Z" fill="#085589" />
                <path d="M4.76697 12.584V15.3642L6.75117 13.9584L4.76697 12.584Z" fill="#085589" />
                <path d="M9.93103 10.189V10.5861H11.1228V9.39453H9.93103V10.189Z" fill="#085589" />
                <path d="M9.93103 17.7372V18.5316H11.1228V17.3398H9.93103V17.7372Z" fill="#085589" />
                <path
                  d="M1.98621 17.3391H9.13653V10.5859H1.98621V17.3391ZM3.97244 12.5615C3.97244 12.2728 4.1313 12.0075 4.38588 11.8712C4.64046 11.7349 4.94935 11.7499 5.18951 11.9101L7.20596 13.3159C7.42099 13.4627 7.54896 13.7068 7.54743 13.9671C7.54608 14.2275 7.41539 14.47 7.19883 14.6144L5.19664 16.0107C5.06578 16.0993 4.91151 16.1468 4.75333 16.1473C4.32139 16.1463 3.97193 15.7957 3.97244 15.3636V12.5615Z"
                  fill="#085589" />
                <path
                  d="M2.54474 6.45257V8.43881C2.54474 8.65826 2.72261 8.83612 2.94188 8.83612H3.73652C3.95579 8.83612 4.13366 8.65826 4.13366 8.43881V6.45257C4.13366 5.7944 4.66726 5.26097 5.32544 5.26097H7.31168V3.67188H5.32544C3.79049 3.67374 2.54644 4.91762 2.54474 6.45257Z"
                  fill="#085589" />
                <path d="M8.10608 6.28792L9.92785 4.46614L8.10608 2.64453V6.28792Z" fill="#085589" />
                <path d="M14.6265 12.806H18.27L16.4482 10.9844L14.6265 12.806Z" fill="#085589" />
                <path
                  d="M15.6538 15.5876C15.6538 16.2458 15.1202 16.7794 14.462 16.7794H12.4758C12.2563 16.7794 12.0786 16.9573 12.0786 17.1767V17.9712C12.0786 18.1906 12.2563 18.3683 12.4758 18.3683H14.462C15.9969 18.3666 17.241 17.1228 17.2427 15.5876V13.6016H15.6538V15.5876Z"
                  fill="#085589" />
              </svg>Upload New Video/File</button>
          </div>
        </div>
        <div *ngIf="videoContentList && videoContentList.length>0 ;else elseBlock">
          <div class="apex-white_wrap tut-inner-content" *ngFor="let videosContent of videoContentList; let i = index ">


            <div class="tutorial-video-part-bg">
              <div class="row no-margin">
                <div class="tutorial-main-sections tml-strt col-xl-5 col-sm-5 col-12 no-padding" *ngIf="uploadVideo">

                  <youtube-player *ngIf="activeTitle == 'videos'" [videoId]="videosContent.videoReturnUrl" (ready)="savePlayer($event)"
                    (change)="onStateChange($event)">
                  </youtube-player>
                  <img *ngIf="activeTitle != 'videos'" src="../../../assets/images/clients/files.png" alt="icon" />
                  <div class="tic-text">
                    <span>Video Title</span>
                    <p>{{videosContent.title }}</p>
                  </div>
                </div>

                <div class="tutorial-main-sections tml-strt col-xl-5 col-sm-5 col-12 no-padding"  *ngIf="!uploadVideo">




                  <img *ngIf="activeTitle != 'videos'" src="../../../assets/images/clients/files.png" alt="icon" />
                 <div class="video-filename">
                  <span>{{videosContent.fileName}}</span>
                  <a [href]="videosContent.videoReturnUrl" target="_blank" [download]="videosContent.fileName">Download</a>
                </div>
                  <div class="tic-text">
                    <span>File Title</span>
                    <p>{{videosContent.title }}</p>
                  </div>
                </div>



                <div class="tutorial-main-sections tml-md col-xl-4 col-sm-4 col-12">
                  <div class="tutorial-sections-part1">
                    <span>Uploaded Date </span>
                    <p>{{videosContent.uploadeddate}}</p>
                  </div>
                  <div *ngIf="usertype != 'Agent'  && usertype != 'Broker'  && usertype != 'Negotiator'" [ngClass]="usertype == 'SuperAdmin'? 'super_active' :''"
                    class="tutorial-sections-part2">
                    <span>To Whom </span>
                    <p *ngIf="videosContent.broker"> Broker</p>
                    <p *ngIf="videosContent.agent">Agent </p>
                    <p *ngIf="videosContent.homeOwner">HomeOwner</p>
                    <p *ngIf="videosContent.negotiator">Negotiator</p>
                  </div>
                </div>
                <div *ngIf="usertype != 'Agent' && usertype != 'Negotiator' && usertype != 'Broker' && usertype != 'HomeOwner'"
                  class="tutorial-main-lft tml-end col-xl-3 col-sm-3 col-12 no-padding">
                  <a [routerLink]="['/superadmin/manage-upload']" [queryParams]="{id:videosContent.videoId }"><img
                      src="../../../assets/images/clients/edit-icon.png" alt="rec"></a>
                  <a (click)="deleteVideo(videosContent)"> <img src="../../../assets/images/clients/delete-icon.png"
                      alt="rec"> </a>
                </div>
                <!-- <div *ngIf="usertype == 'Agent' || usertype == 'Broker'"
                  class="tutorial-main-lft tml-end col-xl-3 col-sm-3 col-12 no-padding">
                  <button class="play-btn btn" (click)="videoPaly(videosContent.videoId, videosContent.videoReturnUrl)">
                    <img src="../../../assets/images/clients/book-icon.png" alt="rec">Play</button>
                </div> -->
              </div>
              <div class="row no-margin tutorial-main-sections sec-full-sections">
                <span>Task</span>
                <p>{{videosContent.description}}</p>

              </div>
            </div>



          </div>
        </div>


      </div>

      <ng-template #elseBlock>
        <div class="nodata_found_components">
          <h2>No Data found</h2>
        </div>
      </ng-template>


    </div>
  </main>

</div>


<!-- Video The Modal -->
<!-- <div class="modal" id="video_tag">
  <div class="modal-dialog modal-lg">
    <div class="modal-content"> -->

<!-- Modal Header -->
<!-- <div class="modal-header">
        <h4 class="modal-title">Video</h4>
        <button type="button" (click)="videoPause()" class="close" data-dismiss="modal">&times;</button>
      </div> -->

<!-- Modal body -->
<!-- <div class="modal-body">
        <div class="video_modalpop">

          <video width="100%" height="450px" #myVideo autoplay loop controls>
            <source src="{{videoUrlPath}}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <p>{{idvideoPath}}</p>

      </div>
    </div>
  </div>
</div> -->


<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <img src="../../../assets/images/danger.png" alt="danger" />
          <p>Are you sure want to delete the data?</p>
          <div class="filterbt_btnn btn_deletecard">
            <button class="btnfilter-cancel">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
