import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';


@Component({
  selector: 'app-trend-report',
  templateUrl: './trend-report.component.html',
  styleUrls: ['./trend-report.component.css']
})
export class TrendReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;

  public trendOBj = {
    "userId": 0,
    "openClose": 0,
    "fromDate": '',
    "todate": '',
    "createdBy": this.tokenStorage._get_userTypeId()
  }

  //DropDown
  public trentDData: any = [];

  //list
  public trendListData: any = [];
  public trenobjlst: any = {};
  public reportName: any = 'trend-report';

  //filter



  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    max: moment(new Date()),
  };


  userId = '0';
  trendFromDateData = '';
  trendToDateData = '';
  openClose = '0';
  trendReport = ''
  resTrendList: any[];
  searchresTrendList: any[];
  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
  
    this.trendRateGetAllReport();
    this.trendRateDropdown();


  }


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }


  trendRateDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.trendRate_dropdown(CurrentUserId).subscribe((trendresponse: any) => {
      this.trentDData = trendresponse;
      console.log(this.trentDData, "trendataaaaa")
    })
  }


  trendRateGetAllReport() {
    this._manager_service.trendRate_getAllReport(this.trendOBj).subscribe((res: any) => {
          
      this.trendListData = (res.listData != null) ? res.listData : [];
      console.log("trend list" , this.trendListData)
  this.resTrendList=[
    {
      category:"Total Commission",
      CurrentMonthCount:this.trendListData[0].totalCommissionCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].totalCommissionPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].totalCommissionSameMonthLastYear,
   
      YeartoDate:this.trendListData[0].totalCommissionYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].totalCommissionProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].totalCommissionLastYearTotal,
    },
    {
      category:"Negotiater Commission",
      CurrentMonthCount:this.trendListData[0].negotiatorCommissionCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].negotiatorCommissionPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].negotiatorCommissionSameMonthLastYear,
   
      YeartoDate:this.trendListData[0].negotiatorCommissionYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].negotiatorCommissionProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].negotiatorCommissionLastYearTotal,
    },
    {
      category:"Other Fees	",
      CurrentMonthCount:this.trendListData[0].otherFeesCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].otherFeesPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].otherFeesSameMonthLastYear,
   
      YeartoDate:this.trendListData[0].otherFeesYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].otherFeesProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].otherFeesLastYearTotal,
    },
    {
      category:"Company $	",
      CurrentMonthCount:this.trendListData[0].companyCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].companyPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].companySameMonthLastYear,
   
      YeartoDate:this.trendListData[0].companyYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].companyProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].companyLastYearTotal,
    },
    {
      category:"Opened Files	",
      CurrentMonthCount:this.trendListData[0].currentMonthCount,
      PreviousMonthCount:this.trendListData[0].previousMonthCount,
      SameMonthLastYear:this.trendListData[0].sameMonthLastYear,
   
      YeartoDate:this.trendListData[0].yeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].projectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].lastYearTotal,
    },
    {
      category:"Closed Files	",
      CurrentMonthCount:this.trendListData[0].closedCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].closedPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].closedSameMonthLastYear,
   
      YeartoDate:this.trendListData[0].closedYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].closedProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].closedLastYearTotal,
    },
    {
      category:"Canceled Files	",
      CurrentMonthCount:this.trendListData[0].cancelledCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].cancelledPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].cancelledSameMonthLastYear,
   
      YeartoDate:this.trendListData[0].cancelledYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].cancelledProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].cancelledLastYearTotal,
    },
    {
      category:"User Count	",
      CurrentMonthCount:this.trendListData[0].userCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].userPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].userSameMonthLastYear,
   
      YeartoDate:this.trendListData[0].userYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].userProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].userLastYearTotal,
    },
    {
      category:"Files in Escrow	",
      CurrentMonthCount:this.trendListData[0].fileInEscrowCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].fileInEscrowPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].fileInEscrowSameMonthLastYear,
   
      YeartoDate:this.trendListData[0].fileInEscrowYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].fileInEscrowProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].fileInEscrowLastYearTotal,
    },
    {
      category:"Short Sale Approval	",
      CurrentMonthCount:this.trendListData[0].shortsaleApprovalCurrentMonthCount,
      PreviousMonthCount:this.trendListData[0].shortsaleApprovalPreviousMonthCount,
      SameMonthLastYear:this.trendListData[0].shortsaleApprovalSameMonthLastYear,
   
      YeartoDate:this.trendListData[0].shortsaleApprovalYeartoDate,
      ProjectedAtCurrentPace:this.trendListData[0].shortsaleApprovalProjectedAtCurrentPace,
    LastYearTotal:this.trendListData[0].shortsaleApprovalLastYearTotal,
    },
  ]
      this.searchresTrendList=this.resTrendList
      console.log(this.trenobjlst, "trenobjlst");
      console.log(this.trendListData, "trendListData")
    })
  }

  trendFilter() {
    this.trendOBj.userId = parseInt(this.userId);
    console.log(this.trendOBj.userId, "hello")
    this.trendOBj.fromDate = this.trendFromDateData;
    this.trendOBj.todate = this.trendToDateData;
    this.trendOBj.openClose = parseInt(this.openClose);
    console.log(this.trendOBj, "list");
    this.trendRateGetAllReport();

  }

  trendReset() {
    this.trendOBj.userId = 0;
    this.trendOBj.openClose = 0;
    this.trendOBj.fromDate = '';
    this.trendOBj.todate = '';
    this.userId = '0';
    this.openClose = '0';
    this.trendFromDateData = '';
    this.trendToDateData = '';
    this.trendRateGetAllReport();
  }


  downloadExcel() {
     this.trendOBj = {
      "userId": 0,
      "openClose": 0,
      "fromDate": '',
      "todate": '',
      "createdBy": this.tokenStorage._get_userTypeId()
    }
    this._file_upload.trend_rate_report(this.trendOBj);
  }
  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    console.log("repot id" , this.reportId)
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  onSearch(e: any) {
    console.log("value ", e); 
    var self = this;
    self.resTrendList = self.searchresTrendList.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
 
      }
      else {
        // self.successRateData=this.successRateData
        return false;
      }
    }));
  }
}

