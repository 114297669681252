<div class="authguard_wrapper login-agreement-full-sec" id="downloadPanel" #downloadPanelContent>
  <div class="authdescribe_wrap">
    <div class="aw-inner">
      <h2>Going Above and Beyond To Discover Home Brokers/ Agents</h2>
      <img src="../../../assets/images/auth_bgm.png" alt="bgm" />
    </div>
  </div>
  <!-- <form name="form" (ngSubmit)="loginAgreemnet.form.valid && onsubimtLoginAgreemnet(loginAgreemnet)" #loginAgreemnet="ngForm" novalidate> -->
    
  <form name="form" (ngSubmit)="onsubimtLoginAgreemnet(loginAgreemnet)" #loginAgreemnet="ngForm" novalidate>
    <div class="authforms_wrap login-agreement-main">
      <div class="logo-heading">
        <img width="165px" height="43px" src="../../../assets/images/ISHORTSALE_LOGO_HORIZONTAL_RGB.png" alt="logo" />
        <h4>CONFIDENTIAL</h4>    <a class="dropdown-item"  (click)="logout()">logout</a>

      </div>
      <div class="apex-title-lilblue atl-center">
        <h3>AGREEMENT FOR SHORT SALE SERVICES</h3>
      </div>
      <div class="login-agreement-paragraph">
        <p>This agreement for the provision of transaction assistance services for short sales transactions is made and
          entered into by and between Peak RE Services, Inc. dba Apex Resolution, a California Corporation, a licensed
          real estate broker in
          the state of California with a license number <input class="lic-no lic-nos" name="apexLicenseNo"
            [(ngModel)]="allAgreementList.apexLicenseNo" #apexLicenseNo="ngModel" readonly /> (“Apex”)  and <input
            class="lic-no lic-name" name="apexName" [(ngModel)]="allAgreementList.apexName"  #apexName="ngModel"    style="background-color:#e6e6e6" [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && apexName.invalid }" required> ,a
          licensed real estate agent/broker in the state of

          <select style="background-color:#e4e438" [(ngModel)]="allAgreementList.agentBrokerstateName"
            #agentBrokerstateName="ngModel" class="la-state" [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && agentBrokerstateName.invalid }"  name="agentBrokerstateName" required>
            <option >----Select state---</option>
            <option *ngFor="let state of statelist" [value]="state.stateName">
              {{ state.stateName }}
            </option>
          </select>

          with a license number <input class="lic-no-two" name="apexLicenceNo"
            [(ngModel)]="allAgreementList.agentBrokerLicense" #agentBrokerLicense="ngModel" style="background-color:#e6e6e6; border:1px solid #D6D6D6;"  [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && agentBrokerLicense.errors }" required/> (“Agent/Broker”).</p>
        <p class="line-h-adjust">WHEREAS Apex provides transaction support services to agents/brokers in connection with
          “short sale” transactions (“Apex Services”), and;</p>
        <p class="line-h-adjust">WHEREAS Agent/Broker wishes to employ Apex Services for different transactions in
          return
          for the fee described below in this Agreement, and;</p>
        <ol type="1">
          <p class="line-h-adjust" style="font-weight: normal;">THEREFORE, in consideration of the foregoing and the terms and covenants hereafter
            set
            forth, Apex and Agent/Broker agree as follows:</p>
          <li>Apex will provide the Apex Services to the Agent/Broker for every file referred to Apex by the
            Agent/Broker.</li>
          <li>In consideration of Apex Services, Agent/Broker agrees to pay Apex a referral fee amounting to 1% of
            the
            sale price of the property (“Fee”) and will timely execute the Compensation and Escrow Instruction attached
            herein as exhibit "A"
            no later than 2 business days from escrow open date.</li>
          <li>Apex Services are non-licensed, transaction support services provided to Agent/Broker as independent
            sub-contractor. Apex Services do not constitute legal, brokerage, accounting, or appraisal’s professional
            services and Apex’s responsibilities
            and liability under this Agreement are limited to the scope and nature of its services as such. </li>
          <li>Apex may, from time to time, at its sole discretion, provide Agent/Broker with referrals for potential
            clients (“Referral/s”). Agent/Broker agrees that Apex will provide Apex Services in connection with these
            Referrals for the Fee set
            forth in this Agreement. For buyer side Referrals, Agent/Broker agrees to pay Apex a fee equal to 0.5% of
            the
            sale price of the property. Apex will be paid the Fee for any transaction closed by Agent/Broker as result
            of
            a Referral,
            regardless of whether Apex Services were retained in connection thereof. </li>
          <li>For any transaction referred to Apex, the homeowner-client will sign Apex Client Service Agreement with
            Apex. Agent/Broker will work diligently, with full transparency and cooperation with Apex for the successful
            completion of Apex
            Services and the closing of the transaction. </li>
          <li>To further the business relationship between Apex and Agent/Broker described herein, Apex may, at its
            sole discretion, share with the Agent/Broker certain confidential and propriety information concerning the
            business of Apex (collectively
            referred to as “confidential information”).
            <p class="inside-list-p">For purposes of this Agreement, the term “Confidential Information” means any
              business, financial, or technical information or data in any form or medium, tangible or intangible, used
              in
              or relating to the properties, business activities,
              or operations of Apex which is actually disclosed, either orally or in writing, by Apex to Agent/Broker,
              whether before or after the date of this Agreement, whether machine or user readable, including, without
              limitation. “Confidential
              Information” shall not include information which is (i) publicly available without breach by Agent/Broker
              of
              its to Apex under this Agreement; (ii) known to Agent/Broker prior to Apex’s disclosure of such
              information
              to Agent/Broker;
              (iii) known to Agent/Broker from a source other than Apex, provided that source is not bound by a
              confidentiality or similar agreement with Apex or otherwise prohibited from transmitting such information;
              (iv) independently developed
              by Agent/Broker; (v) disclosed by Apex to a third party free of any duty of confidentiality on the third
              party.</p>
            <p class="inside-list-p">Agent/Broker agrees to receive and hold Confidential Information, whether
              transmitted
              orally, in writing or in any other form, in confidence, use at least the same degree of care it uses to
              prevent the disclosure of its own confidential
              information and at minimum, use reasonable standard of care to prevent the disclosure thereof.
              Agent/Broker
              agrees to use the Confidential Information solely in furtherance of, and in connection with purpose of
              this
              Agreement.
              Agent/Broker further agrees that all Confidential Information will be kept confidential by itself and
              shall
              not be disclosed to or used by Agent/Broker or any third party without the prior written consent of Apex.
            </p>
            <p class="inside-list-p">This Agreement shall continue to govern the treatment of Confidential Information
              and
              shall survive for a period of three years from the date the information is transmitted to Agent/Broker.
              This
              Agreement shall apply to any Confidential
              Information that may have been provided to Agent/Broker prior to the effective date hereof.</p>
          </li>
          <li>This Agreement may be terminated at any time by Agent/Broker or Apex. Notwithstanding termination of
            this
            Agreement, Apex will be entitled to all fees due to it under this Agreement by the expiration or termination
            thereof. </li>
          <li>Both Agent/Broker and Apex will maintain their respective broker/salesperson real estate license in
            good
            standing at all times and each party will notify the other party immediately of any adverse change in its
            license status. With
regard to an Agent/broker who is required by law to practice under the license of a responsible broker (“Broker of
Record”), Agent/Broker must notify Apex of any change in the Broker of Record information in writing, no later
than three days from the effective date of the change or before the receipt of any of Apex Services or Referrals for
a new transaction, per the earlier date. In any event of a change to the Broker of Record information, the
Agent/Broker and Broker of Record will sign a new Agreement with Apex as a condition for the continuity of
Apex Services and Referrals.
</li>
          <li>Indemnification. Agent/Broker agrees to indemnify, defend and hold harmless Apex, its affiliates,
            employees, agents and officers from and against any and all suits, liabilities, causes of action, claims,
            losses, damages, costs and expenses,
            including reasonable attorneys' fees and other legal expenses, arising directly or indirectly from or in
            connection with Agent/Broker’s services to its clients or any breach of this Agreement by Agent/Broker. All
            defense and indemnity
            obligations in this section shall survive termination and/or expiration of this Agreement. </li>
          <li> If Agent/Broker should violate any of the provisions of this Agreement, said violation shall be
            construed as prima facie interference with Apex’s prospective economic interests, advantage and contract and
            Apex shall be entitled to
            a restraining order and an injunction to be issued by any court of competent jurisdiction enjoining and
            restraining Agent/Broker and any other entity concerned therein, from continuance of such violation, in
            addition to any other rights
            and remedies Apex may have. </li>
          <li> Disclosure of fees. In addition to the Fee paid to it under this Agreement, Apex, or one of its
            affiliated entities, might be paid fees out of the sale proceeds, as approved by the lender/lienholder.
            During
            the period of the first
            three months from the date of this Agreement, the total fees paid to Apex will not exceed 1% of the sale
            proceeds. In addition to Apex fees, other fees might be paid to other vendors for related services in
            connection with the short
            sale process. Such vendors may or may not be affiliated with Apex. </li>
          <li> This Agreement pertains to all Apex Services and Referrals made between Agent/Broker and Apex. Apex
            reserves the right to make changes to this Agreement by giving Agent/Broker ten days advance notice of those
            changes. Unless Agent/Broker
            objects in writing to any such changes, they will become effective at the end of the ten-day period and will
            affect transactions made after the notice was initially given. </li>
          <li> This Agreement and all rights and obligations of Agent/Broker hereunder are personal to the
            Agent/Broker
            and may not be transferred or assigned by Agent/Broker to any other person or entity at any time other than
            in
            accordance with
            the terms of this Agreement or with Apex’s prior written consent. </li>
          <li> It is expressly agreed that this Agreement is not intended to, nor does it create, an
            employeremployee
            relationship nor shall it be construed as creating any joint venture or partnership of any kind between Apex
            and Agent/Broker.
            Agent/Broker is not authorized to make any representations on behalf of Apex to any party unless
            Agent/Broker
            has written authorization from Apex to do so. Agent/Broker shall be responsible for all applicable federal,
            state and other
            taxes related to Agent/Broker and its agents’ earnings and income (if any) and Apex shall not withhold or
            pay
            any such taxes on behalf of Agent/Broker or its agents, including without limitation social security,
            federal,
            state and
            other local income taxes. </li>
          <li> This Agreement has been negotiated, executed and delivered at and shall be deemed to have been made in
            California. This Agreement shall be governed by and construed in accordance with the domestic laws of the
            State of California without
            giving effect to any choice or conflict of law provision or rule (whether of the State of California or any
            other jurisdiction) that would cause the application of the laws of any jurisdiction other than the State of
            California. The
            prevailing party in such legal action shall be entitled to reasonable attorneys' fees, costs incurred, and
            any
            other relief to which such party may be entitled. , If any court should find any provision hereof
            unenforceable, the remaining
            provisions shall remain in full force and effect to the fullest extent permitted by law. </li>
          <li> If any provision of this Agreement is determined to be invalid, illegal or unenforceable at any time
            after the effective date below, such provision shall be modified to reflect the parties’ intention. All
            remaining provisions shall
            not be affected or impaired thereby, and no provision shall be deemed dependent upon any other provision
            unless so expressed herein. </li>


        </ol>

        <p>THIS AGREEMENT HAS BEEN READ, UNDERSTOOD, AND EXECUTED on: <span
            class="date_picker login_agreemnentIc">
<!--
            <dp-date-picker  theme="dp-material" [config]="datePickerConfig"
              [(ngModel)]="allAgreementList.executeDate" >
            </dp-date-picker> -->

            <input type="text" name="executeDate" [(ngModel)]="allAgreementList.executeDate"
          #executeDate="ngModel" style="background-color:#e4e438" readonly/>
          </span>at (City)
          <!-- <select name="executeCity" [(ngModel)]="allAgreementList.executeCity" #executeCity="ngModel" class="la-state">
            <option [ngValue]="null" selected>----Select City---</option>
            <option *ngFor="let city of citylist" [value]="city.cityId">
              {{ city.cityName }}
            </option>
          </select> -->
          <input type="email" style="background-color:#e4e438"  name="executeCity" [(ngModel)]="allAgreementList.executeCity"
          [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && executeCity.errors }"   #executeCity="ngModel" required/>


          (State)
          <select style="background-color:#e4e438" name="executestate" [(ngModel)]="allAgreementList.executestate" #executestate="ngModel"  [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && executestate.errors?.required }"
            class="la-state" required>
            <option [ngValue]="null" selected>----Select state---</option>
            <option *ngFor="let state of statelist" [value]="state.stateName"  >
              {{ state.stateName }}
            </option>
          </select> , and the undersigned have received a copy of this instrument.</p>

      </div>


      <ng-container>

        <div class="agreement-sss" >
          <div >
            <div class="apex-title-lilblue">
              <h3>Agent/Broker</h3>
            </div>
            <div class="apexforms-group lia-form-sec">
              <div class="row no-margins">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Full Name <span>*</span> </label>
                    <input style="background-color : #EDEDED" type="email" name="user_Name" [(ngModel)]="allAgreementList.user_Name"
                      [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && user_Name.invalid }" #user_Name="ngModel"
                      required />
                    <div *ngIf="loginAgreemnet.submitted && user_Name.invalid" class="invalid-feedback">
                      <div *ngIf="user_Name.errors?.required">Full Name is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Email address  <span>*</span></label>
                    <input style="background-color : #EDEDED" type="text" name="emailAddress" [(ngModel)]="allAgreementList.user_Email"
                      [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && user_Email.invalid }" #user_Email="ngModel"
                      required  />
                    <div *ngIf="loginAgreemnet.submitted && user_Email.invalid" class="invalid-feedback">
                      <div *ngIf="user_Email.errors?.required">Email is required</div>
                      <div *ngIf="user_Email.errors?.email">Email must be a valid email address</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Real estate license<span>*</span></label>
                    <input style="background-color : #EDEDED" type="text" name="realEstateLicenseNo" [(ngModel)]="allAgreementList.user_RENo"
                      [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && user_RENo.invalid }" #user_RENo="ngModel"
                      required />
                    <div *ngIf="loginAgreemnet.submitted && user_RENo.invalid" class="invalid-feedback">
                      <div *ngIf="user_RENo.errors?.required">Real estate license is required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row rmt-part no-margins">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Mailing address <span>*</span> </label>
                    <input style="background-color : #EDEDED" type="email" name="mailingAddress" [(ngModel)]="allAgreementList.user_MailingAddress"
                      [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && user_MailingAddress.invalid }"
                      #user_MailingAddress="ngModel" required />
                    <div *ngIf="loginAgreemnet.submitted && user_MailingAddress.invalid" class="invalid-feedback">
                      <div *ngIf="user_MailingAddress.errors?.required">Mailing address is required</div>
                    </div>
                  </div>

                  <div class="apexinput-froms">
                    <label>State  <span>*</span></label>
                    <select style="background-color : #EDEDED"  name="user_State" [(ngModel)]="allAgreementList.user_State"
                           class="la-state"  [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && user_State.invalid }"  #user_State="ngModel" required>
                      <option [ngValue]="null" selected>----Select state---</option>
                        <option *ngFor="let state of statelist" [value]="state.stateName">
                           {{ state.stateName }}
                          </option>
                            </select>
                            <div *ngIf="loginAgreemnet.submitted && user_State.invalid" class="invalid-feedback">
                              <div *ngIf="user_State.errors?.required">State is required</div>
                            </div>
                  </div>

                    <div class="apexinput-froms">
                    <label>Phone number  <span>*</span></label>

                    <input type="text"  style="background-color : #EDEDED" prefix="" mask="(000) 000-0000" name="phoneNumber" [(ngModel)]="allAgreementList.user_Phone"
                      [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && user_Phone.invalid }" #user_Phone="ngModel"
                      required  [validation]="true"/>
                    <div *ngIf="loginAgreemnet.submitted && user_Phone.invalid" class="invalid-feedback">
                      <div *ngIf="user_Phone.errors?.required">Phone number is required</div>
                      <div *ngIf="user_Phone.errors?.phoneNumber">Number Must contains 10 Digits </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                   <div class="apexinput-froms">
                    <label>City  <span>*</span></label>
                    <input type="text" style="background-color : #EDEDED"  name="user_City" [(ngModel)]="allAgreementList.user_City"  [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && user_City.invalid }"
                      #user_City="ngModel"  required/>
                      <div *ngIf="loginAgreemnet.submitted && user_City.invalid" class="invalid-feedback">
                        <div *ngIf="user_City.errors?.required">City is required</div>
                      </div>
                  </div>


                  <div class="apexinput-froms">
                    <label>Zipcode  <span>*</span></label>
                    <input style="background-color : #EDEDED" type="text" name="user_Zipcode" [(ngModel)]="allAgreementList.user_Zipcode"
                    [ngClass]="{ 'is-invalid': loginAgreemnet.submitted && user_Zipcode.invalid }"  #user_Zipcode="ngModel" required />

                      <div *ngIf="loginAgreemnet.submitted && user_Zipcode.invalid" class="invalid-feedback">
                        <div *ngIf="user_Zipcode.errors?.required">Zipcode is required</div>
                      </div>
                  </div>

                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 signaturepad_wrap">
                  <div class="apexinput-froms">
                    <label>Signature  <span>*</span></label>
                    <!-- <ng-signature-pad required doneButtonText="Submit" format="base64" (done)="showImage1($event)">
                    </ng-signature-pad> -->
                       <input type="hidden">
                  <ng-signature-pad format="blob" (done)="showImage1($event)" [(points)]="imgIsEmpty" [emitOnDragEnd]=true>
                  </ng-signature-pad>

                  <!-- <a (click)="clearsign()">clears</a> -->
                  </div>
                  <div *ngIf="formSubmited && imgIsEmpty.length == 0" style="display: block;" class="invalid-feedback">
                    <div *ngIf="imgIsEmpty.length == 0">Signature is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="agreement-sss" *ngIf="usertype == 'Broker'">
          <div class="apex-title-lilblue">
            <h3>Broker of Record</h3>
          </div>
          <div class="apexforms-group lia-form-sec">
            <div class="row no-margins">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="apexinput-froms">
                  <label>Full Name </label>
                  <input type="email" name="brokerFullName" value=" " [(ngModel)]="allAgreementList.brokerFullName"

                    #brokerFullName="ngModel" readonly/>
                  <!-- <div *ngIf="loginAgreemnet.submitted && brokerFullName.invalid" class="invalid-feedback">
                    <div *ngIf="brokerFullName.errors?.required">Full Name is required</div>
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="apexinput-froms">
                  <label>Email address</label>
                  <input type="email" name="brokerEmailAddress" value=" "

                    [(ngModel)]="allAgreementList.brokerEmailAddress" #brokerEmailAddress="ngModel"  readonly/>
                  <!-- <div *ngIf="loginAgreemnet.submitted && brokerEmailAddress.invalid" class="invalid-feedback">
                    <div *ngIf="brokerEmailAddress.errors?.required">Email is required</div>
                    <div *ngIf="brokerEmailAddress.errors?.email">Email must be a valid email address</div>
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="apexinput-froms">
                  <label>Real estate license</label>
                  <input type="text" name="brokerRealEstateLicenseNo"

                    [(ngModel)]="allAgreementList.brokerRealEstateLicenseNo" value=" "  #brokerRealEstateLicenseNo="ngModel"
                    readonly />

                </div>
              </div>
            </div>
            <div class="row rmt-part no-margins">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="apexinput-froms">
                  <label>Mailing address</label>
                  <input type="text" name="brokerMailingAddress"  value=" " [(ngModel)]="allAgreementList.brokerMailingAddress"
                    #brokerMailingAddress="ngModel"   readonly/>

                </div>
                <div class="apexinput-froms">
                  <label>State</label>
                  <!-- <select name="agentstate" [(ngModel)]="allAgreementList.agentstate" #agentstate="ngModel"
                         class="la-state">
                    <option [ngValue]="null" selected>----Select state---</option>
                      <option *ngFor="let state of statelist" [value]="state.stateName">
                         {{ state.stateName }}
                        </option>
                          </select> -->
                          <input type="text" name="brokerState"  value=" "[(ngModel)]="allAgreementList.brokerState"
                          #brokerState="ngModel"  readonly/>

                </div>
                <div class="apexinput-froms">
                  <label>City</label>
                  <input type="text" name="brokerCity" value=" " [(ngModel)]="allAgreementList.brokerCity"
                    #brokerCity="ngModel"  readonly/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                <div class="apexinput-froms">
                  <label>Zipcode</label>
                  <input type="text" name="brokerZipcode" value=" " [(ngModel)]="allAgreementList.brokerZipcode"
                    #brokerZipcode="ngModel" readonly />
                </div>
                <div class="apexinput-froms">
                  <label>Phone number</label>
                  <input type="text" value=" " prefix="" mask="(000) 000-0000" name="brokerPhoneNumber" [(ngModel)]="allAgreementList.brokerPhoneNumber"
                    #brokerPhoneNumber="ngModel" readonly>


                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 signaturepad_wrap">
                <div class="apexinput-froms">
                  <label>Signature</label>
                  <!-- <p>{{allAgreementList.user_SignatureData}}</p> -->

                  <img [src]="allAgreementList.brokersignatureData">
                  <!-- <input type="hidden"> -->
                  <!-- <ng-signature-pad required format="blob" (done)="showImage1($event)" [emitOnDragEnd]=true>
                  </ng-signature-pad> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>




      <div class="aia-end-section col-xl-12">
        <label>Territories Agent/Broker would like to provide services in: (Cities/Zip Codes) </label>
        <textarea name="user_Territories" [(ngModel)]="allAgreementList.user_Territories"
         #user_Territories="ngModel"
          ></textarea>
        <!-- <div *ngIf="loginAgreemnet.submitted && user_Territories.invalid" class="invalid-feedback">
          <div *ngIf="user_Territories.errors?.required">Territories Agent/Broker is required</div>
        </div> -->

        <div class="apxbtn_finalcenter">
          <!-- <button class="btn_grey" type="reset">Cancel</button> -->
          <button class="btn_dblue">Submit</button>
        </div>
      </div>

    </div>
  </form>
</div>
