import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
@Component({
  selector: 'app-closer-edit',
  templateUrl: './closer-edit.component.html',
  styleUrls: ['./closer-edit.component.css']
})

export class CloserEditComponent implements OnInit {
  addCloser: any = {};
  getCloser: any = {};
  id: any = {};
  updateCloser: Boolean = false;
  titleDetail = ['Title', 'Title-1'];
  message: any;


  constructor(private toast: ToastService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }
  ngOnInit(): void {
    this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // let id = this._manager_service.getId();
    if (this.id !== null || undefined) {

      this.updateCloser = true;
      this._getClosers_byId(this.id)
    }

  }

  _create_closers() {
    console.log(this.addCloser)
    if (this.updateCloser) {
      let boolValue = (/true/i).test(this.addCloser.active);
      this.addCloser['active'] = boolValue;
      this.addCloser.createdBy = "SuperAdmin";
      this._manager_service._update_Closers(this.addCloser).subscribe((data: any) => {
        console.log("update", data)
        if (data.reponse == true && data.message == "Invaild Domail mail") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id")
        } else {
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/closer-list'])

        }

      })
    }
    else {
      let boolValue = (/true/i).test(this.addCloser.active);
      this.addCloser['active'] = boolValue;
      this.addCloser.createdBy = "SuperAdmin";
      this._manager_service.add_Closers(this.addCloser).subscribe((data: any) => {
        if (data.reponse == true && data.message == "Invaild Domail mail") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id")
        } else {
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/closer-list'])

        }
      })
    }
  }

  _getClosers_byId(id: number) {
    this._manager_service._get_ClosersByid(id).subscribe((data: any) => {
      console.log(data)
      this.addCloser = data['getData'];
    })


  }
}
