import { email, unique, alpha, compare, prop, propArray, propObject, required } from "@rxweb/reactive-form-validators"

export class contactLenderModel {

    @prop()
    lenderName?: string = "";
    @prop()
    phone?: string = "";
    @prop()
    fax?: string ="";
    @prop()
    repName?: string = "";
    @prop()
    lenderId?: number = 0;
}

export class assinedStaffInfoModel {
    @prop()
    clientAssignId?: number = 0;
    @prop()
    leadChildId?: number = 0;
    @prop()
    userId: number = 0;
    @prop()
    userTypeId: number = 0;
    @prop()
    userfullname: string = "";
    @prop()
    userType: string = "";
    @prop()
    email: string = "";
    @prop()
    phone: string="";
}

// Main Model

export class clientContacts {

    clientId?: number;
    @prop()
    borrowerFirstName: string=" ";
    @prop()
    borrowerLastName: string="";
    @prop()
    cellNo: string="";
    @prop()
    borrowerEmail: string="";
    @prop()
    cborrowFirstName: string="";
    @prop()
    cborrowLastName: string="";
    @prop()
    lombname: string = "";
    @prop()
    lombcompany: string = "";
    @prop()
    lombemail: string = "";
    @prop()
    lombphone: string = "";
    @prop()
    branchName?: "";
    @prop()
    branchCompany?: "string";
    @prop()
    branchEmail?: "string";
    @prop()
    branchPhone?: "string";
    @prop()
    buyer1: string = "";
    @prop()
    buyer2: string = "";
    @prop()
    buyer3?: string = "";

    @propArray(contactLenderModel)
    contactLender: contactLenderModel[];

    @propArray(assinedStaffInfoModel)
    assinedStaffInfo: assinedStaffInfoModel[];

}