import { required,prop,propObject,propArray } from "@rxweb/reactive-form-validators";

// import {homeOwner} from '../models/lead.model';
// import {leadmodel} from '../models/lead.model';

export class validation{
   
    // @propObject(homeOwner)
    // homeOwner? :homeOwner;
    // @propObject(leadmodel)
    // leadmodel?:leadmodel;

}