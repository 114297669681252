<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Reports">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>User Usage  Report</h2>
        </div>
      </div>
      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap">
          <div class="filterapx_wrap report_wrap_fil">
            <div class="filter_setting_forms">
              <h3>Filter By</h3>
              <div class="apxsilsetting_group">
                <label>User Type</label>
                <!-- <input type="text" value="George"> -->
                <!-- <select class="form-control" [(ngModel)]="userTypeId" (change)="getUserListById($event)">
                  <option value="0">--All--</option>
                  <option *ngFor="let item of userType" [value]="item.userTypeId">{{item.userTypeName}}
                  </option>
                </select> -->

                <mat-form-field appearance="fill">
                
                  <mat-select [(ngModel)]="userTypeId" (selectionChange)="getUserListById($event.value)"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option>--Select--</mat-option>
                    <input type="text" (keyup)="onSearchUsersType($event)" (keydown)="$event.stopPropagation()">
                    <mat-option value="0">--All--</mat-option>
                    <mat-option *ngFor="let item of userType" [value]="item.userTypeId">
                      {{item.userTypeName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
               
              </div>


              <!-- <div class="apxsilsetting_group"> -->
                <!-- <label>User Name</label> -->
                <!-- <select class="form-control" [(ngModel)]="userIdData">
                  <option value="0">--All--</option>
                  <option *ngFor="let item of userList" [value]="item.userId">
                    {{item.userName}}
                  </option>
                </select> -->
                <!-- <mat-form-field appearance="fill">
                  <mat-select [(ngModel)]="userIdData" [ngModelOptions]="{standalone: true}">
                    <input type="text" (keyup)="onSearchUsers($event)" (keydown)="$event.stopPropagation()">
                    <mat-option value="0">--All--</mat-option>
                    <mat-option *ngFor="let item of userList" [value]="item.userId">
                      {{item.userName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              <!-- </div> -->

                <!-- <div class="apxsilsetting_group">
                <label class="report_date">From Date</label>
                <dp-date-picker theme="dp-material" [(ngModel)]="userUsageFromDateData" [config]="datePickerConfig"
                  required="true">
                </dp-date-picker>

              </div>
              <div class="apxsilsetting_group">
                <label class="report_date">To date</label>
                <dp-date-picker theme="dp-material" [(ngModel)]="userUsageToDateData" [config]="datePickerConfig"
                  required="true">
                </dp-date-picker>
              </div> -->
              <!-- <div class="apxsilsetting_group">
                <label>Open/Close</label>
                <select class="form-control" [(ngModel)]="openClose">
                  <option value="0">--All--</option>
                  <option value="1">--Close--</option>
                  <option value="2">--Open--</option>
                </select>

              </div>
              <div class="apxsilsetting_group">
                <label>From Date</label>
                <dp-date-picker theme="dp-material" [(ngModel)]="trendFromDateData" [config]="datePickerConfig"
                  required="true">
                </dp-date-picker>

              </div>
              <div class="apxsilsetting_group">
                <label>To date</label>
                <dp-date-picker theme="dp-material" [(ngModel)]="trendToDateData" [config]="datePickerConfig"
                  required="true">
                </dp-date-picker>
              </div> -->
            </div>
            <div class="filter_setting">
              <ul>
                <!-- <li class="filter_apx"><img src="../../../assets/images/filter.png" alt="filter" (click)="filterList()">
                </li> -->
                <li class="filter_apx"><img src="../../../assets/images/filter.png" alt="filter"
                    (click)="userUsageFilter()">
                </li>
                <li class="setting_reset"><img src="../../../assets/images/setting_reset.png" alt="filter"
                    (click)="userUsageReset()"></li>
              </ul>
            </div>
          </div>

        </div>
        <div class="apxtable_grids_wrapper">
          <div class="apxtable_grid_head">
            <h2>List of Reports</h2>
            <select [(ngModel)]="reportName" class="form-control sel-frm-control" (change)="onselectReport($event)">
                        <!-- <option value="">--All--</option> -->
              <option value="report-short-sales">All open files/short sales </option>
              <option value="closed-file-report">All closed files</option>
              <!-- <option value="numberoffile">Number of file per stage</option> -->
              <!-- <option value="openclose-report">Open/closed files per</option> -->
              <option value="filesperfor-closure-report">Files per foreclosure date in the next 30 days </option>
              <option value="financial-reportt">Financial Report</option>
              <option value="file-data-report">File data report</option>
              <option value="lead-success-rate-report">Lead Success rate report</option>
              <option value="cancel-file-report">Report for cancel file</option>
              <option value="success-rate-report">Success rate report</option>
              <option value="trend-report">Trend Report</option>
              <option value="user-usage-report">User usage report</option>
              <!-- <option value="user-usage-date-report">User usage report - date range search ability</option> -->

              <option value="user-report">User report</option>
            </select>

            <div class="sidebar-search search-auto">
              <div>
                <div class="input-group">
                  <div class="input-group-append">
                  </div>
                  <input type="search" class="form-control search-menu" placeholder="Search..."
                  (input)="onSearch($event)" #searchkey>

                </div>
              </div>
            </div>

            <div class="apxtable_headbtn">
              <button (click)="downloadExcel()" class="btn-export-down"><svg width="20" height="20" viewBox="0 0 20 20"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.525 8.45391C16.116 8.05039 15.5746 7.82812 15 7.82812C14.4199 7.82812 13.8746 8.05391 13.4645 8.46445L12.1875 9.74102V2.1875C12.1875 0.98125 11.2062 0 10 0C8.79375 0 7.8125 0.98125 7.8125 2.1875V9.74102L6.55508 8.48359C6.14492 8.07344 5.575 7.83867 4.99062 7.83867H4.9832C4.39961 7.84062 3.85664 8.0668 3.45391 8.475C3.05039 8.88398 2.82812 9.42539 2.82812 10C2.82812 10.5801 3.05391 11.1254 3.46445 11.5355L8.7293 16.8008C9.07969 17.1512 9.53984 17.3262 10 17.3262C10.4602 17.3262 10.9203 17.1512 11.2707 16.8008L16.5164 11.5551C16.9281 11.1434 17.1633 10.5703 17.1613 9.9832C17.1594 9.39961 16.9332 8.85664 16.525 8.45391Z"
                    fill="#9B59B6" />
                  <path
                    d="M19.375 20H18.4375C18.0923 20 17.8125 19.7202 17.8125 19.375C17.8125 19.0298 18.0923 18.75 18.4375 18.75H19.375C19.7202 18.75 20 19.0298 20 19.375C20 19.7202 19.7202 20 19.375 20Z"
                    fill="#9B59B6" />
                  <path
                    d="M1.5625 20H0.625C0.279844 20 0 19.7202 0 19.375C0 19.0298 0.279844 18.75 0.625 18.75H1.5625C1.90766 18.75 2.1875 19.0298 2.1875 19.375C2.1875 19.7202 1.90766 20 1.5625 20Z"
                    fill="#9B59B6" />
                  <path
                    d="M16.25 20H3.75C3.40484 20 3.125 19.7202 3.125 19.375C3.125 19.0298 3.40484 18.75 3.75 18.75H16.25C16.5952 18.75 16.875 19.0298 16.875 19.375C16.875 19.7202 16.5952 20 16.25 20Z"
                    fill="#9B59B6" />
                </svg>
                Download / Export</button>


            </div>
          </div>
          <div class="apxtable_grid_table">
            <div class="table-responsive apx-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th [class.active]="order === 'clientName'" (click)="setOrder('clientName')">User Name <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'fileNo'" (click)="setOrder('fileNo')">User Type <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'assigingDate'" (click)="setOrder('assigingDate')">Last Login
                      Date<span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'forclosureDate'" (click)="setOrder('forclosureDate')">
                      Last 24 Hours<span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'completedDate'" (click)="setOrder('completedDate')">
                      Last 7 Days<span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'completedDate'" (click)="setOrder('completedDate')">
                      Last 30 Days<span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>

                  </tr>
                </thead>

                <tbody>
                  <!-- <tr
                    *ngFor="let managelist of filterData | orderBy: order:reverse | paginate: { itemsPerPage: 6, currentPage: p } "> -->
                  <tr
                    *ngFor="let userUsageList of userUsageListData | orderBy: order:reverse | paginate: { itemsPerPage: 45, currentPage: p } ; let i = index ">
                    <td>{{ i+1}}</td>
                    <td>{{ userUsageList?.userName  || '--'}}</td>
                    <td>{{ userUsageList?.usertype  || '--'}}</td>
                    <td>{{ userUsageList?.lastLoginDateTime  || '--'}}</td>
                    <td>{{ userUsageList?.last24Hours || '--'}}</td>
                    <td>{{ userUsageList?.last7days || '--'}}</td>
                    <td>{{ userUsageList?.last30Days || '--'}}</td>

                  </tr>

                </tbody>
              </table>
              <div class="nodata_found_components no_data_found" *ngIf="userUsageListData.length == 0">
                <div colspan="6">No Data found</div>
              </div>
            </div>

          </div>
        </div>
        <div class="apxtable_pagination_wrapper">
          <!-- <div class="apxtable_pagelist">
            <div class="apxlist_number_page">
              <span>Show</span>
              <input value="7" type="number" />
              <span>Entries</span>
            </div>
            <div class="pagination_gopage">
              <span>Go to Page</span>
              <input type="text" value="1" />
            </div>
          </div> -->
          <div class="apxtable_pagination">
            <!-- <ul>
                  <li class="prev">Prev</li>
                  <li class="active"><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li>...</li>
                  <li><a href="#">16</a></li>
                  <li><a href="#">17</a></li>
                  <li><a href="#">18</a></li>
                  <li class="next">Next</li>
                </ul> -->
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
        </div>

      </div>
    </div>
  </main>

</div>



<!-- The Modal -->
<div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel">Cancel</button>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="filterforms_setting">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>First Name</label>
                <input type="text" placeholder="First name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Last Name</label>
                <input type="text" placeholder="Last name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Status</label>
                <select>
                  <option value="">All</option>
                  <option value="">All-1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>



<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <img src="../../../assets/images/danger.png" alt="danger" />
          <p>Are you sure want to delete the data?</p>
          <div class="filterbt_btnn btn_deletecard">
            <button class="btnfilter-cancel">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
