<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Lead"></app-header>
    <form [formGroup]="nestedFormGroup" #leadForm="ngForm" (ngSubmit)="nestedFormGroup.valid && addLead()" novalidate>
      <div class="container-fluid contentpage_wrapper">
        <div class="apexpage-titletop">
          <div class="apxpage-title_wrap">
            <h2>Rejected lead Detail</h2>
            <button class="btn_to_btitle"><a routerLink="/superadmin/brokers-list">back</a></button>
          </div>
        </div>

        <div class="pagecontent_wrapper">
          <div class="apex-white_wrap newheight-apx">
            <div class="apex-title-lilblue">
              <h3>Lead details</h3>
            </div>
            <!-- cccccc -->
            <div [formGroup]="nestedFormGroup.controls.homeOwner">
              <div class="apexforms-group lead-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner First Name*</label>
                      <input type="text" placeholder="First Name" formControlName="firstName">
                      <p class="form-text text-danger"
                        *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.firstName.errors">
                        FirstName Required</p>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner Last Name *</label>
                      <input type="text" placeholder="Last Name" name="lastName" formControlName="lastName">
                      <div class="form-text text-danger"
                        *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.lastName.errors">
                        LastName Required</div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner</label>
                      <input type="text" placeholder="Last Name">
                    </div>
                  </div>
                </div>
              </div>
              <div class="apexforms-group lead-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner Address</label>
                      <textarea name="address" placeholder="P.O. Box 283 8562 Fusce Rd. Frederick Nebraska 20620"
                        formControlName="address"></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner country</label>
                      <select name="countryName" (input)="select_dropdown($event,'state', true)">
                        <option selected disabled>----Select County---</option>
                        <option *ngFor="let country of countrylist" [value]="country.countryId">
                          {{ country.countryName }}
                        </option>
                      </select>
                    </div>
                    <div class="apexinput-froms">
                      <label>Zip Code</label>
                      <input type="text" placeholder="enter zip code" name="zipCode" formControlName="zipCode">
                    </div>
                  </div>

                  <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div class="apexinput-froms">
                      <label>Owner State </label>
                      <select name="stateName" formControlName=stateId (input)="select_dropdown($event,'city',true)">
                        <option selected disabled>----Select State---</option>
                        <option>tn</option>
                        <option *ngFor="let state of statelist" [value]=state.stateId>
                          {{ state.stateName }}
                        </option>
                      </select>
                    </div>

                    <div class="apexinput-froms">
                      <label>Owner City</label>
                      <select name="cityName" formControlName="city">
                        <option selected>----Select City---</option>
                        <option *ngFor="let city of citylist" [value]="city.cityName">
                          {{ city.cityName }}
                        </option>
                      </select>
                    </div>
                  </div>



                </div>
                <!-- ccc -->
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="row lg-l">
                    <div class="apexinput-froms col-sm-6">
                      <label>Primary Residence</label>
                      <select name="primayResidence" formControlName="primayResidence">
                        <option selected disabled>---select primayResidence---</option>
                        <option>malapuram</option>
                      </select>
                    </div>
                    <div class="apexinput-froms col-sm-6">
                      <label>Property Type</label>
                      <select name="propertyType" formControlName="propertyType">
                        <option>CND</option>

                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="row lg-l">
                    <div class="apexinput-froms col-sm-6">
                      <label>Units</label>
                      <select name="unit" formControlName="unit">
                        <option>1</option>
                        <option>2</option>
                      </select>
                    </div>
                    <div class="apexinput-froms col-sm-6">
                      <label>SqFt</label>
                      <input type="text" placeholder="2400" name="sqFt" formControlName="sqFt">
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="row lg-l">
                    <div class="apexinput-froms col-sm-6">
                      <label>Year Built</label>
                      <input type="text" placeholder="2020" value="" name="yearBuild" formControlName="yearBuild">
                    </div>
                    <div class="apexinput-froms col-sm-6">
                      <label>Beds</label>
                      <select name="noofBeds" formControlName="noofBeds">
                        <option>8</option>
                        <option>8</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                  <div class="row lg-l">
                    <div class="apexinput-froms col-sm-6">
                      <label>Baths</label>
                      <select name="noofBaths" formControlName="noofBaths">
                        <option>3</option>
                      </select>
                    </div>
                    <div class="apexinput-froms col-sm-6">
                      <label>Lot Size</label>
                      <input type="text" placeholder="59886" formControlName="lotSize">
                    </div>
                  </div>

                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Estimated Value *</label>
                    <input type="text" placeholder="$391900" value="" name="estimatedValue"
                      formControlName="estimatedValue">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Assessed Value</label>
                    <input type="text" placeholder="$275627" value="" name="asseddedValue"
                      formControlName="asseddedValue">
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">


                  <div class="apexinput-froms">
                    <label>Fore closure Stage</label>
                    <select name="foreClosureStage" formControlName="foreClosureStage">
                      <option> Pre fore closure</option>
                    </select>
                  </div>



                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Recording Date</label>
                    <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                    <dp-date-picker placeholder="29-Apr-2020" theme="dp-material" [config]="datePickerConfig"
                      required="true" name="recordingDate" formControlName="recordingDate">
                    </dp-date-picker>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Sale Date</label>
                    <!-- <input type="text" placeholder="27-Aug-2020" value="" name="" /> -->
                    <dp-date-picker placeholder="27-Aug-2020" theme="dp-material" [config]="datePickerConfig"
                      name="salesDate" formControlName="salesDate">
                    </dp-date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Default As Of</label>
                    <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                    <dp-date-picker placeholder="29-Apr-2020" theme="dp-material" [config]="datePickerConfig"
                      required="true" name="defaultAsOf" formControlName="defaultAsOf">
                    </dp-date-picker>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>FC Estimated Loan Position</label>
                    <input type="text" placeholder="2" value="" name="fcEstimatedLoanPosition"
                      formControlName="fcEstimatedLoanPosition">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>FC Loan Date</label>
                    <dp-date-picker placeholder="27-Aug-2020" theme="dp-material" formControlName="fcLoanDate"
                      [config]="datePickerConfig" name="fcLoanDate" required="true">
                    </dp-date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>FC Loan Amount</label>
                    <input type="text" placeholder="$391900" value="" name="fcLoanAmount"
                      formControlName="fcLoanAmount">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>First Loan Date</label>
                    <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                    <dp-date-picker placeholder="29-Apr-2020" theme="dp-material" formControlName="firstLoanDate"
                      [config]="datePickerConfig" required="true" name="firstLoanDate">
                    </dp-date-picker>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>First Loan Amount</label>
                    <input type="text" placeholder="$391900" value="" name="firstLoanAmount"
                      formControlName="firstLoanAmount">
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Second Loan Amount</label>
                    <input type="text" placeholder="$91900" value="" name="secondLoanAmount"
                      formControlName="secondLoanAmount">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Phone Number 1</label>
                    <input type="text" mask="(000) 000-0000" value="" name="phoneNumber1"
                      formControlName="phoneNumber1">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Phone Number 2</label>
                    <input type="text" mask="(000) 000-0000" value="" name="phoneNumber2"
                      formControlName="phoneNumber2">
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Phone Number 3</label>
                    <input type="text" mask="(000) 000-0000" value="" name="phoneNumber3"
                      formControlName="phoneNumber3">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Phone Number 4</label>
                    <input type="text" mask="(000) 000-0000" value="" name="phoneNumber4"
                      formControlName="phoneNumber4">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Email 1</label>
                    <input type="text" placeholder="alejandrogomez@aol.com" value="" name="email1"
                      formControlName="email1">
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Email 2</label>
                    <input type="text" placeholder="alejandrogomez88@aol.com" value="" name="email2"
                      formControlName="email2">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>APN</label>
                    <input type="text" placeholder="5271-012-888" value="" name="apn" formControlName="apn">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>County</label>
                    <select name="countryName" formControlName=countryId (input)="select_dropdown($event,'state',true)">
                      <option selected disabled>----Select County---</option>
                      <option *ngFor="let country of countrylist" [value]="country.countryId">
                        {{ country.countryName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Address *</label>
                    <textarea placeholder="P.O. Box 283 8562 Fusce Rd. Frederick Nebraska 20620" name="address"
                      formControlName="address">
                  </textarea>
                    <div class="form-text text-danger"
                      *ngIf="leadForm.submitted && nestedFormGroup.controls.address.errors">Address Required</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>City *</label>
                    <select name="cityName" formControlName="cityId">
                      <option selected>----Select City---</option>
                      <option *ngFor="let city of citylist" [value]="city.cityId">
                        {{ city.cityName }}
                      </option>
                    </select>
                    <div class="form-text text-danger"
                      *ngIf="leadForm.submitted && nestedFormGroup.controls.cityId.errors">City Required</div>
                  </div>
                  <div class="apexinput-froms">
                    <label>Zip Code</label>
                    <input type="text" placeholder=" Pico Rivera" value="" name="zipCode" formControlName="zipCode">
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>State *</label>
                    <select name="stateName" formControlName=stateId (input)="select_dropdown($event,'city',true)">
                      <option selected disabled>----Select State---</option>
                      <option *ngFor="let state of statelist" [value]=state.stateId>
                        {{ state.stateName }}
                      </option>
                    </select>
                    <div class="form-text text-danger"
                      *ngIf="leadForm.submitted && nestedFormGroup.controls.stateId.errors">State Required</div>
                    <!-- <div *ngIf="addEdit.submitted && stateName.invalid" class="invalid-feedback">
                    <div *ngIf="stateName.errors?.required">StateName is required</div>                   
                </div> -->
                  </div>

                </div>
              </div>
            </div>
            <div class="apex-title-lilblue">
              <h3>Assign Lead</h3>
            </div>
            <div class="prev_assigned_detail">
              <h4>Previous Assigned Detail</h4>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Assigned To</label>
                    <span>Manager</span>

                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Manager Name</label>
                    <span>Scarlet Johnson</span>
                  </div>
                </div>

              </div>
            </div>
            <div class="apexforms-group lead-group prev-lead-group" [formGroup]="formGroup"
              *ngFor="let formGroup of nestedFormGroup.controls.leadChildAssign.controls;let i = index;">

              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Re-Assign To</label>
                    <select name="userType" formControlName="userTypeId"
                      (input)="select_dropdown($event,'userType',true)">
                      <option selected disabled>----Select UserType---</option>
                      <option *ngFor="let usertypelist of userTypes" [value]="usertypelist.userTypeId">
                        {{ usertypelist.userTypeName }}
                      </option>

                    </select>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Manager Name</label>
                    <select name="userfullName" formControlName="userId">
                      <option>---Select User-----</option>
                      <option *ngFor="let userlist of  userLists" [value]="userlist.userId">
                        {{ userlist.userName }}
                      </option>


                    </select>
                  </div>
                </div>

              </div>
            </div>

            <div class="apxbtn_finalcenter apx-mt-15">
              <button type="reset" class="btn_grey" type="reset">Cancel</button>
              <button class="btn_dblue">save</button>
            </div>
          </div>
        </div>




        <div class="pagecontent_wrapper">
          <div class="apex-white_wrap newheight-apx">
            <div class="apex-title-lilblue">
              <h3> Rejected Reason</h3>
            </div>

            <div class="apexforms-group lead-group rejected-group">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="apexinput-froms">
                    <label>Note</label>
                    <input type="text" class="notfi_list" formControlName="rejectedReason" />
                  </div>
                </div>

              </div>
            </div>




          </div>
        </div>





      </div>
    </form>
  </main>
</div>
