import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';

@Component({
  selector: 'app-closed-file-report',
  templateUrl: './closed-file-report.component.html',
  styleUrls: ['./closed-file-report.component.css']
})
export class ClosedFileReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;
  public reportName: any = 'closed-file-report';



  //DropDown
  public closedFileData: any = [];

  //list
  public closedfileListData: any = [];
  public closedfileobjlst: any = {};


  //obj
  public closedfileOBj = {
    "clientId": 0,
    "fromdate": '',
    "todate": ''
  }

  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    max: moment(new Date()),
  };

  cfReportData = '';
  clientId = '0';
  closeFileFromDateData = '';
  closeFileToDateData = '';
  usertype: any;
  shortsaleDData: any=[];
  shortsaleDataBack: any;
  usertypeid: any;
  searchclosedfileListData: any;

  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    this.usertype = this.tokenStorage._get_userRole();
    this.closedFileGetAllReport();
    // this.closedFileDropdown();
    this.shortsaleDropdown()
  }
  shortsaleDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.shortsale_dropdown(CurrentUserId).subscribe((response: any) => {
      this.shortsaleDData = response.listData;
      this.shortsaleDataBack = response.listData;
      console.log(this.shortsaleDData, "sssssaaaaa");
    })
  }
  closedFileDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.shortsale_dropdown(CurrentUserId).subscribe((cfresponse: any) => {
      this.closedFileData = cfresponse;
      console.log(this.closedFileData, "ffdataaaaa")
    })
  }

 
  closedFileGetAllReport() {
    var userTypeId:any=this.tokenStorage._get_userTypeId();
    var sendData={};
    if(userTypeId==4){
      var data = {
        "clientId": this.clientId?parseInt(this.clientId):0,
        "fromdate": this.closeFileFromDateData?this.closeFileFromDateData:"",
        "todate": this.closeFileToDateData?this.closeFileToDateData:"",
        "currentUserId":this.tokenStorage._get_userTypeId()
        
      }
      sendData=data;
      this._manager_service.closedFileReport_getAllReportBroker(sendData).subscribe((res: any) => {
        // this.closedfileobjlst = (res.getData != null) ? res.getData : [];
        this.closedfileListData = (res.listData != []) ? res.listData : [];
        this.searchclosedfileListData = (res.listData != []) ? res.listData : [];
        // console.log(this.closedfileobjlst, "closedfileobjlst");
        console.log(this.closedfileListData, "closedfileListData")
      })
    }else{
      var dataone = {
        "clientId": this.clientId?parseInt(this.clientId):0,
        "fromdate": this.closeFileFromDateData?this.closeFileFromDateData:"",
        "todate": this.closeFileToDateData?this.closeFileToDateData:""
        
        
      }
      sendData=dataone;
      this._manager_service.closedFileReport_getAllReport(sendData).subscribe((res: any) => {
        // this.closedfileobjlst = (res.getData != null) ? res.getData : [];
        this.closedfileListData = (res.listData != []) ? res.listData : [];
        this.searchclosedfileListData = (res.listData != []) ? res.listData : [];
        // console.log(this.closedfileobjlst, "closedfileobjlst");
        console.log(this.closedfileListData, "closedfileListData")
      })
    }


  }


  closedfileFilter() {
    this.closedfileOBj.clientId = this.clientId ? parseInt(this.clientId) : 0;
    this.closedfileOBj.fromdate = this.closeFileFromDateData?this.closeFileFromDateData:"";
    this.closedfileOBj.todate = this.closeFileToDateData?this.closeFileToDateData:"";
    this.closedFileGetAllReport();
  }

  closedfileReset() {
    this.closedfileOBj.clientId = 0;
    this.clientId = '0';
    this.closeFileFromDateData = '';
    this.closeFileToDateData = '';
    this.closedFileGetAllReport();
  }

  downloadExcel() {
    var userTypeId:any= sessionStorage.getItem("userTypeId");
    var sendData={};
    if(userTypeId==4){
      var data = {
        "clientId": this.clientId?parseInt(this.clientId):0,
        "fromdate": this.closeFileFromDateData?this.closeFileFromDateData:"",
        "todate": this.closeFileToDateData?this.closeFileToDateData:"",
        "currentUserId":this.tokenStorage._get_userTypeId(),
        
      }
      sendData=data;
      this._file_upload.closedFile_report_broker(sendData);
    }else{
      var dataone = {
        "clientId": this.clientId?parseInt(this.clientId):0,
        "fromdate": this.closeFileFromDateData?this.closeFileFromDateData:"",
        "todate": this.closeFileToDateData?this.closeFileToDateData:""
        
        
      }
      sendData=dataone;
      this._file_upload.closedFile_report(sendData);
    }


    this.closedfileOBj.clientId = this.clientId ? parseInt(this.clientId) : 0;
    this.closedfileOBj.fromdate = this.closeFileFromDateData?this.closeFileFromDateData:"";
    this.closedfileOBj.todate = this.closeFileToDateData?this.closeFileToDateData:"";
    this._file_upload.closedFile_report(this.closedfileOBj);
  }



  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }
  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  cfsearchReport() {
    let cearchclosedfileOBj = {
      "clientId": 0,
      "word": this.cfReportData
    }
    this._manager_service.closedFile_searchReport(cearchclosedfileOBj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.closedfileListData = [];
      }
      else {
        this.closedfileListData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }

  onSearchTitle(e: any) {
    this.shortsaleDData = this.shortsaleDataBack.filter((item: any) => item.clientName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.closedfileListData = self.searchclosedfileListData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }
}
