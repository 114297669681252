import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastService } from '../service/toast.service';
import { DatePipe } from '@angular/common';
import { Observable, of, from } from 'rxjs';
import { TokenService } from '../service/token.service';
declare var require: any

@Injectable({
  providedIn: 'root'

})
export class FileuploadService {
  FileSaver = require('file-saver');
  importantColumn: any = ["Beds"];
  actionUrl: string;
  currentUserLoginID: any;
  currentloggedUserID:any;
  constructor(private http: HttpClient, private Toastr: ToastService, private datePipe: DatePipe, private tokenStrorage: TokenService) {
    this.actionUrl = environment.apiUrl;
    this.currentUserLoginID = this.tokenStrorage._get_userTypeId();
    let userdet:any;
     userdet = this.tokenStrorage._get_userInfo();
    this.currentloggedUserID = JSON.parse(userdet)
  }
  bulkFile: any;



  //**Bulk File Upload */
  uploadFile(data: any) {

    let reqArray: any = [];
    let workBook: any = null;
    let jsonData: any = null;
    let result: any = [];
    const reader = new FileReader();
    const file = data.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'mm/dd/yyyy' });
      var firstSheet = workBook.Sheets[workBook.SheetNames[0]];

      result = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
      console.log(result[0], "eee");
      let x = this.importantColumn.every((i: any) => result[0].includes(i));

      if (x) {
        jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          // console.log(initial[name])
          JSON.parse(JSON.stringify(initial[name]).toLowerCase().replace(/ +/g, "")).forEach((item: any) => {
            console.log(item)
            var obj: any = {};
            if (!item['email1']) {
              // alert("enter Email ")
            }
            else {
              // alert("else")
              obj['createdBy'] = this.currentUserLoginID;
              obj['primayResidence'] = item['primaryresidence'].toString();
              obj['propertyType'] = item['propertytype'].toString();
              obj['unit'] = item['units'].toString();
              obj['sqFt'] = item['sqft'].toString();
              obj['yearBuild'] = item['yearbuilt'].toString();
              obj['noofBeds'] = item['beds'].toString();
              obj['noofBaths'] = item['baths'].toString();
              obj['lotSize'] = parseInt(item['lotsize']);
              obj['estimatedValue'] = item['estimatedvalue'].toString();
              obj['asseddedValue'] = item['assessedvalue'].toString();
              obj['foreClosureStage'] = item['foreclosurestage'].toString();
              obj['recordingDate'] = this.datePipe.transform(item['recordingdate'], 'MM/dd/yyyy');
              obj['salesDate'] = this.datePipe.transform(item['saledate'], 'MM/dd/yyyy');
              obj['defaultAsOf'] = this.datePipe.transform(item['defaultasof'], 'MM/dd/yyyy');
              obj['fcEstimatedLoanPosition'] = item['fcestimatedloanposition'].toString();
              obj['fcLoanDate'] = this.datePipe.transform(item['fcloandate'], 'MM/dd/yyyy');
              obj['fcLoanAmount'] = item['firstloanamount'].toString();
              obj['secondLoanAmount'] = item['secondloanamount'].toString();
              obj['phoneNumber1'] = item['phonenumber1'].toString();
              obj['phoneNumber2'] = item['phonenumber2'].toString();
              obj['phoneNumber3'] = item['phonenumber3'].toString();
              obj['phoneNumber4'] = item['phonenumber4'].toString();
              obj['email1'] = item['email1'];
              obj['email2'] = item['email2'] || "";
              obj['apn'] = item['apn'].toString();
              obj['countryName'] = item['county'];
              obj['address'] = item['address'];
              obj['city'] = item['city'];
              obj['stateName'] = item['state'];
              obj['isDuplicate'] = false;
              obj['duplicate'] = "";
              obj['rejectedReason'] = "";
              obj["homeOwner"] = {
                "firstName": item['ownerfirstname'],
                "lastName": item['ownerlastname'],
                "emailid": item['owneremail'],
                "phone": item['ownerphone'].toString(),
                "address": item['owneraddress'],
                "stateName": item['ownerstate'],
                "cityname": item['ownercity'],
                "zipCode": item['zipcode']+"",
                "isActive": true
              },
                obj['leadChildAssign'] = [],

                console.log(obj)
              reqArray.push(obj);
              // this._bulk_FileUpload(obj)
            }
          });


          this._bulk_FileUpload(reqArray)
        }


          , {});
      }
    }
    reader.readAsBinaryString(file);



  }
  //** END */


  //**Bulk File Upload service call */
  _bulk_FileUpload(fileobj: any) {
    let fileObject = { "bulkUpload": fileobj }
    console.log(fileObject, "hhhh")
    this.http.post(this.actionUrl + "/Lead/BulkUpload", fileObject).subscribe((resObj: any) => {


      this.Toastr.showSuccess(resObj.message)
      console.log(resObj)


    })
  }

  //**END */

  //**Download Template in lead screen API */
  download_template() {
    this.http.post(this.actionUrl + "/Lead/ExcelTemplate?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }
  //**END */

  //**download list in lead */
  download_list_lead() {
    this.http.post(this.actionUrl + "/Lead/DownloadExcel?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }
  download_list_lead_accept() {
    this.http.post(this.actionUrl + "/Lead/AcceptedLeadExcelDownload?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }
  download_list_lead_reject() {
    this.http.post(this.actionUrl + "/Lead/RejectedLeadExcelDownload?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }
  download_list_lead_disqualified() {
    this.http.post(this.actionUrl + "/Lead/GetAllDisqualifiedLeadExcelDownload?CurrentUserId=" + this.currentUserLoginID,{}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }
  //**END */

  //**Download Template in report screen API */

  //cancel report
  download_report(data:any) {
    this.http.post(this.actionUrl + "/CancelFileReport/Excel", data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }


  //successrate report
  success_rate_report(data:any) {
    this.http.post(this.actionUrl + "/SuccessrateReport/Excel", data).subscribe((fileObj: any) => {
      
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }
  filedata_excel() {
    this.http.post(this.actionUrl + "/FileStatus/FileStatusExcel", {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }
  lead_success_rate_report(data:any) {
    this.http.post(this.actionUrl + "/LeadReport/LeadSuccessRateReport", data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }
  user_rate_report(data:any) {
    this.http.post(this.actionUrl + `/User/GetAllUserListExcelDownload`, data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }

  //trendreport
  trend_rate_report(data:any) {
    this.http.post(this.actionUrl + "/TrendReport/TrendReportExcel", data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }
  financial_report_excel() {
    this.http.post(this.actionUrl + "/Financial/FinancialReportExcel", {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }


  //shortreport
  short_rate_report(data:any):Observable<any> {
    var header= new HttpHeaders();
    // header.set("Access-Control-Allow-Origin:"," http://localhost:4200" )
 
// header.set("time",date.getDate())
    return this.http.post(`${this.actionUrl}/ShortSaleReports/Excel`,data)
  }
  short_rate_report_broker(data:any):Observable<any> {
    var header= new HttpHeaders();
    // header.set("Access-Control-Allow-Origin:"," http://localhost:4200" )
 
// header.set("time",date.getDate())
    return this.http.post(`${this.actionUrl}/ShortSaleReports/GetAllReportsForBrokerExcel`,data)
  }


  //user usage report
  userUsage_report(data:any) {
    this.http.post(this.actionUrl + "/UserUsageReport/Excel", data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }

  //filesperclosure report
  filesperClosure_report(data:any) {
    this.http.post(this.actionUrl + "/FilesperForclosuredate/Excel", data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }
  filesperClosure_report_broker(data:any) {
    this.http.post(this.actionUrl + "/FilesperForclosuredate/ExcelForBroker", data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }

  //closedfile report
  closedFile_report(data:any) {
    this.http.post(this.actionUrl + "/ClosedFilesReport/Excel", data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }
  closedFile_report_broker(data:any) {
    this.http.post(this.actionUrl + "/ClosedFilesReport/ExcelForBroker", data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }

  //openclose report

  openclose_report() {
    this.http.post(this.actionUrl + "/OpenClosedFilesReport/Excel", {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }

  //numberof file report

  numberofFile_report() {
    this.http.post(this.actionUrl + "/FilesperStage/Excel", {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }



  //**END */

  //**Download Template in task screen API */
  download_history(data: any) {
    this.http.post(this.actionUrl + "/userloginaudit/Downloaduserauditreport", data).subscribe((fileObj: any) => {
      // console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }
  //**Download Template in task screen API */



//*Hardship Add to Document*//

addHardshipDoc(hardshipdoc:any){
 this.http.post(this.actionUrl+'/ClientHardShip/AddClientHardShipDocument',hardshipdoc).subscribe((res:any) =>{

  this.Toastr.showSuccess('Document has been updated successfully')

 },err =>{
   this.Toastr.showError('Error occured')
 })
}

addShortSaleDoc(docs:any){
this.http.post(this.actionUrl+'/Clientshortsale/UpdateDocumentForShortsale',docs).subscribe((res:any) =>{
  this.Toastr.showSuccess('Document has been updated successfully')
},err =>{
  this.Toastr.showError('Error occured')

})
}
  multifile_upload_autoGen(filesOBj: Array<any>): Observable<any> {

    var formData = new FormData();

    for (var i = 0; i < filesOBj.length; i++) {
      formData.append('formFiles', filesOBj[i]);
    };

    formData.append('formFiles', JSON.stringify(filesOBj));

    return this.http.post(this.actionUrl + '/Autogendocuments/UploadAutogendocFiles', formData)
  }

  //**client Document Tab */
  multiFile_upload(filesOBj: Array<any>, url: any): Observable<any> {
    console.log(filesOBj)
    console.log(url)
    var formData = new FormData();

    for (var i = 0; i < filesOBj.length; i++) {
      formData.append('formFiles', filesOBj[i]);
    };

    formData.append('formFiles', JSON.stringify(filesOBj));

    return this.http.post(this.actionUrl + '/ClientDocment/' + url + '', formData)

  }


  multiFile_uploadDoc(filesOBj: Array<any>): Observable<any> {
    console.log(filesOBj)
    var formData = new FormData();

    for (var i = 0; i < filesOBj.length; i++) {
      formData.append('formFiles', filesOBj[i]);
    };

    formData.append('formFiles', JSON.stringify(filesOBj));

    return this.http.post(this.actionUrl + '/ClientDocment/MutipleFileUpload', formData)

  }


  updateUploadDoc(obj: any) {
    // https://apexresolution.colanonline.net/api/V1/ClientDocment/UpdateNewDocumentChild

    this.http.post(this.actionUrl + '/ClientDocment/UpdateNewDocumentChild', obj).subscribe((data: any) => {
      console.log(data);
      this.Toastr.showSuccess(data.message)
    })
  }

  //**shortsale tab */
  uploadCmaBuyer_Doc(filesOBj: Array<any>, url: any ,clientid:any): Observable<any> {

    var formData = new FormData();

    for (var i = 0; i < filesOBj.length; i++) {
      formData.append('formFiles', filesOBj[i]);
    };

    formData.append('formFiles', JSON.stringify(filesOBj));
    // formData.append('ClientId',clientid)

    return this.http.post(this.actionUrl + '/Clientshortsale/' + url+'?ClientId='+clientid,formData)
  }

  updateDocListNameById(obj: any) {
    return this.http.post(this.actionUrl + '/ClientDocment/UpdtaeDocumentName', obj)
  }

  deleteDoclistById(docid: any) {
    return this.http.post(this.actionUrl + '/ClientDocment/DeleteDocument', docid)


  }
  //  uploadCMAanalysis_Doc(){
  //   return this.http.post(this.actionUrl+'/Clientshortsale/UploadBuyerDcument',{})

  //  }
  //***END */

  // https://apexresolution.colanonline.net/api/V1/ClientSSProposal/ExcelDownload
  excelInSSProposal(data: any, docobj: any) {


    if (data == 'assumption1') {
      console.log(docobj.assumption)
      let assumption1: any = {};

      assumption1["ss"] = docobj.assumption.ssproposalss;
      assumption1["ssReo"] = docobj.assumption.ssproposalreo;
      assumption1["closingCostS"] = docobj.assumption.closingcostss;
      assumption1["closingCostRe"] = docobj.assumption.closingcostreo;
      assumption1["totalOwedss"] = docobj.assumption.totalownedss;
      assumption1["totalOwedReo"] = docobj.assumption.totalownedreo;
      assumption1["lienssettlementTotalSS"] = docobj.assumption.leinss;
      assumption1["lienssettlementTotalReo"] = 0;
      assumption1["futureCostofPaymentsSS"] = 0;
      assumption1["futureCostofPaymentsReo"] = docobj.assumption.futurelostreo;
      assumption1["repairsinREOSS"] = 0;
      assumption1["repairsinREOreo"] = docobj.assumption.reorepairs;
      assumption1["monthlyTaxtillclosingSS"] = 0;
      assumption1["monthlyTaxtillclosingReo"] = docobj.assumption.mothlytaxreo;
      assumption1["shortSaleSS"] = docobj.assumption.ssotherss;
      assumption1["shortSaleReo"] = 0;
      assumption1["reoOtherSS"] = 0;
      assumption1["reoOtherReo"] = docobj.assumption.reoother;
      assumption1["homeDeprectiationSS"] = 0;
      assumption1["homeDeprectiationReo"] = 0;
      assumption1["netPriceSS"] = docobj.assumption.ssnet;
      assumption1["netPriceReo"] = docobj.assumption.reonet;
      assumption1["totalSS"] = docobj.assumption.totalss;
      assumption1["totalReo"] = docobj.assumption.totalreo;
      this.http.post(this.actionUrl + '/ClientSSProposal/ExcelDownload', assumption1).subscribe((data: any) => {
        window.open(data.extraData['ExcelUrl']);
        this.Toastr.showSuccess(data.message);
        console.log(data)
      })
    } else if (data == 'assumption2') {
      let assumption2: any = {};

      console.log(docobj.reo)
      assumption2["ss"] = docobj.ssofferprice;
      assumption2["ssReo"] = docobj.reo.reoofferprice;
      assumption2["closingCostS"] = docobj.reo.closingcostss;
      assumption2["closingCostRe"] = docobj.reo.closingcostreo;
      assumption2["totalOwedss"] = docobj.reo.totalownedss;
      assumption2["totalOwedReo"] = docobj.reo.totalownedreo;
      assumption2["lienssettlementTotalSS"] = 0;
      assumption2["lienssettlementTotalReo"] = docobj.reo.lienssettlementss;
      assumption2["futureCostofPaymentsSS"] = 0;
      assumption2["futureCostofPaymentsReo"] = docobj.reo.futurereo;
      assumption2["repairsinREOSS"] = 0;
      assumption2["repairsinREOreo"] = docobj.reo.repairsreo;
      assumption2["monthlyTaxtillclosingSS"] = 0;
      assumption2["monthlyTaxtillclosingReo"] = docobj.reo.monthlytaskreo;
      assumption2["shortSaleSS"] = docobj.reo.ssother;
      assumption2["shortSaleReo"] = 0;
      assumption2["reoOtherSS"] = 0;
      assumption2["reoOtherReo"] = docobj.reo.reoother;
      assumption2["homeDeprectiationSS"] = 0;
      assumption2["homeDeprectiationReo"] = 0;
      assumption2["netPriceSS"] = docobj.reo.ssnet;
      assumption2["netPriceReo"] = docobj.reo.reonet;
      assumption2["totalSS"] = docobj.reo.totalss;
      assumption2["totalReo"] = docobj.reo.totalreo;




      this.http.post(this.actionUrl + '/ClientSSProposal/ExcelDownload', assumption2).subscribe((data: any) => {
        console.log(data)
        window.open(data.extraData['ExcelUrl']);
        this.Toastr.showSuccess(data.message);
      })
    }
    // return this.http.post(this.actionUrl+'/ClientSSProposal/ExcelDownload',docobj);

  }


  addDocSSComp(data:any,docobj:any,clientid:any){
    if (data == 'assumption1') {
      console.log(docobj.assumption)
      let assumption1: any = {};
      assumption1["clientId"] = clientid;
      assumption1["ss"] = docobj.assumption.ssproposalss;
      assumption1["ssReo"] = docobj.assumption.ssproposalreo;
      assumption1["closingCostS"] = docobj.assumption.closingcostss;
      assumption1["closingCostRe"] = docobj.assumption.closingcostreo;
      assumption1["totalOwedss"] = docobj.assumption.totalownedss;
      assumption1["totalOwedReo"] = docobj.assumption.totalownedreo;
      assumption1["lienssettlementTotalSS"] = docobj.assumption.leinss;
      assumption1["lienssettlementTotalReo"] = 0;
      assumption1["futureCostofPaymentsSS"] = 0;
      assumption1["futureCostofPaymentsReo"] = docobj.assumption.futurelostreo;
      assumption1["repairsinREOSS"] = 0;
      assumption1["repairsinREOreo"] = docobj.assumption.reorepairs;
      assumption1["monthlyTaxtillclosingSS"] = 0;
      assumption1["monthlyTaxtillclosingReo"] = docobj.assumption.mothlytaxreo;
      assumption1["shortSaleSS"] = docobj.assumption.ssotherss;
      assumption1["shortSaleReo"] = 0;
      assumption1["reoOtherSS"] = 0;
      assumption1["reoOtherReo"] = docobj.assumption.reoother;
      assumption1["homeDeprectiationSS"] = 0;
      assumption1["homeDeprectiationReo"] = 0;
      assumption1["netPriceSS"] = docobj.assumption.ssnet;
      assumption1["netPriceReo"] = docobj.assumption.reonet;
      assumption1["totalSS"] = docobj.assumption.totalss;
      assumption1["totalReo"] = docobj.assumption.totalreo;
      assumption1["createdBy"] = this.currentloggedUserID.CurrentLoggedUserId ;
      this.http.post(this.actionUrl + '/ClientSSProposal/Assumption1SSCompDocumentTab', assumption1).subscribe((data: any) => {
        // window.open(data.extraData['ExcelUrl']);
        this.Toastr.showSuccess(data.message);
        console.log(data)
      })
    } else if (data == 'assumption2') {
      let assumption2: any = {};

      console.log(docobj.reo)
      assumption2["clientId"] = clientid;
      assumption2["ss"] = docobj.ssofferprice;
      assumption2["ssReo"] = docobj.reo.reoofferprice;
      assumption2["closingCostS"] = docobj.reo.closingcostss;
      assumption2["closingCostRe"] = docobj.reo.closingcostreo;
      assumption2["totalOwedss"] = docobj.reo.totalownedss;
      assumption2["totalOwedReo"] = docobj.reo.totalownedreo;
      assumption2["lienssettlementTotalSS"] = 0;
      assumption2["lienssettlementTotalReo"] = docobj.reo.lienssettlementss;
      assumption2["futureCostofPaymentsSS"] = 0;
      assumption2["futureCostofPaymentsReo"] = docobj.reo.futurereo;
      assumption2["repairsinREOSS"] = 0;
      assumption2["repairsinREOreo"] = docobj.reo.repairsreo;
      assumption2["monthlyTaxtillclosingSS"] = 0;
      assumption2["monthlyTaxtillclosingReo"] = docobj.reo.monthlytaskreo;
      assumption2["shortSaleSS"] = docobj.reo.ssother;
      assumption2["shortSaleReo"] = 0;
      assumption2["reoOtherSS"] = 0;
      assumption2["reoOtherReo"] = docobj.reo.reoother;
      assumption2["homeDeprectiationSS"] = 0;
      assumption2["homeDeprectiationReo"] = 0;
      assumption2["netPriceSS"] = docobj.reo.ssnet;
      assumption2["netPriceReo"] = docobj.reo.reonet;
      assumption2["totalSS"] = docobj.reo.totalss;
      assumption2["totalReo"] = docobj.reo.totalreo;
      assumption2["createdBy"] = this.currentloggedUserID .CurrentLoggedUserId;



      this.http.post(this.actionUrl + '/ClientSSProposal/Assumption2SSCompDocumentTab', assumption2).subscribe((data: any) => {
        console.log(data)
        // window.open(data.extraData['ExcelUrl']);
        this.Toastr.showSuccess(data.message);
      })
    }
  }


  downloadzipBy_Docid(docid: any) {
    return this.http.post(this.actionUrl + '/ClientDocment/DownalodZipByDocumentId?DocumentId=' + docid, {}).subscribe((fileObj: any) => {

      if (fileObj.extraData != null) {
        window.open(fileObj.extraData['DownloadZip']);
        this.Toastr.showSuccess('downloading...');
      } else {
        this.Toastr.showSuccess(fileObj.message);

      }

    })
  }
  download_zip(clientId: number) {
    this.http.post(this.actionUrl + '/ClientDocment/DownloadAllZip?ClientId=' + clientId, {}).subscribe((fileObj: any) => {
      if (fileObj.extraData != null) {
        window.open(fileObj.extraData['DownloadZip']);
        this.Toastr.showSuccess('downloading...');
      } else {
        this.Toastr.showInformation(fileObj.message);

      }
    })
  }
  //**END */
  leadLoaSignatureUpload(fileObj: any) {
    return this.http.post(this.actionUrl + '/Lead/UploadFile', fileObj);
  }
  sentuploadFile(SentObj: any) {
    return this.http.post(this.actionUrl + '/Lead/AddLOAFileByAgent', SentObj);
  }
  videoUpload(videoObj: any) {
    // const HttpUploadOptions = {headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })}
    let videoUrl: any;


    return this.http.post(this.actionUrl + "/ManageVideo/UploadFile", videoObj)

  }

  getAllVideosDetails() {
    return this.http.post(this.actionUrl + "/ManageVideo/GetAll", {})

  }

  getVideoDetailsbyId(videoId: number) {
    return this.http.post(this.actionUrl + '/ManageVideo/Get?ManageVideoId=' + videoId, {})
  }
  addVideoUploadDetails(addVideo: any) {

    return this.http.post(this.actionUrl + "/ManageVideo/Add", addVideo)
  }
  deleteVideoDetails(deleteVideo: any) {

    return this.http.post(this.actionUrl + '/ManageVideo/Delete', deleteVideo);
  }
  updateVideoDetails(updateDetails: any) {
    return this.http.post(this.actionUrl + '/ManageVideo/Update', updateDetails);
  }


  //User Download
  download_alluser(data:any) {
    debugger
    this.http.post(this.actionUrl + "/User/GetAllUserListExcelDownload" , data).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      //this.Toastr.showSuccess(fileObj.message);
      this.Toastr.showSuccess("Data Download Sucessfully");
    })
  }

  download_inActiveuser() {
    this.http.post(this.actionUrl + "/User/GetAllInactiveUserListExcelDownload?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      //this.Toastr.showSuccess(fileObj.message);
      this.Toastr.showSuccess("Data Download Sucessfully");
    })
  }

  download_deleteduser() {
    this.http.post(this.actionUrl + "/User/GetAllDeletedUserListExcelDownload?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      //this.Toastr.showSuccess(fileObj.message);
      this.Toastr.showSuccess("Data Download Sucessfully");
    })
  }
  //manager download

  download_manager() {
    this.http.post(this.actionUrl + "/Managers/ExcelDownlaod?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }

  download_broker() {
    this.http.post(this.actionUrl + "/Brokers/ExcelDownlaod?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    })
  }

  download_agent() {
    this.http.post(this.actionUrl + "/Agents/ExcelDownlaod?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }

  download_homeowner() {
    this.http.post(this.actionUrl + "/HomeOwners/ExcelDownlaod?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }

  download_negotiator() {
    this.http.post(this.actionUrl + "/Negotiators/ExcelDownlaod?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }
  download_assnegotiator() {
    this.http.post(this.actionUrl + "/AsstNegotiators/ExcelDownlaod?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }
  download_closer() {
    this.http.post(this.actionUrl + "/Closers/ExcelDownlaod?CurrentUserId=" + this.currentUserLoginID, {}).subscribe((fileObj: any) => {
      console.log(fileObj.extraData['ExcelUrl']);
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    })
  }


  //** CLOSING STATEMENT DOWNLOAD */
  closingstatement_downloadurl(clientid: number) {
    // https://apexresolution.colanonline.net/api/V1/ClientClosingStatements/ClosingStatementExcel?ClientId=1

    this.http.get(this.actionUrl + '/ClientClosingStatements/ClosingStatementExcel?ClientId=' + clientid).subscribe((fileObj: any) => {
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    });
  }
  closingstatement_HUD(clientid: number) {
    this.http.get(this.actionUrl + '/ClientClosingStatements/ClosingStatementHudExcelDOwnload?ClientId=' + clientid).subscribe((fileObj: any) => {
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    });
  }

  downloadAuditDataByUserId(userObj: object) {
    // https://apexresolution.colanonline.net/api/V1/userloginaudit/DownloadByUserIdAduitData
    this.http.post(this.actionUrl + '/userloginaudit/DownloadByUserIdAduitData', userObj).subscribe((fileObj: any) => {
      window.open(fileObj.extraData['ExcelUrl']);
      this.Toastr.showSuccess(fileObj.message);
    });
  }
}

