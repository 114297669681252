import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { FormGroup, FormArray, FormBuilder, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { file, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

//**service */
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
//**MODEL */
import { leadmodel, HomeOwner, leadAssign } from '../../models/lead.model';
import { FileuploadService } from 'src/app/service/fileupload.service';
import { NotificationService } from 'src/app/service/notification.service';
// import { validation } from '../../models/lead.validation';

@Component({
  selector: 'app-lead-agent-loa',
  templateUrl: './lead-agent-loa.component.html',
  styleUrls: ['./lead-agent-loa.component.css'],
})
export class LeadAgentLoaComponent implements OnInit {
  // leads? :leadmodel;
  @ViewChild(NgForm) leadLOADetails:NgForm
  @ViewChild('RefVarTemplate') RefVarTemplate?: ElementRef;
  public CurrentGetidLead: number | undefined;
  nestedFormGroup: any = FormGroup;
  updateForm: Boolean = false;
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    min: moment(new Date()).format('MM/DD/YYYY'),
  };
  //YYYY-MM-DD
  max = moment(new Date()).format('MM/DD/YYYY');
  id: any;
  countrylist: any;
  statelist: any;
  citylist: any;
  userTypes: any;
  userLists: any;
  usertypeId: any;
  public currentLoggedUserId: any;
  userInfo: any = [];
  userInfoList: any;
  userTypeId: any;
  public usertype: any;
  //LOA Details
  loaDetailValue = '1';
  loaDetailsList: any = {};
  getAllLeadList: any = {};
  getAllHomeOwnerList: any = {};
  getinvitehomeOwner: any = {};
  updateallLOADetails: any = {};
  files: any;
  iscontectedList: number | undefined;
  formData: any;
  fileuploadName: any;
  homeUserId: any;
  getListagentBroker: any;
  private selectedLink: string = 'Yes';
  public agentId: any = [];
  agentids: any;
  public userTypId: any = 0;
  lastEventId: any;
  isLOACompleted: any;
  //doc = new jsPDF()
  mailngaddress: any;
  mailngaddress2: any;
  mailngaddress_self: any;
  getresAppointDate: any;
  proAdress: any;
  getAppointData: any;
  LOAoptionTrue: boolean | false;
  getLOASendDetails: any
  sendLOASignature: any
  dbPathLOASign: any
  FileNameLOASign: any
  //LeadTask
  public taskTabModel: any = {};
  public activeLogList: any = {};
  getLeadData: any;
  userType: any;
  userTypeid: any;
  popupcontact: boolean = false;
  activityListLength: number;
  submitted: boolean;
  constructor(
    private notification: NotificationService,
    private datePipe: DatePipe,
    private route: Router,
    private tokenStorage: TokenService,
    private _manager_service: ManagerService,
    private activated_route: ActivatedRoute,
    private dropDown: DropdownService,
    private formBuilder: RxFormBuilder,
    private toastr: ToastService,

    private fileuploadService: FileuploadService
  ) { }
  //Mailing Selected Link
  addressChange(e: any): void {
    console.log(e.target.value, 'proaddress');
    this.proAdress = e.target.value;
  }
  setradio(e: string): void {
    this.selectedLink = e;

    console.log(this.selectedLink);
    if (this.selectedLink == 'No') {
      // alert('no');
      this.mailngaddress2 = ' ';
    } else if (this.selectedLink == 'Yes') {
      // alert('yes');
      this.mailngaddress2 = this.proAdress;
    }
  }
  isSelected(name: string): boolean {
    if (!this.selectedLink) {
      // if no radio button is selected, always return false so every nothing is shown
      this.mailngaddress = '';
      return false;
    }

    return this.selectedLink === name; // if current radio button is selected, return true, else return false
  }

  ngOnInit() {
    
    console.log(sessionStorage.getItem('userInfo'));
    this.usertype = this.tokenStorage._get_userRole();

    this.usertypeId = this.tokenStorage._get_userTypeId();
    this.userInfo = this.tokenStorage._get_userInfo();

    this.userInfoList = JSON.parse(this.userInfo);

    this.userTypeId = this.userInfoList.UserTypeId;
    this.currentLoggedUserId = this.userInfoList.CurrentLoggedUserId;

    console.log(this.userTypeId, 'userTypeId----');
    console.log(this.currentLoggedUserId, 'currentLoggedUserId');

    let leads = new leadmodel();
    leads.homeOwner = new HomeOwner();

    let leadchild = new leadAssign();
    leads.leadChildAssign = new Array<leadAssign>();

    // leads.leadChildAssign.push(leadchild);

    /**GET ROUTE PARAM ID */

    this.activated_route.params.subscribe((param: any) => {
      this.id = param['id'];
      if (param['id'] != null || undefined) {
        this.updateForm = true;
        this.getLead_byid(param['id']);
        this.getlistall_LOADetails(param['id']);
      }
    });
    // console.log(this.leads)
    //this.getlistall_LOADetails(id);
    // this.getList_updateLOADetails(id)
    this.nestedFormGroup = this.formBuilder.formGroup(leads);
    console.log(this.nestedFormGroup.value, 'formvalue');
    this.getCountry();
    this.getLead_byid(this.id);
    this.getupdateLeadLastOpenedDetails(this.id)
    this.getAgentsForBroker();
    this.getLoaDettails();

    console.log(this.id, 'leadId');
  }
  /**END */

  get leadChilds() {
    return <FormArray>this.nestedFormGroup.controls.leadChildAssign;
  }
  /** GET LEAD BY ID  OLD */
  // getLead_byid(id: number) {
  //   this._manager_service.getLead_Byid(id).subscribe((getLead: any) => {
  //     console.log(getLead.getData.leadChildAssign.length, 'userid');
  //     console.log(getLead.getData, 'listDecimal');

  //     //getting null value
  //     // this.nestedFormGroup.setValue(getLead.getData)

  //     this.select_dropdown(1, 'state', false);
  //     this.select_dropdown(getLead.getData.homeOwner.stateId, 'city', false);
  //     this.homeUserId = parseInt(getLead.getData.homeOwner.homeUserId);
  //     // getLead.getData.homeOwner.
  //     // this.nestedFormGroup.fcLoanDate.pa
  //     this.nestedFormGroup.controls.homeOwner.patchValue({ countryId: 1 });
  //     this.nestedFormGroup.controls.homeOwner.patchValue(
  //       getLead.getData.homeOwner
  //     );
  //     this.nestedFormGroup.patchValue(getLead.getData);

  //     this.leadChilds.push(
  //       this.formBuilder.group({
  //         userTypeId:
  //           getLead.getData.leadChildAssign[
  //             getLead.getData.leadChildAssign.length - 1
  //           ].userTypeId,
  //         userId:
  //           getLead.getData.leadChildAssign[
  //             getLead.getData.leadChildAssign.length - 1
  //           ].userId,
  //       })
  //     );

  //     console.log(this.leadChilds.value);
  //     //  this.nestedFormGroup.value.leadChildAssign.slice(0,1);
  //     console.log(this.nestedFormGroup.value, 'dddddddd');
  //     console.log(getLead.getData);
  //   });
  //   this.CurrentGetidLead = Number(id);
  //   //console.log(id, '=<id')
  // }

  //** new */
  getLead_byid(id: number) {
    this._manager_service.getLead_Byid(id).subscribe((getLead: any) => {
      console.log("getLead ", getLead);

      //getting null value
      // this.nestedFormGroup.setValue(getLead.getData)
      this.getLeadData = getLead.getData.leadChildAssign;
      if (this.getLeadData && this.getLeadData.length > 0) {
        this.userType = this.getLeadData[this.getLeadData.length - 1].userfullName;
        this.userTypeid = this.getLeadData[this.getLeadData.length - 1].userTypeId;

      }

      // this.getupdateLeadLastOpenedDetails(getLead.getData.leadId);
      console.log(this.getLeadData, "leadDataaaass")
      // this.select_dropdown(1, "state", false);
      // this.select_dropdown(getLead.getData.homeOwner.stateId, 'city', false);
      this.homeUserId = parseInt(getLead.getData.homeOwner.homeUserId);
      this.nestedFormGroup.patchValue({ "phone1": getLead.getData.homeOwner.phone1, "phone2": getLead.getData.homeOwner.phone2, "phone3": getLead.getData.homeOwner.phone3, 'notes': getLead.getData.homeOwner.notes, 'spouse': getLead.getData.homeOwner.spouse, 'referedBy': getLead.getData.homeOwner.referedBy, 'stateName': getLead.getData.stateName });
      this.nestedFormGroup.controls.homeOwner.patchValue({ 'apn': getLead.getData.apn, 'stateName': getLead.getData.homeOwner.stateName });
      this.nestedFormGroup.controls.homeOwner.patchValue(getLead.getData.homeOwner);
      this.nestedFormGroup.patchValue(getLead?.getData);
      this.leadChilds.push(this.formBuilder.group({ "userTypeId": getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userTypeId, "userId": getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userId }))
      this.select_dropdown(getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userTypeId, 'userType', false)
      //  this.nestedFormGroup.value.leadChildAssign.slice(0,1);
      console.log(this.nestedFormGroup.value, "dddddddd")
      console.log(getLead.getData)
    })
    this.CurrentGetidLead = Number(id)
    //console.log(id, '=<id')
  }
  /**END */

  getupdateLeadLastOpenedDetails(leadid: any) {
    let leadObj = {
      "currentUserId": parseInt(this.usertypeId),
      "leadId": parseInt(leadid)
    }
    console.log(leadObj, 'dummyLead')
    this._manager_service.getUpdateLeadLastOpenedDetails(leadObj).subscribe((data: any) => {
      console.log(data, 'LeadList');
    })
  }

  /**Add ,Update LEAD  */
  addLead() {
    console.log(this.id);
    console.log('addLead', this.nestedFormGroup);
    var reqObj = this.nestedFormGroup.value;
    reqObj['leadId'] = parseInt(this.id) || 0;
    reqObj.homeOwner.stateId = parseInt(reqObj.homeOwner.stateId);
    reqObj.homeOwner.emailId = reqObj.email1;
    reqObj.homeOwner.cityId = parseInt(reqObj.homeOwner.cityId);
    reqObj.cityId = parseInt(reqObj.cityId);
    reqObj.stateId = parseInt(reqObj.stateId);
    reqObj.countryId = parseInt(reqObj.countryId);
    reqObj['createdBy'] = this.usertype;
    // reqObj.leadChildAssign[0].userTypeId = parseInt(reqObj.leadChildAssign[0].userTypeId);
    // reqObj.leadChildAssign[0].userId = parseInt(reqObj.leadChildAssign[0].userId);
    // if( reqObj.leadChildAssign[0].userTypeId == 0 &&   reqObj.leadChildAssign[0].userId == 0){
    //   reqObj.leadChildAssign = [];
    // }

    //** if Update by id */
    if (this.id) {
      this.updateForm = true;
      reqObj.leadChildAssign = [];
      reqObj.homeOwner['homeUserId'] = this.homeUserId;
      // alert("update");
      this._manager_service.update_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {
          this.toastr.showSuccess('Lead Updated');
          this.route.navigate(['lead-list']);
        } else {
          this.toastr.showError(data.message);
        }
      });
    }
    //** else Add  */
    else {
      for (let i = 0; i < reqObj.leadChildAssign.length; i++) {
        reqObj.leadChildAssign[i].userTypeId = parseInt(
          reqObj.leadChildAssign[i].userTypeId
        );
        reqObj.leadChildAssign[i].userId = parseInt(
          reqObj.leadChildAssign[i].userId
        );
      }
      // alert("create")
      this.updateForm = false;
      this._manager_service.add_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {
          this.toastr.showSuccess('Lead created');
          this.route.navigate(['lead-list']);
        } else {
          this.toastr.showError(data.message);
        }
      });
    }
  }
  /** END */

  /**select dropdown */
  /**get Country */
  getCountry() {
    this.dropDown._getAll_country().subscribe((data: any) => {
      this.countrylist = data.listData;
      console.log(this.countrylist, 'compo');
      this.select_dropdown(1, 'state', false);
    });

    this.dropDown._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      console.log(this.userTypes, 'ji');
    });
  }

  /** END */
  select_dropdown(id: any, selecteddata: any, htmlupdate?: Boolean) {
    let targetid;
    if (htmlupdate) {
      targetid = parseInt(id.target.value);
    } else {
      targetid = id;
    }

    // console.log(ids)
    if (selecteddata == 'state') {
      this.dropDown._getAll_state(targetid).subscribe((stateList: any) => {
        this.statelist = stateList.listData;
        console.log(stateList);
      });
    } else if (selecteddata == 'city') {
      this.dropDown._getAll_city(targetid).subscribe((cityList: any) => {
        this.citylist = cityList.listData;
        console.log(this.citylist);
      });
    } else if (selecteddata == 'userType') {
      this.dropDown._get_Userlist(targetid).subscribe((getuserlist: any) => {
        this.userLists = getuserlist.listData;
        console.log(this.userLists, 'dsdsdsf');
      });
    }
  }

  /**end Dropdown */
  /**LOA Details */
  selectChangeHandler(event: any) {
    this.loaDetailValue = event.target.value;
    if (this.loaDetailValue == '2') {
      this.iscontectedList = 0;
    } else if (this.loaDetailValue == '6') {
      this.LOAoptionTrue = true
    }
    else {
      this.iscontectedList = 1;
    }
    // alert(this.loaDetailValue);
  }

  getlistall_LOADetails(id: number) {
    this._manager_service.getAll_LOADetails(id).subscribe((getLOA: any) => {

      if (getLOA != undefined || getLOA != null) {

        this.getAllLeadList = getLOA.listData[0];
        if (this.getAllLeadList["wayofContact"] == null) {
          this.getAllLeadList["wayofContact"] = 0;
        }
        if (this.getAllLeadList['isSuccessFullCompleted'] == null) {
          this.getAllLeadList["isSuccessFullCompleted"] = 1;
        }
        console.log(this.getAllLeadList, 'GetLOADetails');

        this.getAllHomeOwnerList = this.getAllLeadList.homeOwnerdetails;
        console.log(this.getAllHomeOwnerList, 'GetLOAhomeAll');
      }
    });
  }
  sendLOAsigSave() {
    this.updateallLOADetails = {
      leadId: Number(this.id),
      event: Number(this.loaDetailValue),
      createdBy: this.currentLoggedUserId
    }
    this._manager_service.get_updateLOADetails(this.updateallLOADetails).subscribe((data: any) => {
      if (data.reponse == true) {
        this.getLoaDettails();
        this.toastr.showSuccess(data.message);

      } else {
        this.toastr.showError(data.message);
      }
    })
  }
  onsubimtload() {
   
    this.getresAppointDate = this.getAllLeadList.resAppointDate;
    // this.getresAppointTime = this.getAllLeadList.resAppointTime;
    console.log(this.getAllLeadList, 'getAllLeadList');
    this.updateallLOADetails = {
      leadId: Number(this.id),
      event: Number(this.loaDetailValue),
      iscontected: this.iscontectedList?this.iscontectedList:0,
      contactedBy: this.usertypeId?this.usertypeId:"",
      createdOn:this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm'),
      //  contactedBy: this.getAllLeadList.contactedBy, //need check
      //wayofContact: 1, //- rejected - list,
      wayofContact: this.getAllLeadList.wayofContact?Number(this.getAllLeadList.wayofContact):0, //- rejected - list,
      contactedNotes: this.getAllLeadList.contactedNotes?this.getAllLeadList.contactedNotes:"",
      notContactedNotes: this.getAllLeadList.notContactedNotes?this.getAllLeadList.notContactedNotes:"",
      appointDate: this.getAllLeadList.appointDate?this.datePipe.transform(this.getAllLeadList.appointDate,'MM/dd/yyyy'):"",
      appointTime: this.getAllLeadList.appointTime?this.getAllLeadList.appointTime:"",
      appointNotes: this.getAllLeadList.appointNotes?this.getAllLeadList.appointNotes:"",
      resAppointDate: this.getresAppointDate?this.datePipe.transform(this.getresAppointDate,'MM/dd/yyyy'):"",
      resAppointTime: this.getAllLeadList.resAppointTime?this.getAllLeadList.resAppointTime:"",
      resAppointNotes: this.getAllLeadList.resAppointNotes?this.getAllLeadList.resAppointNotes:"",
      isSuccessFullCompleted:  this.getAllLeadList.isSuccessFullCompleted?Number(this.getAllLeadList.isSuccessFullCompleted):0, //need check
      reason: this.getAllLeadList.reason?this.getAllLeadList.reason:"", //need check
      appointCompletedNotes: this.getAllLeadList.appointCompletedNotes?this.getAllLeadList.appointCompletedNotes:"", //need check
      createdBy: this.currentLoggedUserId?this.currentLoggedUserId:"", //need check
      DisqualifiedNotes:this.getAllLeadList.DisqualifiedNotes?this.getAllLeadList.DisqualifiedNotes:""
    };
if(Number(this.loaDetailValue)==7){
  this.updateallLOADetails.IsDisqualified=true
 
}else{
  this.updateallLOADetails.IsDisqualified=false
}
console.log(this.updateallLOADetails, 'formsubmit');
// return false;
    // this.updateallLOADetails['resAppointDate'] = this.datePipe.transform(new Date(this.getAllLeadList.resAppointDate._d), 'MM/dd/yyyy');
    //this.updateallLOADetails['appointDate'] = this.datePipe.transform(new Date(this.getAllLeadList.appointDate._d), 'MM/dd/yyyy');
    
    console.log(this.updateallLOADetails['appointDate'], 'dataaaaaa');
    this._manager_service
      .get_updateLOADetails(this.updateallLOADetails)
      .subscribe(
        (getLOA: any) => {
          // console.log(this.getAllLeadList, '<asd');
          if (getLOA.reponse == true) {
            this.getAllLeadList.contactedNotes = '';
            this.getAllLeadList.wayofContact = '';
            this.getAllLeadList.contactedNotes = '';
            this.getAllLeadList.notContactedNotes = '';
            this.getAllLeadList.appointDate = '';
            this.getAllLeadList.appointTime = '';
            this.getAllLeadList.appointNotes = '';
            this.getresAppointDate = '';
            this.getAllLeadList.resAppointTime = '';
            this.getAllLeadList.resAppointNotes = '';
            this.getAllLeadList.isSuccessFullCompleted = '';
            this.getAllLeadList.reason = '';
            this.getAllLeadList.appointCompletedNotes = '';
            this.getAllLeadList.DisqualifiedNotes = '';
if(getLOA.message=="Lead has been converted to client unable to disqualify the lead."){
  this.toastr.showError(getLOA.message);
}else{
  this.leadLOADetails.resetForm()
  this.toastr.showSuccess("Data Updated");
 
}
            
            //this.getlistall_LOADetails(this.id);
            this.leadLOADetails.form.markAsUntouched();
            this.getLoaDettails();
          } else {
            this.leadLOADetails.form.markAsUntouched();
            this.toastr.showError('Error Occur');
          }
        },
        (error) => {
          console.log('error ===>', error);
          // this.errors = error;
        }
      );
  }
  invitehomeOwner() {
    this.getinvitehomeOwner = {
      leadId: Number(this.id),
      homeOwnerId: this.getAllHomeOwnerList.homeUserId,
      homeOwnerMail: this.getAllHomeOwnerList.emailId,
    };
    this._manager_service
      .updateInviteHomeowner(this.getinvitehomeOwner)
      .subscribe((respHomeowner: any) => {
        console.log(respHomeowner, 'sas');
        if (respHomeowner.reponse == true) {
          this.toastr.showSuccess('User Invited');
        } else {
          this.toastr.showError('Error Occure');
        }
      });
    console.log(this.getinvitehomeOwner, 'hello');
  }

  loaCompletedChange(e: any) {
    console.log(e, 'uploader');
  }

  fileUploderSignature(event: any) {
    this.files = event.target.files[0];

    this.formData = new FormData();
    this.formData.append('formFile', this.files);
    console.log(this.files, 'filename')
    console.log(event, 'full event filename')

    this.fileuploadService.leadLoaSignatureUpload(this.formData).subscribe((fileObj: any) => {
      console.log(fileObj, 'file upload');

      this.dbPathLOASign = fileObj.extraData.DbPath
      this.FileNameLOASign = fileObj.extraData.FileName
      if (fileObj.reponse == true) {
        this.toastr.showSuccess(fileObj.message);
      } else {
        this.toastr.showError(fileObj.message);
      }
    });

  }
  sendLOAsignature() {

    this.sendLOASignature = {
      "leadId": Number(this.id),
      "FileName": this.FileNameLOASign,
      "dbPath": this.dbPathLOASign,
      "createdBy": this.currentLoggedUserId
    }
    console.log(this.sendLOASignature, 'finalLOAupload')
    this.fileuploadService.sentuploadFile(this.sendLOASignature).subscribe((respObj: any) => {
      console.log(respObj, 'LOA')
      if (respObj.reponse == true) {
        this.toastr.showSuccess(respObj.message);
      } else {
        this.toastr.showError(respObj.message);
      }
    })
  }

  // sendUploderSignature() {

  //   this.fileuploadName = this.files.name;
  //   console.log(this.fileuploadName, 'ddddasdsa');
  // }
  /**LOA Details */

  /*Task */

  onChangeTask(event: any) {
    console.log(event, 'listsel');
    console.log(event.target.value, 'listsel9999');
    console.log(this.currentLoggedUserId, 'CurrentLoggedId');
    // this.agentId = event.target.value;

    this.agentids = event.target.value;
    this.taskTabModel['leadId'] = Number(this.id);
    if (this.agentids == 0) {
      //alert('0');
      this.taskTabModel['assignedUserId'] = Number(this.currentLoggedUserId);
      this.taskTabModel['userTypeId'] = Number(this.userTypeId);
    } else {
      // alert('1');
      this.taskTabModel['assignedUserId'] = Number(this.agentids);
    }
    console.log(this.agentids, 'listagentIDs');

    //this.userTypeId = AssignName;
    console.log(this.agentId, 'assigeName');
    //console.log(this.userTypeId, 'assigeName')
  }
  onSubmitLeadTask() {
    this.taskTabModel['completionDate'] = this.datePipe.transform(
      new Date(this.taskTabModel.completionDate._d),
      'MM/dd/yyyy'
    );
    //this.taskTabModel['completionDate'] = this.datePipe.transform(new Date(this.taskTabModel.completionDate), 'MM/dd/yyyy');
    //this.taskTabModel['assignedUserId'] = Number(this.taskTabModel.assignedUserId);

    console.log(this.taskTabModel, 'TaskTabModel');
    this.GetaddOrUpdateLeadTask();
  }
  GetaddOrUpdateLeadTask() {
    console.log(this.taskTabModel, 'TaskTabModel-----');
    if (this.usertype == 'Agent') {
      this.taskTabModel['assignedUserId'] = Number(this.currentLoggedUserId);
    }
    this.taskTabModel['createdBy'] = String(this.currentLoggedUserId);
    //this.taskTabModel['assignedUserId'] = String(this.userTypeId);
    //this.taskTabModel['userTypeId'] = String(this.userTypeId);
    console.log(this.taskTabModel, 'TaskTabModel-----');
    this._manager_service
      .addOrUpdateLeadTask(this.taskTabModel)
      .subscribe((resp: any) => {
        console.log(resp, 'DataLeadTask');
        if (resp.reponse) {
          this.toastr.showSuccess(resp.message);
          this.route.navigate(['/superadmin/task-list']);
        } else {
          this.toastr.showError(resp.message);
        }
      });
  }

  getAgentsForBroker() {
    this._manager_service
      .GetAgentsForBroker(this.usertypeId)
      .subscribe((resp: any) => {
        this.getListagentBroker = resp.listData;
        console.log(this.getListagentBroker, 'List agentfor broker');
      });
  }
  getLoaDettails() {
    this._manager_service.getloaDetails(this.id).subscribe((dataResp: any) => {
      console.log(dataResp, 'activeLogList');
      if(dataResp.listData.length>0){
        this.activeLogList = dataResp.listData;
        this.isLOACompleted = dataResp.listData[0].isSuccessFullCompleted;
        this.lastEventId = dataResp.listData[0].lastEventId;
  
        console.log(this.activeLogList, 'LogList--Details');
        console.log(this.isLOACompleted, 'LogList--isLOACompleted');
        console.log(this.lastEventId, 'LogList--isSuccessFullCompleted');
      }else if(dataResp.listData.length>0){
this.activityListLength=0
      }
      
    });
  }
  /*Task */

  loaoptionDocu(e: any) {
    console.log(e.target.value, 'loaoption')
    let LOAdocuOption = e.target.value;
    if (LOAdocuOption == 0) {
      this.LOAoptionTrue = false
      //alert(0);
    } else if (LOAdocuOption == 1) {
      //alert(1);
      this.LOAoptionTrue = true
    }
  }
  loaSendSignatute() {
    this._getLOAsendDoc();
  }
  _getLOAsendDoc() {
    this.getLOASendDetails = {
      "currentUserId": Number(this.currentLoggedUserId),
      "leadId": Number(this.id)
    }
    console.log(this.getLOASendDetails, 'resp_LOAdocu');
    this._manager_service.getLOAsenddocu(this.getLOASendDetails).subscribe((resp: any) => {
      console.log(resp, 'resp_LOAdocu')
      if (resp.reponse) {
        this.toastr.showSuccess(resp.message);
      } else {
        this.toastr.showError(resp.message);
      }
    })
  }



  notifyManager() {
    let reqData = {

      "leadId": parseInt(this.id),
      "currentUserId": Number(this.currentLoggedUserId)

    }
    this.notification.sendNotifyForManager(reqData).subscribe((data: any) => {
      this.toastr.showSuccess('notification sent successfully')
    })
  }

  opencontact() {
    this.popupcontact = true;
  }
  SSonFormReset() {
    this.popupcontact = false;
    //$('#' + id).modal('hide');
  }
  canDeactivate() {
    return !this.leadLOADetails.form.touched;
  }

}
