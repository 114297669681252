<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Clients"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Assign Task</h2>
          <button class="btn_to_btitle" [routerLink]="['/superadmin/client-file-status']">back</button>
        </div>
      </div>

      <div class="pagecontent_wrapper padding_15">
        <!-- <div class="apex-white_wrap">
          <div class="title_specify">
            <h6>Previously Assigned Detail</h6>
          </div>
          <div class="apexforms-group">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div class="apexinput-froms block_lablels">
                  <label>Negotiator</label>
                  <h6 class="inputform_details"></h6>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div class="apexinput-froms block_lablels">
                  <label>Assit. Negotiator</label>
                  <h6 class="inputform_details"></h6>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div class="apexinput-froms block_lablels">
                  <label>Closer</label>
                  <h6 class="inputform_details"></h6>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 flt_right">
                <div class="apexinput-froms block_lablels">
                  <label>Task Priority</label>
                  <h6 class="inputform_details"></h6>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <hr class="line_af">
              </div>
              <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <div class="apexinput-froms block_lablels">
                  <label>Task</label>
                  <h6 class="inputform_details">
                  </h6>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 flt_right">
                <div class="apexinput-froms block_lablels">
                  <label>Completion Date</label>
                  <h6 class="inputform_details"></h6>
                </div>
              </div>

            </div>
          </div>
        </div> -->


        <div class="apex-white_wrap">
          <div class="apex-title-lilblue">
            <h3>Assign task Detail</h3>
          </div>
          <form #reassignTask="ngForm">
            <div class="apex_group_af">
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div class="apexinput-froms block_lablels">
                      <label>Select File</label>
                      <input type="text" name="firstName" class="bg_blur" placeholder="Select File"
                        [value]="clientData.fileNo" readonly />


                       <!-- <select  class="form-control">
                        <option value="">--Select--</option>
                        <option *ngFor="let filestab of filestabData" [value]="filestab.clientID">
                          {{filestab.fileNo}}
                        </option>
                      </select> -->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7" *ngIf="tabName != 'tasks'">
                    <div class="apexinput-froms block_lablels">
                      <label>Select Specific File Tab</label>
                      <input type="text" name="firstName" class="bg_blur" placeholder="Select File" [value]="tabName"
                        readonly />
                         <!-- <select class="form-control" >
                        <option value="">--Select--</option>
                        <option *ngFor="let specificfilestab of specificfileData" [value]="specificfilestab.fileTabId">
                          {{specificfilestab.fileTabName}}</option>
                      </select> -->
                    </div>
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7" *ngIf="tabName == 'tasks'">
                    <div class="apexinput-froms block_lablels">
                      <label>Select Specific File Tab</label>
                   
                         <select name="tabId" class="form-control"  [(ngModel)]="addTaskTab.tabId">
                        <option value="0">--Select--</option>
                        <option *ngFor="let specificfilestab of specificfileData" [ngValue]="specificfilestab.fileTabId">
                          {{specificfilestab.fileTabName}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div class="apexinput-froms block_lablels">
                      <label>Assign To</label>
                      <select name="userTypeId" id="title" [(ngModel)]="addTaskTab.userTypeId"
                        (change)="getAsssignedusers($event,'true')">

                        <option value="2">Manager</option>
                        <option value="3">Broker</option>
                        <option value="4">Agent</option>
                        <!-- <option value="homeowner">HomeOwner</option> -->
                        <option value="6">Negotiator Assitant</option>
                        <option value="5">Negotiator</option>
                        <option value="7">Closer</option>


                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                    <div class="apexinput-froms block_lablels">
                      <label>Assigned Users Name</label>
                      <select name="assignedUserId" id="title" [(ngModel)]="addTaskTab.assignedUserId"
                        (input)="onChange($event)">
                        <option selected disabled>Select User</option>
                        <option *ngFor="let assignedUsers of assignedUserList" [value]="assignedUsers.userId">
                          {{assignedUsers.userName}}</option>
                      </select>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="apexforms-wraps grped_label">
                <div class="apexforms-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                      <div class="apexinput-froms block_lablels">
                        <label>Select Priority </label>
                        <select name="priority" id="title" [(ngModel)]="addTaskTab.priority">
                          <option value="High">High</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>
                        </select>
                      </div>
                      <div class="apexinput-froms block_lablels mt_30">
                        <label>Due Date </label>
                        <dp-date-picker placeholder="selectDate" theme="dp-material" [config]="datePickerConfig"
                          required="true" name="completionDate" [(ngModel)]="addTaskTab.completionDate ">
                        </dp-date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                      <div class="apexinput-froms block_lablels">
                        <label>Task</label>
                        <textarea class="apex-textarea" name="description"
                          [(ngModel)]="addTaskTab.description"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apxbtn_finalcenter mt_20">
              <!-- <button class="btn_grey">Cancel</button> -->
              <button class="btn_dblue" data-toggle="modal" type="button" data-target="#tabledel">Assign</button>
            </div>

          </form>


        </div>
      </div>
    </div>
  </main>
</div>


<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <div class="assign_lead">
            <img src="../../../assets/images/clients/lead.png" alt="danger" /></div>
          <p>Are you sure want to Assign</p>
          <div class="filterbt_btnn btn_deletecard">
            <button class="btnfilter-cancel" (click)="addTaskintab()">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
