<div class="authguard_wrapper">
  <div class="authdescribe_wrap">
    <h2>Going Above and Beyond To Find Your Next Home</h2>
    <img src="../../../assets/images/auth_bgm.png" alt="bgm" />
  </div>
  <div class="authforms_wrap">
    <div class="authforms_guards">
      <img src="../../../assets/images/ISHORTSALE_LOGO_HORIZONTAL_RGB.png" alt="logo" />
      <h3>forgot password</h3>
      <span>The reset password link will be send to your registered email.</span>
      <form name="forgot-password" (ngSubmit)="forgotPassword.form.valid&&_forgot_password()" #forgotPassword="ngForm">
        <div class="apex_auth_forms">
          <span><i class="fa fa-envelope" aria-hidden="true"></i></span>
          <div class="apexauth_layer">
            <label>Email</label>

            <input type="email" name="email" [(ngModel)]="email.email" #Email="ngModel"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              [ngClass]="{'is-invalid': forgotPassword.submitted && Email.invalid}" required>
            <div *ngIf="forgotPassword.submitted && Email.invalid" class="invalid-feedback">
              <div *ngIf=" Email.errors?.required">Email is required</div>
              <div *ngIf="Email.errors?.pattern">Email must be a valid email address</div>
            </div>
          </div>
        </div>
        <div class="apex_auth_submitprocess">
          <button class="btn-apex-primary">Submit</button>
        </div>
        <div class="back_login">
          <span>Back to <a routerLink="/login">LOGIN</a>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>
