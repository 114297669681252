import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  // get token here
  _save_token(token: string) {
    sessionStorage.removeItem('Token');
    sessionStorage.setItem('Token', token);
  }
  _save_term(term:any){
    sessionStorage.removeItem('term');
    sessionStorage.setItem('term',term)
  }
  _get_token() {

    return sessionStorage.getItem('Token');
  }


  _get_agentType() {
    return sessionStorage.getItem('agentype');
  }
  _destroy_token() {
    sessionStorage.removeItem('Token');

  }

  //UserRole
  _save_userRole(user_role: string) {
    sessionStorage.setItem('user_Role', user_role);
  }
  _get_userRole(user_role?: string) {
    return sessionStorage.getItem('user_Role');
  }
  _userTypeId(userTypeId?: string) {
    return sessionStorage.getItem('userTypeId');
  }

  _destroy_userRole() {
    sessionStorage.removeItem('user_Role');
  }
  //Email

  _set_email(email: any) {
    sessionStorage.removeItem('email');
    sessionStorage.setItem('email', email);
  }

  _get_email() {
    console.log(sessionStorage.getItem('email'), "tokenstoreserviece")
    return sessionStorage.getItem('email');

  }

  //set USertypeID
  _set_userTypeId(userTypeId: any) {
    sessionStorage.setItem("userTypeId", userTypeId)
  }
  _get_userTypeId() {
    return sessionStorage.getItem('userTypeId')
  }
  _get_userInfo() {
    return sessionStorage.getItem('userInfo')
  }
  //  _destroy_email(){
  //   sessionStorage.removeItem('email')
  //  }

}
