import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { TokenService } from '../../service/token.service';
import { DropdownService } from 'src/app/service/dropdown.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonDataService } from 'src/app/service/common-data.service';
import { ToastService } from '../../service/toast.service';

declare var $: any;


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  clientassigned: any = [];
  Dbclientassigned: any = [];
  leadId:any
  public clientList: any;
  public cancelledClientList: any;
  public closedClientList: any;
  public DbclientList: any;
  public clientContactCommon: any = [];
  public assignedUsers: any;
  public contactDetails: any = [];
  public negotiatorEmail: any;
  public phoneNumber: any;
  searchKey: any
  showCross: boolean = false;
  isOpen = true;
  isCancel = false;
  isCompleted = false;
  leadUniqueList: any=[];
  fromLead: boolean =false;
  usertypeId: string | null;
  searchleadUniqueList: any;
  constructor(
    private route: Router,
    private clientService: ManagerService,
    private tokenStorage: TokenService,
    private _dropDown: DropdownService,
    private toast:ToastService,
    private commonService:CommonDataService,) {

  }
  public usertype: any;
  ngOnInit(): void {
    
    console.log("datd", this.commonService.data);
    this.usertype = this.tokenStorage._get_userRole();
     this.usertypeId = sessionStorage.getItem('userTypeId')
    console.log(this.usertype, 'userType')
    this.toAssignLead_list()
   setTimeout(() => {
    this.assignedLead_list();
   }, 0);
  
    
    

  }
  reOpen(data:any, clientId:number){ 
    // console.log("bbbb")
    // return false;
    if(data=='complete'){
      this.clientService.openFile(clientId,'/Client/ReOpenClient?ClientId=').subscribe((res:any)=>{
      if(res){
    
setTimeout(() => {
  this.isOpenview()
}, 0);
  
  }
      })
    }
  else{
    this.clientService.openFile(clientId,'/Client/CloseClient?ClientId=').subscribe((res:any)=>{
   if(res){
  
  setTimeout(() => {
    this.isCompletedview()
  }, 0);
   
   }
    })
  }
   
  }


  toAssignLead_list() {
    this.showCross = false;
    this.searchKey = "";
    this.clientService._getall_AssignedClientbyuser().subscribe((clientdata: any) => {

      this.clientList = clientdata.listData ? clientdata.listData : [];
      this.DbclientList = clientdata.listData ? clientdata.listData : [];
      this.assignedUsers = this.clientList.assignedUsers;
      this.contactDetails = this.clientList.contactDetails
      console.log(this.clientList, 'Client')


    })
  }
  getTaskCompletionStatus(data: any) {
    // console.log("data ==>getTaskCompletionStatus", data);
    return new Date().setHours(0, 0, 0, 0) > new Date(data.forclosureDate).setHours(0, 0, 0, 0) && data.docStatus != 'Completed' ? true : false;
  }
  getCancelledList() {
    this.clientService._getall_CancelledClientbyuser().subscribe((cancelledclientdata: any) => {
      this.cancelledClientList = cancelledclientdata.listData;
    })
  }

  getClosedList() {
    
    this.clientService._getall_ClosedClientbyuser().subscribe((closedClientdata: any) => {
      this.closedClientList = closedClientdata.listData;
    })
  }



  assignedLead_list() {
    this.clientService._getall_AssignedClientbyuser().subscribe((data: any) => {
      console.log(data, 'OpenedEscrow');
      this.clientassigned = data.listData;
      this.Dbclientassigned = data.listData;
      if (this.clientassigned !== null) {
        this.clientContactCommon = data.listData[0].contactUs;
        // this.contactDetails = this.clientContactCommon.apexContactDetails;
      }

      console.log(this.clientContactCommon, "contactCommon")
      console.log(this.contactDetails, "contact2222")
    })
  }
  showCrossicon() {
    this.showCross = true;

  }
  leadSearch() {

    console.log(this.searchKey)
    this._dropDown.search_client(this.searchKey).subscribe((searchData: any) => {
      this.clientList = searchData.listData;
      console.log(searchData.listData)
    })
  }

  contactUsList(items: any = {}) {
    this.negotiatorEmail = items.contactUs.negotiatorEmail
    this.phoneNumber = items.contactUs.phoneNumber

    this.contactDetails = items.contactUs.apexContactDetails
    console.log(this.contactDetails, 'ListDEtauilsContact')

  }



  // this.getCurrentList = window.history.state;
  //   console.log(this.getCurrentList.obj);
  //   this.clientTask = this.getCurrentList.obj;
  // this.route.navigateByUrl('/superadmin/task-list-view', { state: { obj: nextIndex } })
  //
  // routerLink="/superadmin/clients-signed-loa/{{items.leadId}}/"
  assignuser_list(clientdata: any) {
    sessionStorage.setItem('clientData', JSON.stringify(clientdata));
    this.route.navigateByUrl('/superadmin/clients-signed-loa')
  }
  
  
  goto_tabs(clientData: any) {
    sessionStorage.setItem('clientData', JSON.stringify(clientData));
    this.route.navigateByUrl('/superadmin/client-file-status')
  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.clientList = self.DbclientList.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {

        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

  onSearchAss(e: any) {
    console.log("value ", e);
    var self = this;
    self.clientassigned = self.Dbclientassigned.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {

        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

  isOpenview() {
    this.isOpen = true;
    this.isCancel = false;
    this.isCompleted = false;
    this.toAssignLead_list();
  }

  isCancelview() {
    this.isOpen = false;
    this.isCancel = true;
    this.isCompleted = false;
    this.getCancelledList()
  }

  isCompletedview() {
    this.isOpen = false;
    this.isCancel = false;
    this.isCompleted = true;
    this.getClosedList()
  }
  fromLeadData(type:any,e:any){
    
  if(type == "fromlead"){
    
    this.fromLead = true;
    this._dropDown.getLeadUniqueId().subscribe((leadData:any)=>{
      this.leadUniqueList = leadData.listData;
      this.searchleadUniqueList = leadData.listData;
      console.log("Lead list",  this.leadUniqueList);
    })
  }else if(type == "fromscratch"){
    this.fromLead = false;
  sessionStorage.removeItem('clientData')
    this.route.navigateByUrl('/superadmin/client-file-status/basic')
    $('#leadscratch').modal('hide')


    
  }else if(type == "fromdropdown"){
    $('#leadscratch').modal('hide')

   
    let req = {"leadId":parseInt(e) ,"isfromScratch":false,  "cretaedBy": this.usertypeId }
    // this.getClientDetails(2)
    this.clientService._add_ManualClient(req).subscribe((clientData:any)=>{
      console.log(clientData,"clientszzzz")
          this.getClientDetails(clientData.returnId) ;   
          this.toast.showSuccess(clientData.message)
          // this.route.navigate(['/client-file-status/lead']);            
          // $('#leadscratch').modal('hide')  
            }
            
            
            )
          
          }}


  getClientDetails(clientid:any) {
    this.clientService.getClientDetails(clientid).subscribe((clientData:any)=>{
      // sessionStorage.removeItem('clientData')
      sessionStorage.setItem('clientData', JSON.stringify(clientData.getData));

      this.route.navigate(['/client-file-status/lead']); 
    
    })
  }
  onSearchTitle(e: any) {
    this.leadUniqueList = this.searchleadUniqueList.filter((item: any) => item.uniqueLeadId.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
}
