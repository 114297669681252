<div class="terms_condition">
    <div class="terms_main">
        <h1>Acknowledgment </h1>
        <p>These Legal Terms, Conditions and Disclosures set forth the entire agreement between the parties relating to
            the use of this site. The Peak Corporate Network Group of Companies, including, but not limited to, Peak
            1031 Exchange, Inc., Apex Resolution, Inc., Peak Realty Co, Peak Finance Company, Escrow Hub, Peak
            Commercial, Peak Foreclosure Services, Inc., Grand Mutual Insurance, Inc., and their affiliates ("Peak,"
            "we," "us," or "our") can revise these Legal Terms, Conditions and Disclosures at any time by updating this
            posting.</p>
        <p>Your access to and use of this site is conditioned on Your acceptance of and compliance with these Terms and
            Conditions. These Terms and Conditions apply to all visitors, users and others who access or use this site.
            By accessing or using this site You agree to be bound by these Terms and Conditions. If You disagree with
            any part of these Terms and Conditions then You may not access this site.</p>
    </div>
    <div class="terms_main">
        <h1>Copyright and Service Mark or Trademark</h1>
        <p>The content of this site, including but not limited to the text and images and their arrangement, are ©
            Copyright 2019 by PEAK CORPORATE NETWORK. All rights reserved.</p>
        <p>You may not copy the material on this site, upload or download, post, publish, transmit or distribute it in
            any way (whether electronically or otherwise) or create other works based on it, without our prior written
            consent.</p>
        <p>Copies made incidentally in the ordinary course of your use of this web site (such as in cache memory) and a
            reasonable number of printed copies for your own educational and research use are permitted, provided you do
            not delete or change any copyright, trademark or other notices contained thereon. Title to, and all
            intellectual property rights in, the material on this site remain in/with Peak and/or its affiliates or
            licensors. </p>

    </div>
    <div class="terms_main">
        <h1>Disclaimers</h1>
        <p>ALL CONTENT OF THIS SITE IS PROVIDED TO YOU "AS IS" WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND EITHER
            EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
            PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, SECURITY, ACCURACY OR OTHERWISE. THESE MATERIALS MAY CONTAIN
            ERRORS OF PUBLICATION OR TRANSMISSION.</p>
        <p>ALL CONTENT OF THIS SITE, INCLUDING WITHOUT LIMITATION ANY DATA, PRICES, AND QUOTATIONS CONTAINED HEREIN, ARE
            SUBJECT TO CHANGE WITHOUT NOTICE AND THEY DO NOT CONSTITUTE AN OFFER, BUT ARE MERELY INFORMATIONAL AND FOR
            YOUR CONVENIENCE.</p>
        <p>We shall not be subject to any obligations of confidentiality regarding information submitted through this
            site or any page hereof except as otherwise agreed to by us in writing with a person(s) or entity(ies)
            having a direct relationship with us or as otherwise required by law. We may use, for any purpose, any
            information submitted through this site or any page hereof and such information shall be deemed to be and
            remain our property.</p>

        <p>Although we attempt to keep the information on this site accurate, complete, and current, we do not warrant
            that the content herein is accurate, complete, or current. We make no commitment to update the content
            herein. It is your responsibility to verify any information before relying on it. The content of this site
            may include technical inaccuracies or typographical errors. From time to time changes are made to the
            content herein. We may make changes in the products and/or the services described herein at any time.</p>
    </div>
    <div class="terms_main">
        <h1>Limitation of Liability</h1>
        <p>PEAK WILL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR ANY DAMAGES OR INJURY, INCLUDING BUT NOT LIMITED TO,
            THOSE CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OF
            TRANSMISSION, COMPUTER VIRUS, OR SYSTEM FAILURE.</p>
        <p>PEAK SPECIFICALLY DISCLAIMS ANY LIABILITY (WHETHER IN TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE AND
            WHETHER FORESEEABLE OR NOT) FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN
            ANY WAY CONNECTED WITH ACCESS TO OR USE OF THIS SITE OR THE COPYING OR DISPLAY OF INFORMATION ACCESSED
            THROUGH THIS SITE, WHETHER OR NOT PEAK WAS ADVISED OF OR AWARE OF THE POSSIBILITY OF SUCH DAMAGES.</p>
        <p>THE FOREGOING DISCLAIMER OF LIABILITY IS EFFECTIVE WITHOUT REGARD TO THE FORM OF ANY ACTON, INCLUDING BUT NOT
            LIMITED TO CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR ANY OTHER THEORY. WITHOUT LIMITING THE FOREGOING,
            PEAK SHALL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES INCURRED AS A RESULT OF ANY INFORMATION OR CONFIDENTIAL
            INFORMATION SUBMITTED TO IT THROUGH THIS SITE OR ANY PAGE HEREOF OR FOR ITS TRANSMISSION OF INFORMATION OR
            CONFIDENTIAL INFORMATION TO ANY PERSON(S) OR ENTITY(IES) AS A RESULT OF A REQUEST FOR SUCH TRANSMISSION
            REASONABLY BELIEVED BY PEAK TO HAVE BEEN MADE BY SUCH PERSON OR ENTITY. THE ABOVE LIMITATIONS OR EXCLUSIONS
            MAY NOT APPLY TO YOU TO THE EXTENT THAT APPLICABLE LAW DOES NOT ALLOW THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.</p>

    </div>
    <div class="terms_main">
        <h1>Links to Third Party Sites</h1>
        <p>Our site may contain links to third-party web sites or services that are not owned or controlled by Peak.</p>
        <p>Peak has no control over, and assumes no responsibility for, the content, privacy policies, or practices of
            any third party web sites or services. These links are provided by us only as a convenience to you. We have
            not tested any information, software, products or services found on these sites and therefore cannot make
            any representations whatsoever with respect thereto. </p>
        <p>There are risks in using any information, software, products, or services found on the Internet; and we
            strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or
            services that you visit.</p>
    </div>
    <div class="terms_main">
        <h1>Jurisdiction</h1>
        <p>These Terms and Conditions will be governed by and be interpreted pursuant to the laws of the State of
            California, United States of America, notwithstanding any principles of conflicts of law. The state or
            federal courts located in Los Angeles, California, shall have exclusive jurisdiction over any cause of
            action arising out of or related to the content or use of this site.</p>
    </div>
    <div class="terms_main">
        <h1>Entire Agreement</h1>
        <p>
            If any part of these Terms and Conditions is unlawful, void, or unenforceable, that part will be deemed
            severable and will not affect the validity and enforceability of any remaining provisions.
        </p>
    </div>
    <div class="terms_main">
        <h1>Privacy Principles</h1>
        <p>We recognize and respect the privacy expectations of our consumer customers and provide an explanation of our
            privacy policy on our Web site. Please read our privacy policy. Please also be aware that the privacy policy
            does not limit or modify, and are governed by, these Terms and Conditions.</p>
    </div>
    <div class="terms_main">
        <h1>Location of Services</h1>
        <p>Our services are offered only in jurisdictions where they may be lawfully offered for sale; and are subject
            to the terms of the applicable agreement. NOT ALL SERVICES MENTIONED HEREIN ARE AVAILABLE IN ALL
            JURISDICTIONS.</p>
    </div>
    <div class="terms_main">
        <h1>General Disclaimers</h1>
        <p>Peak is an equal opportunity employer. The information contained in any article or graph within the Library
            hereof is based on sources, which we believe to be reliable, but we cannot warrant its accuracy or
            completeness. Such information is subject to change and is not intended to influence investment decisions.
        </p>
    </div>
    <div class="terms_main">
        <h1>Contact Us</h1>
        <p>If you have any questions about these Terms and Conditions, you can contact us:</p>
        <div>
            <ul>
                <li>By email: info@apexresolution.com</li>
                <li>By visiting this page on our website: http://www.apexresolution.com</li>
            </ul>
        </div>
    </div>

</div>