import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';


@Component({
  selector: 'app-user-usage-date-report',
  templateUrl: './user-usage-date-report.component.html',
  styleUrls: ['./user-usage-date-report.component.css']
})
export class UserUsageDateReportComponent implements OnInit {

  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;

  datePickerConfig = {
    format: 'MM/DD/YYYY'
  };

  //DropDown
  public userUsageData: any = [];
  public userDataa: any = [];
  public userType: any = [];
  public userTypeBack: any = [];
  public userList: any = [];
  public userListBack: any = [];

  //list
  public userUsageListData: any = [];
  public userUsageobjlst: any = {};

  public userSpend: any = {};
  //obj
  public userUsageOBj = {
    "clientId": 0,
    "userId": 0,
    "fromdate": '',
    "todate": '',
    "createdBy": this.tokenStorage._get_userTypeId()
  }
  userusageReport = '';
  clientIdData = '';
  userTypeId: number;
  userIdData = '';
  public userData: any = [];;
  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    // alert()
    this.userUsageGetAllReport();
    // this.userClientDropdown();
    this.getUserType()
  }

  // userClientDropdown() {
  //   let CurrentUserId: any;
  //   CurrentUserId = this.tokenStorage._get_userTypeId();
  //   console.log(CurrentUserId, "currentuserid");
  //   this._manager_service.userClient_dropdown(CurrentUserId).subscribe((uuresponse: any) => {
  //     this.userUsageData = uuresponse.listData;
  //     console.log(this.userUsageData, "uudataaaaa")
  //   })
  // }

  getUserType() {
    this._manager_service.getUserType().subscribe((response: any) => {
      this.userType = response.listData;
      this.userTypeBack = response.listData;
      console.log(this.userType, "userType")
    })
  }

  getUserListById(userTypeId: number) {
    this._manager_service.getUserList(userTypeId).subscribe((response: any) => {
      this.userList = response.listData;
      this.userListBack = response.listData;
    })
  }

  getUserId(assignVal: any) {
    console.log(assignVal, "helllooo")
    this.user_dropdown(assignVal.target.value)
    console.log(assignVal, "assignedClientId");
    this.userUsageOBj.clientId = parseInt(assignVal.target.value);
    console.log(this.userUsageOBj.clientId, "Hello1");
  }

  user_dropdown(id: number) {
    this._manager_service.user_dropdown(id).subscribe((res: any) => {
      this.userDataa = res.listData;
    })
  }

  userUsageGetAllReport() {
    this._manager_service.userUsageDate_getAllReport(this.userUsageOBj).subscribe((res: any) => {
      // this.userUsageobjlst = (res.listData != null) ? res.listData : [];
      this.userSpend = res.getData != null ? res.getData : {};
      //console.log(this.userUsageobjlst, "userUsageobjlst");
      console.log(this.userSpend, "userUsageListData")
    })
  }

  userUsageFilter() {

    this.userUsageOBj.clientId = parseInt(this.clientIdData);
    this.userUsageOBj.userId = parseInt(this.userIdData);
    this.userUsageGetAllReport();

  }

  userUsageReset() {
    this.userUsageOBj.clientId = 0;
    this.userUsageOBj.userId = 0;
    this.clientIdData = '0';
    this.userIdData = '0';
    this.userUsageGetAllReport();
  }

  downloadExcel() {
    this.userUsageOBj.clientId = parseInt(this.clientIdData);
    this.userUsageOBj.userId = parseInt(this.userIdData);
    this._file_upload.userUsage_report(this.userUsageOBj);
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }
  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  userusagesearchReport() {
    let searchuserUsageOBj = {
      "clientId": 0,
      "userId": 0,
      "createdBy": this.tokenStorage._get_userTypeId(),
      "word": this.userusageReport
    }
    this._manager_service.userUsage_searchReport(searchuserUsageOBj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.userUsageListData = [];
      }
      else {
        this.userUsageListData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }

  onSearchUsersType(e: any) {
    this.userType = this.userTypeBack.filter((item: any) => item.userTypeName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearchUsers(e: any) {
    this.userList = this.userListBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }


}
