<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Auto Generated Documents">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Auto Generated Documents list</h2>
          <div class="sidebar-search lead_glo global-search">
            <div>
                <div class="input-group">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <input type="search" placeholder="Search..." (input)="onSearch($event)" #searchword>
                </div>
            </div>
          </div>
          <!-- <button class="btn_to_btitle"><a routerLink="/superadmin/assitnegotiators-list">back</a></button> -->
        </div>
      </div>
      <div class="pagecontent_wrapper">
        <!-- <div class="apex-white_wrap">
          <div class="filterapx_wrap">
            <div class="filter_setting_forms">
              <h3>Filter By</h3>
              <div class="apxsilsetting_group">
                <label>Folder Name</label>
                <mat-form-field appearance="fill">
                  <mat-select [(ngModel)]="filterObj.folderId" [ngModelOptions]="{standalone: true}">
                    <input type="text" (keyup)="onSearchFile($event)" (keydown)="$event.stopPropagation()">
                    <mat-option>--Select--</mat-option>
                    <mat-option *ngFor="let item of manageAutoBack" [value]="item.folderId">
                      {{item.folderName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="filter_setting">
              <ul>
                <li class="filter_apx"><button type="button"><img src="../../../assets/images/filter.png" alt="filter"
                      (click)="filterTaskList()"></button></li>
                <li class="setting_reset"><button type="reset"><img src="../../../assets/images/setting_reset.png"
                      alt="filter" (click)="manageAuto=manageAutoBack"></button></li>
              </ul>
            </div>
          </div>


        </div> -->
        <div class="apxtable_grids_wrapper">
          <div class="apxtable_grid_head">
            <h2>List of Auto Generated Documents</h2>
            <!-- <button [routerLink]="['/superadmin/manage-auto-edit']">Add AutoGen</button> -->
            <button [routerLink]="['/superadmin/manage-auto-edit']" class="btn-create-manage"><svg width="18"
                height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.61931 10.1372C10.4355 10.137 11.4488 7.31722 11.7141 5.00095C12.0409 2.14755 10.6915 0 7.61931 0C4.54748 0 3.19744 2.14739 3.52447 5.00095C3.78999 7.31722 4.803 10.1374 7.61931 10.1372Z"
                  fill="#085589" />
                <path
                  d="M13.7773 11.311C13.8679 11.311 13.958 11.3137 14.0474 11.3187C13.9134 11.1275 13.7582 10.9589 13.5767 10.8233C13.0355 10.4194 12.3347 10.2869 11.7151 10.0435C11.4135 9.92501 11.1435 9.80733 10.8899 9.67334C10.0342 10.6117 8.91838 11.1026 7.61897 11.1028C6.31999 11.1028 5.20423 10.6119 4.34865 9.67334C4.09512 9.80736 3.82499 9.92501 3.52345 10.0435C2.90387 10.287 2.20306 10.4194 1.66188 10.8233C0.725999 11.5219 0.484136 13.0933 0.294104 14.1652C0.137274 15.05 0.0319142 15.953 0.00116256 16.8517C-0.022661 17.5479 0.321065 17.6455 0.903483 17.8557C1.63273 18.1187 2.38571 18.314 3.14374 18.474C4.6077 18.7832 6.11672 19.0207 7.61923 19.0313C8.34727 19.0261 9.07678 18.9674 9.80198 18.8734C9.26502 18.0954 8.95008 17.153 8.95008 16.1383C8.95012 13.4765 11.1156 11.311 13.7773 11.311Z"
                  fill="#085589" />
                <path
                  d="M13.7773 12.2764C11.6446 12.2764 9.91553 14.0054 9.91553 16.1382C9.91553 18.2709 11.6445 20 13.7773 20C15.9101 20 17.6392 18.2709 17.6392 16.1382C17.6391 14.0054 15.9101 12.2764 13.7773 12.2764ZM15.4669 16.8007H14.4399V17.8276C14.4399 18.1936 14.1433 18.4903 13.7774 18.4903C13.4114 18.4903 13.1147 18.1936 13.1147 17.8276V16.8007H12.0878C11.7219 16.8007 11.4252 16.5041 11.4252 16.1381C11.4252 15.7722 11.7218 15.4755 12.0878 15.4755H13.1147V14.4486C13.1147 14.0826 13.4114 13.7859 13.7774 13.7859C14.1433 13.7859 14.4399 14.0826 14.4399 14.4486V15.4755H15.4669C15.8328 15.4755 16.1295 15.7722 16.1295 16.1381C16.1294 16.5041 15.8328 16.8007 15.4669 16.8007Z"
                  fill="#085589" />
              </svg>
              Create File</button>
          </div>
          <div class="apxtable_grid_table">
            <div class="table-responsive apx-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')"># <span [hidden]="reverse"><img
                          src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <!-- <th [class.active]="order === 'userid'" (click)="setOrder('userid')">Title<span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th> -->
                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')">Folder name<span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')">Attachment<span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let items of manageAuto | orderBy: order:reverse | paginate: { itemsPerPage: 45, currentPage: p }; let i = index ">
                    <td>{{ (6 *(p-1))+ i +1 }}</td>
                    <!-- <td>{{ items.titleName }}</td> -->
                    <td>{{ items.folderName }}</td>
                    <td>
                      <div class="manauto_attachments"><img src="../../../assets/images/clients/ms-icon.png"
                          alt="icon"><span>{{items.fileCount}}</span></div>
                    </td>
                    <td>
                      <div class="apx-actions">

                        <span class="pencil"><a [routerLink]="['/superadmin/manage-auto-edit']"
                            [queryParams]="{id:items.titleId, folderId: items.folderId }"><i class="fas fa-pencil-alt"></i></a></span>
                        <!-- data-toggle="modal" data-target="#tabledel"  modal for delete -->
                        <span class="trash"><i class="far fa-trash-alt" (click)="Deletevent(items)"></i></span>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="apxtable_pagination_wrapper">
          <!-- <div class="apxtable_pagelist">
            <div class="apxlist_number_page">
              <span>Show</span>
              <input value="7" type="number" />
              <span>Entries</span>
            </div>
            <div class="pagination_gopage">
              <span>Go to Page</span>
              <input type="text" value="1" />
            </div>
          </div> -->
          <div class="apxtable_pagination">
            <!-- <ul>
                  <li class="prev">Prev</li>
                  <li class="active"><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li>...</li>
                  <li><a href="#">16</a></li>
                  <li><a href="#">17</a></li>
                  <li><a href="#">18</a></li>
                  <li class="next">Next</li>
                </ul> -->
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
        </div>

      </div>
    </div>
  </main>

</div>



<!-- The Modal -->
<!-- <div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">


      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel">Cancel</button>
        </div>
      </div>


      <div class="modal-body">
        <div class="filterforms_setting">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>First Name</label>
                <input type="text" placeholder="First name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Last Name</label>
                <input type="text" placeholder="Last name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Status</label>
                <select>
                  <option value="">All</option>
                  <option value="">All-1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div> -->



<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <img src="../../../assets/images/danger.png" alt="danger" />
          <p>Are you sure want to delete the data?</p>
          <div class="filterbt_btnn btn_deletecard">
            <button class="btnfilter-cancel">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
