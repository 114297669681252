import { email, unique, alpha, compare, prop, propArray, propObject, required } from "@rxweb/reactive-form-validators"

export class HomeOwner {
  @required()
  firstName?: string;
  @prop()

  countryId: number = 1;
  @required()
  lastName?: string;
  @prop()
  emailId: string = " ";
  @prop()
  phone: string = "";
  @prop()
  @required()
  address: string = " ";
  @prop()
  apn: string = "";
  @required()
  @prop()
  stateName: any = " ";
  @required()
  @prop()
  @required()
  cityName: string;
  @prop()
  @required()
  zipCode: number;
  @prop()
  isActive: Boolean = true;
  @prop()
  notes?: string;
  @prop()
  spouse?: string;
  @prop()
  referedBy?: string;

}

export class leadAssign {
  @prop()
  userTypeId: number = 0;
  @prop()
  userId: number = 0;
}
export class leadmodel {

  @propObject(HomeOwner)
  homeOwner?: HomeOwner;

  @propArray(leadAssign)
  leadChildAssign?: leadAssign[];

  @prop()
  primayResidence: string = " ";
  @prop()
  propertyType: string = " ";
  @prop()
  unit: string = " ";
  @prop()
  sqFt: string = " ";
  @prop()
  yearBuild: string = " ";
  @prop()
  noofBeds: string = " ";
  @prop()
  noofBaths: string = " ";
  @prop()
  lotSize?: number;
  @prop()
  estimatedValue: string = "";
  @prop()
  asseddedValue: string = "";
  @prop()
  foreClosureStage: string = "";
  @prop()
  recordingDate?= "";
  @prop()
  salesDate?= "";
  @prop()
  defaultAsOf?= "";
  @prop()
  fcEstimatedLoanPosition: string = " ";
  @prop()
  fcLoanDate?= "";
  @prop()
  fcLoanAmount: string = "";
  @prop()
  firstLoanDate?= "";
  @prop()
  firstLoanAmount: string = "";
  @prop()
  secondLoanAmount: string = "";
  @prop()
  phoneNumber1: string = "";
  @prop()
  phoneNumber2: string = "";
  @prop()
  phoneNumber3: string = "";
  @prop()
  phoneNumber4: string = "";
  @email()
  email1?: string;
  @email()
  email2?: string;
  @prop()
  apn: string = "";
  @prop()
  countryId: number = 1;
  @prop()
  countryName: string = " ";
  @prop()
  address?: string;
  @prop()
  cityName: string;
  @prop()
  stateName: any = "";
  @required()
  @prop()
  zipCode: string = " ";
  isDuplicate: Boolean = false;
  duplicate: string = " ";
  @prop()
  rejectedReason: string = " ";
  @prop()
  notes?: string;
  @prop()
  spouse?: string;
  @prop()
  referedBy?: string;
  lastOpenedDate?:string="";

}


