import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';

@Component({
  selector: 'app-openclose-report',
  templateUrl: './openclose-report.component.html',
  styleUrls: ['./openclose-report.component.css']
})
export class OpencloseReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;
  public reportName: any = 'openclose-report';

  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true
  };
  //DropDown
  public opencloseData: any = [];

  //list
  public opencloseListData: any = [];
  public opencloseobjlst: any = {};
  public userData: any = [];
  public statusData: any = [];

  // public userTypeIdData: any = 0;
  // public userIdData: any = 0;
  // public statusssData: any = 0;

  userTypeIdData = '0';
  userIdData = '0';
  statusssData = '';
  ssopencloseReport = '';
  opencloseFromDateData = '';
  opencloseToDateData = '';
  //obj
  public opencloseOBj = {
    "userTypeId": 0,
    "userId": 0,
    "status": 0,
    "fromdate": '',
    "todate": '',
    "updatedby": this.tokenStorage._get_userTypeId()
  }


  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    this.openclosedGetAllReport();
    this.opencloseReportUserTypedropdown();
    this.opencloseReportStatusdropdown();
  }



  opencloseReportUserTypedropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.opencloseReport_UserTypedropdown(CurrentUserId).subscribe((res: any) => {
      this.opencloseData = res.listData;
      console.log(this.opencloseData, "ffdataaaaa")
    })
  }


  getUserId(assignVal: any) {
    console.log(assignVal, "helllooo")
    this.opencloseReportUserdropdown(assignVal.target.value)
    console.log(assignVal, "assignedClientId");
    this.opencloseOBj.userTypeId = parseInt(assignVal.target.value);
    console.log(this.opencloseOBj.userTypeId, "Hello1");
  }



  opencloseReportUserdropdown(id: number) {
    this._manager_service.opencloseReport_Userdropdown(id).subscribe((res: any) => {
      this.userData = res.listData;
    })
  }





  opencloseReportStatusdropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.opencloseReport_Statusdropdown(CurrentUserId).subscribe((res: any) => {
      this.statusData = res.listData;
      console.log(this.statusData, "ffdataaaaa")
    })
  }


  openclosedGetAllReport() {
    this._manager_service.opencloseReport_getAllReport(this.opencloseOBj).subscribe((res: any) => {
      // this.opencloseobjlst = (res.getData != null) ? res.getData : [];
      this.opencloseListData = (res.listData != null) ? res.listData : [];
      //console.log(this.opencloseobjlst, "filesperClosureobjlst");
      console.log(this.opencloseListData, "filesperClosureListData")
    })
  }



  opencloseFilter() {
    this.opencloseOBj.userTypeId = parseInt(this.userTypeIdData);
    this.opencloseOBj.userId = parseInt(this.userIdData);
    this.opencloseOBj.status = parseInt(this.statusssData);
    this.opencloseOBj.fromdate = this.opencloseFromDateData;
    this.opencloseOBj.todate = this.opencloseToDateData;
    this.openclosedGetAllReport();
  }

  opencloseReset() {
    this.opencloseOBj.userTypeId = 0;
    this.opencloseOBj.userId = 0;
    this.opencloseOBj.status = 0;
    this.opencloseOBj.fromdate = '';
    this.opencloseOBj.todate = '';
    this.opencloseFromDateData = '';
    this.opencloseToDateData = '';
    this.userTypeIdData = '0';
    this.userIdData = '0';
    this.statusssData = '0';
    this.openclosedGetAllReport();
  }

  downloadExcel() {
    this._file_upload.openclose_report();
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }

  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  openclosedsearchReport() {
    let searchopencloseOBj = {
      "userTypeId": 0,
      "userId": 0,
      "status": 0,
      "updatedby": this.tokenStorage._get_userTypeId(),
      "word": this.ssopencloseReport
    }
    this._manager_service.openclose_searchReport(searchopencloseOBj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.opencloseListData = [];
      }
      else {
        this.opencloseListData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }

}
