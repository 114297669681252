<nav id="sidebar" class="sidebar-wrapper">
  <div class="sidebar-content">
    <div class="sidebar-brand">
      <a href="#"><img src="../../../assets/images/ISHORTSALE_LOGO_HORIZONTAL_RGB.png" /></a>
      <div id="close-sidebar">
        <i class="fas fa-times"></i>
      </div>
    </div>

    <!-- sidebar-header  -->

    <!-- sidebar-search  -->
    <div class="sidebar-menu" *ngIf="usertype == 'SuperAdmin'">
      <ul>
        <li>
          <a routerLink="/superadmin/dashboard" routerLinkActive="link-active">
            <i class="fa fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>


        <!-- <li class="sidebar-dropdown"> -->
        <li>
          <a routerLink="/superadmin/manager-list" [ngClass]="{'active_sub':currentUrl=='/superadmin/manager-list' || currentUrl=='/superadmin/manager-list-edit'
               || currentUrl=='/superadmin/brokers-list' || currentUrl=='/superadmin/brokers-list-edit'
               || currentUrl=='/superadmin/agents-details' || currentUrl=='/superadmin/agents-details-edit'
               || currentUrl=='/superadmin/houseowner-detail-list' || currentUrl=='/superadmin/houseowner-detail-edit'
               || currentUrl=='/superadmin/negotiators-list' || currentUrl=='/superadmin/negotiators-edit'
               || currentUrl=='/superadmin/assitnegotiators-list' || currentUrl=='/superadmin/assitnegotiators-edit'
               || currentUrl=='/superadmin/closer-list' || currentUrl=='/superadmin/closer-edit'}">
            <i class="fas fa-users"></i>
            <span>Manage Users</span>
          </a>
          <!-- <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/manager-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/manager-list-edit'}"
                  routerLinkActive="link-active">Managers

                </a>
              </li>
              <li>
                <a routerLink="/superadmin/brokers-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/brokers-list-edit'}"
                  routerLinkActive="link-active">Brokers</a>
              </li>
              <li>
                <a routerLink="/superadmin/agents-details"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/agents-details-edit'}"
                  routerLinkActive="link-active">Agents</a>
              </li>
              <li>
                <a routerLink="/superadmin/houseowner-detail-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/houseowner-detail-edit'}"
                  routerLinkActive="link-active">Homeowner</a>
              </li>
              <li>
                <a routerLink="/superadmin/negotiators-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/negotiators-edit'}"
                  routerLinkActive="link-active">Negotiator</a>
              </li>
              <li>
                <a routerLink="/superadmin/assitnegotiators-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/assitnegotiators-edit'}"
                  routerLinkActive="link-active">Negotiator Assistant</a>
              </li>
              <li>
                <a routerLink="/superadmin/closer-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/closer-edit'}"
                  routerLinkActive="link-active">Closer</a>
              </li>
            </ul>
          </div> -->
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/lead-list"
            [ngClass]="{'active_sub':currentUrl=='/superadmin/lead-list' || currentUrl=='/superadmin/lead-list-edit'
            || currentUrl=='/superadmin/lead-rejected-list' || currentUrl=='/superadmin/lead-rejected-list-edit ' || currentUrl=='/superadmin/lead-accepted-list' || currentUrl=='/superadmin/lead-accepted-edit' }">
            <i class="fas fa-user-tie"></i>
            <span>Leads Center</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/lead-list" routerLinkActive="link-active">All Lead</a>
              </li>
              <li>
                <a routerLink="/superadmin/lead-accepted-list" routerLinkActive="link-active">Accepted</a>
              </li>
              <li>
                <a routerLink="/superadmin/lead-rejected-list" routerLinkActive="link-active">Rejected</a>
              </li>
              <li>
                <a routerLink="/superadmin/lead-disqualified-list" routerLinkActive="link-active">Disqualified</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a routerLink="/superadmin/clients"
            [ngClass]="{'link-active':currentUrl=='/superadmin/clients-signed-loa' || currentUrl=='/superadmin/client-file-status'}"
            routerLinkActive="link-active">
            <i class="far fa-user-circle"></i>
            <span>Clients</span>
          </a>
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/task-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/task-list' || currentUrl=='/superadmin/task-details' || currentUrl=='/superadmin/task-template' || currentUrl=='/superadmin/task-template-detail'}">
            <i class=" fas fa-tasks"></i>
            <span>Tasks</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/task-list" routerLinkActive="link-active">Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-completed-list" routerLinkActive="link-active">Completed Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-template" routerLinkActive="link-active">Task template</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a routerLink="/superadmin/manage-auto-gen"
            [ngClass]="{'link-active':currentUrl=='/superadmin/manage-auto-edit'}" routerLinkActive="link-active">
            <i class="fas fa-folder-open"></i>
            <span>Manage Auto Gen...</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/manage-video" [ngClass]="{'link-active':currentUrl=='/superadmin/manage-video' || currentUrl=='/superadmin/manage-upload'}" routerLinkActive="link-active">
            <i class="fas fa-file-video"></i>
            <span>Manage University</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/manage-notification" routerLinkActive="link-active">
            <i class="fas fa-bell"></i>
            <span>Manage Notification</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/login-history" routerLinkActive="link-active">
            <i class="fas fa-clock"></i>
            <span>Login History</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/configuration-setting" routerLinkActive="link-active">
            <i class="fas fa-cogs"></i>
            <span>Configuration Setting</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/manager-profile" routerLinkActive="link-active">
            <i class="fas fa-user-cog"></i>
            <span>Manage profile</span>
          </a>
        </li>
        <!-- <li class="sidebar-dropdown"> -->
        <li>
          <a routerLink="/superadmin/report-short-sales"
            [ngClass]="{'link-active':currentUrl=='/superadmin/report-short-sales' || currentUrl=='/superadmin/closed-file-report' || currentUrl=='/superadmin/numberoffile' || currentUrl=='/superadmin/openclose-report' || currentUrl=='/superadmin/filesperfor-closure-report' || currentUrl=='/superadmin/user-usage-report' || currentUrl=='/superadmin/success-rate-report' || currentUrl=='/superadmin/cancel-file-report'}">
            <i class="fas fa-chart-bar"></i>
            <span>Reports</span>
          </a>

          <!-- <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/report-short-sales" routerLinkActive="link-active">All open files/short
                  sales

                </a>
              </li>
              <li>
                <a routerLink="/superadmin/closed-file-report" routerLinkActive="link-active">All closed files</a>
              </li>
              <li>
                <a routerLink="/superadmin/numberoffile" routerLinkActive="link-active">Number of file per
                  stage</a>
              </li>
              <li>
                <a routerLink="/superadmin/openclose-report" routerLinkActive="link-active">Open/closed files
                  per</a>
              </li>
              <li>
                <a routerLink="/superadmin/filesperfor-closure-report" routerLinkActive="link-active">Files per
                  foreclosure
                  date in the
                  next 30 days</a>
              </li>
              <li>
                <a routerLink="/superadmin/trend-report" routerLinkActive="link-active">Trend (price) report by
                  city/state</a>
              </li>
              <li>
                <a routerLink="/superadmin/user-usage-report" routerLinkActive="link-active">User usage report</a>
              </li>
              <li>
                <a routerLink="/superadmin/success-rate-report" routerLinkActive="link-active">Success rate report</a>
              </li>
              <li>
                <a routerLink="/superadmin/cancel-file-report" routerLinkActive="link-active">Report for cancel
                  file</a>
              </li>
            </ul>
          </div> -->
        </li>


      </ul>
    </div>

    <!--manager starts-->
    <div class="sidebar-menu" *ngIf="usertype == 'Manager'">
      <ul>
        <li>
          <a routerLink="/superadmin/dashboard" routerLinkActive="link-active">
            <i class="fa fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>


        <li>
          <a routerLink="/superadmin/manager-list" [ngClass]="{'active_sub':currentUrl=='/superadmin/manager-list' || currentUrl=='/superadmin/manager-list-edit'
               || currentUrl=='/superadmin/brokers-list' || currentUrl=='/superadmin/brokers-list-edit'
               || currentUrl=='/superadmin/agents-details' || currentUrl=='/superadmin/agents-details-edit'
               || currentUrl=='/superadmin/houseowner-detail-list' || currentUrl=='/superadmin/houseowner-detail-edit'
               || currentUrl=='/superadmin/negotiators-list' || currentUrl=='/superadmin/negotiators-edit'
               || currentUrl=='/superadmin/assitnegotiators-list' || currentUrl=='/superadmin/assitnegotiators-edit'
               || currentUrl=='/superadmin/closer-list' || currentUrl=='/superadmin/closer-edit'}">
            <i class="fas fa-users"></i>
            <span>Manage Users</span>
          </a>
          <!-- <a routerLink="/superadmin/brokers-list" [ngClass]="{'active_sub':currentUrl=='/superadmin/brokers-list' || currentUrl=='/superadmin/brokers-list-edit'
                     || currentUrl=='/superadmin/brokers-list' || currentUrl=='/superadmin/brokers-list-edit'
                     || currentUrl=='/superadmin/agents-details' || currentUrl=='/superadmin/agents-details-edit'
                   }">
            <i class="fas fa-users"></i>
            <span>Manage Users</span>
          </a> -->
          <!-- <div class="sidebar-submenu">
            <ul>

              <li>
                <a routerLink="/superadmin/brokers-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/brokers-list-edit'}"
                  routerLinkActive="link-active">Brokers</a>
              </li>
              <li>
                <a routerLink="/superadmin/agents-details"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/agents-details-edit'}"
                  routerLinkActive="link-active">Agents</a>
              </li>
                <li>
                <a routerLink="/superadmin/houseowner-detail-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/houseowner-detail-edit'}"
                  routerLinkActive="link-active">Homeowner</a>
              </li>


            </ul>
          </div> -->
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/lead-list"
            [ngClass]="{'active_sub':currentUrl=='/superadmin/lead-list' || currentUrl=='/superadmin/lead-list-edit'
                  || currentUrl=='/superadmin/lead-rejected-list' || currentUrl=='/superadmin/lead-rejected-list-edit'}">
            <i class="fas fa-user-tie"></i>
            <span>Leads Center</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/lead-list" routerLinkActive="link-active">Accepted

                </a>
              </li>
              <li>
                <a routerLink="/superadmin/lead-rejected-list" routerLinkActive="link-active">Rejected</a>
              </li>
              <li>
                <a routerLink="/superadmin/lead-disqualified-list" routerLinkActive="link-active">Disqualified</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a routerLink="/superadmin/clients"
            [ngClass]="{'link-active':currentUrl=='/superadmin/clients-signed-loa' || currentUrl=='/superadmin/client-file-status'}"
            routerLinkActive="link-active">
            <i class="far fa-user-circle"></i>
            <span>Clients</span>
          </a>
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/task-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/task-list' || currentUrl=='/superadmin/task-details' || currentUrl=='/superadmin/task-template' || currentUrl=='/superadmin/task-template-detail'}">
            <i class=" fas fa-tasks"></i>
            <span>Tasks</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/task-list" routerLinkActive="link-active">Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-completed-list" routerLinkActive="link-active">Completed Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-template" routerLinkActive="link-active">Task template</a>
              </li>
            </ul>
          </div>
        </li>



        <li>
          <a routerLink="/superadmin/manager-profile" routerLinkActive="link-active">
            <i class="fas fa-user-cog"></i>
            <span>Manage profile</span>
          </a>
        </li>
        <!-- <li class="sidebar-dropdown"> -->
        <li>

          <a routerLink="/superadmin/report-short-sales"
            [ngClass]="{'link-active':currentUrl=='/superadmin/report-short-sales' || currentUrl=='/superadmin/closed-file-report' || currentUrl=='/superadmin/numberoffile' || currentUrl=='/superadmin/openclose-report' || currentUrl=='/superadmin/filesperfor-closure-report' || currentUrl=='/superadmin/user-usage-report' || currentUrl=='/superadmin/success-rate-report' || currentUrl=='/superadmin/cancel-file-report'}">
            <i class="fas fa-chart-bar"></i>
            <span>Reports</span>
          </a>

          <!-- <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/report-short-sales" routerLinkActive="link-active">All open files/short
                  sales

                </a>
              </li>
              <li>
                <a routerLink="/superadmin/closed-file-report" routerLinkActive="link-active">All closed files</a>
              </li>
              <li>
                <a routerLink="/superadmin/numberoffile" routerLinkActive="link-active">Number of file per
                  stage</a>
              </li>
              <li>
                <a routerLink="/superadmin/openclose-report" routerLinkActive="link-active">Open/closed files
                  per</a>
              </li>
              <li>
                <a routerLink="/superadmin/filesperfor-closure-report" routerLinkActive="link-active">Files per
                  foreclosure
                  date in the
                  next 30 days</a>
              </li>
              <li>
                <a routerLink="/superadmin/trend-report" routerLinkActive="link-active">Trend (price) report by
                  city/state</a>
              </li>
              <li>
                <a routerLink="/superadmin/user-usage-report" routerLinkActive="link-active">User usage report</a>
              </li>
              <li>
                <a routerLink="/superadmin/success-rate-report" routerLinkActive="link-active">Success rate report</a>
              </li>
              <li>
                <a routerLink="/superadmin/cancel-file-report" routerLinkActive="link-active">Report for cancel
                  file</a>
              </li>
            </ul>
          </div> -->
        </li>


      </ul>
    </div>
    <!--manager ends-->

    <!--closure starts-->
    <div class="sidebar-menu" *ngIf="usertype == 'Closer'">
      <ul>
        <li>
          <a routerLink="/superadmin/dashboard" routerLinkActive="link-active">
            <i class="fa fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/clients"
            [ngClass]="{'link-active':currentUrl=='/superadmin/clients-signed-loa' || currentUrl=='/superadmin/client-file-status'}"
            routerLinkActive="link-active">
            <i class="far fa-user-circle"></i>
            <span>Open File</span>
          </a>
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/task-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/task-list' || currentUrl=='/superadmin/task-details' || currentUrl=='/superadmin/task-template' || currentUrl=='/superadmin/task-template-detail'}">
            <i class=" fas fa-tasks"></i>
            <span>Tasks</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/task-list" routerLinkActive="link-active">Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-completed-list" routerLinkActive="link-active">Completed Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-template" routerLinkActive="link-active">Task template</a>
              </li>
            </ul>
          </div>
        </li>


        <li>
          <a routerLink="/superadmin/manager-profile" routerLinkActive="link-active">
            <i class="fas fa-user-cog"></i>
            <span>Manage profile</span>
          </a>
        </li>



      </ul>
    </div>
    <!--closure ends-->



    <!--nego starts-->
    <div class="sidebar-menu" *ngIf="usertype == 'Negotiator'">
      <ul>
        <li>
          <a routerLink="/superadmin/dashboard" routerLinkActive="link-active">
            <i class="fa fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>

        <li>
          <a routerLink="/superadmin/clients"
            [ngClass]="{'link-active':currentUrl=='/superadmin/clients-signed-loa' || currentUrl=='/superadmin/client-file-status'}"
            routerLinkActive="link-active">
            <i class="far fa-user-circle"></i>
            <span>Open Files</span>
          </a>
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/task-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/task-list' || currentUrl=='/superadmin/task-details' || currentUrl=='/superadmin/task-template' || currentUrl=='/superadmin/task-template-detail'}">
            <i class=" fas fa-tasks"></i>
            <span>Tasks</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/task-list" routerLinkActive="link-active">Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-completed-list" routerLinkActive="link-active">Completed Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-template" routerLinkActive="link-active">Task template</a>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <a routerLink="/superadmin/manage-video" [ngClass]="{'link-active':currentUrl=='/superadmin/manage-video' || currentUrl=='/superadmin/manage-upload'}" routerLinkActive="link-active">
            <i class="fas fa-file-video"></i>
            <span>Manage University</span>
          </a>
        </li>

        <li>
          <a routerLink="/superadmin/manager-profile" routerLinkActive="link-active">
            <i class="fas fa-user-cog"></i>
            <span>Manage profile</span>
          </a>
        </li>
        <!-- <li class="sidebar-dropdown"> -->
        <li>

          <a routerLink="/superadmin/report-short-sales"
            [ngClass]="{'link-active':currentUrl=='/superadmin/report-short-sales' || currentUrl=='/superadmin/closed-file-report' || currentUrl=='/superadmin/numberoffile' || currentUrl=='/superadmin/openclose-report' || currentUrl=='/superadmin/filesperfor-closure-report' || currentUrl=='/superadmin/user-usage-report' || currentUrl=='/superadmin/success-rate-report' || currentUrl=='/superadmin/cancel-file-report'}">
            <i class="fas fa-chart-bar"></i>
            <span>Reports</span>
          </a>

          <!-- <div class="sidebar-submenu">
            <ul>

              <li>
                <a routerLink="/superadmin/closed-file-report" routerLinkActive="link-active">All closed files</a>
              </li>
              <li>
                <a routerLink="/superadmin/numberoffile" routerLinkActive="link-active">Number of file per
                  stage</a>
              </li>
              <li>
                <a routerLink="/superadmin/openclose-report" routerLinkActive="link-active">Open/closed files
                  per</a>
              </li>
              <li>
                <a routerLink="/superadmin/filesperfor-closure-report" routerLinkActive="link-active">Files per
                  foreclosure
                  date in the
                  next 30 days</a>
              </li>


              <li>
                <a routerLink="/superadmin/success-rate-report" routerLinkActive="link-active">Success rate report</a>
              </li>
              <li>
                <a routerLink="/superadmin/cancel-file-report" routerLinkActive="link-active">Report for cancel
                  file</a>
              </li>
            </ul>
          </div> -->
        </li>

      </ul>
    </div>
    <!--nego  ends-->
    <!--ass nego starts-->
    <div class="sidebar-menu" *ngIf="usertype == 'AsstNegotiator'">
      <ul>
        <li>
          <a routerLink="/superadmin/dashboard" routerLinkActive="link-active">
            <i class="fa fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>

        <li>
          <a routerLink="/superadmin/clients"
            [ngClass]="{'link-active':currentUrl=='/superadmin/clients-signed-loa' || currentUrl=='/superadmin/client-file-status'}"
            routerLinkActive="link-active">
            <i class="far fa-user-circle"></i>
            <span>Open Files</span>
          </a>
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/task-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/task-list' || currentUrl=='/superadmin/task-details' || currentUrl=='/superadmin/task-template' || currentUrl=='/superadmin/task-template-detail'}">
            <i class=" fas fa-tasks"></i>
            <span>Tasks</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/task-list" routerLinkActive="link-active">Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-completed-list" routerLinkActive="link-active">Completed Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-template" routerLinkActive="link-active">Task template</a>
              </li>
            </ul>
          </div>
        </li>



        <li>
          <a routerLink="/superadmin/manager-profile" routerLinkActive="link-active">
            <i class="fas fa-user-cog"></i>
            <span>Manage profile</span>
          </a>
        </li>


      </ul>
    </div>
    <!--ass nego  ends-->

    <!--HomeOwner Starts-->
    <div class="sidebar-menu" *ngIf="usertype == 'HomeOwner'">
      <ul>
        <li>
          <a routerLink="/superadmin/dashboard" routerLinkActive="link-active">
            <i class="fa fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <!-- <li>
          <a routerLink="/superadmin/clients"
            [ngClass]="{'link-active':currentUrl=='/superadmin/clients-signed-loa' || currentUrl=='/superadmin/client-file-status'}"
            routerLinkActive="link-active">
            <i class="far fa-user-circle"></i>
            <span>Open Escrows</span>
          </a>
        </li> -->


        <li>
          <a routerLink="/superadmin/manage-video" [ngClass]="{'link-active':currentUrl=='/superadmin/manage-video' || currentUrl=='/superadmin/manage-upload'}" routerLinkActive="link-active">
            <i class="fas fa-video"></i>
            <span>University</span>
          </a>
        </li>


        <li>
          <a routerLink="/superadmin/manager-profile" routerLinkActive="link-active">
            <i class="fas fa-user-cog"></i>
            <span>Manage profile</span>
          </a>
        </li>



      </ul>
    </div>
    <!--HomeOwner Ends-->

    <!--Agent-->
    <div class="sidebar-menu" *ngIf="usertype == 'Agent'">
      <ul>
        <li>
          <a [ngClass]="{'link-active':currentUrl=='/superadmin/lead-list-edit'}" routerLink="/superadmin/dashboard"
            routerLinkActive="link-active">
            <i class="fa fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/houseowner-detail-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/houseowner-detail-edit'}" routerLinkActive="link-active">
            <i class="fas fa-users"></i>
            <span>Manage Home Owner</span>
          </a>
        </li>

        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/lead-accepted-list"
            [ngClass]="{'active_sub':currentUrl=='/superadmin/lead-accepted-list'}">
            <i class="fas fa-user-tie"></i>
            <span>Leads Center</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/lead-accepted-list" routerLinkActive="link-active">Accepted
                </a>
              </li>
              <li>
                <a routerLink="/superadmin/lead-disqualified-list" routerLinkActive="link-active">Disqualified</a>
              </li>
              <!-- <li>
                <a routerLink="/superadmin/lead-rejected-list" routerLinkActive="link-active">Rejected</a>
              </li> -->
            </ul>
          </div>
        </li>
        <li>
          <a routerLink="/superadmin/clients"
            [ngClass]="{'link-active':currentUrl=='/superadmin/clients-signed-loa' || currentUrl=='/superadmin/client-file-status'}"
            routerLinkActive="link-active">
            <i class="far fa-user-circle"></i>
            <span>Short Sale Files</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/manage-video" [ngClass]="{'link-active':currentUrl=='/superadmin/manage-video' || currentUrl=='/superadmin/manage-upload'}" routerLinkActive="link-active">
            <i class="fas fa-video"></i>
            <span>University</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/manager-profile"
            [ngClass]="{'link-active':currentUrl=='/superadmin/manage-auto-edit'}" routerLinkActive="link-active">
            <i class="fas fa-user-cog"></i>
            <span>Manage Profile</span>
          </a>
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/task-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/task-list' || currentUrl=='/superadmin/task-details' || currentUrl=='/superadmin/task-template' || currentUrl=='/superadmin/task-template-detail'}">
            <i class=" fas fa-tasks"></i>
            <span>Tasks</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/task-list" routerLinkActive="link-active">Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-completed-list" routerLinkActive="link-active">Completed Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-template" routerLinkActive="link-active">Task template</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <!-- Broker -->
    <div class="sidebar-menu" *ngIf="usertype == 'Broker'">
      <ul>
        <li>
          <a [ngClass]="{'link-active':currentUrl=='/superadmin/lead-list-edit'}" routerLink="/superadmin/dashboard"
            routerLinkActive="link-active">
            <i class="fa fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <!-- <a routerLink="/superadmin/agents-details" [ngClass]="{'active_sub':currentUrl=='/superadmin/manager-list' || currentUrl=='/superadmin/manager-list-edit'
               || currentUrl=='/superadmin/brokers-list' || currentUrl=='/superadmin/brokers-list-edit'
               || currentUrl=='/superadmin/agents-details' || currentUrl=='/superadmin/agents-details-edit'
               || currentUrl=='/superadmin/houseowner-detail-list' || currentUrl=='/superadmin/houseowner-detail-edit'
               || currentUrl=='/superadmin/negotiators-list' || currentUrl=='/superadmin/negotiators-edit'
               || currentUrl=='/superadmin/assitnegotiators-list' || currentUrl=='/superadmin/assitnegotiators-edit'
               || currentUrl=='/superadmin/closer-list' || currentUrl=='/superadmin/closer-edit'}">
            <i class="fas fa-users"></i>
            <span>Manage Users</span>
          </a> -->
          <a routerLink="/superadmin/manager-list" [ngClass]="{'active_sub':currentUrl=='/superadmin/manager-list' || currentUrl=='/superadmin/manager-list-edit'
               || currentUrl=='/superadmin/brokers-list' || currentUrl=='/superadmin/brokers-list-edit'
               || currentUrl=='/superadmin/agents-details' || currentUrl=='/superadmin/agents-details-edit'
               || currentUrl=='/superadmin/houseowner-detail-list' || currentUrl=='/superadmin/houseowner-detail-edit'
               || currentUrl=='/superadmin/negotiators-list' || currentUrl=='/superadmin/negotiators-edit'
               || currentUrl=='/superadmin/assitnegotiators-list' || currentUrl=='/superadmin/assitnegotiators-edit'
               || currentUrl=='/superadmin/closer-list' || currentUrl=='/superadmin/closer-edit'}">
            <i class="fas fa-users"></i>
            <span>Manage Users</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/agents-details"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/agents-details-edit'}"
                  routerLinkActive="link-active">Agents</a>
              </li>
              <li>
                <a routerLink="/superadmin/houseowner-detail-list"
                  [ngClass]="{'link-active':currentUrl=='/superadmin/houseowner-detail-edit'}"
                  routerLinkActive="link-active">Homeowner</a>
              </li>

            </ul>
          </div>
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/lead-list"
            [ngClass]="{'active_sub':currentUrl=='/superadmin/lead-list' || currentUrl=='/superadmin/lead-list-edit'
            || currentUrl=='/superadmin/lead-rejected-list' || currentUrl=='/superadmin/lead-rejected-list-edit ' || currentUrl=='/superadmin/lead-accepted-list' || currentUrl=='/superadmin/lead-accepted-edit' }">
            <i class="fas fa-user-tie"></i>
            <span>Leads Center</span>
          </a>
          <div class="sidebar-submenu">
            <ul>

              <li>
                <a routerLink="/superadmin/lead-accepted-list" routerLinkActive="link-active">Accepted</a>
              </li>
              <li>
                <a routerLink="/superadmin/lead-rejected-list" routerLinkActive="link-active">Rejected</a>
              </li>
               <li>
                <a routerLink="/superadmin/lead-list" routerLinkActive="link-active">Assigned to Agent</a>
              </li>
              <li>
                <a routerLink="/superadmin/lead-disqualified-list" routerLinkActive="link-active">Disqualified</a>
              </li>
            </ul>
          </div>
        </li>
        <!-- <li>
          <a routerLink="/superadmin/houseowner-detail-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/houseowner-detail-edit'}" routerLinkActive="link-active">
            <i class="fas fa-users"></i>
            <span>Manage Home Owner</span>
          </a>
        </li>
        <li>
          <a [ngClass]="{'link-active':currentUrl=='/superadmin/lead-list'}" routerLinkActive="link-active"
            routerLink="/superadmin/lead-list">
            <i class="fas fa-user-tie"></i>
            <span>Leads</span>
          </a>
        </li> -->
        <li>
          <a routerLink="/superadmin/clients"
            [ngClass]="{'link-active':currentUrl=='/superadmin/clients-signed-loa' || currentUrl=='/superadmin/client-file-status'}"
            routerLinkActive="link-active">
            <i class="far fa-user-circle"></i>
            <span>Short Sale Files</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/manage-video" [ngClass]="{'link-active':currentUrl=='/superadmin/manage-video' || currentUrl=='/superadmin/manage-upload'}" routerLinkActive="link-active">
            <i class="fas fa-video"></i>
            <span>University</span>
          </a>
        </li>
        <li>
          <a routerLink="/superadmin/manager-profile"
            [ngClass]="{'link-active':currentUrl=='/superadmin/manage-auto-edit'}" routerLinkActive="link-active">
            <i class="fas fa-user-cog"></i>
            <span>Manage Profile</span>
          </a>
        </li>
        <li class="sidebar-dropdown">
          <a routerLink="/superadmin/task-list"
            [ngClass]="{'link-active':currentUrl=='/superadmin/task-list' || currentUrl=='/superadmin/task-details' || currentUrl=='/superadmin/task-template' || currentUrl=='/superadmin/task-template-detail'}">
            <i class=" fas fa-tasks"></i>
            <span>Tasks</span>
          </a>
          <div class="sidebar-submenu">
            <ul>
              <li>
                <a routerLink="/superadmin/task-list" routerLinkActive="link-active">Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-completed-list" routerLinkActive="link-active">Completed Tasks</a>
              </li>
              <li>
                <a routerLink="/superadmin/task-template" routerLinkActive="link-active">Task template</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a routerLink="/superadmin/broker-report-short-sales">
            <i class="fas fa-chart-bar"></i>
            <span>Reports</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- sidebar-menu  -->
  </div>
  <!-- sidebar-content  -->

</nav>
