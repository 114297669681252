import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
import { DropdownService } from '../../service/dropdown.service';

import { TokenService } from '../../service/token.service';

@Component({
  selector: 'app-agents-detail-edit',
  templateUrl: './agents-detail-edit.component.html',
  styleUrls: ['./agents-detail-edit.component.css']
})
export class AgentsDetailEditComponent implements OnInit {
  addAgent: any = {};
  getAgentlist: any = {};
  id: any = {};
  updateAgent: Boolean = false;
  agentTypes: any = [];
  companyName = ['Leanon Enterprises', 'Leanon Enterprises-1'];
  statusActive = ['Active', 'InActive'];
  stateList: any;
  cityList: any;
  agentListCompany: any = [];
  agentListManager: any = [];
  agentManagerIdList = false;
  companyIdList = false;
  myuserType: any;
  formTypeList: any;
  constructor(private toast: ToastService, private tokenStorage: TokenService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute, private _dropDownService: DropdownService) { }


  ngOnInit(): void {
    this.myuserType = this.tokenStorage._get_userRole();
    this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // let id = this._manager_service.getId();
    if (this.id !== null || undefined) {
      this.updateAgent = true;
      this._getAgent_byId(this.id);
    }


    this._manager_service._get_All_agentType().subscribe((options: any) => {
      // console.log(options.listData)
      this.agentTypes = options.listData;

    })



    this._getAll_states();
    this._getallAgentCompany();
    this._getallAgentManager();
  }


  _create_Agent() {
    console.log(this.addAgent)
    this.addAgent['agentCompanyId'] = 0;
    this.addAgent['companyName'] = "";
    this.addAgent['createdBy'] = "1";
    if (this.addAgent.agentTypeId == 1) {
      console.log(this.addAgent.managerId, 'select id')
      this.addAgent['agentManagerId'] = Number(this.addAgent.managerId);
    } else if (this.addAgent.agentTypeId == 2) {
      console.log(this.addAgent.companyId, 'select id')
      this.addAgent['agentCompanyId'] = Number(this.addAgent.companyId);
    }
    console.log(this.addAgent, 'formOnsubmit')

    if (this.updateAgent) {
      let boolValue = (/true/i).test(this.addAgent.active);
      this.addAgent['active'] = boolValue;
      this.addAgent['agentTypeId'] = parseInt(this.addAgent.agentTypeId);
      this._manager_service._update_agent(this.addAgent).subscribe((data: any) => {
        console.log("update", data)
        // alert(data.message)
        // message: "Invaild Domail Mail"
        // reponse: true
        if (data.reponse == true && data.message == "Invaild Domail Mail") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id");
        } else {
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/agents-details'])
        }

      })
    }
    else {
      let boolValue = (/true/i).test(this.addAgent.active);
      this.addAgent['active'] = boolValue;
      this.addAgent['agentTypeId'] = parseInt(this.addAgent.agentTypeId);
      this._manager_service._add_agent(this.addAgent).subscribe((data: any) => {
        console.log("add", data)
        if (data.reponse == true && data.message == "Invaild Domail Mail") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id");
        } else {
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/agents-details'])
        }
      })
    }
  }


  _getAll_states() {
    this._dropDownService._getAll_state(1).subscribe((statelist: any) => {

      console.log(statelist, "statelist");
      this.stateList = statelist.listData;

    })
  }
  _getallAgentCompany() {
    this._manager_service.getallAgentCompany().subscribe((resp: any) => {
      this.agentListCompany = resp.listData
      console.log(this.agentListCompany, 'AG_Company');
    })
  }
  _getallAgentManager() {
    this._manager_service.getallAgentManager().subscribe((resp: any) => {
      this.agentListManager = resp.listData
      console.log(this.agentListManager, 'AG_Manager');
    })
  }
  agentTypeList(data: any) {
    console.log(data.target.value, 'typeList');
    this.formTypeList = data.target.value;
  }

  dropdown_city(event: any) {
    console.log(event.target.value)
    // let id = event.target.value;
    this._dropDownService._getAll_city(event.target.value).subscribe((citylist: any) => {
      console.log(citylist, "cityList");
      this.cityList = citylist.listData;

    })
  }
  _getAgent_byId(id: number) {
    this._manager_service._get_agentByid(id).subscribe((data: any) => {
      console.log(data)
      this.addAgent = data.getData;
    })

  }


}
