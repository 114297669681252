import { Component, OnInit, ViewChild ,AfterViewInit} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray, NgForm } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { docFolder, docFiles, autoDocModel } from '../../models/auto_generation.model';
import { ToastService } from '../../service/toast.service';
import { DatePipe } from '@angular/common';
import {ComponentCanDeactivate} from 'src/app/can-deactivate.guard'

// import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { FileuploadService } from '../../service/fileupload.service';
import { get } from 'lodash';


@Component({
  selector: 'app-manage-auto-edit',
  templateUrl: './manage-auto-edit.component.html',
  styleUrls: ['./manage-auto-edit.component.css']
})
export class ManageAutoEditComponent implements OnInit,ComponentCanDeactivate,AfterViewInit {

  public files: any = [];
  id: any;
  @ViewChild(NgForm) manageForm:NgForm
  
  manageautogenForm: any = FormGroup;
  autodocModel = new autoDocModel();
  submitted = false;
  state: boolean = false;
  url: any = '';
  event: any = '';
  responseFileObj: any = [
    { "docmentUrl": '', "documentName": '', docStatus: false }
  ];
  uploadedFile:any = [];
  updateform: Boolean = false;
  titleId: any = 0;
  folderId:any = 0;
  constructor(private datePipe: DatePipe,    private toast: ToastService, private formBuilder: RxFormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute, private autogenfileupload: FileuploadService) { }
  ngAfterViewInit() {

  }
  ngOnInit(): void {
    this.activated_route.queryParams.subscribe((param: any) => {
      this.titleId = param['id'];
      let titleId = param['id'];
      this.folderId = param['folderId'];
      if (titleId != null || undefined) {
        this.updateform = true;
        this.state = true;
        this.getbyDetailsId(titleId, this.folderId);
      }

    });


    //**OBJECT */
    this.autodocModel.autogenerateddocfolder = new docFolder();

    //**ARRAY */
    let docfiles = new docFiles();
    this.autodocModel.autogenerateddocfiles = new Array<docFiles>();
    console.log("this.autodocModel.autogenerateddocfiles  ", this.autodocModel.autogenerateddocfiles);
    this.autodocModel.autogenerateddocfiles.push(docfiles);
    console.log("this.autodocModel.autogenerateddocfiles ", this.autodocModel.autogenerateddocfiles);
    this.manageautogenForm = this.formBuilder.formGroup(this.autodocModel);
    console.log("this.manageautogenForm  ", this.manageautogenForm);
    console.log(this.manageautogenForm.value);

  }
  addDoc() {
    console.log(this, "this")
    var self = this;
    const doc = self.docFile;
    console.log("doc ", doc);
    //  var arr = Array<docFiles>();
    let docfiles = new docFiles();
    //  console.log("docfiles ", docfiles);
    self.responseFileObj.push({ "docmentUrl": '', "documentName": '', docStatus: false })
    doc.push(self.formBuilder.formGroup(docfiles))
  }

  deleteFile(i: number) {
    var self = this;
    self.docFile.removeAt(i);
    self.responseFileObj.splice(i, 1);
  }

  get f() { return this.manageautogenForm.controls; }
  getbyDetailsId(tileid: any, folderId: any) {
    var reqObj:any = {};
    reqObj['titleId'] = parseInt(tileid);
    reqObj['folderId'] = parseInt(folderId);
    this._manager_service.get_autogendoc(reqObj).subscribe((getData: any) => {
      console.log(getData.listData, "getDataid")
      var data = getData.listData[0] ? getData.listData[0]: {};
      this.manageautogenForm.patchValue({ 'titleName': getData.listData[0].titleName });
      
      // (<FormArray>this.manageautogenForm.controls.autogenerateddocfiles).push(
      //   this.formBuilder.group({
          
      //   })
      // )
      this.manageautogenForm.controls.autogenerateddocfolder.patchValue({ 'folderName': getData.listData[0].folderName });
      data.autogenerateddocfiles.forEach((fileObj: any) => {
 console.log("fileObj ", fileObj);
        this.uploadedFile.push({ 
          "docmentUrl": fileObj.docmentUrl,
           "documentName": fileObj.documentName,
           "docStatus": true,
           docmentId: fileObj.docmentId
          })
      });

    })
  }

  get docFile() { return <FormArray>this.manageautogenForm.controls.autogenerateddocfiles; }

  onSubmit(): any {
    // this.uploadedFile.forEach((fileobj: any) => {
    //   if (fileobj.docStatus == true) {
    //     this.docFile.push(this.formBuilder.group({ "documentName": fileobj.documentName, "docmentUrl": fileobj.docmentUrl }))
    //   }
    // });

    // for (let i = 0; i < this.manageautogenForm.value.autogenerateddocfiles.length; i++) {
    //   if (this.manageautogenForm.value.autogenerateddocfiles[i].documentName == null || this.manageautogenForm.value.autogenerateddocfiles[i].docmentUrl == null) {
    //     this.manageautogenForm.value.autogenerateddocfiles.splice(i, 1);
    //   }
    // }
    console.log(this.manageautogenForm.value, "new form alue");
    if (this.uploadedFile.length == 0) {
      return false;
    }
    console.log("this.manageautogenForm.value ", this.manageautogenForm.value);
    this.manageautogenForm.value ["createdOn"] =this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm');

    // return false;
    if (this.state) {

      // alert("update")
      this.updateform = false;
      this.manageautogenForm.value['titleId'] = parseInt(this.titleId);
      this.manageautogenForm.value['autogenerateddocfiles'] = this.uploadedFile;
      this.manageautogenForm.value.autogenerateddocfolder['folderId'] = parseInt(this.folderId);
      console.log("manageautogenForm value",this.manageautogenForm);
      
      this._manager_service.update_autogendoc(this.manageautogenForm.value).subscribe((updateForm: any) => {
        this.manageautogenForm.markAsUntouched();
        if (updateForm.reponse == true) {
          this.toast.showSuccess(updateForm.message)
          this.route.navigate(['/superadmin/manage-auto-gen'])

        } else {
          this.toast.showError(updateForm.message)
        }
        console.log(updateForm, "updated")
      }, err => {
        this.toast.showError("Somthing Went Wrong")
      })
    }
    else {
      // alert("create")

      //this.submitted = true;
      this.manageautogenForm.value['autogenerateddocfiles'] = this.uploadedFile;
      
      this._manager_service.add_autogendoc(this.manageautogenForm.value).subscribe((data: any) => {
        // if (this.manageautogenForm.invalid) {
        //   return;
        // }
        
        if (data.reponse == true) {
          this.toast.showSuccess(data.message);
          this.manageautogenForm.markAsUntouched();

          this.route.navigate(['/superadmin/manage-auto-gen'])
        } else {
          this.manageautogenForm.markAsUntouched();
          this.toast.showError(data.message)
        }


      }, err => {
        this.manageautogenForm.markAsUntouched();
        this.toast.showError("Somthing Went Wrong")
      })
    }



  }
  // confirm() {
  //   return  !this.manageautogenForm.dirty;
  // }
  canDeactivate() {
    return !this.manageautogenForm.touched;
  }
  Reset() {
    this.submitted = false;
    this.manageautogenForm.reset();
    console.log(this.responseFileObj)
  }

  removeDocs(index: number) {
    // alert('Deleted Attached File');
    console.log(index, "docfile");
    // if (index == 0) {
    //   this.responseFileObj[index].docmentUrl = '';
    //   this.responseFileObj[index].documentName = '';
    //   this.responseFileObj[index].docStatus = false;
    // } else {
      this.uploadedFile.splice(index, 1);
    // }

  }

  onSelectFile(event: any, i: number, url: string) {
    var self = this;
    console.log("event ", event);
    console.log("i ", i);
 // console.log(event.target.files)
    self.autogenfileupload.multifile_upload_autoGen(event.target.files).subscribe((data: any) => {
      console.log('test', data)
      let result = Object.keys(data?.mutiplesFile).map(key => {
        // return data.mutiplesFile[key];
        console.log(data.mutiplesFile[key], "ji")
        console.log("self.responseFileObj[i] ", self.responseFileObj[i]);
        self.uploadedFile = self.uploadedFile.filter((item:any)=> {
          return item.docmentUrl != url;
        });
        self.responseFileObj[i].docmentUrl = data.mutiplesFile[key].DownloadPath;
        self.responseFileObj[i].documentName = data.mutiplesFile[key].FileName;
        self.responseFileObj[i].docStatus = true;
        self.responseFileObj[i].docmentId = 0;
        
        self.uploadedFile.push(self.responseFileObj[i]);
        // self.responseFileObj.push({ "docmentUrl": data.mutiplesFile[key].DownloadPath, "documentName": data.mutiplesFile[key].FileName })
      })
      console.log(self.responseFileObj, "jijijijiji")
    })

  }


}
