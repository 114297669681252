import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from 'src/app/service/dropdown.service';

import { FileuploadService } from 'src/app/service/fileupload.service';
@Component({
  selector: 'app-closer-list',
  templateUrl: './closer-list.component.html',
  styleUrls: ['./closer-list.component.css']
})
export class CloserListComponent implements OnInit {


  public closerslist: any = [];
  order: string = 'firstName';
  p: number = 1;
  reverse: boolean = false;
  deleteData: any;
  userListOptions: any;
  statusListOptions: any;
  filterRequest: any = {};
  constructor(private managerService: ManagerService, private dropDownService: DropdownService, private fileupload: FileuploadService) {

  }

  ngOnInit(): void {
    this._closers_records();
  }


  _closers_records() {
    this.managerService._get_All_Closers().subscribe((data: any) => {
      console.log(data)
      this.closerslist = data.listData;
    })
  }

  getFilterData() {
    console.log(this.filterRequest)
    this.filterRequest.status = parseInt(this.filterRequest.status);
    this.dropDownService._getAll_Closers_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.closerslist = filteredData.listData;
    });
  }

  //**Get Details for Filter */
  getfilter_details() {
    this.dropDownService._getAll_Closers_UserList().subscribe((userList: any) => {
      console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
    })
    this.dropDownService._getAll_Closers_StatusList().subscribe((statusList: any) => {
      console.log(statusList.listData, "sji")
      this.statusListOptions = statusList.listData;
    })
  }
  //**End */

  setId(data: any) {
    this.deleteData = data;
  }

  _delete_Closers() {
    this.managerService._delete_Closers(this.deleteData).subscribe(deleteData => {
      console.log(deleteData)
      this._closers_records()
    })
  }


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  downloadExcel() {
    this.fileupload.download_closer();
  }

}
