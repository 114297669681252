<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"> </i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Lead"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Add/ Edit Lead</h2>
          <button class="btn_to_btitle">
            <a routerLink="/superadmin/lead-list">back</a>
          </button>
        </div>
      </div>
      <div class="apex_tabs pd-0 activity-tab">
        <ul class="nav nav-tabs mg-0">

           <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#loa_details">Activity Log/Task</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " data-toggle="tab" href="#lead_details">Lead Detail</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#loa_details">Activity Log</a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#task">Task</a>
          </li> -->

        </ul>

        <ul class="activity-log-btn">
          <li><button class="btn_to_btitle" data-toggle="modal"
                    data-target="#contactapex"  (click)="opencontact()">Short sale support team</button></li>
          <li><button class="btn_to_btitle" (click)="notifyManager()">Open short sale</button></li>
        </ul>
      </div>
      <!-- Nav tabs -->
      <!-- Tab panes -->

      <!---->
      <div class="tab-content my-content-customtab pd-0">
        <div class="tab-pane fade" id="lead_details">
          <form [formGroup]="nestedFormGroup" #leadForm="ngForm" (ngSubmit)="nestedFormGroup.valid && addLead()"
            novalidate>
            <div class="pagecontent_wrapper">
              <div class="apex-white_wrap newheight-apx" [ngClass]="usertype == 'Agent' ? 'dash-edit-bg' : ''">
                <div class="apex-title-lilblue">
                  <h3>Lead details</h3>
                </div>
                <!-- cccccc -->
                <div [formGroup]="nestedFormGroup.controls.homeOwner">
                  <div class="apexforms-group lead-group">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="apexinput-froms">
                          <label>Owner First Name*</label>

                          <input type="text" placeholder="" formControlName="firstName" class="form-control">
                          <!-- <p>Form valid {{nestedFormGroup.valid}}</p>
                          <p>Form invalid {{nestedFormGroup.invalid}}</p>
                          <p>Form pending {{nestedFormGroup.pending}}</p>  -->
                          <p class="form-text text-danger"
                            *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.firstName.errors">
                            FirstName Required</p>
                          <!-- <div *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.firstName.invalid" class="invalid-feedback"> -->
                          <!-- <div  class="form-text text-danger"  *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.firstName.">First Name is required</div> -->
                          <!-- </div> -->

                        </div>



                      </div>
                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="apexinput-froms">
                          <label>Owner Last Name *</label>
                          <input type="text" placeholder="" name="lastName" formControlName="lastName">
                          <div class="form-text text-danger"
                            *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.lastName.errors">
                            LastName Required</div>
                        </div>
                      </div>
                      <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="apexinput-froms">
                          <label>Owner</label>
                          <input type="text" placeholder="">
                        </div>
                      </div> -->
                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="apexinput-froms">
                          <label>Property Address *</label>
                          <textarea class="hgt_reduce" name="address" [(ngModel)]="mailngaddress" placeholder=""
                            formControlName="address"></textarea>
                          <div class="form-text text-danger"
                            *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.address.errors">Address
                            Required</div>
                        </div>


                        <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-8">
                          <div class="apexinput-froms">
                            <label>list for sale address</label>
                            <select (input)="getpropertyid($event)">
                              <option value="">----Select Address---</option>

                              <option *ngFor="let addresslist of realtorPropertyAdrress" [value]=addresslist.price_raw>
                                {{addresslist.address}}</option>
                            </select>
                          </div>
                        </div> -->

                      </div>
                      <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="apexinput-froms">
                          <label>APN</label>
                          <input type="text"    name="apn" formControlName="apn">

                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div class="apexforms-group lead-group">
                    <div class="row">


                      <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="apexinput-froms">
                          <label>Owner country</label>
                          <select name="countryName" formControlName=countryId
                            (input)="select_dropdown($event,'state',true)">
                            <option value="">----Select County---</option>

                            <option *ngFor="let country of countrylist" [value]="country.countryId">
                              {{ country.countryName }}
                            </option>
                          </select>
                        </div>



                        <div class="apexinput-froms">
                          <label>Zip Code</label>
                          <input type="text" placeholder="" name="zipCode" formControlName="zipCode">
                        </div>
                      </div> -->




                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">


                        <div class="apexinput-froms">
                          <label>Property City *</label>
                          <!-- <select name="cityName" formControlName="cityId">
                            <option selected disabled>----Select City---</option>
                            <option *ngFor="let city of citylist" [value]="city.cityId">
                              {{ city.cityName }}
                            </option>
                          </select> -->
                          <input type="text" name="cityName" formControlName="cityName">
                          <div class="form-text text-danger"
                            *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.cityName.errors">City
                            Required</div>
                        </div>

                      </div>


                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="apexinput-froms">
                          <label>Property State *</label>
                          <select name="stateName" formControlName=stateName>
                            <option selected disabled>----Select State---</option>
                            <!-- <option>tn</option> -->
                            <option *ngFor="let state of statelist" [value]="state.stateName">
                              {{ state.stateName }}
                            </option>
                          </select>
                          <div class="form-text text-danger"
                            *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.stateName.errors">State
                            Required</div>
                        </div>


                      </div>


                      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div class="apexinput-froms">
                          <label>Property Zipcode *</label>
                          <input type="text" name="zipcode" formControlName="zipCode">
                          <div class="form-text text-danger"
                            *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.zipCode.errors">zipcode
                            Required</div>
                        </div>
                      </div>

                    </div>
                    <!-- ccc -->
                  </div>



                </div>


                <div class="apexforms-group lead-group">
                  <div class="row">

                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Phone Number 1</label>
                        <input type="text" prefix="" mask="(000) 000-0000" value="" name="phone1" formControlName="phone1">
                        <!-- <div class="form-text text-danger"
                          *ngIf="leadForm.submitted && nestedFormGroup.controls.homeOwner.controls.phone1.errors">phone
                          Required</div> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Phone Number 2</label>
                        <input type="text" prefix="" mask="(000) 000-0000" value="" name="phone2" formControlName="phone2">
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Phone Number 3</label>
                        <input type="text" prefix="" mask="(000) 000-0000" value="" name="phone3" formControlName="phone3">
                      </div>
                    </div>
                  </div>
                </div>


                <div class="apexforms-group lead-group">
                  <div class="row">

                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Phone Number 4</label>
                        <input type="text" prefix="" mask="(000) 000-0000" value="" name="phone4" formControlName="phone4">
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Email 1</label>
                        <input type="text" placeholder="" value="" name="email1" formControlName="email1">

                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Email 2</label>
                        <input type="text" placeholder="" value="" name="email2" formControlName="email2">
                      </div>
                    </div>

                  </div>
                </div>
                <div class="apexforms-group lead-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                      <div class="row lg-l">
                        <div class="apexinput-froms col-sm-6">
                          <label>Baths</label>
                          <!-- <select name="noofBaths" formControlName="noofBaths">
                            <option>--Select--</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                          </select> -->
                          <input type="text" prefix="" thousandSeparator="," mask="separator" formControlName="noofBaths">
                        </div>
                        <div class="apexinput-froms col-sm-6">
                          <label>Lot Size</label>
                          <input type="text" prefix="" thousandSeparator="," mask="separator" formControlName="lotSize">
                        </div>
                      </div>

                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Estimated Value </label>
                        <input type="text" prefix="$" thousandSeparator="," mask="separator" placeholder="" value=""
                          name="estimatedValue" formControlName="estimatedValue">
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Assessed Value</label>
                        <input type="text" prefix="$" thousandSeparator="," mask="separator" placeholder="" value=""
                          name="asseddedValue" formControlName="asseddedValue">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="apexforms-group lead-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">


                      <div class="apexinput-froms">
                        <label>Foreclosure Stage</label>
                        <select name="foreClosureStage" formControlName="foreClosureStage">
                          <option>--Select--</option>
                          <option> Pre fore closure</option>
                        </select>
                      </div>



                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Recording Date</label>
                        <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                        <dp-date-picker placeholder="" theme="dp-material" [config]="datePickerConfig" name="recordingDate"
                          formControlName="recordingDate">
                        </dp-date-picker>
                        <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.recordingDate.errors">RecordingDate Required</p> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Sale Date</label>
                        <!-- <input type="text" placeholder="27-Aug-2020" value="" name="" /> -->
                        <dp-date-picker placeholder="" theme="dp-material" [config]="datePickerConfig" name="salesDate"
                          formControlName="salesDate">
                        </dp-date-picker>
                        <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.salesDate.errors">Sale Required</p> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="apexforms-group lead-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Default As Of</label>
                        <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                        <dp-date-picker placeholder="" theme="dp-material" [config]="datePickerConfig" name="defaultAsOf"
                          formControlName="defaultAsOf">
                        </dp-date-picker>
                        <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.defaultAsOf.errors">defaultAsOf Required</p> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>FC Estimated Loan Position</label>
                        <input type="text" prefix="$" thousandSeparator="," mask="separator" value=""
                          name="fcEstimatedLoanPosition" formControlName="fcEstimatedLoanPosition">
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>FC Loan Date</label>
                        <dp-date-picker placeholder="" theme="dp-material" formControlName="fcLoanDate"
                          [config]="datePickerConfig" name="fcLoanDate">
                        </dp-date-picker>
                        <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.fcLoanDate.errors">FCLoanDate Required</p> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="apexforms-group lead-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>FC Loan Amount</label>
                        <input type="text" prefix="$" thousandSeparator="," mask="separator" value="" name="fcLoanAmount"
                          formControlName="fcLoanAmount">
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>First Loan Date</label>
                        <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                        <dp-date-picker placeholder="" theme="dp-material" formControlName="firstLoanDate"
                          [config]="datePickerConfig" name="firstLoanDate">
                        </dp-date-picker>
                        <!-- <p class="form-text text-danger" *ngIf="leadForm.submitted && nestedFormGroup.controls.firstLoanDate.errors">FirstLoanDate Required</p> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>First Loan Amount</label>
                        <input type="text" prefix="$" thousandSeparator="," mask="separator" placeholder="" value=""
                          name="firstLoanAmount" formControlName="firstLoanAmount">
                      </div>
                    </div>

                  </div>
                </div>
                <div class="apexforms-group lead-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>Second Loan Amount</label>
                        <input type="text" prefix="$" thousandSeparator="," mask="separator" placeholder="" value=""
                          name="secondLoanAmount" formControlName="secondLoanAmount">
                      </div>
                    </div>

                    <!-- <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>County</label>
                        <select name="countryName" formControlName=countryId (input)="select_dropdown($event,'state',true)">
                          <option selected disabled>----Select County---</option>
                          <option *ngFor="let country of countrylist" [value]="country.countryId">
                            {{ country.countryName }}
                          </option>
                        </select>
                      </div>
                    </div> -->



                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                      <div class="apexinput-froms">
                        <label>Notes</label>
                        <input type="text" name="notes" formControlName="notes">
                      </div>



                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">



                      <div class="apexinput-froms">
                        <label>Spouse</label>
                        <input type="text" name="spouse" formControlName="spouse">
                      </div>



                    </div>



                  </div>
                </div>


                <div class="apexforms-group lead-group">
                  <div class="row">

                     <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                      <div class="apexinput-froms">
                        <label>Mailing Address *</label>
                        <!-- <textarea placeholder="" name="address" formControlName="address">
                      </textarea>
                        <div class="form-text text-danger"
                          *ngIf="leadForm.submitted && nestedFormGroup.controls.address.errors">Address Required
                        </div> -->
                        <span class="label_sub">Is the Property address the same as Mailing Address?</span>
                        <div class="mail_add">
                          <div class="radio">
                            <label>
                              <input type="radio" name="gender" value="Yes" (click)="setradio('Yes')" ngModel>
                              Yes
                            </label>
                          </div>
                          <div class="radio">
                            <label>
                              <input type="radio" name="gender" value="No" (click)="setradio('No')" ngModel>
                              No
                            </label>
                          </div>
                        </div>

                        <div *ngIf="isSelected('Yes')">
                          <!-- <textarea name="address" class="hgt_reduce" [(ngModel)]="mailngaddress"
                            formControlName="address">  </textarea> -->


                        </div>


                        <!---->
                      </div>
                    </div>




                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                      <div class="apexinput-froms">
                        <label>Referred by</label>
                        <input type="text" name="referedBy" formControlName="referedBy">
                      </div>

                    </div>


                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="apexinput-froms">
                        <label>APN</label>
                        <input type="text" name="apn" formControlName="apn">

                      </div>
                    </div>


                     <!-- <div *ngIf="isSelected('No')"> -->
                          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"  *ngIf="isSelected('No')">
                            <div class="apexinput-froms">
                              <label>Mailing address</label>
                              <textarea name="address" class="hgt_reduce" formControlName="address"> </textarea>

                            </div>
                            <!-- <div class="apexinput-froms">
                              <label>Mailing City *</label>
                              <select name="cityId" formControlName="cityId">
                                <option [ngValue]="null" selected disabled>----Select City---</option>
                                <option *ngFor="let city of citylist" [value]="city.cityId">
                                  {{ city.cityName }}
                                </option>
                              </select>
                              <div class="form-text text-danger"
                                *ngIf="leadForm.submitted && nestedFormGroup.controls.stateId.errors">State
                                Required</div>
                            </div> -->
                            <!-- <div class="apexinput-froms">
                              <label>Zip Code</label>
                              <input type="text" placeholder="" value="" name="zipCode" formControlName="zipCode">
                            </div> -->
                          </div>
                        <!-- </div> -->



                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngIf="isSelected('No')">
                      <div class="apexinput-froms">
                        <label>City </label>
                        <!-- <select name="cityName" formControlName="cityId">
                          <option [ngValue]="null" selected disabled>----Select City---</option>
                          <option *ngFor="let city of citylist" [value]="city.cityId">
                            {{ city.cityName }}
                          </option>
                        </select> -->
                        <input type="text" name="cityName" formControlName="cityName">

                      </div>

                    </div>



                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngIf="isSelected('No')">

                      <div class="apexinput-froms">
                        <label>State </label>
                        <select name="stateName" formControlName=stateName>
                          <option selected disabled>----Select State---</option>
                          <option *ngFor="let state of statelist" [value]=state.stateName>
                            {{ state.stateName }}
                          </option>
                        </select>
                        <div class="form-text text-danger"
                          *ngIf="leadForm.submitted && nestedFormGroup.controls.stateName.errors">State Required</div>
                      </div>

                    </div>

                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" *ngIf="isSelected('No')">
                      <div class="apexinput-froms">
                        <label>Zip Code</label>
                        <input type="text" placeholder="" value="" name="zipCode" formControlName="zipCode">
                      </div>
                    </div>

                  <!--agent-->
                   <div class="apxbtn_finalcenter apx-mt-15" *ngIf="usertype == 'Agent'">
                      <button class="btn_grey" routerLink="/superadmin/lead-list">
                        Cancel
                      </button>
                      <button class="btn_dblue" type="submit">save</button>
                    </div>
                  <!--agent-->
                  <!--broker-->
                   <div class="apxbtn_finalcenter apx-mt-15" *ngIf="usertype == 'Broker'">
                      <button class="btn_grey" routerLink="/superadmin/lead-list">
                        Cancel
                      </button>
                      <button class="btn_dblue" type="submit">save</button>
                    </div>
                  <!--broker-->

                  </div>
                </div>


                <div *ngIf="usertype != 'Agent' && usertype != 'Broker'">
                  <div *ngIf="updateForm">
                    <div class="apex-title-lilblue">
                      <h3>Assign Lead</h3>
                    </div>
                    <div class="prev_assigned_detail">
                      <h4>Previous Assigned Detail</h4>
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="apexinput-froms">
                            <label>Assigned To</label>
                            <!-- <span>Manager</span> -->
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="apexinput-froms">
                            <label>User Name</label>
                            <!-- <span>Scarlet Johnson</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="apexforms-group lead-group prev-lead-group" [formGroup]="formGroup" *ngFor="
                      let formGroup of nestedFormGroup.controls.leadChildAssign
                        .controls;
                      let i = index
                    ">
                      <div class="row" *ngIf="i<1">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="apexinput-froms">
                            <label>Re-Assign To</label>
                            <select formControlName="userTypeId" (input)="select_dropdown($event, 'userType', true)">
                              <option selected disabled>
                                ----Select UserType---
                              </option>
                              <option *ngFor="let usertypelist of userTypes" [value]="usertypelist.userTypeId" (input)="
                                select_dropdown($event, 'userType', true)
                              ">
                                {{ usertypelist.userTypeName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="apexinput-froms">
                            <label *ngIf="usertype != 'Agent'">Manager Name</label>
                            <label *ngIf="usertype == 'Agent'">Agent Name</label>
                            <select formControlName="userId">
                              <option selected disabled>
                                ---Select User-----
                              </option>
                              <option *ngFor="let userlist of userLists" [value]="userlist.userId">
                                {{ userlist.userName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="apxbtn_finalcenter apx-mt-15" *ngIf="usertype != 'Agent'">

                      <button class="btn_grey" routerLink="/superadmin/lead-list">
                        Cancel
                      </button>
                      <button class="btn_dblue" type="submit">save</button>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="tab-pane active" id="loa_details">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
              <!-- <div class="tab-pane fade" id="loa_details"> -->
                <div>
              <div class="pagecontent_wrapper">
                <div class="apex-white_wrap newheight-apx">
                  <div class="apex-title-lilblue">
                    <h3>Activity Log</h3>
                  </div>
                  <form  (ngSubmit)="leadLOADetails.form.valid && onsubimtload()" #leadLOADetails="ngForm">
                    <div class="apexforms-group">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="apexinput-froms">
                            <select (change)="selectChangeHandler($event)">
                              <option value="1">Contacted</option>
                              <option value="2">Not Contacted</option>
                              <option value="3">Appointment Scheduled</option>
                              <option value="4">Appointment ReScheduled</option>
                              <option value="5">Appointment Completed</option>
                             
                              <!-- <option value="6">Letter of Authorization Completed</option> -->
                              <option value="7">Disqualified </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="loaDetailValue == '1'">
                      <div class="apexforms-group mb_0">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="label_headers">
                              <label>Contacted</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="apexforms-group">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label class="label_cmn">Way of Contact</label>
                            <div class="apexinput-froms checked_spanWidth">
                              <span class="checkbox">
                                <div class="form-group check-form">
                                  <input type="radio" [value]=0 id="email_loa0" name="wayofContact" #wayofContact="ngModel"
                                    [(ngModel)]="getAllLeadList.wayofContact" />
                                  <label for="email_loa0"></label>
                                  <small>Email</small>
                                </div>
                              </span>
                              <span class="checkbox">
                                <div class="form-group check-form">
                                  <input type="radio" [value]=1 id="phone_loa1" name="wayofContact" #wayofContact="ngModel"
                                    [(ngModel)]="getAllLeadList.wayofContact" />
                                  <label for="phone_loa1"></label>
                                  <small>Phone</small>
                                </div>
                              </span>
                              <span class="checkbox">
                                <div class="form-group check-form">
                                  <input type="radio" [value]="2" id="both_loa2" name="wayofContact" #wayofContact="ngModel"
                                    [(ngModel)]="getAllLeadList.wayofContact" />
                                  <label for="both_loa2"></label>
                                  <small>Both</small>
                                </div>
                              </span>


                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="apexforms-group">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="apexinput-froms block_lablels">
                              <label>Notes *</label>
                              <textarea name="contactedNotes" [ngClass]="{
                                'is-invalid':
                                leadLOADetails.submitted && contactedNotes.invalid
                              }" #contactedNotes="ngModel"
                                [(ngModel)]="getAllLeadList.contactedNotes" class="af_text mt_0" required></textarea>

                            </div>
                            <div *ngIf="leadLOADetails.submitted && contactedNotes.invalid">
                              <div *ngIf="contactedNotes.errors?.required" class="required">The field is required.</div>
                              </div>
    
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="loaDetailValue == '2'">
                      <div class="apexforms-group mb_0">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="label_headers">
                              <label>Not Contacted</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="apexforms-group">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="apexinput-froms block_lablels">
                              <label>Reason *</label>
                              <textarea  [ngClass]="{
                                'is-invalid':
                                leadLOADetails.submitted && notContactedNotes.invalid
                              }"
                          name="notContactedNotes" #notContactedNotes="ngModel"
                                [(ngModel)]="getAllLeadList.notContactedNotes" class="af_text mt_0" required></textarea>

                                <div *ngIf="leadLOADetails.submitted && notContactedNotes.invalid">
                                  <div *ngIf="notContactedNotes.errors?.required" class="required">Reason is required.</div>
                                  </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
             
               
                    <ng-container *ngIf="loaDetailValue == '3'">
                      <div class="appointed_scheduler appointment_compl">
                        <div class="apexforms-group mb_0">
                          <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="label_headers">
                        <label>Appointment Scheduled</label>
                        </div>
                        </div>
                          </div>
                          </div>
                        <div class="apexforms-group col-lg-12 mt_20">
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Date</label>
                                <dp-date-picker placeholder="Select Date" [(ngModel)]="getAllLeadList.appointDate"
                                  #appointDate="ngModel" theme="dp-material" [config]="datePickerConfig"
                                  name="recordingDate">
                                </dp-date-picker>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Time</label>
                                <input type="time" required name="appointTime" #appointTime="ngModel"
                                  [(ngModel)]="getAllLeadList.appointTime" id="" />

                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
                              <div class="apexinput-froms block_lablels">
                                <label>Notes *</label>
                                <textarea name="appointNotes" [ngClass]="{
                                  'is-invalid':
                                  leadLOADetails.submitted && appointNotes.invalid
                                }"  required #appointNotes="ngModel"
                                  [(ngModel)]="getAllLeadList.appointNotes" class="af_text mt_0"></textarea>
                                  <div *ngIf="leadLOADetails.submitted && appointNotes.invalid">
                                    <div *ngIf="appointNotes.errors?.required" class="required" >Notes is required.</div>
                                    </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="loaDetailValue == '4'">
                      <div class="appointed_scheduler">
                            <div class="apexforms-group mb_0">
                          <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="label_headers">
                        <label>Appointment ReScheduled By</label>
                        </div>
                        </div>
                        </div>
                            </div>
                        <div class="apexforms-group col-lg-12 mt_20">
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Date</label>
                                <dp-date-picker placeholder="Select Date" [(ngModel)]="getAllLeadList.resAppointDate"
                                  #resAppointDate="ngModel" theme="dp-material" [config]="datePickerConfig"
                                  name="resAppointDate">
                                </dp-date-picker>
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Time</label>
                                <input type="time" name="resAppointTime" #resAppointTime="ngModel"
                                  [(ngModel)]="getAllLeadList.resAppointTime" required />

                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
                              <div class="apexinput-froms block_lablels">
                                <label>Notes *</label>
                                <textarea [ngClass]="{
                                  'is-invalid':
                                  leadLOADetails.submitted && resAppointNotes.invalid
                                }"  name="resAppointNotes" #resAppointNotes="ngModel"
                                  [(ngModel)]="getAllLeadList.resAppointNotes" class="af_text mt_0" required></textarea>
                                  <div *ngIf="leadLOADetails?.submitted && resAppointNotes.invalid">
                                    <div *ngIf="resAppointNotes.errors?.required" class="required">
                                      Notes is required
                                    </div>
                                  </div>
                                 
                              </div>
                            </div>
                            <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
                              <div class="apexinput-froms apex_bg_white reschule_txt">
                                <label>Are you sure want to reschedule the appointment?</label>
                                <div class="radio_flex">
                                  <div class="radiobuttons">
                                    <div class="rdio rdio-primary radio-inline">
                                      <input name="radio" value="3" id="radio3" type="radio">
                                      <label for="radio3">Yes</label>
                                    </div>
                                  </div>
                                  <div class="radiobuttons">
                                    <div class="rdio rdio-primary radio-inline">
                                      <input name="radio" value="4" id="radio4" type="radio" checked>
                                      <label for="radio4">No</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="btn_reschedule">
                                  <button class="btn">Reschedule</button>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="loaDetailValue == '5'">
                      <div class="apexforms-group mb_0">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="label_headers">
                              <label>Appointment Completed</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="apexforms-group appoint_stat">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                            <div class="apexinput-froms block_lablels">
                              <label>Appointment Status</label>
                              <div class="radio_flex">
                                <div class="radiobuttons">
                                  <div class="rdio rdio-primary radio-inline">
                                    <input name="isSuccessFullCompleted" #isSuccessFullCompleted="ngModel" [(ngModel)]="
                                        getAllLeadList.isSuccessFullCompleted
                                      " [value]=1 id="radio10" type="radio" />
                                    <label for="radio10">Successful</label>
                                  </div>
                                </div>
                                <div class="radiobuttons">
                                  <div class="rdio rdio-primary radio-inline">
                                    <input name="isSuccessFullCompleted" #isSuccessFullCompleted="ngModel" [(ngModel)]="
                                        getAllLeadList.isSuccessFullCompleted
                                      " [value]=0 id="radio11" type="radio"  />
                                    <label for="radio11">Not Successful</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                            <div class="apexinput-froms block_lablels">
                              <label>Reason</label>
                              <input type="text" placeholder="Reason" class="wt_100" value="Approached Properly"
                                name="reason" #reason="ngModel" [(ngModel)]="getAllLeadList.reason" />
                            </div>
                          </div> -->
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
                            <div class="apexinput-froms block_lablels">
                              <label>Notes *</label>
                              <textarea [ngClass]="{
                                'is-invalid':
                                leadLOADetails.submitted && appointCompletedNotes.invalid
                              }" name="appointCompletedNotes"  #appointCompletedNotes="ngModel"
                                [(ngModel)]="getAllLeadList.appointCompletedNotes" class="af_text mt_0">
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</textarea>
    <div *ngIf="leadLOADetails?.submitted && appointCompletedNotes.invalid">
      <div *ngIf="appointCompletedNotes.errors?.required" class="required">
        Notes is required
      </div>
    </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="loaDetailValue == '7'">
                      <div class="apexforms-group mb_0">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="label_headers">
                              <label>Disqualified</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="apexforms-group">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="apexinput-froms block_lablels">
                              <label>Reason</label>
                              <!-- [ngClass]="{
                                'is-invalid':
                                leadLOADetails.submitted && DisqualifiedNotes.invalid
                              }" -->
                              <textarea  name="DisqualifiedNotes" #DisqualifiedNotes="ngModel"
                                [(ngModel)]="getAllLeadList.DisqualifiedNotes" class="af_text mt_0" ></textarea>
                                <!-- <div *ngIf="leadLOADetails?.submitted && DisqualifiedNotes.invalid" class="invalid-feedback">
                                  <div *ngIf="DisqualifiedNotes.errors?.required">
                                    Reason is required
                                  </div>
                                </div> -->
                               
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="loaDetailValue != '6'" class="apxbtn_finalcenter mt_20">
                      <button class="btn_grey" routerLink="/superadmin/lead-list">
                        Cancel
                      </button>
                      <button class="btn_dblue" type="submit">save</button>
                    </div>
              
                  </form>
                  <!-- mainLOAD -->
                  <ng-container *ngIf="loaDetailValue == '6'">
                    <div class="apexforms-group mb_10 mt_10">
                      <div class="row apex_m0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="label_headers">
                            <label>Completed</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="loa_wrappers">
                      <div class="loaradio_selector">
                        <div class="radio_flex">
                          <div class="radiobuttons">
                            <div class="rdio rdio-primary radio-inline">
                              <input name="isSuccessFullCompleted" (change)=loaoptionDocu($event) value="1" id="loa_send"
                                type="radio" checked />
                              <label for="loa_send">Send</label>
                            </div>
                          </div>
                          <div class="radiobuttons">
                            <div class="rdio rdio-primary radio-inline">
                              <input name="isSuccessFullCompleted" (change)=loaoptionDocu($event) value="0" id="upload_loa"
                                type="radio" />
                              <label for="upload_loa">Upload</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 upload_form_flex"
                      *ngIf="LOAoptionTrue == true">
                      <div class="row apex_m0 row_flex">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7">
                          <div class="apexinput-froms mangersigned_copy row_flex">
                            <label>Send Signature</label>
                            <div class="manageauto_upload">
                              <button (click)="loaSendSignatute()" class="btn_signature">
                                Send for Signature
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="LOAoptionTrue == false"
                      class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 upload_form_flex">
                      <div class="row apex_m0 row_flex">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7">
                          <div class="apexinput-froms mangersigned_copy row_flex">
                            <label>Upload Signed Copy</label>
                            <div class="manageauto_upload">
                              <input (change)="fileUploderSignature($event)" type="file" id="managerupload_file" />
                              <label for="managerupload_file">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M11.0418 19.1679H8.95842C8.38425 19.1679 7.91675 18.7004 7.91675 18.1262V14.5845H6.04175C5.79175 14.5845 5.56592 14.4354 5.46758 14.2062C5.36925 13.977 5.41675 13.7104 5.58842 13.5287L9.54675 9.36203C9.78258 9.1137 10.2176 9.1137 10.4534 9.36203L14.4118 13.5287C14.5834 13.7095 14.6318 13.9762 14.5334 14.2062C14.4351 14.4362 14.2084 14.5845 13.9584 14.5845H12.0834V18.1262C12.0834 18.7004 11.6159 19.1679 11.0418 19.1679Z"
                                    fill="#828282" />
                                  <path
                                    d="M15.5608 4.10648C14.4675 2.10732 12.3242 0.833984 10 0.833984C7.04583 0.833984 4.48333 2.88815 3.83583 5.69148C1.6825 5.91815 0 7.72648 0 9.91732C0 12.0798 1.64667 13.8506 3.76 14.114C3.7175 13.4865 3.93 12.8556 4.37917 12.3823L8.33833 8.21482C9.19083 7.31815 10.81 7.31815 11.6608 8.21482L15.62 12.3815C16.0383 12.8223 16.2508 13.399 16.245 13.9823C18.4058 13.3923 20 11.4348 20 9.11148C20 6.55648 18.0775 4.42398 15.5608 4.10648Z"
                                    fill="#828282" />
                                </svg>
                                upload file
                              </label>
                              <button (click)="sendLOAsignature()" class="btn_signature">
                                Send for Signature
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="loaDetailValue == '6'" class="apxbtn_finalcenter mt_20">

                      <button class="btn_grey" routerLink="/superadmin/lead-list">
                        Cancel
                      </button>
                      <button class="btn_dblue" (click)="sendLOAsigSave()" type="submit">save</button>

                    </div>
                  </ng-container>



                 <section class="recent_update">
                   <div *ngFor="let row of activeLogList">
                    <ng-container *ngIf="row.lastEventId == 1">
                      <div class="appointed_scheduler mt_20" *ngIf="
                      row.contactedNotes != null ||
                      row.wayofContact != null
                        ">
                        <div class="label_headers">
                        <label>Contacted</label>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="apexinput-froms block_lablels">
                              <label>Way of Contact</label>
                              <span class="higlight_span" *ngIf="row.wayofContact == 0">Email</span>
                              <span class="higlight_span" *ngIf="row.wayofContact == 1">Phone</span>
                              <span class="higlight_span" *ngIf="row.wayofContact == 2">Email and Phone</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="apexinput-froms block_lablels">
                              <label>Date & Time</label>
                              <span>{{ row.contactDateTime }}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <div class="apexinput-froms block_lablels">
                              <label>Updated by</label>
                              <span>{{ row.contactedBy }}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
                            <div class="apexinput-froms block_lablels">
                              <label>Notes</label>
                              <span>{{ row.contactedNotes }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="row.lastEventId == 2">
                      <div class="not_contacted mb_10">
                        <div class="apexforms-group" *ngIf="row.notContactedNotes != null">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="label_headers">
                                <label>Not Contacted</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="apexforms-group" *ngIf="row.notContactedNotes != null">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="apexinput-froms block_lablels">
                                <label>Reason</label>
                                <span>{{ row.notContactedNotes }}</span>
                              </div>
                
                              <div class="apexinput-froms block_lablels">
                                <label>Date & Time</label>
                                <span>{{ row.updatedOn }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="row.lastEventId == 3">
                      <div class="appointed_scheduler" *ngIf="
                          row.appointDate ||
                          row.appointTime ||
                          row.appointNotes 
                        ">
                            <div class="apexforms-group mb_0">
                          <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="label_headers">
                        <label>Appointment Scheduled</label>
                        </div>
                        </div>
                        </div>
                            </div>
                        <div class="assigned_detailsBg">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Date</label>
                                <span>{{ row.appointDate }}</span>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Time</label>
                                <span>{{ row.appointTime }}</span>
                              </div>
                            </div>
                
                          </div>
                        </div>
                        <div class="apexforms-group">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
                              <div class="apexinput-froms block_lablels">
                                <label>Notes</label>
                                <span>{{ row.appointNotes }}</span>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Time</label>
                                <span>{{ row.updatedOn }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="row.lastEventId == 4">
                      <div class="appointed_scheduler" *ngIf="
                          row.resAppointDate||
                          row.resAppointTime ||
                          row.resAppointNotes
                        ">
                            <div class="apexforms-group mb_0">
                          <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="label_headers">
                        <label>Appointment ReScheduled By</label>
                        </div>
                        </div>
                        </div>
                            </div>
                        <div class="assigned_detailsBg">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Date</label>
                                <span>{{ row.resAppointDate }}</span>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Time</label>
                                <span>{{ row.resAppointTime }}</span>
                              </div>
                            </div>
                
                          </div>
                        </div>
                        <div class="apexforms-group">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
                              <div class="apexinput-froms block_lablels">
                                <label>Notes</label>
                                <span>{{ row.resAppointNotes }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="row.lastEventId == 5">
                      <div class="appointment_compl">
                        <div class="apexforms-group mb_0" *ngIf="row.reason != null">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="label_headers">
                                <label>Appointment Completed</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="apexforms-group appoint_stat leadagent_load" *ngIf="row.reason != null">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                              <div class="apexinput-froms block_lablels">
                                <label>Appointment Status</label>
                                <span *ngIf="row.isSuccessFullCompleted == 0">Not Successful</span>
                                <span *ngIf="row.isSuccessFullCompleted == 1">Successful</span>
                              </div>
                            </div>
                            <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                              <div class="apexinput-froms block_lablels">
                                <label>Reason</label>
                                <span>{{ row.reason }}</span>
                              </div>
                            </div> -->
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt_10">
                              <div class="apexinput-froms block_lablels">
                                <label>Notes</label>
                                <span>{{ row.appointCompletedNotes }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="row.lastEventId == 7">
                      <div class="not_contacted mb_10">
                        <div class="apexforms-group" *ngIf="row.disQualifiedNotes != null">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="label_headers">
                                <label>Disqualified</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="apexforms-group" *ngIf="row.disQualifiedNotes != null">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div class="apexinput-froms block_lablels">
                                <label>Reason</label>
                                <span>{{ row.disQualifiedNotes }}</span>
                              </div>
                
                              <div class="apexinput-froms block_lablels">
                                <label>Date & Time</label>
                                <span>{{ row.updatedOn }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="activityListLength==0">
<h1>No log Found</h1>
                    </ng-container>
                   </div>
                  
                 </section>

               
                </div>
              </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
           
              <div>
              <div class="pagecontent_wrapper">
                <div class="apex-white_wrap newheight-apx">
                  <div class="apex-title-lilblue">
                    <h3>Create Task</h3>
                  </div>
                  <div class="apex_group_af">
                    <div class="apexforms-group">
                      <form name="form" (ngSubmit)="LeadTask.form.valid && onSubmitLeadTask()" #LeadTask="ngForm">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                            <div class="apexinput-froms block_lablels">
                              <label>Priority</label>
                              <select [ngClass]="{
                                  'is-invalid':
                                    LeadTask.submitted && priority.invalid
                                }" name="priority" [(ngModel)]="taskTabModel.priority" #priority="ngModel" required>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                              </select>
                              <!-- <input type="text"  /> -->
                              <div *ngIf="LeadTask.submitted && priority.invalid" class="invalid-feedback">
                                <div *ngIf="priority.errors?.required">
                                  Priority is required
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                            <div class="apexinput-froms block_lablels">
                              <label>Completion Date</label>
                              <dp-date-picker placeholder="Select Date" [ngClass]="{
                                  'is-invalid':
                                    LeadTask.submitted && completionDate.invalid
                                }" [(ngModel)]="taskTabModel.completionDate" #completionDate="ngModel" theme="dp-material"
                                [config]="datePickerConfig" name="recordingDate">
                              </dp-date-picker>
                              <div *ngIf="LeadTask.submitted && completionDate.invalid" class="invalid-feedback">
                                <div *ngIf="completionDate.errors?.required">
                                  Completion is required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                            <div class="apexinput-froms block_lablels">
                              <label>Status</label>
                              <select [ngClass]="{
                                  'is-invalid':
                                    LeadTask.submitted && taskStatus.invalid
                                }" name="taskStatus" [(ngModel)]="taskTabModel.taskStatus" #taskStatus="ngModel" required>
                                <!-- <option value="Yet to start">Yet To start</option> -->
                                <option value="In progress">In progress</option>
                                <option value="Completed">Completed</option>
                              </select>
                              <div *ngIf="LeadTask.submitted && taskStatus.invalid" class="invalid-feedback">
                                <div *ngIf="taskStatus.errors?.required">
                                  Status is required
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                            <div class="apexinput-froms block_lablels select_dis" *ngIf="usertype == 'Agent'">
                              <label>Assign To</label>
                              <select name="assignedUserId" (change)="onChangeTask($event)" required>
                                <option selected disable>Select Assign To</option>
                                <option value="0">Self</option>
                              </select>
                            </div>

                            <div class="apexinput-froms block_lablels" *ngIf="usertype == 'Broker'">
                              <label>Assign To(Self/Agent) </label>
                              <select name="assignedUserId" (change)="onChangeTask($event)" required>
                                <option selected disable>Select Assign To</option>
                                <option value="0">Self</option>
                                <option *ngFor="let item of getListagentBroker" [value]="item.agentId">
                                  {{ item.agentName }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                            <div class="apexinput-froms block_lablels">
                              <label>Task</label>
                              <textarea name="description" [ngClass]="{
                                  'is-invalid':
                                    LeadTask.submitted && description.invalid
                                }" [(ngModel)]="taskTabModel.description" #description="ngModel" required></textarea>
                              <div *ngIf="LeadTask.submitted && description.invalid" class="invalid-feedback">
                                <div *ngIf="description.errors?.required">
                                  Task is required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="apxbtn_finalcenter mt_20">
                          <button class="btn_grey" routerLink="/superadmin/lead-list">
                            Cancel
                          </button>
                          <button class="btn_dblue" type="submit">Save</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---->

    </div>
  </main>
</div>


<!-- Model task - autogen doc popup-->
<div class="modeltask_wrapper modal" id="contactapex" *ngIf="popupcontact == true">
    <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

    <!-- Modal body -->
    <div class="modelbody">
<div class="" >
      <div class="modelhead">
      <h2>CONTACT APEX RESOLUTION  </h2>
      <button type="button" class="close" data-dismiss="modal" aria-label="close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

      <div class="addres-bk">
        <div class="address-inner">
          <div class="add-icon-left"><svg width="32" height="32" viewBox="0 0 32 32" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.7286 0.984375C9.97876 0.984375 5.30078 5.66235 5.30078 11.4122C5.30078 18.6392 15.7389 30.9844 15.7389 30.9844C15.7389 30.9844 26.1565 18.2838 26.1565 11.4122C26.1565 5.66235 21.4787 0.984375 15.7286 0.984375ZM18.8749 14.4655C18.0074 15.3329 16.8681 15.7667 15.7286 15.7667C14.5894 15.7667 13.4497 15.3329 12.5825 14.4655C10.8476 12.7308 10.8476 9.90802 12.5825 8.17309C13.4226 7.33264 14.5401 6.86975 15.7286 6.86975C16.9172 6.86975 18.0345 7.33282 18.8749 8.17309C20.6099 9.90802 20.6099 12.7308 18.8749 14.4655Z"
                fill="#F15925" />
            </svg></div>
          <div class="add-det-right">
            <p>#44-46, Morningside Road,
              Edinburgh, Scotland, EH10 4BF</p>
          </div>
        </div>

        <div class="address-inner">
          <div class="add-icon-left"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
              viewBox="0 0 30 30" fill="none">
              <path
                d="M29.1837 22.017L24.9971 17.8304C23.5019 16.3352 20.9601 16.9333 20.362 18.877C19.9134 20.2228 18.4182 20.9704 17.0725 20.6713C14.0821 19.9237 10.045 16.0361 9.29742 12.8962C8.84886 11.5504 9.74598 10.0552 11.0917 9.60673C13.0354 9.00864 13.6335 6.46679 12.1383 4.97158L7.95173 0.784986C6.75556 -0.261662 4.96131 -0.261662 3.91466 0.784986L1.07376 3.62589C-1.76714 6.61631 1.3728 14.5409 8.40029 21.5684C15.4278 28.5959 23.3524 31.8854 26.3428 28.8949L29.1837 26.054C30.2304 24.8579 30.2304 23.0636 29.1837 22.017Z"
                fill="#F15925" />
            </svg></div>
          <div class="add-det-right">
            <a href="tel:'+861 9856 6528'">(861) 9856 6528 </a>
            <a href="tel:'+965 3312 9633'">(965) 3312 9633</a>
          </div>
        </div>

        <div class="address-inner">
          <div class="add-icon-left">
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.87941 7.47947C3.2589 7.74726 4.40284 8.54261 6.31129 9.8651C8.21979 11.1876 9.68184 12.2059 10.6975 12.92C10.8091 12.9983 11.0461 13.1684 11.4088 13.4307C11.7715 13.6932 12.0729 13.9053 12.3127 14.0671C12.5527 14.2288 12.8429 14.4102 13.1834 14.611C13.5239 14.8117 13.8448 14.9626 14.1461 15.0625C14.4475 15.1633 14.7265 15.2132 14.9832 15.2132H15H15.0169C15.2735 15.2132 15.5526 15.1632 15.854 15.0625C16.1553 14.9626 16.4765 14.8115 16.8166 14.611C17.1569 14.41 17.4471 14.2287 17.6871 14.0671C17.9271 13.9053 18.2282 13.6932 18.5911 13.4307C18.9537 13.1682 19.191 12.9983 19.3026 12.92C20.3292 12.2059 22.9411 10.3922 27.1374 7.47912C27.9521 6.91017 28.6327 6.22365 29.1795 5.42003C29.7267 4.61677 30 3.7741 30 2.8925C30 2.15579 29.7348 1.52516 29.2046 1.00065C28.6745 0.476031 28.0466 0.213867 27.3214 0.213867H2.67846C1.81914 0.213867 1.15784 0.504001 0.694668 1.08427C0.231556 1.66465 0 2.39011 0 3.26057C0 3.96368 0.307021 4.7256 0.92077 5.54587C1.53446 6.36619 2.18755 7.01079 2.87941 7.47947Z"
                fill="#F15925" />
              <path
                d="M28.3257 9.27087C24.6653 11.7483 21.886 13.6737 19.9889 15.0466C19.3528 15.5152 18.8367 15.881 18.4404 16.1431C18.044 16.4054 17.517 16.6732 16.8584 16.9465C16.2 17.2202 15.5864 17.3567 15.0171 17.3567H15H14.9832C14.414 17.3567 13.8001 17.2202 13.1417 16.9465C12.4834 16.6732 11.9559 16.4054 11.5597 16.1431C11.1636 15.881 10.6474 15.5152 10.0113 15.0466C8.50452 13.9418 5.73113 12.0163 1.69102 9.27087C1.0547 8.84704 0.491081 8.36124 0 7.81445V21.1065C0 21.8435 0.262164 22.4738 0.786785 22.9984C1.31129 23.5231 1.94198 23.7854 2.67863 23.7854H27.3216C28.058 23.7854 28.6887 23.5231 29.2132 22.9984C29.738 22.4737 30 21.8436 30 21.1065V7.81445C29.52 8.34992 28.9622 8.83573 28.3257 9.27087Z"
                fill="#F15925" />
            </svg></div>
          <div class="add-det-right">
            <a href="mailto:support@apexresolution.com">support@apexresolution.com
            </a>
            <a href="contact@apexresolution.com"> contact@apexresolution.com</a>
          </div>
        </div>

      </div>
    </div>
     <div class="model_footer">
      <!-- <button class="btn_grey"  data-dismiss="modal" type="reset" >Cancel</button> -->

    </div>

    </div>

        <!-- Modal body -->

</div>
</div>
</div>
<!-- Model task -->

