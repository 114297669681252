<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Clients"></app-header>

    <form [formGroup]="addTaskgroup" (ngSubmit)="assign_task()">
      <div class="container-fluid contentpage_wrapper">
        <div class="apexpage-titletop">
          <div class="apxpage-title_wrap">
            <h2>Create/Edit Task</h2>
            <button class="btn_to_btitle" [routerLink]="['/superadmin/client-file-status']">back</button>
          </div>
        </div>
        <div class="pagecontent_wrapper padding_15">
          <div class="apex-white_wrap">
            <div class="apex-title-lilblue">
              <h3>Assign task Detail</h3>
            </div>
            <div class="apex_group_af">
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div class="apexinput-froms block_lablels">
                      <label>Select File</label>
                      <select>
                        <option selected disabled>Select File</option>
                        <option *ngFor="let item of clientAddressList" [value]="item.fileId">{{item.fileslist}}
                        </option>
                      </select>
                    </div>

                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                    <div class="apexinput-froms block_lablels">
                      <label>Select Specific File Tab</label>
                      <select>
                        <option selected disabled>Select Specific File Tab</option>
                        <option *ngFor="let filename of filetabList" [value]="filename.fileId">{{filename.filename}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div class="apexinput-froms block_lablels">
                      <label>Assign To</label>
                      <select name="title" id="title" formControlName="assignedTabs">
                        <option selected disabled>Assign To</option>
                        <option *ngFor="let item of assignedUserTypeList" [value]="item.fileid">{{item.value}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                    <div class="apexinput-froms block_lablels">
                      <label>Negotiator Name</label>
                      <select name="Negotiator" formControlName="assignedUserId">
                        <option selected disabled>Negotiator Name</option>
                        <option *ngFor="let item of assignedUserList" [value]="item.fileid">{{item.value}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="apexforms-wraps grped_label">
                <div class="apexforms-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                      <div class="apexinput-froms block_lablels">
                        <label>Select Priority </label>
                        <select name="title" id="title" formControlName="priority">
                          <option value="High">High</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>
                        </select>
                      </div>
                      <div class="apexinput-froms block_lablels mt_30">
                        <label>Completion Date </label>
                        <dp-date-picker placeholder="Completion Date" theme="dp-material" [config]="datePickerConfig"
                          required="true" formControlName="completionDate">
                        </dp-date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                      <div class="apexinput-froms block_lablels">
                        <label>Task</label>
                        <textarea class="apex-textarea"
                          form>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="apxbtn_finalcenter mt_20">
              <button class="btn_grey" type="reset">Cancel</button>
              <button class="btn_dblue" type="submit">Assign</button>
            </div>


          </div>
        </div>
      </div>
    </form>





  </main>





</div>


<!-- The Modal -->
<div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel" data-dismiss="modal">Cancel</button>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="filterforms_setting label_fixed_width">
          <div class="apexforms-group">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="apexinput-froms">
                  <label>Assigned Negotiators</label>
                  <input type="text" name="" id="" class="bg_blur" value="Matthew McConaughey">
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="apexinput-froms">
                  <label>Completion Date</label>
                  <dp-date-picker placeholder="25-Aug-2020" class="bg_blur" theme="dp-material"
                    [config]="datePickerConfig" required="true">
                  </dp-date-picker>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="apexinput-froms lbl_txtarea">
                  <label>Notes</label>
                  <textarea
                    class="apex-textarea bg_blur">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</textarea>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="apexinput-froms">
                  <label>Status</label>
                  <select name="title" id="title">
                    <option value="manager">Pending</option>
                    <option value="Title-1">Medium-1</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!--modal end-->
<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <div class="assign_lead">
            <img src="../../../assets/images/clients/lead.png" alt="danger" /></div>
          <p>Are you sure want to assign negotiator, assistant negotiator & closer</p>
          <div class="filterbt_btnn btn_deletecard">
            <button class="btnfilter-cancel">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
