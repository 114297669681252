import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private actionUrl: any;
  private id: any;
  leadObj: any;
  userInfo: any;
  userInfoJson:any;
  currentUserTypeId: string | null;

  constructor(private http: HttpClient) {
    this.actionUrl = environment.apiUrl;
    this.currentUserTypeId = sessionStorage.getItem('userTypeId');
     this.userInfo = sessionStorage.getItem('userInfo');
     this.userInfoJson =JSON.parse(this.userInfo);
   
  }

  sendUpdatenotify(notification: any) {
    // debugger;
    return this.http.post(this.actionUrl + '/UserNotify/SendorupdateNotify', notification);
  }
  getAllNotify() {
    return this.http.post(this.actionUrl + '/UserNotify/GetAllNotifyByUser', {});
  }
  updateReadSinglenotify(readNotificationObj: any) {
    return this.http.post(this.actionUrl + '/UserNotify/UpdateReadSinglenotify', readNotificationObj);
  }
  updateReadnotify(readBulkNotificationObj: any) {
    return this.http.post(this.actionUrl + '/UserNotify/UpdateReadnotify', readBulkNotificationObj)
  }

  getImpMsg() {
    return this.http.post(this.actionUrl + '/UserNotify/GetAllImportantNotifyByUser?CurrentUserId=' + this.currentUserTypeId, {})
  }

  senderAutoNotify() {
    return this.http.post(this.actionUrl + '/UserNotify/AutoNotification?CurrentUserId=' + this.currentUserTypeId, {})
  }
  receiverAutoNotify() {
    return this.http.post(this.actionUrl + '/UserNotify/AutoNotificationAssignedBy?CurrentUserId=' + this.currentUserTypeId, {})
  }

  lienholderCount(clientid: any) {
    // https://apexresolution.colanonline.net/api/V1
    return this.http.post(this.actionUrl + '/ClientFileStatus/GetLienHolderCount?ClientId=' + clientid, {});
  }

  get_notificationList(id: number) {
    return this.http.post(this.actionUrl + '/UserNotify/GetNotificationDetails?CurrentUserId=' + id, {});
  }
  update_notify(data: any) {
    return this.http.post(this.actionUrl + '/UserNotify/UpdateNotification', data);
  }

  delete_notify(data: any) {
    return this.http.post(this.actionUrl + '/UserNotify/DeleteNotification', data);
  }
  foreClosureAlert(){
    // https://apexresolution.colanonline.net/api/V1/Lead/SendNotificationForClosureDate?CurrentUserid=1
    return this.http.post(this.actionUrl + '/Lead/SendNotificationForClosureDate?CurrentUserid='+this.userInfoJson.CurrentLoggedUserId,{});



  }

// **send notification for mapped manager in broker and agent lead edit **//
  sendNotifyForManager(notifyData:any){
 return this.http.post(this.actionUrl+'/UserNotify/OpenShortSaleNotification',notifyData)
  }

_viewUser(id:any):Observable<any>{
return this.http.post(this.actionUrl+"/UserNotify/GetNotificationDetailsById?NotificationById="+id,{})
}

  // allUpdateReadingle(){
  //   return this.HTMLOutputElement.post(this.actionUrl + '/UserNotify/UpdateReadnotify',);
  // }
}
