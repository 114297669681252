import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';

import { TokenService } from '../../service/token.service';
import { DropdownService } from 'src/app/service/dropdown.service';
import { FileuploadService } from 'src/app/service/fileupload.service';
@Component({
  selector: 'app-houseowner-detail',
  templateUrl: './houseowner-detail.component.html',
  styleUrls: ['./houseowner-detail.component.css']
})
export class HouseownerDetailComponent implements OnInit {
  homeownerList: any = [];
  DbhomeownerList: any = [];
  order: string = 'managersFirstname';
  p: number = 1;
  reverse: boolean = false;
  usertype: any;
  deleteData: any;

  userListOptions: any;
  getHomeownerListBack: any
  getHomeownerList: any
  userListOptionsBack: any;
  statusListOptions: any;
  filterRequest: any = {};
  constructor(private tokenStorage: TokenService, private managerService: ManagerService, private dropDownService: DropdownService, private fileupload: FileuploadService) {


  }

  ngOnInit(): void {
    this.usertype = this.tokenStorage._get_userRole();
    this._getHomeOwner_records();

    this.getfilter_details();
    this._getHomeOwner_records();
    this.getallHomeOwner();
    // this.usertype = this.tokenStorage._get_userRole();
  }

  _getHomeOwner_records() {
    this.managerService._get_All_HomeOwners().subscribe((data: any) => {
      console.log(data)
      this.homeownerList = data.listData;
      this.DbhomeownerList = data.listData;
      console.log(this.homeownerList)
    })
  }

  setId(data: any) {
    this.deleteData = data;
  }
  _delete_houseOwner() {

    this.managerService._delete_HomeOwners(this.deleteData).subscribe(deleteData => {
      console.log(deleteData)
      this._getHomeOwner_records();
    })
  }

  getFilterData() {
    console.log(this.filterRequest)
    this.filterRequest.status = parseInt(this.filterRequest.status);
    this.dropDownService._getAll_homeowner_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.homeownerList = filteredData.listData;
      this.DbhomeownerList = filteredData.listData;
    });
  }

  //**Get Details for Filter */
  getfilter_details() {
    this.dropDownService._getAll_homeowner_UserList().subscribe((userList: any) => {
      console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
      this.userListOptionsBack = userList.listData;
    })
    this.dropDownService._getAll_homeowner_StatusList().subscribe((statusList: any) => {
      console.log(statusList.listData, "sji")
      this.statusListOptions = statusList.listData;
    })
  }
  //**End */
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }
  getallHomeOwner() {
    this.managerService.getHomeOwnersGetall().subscribe((resp: any) => {
      console.log(resp.listData, 'Filter_Homeownerlist');
      this.getHomeownerList = resp.listData
      this.getHomeownerListBack = resp.listData
    })
  }

  downloadExcel() {
    this.fileupload.download_homeowner();
  }

  onSearchuserName(e: any) {
    this.userListOptions = this.userListOptionsBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearchuserhome(e: any) {
    this.getHomeownerList = this.getHomeownerListBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.homeownerList = self.DbhomeownerList.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

}
