import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
@Component({
  selector: 'app-file-data-report',
  templateUrl: './file-data-report.component.html',
  styleUrls: ['./file-data-report.component.css']
})
export class FileDataReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;
  public reportName: any = 'file-data-report';
  public sucessReportObj = {
    "userId": 0,
    "openClose": 0,
    "fromDate": '',
    "todate": '',
    "createdBy": this.tokenStorage._get_userTypeId()
  }
  public UserData: any = [];
  public filesData: any = [];
  public successobj: any = {}
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true
  };
  sucessReport = '';
  userId = '0';
  FromDateData = '';
  ToDateData = '';
  openClose = '0';
  resFileData: any[];
  searchFileData: any[];
  


  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit() {
     this.filedataAllReport();
    this.filedataDropdown();

  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }
  filedataDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentData")
    this._manager_service.successRate_dropdown(CurrentUserId).subscribe((res: any) => {
      this.UserData = res;
      console.log(res, "userdata")
    })
  }

  filedataAllReport() {
   this._manager_service.fileDataAllReport(this.sucessReportObj).subscribe((res: any) => {
      this.filesData=  (res.listData.length!=[])?res.listData:[];
      
      console.log(this.filesData, "sucessRate");
    this.resFileData=[
      {status:"Documents Colleted from Agent	",count:this.filesData[0]?.documentColletedFromAgent},
      {status:"Documents Colleted from Homeowner	",count:this.filesData[0]?.documentColletedFromHomeowner},
      {status:"Listed for sale	",count:this.filesData[0]?.listedForSale},
      {status:"Offer Accepted	",count:this.filesData[0]?.offerAccepted},
      {status:"Package Submitted to Bank	",count:this.filesData[0]?.packageSubmittedtoBank},
      {status:"File Submitted to Setup Department	",count:this.filesData[0]?.documentColletedFromAgent},
      {status:"BPO Ordered	",count:this.filesData[0]?.bpoOrdererd},
      {status:"BPO Completed	",count:this.filesData[0]?.bpocompleted},
      {status:"Bank Negotiator Assigned	",count:this.filesData[0]?.bankNegotiatorAssigned },
     
      {status:"Bank Rejected Offer	",count:this.filesData[0]?.bankRejectedOffer},
      {status:"Submitted with Approval	",count:this.filesData[0]?.submittedwithapproval},
      {status:"Offer Denied	",count:this.filesData[0]?.offerDenied},
      {status:"Shortsale Approved	",count:this.filesData[0]?.shortsaleApproved},
      {status:"InEcrow	",count:this.filesData[0]?.inEscrow},
      {status:"Closed	",count:this.filesData[0]?.closed},
    ];
    
this.searchFileData= this.resFileData
    })
  }

  successFilter() {
    this.sucessReportObj.userId = parseInt(this.userId);
    this.sucessReportObj.fromDate = this.FromDateData;
    this.sucessReportObj.todate = this.ToDateData;
    this.sucessReportObj.openClose = parseInt(this.openClose);
    console.log(this.sucessReportObj, "list");
    this.filedataAllReport();
  }

  successReset() {
    this.sucessReportObj.userId = 0;
    this.sucessReportObj.openClose = 0;
    this.sucessReportObj.fromDate = '';
    this.sucessReportObj.todate = '';
    this.userId = '0';
    this.openClose = '0';
    this.FromDateData = '';
    this.ToDateData = '';
    console.log(this.sucessReportObj, "list");
    this.filedataAllReport();
  }

  downloadExcel() {
    this._file_upload.filedata_excel();
  }

  onselectReport(assignVal: any) {
    // debugger
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.resFileData = self.searchFileData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
 
      }
      else {
        // self.successRateData=this.successRateData
        return false;
      }
    }));
  }
}
