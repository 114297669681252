import { Component, OnInit, ViewChild } from '@angular/core';
import { FileuploadService } from 'src/app/service/fileupload.service';
// import { toBase64String } from '@angular/compiler/src/output/source_map';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
import { DatePipe } from '@angular/common';
import { CommonDataService } from 'src/app/service/common-data.service';
import { ComponentCanDeactivate } from 'src/app/can-deactivate.guard';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-manage-upload',
  templateUrl: './manage-upload.component.html',
  styleUrls: ['./manage-upload.component.css']
})
export class ManageUploadComponent implements OnInit,ComponentCanDeactivate  {
  @ViewChild(NgForm) managefrm: NgForm;
  managevideos: any = {
    extension:null
  };
  uploadVideoSrc: any = '';
  isSuccessFullCompleted: any;
  videoContentList: any;
  fileName: any;
  updateForm: Boolean = false;
  getVideoUpload: boolean = true;

  fileUrl: any;
  constructor(private datePipe: DatePipe,

    private toastr: ToastService,
     private fileuploadservice: FileuploadService, private activeroute: ActivatedRoute, private route: Router) { }
 

  ngOnInit(): void {

    this.activeroute.queryParams.subscribe((params: any) => {
      let id;

      if (params['id'] != null || params['id'] != undefined) {
        this.updateForm = true;
        this.getVideoContentById(params['id'])
      }
    })
  }
  ngOnDestroy() {
   
     }
 



  // Add Video
  uploadFormVideo(videofrm: any) {
    console.log("this.managevideos.extension",this.managevideos.extension)
if(this.managevideos.extension==null){
this.toastr.showError("Please choose vedio url or upload file")
}
else{
  this.managevideos["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')
  if (!this.updateForm) {
    this.fileuploadservice.addVideoUploadDetails(this.managevideos).subscribe((addVideo: any) => {
      console.log(addVideo.reponse, "add video content");
      if (addVideo.reponse) {
        this.managefrm.form.markAsUntouched();
        this.updateForm = false;
        //alert('upload')
        this.route.navigate(['/superadmin/manage-video'],{queryParams: {type: this.managevideos.extension == 'video'? 'videos': this.managevideos.extension}})
        // alert("Video Content created");
        this.toastr.showSuccess("Data Created")
      }

    }, err => {
      this.managefrm.form.markAsUntouched();
      this.toastr.showError("Something Went Wrong")
    }
    )

  } else {

// 
    // this.managevideos["fileName"] = this.fileName;
    // this.managevideos["videoReturnUrl"] = this.fileUrl;

    this.fileuploadservice.updateVideoDetails(this.managevideos).subscribe((updatedData: any) => {
      console.log(updatedData, "update");

      if (updatedData.reponse == true) {
        this.managefrm.form.markAsUntouched();
        this.route.navigate(['/superadmin/manage-video'],{queryParams: {type: this.managevideos.extension == 'video'? 'videos': this.managevideos.extension}})

        this.toastr.showSuccess("Video updated")

      }
    }, err => {
      this.managefrm.form.markAsUntouched();
      this.toastr.showError("Something Went Wrong")
    })
  }
}


    
  }





  uploadVideo(videoEvent: any) {
    // console.log(videoEvent.target.files[0])
    let files: any = ""; files = videoEvent.target.files[0];
    let formData = new FormData();
    formData.append('formFile', files);

    this.fileuploadservice.videoUpload(formData).subscribe((videoUrl: any) => {
      console.log(videoUrl.extraData, "hi")
      // this.uploadVideoSrc = videoUrl.extraData.DbPath;

      // this.managevideos['fileName'] = this.fileName;
      // this.managevideos['videoReturnUrl'] = this.fileUrl;
      this.managevideos['fileName'] = videoUrl.extraData.FileName;
      this.managevideos['videoReturnUrl']= videoUrl.extraData.DownloadPath;

      // this.fileName = videoUrl.extraData.fileName

      // this.managevideos['extension'] = this.isSuccessFullCompleted;




    })



    //    console.log(data,"hkjsdhkjashdkj")
    //       })
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   base64data = reader.result;
    //       // console.log(base64data,"hi");
    //       console.log(base64data,"hi");

    //
    // }
    // reader.readAsDataURL(files);
    // console.log("ffffdsfdcfvcx", files)


  }

  getVideoContentById(videoId: number) {

    this.fileuploadservice.getVideoDetailsbyId(videoId).subscribe((getVideoData: any) => {
      console.log(getVideoData, "hi im contec=nt")
      // this.managevideos = getVideoData.getData;
      this.managevideos['extension'] = getVideoData.getData.extension;
      if(getVideoData.getData.extension == 'video'){
        this.managevideos['videoReturnUrl'] = getVideoData.getData.videoReturnUrl;

      }
      this.managevideos['title'] = getVideoData.getData.title;
      
          // this.managevideos['urls'] = getVideoData.getData.videoReturnUrl;
      
     this.managevideos['videoId'] = Number(videoId);
      this.managevideos['description'] = getVideoData.getData.description;
      this.managevideos['broker'] = getVideoData.getData.broker;
      this.managevideos['agent'] = getVideoData.getData.agent;
      this.managevideos['homeOwner'] = getVideoData.getData.homeOwner;
      this.managevideos['negotiator'] = getVideoData.getData.negotiator;
      this.uploadVideoSrc = getVideoData.getData.videoReturnUrl;

    })
  }
  removeVideo() {
    this.uploadVideoSrc = '';
  }
  loaoptionDocu(item: any) {
    var getUpload = item.target.value
    console.log(getUpload, 'value=>uploader')
    if (getUpload == 1) {
      this.getVideoUpload = true;

    } else if (getUpload == 0) {
      this.getVideoUpload = false;

    }
  }
  canDeactivate() {
    return !this.managefrm.form.touched;
 }

  
}
