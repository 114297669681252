<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
  
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="decline()"> Cancel</button>
      <button type="button" class="btn btn-primary" (click)="accept()">Don't save</button>
      <button type="button" class="btn btn-success" (click)="save()">Save</button>
    </div>