import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-brokers-list-edit',
  templateUrl: './brokers-list-edit.component.html',
  styleUrls: ['./brokers-list-edit.component.css']
})
export class BrokersListEditComponent implements OnInit {
  addBroker: any = {};
  getBrokerlist: any = {};
  id: any = {};
  updateBroker: Boolean = false;
  constructor(private toast: ToastService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // let id = this._manager_service.getId();
    if (this.id !== null || undefined) {
      this.updateBroker = true;
      this._getBroker_byId(this.id)
    }

  }


  _create_broker() {

    console.log("brokerEmail", this.addBroker);
    if (this.updateBroker) {
      console.log(this.addBroker);
      let boolValue = (/true/i).test(this.addBroker.active);
      this.addBroker['active'] = boolValue;
      this.addBroker.createdBy = "SuperAdmin";
      this._manager_service._update_broker(this.addBroker).subscribe((data: any) => {

        if (data.reponse == false) {
          console.log("update", data)
          this.toast.showError("Kindly Enter Valid Domain Mail Id")

        } else {

          //  alert(data.message)
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/brokers-list'])
        }

      }, err => {
        //alert("Something Went Wrong")
      })
    }
    else {
      let boolValue = (/true/i).test(this.addBroker.active);
      this.addBroker['active'] = boolValue;
      this.addBroker.createdBy = "SuperAdmin";
      this._manager_service._add_broker(this.addBroker).subscribe((data: any) => {

        if (data.reponse == false) {
          console.log("update", data)
          this.toast.showError("Kindly Enter Valid Domain Mail Id")

        } else {

          //  alert(data.message)
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/brokers-list'])
        }

      }, err => {
        // alert("Something Went Wrong");
      })
    }
  }

  _getBroker_byId(id: number) {
    this._manager_service._get_brokerByid(id).subscribe((data: any) => {
      // console.log(data['getData'])
      this.addBroker = data['getData'];
    }, err => {
      // alert("Something Went Wrong")
    })


  }





}
