<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="LEAD">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <!-- <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2 *ngIf="usertype != 'Broker'">All Lead</h2>
          <h2 *ngIf="usertype == 'Broker'">Assigned To Lead</h2>
          <div class="sidebar-search lead_glo global-search">
            <div>
              <div class="input-group">
                <i class="fa fa-search" aria-hidden="true"></i>
                <input type="search" placeholder="Search..." (input)="onSearch($event)" #searchword>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="pagecontent_wrapper">
        <!-- <div class="apex-white_wrap">
          <form (ngSubmit)="getFilterData()">

            <div class="filterapx_wrap">
              <div class="filter_setting_forms">
                <h3>Filter By</h3>
                <div class="apxsilsetting_group">
                  <label>Owner</label>
                  <mat-form-field appearance="fill">
                    <mat-select [(ngModel)]="filterRequest.homeOwnerId" [ngModelOptions]="{standalone: true}">
                      <input type="text" (keyup)="onSearchOwner($event)" (keydown)="$event.stopPropagation()">
                      <mat-option value="0">--Select--</mat-option>
                      <mat-option *ngFor="let item of homeOwnerList" [value]="item.homeOwnerId">
                        {{item.ownerName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="apxsilsetting_group">
                  <label>status</label>
                  <select class="form-control" name="status" [(ngModel)]="filterRequest.status">
                    <option *ngFor="let  status of  statusList"  [selected]="status.statusId == 2" [value]=status.statusId>{{status.statusName}}</option>
                  </select>
                </div>
              </div>
              <div class="filter_setting">
                <ul>
                  <li class="filter_apx"><button type="submit"><img src="../../../assets/images/filter.png"
                        alt="filter"></button></li>
                  <li class="setting_reset"><button type="reset" (click)="getLeadlist()"><img
                        src="../../../assets/images/setting_reset.png" alt="filter"></button></li>
                </ul>
              </div>
            </div>
          </form>

        </div> -->
        <div class="apxtable_grids_wrapper">
          <div class="apxtable_grid_head">
            <div class="row">
              <div class="col-lg-12 lead-head">
                <h2>List of Leads</h2>
                <div class="apxtable_headbtn">
                  <!-- <button *ngIf="myuserType != 'Agent' && myuserType != 'Broker'" data-toggle="modal"
                    data-target="#cust-column-modal" class="btn-export-down btn-columns"><svg width="20" height="20"
                      viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.2927 0H0.756101C0.486685 0 0.268295 0.21839 0.268295 0.487806V3.41464C0.268295 3.68406 0.486685 3.90245 0.756101 3.90245H19.2927C19.5621 3.90245 19.7805 3.68406 19.7805 3.41464V0.487806C19.7805 0.21839 19.5621 0 19.2927 0Z"
                        fill="#F15925" />
                      <path
                        d="M19.2927 5.36572H14.9024C14.633 5.36572 14.4146 5.58411 14.4146 5.85353V8.78036C14.4146 9.04978 14.633 9.26817 14.9024 9.26817H19.2927C19.5621 9.26817 19.7805 9.04978 19.7805 8.78036V5.85353C19.7805 5.58411 19.5621 5.36572 19.2927 5.36572Z"
                        fill="#F15925" />
                      <path
                        d="M19.2927 10.7319H14.9024C14.633 10.7319 14.4146 10.9503 14.4146 11.2197V14.1466C14.4146 14.416 14.633 14.6344 14.9024 14.6344H19.2927C19.5621 14.6344 19.7805 14.416 19.7805 14.1466V11.2197C19.7805 10.9503 19.5621 10.7319 19.2927 10.7319Z"
                        fill="#F15925" />
                      <path
                        d="M19.2927 16.0977H14.9024C14.633 16.0977 14.4146 16.316 14.4146 16.5855V19.5123C14.4146 19.7817 14.633 20.0001 14.9024 20.0001H19.2927C19.5621 20.0001 19.7805 19.7817 19.7805 19.5123V16.5855C19.7805 16.316 19.5621 16.0977 19.2927 16.0977Z"
                        fill="#F15925" />
                      <path
                        d="M12.2195 5.36572H0.756101C0.486685 5.36572 0.268295 5.58411 0.268295 5.85353V14.1462C0.268295 14.4156 0.486685 14.634 0.756101 14.634H12.2195C12.4889 14.634 12.7073 14.4156 12.7073 14.1462V5.85353C12.7073 5.58411 12.4889 5.36572 12.2195 5.36572Z"
                        fill="#F15925" />
                      <path
                        d="M0.707319 17.5611H8.12193C8.39135 17.5611 8.60974 17.3427 8.60974 17.0733C8.60974 16.8038 8.39135 16.5854 8.12193 16.5854H0.707319C0.437903 16.5854 0.219513 16.8038 0.219513 17.0733C0.219513 17.3427 0.437903 17.5611 0.707319 17.5611Z"
                        fill="#F15925" />
                      <path
                        d="M12.0244 19.0244H0.707319C0.437903 19.0244 0.219513 19.2428 0.219513 19.5122C0.219513 19.7816 0.437903 20 0.707319 20H12.0244C12.2938 20 12.5122 19.7816 12.5122 19.5122C12.5122 19.2428 12.2938 19.0244 12.0244 19.0244Z"
                        fill="#F15925" />
                    </svg>
                    Customize Column</button> -->
                  <!-- <button (click)="downloadExcellist()" class="btn-export-down"><svg width="20" height="20"
                      viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.525 8.45391C16.116 8.05039 15.5746 7.82812 15 7.82812C14.4199 7.82812 13.8746 8.05391 13.4645 8.46445L12.1875 9.74102V2.1875C12.1875 0.98125 11.2062 0 10 0C8.79375 0 7.8125 0.98125 7.8125 2.1875V9.74102L6.55508 8.48359C6.14492 8.07344 5.575 7.83867 4.99062 7.83867H4.9832C4.39961 7.84062 3.85664 8.0668 3.45391 8.475C3.05039 8.88398 2.82812 9.42539 2.82812 10C2.82812 10.5801 3.05391 11.1254 3.46445 11.5355L8.7293 16.8008C9.07969 17.1512 9.53984 17.3262 10 17.3262C10.4602 17.3262 10.9203 17.1512 11.2707 16.8008L16.5164 11.5551C16.9281 11.1434 17.1633 10.5703 17.1613 9.9832C17.1594 9.39961 16.9332 8.85664 16.525 8.45391Z"
                        fill="#9B59B6" />
                      <path
                        d="M19.375 20H18.4375C18.0923 20 17.8125 19.7202 17.8125 19.375C17.8125 19.0298 18.0923 18.75 18.4375 18.75H19.375C19.7202 18.75 20 19.0298 20 19.375C20 19.7202 19.7202 20 19.375 20Z"
                        fill="#9B59B6" />
                      <path
                        d="M1.5625 20H0.625C0.279844 20 0 19.7202 0 19.375C0 19.0298 0.279844 18.75 0.625 18.75H1.5625C1.90766 18.75 2.1875 19.0298 2.1875 19.375C2.1875 19.7202 1.90766 20 1.5625 20Z"
                        fill="#9B59B6" />
                      <path
                        d="M16.25 20H3.75C3.40484 20 3.125 19.7202 3.125 19.375C3.125 19.0298 3.40484 18.75 3.75 18.75H16.25C16.5952 18.75 16.875 19.0298 16.875 19.375C16.875 19.7202 16.5952 20 16.25 20Z"
                        fill="#9B59B6" />
                    </svg>
                  </button> -->
                  <button (click)="downloadExcel()" class="btn-export-down"><svg width="20" height="20"
                      viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.525 8.45391C16.116 8.05039 15.5746 7.82812 15 7.82812C14.4199 7.82812 13.8746 8.05391 13.4645 8.46445L12.1875 9.74102V2.1875C12.1875 0.98125 11.2062 0 10 0C8.79375 0 7.8125 0.98125 7.8125 2.1875V9.74102L6.55508 8.48359C6.14492 8.07344 5.575 7.83867 4.99062 7.83867H4.9832C4.39961 7.84062 3.85664 8.0668 3.45391 8.475C3.05039 8.88398 2.82812 9.42539 2.82812 10C2.82812 10.5801 3.05391 11.1254 3.46445 11.5355L8.7293 16.8008C9.07969 17.1512 9.53984 17.3262 10 17.3262C10.4602 17.3262 10.9203 17.1512 11.2707 16.8008L16.5164 11.5551C16.9281 11.1434 17.1633 10.5703 17.1613 9.9832C17.1594 9.39961 16.9332 8.85664 16.525 8.45391Z"
                        fill="#9B59B6" />
                      <path
                        d="M19.375 20H18.4375C18.0923 20 17.8125 19.7202 17.8125 19.375C17.8125 19.0298 18.0923 18.75 18.4375 18.75H19.375C19.7202 18.75 20 19.0298 20 19.375C20 19.7202 19.7202 20 19.375 20Z"
                        fill="#9B59B6" />
                      <path
                        d="M1.5625 20H0.625C0.279844 20 0 19.7202 0 19.375C0 19.0298 0.279844 18.75 0.625 18.75H1.5625C1.90766 18.75 2.1875 19.0298 2.1875 19.375C2.1875 19.7202 1.90766 20 1.5625 20Z"
                        fill="#9B59B6" />
                      <path
                        d="M16.25 20H3.75C3.40484 20 3.125 19.7202 3.125 19.375C3.125 19.0298 3.40484 18.75 3.75 18.75H16.25C16.5952 18.75 16.875 19.0298 16.875 19.375C16.875 19.7202 16.5952 20 16.25 20Z"
                        fill="#9B59B6" />
                    </svg>
                    Download / Export template</button>
                  <input hidden type="file" #uploader (input)="uploadFile($event)" />

                  <button (click)="uploader.click()" type="file" class="btn-create-manage btnexcel-file"><svg width="20"
                      height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.0417 19.1669H8.95833C8.38416 19.1669 7.91666 18.6994 7.91666 18.1252V14.5836H6.04166C5.79166 14.5836 5.56583 14.4344 5.46749 14.2052C5.36916 13.9761 5.41666 13.7094 5.58833 13.5277L9.54666 9.36105C9.78249 9.11272 10.2175 9.11272 10.4533 9.36105L14.4117 13.5277C14.5833 13.7086 14.6317 13.9752 14.5333 14.2052C14.435 14.4352 14.2083 14.5836 13.9583 14.5836H12.0833V18.1252C12.0833 18.6994 11.6158 19.1669 11.0417 19.1669Z"
                        fill="#2ECC71" />
                      <path
                        d="M15.5608 4.106C14.4675 2.10683 12.3242 0.833496 10 0.833496C7.04583 0.833496 4.48333 2.88766 3.83583 5.691C1.6825 5.91766 0 7.72599 0 9.91683C0 12.0793 1.64667 13.8502 3.76 14.1135C3.7175 13.486 3.93 12.8552 4.37917 12.3818L8.33833 8.21433C9.19083 7.31766 10.81 7.31766 11.6608 8.21433L15.62 12.381C16.0383 12.8218 16.2508 13.3985 16.245 13.9818C18.4058 13.3918 20 11.4343 20 9.11099C20 6.55599 18.0775 4.4235 15.5608 4.106Z"
                        fill="#2ECC71" />
                    </svg>
                    import via excel file</button>
                  <button *ngIf="myuserType != 'Broker'" [routerLink]="['/superadmin/lead-list-edit']"
                    class="btn-create-manage"><svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.61931 10.1372C10.4355 10.137 11.4488 7.31722 11.7141 5.00095C12.0409 2.14755 10.6915 0 7.61931 0C4.54748 0 3.19744 2.14739 3.52447 5.00095C3.78999 7.31722 4.803 10.1374 7.61931 10.1372Z"
                        fill="#085589" />
                      <path
                        d="M13.7773 11.311C13.8679 11.311 13.958 11.3137 14.0474 11.3187C13.9134 11.1275 13.7582 10.9589 13.5767 10.8233C13.0355 10.4194 12.3347 10.2869 11.7151 10.0435C11.4135 9.92501 11.1435 9.80733 10.8899 9.67334C10.0342 10.6117 8.91838 11.1026 7.61897 11.1028C6.31999 11.1028 5.20423 10.6119 4.34865 9.67334C4.09512 9.80736 3.82499 9.92501 3.52345 10.0435C2.90387 10.287 2.20306 10.4194 1.66188 10.8233C0.725999 11.5219 0.484136 13.0933 0.294104 14.1652C0.137274 15.05 0.0319142 15.953 0.00116256 16.8517C-0.022661 17.5479 0.321065 17.6455 0.903483 17.8557C1.63273 18.1187 2.38571 18.314 3.14374 18.474C4.6077 18.7832 6.11672 19.0207 7.61923 19.0313C8.34727 19.0261 9.07678 18.9674 9.80198 18.8734C9.26502 18.0954 8.95008 17.153 8.95008 16.1383C8.95012 13.4765 11.1156 11.311 13.7773 11.311Z"
                        fill="#085589" />
                      <path
                        d="M13.7773 12.2764C11.6446 12.2764 9.91553 14.0054 9.91553 16.1382C9.91553 18.2709 11.6445 20 13.7773 20C15.9101 20 17.6392 18.2709 17.6392 16.1382C17.6391 14.0054 15.9101 12.2764 13.7773 12.2764ZM15.4669 16.8007H14.4399V17.8276C14.4399 18.1936 14.1433 18.4903 13.7774 18.4903C13.4114 18.4903 13.1147 18.1936 13.1147 17.8276V16.8007H12.0878C11.7219 16.8007 11.4252 16.5041 11.4252 16.1381C11.4252 15.7722 11.7218 15.4755 12.0878 15.4755H13.1147V14.4486C13.1147 14.0826 13.4114 13.7859 13.7774 13.7859C14.1433 13.7859 14.4399 14.0826 14.4399 14.4486V15.4755H15.4669C15.8328 15.4755 16.1295 15.7722 16.1295 16.1381C16.1294 16.5041 15.8328 16.8007 15.4669 16.8007Z"
                        fill="#085589" />
                    </svg>
                    Create Lead</button>
                </div>
              </div>

              <div class="search_lead_grid col-lg-12">
                <!-- <div class="sidebar-search">
                  <div>
                    <form>
                      <div class="input-group">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <input type="search" placeholder="Search..." (input)="leadSearch(searchword.value)" #searchword>
                      </div>
                    </form>
                  </div>
                </div> -->

                <div class="apexinput-froms"
                  *ngIf="myuserType != 'Agent' && myuserType != 'Broker' && myuserType != 'Manager'">
                  <h2>Assigned Selected Lead/S</h2>

                  <select [(ngModel)]="getDropdownUser" [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="usertypes_Dropdown()">
                    <option selected >----select UserType---</option>
                    <option *ngFor="let user of  userTypes" [ngValue]="user.userTypeId">{{user.userTypeName}}</option>
                  </select>
                  <!-- <select [(ngModel)]="getUserlist">
                    <option selected disabled>----select User---</option>
                    <option *ngFor="let  listuser of  userLists" [ngValue]="listuser.userId">{{listuser.userName}}
                    </option>
                  </select> -->
                  <mat-form-field appearance="fill">
                    <!-- <mat-label>--Select--</mat-label>-->
                    <mat-select [(ngModel)]="getUserlist" [ngModelOptions]="{standalone: true}">
                      <!-- <mat-option>--Select--</mat-option> -->
                      <input type="text" (keyup)="onSearchUsers($event)" (keydown)="$event.stopPropagation()">
                      <mat-option *ngFor="let item of userLists" [value]="item.userId">
                        {{item.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <button class="btn_dblue" (click)="assign_lead()">Assign</button>
                </div>

                <div class="apexinput-froms" *ngIf="myuserType == 'Manager'">
                  <h2>Assigned Selected Lead/S</h2>

                  <select [(ngModel)]="getDropdownUser" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="usertypes_Dropdown()">
                    <option selected>Select UserType </option>
                    <option *ngFor="let user of  userTypes"  [ngValue]="user.userTypeId">{{user.userTypeName}}</option>
                  </select>

                  <mat-form-field appearance="fill">
                    <!-- <mat-label>--Select--</mat-label>-->
                    <mat-select [(ngModel)]="getUserlist" [ngModelOptions]="{standalone: true}">
                      <!-- <mat-option>--Select--</mat-option> -->
                      <input type="text" (keyup)="onSearchUsers($event)" (keydown)="$event.stopPropagation()">
                      <mat-option *ngFor="let item of userLists" [value]="item.userId">
                        {{item.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <button class="btn_dblue" (click)="assign_lead()">Assign</button>
                </div>
              </div>
            </div>
          </div>


          <!-- *ngIf="leadData && leadData! == 'null'" -->
          <ng-container *ngIf="myuserType != 'Broker'">
            <div class="apxtable_grid_table" *ngIf="leadData && leadData.length>0">
              <div class="table-responsive apx-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" [(ngModel)]="masterSelected" [ngModelOptions]="{standalone: true}"
                          name="list_name" value="m1" (change)="checkUncheckAll()" /> <strong></strong>
                      </th>
                      <th>#
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <th [class.active]="order === 'firstName'" (click)="setOrder('firstName')">Owner
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <th [class.active]="order === 'emailId'" (click)="setOrder('emailId')">Email
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <th *ngIf="myuserType == 'Broker'" [class.active]="order === 'phone'" (click)="setOrder('phone')">
                        Address
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <th *ngIf="myuserType != 'Broker'" [class.active]="order === 'phone'" (click)="setOrder('phone')">
                        Owner Phone
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>

                      <th *ngIf="myuserType != 'Broker'" [class.active]="order === 'address'"
                        (click)="setOrder('address')">
                        Address
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                      <th [class.active]="order === 'userfullName'" (click)="setOrder('userfullName')">Assigned To <span
                          [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <th [class.active]="order === 'recordingDate'" (click)="setOrder('recordingDate')">Next Task <span
                          [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <th [class.active]="order === 'recordingDate'" (click)="setOrder('recordingDate')">Last Opened
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <!-- <th [class.active]="order === 'isActive'" (click)="setOrder('isActive')">Status
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th> -->
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      *ngFor="let leadlist of leadData | orderBy: order:reverse| paginate: { itemsPerPage: 45, currentPage: p }; let i= index; ">
                      <td>
                        <input type="checkbox" [(ngModel)]="leadlist.isSelected" [ngModelOptions]="{standalone: true}"
                          name="list_name" value="{{leadlist.leadId}}" (change)="isAllSelected()" />
                      </td>


                      <td>{{45 *(p-1)+i+1}} </td>
                      <td>
                        <!-- <a class="pencilleadlist"
                          [routerLink]="['/superadmin/lead-loa-list/', leadlist.leadId]">{{leadlist['homeOwner'].firstName || '--'}}
                          {{leadlist['homeOwner'].lastName || '--'}}</a> -->
                          <a class="pencilleadlist" *ngIf="usertype == 'Agent' || usertype == 'Broker'"
                          [routerLink]="['/superadmin/lead-loa-list/', leadlist.leadId]">{{leadlist['homeOwner'].firstName || '--'}}
                          {{leadlist['homeOwner'].lastName || '--'}}</a>
                          <a class="pencilleadlist" *ngIf="usertype == 'SuperAdmin' || usertype == 'Manager'"
                          [routerLink]="['/superadmin/lead-list-edit/', leadlist.leadId]">{{leadlist['homeOwner'].firstName || '--'}}
                          {{leadlist['homeOwner'].lastName || '--'}}</a>
                      </td>
                      <td><a
                          href="mailto:{{leadlist['homeOwner'].emailId}}">{{leadlist['homeOwner'].emailId || '--'}}</a>
                      </td>
                      <td *ngIf="myuserType == 'Broker'">{{leadlist.homeOwner.cityName || '--'}}, {{leadlist.homeOwner.stateName || '--'}}</td>
                      <td *ngIf="myuserType != 'Broker'"><a
                          href="tel:{{leadlist.phoneNumber1}}">{{leadlist.phoneNumber1 | mask: '(000) 000-0000' || '--'}}</a></td>
                      <td *ngIf="myuserType != 'Broker'">{{leadlist.homeOwner.cityName || '--'}}, {{leadlist.homeOwner.stateName || '--'}}</td>
                      <td>
                        {{leadlist.leadChildAssign.length>0 && leadlist.leadChildAssign[leadlist.leadChildAssign.length-1].userfullName || '--'}}
                      </td>
                      <td [ngStyle]="{'color': getTaskCompletionStatus(leadlist.nextTask?.nextTaskDate) >1  ?'red':'' }">{{leadlist.nextTask?.nextTaskDate}}</td>
                      <td>{{leadlist.lastOpenedDate || '--'}}</td>
                      <!-- <td>{{leadlist.loaStatus || '--'}}</td> -->
                      <td>
                        <div class=" apx-actions ">
                          <a *ngIf="myuserType != 'Agent' && myuserType != 'Broker'" class="pencilleadlist"
                            [routerLink]="['/superadmin/lead-list-edit/', leadlist.leadId]"> <span class="pencil"><i
                                class="fas fa-pencil-alt "></i></span></a>
                          <a *ngIf="myuserType == 'Agent' || myuserType == 'Broker'" class="pencilleadlist"
                            [routerLink]="['/superadmin/lead-loa-list/', leadlist.leadId]"> <span class="pencil"><i
                                class="fas fa-pencil-alt "></i></span></a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="myuserType == 'Broker'">
            <div class="apxtable_grid_table" *ngIf="assignedAgentList && assignedAgentList.length>0">
              <div class="table-responsive apx-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" [(ngModel)]="masterSelected" [ngModelOptions]="{standalone: true}"
                          name="list_name" value="m1" (change)="checkUncheckAll()" /> <strong></strong>
                      </th>
                      <th>#
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <th [class.active]="order === 'firstName'" (click)="setOrder('firstName')">Owner
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>
                      <th [class.active]="order === 'emailId'" (click)="setOrder('emailId')">Email
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>


                      <th [class.active]="order === 'phone'" (click)="setOrder('phone')">
                        Address
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>


                      <th [class.active]="order === 'userfullName'" (click)="setOrder('userfullName')">Assigned To <span
                          [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>

                      <th [class.active]="order === 'isActive'" (click)="setOrder('isActive')">Statussssss
                        <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                        <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                      </th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let leadlist of assignedAgentList | orderBy: order:reverse| paginate: { itemsPerPage: 10, currentPage: p }; let i= index; ">
                      <td>
                        <input type="checkbox" [(ngModel)]="leadlist.isSelected" [ngModelOptions]="{standalone: true}"
                          name="list_name" value="{{leadlist.leadId}}" (change)="isAllSelected()" /> {{leadlist.value}}
                      </td>


                      <td>{{45 *(p-1)+i+1}} </td>
                      <td>
                        {{leadlist.homeOwnerName || '--'}}
                      </td>
                      <td>{{leadlist.emailId || '--'}}</td>
                      <td>{{leadlist.homeOwner.cityName || '--'}}, {{leadlist.homeOwner.stateName || '--'}}</td>
                      <td>{{leadlist.assignedTo || '--'}}</td>
                      <td>{{leadlist.status || '--'}}</td>
                      <td>
                        <div class=" apx-actions ">

                          <a class="pencilleadlist" [routerLink]="['/superadmin/lead-loa-list/', leadlist.leadId]">
                            <span class="pencil"><i class="fas fa-pencil-alt "></i></span></a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="apxtable_pagination_wrapper" *ngIf="leadData && leadData !== null else elseBlock">
          <!-- <div class="apxtable_pagelist">
            <div class="apxlist_number_page">
              <span>Show</span>
              <input  min="1" max="45" value="45" (keyup)="pageListNumber($event)" type="number" />
              <span>Entries</span>
            </div>
            <div class="pagination_gopage">
              <span>Go to Page</span>
              <input type="text" value="1" />
            </div>
          </div> -->
          <div class="apxtable_pagination">
            <!-- <ul>
                        <li class="prev">Prev</li>
                        <li class="active"><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li>...</li>
                        <li><a href="#">16</a></li>
                        <li><a href="#">17</a></li>
                        <li><a href="#">18</a></li>
                        <li class="next">Next</li>
                    </ul> -->
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
          <ng-template>
            <div class="nodata_found_components" *ngIf="leadData == 'null'">
              <h2>No Data found</h2>
            </div>
          </ng-template>
          <ng-template>
            <div class="nodata_found_components" *ngIf="assignedAgentList == 'null'">
              <h2>No Data found</h2>
            </div>
          </ng-template>
        </div>


        <ng-template #elseBlock>
          <div class="nodata_found_components">
            <h2>No Data found</h2>
          </div>
        </ng-template>




      </div>
    </div>
  </main>

</div>



<!-- The Modal -->
<div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel">Cancel</button>
        </div>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="filterforms_setting">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>First Name</label>
                <input type="text" placeholder="First name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Last Name</label>
                <input type="text" placeholder="Last name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Status</label>
                <select>
                  <option value="">All</option>
                  <option value="">All-1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- The customise column Modal -->
<div class="modal" id="cust-column-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <div class="cust-modal-head">
          <h4 class="modal-title">Customize Column</h4>
          <div>
            <button type="button" class="apply-btn-g" data-dismiss="modal">Apply</button>
            <button type="button" class="Cancel-btn-g" data-dismiss="modal">Cancel</button></div>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body cust-column-modal-body">
        <!-- <button type="button" class="close add-col-btn-g" (click)="addnewCol()">Add New Column</button> -->
        <div class="cust-modal-inner" id="addinputcol">

          <div class="content-mod" *ngFor="let columns of columnsList"
            [ngClass]="{'textbox_activated': isActive(columns.excelHeadersId)}">
            <input type="text" class="edit-box-g" [value]="columns.columnName"
              [ngClass]="{'disable-box-g': isActive(columns.excelHeadersId)}">
            <div class="edit-icons-g editeyes">
              <a><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12" viewBox="0 0 18 12"
                  fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a (click)="select(columns.excelHeadersId)"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g"
                  width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg> -->
              </a>
            </div>
            <div class="edit-icons-g yes-icon-g" *ngIf="isActive(columns.excelHeadersId)">
              <a (click)="select()">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="30" height="30" rx="5" fill="#2ECC71" />
                  <g clip-path="url(#clip0)">
                    <path d="M7 15.5105L12.4791 21.0001L23 10.4896L21.4896 9L12.4791 18L8.48953 14.0105L7 15.5105Z"
                      fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="16" height="16" fill="white" transform="translate(7 7)" />
                    </clipPath>
                  </defs>
                </svg></a>

              <a (click)="select()">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <rect width="30" height="30" rx="5" fill="#E74C3C" />
                  <path
                    d="M21 10.2L19.8 9L15 13.8L10.2 9L9 10.2L13.8 15L9 19.8L10.2 21L15 16.2L19.8 21L21 19.8L16.2 15L21 10.2Z"
                    fill="white" />
                </svg>
              </a>
            </div>

          </div>
          <!-- list end -->


        </div>
      </div>

    </div>
  </div>
</div>

<!-- end  customise column Modal -->
