import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import {DropdownService} from '../../service/dropdown.service';
import { FileuploadService } from 'src/app/service/fileupload.service'

@Component({
  selector: 'app-brokers-list',
  templateUrl: './brokers-list.component.html',
  styleUrls: ['./brokers-list.component.css']
})
export class BrokersListComponent implements OnInit {

 brokerList:any =[];
deleteData: any;
order: string = 'managersFirstname';
p: number = 0;
reverse: boolean = false;
userListOptions:any;
statusListOptions:any;
filterRequest:any={};
  constructor(private managerService : ManagerService,private dropDownService:DropdownService,private fileupload: FileuploadService) { }

  ngOnInit(): void {
    this.getfilter_details();
    this._getBroker_records();

  }

  _getBroker_records() {
    this.managerService._get_All_Broker().subscribe((data: any) => {
      this.brokerList = data.listData;
      // console.log(data.listData)
    })
  }


  getFilterData(){
    console.log(this.filterRequest)
    this.filterRequest.status = parseInt( this.filterRequest.status);
    this.dropDownService._getAll_Brokers_FilterData(this.filterRequest).subscribe((filteredData:any)=>{
      this.brokerList =filteredData.listData;
    });
  }

 //**Get Details for Filter */
  getfilter_details(){
    this.dropDownService._getAll_Brokers_UserList().subscribe((userList:any) =>{
      console.log(userList.listData,"ji");
      this.userListOptions = userList.listData;
    })
    this.dropDownService._getAll_Brokers_StatusList().subscribe((statusList:any)=>{
      console.log(statusList.listData,"sji")
      this.statusListOptions = statusList.listData;
    })
  }
 //**End */
  setId(data :any){
    this.deleteData =  data;
  }

  _delete_manager() {
    this.managerService._delete_broker(this.deleteData).subscribe(deleteData => {
      console.log(deleteData)
      this._getBroker_records()
    })


  }


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  downloadExcel() {
    this.fileupload.download_broker();
  }

}
