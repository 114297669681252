import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  private actionUrl: any;
  private id: any;
  leadObj: any;
  userInfo: any;
  userInfoJson:any;
  currentUserTypeId: string | null;

  constructor(private http: HttpClient) {
    this.actionUrl = environment.apiUrl;
    this.currentUserTypeId = sessionStorage.getItem('userTypeId');
     this.userInfo = sessionStorage.getItem('userInfo');
     this.userInfoJson =JSON.parse(this.userInfo);
   
  }

  getNotes(id:any):Observable<any>{

  return  this.http.post(this.actionUrl+`/ClientNotes/GetAllNotesByUserId?CurrentUserId=${id}`,{})
  }

  UpdateAsReadNotes(data:any){
  // debugger;
    return this.http.post(this.actionUrl+`/ClientNotes/UpdateAsReadForNotes`,data)
  }
} 
