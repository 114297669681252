<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Auto Generated Documents"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>add / edit Auto Generated Documents</h2>
          <button class="btn_to_btitle"><a routerLink="/superadmin/manage-auto-gen">back</a></button>
        </div>
      </div>
      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap newheight-apx">
          <div class="apex-title-lilblue">
            <h3>Document Details</h3>
          </div>
          <form [formGroup]="manageautogenForm" #manageForm="ngForm" (ngSubmit)="manageautogenForm.valid && onSubmit()"
            novalidate>
            <!-- <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="apexinput-froms">
                    <label>Title</label>
                    <input type="text" value="" name="titleName" formControlName="titleName" class="form-control" />
                    <div class="form-text text-danger"
                      *ngIf="manageForm.submitted && manageautogenForm.controls.titleName.errors">
                      Title Required</div>

                  </div>
                </div>
              </div>
            </div> -->
            <div class="apexforms-group" [formGroup]="manageautogenForm.controls.autogenerateddocfolder">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="apexinput-froms apxfiles">
                    <label>Folder Name<sup>*</sup></label>
                    <div class="filesname_clients_doc">
                      <img src="../../../assets/images/clients/files.png" alt="icon">
                      <!-- <span formControlName="folderName">Affiliated Business Disclosure Form</span> -->
                      <input type="text" value="" name="folderName" formControlName="folderName" class="form-control"
                        required placeholder="Folder Name"/>
                      <div class="form-text text-danger"
                        *ngIf="manageForm.submitted && manageautogenForm.controls.autogenerateddocfolder.controls.folderName.errors">
                        FolderName Required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="apexinput-froms">
                    <label>Upload<sup>*</sup></label>
                    <div class="manageauto_upload" [formGroup]="formGroup"
                      *ngFor="let formGroup of manageautogenForm.controls.autogenerateddocfiles.controls;let i = index;">
                      <input type="file" id="managerupload_file" name="managerupload_file"  class="form-control" multiple
                        (change)="onSelectFile($event, i, responseFileObj[i].docmentUrl)" required>

                      <!-- <input type="file" id="managerupload_file" class="form-control" multiple
                        (change)="onSelectFile($event, i)">
                      <label for="managerupload_file">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.0418 19.1679H8.95842C8.38425 19.1679 7.91675 18.7004 7.91675 18.1262V14.5845H6.04175C5.79175 14.5845 5.56592 14.4354 5.46758 14.2062C5.36925 13.977 5.41675 13.7104 5.58842 13.5287L9.54675 9.36203C9.78258 9.1137 10.2176 9.1137 10.4534 9.36203L14.4118 13.5287C14.5834 13.7095 14.6318 13.9762 14.5334 14.2062C14.4351 14.4362 14.2084 14.5845 13.9584 14.5845H12.0834V18.1262C12.0834 18.7004 11.6159 19.1679 11.0418 19.1679Z"
                            fill="#828282" />
                          <path
                            d="M15.5608 4.10648C14.4675 2.10732 12.3242 0.833984 10 0.833984C7.04583 0.833984 4.48333 2.88815 3.83583 5.69148C1.6825 5.91815 0 7.72648 0 9.91732C0 12.0798 1.64667 13.8506 3.76 14.114C3.7175 13.4865 3.93 12.8556 4.37917 12.3823L8.33833 8.21482C9.19083 7.31815 10.81 7.31815 11.6608 8.21482L15.62 12.3815C16.0383 12.8223 16.2508 13.399 16.245 13.9823C18.4058 13.3923 20 11.4348 20 9.11148C20 6.55648 18.0775 4.42398 15.5608 4.10648Z"
                            fill="#828282" />
                        </svg>
                        upload file
                      </label> -->
                      <input type="text" [(ngModel)]="responseFileObj[i].documentName"
                        [ngModelOptions]="{standalone: true}">
                      <div>
                        <div class="apexinput-froms img-center tasktemp_detail">
                          <img (click)="addDoc()" src="../../../assets/images/clients/increment.png">
                          <img *ngIf="i != 0" (click)="deleteFile(i)"
                            src="../../../assets/images/clients/decrement.png">
                        </div>
                       
                      </div>
                      <div class="form-text text-danger" *ngIf="uploadedFile.length==0 && manageForm.submitted  " >Please Select File</div>

                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="apexinput-froms apxfiles">
                    <label></label>
                    <div class="manager_uploader_files">
                      <span>Uploaded Files</span>
                      <ul *ngFor="let files of uploadedFile; let i = index">
                        <li
                          *ngIf="(files.DownloadPath !== undefined || files.docmentUrl !== undefined) && files.docStatus">
                          <img src="../../../assets/images/clients/pdf_normal.png" alt="pdf">
                          <p *ngIf="files.documentName !== undefined">{{files.documentName}}</p>
                          <a *ngIf="files.DownloadPath !== undefined || files.docmentUrl !== undefined"
                            [href]="files.docmentUrl" target="_blank" [download]="files.documentName">Download</a><span>

                            <i class="far fa-trash-alt" (click)="removeDocs(i)"></i></span>
                        </li>

                      </ul>

                    </div>
                    
                  </div>
                </div>
              
              </div>
            </div>


            <div class="apxbtn_finalcenter apx-mt-15">
              <!-- <button class="btn_grey" routerLink="/superadmin/manage-auto-gen">Cancel</button> -->
              <button class="btn_grey client_remove" type="reset">Cancel</button>
              <button class="btn_dblue">save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</div>
