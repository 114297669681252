<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Users"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>add / edit Agent</h2>
          <button class="btn_to_btitle"> <a routerLink="/superadmin/agents-details">back</a></button>
        </div>
      </div>
      <form (ngSubmit)=addEdit.form.valid&&_create_Agent() #addEdit="ngForm">
        <div class="pagecontent_wrapper">
          <div class="apex-white_wrap newheight-apx">
            <div class="apex-title-lilblue">
              <h3>basic details</h3>
            </div>

            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>First Name</label>
                    <input type="text" name="firstName" [(ngModel)]="addAgent.firstName" #firstName="ngModel"
                      placeholder="First Name" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && firstName.invalid}" required />
                    <div *ngIf="addEdit.submitted && firstName.invalid" class="invalid-feedback">
                      <div *ngIf="firstName.errors?.required">Firstname is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Last Name</label>
                    <input type="text" name="lastName" [(ngModel)]="addAgent.lastName" #lastName="ngModel"
                      placeholder="Last Name" value="" [ngClass]="{'is-invalid': addEdit.submitted && lastName.invalid}"
                      required />
                    <div *ngIf="addEdit.submitted && lastName.invalid" class="invalid-feedback">
                      <div *ngIf="lastName.errors?.required">Lastname is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Email Address</label>
                    <input type="email" name="emailId" [(ngModel)]="addAgent.emailId" #emailId="ngModel"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Email Address" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && emailId.invalid}" required />
                    <div *ngIf="addEdit.submitted && emailId.invalid" class="invalid-feedback">
                      <div *ngIf=" emailId.errors?.required">Email is required</div>
                      <div *ngIf="emailId.errors?.pattern">Email must be a valid email address</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Phone Number</label>
                    <input type="text" [(ngModel)]="addAgent.phone" #phone="ngModel" placeholder="Phone Number" value=""
                      name="phone" minlength="10" maxlength="15"
                      [ngClass]="{'is-invalid': addEdit.submitted && phone.invalid}" required />
                    <div *ngIf="addEdit.submitted && phone.invalid" class="invalid-feedback">
                      <div *ngIf="phone.errors?.required">Phone Number is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group specialmb-15">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Agent Type</label>
                    <select [(ngModel)]='addAgent.agentTypeId' (change)="agentTypeList($event)" name="agentTypeId" #agentTypeId="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && agentTypeId.invalid}" required>
                      <option disabled selected>Select Agent Type</option>
                      <option *ngFor="let item of agentTypes" [value]="item.agentTypeId">{{item.agentTypeName}}
                      </option>
                    </select>
                    <div *ngIf="addEdit.submitted && agentTypeId.invalid" class="invalid-feedback">
                      <div *ngIf="agentTypeId.errors?.required">Agent Type is required</div>

                    </div>

                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="addAgent.agentTypeId == 1">
                  <div class="apexinput-froms">
                    <label>Manager</label>
                    <select  name="managerId" [(ngModel)]="addAgent.managerId" #managerId="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && managerId.invalid}" required>
                      <option disabled selected>Select Manager</option>
                      <option *ngFor="let item of agentListManager" [value]="item.managerId">{{item.managerName}}
                      </option>
                    </select>
                    <div *ngIf="addEdit.submitted && managerId.invalid" class="invalid-feedback">
                      <div *ngIf="managerId.errors?.required">Manager is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="addAgent.agentTypeId == 2">
                  <div class="apexinput-froms">
                    <label>Broker</label>
                    <select name="companyId" [(ngModel)]="addAgent.companyId" #companyId="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && companyId.invalid}" required>
                      <option disabled selected>Select Broker</option>
                      <option *ngFor="let item of agentListCompany" [value]="item.companyId">{{item.name}}
                      </option>
                    </select>
                    <div *ngIf="addEdit.submitted && companyId.invalid" class="invalid-feedback">
                      <div *ngIf="companyId.errors?.required">Broker is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Status</label>
                    <select id="active" name="active" [(ngModel)]="addAgent.active" #active="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && active.invalid}" required>

                      <option [value]="true" selected>Active</option>
                      <option [value]="false">InActive</option>
                    </select>
                    <div *ngIf="addEdit.submitted && active.invalid" class="invalid-feedback">
                      <div *ngIf="active.errors?.required">Status is required</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>State</label>
                    <select id="stateId" name="stateId" [(ngModel)]="addAgent.stateId" #stateId="ngModel"
                      (change)="dropdown_city($event)" [ngClass]="{'is-invalid': addEdit.submitted && stateId.invalid}"
                      required>

                      <option *ngFor="let states of stateList | sort: 'stateName'" [value]="states.stateId">{{states.stateName}}</option>
                    </select>
                    <div *ngIf="addEdit.submitted && stateId.invalid" class="invalid-feedback">
                      <div *ngIf="stateId.errors?.required">State is required</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>City</label>
                    <select id="cityId" name="cityId" [(ngModel)]="addAgent.cityId" #cityId="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && cityId.invalid}" required>

                      <option *ngFor="let cities of cityList | sort: 'cityName'" [value]="cities.cityId">{{cities.cityName}}</option>
                    </select>
                    <div *ngIf="addEdit.submitted && cityId.invalid" class="invalid-feedback">
                      <div *ngIf="cityId.errors?.required">City is required</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="apxbtn_finalcenter apx-mt-15">
              <button type="reset" routerLink="/agents-details" class="btn_grey">Cancel</button>
              <button class="btn_dblue">save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</div>
