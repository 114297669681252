<div class="page-wrapper chiller-theme">

  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Contact Us">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap">
          <div class="contact_user">
            <h2 class="inner-head-g">Contact apex Resolution</h2>
            <div class="broker-contact-info">
              <div class="broker-con-head">
                <img src="../../../assets/images/ISHORTSALE_LOGO_HORIZONTAL_RGB.png">
                <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard
                  dummy text ever since the 1500s, when an unknown printer took a galley of make a type specimen book.
                </p> -->
              </div>

              <!-- <p>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to
                generate Lorem Ipsum which looks reasonable. The
                generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic
                words. There are many variations of passages of
                Lorem Ipsum available, but the majority have suffered alteration, or randomised words which don't look
                even slightly believable.</p>

              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.</p> -->

              <div class="addres-bk">
                <div class="address-inner">
                  <div class="add-icon-left"><svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.7286 0.984375C9.97876 0.984375 5.30078 5.66235 5.30078 11.4122C5.30078 18.6392 15.7389 30.9844 15.7389 30.9844C15.7389 30.9844 26.1565 18.2838 26.1565 11.4122C26.1565 5.66235 21.4787 0.984375 15.7286 0.984375ZM18.8749 14.4655C18.0074 15.3329 16.8681 15.7667 15.7286 15.7667C14.5894 15.7667 13.4497 15.3329 12.5825 14.4655C10.8476 12.7308 10.8476 9.90802 12.5825 8.17309C13.4226 7.33264 14.5401 6.86975 15.7286 6.86975C16.9172 6.86975 18.0345 7.33282 18.8749 8.17309C20.6099 9.90802 20.6099 12.7308 18.8749 14.4655Z"
                        fill="#F15925" />
                    </svg></div>
                  <div class="add-det-right">
                    <p>#44-46, Morningside Road,
                      Edinburgh, Scotland, EH10 4BF</p>
                  </div>
                </div>

                <div class="address-inner">
                  <div class="add-icon-left"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                      viewBox="0 0 30 30" fill="none">
                      <path
                        d="M29.1837 22.017L24.9971 17.8304C23.5019 16.3352 20.9601 16.9333 20.362 18.877C19.9134 20.2228 18.4182 20.9704 17.0725 20.6713C14.0821 19.9237 10.045 16.0361 9.29742 12.8962C8.84886 11.5504 9.74598 10.0552 11.0917 9.60673C13.0354 9.00864 13.6335 6.46679 12.1383 4.97158L7.95173 0.784986C6.75556 -0.261662 4.96131 -0.261662 3.91466 0.784986L1.07376 3.62589C-1.76714 6.61631 1.3728 14.5409 8.40029 21.5684C15.4278 28.5959 23.3524 31.8854 26.3428 28.8949L29.1837 26.054C30.2304 24.8579 30.2304 23.0636 29.1837 22.017Z"
                        fill="#F15925" />
                    </svg></div>
                  <div class="add-det-right">
                    <a href="tel:'+861 9856 6528'">(861) 9856 6528 </a>
                    <a href="tel:'+965 3312 9633'">(965) 3312 9633</a>
                  </div>
                </div>

                <div class="address-inner">
                  <div class="add-icon-left">
                    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.87941 7.47947C3.2589 7.74726 4.40284 8.54261 6.31129 9.8651C8.21979 11.1876 9.68184 12.2059 10.6975 12.92C10.8091 12.9983 11.0461 13.1684 11.4088 13.4307C11.7715 13.6932 12.0729 13.9053 12.3127 14.0671C12.5527 14.2288 12.8429 14.4102 13.1834 14.611C13.5239 14.8117 13.8448 14.9626 14.1461 15.0625C14.4475 15.1633 14.7265 15.2132 14.9832 15.2132H15H15.0169C15.2735 15.2132 15.5526 15.1632 15.854 15.0625C16.1553 14.9626 16.4765 14.8115 16.8166 14.611C17.1569 14.41 17.4471 14.2287 17.6871 14.0671C17.9271 13.9053 18.2282 13.6932 18.5911 13.4307C18.9537 13.1682 19.191 12.9983 19.3026 12.92C20.3292 12.2059 22.9411 10.3922 27.1374 7.47912C27.9521 6.91017 28.6327 6.22365 29.1795 5.42003C29.7267 4.61677 30 3.7741 30 2.8925C30 2.15579 29.7348 1.52516 29.2046 1.00065C28.6745 0.476031 28.0466 0.213867 27.3214 0.213867H2.67846C1.81914 0.213867 1.15784 0.504001 0.694668 1.08427C0.231556 1.66465 0 2.39011 0 3.26057C0 3.96368 0.307021 4.7256 0.92077 5.54587C1.53446 6.36619 2.18755 7.01079 2.87941 7.47947Z"
                        fill="#F15925" />
                      <path
                        d="M28.3257 9.27087C24.6653 11.7483 21.886 13.6737 19.9889 15.0466C19.3528 15.5152 18.8367 15.881 18.4404 16.1431C18.044 16.4054 17.517 16.6732 16.8584 16.9465C16.2 17.2202 15.5864 17.3567 15.0171 17.3567H15H14.9832C14.414 17.3567 13.8001 17.2202 13.1417 16.9465C12.4834 16.6732 11.9559 16.4054 11.5597 16.1431C11.1636 15.881 10.6474 15.5152 10.0113 15.0466C8.50452 13.9418 5.73113 12.0163 1.69102 9.27087C1.0547 8.84704 0.491081 8.36124 0 7.81445V21.1065C0 21.8435 0.262164 22.4738 0.786785 22.9984C1.31129 23.5231 1.94198 23.7854 2.67863 23.7854H27.3216C28.058 23.7854 28.6887 23.5231 29.2132 22.9984C29.738 22.4737 30 21.8436 30 21.1065V7.81445C29.52 8.34992 28.9622 8.83573 28.3257 9.27087Z"
                        fill="#F15925" />
                    </svg></div>
                  <div class="add-det-right">
                    <a href="mailto:support@apexresolution.com">support@apexresolution.com
                    </a>
                    <a href="mailto:contact@apexresolution.com"> contact@apexresolution.com</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

</div>
