<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Profile">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap">
          <div class="apex-title-lilblue">
            <h3>Profile Picture</h3>
          </div>
          <div class="m-profile-parent col-xl-12">
            <div class="row">
              <div class="manage_photos col-md-7 col-lg-7 col-xl-7 col-12">
                <div class="manager_photos_images">
                  <img [src]=propic alt="pic" />
                </div>
                <div class="manager_photos_content">
                  <h4>{{getprofileData?.FullName}}</h4>
                  <span>{{usertype}}</span>
                  <div class="manager_img_updates">
                    <input hidden type="file" #uploader (input)="uploadFile($event)" />

                    <button (click)="removepic()" class="btn_removepic">remove photo</button>
                    <button (click)="uploader.click()" class="btn_uploadpic">Upload picture</button>
                  </div>
                </div>
              </div>
              <div *ngIf="usertype == 'Agent' || usertype == 'Broker'"
                class="mp-right col-md-5 col-lg-5 col-xl-5 col-12">
                <p>Uploaded Agreement Detail</p>
                <span><img src="../../../assets/images/clients/pdf_normal.png" alt="pic"><b>Agreeement.pdf</b>
                  <button (click)="getPDFLinkdownload()" class="btn_uploadpic">Download</button>
                </span>
              </div>
            </div>
          </div>
          <div class="apex-title-lilblue">
            <h3>basic details </h3>
          </div>
          <div>
            <form (ngSubmit)="updateProfile()" #updatepro="ngForm">
              <div class="apexforms-wraps">
                <div class="apexforms-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="apexinput-froms">
                        <label>First Name</label>
                        <input type="text" placeholder="First Name" value="" name="firstName"
                          [(ngModel)]="userProfileupdate.firstName" />
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="apexinput-froms">
                        <label>Last Name</label>
                        <input type="text" placeholder="Last Name" value="" name="lastName"
                          [(ngModel)]="userProfileupdate.lastName" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="apexforms-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="apexinput-froms">
                        <label>Email Address</label>
                        <input type="email" placeholder="Email Address" value="" name="email"
                          [(ngModel)]="userProfileupdate.email"  />
                        <!-- <span class="forms_verified">Verified</span> -->
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="apexinput-froms">
                        <label>Phone Number</label>
                        <input type="text" mask="(000) 000-0000"  maxlength="20" placeholder="Phone Number" value="" name="phonenumber"
                          [(ngModel)]="userProfileupdate.phonenumber" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="apexforms-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="apexinput-froms">
                        <label>Date of Birth</label>
                        <!-- <input type="text" placeholder="29-Apr-2020" value="" name="" /> -->
                        <dp-date-picker theme="dp-material" name="dateofBirth"
                          [(ngModel)]="userProfileupdate.dateofBirth" [config]="datePickerConfig" required="true">
                        </dp-date-picker>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="apxbtn_finalcenter">
                <!-- <button class="btn_grey" routerLink="/superadmin/dashboard">Cancel</button> -->
                <!-- <button class="btn_grey client_remove" type="reset">Cancel</button> -->
                <button class="btn_dblue" type="submit">update</button>
              </div>
            </form>
          </div>
        </div>



        <!-- change password -->
        <form name="change_password" (ngSubmit)="change_password.form.valid && _change_password()"
          #change_password="ngForm">
          <div class="apex-white_wrap">
            <div class="apex-title-lilblue">
              <h3>Change Password</h3>
            </div>

            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Current Password<sup>*</sup></label>
                    <input [(ngModel)]="changeList.currentPassword" type="password" placeholder="Current Password"
                      name="currentPassword" #currentPassword="ngModel"
                      [ngClass]="{'is-invalid': change_password.submitted && currentPassword.invalid}" required />
                    <div *ngIf="change_password.submitted && currentPassword.invalid" class="invalid-feedback">
                      <div *ngIf=" currentPassword.errors?.required">currentPassword is Required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group placeholder-clr">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Create Password<sup>*</sup></label>
                    <input [(ngModel)]="changeList.createPassword" #createPassword="ngModel" type="password"
                      placeholder="New Password" value="" name="createPassword"
                      [ngClass]="{'is-invalid': change_password.submitted && createPassword.invalid}" required />

                    <div *ngIf="change_password.submitted && createPassword.invalid" class="invalid-feedback">
                      <div *ngIf=" createPassword.errors?.required">Password is Required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Confirm Password<sup>*</sup></label>
                    <input ngValidateEqual="createPassword" [(ngModel)]="changeList.newPassword" #newPassword="ngModel"
                      type="password" placeholder="Confirm Password" value="" name="newPassword"
                      [ngClass]="{'is-invalid': change_password.submitted && newPassword.invalid}" required />
                    <div *ngIf="change_password.submitted && newPassword.invalid" class="invalid-feedback">
                      <div *ngIf="newPassword.errors?.required">Confirm Password is Required</div>

                      <div *ngIf="(newPassword.dirty || newPassword.touched) && newPassword.invalid">
                        <p class="warning-text" *ngIf="newPassword.hasError('notEqual') && createPassword.valid">
                          Passwords Don't Match
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apxbtn_finalcenter">
              <!-- <button class="btn_grey" routerLink="/superadmin/dashboard">Cancel</button> -->
              <button class="btn_grey client_remove" type="reset">Cancel</button>
              <button class="btn_dblue" type="submit">update</button>
            </div>
          </div>

        </form>
        <!-- END -->


      </div>
    </div>
  </main>
</div>
