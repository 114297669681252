
import { email, unique, alpha, compare, prop, propArray, propObject, required } from "@rxweb/reactive-form-validators"



//  public clientData:any;

//  clientData = sessionStorage.getItem('clientData');
//  parsedData = JSON.parse(clientData);
//hello




export class property {


  @prop()
  coBorFirstName?: string = "";
  @prop()
  coBorLastName?: string = "";
  @email()
  coBorEmail?: string = "";
  @prop()
  coBorHomePhone?: string = "";
  @prop()
  coBorCellPhone?: string = "";
  @prop()
  coBorCellCarrier?: string = "";
  @prop()
  actualClosingDate?: string = "";
  @prop()
  averageValue: number = 0;
  @prop()
  borDOB?: string = "";
  @prop()
  subjectZipcode: string = "";
  @prop()
  @required()
  @email()
  borrowerEmail?: string = "";
  @prop()
  borrowerofDetails?: string = "";
  @email()
  borrowerOverviewEmail?: string = "";
  @prop()
  borrowerOverViewName?: string = "";
  @prop() cellCarrier?: string = "";
  @prop() isCoBorrower: Boolean = false;
  @prop() borrowerResided: Boolean = false;
  @prop() borrowerSameSeller: Boolean = true;
  @prop() branchId: number = 1;
  @prop() brokerAgentValue: number = 0;
  @prop() brokerRefPartyId?: string = "";
  @prop() cellPhone: string = "";
  @prop() clientId: number = 0;
  @prop() closingDate: string = "";
  @prop() createdDate?: string = "";
  @prop() cretaedBy: string = "";
  @prop() fileTypeId: number = 1;
  @prop() @required() firstName: string = "";
  @prop() @required() homePhone: string = "";
  @prop() interiorAccess: number = 0;
  @prop() isSameMailaddress: Boolean = true;
  @prop() @required() lastName: string = "";
  @prop() leadSource: string = "";
  @prop() lengthoftheTime?: string = "";
  @prop()// loanOMBId: number = 0;
  @prop() lombCompany: string = "";
  @email() lombEmail: string = "";
  @prop() lombName: string = "";
  @prop() lombphone: string = "";
  @prop() lombStatus: string = "";
  @prop() maritalstatus: number = 0;
  @prop() nameofSpouse: string = "";
  @prop() propertyAdderss: string = '';
  @prop() propertyCityName: string = "";
  @prop() propertyId: number = 0;
  @prop() propertyStateName: string = "";
  @prop() propertyTypeId: number = 0;
  @prop() realtorValue: number = 0;
  @prop() receviedDate: string = "";
  @prop() socialSecurityNo?: string = "";
  @prop() zillowValue: number = 0;

  @prop() mailingStateName: string = "";
  @prop() mailingCityName: string = "";
  @prop() mailingZipCode: string = "";
  @prop() mailingAddress?: string = "";

  @prop() sellerName?: string = "";
  @prop() sellerPhone?: string = "";
  @email() sellerEmailId?: string = "";
  @prop() sellerDOB?: string = "";
  @prop() sellerSecurityNo?: string = "";
  @prop() sellerMaritalStatus?: string = "";

  @prop() borrowerOverViewFirstName: string = "";
  @prop() borrowerOverViewLastName: string = ""



}


        // propertyId: number = 0;
        // propertyTypeId: number = 0;
        // clientId: number ;
        // branchId?: number ;
        // loanOMBId?: number;
        // fileTypeId: number = 1;
        // fileStatusId?: number=1;
        // fileSubstatusId?: number=1;
        // leadSource: string = "";
        // brokerRefPartyId: number = 0;
        // receviedDate: string = "";

        // /**change */
        // actualClosingDate: string = "";
        // closingDate:string="";
        // propertyAdderss:string="";
        // /**change */
        // createdDate:string="";
        // isActive: string = "";
        // isDeleted: Boolean = true;
        // branchName: string = "";
        // bpname: string = "";
        // fileTypeName: string = "";
        // lombName: string = "";
        // brokerAgentValue: number = 0;
        // zillowValue: number = 0;
        // realtorValue: number = 0;
        // averageValue: number = 0;
        // interiorAccess: number = 0;
        // firstName: string = "";
        // lastName: string = "";
        // borrowerEmail: string = "";
        // homePhone: string = "";
        // cellPhone: string = "";
        // cellCarrier: string = "";
        // isCoBorrower: Boolean = true;
        // // coBFirstName: string = "";
        // // coBLastName: string = "";
        // // coBEmail: string = "";
        // // coBHomePhone: string = "";
        // lomBphone: string = "";
        // lombEmail: string = "";
        // // coBCellPhone: string = "";
        // // coBCellCarrier: string = "";
        // isSameMailaddress: Boolean = true;
        // borrowerSameSeller:Boolean = true;
        // // address: string = "";
        // zipCode: string = "";
        // lenofTimeatProperty: string = "";
        // borDOB: string = "";
        // socialSecurityNo: string = "";
        // maritalstatus: number = 0;
        // nameofSpouse: string = "";
        // clientPropertyId: any ="";
        // lombStatus: number = 0;
        // lombCompany: string = "";
        // borrowerOverViewName: string="";
        // borrowerOverviewEmail: string="";
        // borrowerofDetails: string;
        // propertyCityId: number = 0;
        // propertyStateId: number = 0;
        // borrowerResided: Boolean = true;
        // isSeller: Boolean = true;
        // cretaedBy: string = "";
