/**ACCEPTED LEAD */
import { Component, OnInit } from '@angular/core';

import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { DatePipe } from '@angular/common';

//**service */
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
//**MODEL */
import { leadmodel, HomeOwner, leadAssign } from '../../models/lead.model';

@Component({
  selector: 'app-lead-accepted-edit',
  templateUrl: './lead-accepted-edit.component.html',
  styleUrls: ['./lead-accepted-edit.component.css']
})
export class LeadAcceptedEditComponent implements OnInit {

  // leads? :leadmodel;
  nestedFormGroup: any = FormGroup;
  datePickerConfig = { 'format': 'MM/DD/YYYY', disableKeypress: true };
  id: any;
  countrylist: any;
  statelist: any;
  citylist: any;
  userTypes: any;
  userLists: any;
  public usertype: any;
  myuserTypeId: any;

  constructor(
    private datePipe: DatePipe,
    private route: Router,
    private tokenStorage: TokenService,
    private _manager_service: ManagerService,
    private activated_route: ActivatedRoute,
    private dropDown: DropdownService,
    private formBuilder: RxFormBuilder,
    private toastr: ToastService) { }



  ngOnInit() {
    this.usertype = this.tokenStorage._get_userRole();
    this.myuserTypeId = this.tokenStorage._userTypeId();

    let leads = new leadmodel();
    leads.homeOwner = new HomeOwner();

    let leadchild = new leadAssign();
    leads.leadChildAssign = new Array<leadAssign>();

    leads.leadChildAssign.push(leadchild);

    /**GET ROUTE PARAM ID */

    this.activated_route.params.subscribe((param: any) => {
      this.id = param['id'];
      if (param['id'] != null || undefined) {
        this.getLead_byid(param['id'])
        this.getupdateLeadLastOpenedDetails(param['id'])
      }
    });
    // console.log(this.leads)

    this.nestedFormGroup = this.formBuilder.formGroup(leads);
    console.log(this.nestedFormGroup.value, "formvalue")
    this.getCountry();

  }
  /**END */

  /** GET LEAD BY ID */
  getLead_byid(id: any) {
    this._manager_service.getLead_Byid(id).subscribe((getLead: any) => {
      //getting null value
      this.nestedFormGroup.setValue(getLead.getData)
      // this.nestedFormGroup = this.formBuilder.formGroup(getLead.getData);
      console.log(getLead.getData)
    })
  }
  /**END */

  getupdateLeadLastOpenedDetails(leadid: any) {
    let leadObj = {
      "currentUserId": parseInt(this.myuserTypeId),
      "leadId": parseInt(leadid)
    }
    console.log(leadObj, 'dummyLead')
    this._manager_service.getUpdateLeadLastOpenedDetails(leadObj).subscribe((data: any) => {
      console.log(data, 'LeadList');
    })
  }

  /**Add ,Update LEAD  */
  addLead() {
    console.log(this.id)
    console.log('addLead', this.nestedFormGroup)
    var reqObj = this.nestedFormGroup.value;
    reqObj["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')
    reqObj['leadId'] = this.id || 0;
    reqObj.homeOwner.stateId = parseInt(reqObj.homeOwner.stateId);
    reqObj.homeOwner.emailId = reqObj.email1;
    reqObj.stateId = parseInt(reqObj.stateId);
    reqObj.countryId = parseInt(reqObj.countryId);
    reqObj.leadChildAssign[0].userTypeId = parseInt(reqObj.leadChildAssign[0].userTypeId);
    reqObj.leadChildAssign[0].userId = parseInt(reqObj.leadChildAssign[0].userId);
    //** if Update by id */
    if (this.id) {
      // alert("update")
      this._manager_service.update_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {
          this.toastr.showSuccess("Lead Created");
          this.route.navigate(['\lead-list'])
        } else { this.toastr.showError(data.message); }
      })
    }
    //** else Add  */
    else {
      // alert("create")
      this._manager_service.add_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {
          this.toastr.showSuccess("Lead Updated");
          this.route.navigate(['\lead-rejected-list'])
        } else {
          this.toastr.showError(data.message);
        }
      })
    }
  }
  /** END */




  /**select dropdown */
  /**get Country */
  getCountry() {
    this.dropDown._getAll_country().subscribe((data: any) => {
      this.countrylist = data.listData;
      console.log(this.countrylist, "compo")
    })

    this.dropDown._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      console.log(this.userTypes, "ji")
    })

  }


  /** END */
  select_dropdown(id: any, selecteddata: any) {
    // let ids = parseInt(id.target.value);
    // console.log(ids)
    if (selecteddata == 'state') {
      this.dropDown._getAll_state(id.target.value).subscribe((stateList: any) => {

        this.statelist = stateList.listData;
        console.log(stateList)
      })
    }
    else if (selecteddata == 'city') {
      this.dropDown._getAll_city(id.target.value).subscribe((cityList: any) => {

        this.citylist = cityList.listData;
        console.log(this.citylist)
      })
    }

    else if (selecteddata == 'userType') {
      this.dropDown._get_Userlist(id.target.value).subscribe((getuserlist: any) => {
        this.userLists = getuserlist.listData;
        console.log(this.userLists, "dsdsdsf")
      })
    }
  }

  /**end Dropdown */

}
