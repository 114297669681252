<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="LOGIN HISTORY">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap login version">
          <div class="filterapx_wrap report_wrap_fil">
            <div class="filter_setting_forms">
              <h3>Filter By</h3>
              <!-- <div class="apxsilsetting_group">
                <label>Select role</label>
                <input type="text" value="Agent">
              </div>
              <div class="apxsilsetting_group">
                <label>agent Name</label>
                <input type="text" value="George Bailey">
              </div>
              <div class="apxsilsetting_group">
                <label>Task Updated Date</label>
                <input type="text" value="07 / 09 / 2020">
              </div> -->
              <div class="apxsilsetting_group">
                <select class="form-control" [(ngModel)]="filterObj.usertypeId">
                  <option [value]=0>--All--</option>
                  <option *ngFor="let summary of summaries" [ngValue]=summary.userTypeId>
                    {{summary.userTypeName}}
                  </option>
                </select>
              </div>
              <div class="apxsilsetting_group">
                <select class="form-control" [(ngModel)]="filterObj.days">
                  <option [ngValue]=0>--All--</option>
                  <option [ngValue]=1>30</option>
                  <option [ngValue]=2>90</option>
                  <option [ngValue]=3>180</option>
                  <option [ngValue]=4>240</option>
                  <option [ngValue]=5>Date Range</option>
                </select>
              </div>
              <div class="apxsilsetting_group " *ngIf="filterObj.days == 5">
                <label class="report_date">From Date</label>
                <dp-date-picker theme="dp-material" [(ngModel)]="filterObj.fromdate" [config]="datePickerConfig"
                  required="true">
                </dp-date-picker>
              </div>
              <div class="apxsilsetting_group" *ngIf="filterObj.days == 5">
                <label class="report_date">To date</label>
                <dp-date-picker theme="dp-material" [(ngModel)]="filterObj.todate" [config]="datePickerConfig"
                  required="true">
                </dp-date-picker>
              </div>
            </div>
            <div class="filter_setting">
              <ul>
                <!-- <li data-toggle="modal" data-target="#filterby" class="filter_apx"><img
                    src="../../../assets/images/filter.png" alt="filter"></li> -->
                <li (click)="getallUserLonginHistoryAuditList()" class="filter_apx"><img
                    src="../../../assets/images/filter.png" alt="filter"></li>
                <li (click)="onReset()" class="setting_reset"><img src="../../../assets/images/setting_reset.png"
                    alt="filter"></li>
              </ul>
            </div>

          </div>

        </div>

        <div class="apextable_select login_selected login_hist">
          <!-- <select (change)="filterForeCasts($event)"> -->

          <div class="sidebar-search">
            <div>
              <div class="input-group">
                <div class="input-group-append">
                </div>
                <input type="search" class="form-control search-menu" placeholder="Search..."
                  (input)="searchLoginHistory(searchkey.value)" #searchkey>
              </div>
            </div>
          </div>
          <div class="apxtable_headbtn">


            <button (click)="downloadExcelHistory()" class="btn-export-down"><svg width="20" height="20"
                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.525 8.45391C16.116 8.05039 15.5746 7.82812 15 7.82812C14.4199 7.82812 13.8746 8.05391 13.4645 8.46445L12.1875 9.74102V2.1875C12.1875 0.98125 11.2062 0 10 0C8.79375 0 7.8125 0.98125 7.8125 2.1875V9.74102L6.55508 8.48359C6.14492 8.07344 5.575 7.83867 4.99062 7.83867H4.9832C4.39961 7.84062 3.85664 8.0668 3.45391 8.475C3.05039 8.88398 2.82812 9.42539 2.82812 10C2.82812 10.5801 3.05391 11.1254 3.46445 11.5355L8.7293 16.8008C9.07969 17.1512 9.53984 17.3262 10 17.3262C10.4602 17.3262 10.9203 17.1512 11.2707 16.8008L16.5164 11.5551C16.9281 11.1434 17.1633 10.5703 17.1613 9.9832C17.1594 9.39961 16.9332 8.85664 16.525 8.45391Z"
                  fill="#9B59B6" />
                <path
                  d="M19.375 20H18.4375C18.0923 20 17.8125 19.7202 17.8125 19.375C17.8125 19.0298 18.0923 18.75 18.4375 18.75H19.375C19.7202 18.75 20 19.0298 20 19.375C20 19.7202 19.7202 20 19.375 20Z"
                  fill="#9B59B6" />
                <path
                  d="M1.5625 20H0.625C0.279844 20 0 19.7202 0 19.375C0 19.0298 0.279844 18.75 0.625 18.75H1.5625C1.90766 18.75 2.1875 19.0298 2.1875 19.375C2.1875 19.7202 1.90766 20 1.5625 20Z"
                  fill="#9B59B6" />
                <path
                  d="M16.25 20H3.75C3.40484 20 3.125 19.7202 3.125 19.375C3.125 19.0298 3.40484 18.75 3.75 18.75H16.25C16.5952 18.75 16.875 19.0298 16.875 19.375C16.875 19.7202 16.5952 20 16.25 20Z"
                  fill="#9B59B6" />
              </svg>
              Download / Export</button>
          </div>
        </div>
        <div class="apxtable_grids_wrapper">
          <div class="apxtable_grid_table">
            <div class="table-responsive apx-responsive">
              <table class="table">
                <thead>
                  <tr>

                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')">#
                      <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                    </th>
                    <!-- <th [class.active]="order === 'userid'" (click)="setOrder('userid')">Role
                      <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                    </th> -->
                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')">User Name
                      <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                    </th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')">Task Updated Date & Time
                      <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span>
                    </th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')">Last updated task
                      <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>

                    <th [class.active]="order === 'userid'" (click)="setOrder('userid')">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let leadlist of leadData | orderBy: order:reverse| paginate: { itemsPerPage: 45, currentPage: p }; let i=index ">

                    <td>{{45 *(p-1)+i+1}}</td>
                    <!-- <td>
                      {{leadlist.userTypeName || leadlist.userTypeName === null ? leadlist.userTypeName : 'test'}}
                    </td> -->
                    <td [routerLink]="['/superadmin/login-history-detail']"
                      [queryParams]="{id:leadlist.userId,userName:leadlist.userName,date:this.myDate}"> {{leadlist.userName  }}</td>
                    <td>{{leadlist.date}}</td>
                    <td>{{leadlist.information }}</td>


                    <td>
                      <div class=" apx-actions ">
                        <!-- <a class="pencilleadlist"> <span class="eye "><i (click)="loginHistory(leadlist.usertypeId,leadlist.date)"
                              class="fas fa-eye"></i></span></a> -->
                        <a class="pencilleadlist"> <span class="eye "><i
                              [routerLink]="['/superadmin/login-history-detail']"
                              [queryParams]="{id:leadlist.userId,userName:leadlist.userName,date:this.myDate}" class="fas fa-eye"></i></span></a>
                      </div>
                    </td>




                  </tr>




                </tbody>

              </table>
              <div class="nodata_found_components no_data_found" *ngIf="leadData.length == 0">
                <div colspan="5">No Data found</div>
              </div>

            </div>

          </div>

        </div>


        <div class="apxtable_pagination_wrapper">
          <!-- <div class="apxtable_pagelist">
            <div class="apxlist_number_page">
              <span>Show</span>
              <input value="07" type="number" />
              <span>Entries</span>
            </div>
            <div class="pagination_gopage">
              <span>Go to Page</span>
              <input type="text" value="1" />
            </div>
          </div> -->
          <div class="apxtable_pagination">
            <!-- <ul>
              <li class="prev">Prev</li>
              <li class="active"><a href="#">1</a></li>
              <li><a href="#">2</a></li>
              <li><a href="#">3</a></li>
              <li>...</li>
              <li><a href="#">16</a></li>
              <li><a href="#">17</a></li>
              <li><a href="#">18</a></li>
              <li class="next">Next</li>
            </ul> -->
            <pagination-controls (pageChange)="p = $event"></pagination-controls>

          </div>
        </div>

      </div>
    </div>
  </main>

</div>



<!-- The Modal -->
<div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel">Cancel</button>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="filterforms_setting">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>First Name</label>
                <input type="text" placeholder="First name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Last Name</label>
                <input type="text" placeholder="Last name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Status</label>
                <select>
                  <option value="">All</option>
                  <option value="">All-1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>



<!-- The customise column Modal -->
<div class="modal" id="cust-column-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <div class="cust-modal-head">
          <h4 class="modal-title">Customize Column</h4>
          <div>
            <button type="button" class="apply-btn-g">Apply</button>
            <button type="button" class="Cancel-btn-g" data-dismiss="modal">Cancel</button></div>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body cust-column-modal-body">
        <button type="button" class="close add-col-btn-g">Add New Column</button>
        <div class="cust-modal-inner">
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g" value="Owner First Name">
            <div class="edit-icons-g">
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12"
                  viewBox="0 0 18 12" fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>

          <div class="content-mod">
            <input type="text" class="edit-box-g" value="Owner Last Name |">
            <div class="edit-icons-g yes-icon-g">
              <a href="#">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="30" height="30" rx="5" fill="#2ECC71" />
                  <g clip-path="url(#clip0)">
                    <path d="M7 15.5105L12.4791 21.0001L23 10.4896L21.4896 9L12.4791 18L8.48953 14.0105L7 15.5105Z"
                      fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="16" height="16" fill="white" transform="translate(7 7)" />
                    </clipPath>
                  </defs>
                </svg></a>

              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <rect width="30" height="30" rx="5" fill="#E74C3C" />
                  <path
                    d="M21 10.2L19.8 9L15 13.8L10.2 9L9 10.2L13.8 15L9 19.8L10.2 21L15 16.2L19.8 21L21 19.8L16.2 15L21 10.2Z"
                    fill="white" />
                </svg>
              </a>
            </div>
          </div>
          <!-- list start -->
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g" value="Owner">
            <div class="edit-icons-g">
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12"
                  viewBox="0 0 18 12" fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <!-- list end -->

          <!-- list start -->
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g dis-txt-g" value="Owner Address">
            <div class="edit-icons-g">
              <a href="#">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.99998 4.63634C11.3004 4.63634 13.2475 5.68496 14.4758 6.5648C15.9311 7.60715 16.68 8.61542 16.8742 8.99998C16.6798 9.38451 15.9311 10.3928 14.4758 11.4352C13.2475 12.315 11.3004 13.3636 8.99998 13.3636C6.69953 13.3636 4.75254 12.315 3.52417 11.4352C2.06862 10.3928 1.31999 9.38451 1.12552 8.99998C1.31999 8.61542 2.06862 7.60715 3.52389 6.5648C4.75254 5.68496 6.69953 4.63634 8.99998 4.63634ZM8.99998 3.54541C4.02955 3.54541 0 7.90905 0 8.99998C0 10.0909 4.02955 14.4545 8.99998 14.4545C13.9704 14.4545 18 10.0909 18 8.99998C18 7.90908 13.9705 3.54541 8.99998 3.54541ZM8.99998 5.72726C7.19262 5.72726 5.72727 7.19261 5.72727 8.99998C5.72727 10.8073 7.19262 12.2727 8.99998 12.2727C10.8074 12.2727 12.2727 10.8073 12.2727 8.99998C12.2727 7.19261 10.8074 5.72726 8.99998 5.72726ZM8.11881 8.99998C7.35408 8.99998 6.73416 8.38005 6.73416 7.61533C6.73416 6.8506 7.35408 6.23071 8.11881 6.23071C8.88354 6.23071 9.50346 6.85063 9.50346 7.61533C9.50346 8.38005 8.88354 8.99998 8.11881 8.99998Z"
                    fill="#4A4E4B" />
                  <rect x="0.707153" width="24.316" height="1" transform="rotate(45 0.707153 0)" fill="#4A4E4B" />
                </svg></a>
              <a href="#" class="disabled"><svg xmlns="http://www.w3.org/2000/svg" class="dis-edit-pen-g" width="18"
                  height="18" viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#" class="disabled">
                <svg xmlns="http://www.w3.org/2000/svg" class="dis-delete-icon-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <!-- list end -->


          <!-- list start -->
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g" value="Owner City">
            <div class="edit-icons-g">
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12"
                  viewBox="0 0 18 12" fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <!-- list end -->


          <!-- list start -->
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g" value="Owner State">
            <div class="edit-icons-g">
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12"
                  viewBox="0 0 18 12" fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <!-- list end -->


          <!-- list start -->
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g" value="Owner Zip Code">
            <div class="edit-icons-g">
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12"
                  viewBox="0 0 18 12" fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <!-- list end -->


          <!-- list start -->
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g" value="Owner Phone">
            <div class="edit-icons-g">
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12"
                  viewBox="0 0 18 12" fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <!-- list end -->


          <!-- list start -->
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g" value="Primary Resident">
            <div class="edit-icons-g">
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12"
                  viewBox="0 0 18 12" fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <!-- list end -->

          <!-- list start -->
          <div class="content-mod">
            <input type="text" class="edit-box-g disable-box-g" value="Units">
            <div class="edit-icons-g">
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="eye-icon-g" width="18" height="12"
                  viewBox="0 0 18 12" fill="none">
                  <path
                    d="M8.99998 1.63634C11.3004 1.63634 13.2475 2.68496 14.4758 3.5648C15.9311 4.60715 16.68 5.61542 16.8742 5.99998C16.6798 6.38451 15.9311 7.39277 14.4758 8.43516C13.2475 9.31496 11.3004 10.3636 8.99998 10.3636C6.69953 10.3636 4.75254 9.31496 3.52417 8.43516C2.06862 7.39277 1.31999 6.38451 1.12552 5.99998C1.31999 5.61542 2.06862 4.60715 3.52389 3.5648C4.75254 2.68496 6.69953 1.63634 8.99998 1.63634ZM8.99998 0.54541C4.02955 0.54541 0 4.90905 0 5.99998C0 7.0909 4.02955 11.4545 8.99998 11.4545C13.9704 11.4545 18 7.09087 18 5.99998C18 4.90908 13.9705 0.54541 8.99998 0.54541ZM8.99998 2.72726C7.19262 2.72726 5.72727 4.19261 5.72727 5.99998C5.72727 7.80731 7.19262 9.27269 8.99998 9.27269C10.8074 9.27269 12.2727 7.80731 12.2727 5.99998C12.2727 4.19261 10.8074 2.72726 8.99998 2.72726ZM8.11881 5.99998C7.35408 5.99998 6.73416 5.38005 6.73416 4.61533C6.73416 3.8506 7.35408 3.23071 8.11881 3.23071C8.88354 3.23071 9.50346 3.85063 9.50346 4.61533C9.50346 5.38005 8.88354 5.99998 8.11881 5.99998Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="edit-pen-g" width="18" height="18"
                  viewBox="0 0 18 18" fill="none">
                  <path
                    d="M17.5205 2.9221L15.066 0.465497C14.4475 -0.153467 13.3717 -0.154656 12.7508 0.466686L1.755 11.571C1.70625 11.6202 1.67108 11.6806 1.65191 11.7469L0.0155795 17.4791C-0.0251888 17.6218 0.0147741 17.7758 0.119859 17.8809C0.197752 17.9589 0.302031 18.0009 0.40911 18.0009C0.446657 18.0009 0.484625 17.9957 0.521366 17.9853L6.24859 16.3475C6.3149 16.3284 6.37523 16.2932 6.42436 16.2444L17.5205 5.23799C17.8297 4.92852 17.9999 4.51747 17.9999 4.08006C17.9999 3.64266 17.8297 3.23156 17.5205 2.9221ZM10.9682 3.4425L12.474 4.94835L4.612 12.8104L4.04776 11.6816C3.97823 11.5429 3.83682 11.4554 3.68181 11.4554H3.02668L10.9682 3.4425ZM1.00476 16.9961L1.53762 15.1308L2.87005 16.4632L1.00476 16.9961ZM5.72724 15.647L3.76994 16.2061L1.79469 14.2309L2.35383 12.2736H3.42887L4.13398 13.6838C4.17352 13.763 4.23783 13.8273 4.31695 13.8668L5.7272 14.5719V15.647H5.72724ZM6.54544 14.9742V14.3191C6.54544 14.164 6.45796 14.0226 6.31931 13.9531L5.19046 13.3889L13.0525 5.52686L14.5583 7.03271L6.54544 14.9742ZM16.9432 4.66911L15.1394 6.45682L11.544 2.86143L13.3305 1.05884C13.6398 0.749609 14.1783 0.749609 14.4875 1.05884L16.942 3.51337C17.0966 3.66797 17.1817 3.87331 17.1817 4.09184C17.1817 4.31037 17.0966 4.5157 16.9432 4.66911Z"
                    fill="#4A4E4B" />
                </svg></a>
              <a href="#">
                <svg xmlns="http://www.w3.org/2000/svg" class="delete-icon-g" width="18" height="18" viewBox="0 0 18 18"
                  fill="none">
                  <g clip-path="url(#clip0)">
                    <path d="M12.4302 6.20598L11.0212 6.1543L10.7157 14.4963L12.1247 14.5478L12.4302 6.20598Z"
                      fill="#4A4E4B" />
                    <path d="M9.70495 6.18018H8.29504V14.5222H9.70495V6.18018Z" fill="#4A4E4B" />
                    <path d="M7.2838 14.4961L6.97833 6.15405L5.56934 6.20577L5.87484 14.5478L7.2838 14.4961Z"
                      fill="#4A4E4B" />
                    <path
                      d="M0.61084 2.70239V4.1123H2.07998L3.24548 17.3569C3.27744 17.7209 3.58225 18 3.94762 18H14.0283C14.3937 18 14.6988 17.7207 14.7305 17.3566L15.896 4.1123H17.3889V2.70239H0.61084ZM13.3826 16.5901H4.59316L3.4953 4.1123H14.4807L13.3826 16.5901Z"
                      fill="#4A4E4B" />
                    <path
                      d="M11.3028 0H6.69702C6.04916 0 5.52209 0.527062 5.52209 1.17492V3.40731H6.932V1.40991H11.0678V3.40731H12.4777V1.17492C12.4777 0.527062 11.9506 0 11.3028 0Z"
                      fill="#4A4E4B" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <!-- list end -->


        </div>
      </div>

    </div>
  </div>
</div>

<!-- end  customise column Modal -->
