/**ACCEPTED LEAD */
import { Component, OnInit } from '@angular/core';

import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { DatePipe } from '@angular/common';

//**service */
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';

//**MODEL */
import { leadmodel, HomeOwner, leadAssign } from '../../models/lead.model';
import { validation } from '../../models/lead.validation';
import { ComponentCanDeactivate } from 'src/app/can-deactivate.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-lead-rejected-edit',
  templateUrl: './lead-rejected-edit.component.html',
  styleUrls: ['./lead-rejected-edit.component.css']
})
export class LeadRejectedEditComponent implements OnInit,ComponentCanDeactivate {
  leads?: leadmodel;
  public validations = new validation();
  nestedFormGroup: any = FormGroup;
  datePickerConfig = { 'format': 'M/D/YYYY', disableKeypress: true };
  id: any;
  countrylist: any;
  statelist: any;
  citylist: any;
  userTypes: any;
  userLists: any;
  homeUserId: any;
  public usertype: any;
  constructor(
    private datePipe: DatePipe,
    private route: Router,
    private tokenStorage: TokenService,
    private _manager_service: ManagerService,
    private activated_route: ActivatedRoute,
    private dropDown: DropdownService,
    private formBuilder: RxFormBuilder,
    private toastr: ToastService) { }
  canDeactivate(): boolean | Observable<boolean> {
    return !this.nestedFormGroup.dirty
  }

  //

  ngOnInit() {
    this.usertype = this.tokenStorage._get_userTypeId();

    /**GET ROUTE PARAM ID */
    this.activated_route.params.subscribe((param: any) => {
      this.id = param['id'];
      if (param['id'] != null || undefined) {
        this.getLead_byid(param['id'])
        this.getupdateLeadLastOpenedDetails(param['id'])
      }
    });
    let leads = new leadmodel();
    leads.homeOwner = new HomeOwner();
    let leadchild = new leadAssign();
    leads.leadChildAssign = new Array<leadAssign>();

    leads.leadChildAssign.push(leadchild);
    this.nestedFormGroup = this.formBuilder.formGroup(leads);
    this.getCountry();

  }
  /**END */

  getupdateLeadLastOpenedDetails(leadid: any) {
    let leadObj = {
      "currentUserId": parseInt(this.usertype),
      "leadId": parseInt(leadid)
    }
    console.log(leadObj, 'dummyLead')
    this._manager_service.getUpdateLeadLastOpenedDetails(leadObj).subscribe((data: any) => {
      console.log(data, 'LeadList');
    })
  }

  get leadChilds() { return <FormArray>this.nestedFormGroup.controls.leadChildAssign; }
  /** GET LEAD BY ID */
  getLead_byid(id: any) {
    this._manager_service.getLead_Byid(id).subscribe((getLead: any) => {

      this.select_dropdown(1, "state", false);
      this.select_dropdown(getLead.getData.homeOwner.stateId, 'city', false);
      this.homeUserId = parseInt(getLead.getData.homeOwner.homeUserId);
      // getLead.getData.homeOwner.
      this.nestedFormGroup.controls.homeOwner.patchValue({ "countryId": 1 });
      this.nestedFormGroup.controls.homeOwner.patchValue(getLead.getData.homeOwner);
      this.nestedFormGroup.patchValue(getLead.getData);
      this.leadChilds.push(this.formBuilder.group({ "userTypeId": getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userTypeId, "userId": getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userId }))

      console.log(this.nestedFormGroup.value, "dddddddd")
      console.log(getLead.getData)

    })
  }
  /**END */

  /**Add ,Update LEAD  */
  addLead() {
    console.log(this.id)
    console.log('addLead', this.nestedFormGroup)
    var reqObj = this.nestedFormGroup.value;
    reqObj['leadId'] = parseInt(this.id) || 0;
    reqObj.homeOwner.stateId = parseInt(reqObj.homeOwner.stateId);
    reqObj.homeOwner.emailId = reqObj.email1;
    reqObj.homeOwner.cityId = parseInt(reqObj.homeOwner.cityId);
    reqObj.cityId = parseInt(reqObj.cityId);
    reqObj.stateId = parseInt(reqObj.stateId);
    reqObj.countryId = parseInt(reqObj.countryId);
    reqObj['createdBy'] = this.usertype;
    reqObj.leadChildAssign[0].userTypeId = parseInt(reqObj.leadChildAssign[0].userTypeId);
    reqObj.leadChildAssign[0].userId = parseInt(reqObj.leadChildAssign[0].userId);
    reqObj["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')
    if (reqObj.leadChildAssign[0].userTypeId == 0 && reqObj.leadChildAssign[0].userId == 0) {
      reqObj.leadChildAssign = [];
    }
    //** if Update by id */
    if (this.id) {
      
      this._manager_service.update_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {
          this.toastr.showSuccess("Lead Created");
          this.route.navigate(['\lead-list'])
        } else { this.toastr.showError("Error Occured"); }
      })
    }
    //** else Add  */
    else {
      
      this._manager_service.add_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {
          this.toastr.showSuccess("Lead Updated");
          this.route.navigate(['\lead-list'])
        } else {
          this.toastr.showError("Error Occured");
        }
      })
    }
  }
  /** END */


  getCountry() {
    this.dropDown._getAll_country().subscribe((data: any) => {
      this.countrylist = data.listData;
      console.log(this.countrylist, "compo")
    })

    this.dropDown._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      console.log(this.userTypes, "ji")
    })

  }



  select_dropdown(id: any, selecteddata: any, htmlupdate?: Boolean) {
    let targetid;
    if (htmlupdate) {
      targetid = parseInt(id.target.value);

    } else {
      targetid = id;
    }


    if (selecteddata == 'state') {
      this.dropDown._getAll_state(targetid).subscribe((stateList: any) => {

        this.statelist = stateList.listData;
        console.log(stateList)
      })
    }
    else if (selecteddata == 'city') {
      this.dropDown._getAll_city(targetid).subscribe((cityList: any) => {

        this.citylist = cityList.listData;
        console.log(this.citylist)
      })
    }

    else if (selecteddata == 'userType') {
      this.dropDown._get_Userlist(targetid).subscribe((getuserlist: any) => {
        this.userLists = getuserlist.listData;
        console.log(this.userLists, "dsdsdsf")
      })
    }
  }

  /**end Dropdown */



}
