import { Component, OnInit,ViewChild } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
import { DropdownService } from '../../service/dropdown.service';
import { TokenService } from '../../service/token.service';

declare var jQuery: any;
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-manager-list-edit',
  templateUrl: './manager-list-edit.component.html',
  styleUrls: ['./manager-list-edit.component.css']
})
export class ManagerListEditComponent implements OnInit {
  // tooglePassword: boolean;
@ViewChild(NgForm) addEdit:NgForm;
  toogleConPassword: boolean;
  // tooglerePassword: boolean;
  addAgent: any = {};
  addManager: any = {role:""};
  getManagerlist: any = {};
  id: any = {};
  updateManager: any = false;
  message: any;
  getRoleval: any;
  formTypeList: any;
  stateList: any;
  cityList: any;
  agentListCompany: any = [];
  agentListManager: any = [];
  agentTypes: any = [];
  companyNameShow = false;
  titleListShow = true;
  agentTypeshow = false;
  addManagerforBroker = false;
  addManagerforAgent = false;
  myuserType: any;
  agentManagerIdDetails: any;
  showSiteContactbroker: Boolean = true;
  showSiteContactagent: Boolean = true;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  optionsSelectRole: any;
  superAdminUser = [{ 'link': '/Managers/Add', 'usertype': 'Manager' }, { 'link': '/Agents/Add', 'usertype': 'Agent' }, { 'link': '/Brokers/Add', 'usertype': 'Brokers' }, { 'link': '/Negotiators/Add', 'usertype': 'Negotiators' }, { 'link': '/AsstNegotiators/Add', 'usertype': 'Negotiator Assistant' }, { 'link': '/Closers/Add', 'usertype': 'Closers' }]

  ManagerUser = [{ 'link': '/Agents/Add', 'usertype': 'Agent' }, { 'link': '/Brokers/Add', 'usertype': 'Brokers' }]

  BrokerUser = [{ 'link': '/Managers/Add', 'usertype': 'Manager' }, { 'link': '/Agents/Add', 'usertype': 'Agent' }, { 'link': '/Brokers/Add', 'usertype': 'Brokers' }, { 'link': '/Negotiators/Add', 'usertype': 'Negotiators' }, { 'link': '/AsstNegotiators/Add', 'usertype': 'Negotiator Assistant' }, { 'link': '/Closers/Add', 'usertype': 'Closers' }]
  UseruserTypeId: string;
  agentListCompanyBackup: any;
  agentListManagerBackup: any;
  termsacceptedDate: string;
  
  managerIdvalue: boolean=false;
  agentmanagerIdvalue: boolean=false;
  constructor( private ngform:NgForm,private datepipe:DatePipe,private tokenStorage: TokenService, private _dropDownService: DropdownService, private toast: ToastService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) {
    // this.tooglePassword = false;
    this.toogleConPassword = false;
    // this.tooglerePassword = false;

    this.myuserType = this.tokenStorage._get_userRole();
  }

  ngOnInit(): void {
    console.log(this.ngform)
    // jQuery("#phone").keyup(function () {
    //   jQuery("#phone").val(this.value.match(/[0-9]*/));
    // });
    this.addManager.active = true;
    this._getAll_states();

    if (this.myuserType == 'SuperAdmin') {

      this.optionsSelectRole = this.superAdminUser;
    }

    else if (this.myuserType == 'Manager') {
      this.optionsSelectRole = this.ManagerUser;
    }

    else if (this.myuserType == 'Broker') {
      this.optionsSelectRole = this.BrokerUser;
    }


    this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // let id = this._manager_service.getId();
    if (this.id !== null || undefined) {
      this.updateManager = true;
      this._getManager_byId(this.id)
    }


    this._manager_service._get_All_agentType().subscribe((options: any) => {
      // console.log(options.listData)
      this.agentTypes = options.listData;

    })


    // this._getAll_states();
    this._getallAgentCompany();
    this._getallAgentManager();

  }



  agentManager(e: any) {
    this.agentManagerIdDetails = e?.value;
    console.log(this.agentManagerIdDetails, 'details')
    // console.log(e, 'details')
  }
  _create_manager() {
    // debugger
   this.addManager["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');
   
    if (this.updateManager) {
      let rolesUpdate: any;
      let getuserrole: any;

      getuserrole = sessionStorage.getItem('roles')
      console.log(this.addManager, this.getRoleval, "cheking")
      //   this.addManager.agentManagerId = Number(this.addManager.agentManagerId);
      this.addManager['agentCompanyId'] = Number(this.addManager.agentCompanyId);


      let boolValue = (/true/i).test(this.addManager.active);
      this.addManager['active'] = boolValue;
      this.addManager['agentTypeId'] = parseInt(this.addManager.agentTypeId);



      // console.log(this.addAgent.companyId, 'select i')

      this.addManager.createdBy = sessionStorage.getItem('userTypeId');
      //  this.addManager['managerId'] = Number(this.addManager.managerId);

      rolesUpdate = getuserrole.substring(0, getuserrole.lastIndexOf("/") + 1);

      console.log(rolesUpdate + 'Update', "updation")
      this._manager_service._update_User(rolesUpdate + 'Update', this.addManager).subscribe((data: any) => {
        this.addEdit.form.markAsUntouched();

        if (data.reponse == true && data.message == "kindly enter correct domain mail Id") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id");
          this.addEdit.form.markAsUntouched();
        } else {
          // console.log("update", this.message['message'])
          this.toast.showSuccess(data.message);
          this.addEdit.form.markAsUntouched();
          // this.route.navigate(['/superadmin/manager-list']);
        }


      }, err => {
        this.toast.showError("something Went wrong");
        console.log("something Went wrong")
      })



    }



    else {
      debugger;
      if(this.getRoleval != '/Brokers/Add'){
        let boolValue = (/true/i).test(this.addManager.active);
        this.addManager['active'] = boolValue;
        this.addManager['agentTypeId'] = parseInt(this.addManager.agentTypeId);
        this.addManager['agentManagerId'] = Number(this.agentManagerIdDetails);
        // this.addManager.agentManagerId = Number(this.addManager.agentManagerId);
        this.addManager.agentCompanyId = Number(this.addManager.agentCompanyId);
        this.addManager.createdBy = sessionStorage.getItem('userTypeId');
        ///this.addManager['managerId'] = Number(this.addManager.managerId);
        this.add_up_manager()
      }

     else  if (this.getRoleval == '/Brokers/Add') {
        this.addManager['managerId'] = Number(this.addManager.managerId);
        if( this.addManager['managerId']){
          this.managerIdvalue=true
          this.add_up_manager()
        }
        else{
          this.managerIdvalue=false
        }
      } else if (this.getRoleval == '/Agents/Add') {
        this.addManager['agentManagerId'] = Number(this.agentManagerIdDetails);
        if(  this.addManager['agentManagerId']){
          this.agentmanagerIdvalue=true
          this.add_up_manager()
        }
        else{
          this.agentmanagerIdvalue=false
        }
        //  this.addManager['agentManagerId'] = Number(this.addManager.agentManagerId);
      }


    }
  }


add_up_manager(){
  this._manager_service._create_User(this.getRoleval, this.addManager).subscribe((data: any) => {
    console.log("add", data)
    this.message = data;
    this.addEdit.form.markAsUntouched();
    if (data.reponse == true && data.message == "kindly enter correct domail mail Id") {
      this.toast.showError("Kindly Enter Valid Domain Mail Id")
    } else {
      // console.log("update", this.message['message'])
      this.toast.showSuccess(data.message)
      this.route.navigate(['/superadmin/manager-list']);
    }
  }, err => {
    this.toast.showError('something went wrong')
    // alert("something went wrong")
  })
}

  _getManager_byId(id: number) {
    this._manager_service._get_managerByid(id).subscribe((data: any) => {
      //  console.log(data['getData'])
      this.addManager = data['getData'];
     this.termsacceptedDate=data['getData']?.isTermsCheckedDate;
   console.log(this.addManager)
      if (this.addManager.userTypeId == '2') {
        sessionStorage.setItem('roles', '/Managers/Add');

        this.UseruserTypeId = 'Manager';
      }
      else if (this.addManager.userTypeId == '3') {
        sessionStorage.setItem('roles', '/Brokers/Add');
        this.UseruserTypeId = 'Broker';
      }
      else if (this.addManager.userTypeId == '4') {
        sessionStorage.setItem('roles', '/Agents/Add');
        this.UseruserTypeId = 'Agent';
      }
      else if (this.addManager.userTypeId == '5') {
        sessionStorage.setItem('roles', '/Negotiators/Add');
        this.UseruserTypeId = 'Negotiator';
      }
      else if (this.addManager.userTypeId == '6') {
        sessionStorage.setItem('roles', '/AsstNegotiators/Add');
        this.UseruserTypeId = 'Negotiator Assitant';
      }
      else if (this.addManager.userTypeId == '7') {
        sessionStorage.setItem('roles', '/Closers/Add');
        this.UseruserTypeId = 'Closer';
      }
      else if (this.addManager.userTypeId == '8') {
        sessionStorage.setItem('roles', '/HomeOwner/Add');
        this.UseruserTypeId = 'HomeOwner';
      }


    })


  }

  // _getAll_states() {
  //   this._dropDownService._getAll_state(1).subscribe((statelist: any) => {

  //     console.log(statelist, "statelist");
  //     this.stateList = statelist.listData;

  //   })
  // }

  // _keyPress(event: any) {
  //   const pattern = /[0-9]/;
  //   let inputChar = String.fromCharCode(event.charCode);
  //   if (!pattern.test(inputChar)) {
  //     event.preventDefault();

  //   }
  // }

  _getallAgentCompany() {
    this._manager_service.getallAgentCompany().subscribe((resp: any) => {
      this.agentListCompany = resp.listData;
      this.agentListCompanyBackup = resp.listData;
      console.log(this.agentListCompany, 'AG_Company');
    })
  }
  _getallAgentManager() {
    this._manager_service.getallAgentManager().subscribe((resp: any) => {
      this.agentListManager = resp.listData;
      this.agentListManagerBackup =  resp.listData;
      console.log(this.agentListManager, 'AG_Manager');
    })
  }
  agentTypeList(data: any) {
    console.log(data.target.value, 'typeList');
    this.formTypeList = data.target.value;

  }

  // dropdown_city(event: any) {
  //   console.log(event.target.value)
  //   // let id = event.target.value;
  //   this._dropDownService._getAll_city(event.target.value).subscribe((citylist: any) => {
  //     console.log(citylist, "cityList");
  //     this.cityList = citylist.listData;

  //   })
  // }

  _getAgent_byId(id: number) {
    this._manager_service._get_agentByid(id).subscribe((data: any) => {
      console.log(data)
      this.addManager = data.getData;
    })

  }
  onSelectRole(roleVal: any) {
    this.getRoleval = roleVal.target.value;
    sessionStorage.setItem('roles', this.getRoleval);
    console.log(this.getRoleval, "hello")




    if (this.getRoleval == '/Brokers/Add') {

      // this.showSiteContactbroker = true;
      // this.showSiteContactagent = false;
      this.titleListShow = false
      this.companyNameShow = true
      this.agentTypeshow = false
      this.addManagerforBroker = true
      this.addManagerforAgent = false

    }
    else if (this.getRoleval == '/Agents/Add') {

      // this.showSiteContactbroker = false;
      // this.showSiteContactagent = true;
      this.titleListShow = false
      this.companyNameShow = false
      this.agentTypeshow = true
      this.addManagerforBroker = false
      this.addManagerforAgent = true

    }
    else {
      this.titleListShow = true
      this.companyNameShow = false
      this.agentTypeshow = false
      this.addManagerforBroker = false
      this.addManagerforAgent = false
      this.showSiteContactbroker = false;
      this.showSiteContactagent = false;
      this.addManager.agentTypeId = null
    }



  }
  _getAll_states() {
    this._dropDownService._getAll_state(1).subscribe((statelist: any) => {

      console.log(statelist, "statelist");
      this.stateList = statelist.listData;

    })
  }


  dropdown_city(event: any) {
    console.log(event.target.value)
    // let id = event.target.value;
    this._dropDownService._getAll_city(event.target.value).subscribe((citylist: any) => {
      console.log(citylist, "cityList");
      this.cityList = citylist.listData;

    })
  }

//** AUTO SEARCH */
  onSearchUsers(e: any) {
    this.agentListCompany = this.agentListCompanyBackup.filter((item: any) => item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
  }

  onSearchManager(e:any){
    this.agentListManager = this.agentListManagerBackup.filter((item: any) => item.managerName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);

  }
//password eye icon

  
  clicktoogleConPassword() {
    // alert()
    this.toogleConPassword = !this.toogleConPassword;
  }
  canDeactivate() {
    return !this.addEdit.touched;
 }
  
}
