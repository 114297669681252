import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

//service
import { TokenService } from '../../service/token.service';
import { NotificationService } from '../../service/notification.service';
import { ToastService } from '../../service/toast.service';

import { AbstractControl, FormGroup, NgForm } from '@angular/forms';
import {CommonDataService} from 'src/app/service/common-data.service'
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.css'],
  //providers:[]
})
export class ManageNotificationComponent implements OnInit ,OnDestroy,AfterViewInit {
  @ViewChild(NgForm) notifyform: NgForm;


  messagetoSend: string = "";
  getUpdate: any;
  isImportant: Boolean = false;
  p:number= 1
  userInfo: any
  loggedUserId:any;
  //**DB MODEL FOR NOTIFICATION */
  public notificationDetails: any = {
    "notificDetails": [],
    "createdBy": sessionStorage.getItem('userTypeId')
    
  }

  formModel = {
    name: '',
    email: 'ascasc@aa.com',
    skills: [{ "id": 1, "itemName": "Angular" }]
};

  //**END */
  //**check box value in HTML */
  public checklist: any = [
    { "userTypeId": 2, "userName": "Manager", "isselected": false },
    { "userTypeId": 3, "userName": "Broker", "isselected": false },
    { "userTypeId": 4, "userName": "Agent", "isselected": false },
    { "userTypeId": 5, "userName": "Negotiator", "isselected": false },
    { "userTypeId": 6, "userName": "Negotiator Assistant", "isselected": false },
    { "userTypeId": 7, "userName": "Closer", "isselected": false },
    { "userTypeId": 8, "userName": "Homeowner", "isselected": false }
  ]
  public data: { notificDetails: { usertypeId: number; message: string; isImportant: boolean; sendBy: string; }[]; createdBy: string; createdOn: string; };
 

  noficationList:any=[]
  editObj:any= {};
  userselected: Boolean = false;
  userDetail: any=[];
  userTypeName: any;
  isDirty: boolean=false;
  dropdownList: { id: number; itemName: string; }[];
  selectedItems: { id: number; itemName: string; }[];
  dropdownSettings: { singleSelection: boolean; text: string; selectAllText: string; unSelectAllText: string; enableSearchFilter: boolean; classes: string; };
  name: string;
 
  //**END */
  constructor(private datePipe: DatePipe, 
    private http:HttpClient,  
    private commonService:CommonDataService,
    private _notificationService: NotificationService, private tokenStorage: TokenService,private toast: ToastService) { }

  ngAfterViewInit() {

  }
  ngOnInit(): void {
    
    this.userInfo = this.tokenStorage._get_userInfo();
    this.loggedUserId = JSON.parse(this.userInfo).CurrentLoggedUserId;
    //console.log(this.getUpdate, 'dataaa');
    this.getNotifications();
  //  this.notifyform.valueChanges.subscribe( () => this.isDirty = true  );
  }



  //**SEND NOTIFICATION */
   addOrUpdateNotification(notifyform?: any) {

    
    this.notificationDetails["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    this._notificationService.sendUpdatenotify(this.notificationDetails).subscribe((notifyObj: any) => {
      this.notifyform.form.markAsUntouched();
      console.log(notifyObj, 'dataget');
    if( this.commonService.id){
      this.loggedUserId=this.commonService.id;
      this.getNotifications();
    }

      if (notifyObj.reponse == true) {
        this.getNotifications();
        this.toast.showSuccess('Notication sent successfully')
   notifyform.resetForm();
       
       
      }
    }, err => {
      // alert("something went wrong");
    })
  }

 
  ngOnDestroy() {

  }

  //**END  */

  //**SELECT ROLE IN CHECKBOX notifySend = $event */
  selectRole(notifySend: any) {

    console.log(notifySend, "hi")
    this.notificationDetails.notificDetails = this.notificationDetails.notificDetails.filter((item:any)=> {
      return item.usertypeId != notifySend.userTypeId;
    });
    this.notificationDetails.notificDetails.push({ "usertypeId": notifySend.userTypeId, "message": this.messagetoSend, "isImportant": this.isImportant, "sendBy": sessionStorage.getItem('userTypeId') })
  
    if(this.notificationDetails.notificDetails.length >0){
      this.userselected = true;
    }else{
      this.userselected = false
    }
    
  }
  //**END */


  // get selectOne() {
  //   return this.checklist ? this.checklist.some((x: any) => x.isselected) : false
  // }
  Notifyupdate() {
    //alert('ssd');
    // this._notificationService.getAllNotify().subscribe((data: any) => {
    //   this.getUpdate = data.listData;

    // })
  }

  getNotifications() {
  
    var self = this;
    self._notificationService.get_notificationList(this.loggedUserId).subscribe((res: any)=> {
      console.log("res===> get_notificationList", res);
      self.noficationList = res.listData ? res.listData: [];
    })
  }

  editModel(data: any) {
 console.log("data ", data);
    var self = this;
    self.editObj = data;
  }

  updateNotification(notifyEditform:any) {
    // notifyEditform.reset();
    var self = this;
    self.editObj.createdBy = self.loggedUserId;
    self.editObj["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    console.log("self.editObj ", self.editObj);
    //update_notify
    self._notificationService.update_notify(self.editObj).subscribe((res:any)=> {
      console.log("res notifyEditform", res);
      if (res.reponse == true) {
        // notifyEditform.resetForm()
        $("#snd_msg").modal("hide");
        self.toast.showSuccess('Updated Successfully')
      }
      
    })
  }

  deleteNotification(data:any) {
    var self = this;
    data.createdBy = self.loggedUserId;
    self._notificationService.delete_notify(data).subscribe((res:any)=> {
      console.log("res deleteNotification", res);

      if (res.reponse == true) {
        self.toast.showSuccess('Deleted Successfully')
      }
      self.getNotifications();
    })
  }






  viewUser(user:any){
    var id=user.notificationId;
    this.userTypeName=user.userTypeName;
this._notificationService._viewUser(id).subscribe(res=>{
  console.log("user detail", res);
  if(res && res.listData){
    this.userDetail= res.listData
  }
})
  
  }

  canDeactivate() {
    return !this.notifyform.form.touched;
  }

}
