<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="TASKS"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>CREATE TASK TEMPLATE</h2>
          <button class="btn_to_btitle" routerLink="/superadmin/task-template">back</button>
        </div>
      </div>
      <div class="pagecontent_wrapper">
        <form [formGroup]="tasktemplateForm" (ngSubmit)="tasktemplateSubmit()">
          <div class="apex-white_wrap newheight-apx">
            <div class="apex-title-lilblue">
              <h3>TASK TEMPLATE DETAIL</h3>

            </div>
            <div class="apexforms-group lead-group prev-lead-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div class="apexinput-froms">
                    <label>Task Template Name<sup>*</sup></label>
                    <input type="text" formControlName="templateName" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.templateName.errors }" required />
                      <div *ngIf="submitted && f.templateName.invalid" class="invalid-feedback">
                        <span *ngIf="f.templateName.errors.required">Task is required</span>
                      </div>
                  </div>
                  <!--err-->
             
                  <!--err-->
                </div>
              </div>
            </div>
            <div class="apexforms-group lead-group">

              <div [formGroup]="child"
                *ngFor="let child of tasktemplateForm.controls.templatechild.controls; let i = index; let last = last;">
                <!-- <form [formGroup]="child"> -->
                <div class="table-responsive table_template_task">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td style="max-width:70px; width:70px;   padding-left: 4px;
                                    padding-right: 4px;">
                          <div class="apexinput-froms ">
                            <label>Day<sup>*</sup></label> 
                            <input type="text" formControlName="day" class="form-control" required
                              [ngClass]="{ 'is-invalid': submitted && child.controls.day.errors }" />
                              <div *ngIf="submitted && child.controls.day.errors" class="invalid-feedback">
                                <span *ngIf="child.controls.day.errors.required">day is required</span>
                              </div>
                        
                          </div>
                          <!--err-->
                     
                          <!--err-->
                        </td>
                        <td style="max-width:280px;   width:280px;   padding-left: 4px;
                                    padding-right: 4px;">
                          <div class="apexinput-froms">
                            <label>Task<sup>*</sup></label>
                            <input type="text" class="w_300" formControlName="description" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && child.controls.description.errors }" />
                              <div *ngIf="submitted && child.controls.description.errors" class="invalid-feedback">
                                <span *ngIf="child.controls.description.errors.required">Task is required</span>
                              </div>
                          </div>
                          <!--err-->
                       
                          <!--err-->
                        </td>
                        <td style="max-width:180px;   width:180px;   padding-left: 4px;
                                    padding-right: 4px;">
                          <div class="apexinput-froms">
                            <label>Select Tab<sup>*</sup></label>
                            <select formControlName="fileTabId"
                              [ngClass]="{ 'is-invalid': submitted && child.controls.fileTabId.errors }">
                              <option *ngFor="let specificfilestab of specificfileData"
                                [value]="specificfilestab.fileTabId">
                                {{specificfilestab.fileTabName}}</option>
                            </select>
                            <!--err-->
                            <div *ngIf="submitted && child.controls.fileTabId.errors" class="invalid-feedback">
                              <div *ngIf="child.controls.fileTabId.errors.required">Filename is required</div>
                            </div>
                            <!--err-->
                          </div>

                        </td>
                        <!-- <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                    padding-right: 4px;"> -->
                        <!-- <div class="apexinput-froms"> -->
                        <!-- <label>Assign to</label> -->
                        <!-- <select formControlName="userTypeId"
                              [ngClass]="{ 'is-invalid': submitted && child.controls.userTypeId.errors }">
                              <option *ngFor="let assigntab of assigntabData" [value]=assigntab.userTypeId>
                                {{assigntab.userTypeName}}</option>
                            </select> -->
                        <!--err-->
                        <!-- <div *ngIf="submitted && child.controls.userTypeId.errors" class="invalid-feedback">
                              <div *ngIf="child.controls.userTypeId.errors.required">Username is required</div>
                            </div> -->
                        <!--err-->
                        <!-- <select formControlName="assignedUserId">
                            <option *ngFor="let assigneduser of assignuserData" [value]="assigneduser.userId">
                              {{assigneduser.userName}}</option>
                          </select> -->

                        <!-- </div> -->

                        <!-- </td> -->

                        <!-- <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                    padding-right: 4px;">
                          <div class="apexinput-froms">
                            <label>Assign to</label>
                            <select formControlName="userTypeId"
                              [ngClass]="{ 'is-invalid': submitted && child.controls.userTypeId.errors }">
                              <option *ngFor="let assigntab of assigntabData" [value]=assigntab.userTypeId>
                                {{assigntab.userTypeName}}</option>
                            </select>

                            <div *ngIf="submitted && child.controls.userTypeId.errors" class="invalid-feedback">
                              <div *ngIf="child.controls.userTypeId.errors.required">Username is required</div>
                            </div>



                          </div>

                        </td> -->

                        <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                    padding-right: 4px;">
                          <div class="apexinput-froms">
                            <label>Select Priority<sup>*</sup> </label>
                            <select formControlName="priority"
                              [ngClass]="{ 'is-invalid': submitted && child.controls.priority.errors }">
                              <option value="">select</option>
                              <option value="high">High</option>
                              <option value="medium">Medium</option>
                              <option value="low">Low</option>
                            </select>
                            <!--err-->
                            <div *ngIf="submitted && child.controls.priority.errors" class="invalid-feedback">
                              <div *ngIf="child.controls.priority.errors.required">Priority is required</div>
                            </div>
                            <!--err-->

                          </div>

                        </td>
                        <td style="max-width:30px; width:30px;   padding-left: 4px;padding-right: 4px;">
                          <div class="apexinput-froms img-center tasktemp_detail">
                            <img src="../../../assets/images/clients/increment.png" (click)="increment(i+1)">
                            <img src="../../../assets/images/clients/decrement.png" *ngIf="i!=0" (click)="decrement(i)">
                          </div>
                        </td>
                      </tr>
                      <!-- <tr>
                    <td style="max-width:70px; width:70px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms ">
                        <label>Day</label>
                        <input type="text" placeholder="Day" value="5" name="" />
                      </div>
                    </td>
                    <td style="max-width:280px;   width:280px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Task</label>
                        <input type="text" class="w_300" placeholder="Day" value="Recorrect the closing statement"
                          name="" />
                      </div>
                    </td>
                    <td style="max-width:180px;   width:180px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Select Tab</label>
                        <select name="" id="">
                          <option value="">Closing Statement</option>
                          <option value="">Closing Statement</option>
                        </select>
                      </div>
                    </td>
                    <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Assign to</label>
                        <select name="" id="">
                          <option value="">Negotiator</option>
                          <option value="">Negotiator</option>
                        </select>
                      </div>
                    </td>

                    <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Select Priority </label>
                        <select name="" id="">
                          <option value="">Medium</option>
                          <option value="">Medium</option>
                        </select>
                      </div>
                    </td>
                    <td style="max-width:30px; width:30px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms img-center">
                        <img src="../../../assets/images/clients/decrement.png" alt="">
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td style="max-width:70px; width:70px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms ">
                        <label>Day</label>
                        <input type="text" placeholder="Day" value="2" name="" />
                      </div>
                    </td>
                    <td style="max-width:280px;   width:280px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Task</label>
                        <input type="text" class="w_300" placeholder="Day" value="Recorrect the closing statement"
                          name="" />
                      </div>
                    </td>
                    <td style="max-width:180px;   width:180px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Select Tab</label>
                        <select name="" id="">
                          <option value="">Closing Statement</option>
                          <option value="">Closing Statement</option>
                        </select>
                      </div>
                    </td>
                    <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Assign to</label>
                        <select name="" id="">
                          <option value="">Negotiator</option>
                          <option value="">Negotiator</option>
                        </select>
                      </div>
                    </td>

                    <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Select Priority </label>
                        <select name="" id="">
                          <option value="">Medium</option>
                          <option value="">Medium</option>
                        </select>
                      </div>
                    </td>
                    <td style="max-width:30px; width:30px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms img-center">
                        <img src="../../../assets/images/clients/decrement.png" alt="">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="max-width:70px; width:70px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms ">
                        <label>Day</label>
                        <input type="text" placeholder="Day" value="10" name="" />
                      </div>
                    </td>
                    <td style="max-width:280px;   width:280px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Task</label>
                        <input type="text" class="w_300" placeholder="Day" value="Recorrect the closing statement"
                          name="" />
                      </div>
                    </td>
                    <td style="max-width:180px;   width:180px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Select Tab</label>
                        <select name="" id="">
                          <option value="">Closing Statement</option>
                          <option value="">Closing Statement</option>
                        </select>
                      </div>
                    </td>
                    <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Assign to</label>
                        <select name="" id="">
                          <option value="">Negotiator</option>
                          <option value="">Negotiator</option>
                        </select>
                      </div>
                    </td>

                    <td style="max-width:180px;   width:180px;  padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms">
                        <label>Select Priority </label>
                        <select name="" id="">
                          <option value="">Medium</option>
                          <option value="">Medium</option>
                        </select>
                      </div>
                    </td>
                    <td style="max-width:30px; width:30px;   padding-left: 4px;
                                        padding-right: 4px;">
                      <div class="apexinput-froms img-center">
                        <img src="../../../assets/images/clients/decrement.png" alt="">
                      </div>
                    </td>
                  </tr> -->


                    </tbody>
                  </table>
                </div>
                <!-- </form> -->
                <!-- <div class="row"><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="apexinput-froms "><label>Day</label><input type="text" placeholder="Day" value="1" name=""  /></div></div><div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"><div class="apexinput-froms"><label>Day</label><input type="text" class="w_300" placeholder="Day" value="Send email to client" name=""  /></div></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"><div class="apexinput-froms"><label>Select Tab</label><select name="" id=""><option value="">Closing Statement</option><option value="">Closing Statement</option></select></div></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"><div class="apexinput-froms"><label>Assign to</label><select name="" id=""><option value="">Negotiator</option><option value="">Negotiator</option></select></div></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"><div class="apexinput-froms"><label>Select Priority </label><select name="" id=""><option value="">Medium</option><option value="">Medium</option></select></div></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 img_center"><div class="apexinput-froms"><img src="../../../assets/images/clients/increment.png" alt=""></div></div></div> -->
              </div>
            </div>
            <!--  (click)="updateTaskTemplate(leadlist)" -->
            <div class="apxbtn_finalcenter ">
              <!-- <button class="btn_grey" routerLink="/superadmin/task-template">Cancel</button> -->
              <button class="btn_dblue">Create</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</div>
