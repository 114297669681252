import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
@Component({
  selector: 'app-task-template',
  templateUrl: './task-template.component.html',
  styleUrls: ['./task-template.component.css']
})
export class TaskTemplateComponent implements OnInit {



  public leadData: any = [];

  order: string = 'leadowner';
  p: number = 1;
  reverse: boolean = false;
  masterSelected: boolean;
  checkedList: any;
  leadbyid: any;
  userTypeId: any;
  tasklength: any;
  constructor(private tokenStorage: TokenService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute, private toastr: ToastService) {
    // for (let i = 1; i <= 100; i++) {
    //   this.leadData.push(`item ${i}`);
    // }
    this.masterSelected = false;

    this.getCheckedItemList();

  }
  ngOnInit(): void {
    this.userTypeId = this.tokenStorage._get_userTypeId();
    this.gettasktemplateDetails(this.userTypeId);
    // this.leadData = [
    //  {"leadid":'Task Template 01',"leadowner":'5'},
    //  {"leadid":'Task Template 02',"leadowner":'2'},
    //  {"leadid":'Task Template 03',"leadowner":'1'},
    //  {"leadid":'Task Template 04',"leadowner":'5'},
    //  {"leadid":'Task Template 05',"leadowner":'2'},
    //  {"leadid":'Task Template 06',"leadowner":'1'},
    //  {"leadid":'Task Template 07',"leadowner":'5'},
    //  {"leadid":'Task Template 08',"leadowner":'2'},
    //  {"leadid":'Task Template 09',"leadowner":'1'},
    //  {"leadid":'Task Template 10',"leadowner":'1'}
    // ];
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }


  checkUncheckAll() {
    for (var i = 0; i < this.leadData.length; i++) {
      this.leadData[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.leadData.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.leadData.length; i++) {
      if (this.leadData[i].isSelected)
        this.checkedList.push(this.leadData[i]);
    }
    this.checkedList = JSON.stringify(this.checkedList);
  }

  gettasktemplateDetails(data: number) {
    this._manager_service.get_tasktemplateDetails(data).subscribe((res: any) => {
      console.log(res, "ttd")
      //this.leadData = res.listData;
      if (res.listData == null) {
        this.leadData = [];

      }
      else {
        this.leadData = res.listData;
        // this.tasklength = this.leadData.taskchilddetails.length;
        // console.log(this.tasklength, "88888")
      }
    })
  }




  deleteTaskTemplate(deleteData: any) {
    deleteData['createdBy'] = sessionStorage.getItem('userTypeId')
    this._manager_service.delete_tasktemplate(deleteData).subscribe((deletedData: any) => {
      console.log(deletedData, "deletedtasktemplate");
      this.gettasktemplateDetails(this.userTypeId);

      this.toastr.showSuccess("Deleted SuccessFully")

    }, err => {
      this.toastr.showError("Something Went Wrong")
    })
  }



}

