import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ManagerService } from '../../service/manager.service';
import { DropdownService } from 'src/app/service/dropdown.service';
import { ToastService } from '../../service/toast.service';
import { loamodel, asignloa } from '../../models/loa-model';

@Component({
  selector: 'app-client-signed-loa',
  templateUrl: './client-signed-loa.component.html',
  styleUrls: ['./client-signed-loa.component.css']
})
export class ClientSignedLoaComponent implements OnInit {
  loaAssignModel: any;
  public clientList: any = [];
  clientid: any;
  formvalid: Boolean = false;
  loaFormGroup: any = FormGroup;
  previouslyAssignedLead: any;
  submitted: Boolean = false;
  currentclientdata: any;
  clientAssignManager: any;
  clientAssignManagerBack: any;
  clientAssignBroker: any;
  clientAssignBrokerBack: any;
  clientAssignAgentBack: any;
  clientAssignAgent: any;
  constructor(private router: Router, private toastr: ToastService, private activateroute: ActivatedRoute, private formBuilder: RxFormBuilder, private _manager_service: ManagerService, private _dropdown_Service: DropdownService) {
    this.activateroute.params.subscribe((param: any) => {
      this.clientid = param['id'];
      console.log(this.clientid)
    })
  }
  clientAssignNego: any = [];
  clientAssignNegoBack: any = [];
  clientAssitNego: any = [];
  clientAssitNegoBack: any = [];
  clientAssignCloser: any = [];
  clientAssignCloserBack: any = [];
  assignedlist: any = [];
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true
  };

  canDeactivate() {
    return !this.loaFormGroup.touched;
 }
  ngOnInit(): void {
    let userdata: any;
    userdata = sessionStorage.getItem('clientData');



    this.currentclientdata = JSON.parse(userdata);
    console.log(this.currentclientdata);
    this.getUserList_SignedLoa();

    this.loaAssignModel = {

      "clientId": parseInt(this.currentclientdata.clientId),
      "clientAssingDetail": [
        {
          "clientAssignId": 0,
          "userId": [0 , Validators.required]
        }

      ],
      "notes": "",
      "forClosureDate": '',


    }


    this.loaFormGroup = this.formBuilder.group(this.loaAssignModel);


    if (this.currentclientdata.assignedUsers.length > 0) {

      this.previouslyAssignedLead = this.currentclientdata.assignedUsers[this.currentclientdata.assignedUsers.length - 1];



    }

    // this.loaFormGroup = this.formBuilder.group({
    //   userId: ['', Validators.required],
    //   createdBy: sessionStorage.getItem('userTypeId')
    // });

    // this.clientList = [
    //   { 'clientFileName': 'CABOA20001 (Kevin Spacey)', 'clientFileNumber': '7723 Pennsylvania ave', 'assiManager': 'Steve Job', 'neogtiator': '-', 'assNeogtiator': '-', 'clientFileStatus': 'Documents collected from ho...', 'assignDate': '25-Jul-2020', }
    // ]
  }

  getUserList_SignedLoa() {
    this._dropdown_Service._getAll_LoaUser(5).subscribe((nego: any) => {
      this.clientAssignNego = nego.listData;
      console.log("file ", this.clientAssignNego)
      this.clientAssignNegoBack = nego.listData;
    })
    this._dropdown_Service._getAll_LoaUser(6).subscribe((asstnego: any) => {
      this.clientAssitNego = asstnego.listData;
      this.clientAssitNegoBack = asstnego.listData;
      const clietAssistNeg = this.clientAssitNego.find((c:any) => c.userId === 5);
      console.log("muydas dara", clietAssistNeg)
      this.loaFormGroup.controls.clientAssingDetail.controls.get('userId').setValue(clietAssistNeg.userId);
      console.log("fff" ,this.loaFormGroup.get('userId').setValue(clietAssistNeg))

    })
    this._dropdown_Service._getAll_LoaUser(7).subscribe((closure: any) => {
      this.clientAssignCloser = closure.listData;
      this.clientAssignCloserBack = closure.listData;
    })
    this._dropdown_Service._getAll_LoaUser(2).subscribe((manager: any) => {
      this.clientAssignManager = manager.listData;
      this.clientAssignManagerBack = manager.listData;
     
    })
    this._dropdown_Service._getAll_LoaUser(3).subscribe((broker: any) => {
      this.clientAssignBroker = broker.listData;
      this.clientAssignBrokerBack = broker.listData;
    })
    this._dropdown_Service._getAll_LoaUser(4).subscribe((agent: any) => {
      this.clientAssignAgent= agent.listData;
      this.clientAssignAgentBack = agent.listData;
    })
  }
  add_signed_loa() {
    console.log(this.loaFormGroup.value)
    this.submitted = true;
    if (this.formvalid == true) {


      this.loaAssignModel = {
       "clientId": parseInt(this.currentclientdata.clientId),
        "clientAssingDetail": this.assignedlist,
        "notes": this.loaFormGroup.value.notes,
        "forClosureDate": this.loaFormGroup.value.forClosureDate,
        "createdBy": sessionStorage.getItem('userTypeId')

      }
      this._manager_service._signedLoa_Adduser(this.loaAssignModel).subscribe((assignuser: any) => {

        if (assignuser.reponse == true) {
          this.formvalid = false
          this.loaFormGroup.markAsUntouched();
          if (this.currentclientdata.isAssigned == true) {
            this.toastr.showSuccess('User Reassigned Successfully');
          } else if (this.currentclientdata.isAssigned == false) {
            this.toastr.showSuccess('User Assigned Successfully');
          }

          // this.router.navigate(['/client-file-status'],{queryParams:{id:this.clientid}})
          // this.router.navigateByUrl('/superadmin/clients-file-status', { state: { obj: this.currentclientdata } })
          this.router.navigateByUrl('/superadmin/clients');

        }

      }, err => {
        this.toastr.showError("something went wrong")
      })
    } else {
      this.loaFormGroup.markAsUntouched();
      this.formvalid = false;
      // this.loaFormGroup.controls.clientAssingDetail.controls['userId'].markAsTouched()

    }
  }
  get assign() {
    return <FormArray>this.loaFormGroup.controls.clientAssingDetail;
  }
  add_select(assignid: number) { 

    if (assignid == 5) {
      this.formvalid = true;
    }
    this.assignedlist.push({ "userId": parseInt(this.assign.value[0].userId) })

    console.log(this.assignedlist)
  }


  // onSubmit() {
  //   this.submitted = true;

  //   if (this.loaFormGroup.invalid) {
  //     return;
  //   }
  // }


  onSearchNego(e: any) {
    this.clientAssignNego = this.clientAssignNegoBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearchAssNego(e: any) {
    this.clientAssitNego = this.clientAssitNegoBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearchAssCloser(e: any) {
    this.clientAssignCloser = this.clientAssignCloserBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearchManager(e: any) {
    this.clientAssignManager = this.clientAssignManagerBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearchBroker(e: any) {
    this.clientAssignBroker = this.clientAssignBrokerBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearchAgent(e: any) {
    this.clientAssignAgent = this.clientAssignAgentBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

}
