//modules
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuperadminRoutingModule } from './superadmin-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ValidateEqualModule } from 'ng-validate-equal';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { ToastrModule } from 'ngx-toastr';
import { DpDatePickerModule } from "ng2-date-picker";
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ChartsModule } from 'ng2-charts';

import { NgxYoutubePlayerModule } from "ngx-youtube-player";
//Signature
//import { SignaturePadModule } from '@ng-plus/signature-pad';
import { NgOtpInputModule } from 'ng-otp-input';
import { OrderSuffixPipe } from '../pipes/oridinal.pipe';

//components
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ManagerListComponent } from './manager-list/manager-list.component';
import { ManagerListEditComponent } from './manager-list-edit/manager-list-edit.component';
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { BrokersListComponent } from './brokers-list/brokers-list.component';
import { BrokersListEditComponent } from './brokers-list-edit/brokers-list-edit.component';

import { AgentsDetailEditComponent } from './agents-detail-edit/agents-detail-edit.component';
import { HouseownerDetailEditComponent } from './houseowner-detail-edit/houseowner-detail-edit.component';
import { NegotiatorsEditComponent } from './negotiators-edit/negotiators-edit.component';
import { AssitnegotiatorsEditComponent } from './assitnegotiators-edit/assitnegotiators-edit.component';
import { CloserEditComponent } from './closer-edit/closer-edit.component';
import { AgentsDetailComponent } from './agents-detail/agents-detail.component';
import { HouseownerDetailComponent } from './houseowner-detail/houseowner-detail.component';
import { NegotiatorsListComponent } from './negotiators-list/negotiators-list.component';
import { AssitnegotiatorsListComponent } from './assitnegotiators-list/assitnegotiators-list.component';
import { CloserListComponent } from './closer-list/closer-list.component';
import { LeadListComponent } from './lead-list/lead-list.component';
import { LeadListEditComponent } from './lead-list-edit/lead-list-edit.component';
import { LeadRejectedListComponent } from './lead-rejected-list/lead-rejected-list.component';
import { LeadRejectedEditComponent } from './lead-rejected-edit/lead-rejected-edit.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientSignedLoaComponent } from './client-signed-loa/client-signed-loa.component';
import { ClientFileStatusComponent } from './client-file-status/client-file-status.component';
import { ClientReassignTaskComponent } from './client-reassign-task/client-reassign-task.component';
import { ClientAssignTaskComponent } from './client-assign-task/client-assign-task.component';
import { ScrollableTabsComponent } from './scrollable-tabs/scrollable-tabs.component';
import { ManageAutoGenComponent } from './manage-auto-gen/manage-auto-gen.component';
import { ManageAutoEditComponent } from './manage-auto-edit/manage-auto-edit.component';
import { ReportShortSalesComponent } from './report-short-sales/report-short-sales.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskdetailsComponent } from './taskdetails/taskdetails.component';
import { ManageVideoComponent } from './manage-video/manage-video.component';
import { ManageUploadComponent } from './manage-upload/manage-upload.component';
import { ManageNotificationComponent } from './manage-notification/manage-notification.component';
import { LoginHistoryComponent } from './login-history/login-history.component';
import { ConfiguratinSettingComponent } from './configuratin-setting/configuratin-setting.component';
import { TaskTemplateComponent } from './task-template/task-template.component';
import { TaskTemplateDetailComponent } from './task-template-detail/task-template-detail.component';
import { LoginHistoryDetailComponent } from './login-history-detail/login-history-detail.component';
import { TaskListViewComponent } from './task-list-view/task-list-view.component';
import { LeadAcceptedListComponent } from './lead-accepted-list/lead-accepted-list.component';
import { LeadAcceptedEditComponent } from './lead-accepted-edit/lead-accepted-edit.component';
import { LeadAgentLoaComponent } from './lead-agent-loa/lead-agent-loa.component';
import { LoginAuthenticationComponent } from './login-authentication/login-authentication.component';
import { LoginAgreementComponent } from './login-agreement/login-agreement.component';
import { CancelFileReportComponent } from './cancel-file-report/cancel-file-report.component';
import { SuccessRateReportComponent } from './success-rate-report/success-rate-report.component';
import { LeadSuccessRateReportComponent } from './lead-success-rate-report/lead-success-rate-report.component';
import { TrendReportComponent } from './trend-report/trend-report.component';
import { UserUsageReportComponent } from './user-usage-report/user-usage-report.component';
import { FilesperforClosureReportComponent } from './filesperfor-closure-report/filesperfor-closure-report.component';
import { ClosedFileReportComponent } from './closed-file-report/closed-file-report.component';
import { OpencloseReportComponent } from './openclose-report/openclose-report.component';
import { NumberoffileReportComponent } from './numberoffile-report/numberoffile-report.component';
import { ContactusComponent } from './contactus/contactus.component';
import { SortPipe } from '../pipes/sort.pipe';

import { UserUsageDateReportComponent } from './user-usage-date-report/user-usage-date-report.component';
import { TaskCompleteListComponent } from './task-complete-list/task-complete-list.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AssignedToAgentComponent } from './assigned-to-agent/assigned-to-agent.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CanDeactivateGuard } from '../can-deactivate.guard';
import { ConfirmationComponent } from '../confirmation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
// import { TimeAgoPipe } from 'time-ago-pipe';

// import { TimeagoModule, TimeagoIntl, TimeagoFormatter, TimeagoCustomFormatter } from 'ngx-timeago';
//module

import { ConfirmationDialogService } from '../superadmin/confirmation-dialog.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { FinancialReportComponent } from './financial-report/financial-report.component';
import { UserReportComponent } from './user-report/user-report.component';

import { FileDataReportComponent } from './file-data-report/file-data-report.component';
import { BrokerShortSaleComponent } from './broker-short-sale/broker-short-sale.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LeadDisqualifiedListComponent } from './lead-disqualified-list/lead-disqualified-list.component';
import { LeadDisqualifiedListEditComponent } from './lead-disqualified-list-edit/lead-disqualified-list-edit.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({

  imports: [
    CommonModule,
    SuperadminRoutingModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    ValidateEqualModule,
    NgxPaginationModule,
    OrderModule,
    DpDatePickerModule,
    AngularMultiSelectModule,
    SignaturePadModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    RxReactiveFormsModule,
    ChartsModule,
    NgOtpInputModule,
    NgxYoutubePlayerModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxMaskModule.forRoot(maskConfig),
    NgMultiSelectDropDownModule.forRoot(),

  ],
  exports: [
    FormsModule,
    NgxPaginationModule,
    OrderModule,
    ToastrModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDialogModule,

  ],
  providers: [SortPipe,
    ConfirmationDialogService,
    CanDeactivateGuard, OrderSuffixPipe,
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  declarations: [SortPipe, OrderSuffixPipe, LoginComponent, LeadAgentLoaComponent,

    LoginAuthenticationComponent, LoginAgreementComponent, DashboardComponent, HeaderComponent, SidebarComponent, ForgotPasswordComponent, ResetPasswordComponent, ManagerListComponent, ManagerListEditComponent, ManageProfileComponent, BrokersListComponent, BrokersListEditComponent, AgentsDetailEditComponent, HouseownerDetailEditComponent, NegotiatorsEditComponent, AssitnegotiatorsEditComponent, CloserEditComponent, AgentsDetailComponent, HouseownerDetailComponent, NegotiatorsListComponent, AssitnegotiatorsListComponent, CloserListComponent, LeadListComponent, LeadListEditComponent, LeadRejectedListComponent, LeadRejectedEditComponent, ClientsComponent, ClientSignedLoaComponent, ClientFileStatusComponent, ClientAssignTaskComponent, ClientReassignTaskComponent, ScrollableTabsComponent, ManageAutoGenComponent, ManageAutoEditComponent, ReportShortSalesComponent, TaskListComponent, TaskdetailsComponent, ManageVideoComponent, ManageUploadComponent, ManageNotificationComponent, LoginHistoryComponent, ConfiguratinSettingComponent, TaskTemplateComponent, TaskTemplateDetailComponent, LoginHistoryDetailComponent, TaskListViewComponent, LeadAcceptedListComponent, LeadAcceptedEditComponent, CancelFileReportComponent, SuccessRateReportComponent, TrendReportComponent, UserUsageReportComponent, FilesperforClosureReportComponent, ClosedFileReportComponent, OpencloseReportComponent, NumberoffileReportComponent, ContactusComponent, UserUsageDateReportComponent, TaskCompleteListComponent, AssignedToAgentComponent, ChangePasswordComponent, ConfirmationDialogComponent, ConfirmationComponent,
    LeadSuccessRateReportComponent,
    FinancialReportComponent,
    UserReportComponent,

    FileDataReportComponent,

    BrokerShortSaleComponent,

    TermsAndConditionComponent,

    PageNotFoundComponent,

    LeadDisqualifiedListComponent,

    LeadDisqualifiedListEditComponent,
],
  entryComponents: [ConfirmationDialogComponent],
})
export class SuperadminModule {
}
