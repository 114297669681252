import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-scrollable-tab-super',
  templateUrl: './scrollable-tabs.component.html',
  styleUrls: ['./scrollable-tabs.component.css']
})
export class ScrollableTabsComponent implements OnInit {
  public usertype: any;
  @Input() tabs: any;
  selectedIndex = 0;
  abc: string | undefined;
  leftTabIdx = 0;
  atStart = true;
  atEnd = false
  @Output() emitSelectedTab = new EventEmitter()
  val: number = 0;
  constructor(private tokenStorage: TokenService, private activatedroute: ActivatedRoute) { alert(1)}

  ngOnInit() {
    // alert(12)
    this.activatedroute.params.subscribe((params: any) => {
      // alert(11)
      let id;
      let type;
      console.log(params)
      //  alert(params['id'])
        //  alert(params['id'])


        if (params['id'] == 'basic' || params['id'] == 'lead') {
          // alert(1)
          this.emitSelectedTab.emit(this.tabs[2])

        }
       else {
          // alert(2)
        this.emitSelectedTab.emit(this.tabs[0])
      }
    })

    this.usertype = this.tokenStorage._get_userRole();

    this.abc = `translateX(0px)`
  }

  selectTab(index: any) {
    this.selectedIndex = index
    this.emitSelectedTab.emit(this.tabs[index])
    //this.scrollTab(index - this.leftTabIdx - 1)
  }

  scrollTab(x: number) {


    // console.log("atend initial", this.atEnd && x > 0)
    if (this.atStart && x < 0 || this.atEnd && x > 0) {
      return
    }
    this.leftTabIdx = this.leftTabIdx + x;
    this.abc = `translateX(${(this.leftTabIdx) * - 65}px)`

    this.atStart = this.leftTabIdx === 0;
    //   console.log("===", this.leftTabIdx === this.tabs.length - 1)
    this.atEnd = this.leftTabIdx === this.tabs.length - 1
    // if(this.abc=='translateX(-420px)'){
    //   this.atEnd=true
    // }else{
    //   this.atEnd=false
    // }
    //console.log("atend last", this.atEnd)
  }

}
