import { Component, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, } from '@angular/common/http';
//**FORM BUILDER*/
import { FormGroup, FormArray, NgForm } from "@angular/forms";

import { RxFormBuilder, file } from '@rxweb/reactive-form-validators';
import { PlatformLocation } from '@angular/common'

declare var $: any;

import * as moment from 'moment';

//**MODEL*/
import { clientfilestatus } from '../../models/client_filestatus.model';
import { property } from '../../models/client_property.model';
import { documentTabModel, docuDocument } from '../../models/client_document.model';
import { clientContacts, contactLenderModel, assinedStaffInfoModel } from '../../models/client_contact.model';
import { ssProposal } from '../../models/client_ss_proposal';
import { clientShortSale, lender, attornerModel, clientListinginfomodel, buyerinfomodel, clientssBuyerAgentBrokerninfo, clientsstitleinfo, clientbuyerloanoffice, clientSSOwnerattorney, paralegalinfo, escrowinfo, listinghistory } from '../../models/client_shortsale';
import { clientClosingStatementModel } from '../../models/clientClosingStatement';
import { leadmodel, HomeOwner, leadAssign } from '../../models/lead.model';
import { comissionbreakdownModel, others, Misc } from '../../models/commission_model';


//**SERVICE */
import { ManagerService } from '../../service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { DatePipe } from '@angular/common';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import { FileuploadService } from '../../service/fileupload.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ChangeDetectorRef } from '@angular/core'
import { ConfirmationDialogService } from '../confirmation-dialog.service';
import { data } from 'jquery';
import { isThisTypeNode } from 'typescript';



@Component({
  selector: 'app-client-file-status',
  templateUrl: './client-file-status.component.html',
  styleUrls: ['./client-file-status.component.css'],
  providers: [DatePipe]
})
export class ClientFileStatusComponent implements OnInit {

  public usertype: any;
 
  selectedIndex = 0;
  abc: string | undefined;
  leftTabIdx = 0;
  atStart = true;
  atEnd = false

  val: number = 0;
  imgIsEmpty:any;

clear:boolean=true;

points = [];
signatureImage1:any;
  signatureImage2: any;

showBorrower(data?:any) {
  this.signatureImage1 = data;
  console.log("sign " , this.signatureImage1)
}
showCoBorrower(data?:any) {
  this.signatureImage2 = data;
  console.log("sign2 " , this.signatureImage2)
}

  @ViewChild('updatefilename', { read: ElementRef }) myElement: ElementRef;

  @ViewChild(NgForm) docTabForm: NgForm;
  @ViewChild(NgForm) emailsending: NgForm;


  // @ViewChild(NgForm) docForm: NgForm;



  @ViewChild(NgForm) filestatusform: NgForm;
  @ViewChild(NgForm) ssForm: NgForm;
  // @ViewChild(NgForm) clientpropform: NgForm;
  // @ViewChild(NgForm) contactForm: NgForm;
  @ViewChild(NgForm) closingform: NgForm;
  // @ViewChild(NgForm) shortsalesForm: NgForm;
  // @ViewChild(NgForm) ssForm: NgForm;
  @ViewChild(NgForm) hardShipForm: NgForm;
  // @ViewChild(NgForm) commissionBreakdownForm: NgForm;
  @ViewChild("screenshotdataform") screenshots: ElementRef;
  public inviteHomeOwner: boolean = false;
  public filestabData: any = [];
  public filestabDataBack: any = [];
  taskDetailList: any = [];
  public templatetabDataBack: any = [];
  public assignuserDataBack: any = [];
  public clienttabDataBack: any = [];
  public assignedClientDataBack: any = [];
  public fileStatusTabModel: any = {};
  propertyState: any;
  stateNameProp: any;
  toEmailids: any = []
  sellerfundValues:any = ["sellerfund701",
    "sellerfund702",
    "sellerfund703",
    "sellerfund704",
    "sellerfund801",
    "sellerfund802",
    "sellerfund803",
    "sellerfund804",
    "sellerfund805",
    "sellerfund806",
    "sellerfund807",
    "sellerfund808",
    "sellerfund809",
    "sellerfund810",
    "sellerfund811",
    "sellerfund812",
    "sellerfund813",
    "sellerfund901",
    "sellerfund902",
    "sellerfund903",
    "sellerfund904",
    "sellerfund905",
    "sellerfund1001",
    "sellerfund1002",
    "sellerfund1003",
    "sellerfund1004",
    "sellerfund1005",
    "sellerfund1006",
    "sellerfund1007",
    "sellerfund1101",
    "sellerfund1102",
    "sellerfund1103",
    "sellerfund1104",
    "sellerfund1105",
    "sellerfund1106",
    "sellerfund1107",
    "sellerfund1108",
    "sellerfund1109",
    "sellerfund1110",
    "sellerfund1111",
    "sellerfund1112",
    "sellerfund1113",
    "sellerfund1201",
    "sellerfund1202",
    "sellerfund1203",
    "sellerfund1204",
    "sellerfund1205",
    "sellerfund1206",
    "sellerfund1301",
    "sellerfund1302",
    "sellerfund1303",
    "sellerfund1304",
    "sellerfund1305",
    "sellerfund1306",
    "sellerfund1307","totalsellerfund1400"]
  //task
  staticLength: number = 0;
  submitted = false;
  public templatetabData: any = [];
  addtasktemplatefrm: any = FormGroup;
  public clienttabData: any = [];
  public assignedClientData: any = [];
  public assignuserData: any = [];
  public createUsersData: any = [];
  public clientDataId: any = 0;
  public typeId: any = 0;
  public closerId: any = 0;
  public clientTask: any = [];
  public filterData: any;
  public checkedList: any;
  masterSelected: boolean;

  //task

  docuSign: any = {

  }


  public CurrentGetidLead: number | undefined;
  public acceptObj = {};
  public rejectObj = {};
  public Rejectmodel: any = {};
  openPopReject = false;
  //SignaturePad
  signatureImage: any;
  
  base64image1: any;
  userLists: any;
  //Mailing Selected Link
  private selectedLink: string = "Yes";


  public emailsendBody: any = { 'attachmentUrl': [], 'toMailIds': "" };
  public viewEmail: any = {};
  //**VARIABLE DECLARATION */
  // tab: any = ['file status', 'tasks', 'client & property', 'Documents', 'contacts', 'Closing Statements', 'Short Sales', 'SS Comp', 'Hardship', 'Commission breakdown'];
  // tabs = ['file status', 'tasks', 'client & property', 'Documents', 'contacts', 'Closing Statements', 'Short Sales', 'SS Comp', 'Hardship', 'Commission breakdown'];

  // documentTabModel?: documentTabModel;
  documentTabList: any;
  docsList: any;
  doctypes: any;
  docsStatusList: any;

  //end
  filestatusgroup: any = FormGroup;
  updateForm: Boolean = false;
  ssproposal: any = { "datapoints": {"closingCostVal":0}, "assumption": {}, "reo": {}, "req": {} };
  clientFilelist: any;
  datePickerConfig = { format: 'M/D/YYYY', disableKeypress: true };
  dateExpires = moment(new Date());

  //** model instance */
  documents = new documentTabModel();
  clientContactsmodel = new clientContacts();
  ssProposal_list = new ssProposal();
  clientfileModel = new clientfilestatus();
  property = new property();
  clientshortsalelist = new clientShortSale();
  comissionbreakdownmodel = new comissionbreakdownModel();
  closingStatementModel = new clientClosingStatementModel();

  //** END */
  /**Agent Module */
  nestedFormGroup: any = FormGroup;
  countrylist: any;
  userTypes: any;
  leads = new leadmodel();
  /** //END Agent Module */
  public clientList: any = [];
  //public clientTask: any = [];
  public documentEmailList: any = [];
  order: string = 'managersFirstname';
  p: number = 1;
  reverse: boolean = false;
  showFiles: any = [];
  //**country,state,city variable */
  statelist: any;
  citylist: any;
  branchlist: any;
  lomblist: any;
  filelist: any;
  filetab: any;
  closingTabModel?: any = {
    sellerSummaryModel: {},
    setetlementcharges: {},
    borrowerDetails: {},
    sellerDetails: {},
    lenderDetails: {}
  };
  emailsendBodyShow: any = { "attachmentUrl": [] };
  hardshipModel: any = {
    "hardShipId": 0,
    "clientId": 0,
    "shortSalesorModifications": "",
    "unEmployement": false,
    "unEmployementstr": "I am experiencing financial difficulties due to unemployment. After having my current financial situation carefully analyzed. I have concluded that it is no longer possible to comply with the original terms of the agreement. Due to the dropping of home prices thal have affect the entire country in the Iasi year. I currently owe more on my mortgage than my home is actually worth. Please consider allowing me into your Short Sale Program so that we can lower the price and sell the house quickly in order to avoid a possible foreclosure. This will allow me to settle my financial obligation to you and have a chance to get back on my feet as I am unable lo continue to pay my mortgage payments. Enclosed you will find our documents and statements that will substantiate my present economic situation.",
    "reducedIncome": false,
    "reducedIncomestr": "I am experiencing financial hardship due to reduced income. My income has dropped considerably since I first purchased the home and I therefore can no longer afford to make the monthly payments. Due to the dropping of home prices that have affect the entire country in the Iasi year, I currently owe more on my mortgage than my home is actually worth. I believe my situation will not improve in the near future and therefore request you allow me to into your Short Sale Program so that v'e can lower the price and sell the home quickly in order to avoid a possible foreclosure. Enclosed you will find our documents and statements that will substa.ntiale my present economic situation.",
    "divorce": false,
    "divorcestr": "My spouse and I have just divorced and money has been tight. We have spent most of our savings on attorneys and our family has suffered grave emotional pain. I do not think that I will be able to stabilize my financial situation and request assistance from you would greatly be appreciated. A short sale seems to be the only option at this lime.",
    "clarification1": "",
    "sepration": false,
    "seprationstr": "My spouse and I have just divorced and money has been tight. We have spent most of our savings on attorneys and our family has suffered grave emotional pain. I believe that I will be able to stabilize my financial situation shortly and assistance from you would greatly improve the chances. I will be receiving both child support and alimony,",
    "medicalBills": false,
    "medicalBillsstr": "As a result of a significant illness and expensive hospital/Medical bills. I have become delinquent in my mortgage payments. I have since recuperated and am trying to gel my affairs in order. Any assistance with my mortgage will be greatly appreciated and will allow me to get my feet back on the ground. I am currently working with my creditors in an effort to reduce my total overall monthly obligations. Thanks in advance for any consideration.",
    "clarification2": "",
    "tooMuchDept": false,
    "tooMuchDeptstr": "Over the past few years we have acquired substantial debt which at the time was affordable. We currently cannot afford to maintain our current debt load and monthly obligations. We are working with our creditors to reduce the debts and monthly payments. Among several of our main assets. our home is a mandatory asset we can no longer afford to keep. We would humbly ask that you consider reducing some of our principal. in an effort to short sale the property",
    "deathofSignificant": false,
    "deathofSignificantstr": "My spouse has just passed away and our family is simply torn apart. 'Are have lost significant income and have incurred bills as a result of this tragic loss_ Our family is sticking together and helping us with some bills. however everything is overwhelming at this point I believe it would be in our best interest to simply short sale the property, with a principal reducfion. I understand the banks are not in the business of charity.. but kindly lake into consideration our situation and the economy and provide us with some relief.",
    "hosingPayIncrease": false,
    "hosingPayIncreasestr": "When I received this mortgage, my mortgage broker promised me a low fixed rate mortgage. When I arrived for the closing, my rale was relatively low; however, it was only fixed for a short time. At the closing table. my broker told me the lender would refinance me in before the loan would reset and pressured me into signing the loan documents_ At this lime. my rate has gone way up and I cannot gel a refinance_ There is no equity in my home and the value has dropped tremendously. Please consider us for a short sale. as we see no other option make our housing payments affordable. Thank you for your assistance and cooperation_",
    "businessFailure": false,
    "businessFailurestr": "I have a new business and the current economy has been devastating. I used almost all of my savings to support my business and it is simply not generating the revenue needed to stay afloat. Asa result of the outlay in expenses and the bleeding dry of my money, I am struggling to make my mortgage payments. Kindly consider my current situation and allow me to short sale the properly_ I had every intention on making good on this loan. but at this time it does not look possible.",
    "jobRelocation": false,
    "jobRelocationstr": "As a result of an unexpected change in my employment due to a job relocation. I have fell behind in my mortgage payments. I am not able lo gel the property rented nor support 2 housing payments_ I belive the only option is to short sale the property. please provide any assistance that seems just and proper based on the foregoing. Thanks in advance.",
    "illness": false,
    "illnessstr": "As a result of a significant illness. I have become delinquent in my mortgage payments. I am trying to get my affairs in order during these difficult times but all the walls seem to be caving in. Al this time. I believe my situation will not improve any lime soon. and feel my only option is to short sale the property. I am currently working with a realtor in an effort to find the highest bidder to buy the property. Thanks in advance for any consideration.",
    "militaryService": false,
    "militaryServicestr": "As a recentlaclive military personnel, it is humbling to be in a position to ask for financial help. Unfortunately, I have no other options, as the economy has reduced many potential options for me in the work force. Our current fixed income is not sufficient lo make all of our monthly obligations. Please consider the property for a short sale. as it seems to be the only option at this point",
    "incareration": false,
    "incarerationstr": "Please be advised my spouse has just been incarcerated. There is currently a shortage in the household income making it too difficult to make the mortgage payments. My family has been assisting me. viihich has taken its toll on them. I have done all that I can in order to supplement lost income. At this time. I am requesting a short sale approval based on the submitted package. Thanks in advance",
    "lowCashReverse": false,
    "lowCashReversestr": "I am currently having a hard time making timely payments. since I have low cash reserves. I have exhausted a lot of my savings lo slay afloat, and my finances are now looking severe. Please evaluate my overall financial scenario and consider me for a loan modification. I believe with an adjusted mortgage payment. I will be able to afford my home and avoid foreclosure.",
    "negativeEquality": false,
    "negativeEqualitystr": "Due to the current devastating economy and declined housing market. I am significantly upside down on my house. It is gelling to a point where it makes sound financial sense to walk away. I am struggling lo make my mortgage payments and desperately need a loan restructuring. Kindly consider my current situation and provide me with the relief I need to stay afloat. I have every intention on making good on this loan: I just need some relief at this time.",
    "disaster": false,
    "disasterstr": "Due to the recent disaster in my region, I have suffered tremendous damage and financial loss. I am looking for relief in my mortgage payment via a loan restructuring_ Please evaluate my situation and kindly get back to me with a loan workout that provides breathing room for me financially",
    "others": false,
    "othersstr": "",
    "othrsNotes": false,
    "othrsNotesstr": "",
    "dateOfHardshipBegins": "",
    "dateOfBirth": "", 
    "situation": "",
    "duration": "",
    "contractSalesPrice": "",
    "borrowerSignature": "",
    "borrowerSignPath": "",
    "borrowerSignDate": "",
    "coBorrowerSign": "",
    "coBorrowerSignPath": "",
    "coBorrowerSignDate": "",
    "personalSituation":"We are writing this letter to explain our unfortunate set of circumstances that have resulted in extreme financial distress.", 
    "createdBy": "",
  }
  closingssDownloadurl: any; 
  clientLOADetailsList: any = [];
  public tab: any = [];
  public tabs: any = [];
  autoGenDoclists: any;
  clientContactCommon: any;
  contactDetails: any;
  emaillistings: any;
  homeUserId: any;
  currentclientData: any = {};
  clientid: any;
  docStatus: any;
  DocListNote: any;
  DoclistId: any;
  clientfileId: any;
  leadId: any;
  docTaskLists: any = [];
  assignedUsers: any;
  createUsers: any;
  userTypeId: any;
  screenshotModel: any = {};
  updateDocName: any;
  loanList: any = []
  payoffKeys: any = ['pf1of504', 'pf1of505', 'pf2of506', 'pf3of507', 'pf4of508', 'pf5of509']
  i: number = 1
  autopopulateData: any;
  propertytypelist: any;
  buyerFile: any = [];
  cmaFile: any = [];
  getTotalMiscAmount: number = 0;
  requiresbyList: any;
  updateDocDetails: boolean = false;
  checkItInfo: boolean = false;
  getFileName: any;
  updateFN: boolean = true;
  selectedColumn: any;
  getDocsListById: any;
  clientTaskID: any;
  selectAutoGenDoc: any;
  isInviteHomeOwnerDB: boolean = false;

  cityListArray: any = [
    { cityList: [] }
  ];

  attorneyCityListArray: any = [{ cityList: [] }];

  shortSaleId: number = 0;
  userinfo: any = {}
  CurrentLoggedUserId: any;
  buyerInfoDocsUrl: string;
  cmaAnalysisDocsUrl: string;
  buyerList: any = [];
  contactManagerInfo: any;
  fileFormReadonly: boolean = false;
  docList: any = [];
  realtorAddress: any = [];
  realtorAddressBack: any = [];
  selectedRealtorprice: any;
  selectedZillowPrice: any;
  userloggedIn: string | null;
  realtorPopup: boolean = false;
  zillowpopup: boolean = false;
  fileStatusid: any
  fileCloedStatus: any
  fileCloedStatusId: any
  userObject: any;
  IsDropping: boolean = false;
  autogenDocListObject: { titleId: number; folderId: number; };
  addDoctypeDetails: any;
  fileClosedStatuscheck: boolean;

  disableFileStatus: any = null;
  hardShipData: any;
  openDocPopup: boolean;
  getDocLists: any = [];
  //invite homeownermodel
  getAllHomeOwnerList: any = {};
  leadIdBeforeClient: any;
  showInviteHomeOwner: boolean = false;
  clienPropertyScratch: boolean = false;
  getLienNames: any;
  docIdDocuSign: any;
  external: boolean = false;
  both: boolean = false;

  homeOwnerId: any;
  UserTypeName: any;
  user_Role: any;
  templateForm: any;
  dirtyCheck: boolean;
  closingCostVal: string;
  netValue: number = 0;
  isCancelled: boolean=false;
  dTarget: boolean;

  //**END DECLARATION */

  constructor(private http: HttpClient, private location: PlatformLocation,
    private confirmationDialogService: ConfirmationDialogService,
    private cdr: ChangeDetectorRef,
    private activatedroute: ActivatedRoute,
    private notificationservice: NotificationService, private toastr: ToastService, private tokenStorage: TokenService, private datepipe: DatePipe, private formBuilder: RxFormBuilder, private _manageService: ManagerService, private dropdownService: DropdownService, private fileuploadService: FileuploadService, private route: Router, private activated_route: ActivatedRoute) {

    location.onPopState(() => {
      if (this.inviteHomeOwner == true && this.clienPropertyScratch == false) {
        //  alert(this.inviteHomeOwner+'hghgh'+this.clienPropertyScratch)
        // alert('client not created');

      }

    });


    // for (let i = 1; i <= 100; i++) {
    //   this.clientList.push(`item ${i}`);
    //   this.clientTask.push(`item ${i}`);
    //   this.documentEmailList.push(`item ${i}`);

    // }
  }


  ngAfterViewInit() {

    // this.getClass(this.isCancelled)  

    // if (this.clientpropform.dirty) {

    //   var req = confirm("like to leave");

    //   if (req == true) {
    //     return true;
    //   }
    //   else if (req == false) {
    //     return false;
    //   }

    // }
  }
  //** Contact tab  */
  get assignstaff() { return <FormArray>this.filestatusgroup.controls.assinedStaffInfo; }
  get contactlender() { return <FormArray>this.filestatusgroup.controls.contactLender }
  //** END */
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};

  clearsign() {
    let x: any;
    // var x = document.getElementsByClassName("btn btn-default").type="button";
    x = document.getElementsByClassName("btn btn-default");
    console.log(x)
  }

  ngOnInit() {

    // alert(this.hardshipModel.coBorrowerSignPath)
    console.log("sdfsf", this.filestatusform)
    //   this.filestatusform.form.valueChanges.subscribe((value: any) => {
    //     if (this.filestatusform.dirty) {
    //         console.log('template form dirty - yes: ', value);
    //     } else {
    //         console.log('template form dirty - no: ');
    //     }
    // });


    // alert('oninit')

    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.closingTabModel.settlementDate = this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString();
    this.closingTabModel.sellerSummaryModel.dailyInrestChargeFrom = this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString();
    this.closingTabModel.sellerSummaryModel.dailyInrestChargeTo = this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString();
    this.closingTabModel.sellerSummaryModel.mortageInsurencefrom = this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString();
    this.closingTabModel.sellerSummaryModel.mortageInsurenceTo = this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString();
    this.closingTabModel.sellerSummaryModel.homeOwnerIncFrom = this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString();
    this.closingTabModel.sellerSummaryModel.homeOwnerIncTo = this.datepipe.transform(new Date(), 'MM/dd/yyyy')?.toString();
    this.activated_route.params.subscribe((params: any) => {
      let id;
      let type;
      console.log(params)
      //  alert(params['id'])
      if (params['id'] != null || params['id'] != undefined) {
        //  alert(params['id'])

        this.showInviteHomeOwner = true;
        if (params['id'] == 'basic') {
          this.inviteHomeOwner = true;

        } else if (params['id'] == 'lead') {
          this.showInviteHomeOwner = true;
        }
      }
    })


    //ACCORDION
    var $activePanelHeading = $('.panel-group .panel .panel-collapse.in').prev().addClass('active');  //add class="active" to panel-heading div above the "collapse in" (open) div
    $activePanelHeading.find('a').prepend('<span class="fa fa-minus"></span> ');  //put the minus-sign inside of the "a" tag
    $('.panel-group .panel-heading').not($activePanelHeading).find('a').prepend('<span class="fa fa-plus"></span> ');  //if it's not active, it will put a plus-sign inside of the "a" tag
    $('.panel-group').on('show.bs.collapse', function (e: any) {  //event fires when "show" instance is called
      //$('.panel-group .panel-heading.active').removeClass('active').find('.fa').toggleClass('fa-plus fa-minus'); - removed so multiple can be open and have minus sign
      $(e.target).prev().addClass('active').find('.fa').toggleClass('fa-plus fa-minus');
    });
    $('.panel-group').on('hide.bs.collapse', function (e: any) {  //event fires when "hide" method is called
      $(e.target).prev().removeClass('active').find('.fa').removeClass('fa-minus').addClass('fa-plus');
    });
    //ACCORDION
    this.getAllClientTask();
    this.calculate_ssProposal()
    this.getDocstatus();

    this.userinfo = this.tokenStorage._get_userInfo();
    this.user_Role = sessionStorage.getItem('user_Role');
    console.log("this.userinfo ", this.userinfo);
    console.log("this.UserTypeName ", this.user_Role);

    this.CurrentLoggedUserId = JSON.parse(this.userinfo).CurrentLoggedUserId
    // alert(this.CurrentLoggedUserId  +this.inviteHomeOwner)
    let userdata: any;
    userdata = sessionStorage.getItem('clientData');

    console.log(userdata, "clientdata---") //** route data obj */
    // console.log(userdata.obj.leadId, "clientdata333333333333") //** route data obj */
    this.currentclientData = JSON.parse(userdata) ? JSON.parse(userdata) : {};
    console.log(this.currentclientData);

    this.isCancelled= this.currentclientData?.isCancelled;
    
    console.log(this.currentclientData, "clientdata")
    this.leadId = this.currentclientData.leadId;
    this.clientid = this.currentclientData.clientId;
    this.fileStatusid = this.currentclientData.fileStatusId;
    this.assignedUsers = this.currentclientData.assignedUsers;
    this.homeOwnerId = this.currentclientData.homeOwnerId;
    this.isInviteHomeOwnerDB = this.currentclientData.isHomeOwnerInvited

    this.getAllClientTask();
    this.usertype = this.tokenStorage._get_userRole();
    // alert(this.usertype)
    if (this.usertype == 'Agent' || this.usertype == 'Broker') {
      this.disableFileStatus = true;
    } else {
      this.disableFileStatus = null;
    }
    this.userTypeId = Number(sessionStorage.getItem('userTypeId'));
    this.getCountry();
    this.dropDownlist();

    this.getLoanList();
    this.getTOtalLienHolderNames();
    this.get_status();
    this._get_LienholderCount();
    

    // this.clientList = [{ 'clientFileName': 'CABOA20001 (Kevin Spacey)', 'clientFileNumber': '7723 Pennsylvania ave', 'assiManager': 'Steve Job', 'neogtiator': 'Matthew McConaughey', 'assNeogtiator': 'Scarlett Johansson', 'clientFileStatus': 'Johnny Depp', 'assignDate': '25-Jul-2020' }];
    // this.clientTask = [{ 'clientFileName': 'CABOA20001 (Kevin Spacey)', 'clientFileNumber': '7723 Pennsylvania ave', 'neogtiator': 'Matthew McConaughey', 'assNeogtiator': 'Scarlett Johansson', 'closer': 'Robert De Niro', 'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.', 'priority': 'Medium', 'specifictabs': 'Client & Property', 'CompletionDate': '25-Jul-2020', 'AssignedDate': '25-Jul-2020', 'CurrentStatus': 'Package submitted to bank' },
    // { 'clientFileName': 'CABOA20001 (Kevin Spacey)', 'clientFileNumber': '7723 Pennsylvania ave', 'neogtiator': 'Matthew McConaughey', 'assNeogtiator': 'Scarlett Johansson', 'closer': 'Robert De Niro', 'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.', 'priority': 'Medium', 'specifictabs': 'Client & Property', 'CompletionDate': '25-Jul-2020', 'AssignedDate': '25-Jul-2020', 'CurrentStatus': 'Package submitted to bank' },
    // { 'clientFileName': 'CABOA20001 (Kevin Spacey)', 'clientFileNumber': '7723 Pennsylvania ave', 'neogtiator': 'Matthew McConaughey', 'assNeogtiator': 'Scarlett Johansson', 'closer': 'Robert De Niro', 'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.', 'priority': 'Medium', 'specifictabs': 'Client & Property', 'CompletionDate': '25-Jul-2020', 'AssignedDate': '25-Jul-2020', 'CurrentStatus': 'Package submitted to bank' },
    // { 'clientFileName': 'CABOA20001 (Kevin Spacey)', 'clientFileNumber': '7723 Pennsylvania ave', 'neogtiator': 'Matthew McConaughey', 'assNeogtiator': 'Scarlett Johansson', 'closer': 'Robert De Niro', 'description': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.', 'priority': 'Medium', 'specifictabs': 'Client & Property', 'CompletionDate': '25-Jul-2020', 'AssignedDate': '25-Jul-2020', 'CurrentStatus': 'Package submitted to bank' }]

    this.documentEmailList = [{ 'documentNum': '01', 'documentDate': 'Apr 9, 2020 04:33 PM - EST', 'documentSentBy': 'Patrick Schultz', 'documentSentByType': 'Manager', 'documentSentTo': 'Test-Abol-Peak 6240896@dummyemail.com', 'documentSents': 'Leading Wise - Admin Page.pdf', 'documentSubject': 'asas' }];
    // this.clientFilelist= this.clientfileModel;
    this.filestatusgroup = this.formBuilder.group(this.clientfileModel);
    console.log("this.filestatusgroup", this.filestatusgroup.dirty);
    this.addtasktemplatefrm = this.formBuilder.group({
      clientId: 0,
      templateId: 0
    })
    console.log(this.filestatusgroup);

    if (this.usertype == 'Agent') {
      this.tab = ['file status', 'client & property', 'Documents', 'Lead Detail'];
      this.tabs = ['file status', 'client & property', 'Documents', 'Lead Detail'];
    } else if (this.usertype == 'Broker') {
      this.tab = ['file status', 'client & property', 'Documents', 'Lead Detail', 'LOA Detail'];
      this.tabs = ['file status', 'client & property', 'Documents', 'Lead Detail', 'LOA Detail'];
    }

    else {
      this.tab = ['file status', 'tasks', 'client & property', 'Documents', 'contacts', 'Closing Statements', 'Short Sales Information', 'SS Comp', 'Hardship', 'Commission breakdown'];
      this.tabs = ['file status', 'tasks', 'client & property', 'Documents', 'contacts', 'Closing Statements', 'Short Sales Information', 'SS Comp', 'Hardship', 'Commission breakdown'];

    }
    if (this.usertype == 'HomeOwner') {
      this.tab = ['file status', 'client & property', 'Documents', 'Hardship'];
      this.tabs = ['file status', 'client & property', 'Documents', 'Hardship'];
    }

    // this.ngOnChanges()

    // tabs part working


    this.activatedroute.params.subscribe((params: any) => {
      let id;
      let type;
      console.log(params)
      //  alert(params['id'])
      if (params['id'] != null || params['id'] != undefined) {
        //  alert(params['id'])


        if (params['id'] == 'basic' || params['id'] == 'lead') {
          this.selectedIndex=2;
          this.selectedTab('client & property',this.selectedIndex)

        }
      
      }  else{
        this.selectedIndex=0;
        this.selectedTab('file status', this.selectedIndex);
      }
    })

    this.usertype = this.tokenStorage._get_userRole();

    this.abc = `translateX(0px)`

   
    
  }


  getTOtalLienHolderNames() {
    this._manageService.getLienHolderName(this.clientid).subscribe((names: any) => {
      this.getLienNames = names.listData;
      console.log(this.getLienNames);
    })

  }
  //multiselect

  formModel = {
    name: '',
    email: 'ascasc@aa.com',
    skills: [{ "id": 1, "itemName": "Angular" }
    ]
  };
  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }
  //multiselect
  _get_LienholderCount() {
    this.notificationservice.lienholderCount(this.clientid).subscribe((counts: any) => {
      this.staticLength = counts.getData.lienHolderCount;

    })
  }

  // Agent Module
  get leadChilds() { return <FormArray>this.filestatusgroup.controls.leadChildAssign; }
  /** GET LEAD BY ID */
  getLead_byid() {
    this._manageService.getLead_Byid(this.leadId).subscribe((getLead: any) => {
      //getting null value
      this.filestatusgroup.patchValue(getLead.getData)

      // this.filestatusgroup.controls.homeOwner.patchValue({ "countryId": 1 });
      this.filestatusgroup.controls.homeOwner.patchValue(getLead.getData.homeOwner);

      this.leadChilds.push(this.formBuilder.group({ "userTypeId": getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userTypeId, "userId": getLead.getData.leadChildAssign[getLead.getData.leadChildAssign.length - 1].userId }))
      //  this.nestedFormGroup.value.leadChildAssign.slice(0,1);
      console.log(this.nestedFormGroup.value, "dddddddd");
      console.log(getLead.getData)
    })
    //console.log(id, '=<id')
  }

  /**get Country */
  getCountry() {
    this.dropdownService._getAll_country().subscribe((data: any) => {
      this.countrylist = data.listData;
      console.log(this.countrylist, "compo")
    })

    this.dropdownService._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      console.log(this.userTypes, "ji")
    })
  }

  /**Add ,Update LEAD  */
  /**Add ,Update LEAD  */
  addLead() {
    console.log(this.clientid)
    console.log('addLead', this.nestedFormGroup)
    var reqObj = this.nestedFormGroup.value;
    reqObj['leadId'] = parseInt(this.clientid) || 0;
    reqObj.homeOwner.stateId = parseInt(reqObj.homeOwner.stateId);
    reqObj.homeOwner.emailId = reqObj.email1;
    reqObj.homeOwner.cityId = parseInt(reqObj.homeOwner.cityId);
    reqObj.cityId = parseInt(reqObj.cityId);
    reqObj.stateId = parseInt(reqObj.stateId);
    reqObj.countryId = parseInt(reqObj.countryId);
    reqObj['createdBy'] = this.usertype;
    // reqObj.leadChildAssign[0].userTypeId = parseInt(reqObj.leadChildAssign[0].userTypeId);
    // reqObj.leadChildAssign[0].userId = parseInt(reqObj.leadChildAssign[0].userId);
    // if( reqObj.leadChildAssign[0].userTypeId == 0 &&   reqObj.leadChildAssign[0].userId == 0){
    //   reqObj.leadChildAssign = [];
    // }

    //** if Update by id */
    if (this.clientid) {
      this.updateForm = true;
      reqObj.leadChildAssign = [];
      reqObj.homeOwner['homeUserId'] = this.homeUserId;
      // alert("update");
      this._manageService.update_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {

          this.toastr.showSuccess("Lead Updated");
          this.route.navigate(['\lead-list'])
        } else { this.toastr.showError(data.message); }
      })
    }
    //** else Add  */
    else {
      for (let i = 0; i < reqObj.leadChildAssign.length; i++) {
        reqObj.leadChildAssign[i].userTypeId = parseInt(reqObj.leadChildAssign[i].userTypeId);
        reqObj.leadChildAssign[i].userId = parseInt(reqObj.leadChildAssign[i].userId);
      }
      // alert("create")
      this.updateForm = false;
      this._manageService.add_lead(reqObj).subscribe((data: any) => {
        if (data.reponse) {
          this.toastr.showSuccess("Lead created");
          this.route.navigate(['\lead-list'])
        } else {
          this.toastr.showError(data.message);
        }
      })
    }
  }
  /** END */
  /**END */
  // End Agent Module

  //**GET SELECTED TAB EVENT */
  selectedTab(e?: any, i?: any): any {

    this.tab = e; console.log(this.tab)
    this.selectedIndex = i;
    // this.templateForm= filestatusform;
    // if (this.tab == 'Commission breakdown') {
    //   $('.change-btn.next').hide();
    // }

    // if(this.contactForm){
    // console.log("form value" , this.contactForm)
    // }



    if (this.tab == 'file status' && this.selectedIndex==0) {

      this.getTOtalLienHolderNames();
      this.get_status();
      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '0');
      this.filestatusgroup = this.formBuilder.group(this.clientfileModel);


    }
    else if (this.tab == 'client & property') {

      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '3')
      this.filestatusgroup = this.formBuilder.formGroup(this.property)
      console.log(this.filestatusgroup)

      this._manageService.getAllTab_Tasks({ "clientId": this.clientid, "tabId": "3", 'CurrentUserId': this.userTypeId }).subscribe((doctasklist: any) => {

        this.docTaskLists = doctasklist.listData;

      })
      // this.getClientPropertyAddress();
      this.getUpdatedClientpropertyDetails();



    }
    else if (this.tab == 'Documents') {
      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '4')

      let docDetailsObj = new docuDocument();
      this.documents.docDetails = new Array<docuDocument>();
      this.filestatusgroup = this.formBuilder.formGroup(this.documents);

      this._manageService.getAllTab_Tasks({ "clientId": this.clientid, "tabId": "4", 'CurrentUserId': this.userTypeId }).subscribe((doctasklist: any) => {

        this.docTaskLists = doctasklist.listData;
      })

      this._getAll_Doclist();
      this._getAll_documentTab();
      this.getallManageAutogenList();
      this.assignedLead_list();
      this.email_list();

    }
    else if (this.tab == 'client & property') {

      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '3')
      console.log(this.property)
      this.filestatusgroup = this.formBuilder.formGroup(this.property)

      this._manageService.getAllTab_Tasks({ "clientId": this.clientid, "tabId": "3", 'CurrentUserId': this.userTypeId }).subscribe((doctasklist: any) => {

        this.docTaskLists = doctasklist.listData;
      })
      console.log(this.filestatusgroup, "hhjhj")
      // this.getClientPropertyAddress()
      this.getUpdatedClientpropertyDetails();
    }


    else if (this.tab == 'contacts') {

      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '5')
      //**get task details */
      this._manageService.getAllTab_Tasks({ "clientId": this.clientid, "tabId": "5", 'CurrentUserId': this.userTypeId }).subscribe((doctasklist: any) => {
        this.docTaskLists = doctasklist.listData;
      })
      // SubModal instance
      let contactLender = new contactLenderModel();
      let assignedstaffInfo = new assinedStaffInfoModel();
      //Pass array instance
      this.clientContactsmodel.contactLender = new Array<contactLenderModel>();
      this.clientContactsmodel.assinedStaffInfo = new Array<assinedStaffInfoModel>();
      //Push array
      // this.clientContactsmodel.assinedStaffInfo.push(assignedstaffInfo);
      // this.clientContactsmodel.contactLender.push(contactLender);
      this.filestatusgroup = this.formBuilder.group(this.clientContactsmodel);
      // this.filestatusgroup.patchValue({"borrowerFirstName": this.currentclientData.firstName, "borrowerLastName": this.currentclientData.lastName, "borrowerEmail": this.currentclientData.emailId,'cellNo':this.currentclientData.phone})

      this.getClientContactTabsDetails();
      this.getBuyerInfo();



    }
    else if (this.tab == 'SS Comp') {

      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '8')
      // alert("ss Comp ")
      this._manageService.getAllTab_Tasks({ "clientId": this.clientid, "tabId": "8", 'CurrentUserId': this.userTypeId }).subscribe((doctasklist: any) => {

        this.docTaskLists = doctasklist.listData;
      })

      this._getAutoPopulate_data();
      this._getSSProposal_calc();

    }



    else if (this.tab == 'Short Sales Information') {

      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '7')
      this._manageService.getAllTab_Tasks({ "clientId": this.clientid, "tabId": "7", 'CurrentUserId': this.userTypeId }).subscribe((doctasklist: any) => {

        this.docTaskLists = doctasklist.listData;
      })
      //** SubModel instance*/
      // let this.clientshortsalelist = new clientShortSale();

      let Lender = new lender();
      let Attorner = new attornerModel();
      let Clientlitsinfomodel = new clientListinginfomodel();
      let Buyerinfo = new buyerinfomodel();
      let ClientssBuyerAgentBrokerninfo = new clientssBuyerAgentBrokerninfo();
      let Clientsstitleinfo = new clientsstitleinfo();
      let Clientbuyerloanoffice = new clientbuyerloanoffice();
      let ClientSSOwnerattorney = new clientSSOwnerattorney();
      let Paralegalinfo = new paralegalinfo();
      let Escrowinfo = new escrowinfo();
      let Listinghistory = new listinghistory();
      this.clientshortsalelist.listinghistory = new listinghistory();
      this.clientshortsalelist.escrowinfo = new escrowinfo();
      this.clientshortsalelist.paralegalinfo = new paralegalinfo();
      this.clientshortsalelist.clientListinginfomodel = new clientListinginfomodel();
      this.clientshortsalelist.clientSSBuyerAgentorBrokerinfo = new clientssBuyerAgentBrokerninfo();
      //** PASS ARRAY INSTANCE */
      this.clientshortsalelist.clientsslenderinfo = new Array<lender>();
      this.clientshortsalelist.ownerAttornermodel = new Array<attornerModel>();
      // this.clientshortsalelist.clientListinginfomodel = new Array<clientListinginfomodel>();
      this.clientshortsalelist.buyerinfomodel = new Array<buyerinfomodel>();
      // this.clientshortsalelist.clientSSBuyerAgentorBrokerinfo = new Array<clientssBuyerAgentBrokerninfo>();
      this.clientshortsalelist.clientsstitleinfo = new Array<clientsstitleinfo>();
      this.clientshortsalelist.clientbuyerloanoffice = new Array<clientbuyerloanoffice>();
      this.clientshortsalelist.clientSSOwnerattorney = new Array<clientSSOwnerattorney>();
      this.clientshortsalelist.shortSaleId = 0;
      // this.clientshortsalelist.paralegalinfo = new Array<paralegalinfo>();
      // this.clientshortsalelist.escrowinfo = new Array<escrowinfo>();
      // this.clientshortsalelist.listinghistory = new Array<listinghistory>();
      //** PUSH ARRAY */
      // this.clientshortsalelist.clientsslenderinfo.push(Lender);
      // this.clientshortsalelist.ownerAttornermodel.push(Attorner);
      // this.clientshortsalelist.clientListinginfomodel.push(Clientlitsinfomodel);
      // this.clientshortsalelist.buyerinfomodel.push(Buyerinfo);
      // this.clientshortsalelist.clientSSBuyerAgentorBrokerinfo.push(ClientssBuyerAgentBrokerninfo);
      // this.clientshortsalelist.clientbuyerloanoffice.push(Clientbuyerloanoffice);
      //this.clientshortsalelist.clientsstitleinfo.push(Clientsstitleinfo);
      // this.clientshortsalelist.clientSSOwnerattorney.push(ClientSSOwnerattorney);
      // this.clientshortsalelist.paralegalinfo.push(Paralegalinfo);
      // this.clientshortsalelist.escrowinfo.push(Escrowinfo);
      // this.clientshortsalelist.listinghistory.push(Listinghistory);
      //** PASS INSTANCE TO FORMGROUP */

      // this.clientshortsalelist.clientsslenderinfo.push(Lender);
      // this.clientshortsalelist.ownerAttornermodel.push(Attorner);
      // this.clientshortsalelist.buyerinfomodel.push(Buyerinfo);
      // this.clientshortsalelist.clientbuyerloanoffice.push(Clientbuyerloanoffice);
      // this.clientshortsalelist.clientsstitleinfo.push(Clientsstitleinfo);
      // this.clientshortsalelist.clientSSOwnerattorney.push(ClientSSOwnerattorney);
      this.select_dropdown(1, 'state', false);
      this.filestatusgroup = this.formBuilder.formGroup(this.clientshortsalelist);

      console.log(this.filestatusgroup.value, 'shortsale');
      this._get_shortsaleData();



    }

    else if (this.tab == 'tasks') {

      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '2')
      this.getAssignedUserTypeByClient();
      this.getclientFileList();

      this.getTemplatetabList(this.userTypeId);
      this.getclienttabList();


      this.getAllClientTask();

    }
    else if (this.tab == 'Closing Statements') {

      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '6')
      //  this.filestatusgroup.group(this.closingStatementModel)
      // console.log(this.filestatusgroup)
      //call get in closing statement
      //data.listData

      // this.closingStatementModel = data.listData;
      console.log("codddddddeeeeee")
      this._manageService.getAllTab_Tasks({ "clientId": this.clientid, "tabId": "6" }).subscribe((doctasklist: any) => {
        this.docTaskLists = doctasklist.listData;
      })

      //  this.closingTabModel.borrowerDetails['borrowerFirstName'] = this.currentclientData.firstName;
      //  this.closingTabModel.borrowerDetails['borrowerlastName'] = this.currentclientData.lastName;
      //  this.closingTabModel.borrowerDetails['borrowerAddress'] = this.currentclientData.homeOwnerAddress;


      this._manageService._getclientclosing_statement(this.clientid).subscribe((data: any) => {



        console.log(data, 'dataget');

        this.closingTabModel = data.listData[0];
        if (this.closingTabModel.setetlementcharges == null) {
          this.closingTabModel.setetlementcharges = {}
        }

        if (this.closingTabModel.sellerSummaryModel == null) {
          this.closingTabModel.sellerSummaryModel = {}
        }

        if (this.closingTabModel.lenderDetails == null) {
          this.closingTabModel.lenderDetails = {}
        }
        // this.closingTabModel = data.listData? data.listData[0]: {};

        // this.closingTabModel = data.listData[0] ? data.listData[0] : {
        //   sellerSummaryModel: {},
        //   setetlementcharges: {},
        //   borrowerDetails: {},
        //   sellerDetails: {},
        //   lenderDetails: {}
        // }

        console.log("this.closingTabModel ==>", this.closingTabModel);

        this.getLoanList()
      })







    }
    else if (this.tab == 'Commission breakdown') {
      sessionStorage.setItem('tabName', this.tab);
      sessionStorage.setItem('tabId', '10')

      let misc = new Misc();
      let OthersFeeDetails = new others();
      console.log("OthersFeeDetails ", OthersFeeDetails);
      this.comissionbreakdownmodel.otherfee = new Array<others>();
      this.comissionbreakdownmodel.referalorMisc = new Array<Misc>();
      //  this.comissionbreakdownmodel.otherfee.push(OthersFeeDetails)

      // this.comissionbreakdownmodel.referalorMisc.push(misc);
      console.log("this.comissionbreakdownmodel ", this.comissionbreakdownmodel);

      this.filestatusgroup = this.formBuilder.formGroup(this.comissionbreakdownmodel);
      (<FormArray>this.filestatusgroup.controls.otherfee).push(this.formBuilder.group({
        otherfee: "0",
        otherfeetitle:""
      }))

      this.filestatusgroup = this.formBuilder.formGroup(this.comissionbreakdownmodel);
      (<FormArray>this.filestatusgroup.controls.referalorMisc).push(this.formBuilder.group({
        misc: "0",
        miscTitle:""
      }))

      // console.log(this.filestatusgroup, "commision")

      // alert()
      this.getCommissionDetails();
    }
    else if (this.tab == 'Lead Detail') {

      //this.nestedFormGroup = this.formBuilder.formGroup(leads);
      let leads = new leadmodel();
      leads.homeOwner = new HomeOwner();
      let leadchild = new leadAssign();
      leads.leadChildAssign = new Array<leadAssign>();
      // leads.leadChildAssign.push(leadchild);
      this.filestatusgroup = this.formBuilder.formGroup(leads);
      console.log(this.filestatusgroup.value, "formvalue")

      this.getLead_byid();
    }
    else if (this.tab == 'LOA Detail') {
      this.getall_LOAdetails();
    }
    else if (this.tab == 'Hardship') {
      this._manageService.getAllTab_Tasks({ "clientId": this.clientid, "tabId": "9", 'CurrentUserId': this.userTypeId }).subscribe((doctasklist: any) => {

        this.docTaskLists = doctasklist.listData;
        console.log("this.docTaskLists",this.docTaskLists);
      })

      this.getHardShipData();



    }
  }

  downloadclosing_statment() {
    this.fileuploadService.closingstatement_downloadurl(this.clientid);
  }

  downloadclosingstatement_HUD() {
    this.fileuploadService.closingstatement_HUD(this.clientid);
  }
  closingformsubmit(formData?: any) {
    console.log(formData)


    if (this.closingTabModel && this.closingTabModel.settlementDate != null) {
      this.closingTabModel.settlementDate = this.datepipe.transform(this.closingTabModel.settlementDate?._d, 'MM/dd/yyyy')?.toString();
    }
    if (this.closingTabModel.setetlementcharges && this.closingTabModel.setetlementcharges.dailyInrestChargeFrom != null) {
      this.closingTabModel.setetlementcharges.dailyInrestChargeFrom = this.datepipe.transform(this.closingTabModel.setetlementcharges.dailyInrestChargeFrom._d, 'MM/dd/yyyy')?.toString();
    }
    if (this.closingTabModel.setetlementcharges && this.closingTabModel.setetlementcharges.dailyInrestChargeTo != null) {
      this.closingTabModel.setetlementcharges.dailyInrestChargeTo = this.datepipe.transform(this.closingTabModel.setetlementcharges.dailyInrestChargeTo._d, 'MM/dd/yyyy')?.toString();

    }
    if (this.closingTabModel.setetlementcharges && this.closingTabModel.setetlementcharges.mortageInsurencefrom != null) {
      this.closingTabModel.setetlementcharges.mortageInsurencefrom = this.datepipe.transform(this.closingTabModel.setetlementcharges.mortageInsurencefrom._d, 'MM/dd/yyyy')?.toString();

    }
    if (this.closingTabModel.setetlementcharges && this.closingTabModel.setetlementcharges.mortageInsurenceTo != null) {
      this.closingTabModel.setetlementcharges.mortageInsurenceTo = this.datepipe.transform(this.closingTabModel.setetlementcharges.mortageInsurenceTo._d, 'MM/dd/yyyy')?.toString();
    }
    if (this.closingTabModel.sellerSummaryModel && this.closingTabModel.sellerSummaryModel.homeOwnerIncFrom != null) {
      this.closingTabModel.sellerSummaryModel.homeOwnerIncFrom = this.datepipe.transform(this.closingTabModel.sellerSummaryModel.homeOwnerIncFrom._d, 'MM/dd/yyyy')?.toString();

    }
    if (this.closingTabModel.sellerSummaryModel && this.closingTabModel.sellerSummaryModel.homeOwnerIncTo != null) {
      this.closingTabModel.sellerSummaryModel.homeOwnerIncTo = this.datepipe.transform(this.closingTabModel.sellerSummaryModel.homeOwnerIncTo._d, 'MM/dd/yyyy')?.toString();

    }
    if (this.closingTabModel.setetlementcharges && this.closingTabModel.setetlementcharges.homeOwnerIncFrom != null) {
      this.closingTabModel.setetlementcharges.homeOwnerIncFrom = this.datepipe.transform(this.closingTabModel.setetlementcharges.homeOwnerIncFrom._d, 'MM/dd/yyyy')?.toString();
    }

    if (this.closingTabModel.setetlementcharges && this.closingTabModel.setetlementcharges.homeOwnerIncTo != null) {
      this.closingTabModel.setetlementcharges.homeOwnerIncTo = this.datepipe.transform(this.closingTabModel.setetlementcharges.homeOwnerIncTo._d, 'MM/dd/yyyy')?.toString();
    }
    // console.log(this.closingTabModel, 'after');


    // this.closingTabModel.sellerSummaryModel.cash = parseInt( this.closingTabModel.sellerSummaryModel.cash);
    // this.closingTabModel.sellerSummaryModel.cash = 0;


    this.closingTabModel['clientId'] = this.clientid;
    this.closingTabModel["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')
    // if (this.closingTabModel.sellerSummaryModel! = null) {
    this.closingTabModel.sellerSummaryModel.cashDetails = this.closingTabModel.sellerSummaryModel?.cashDetails + "";
    this.closingTabModel.sellerSummaryModel['contSecondLienHolder'] = "";
    // }
    // else {
    // this.closingTabModel.sellerSummaryModel = {}
    // }
    this.closingTabModel.mortageIncCaseNo = this.closingTabModel.mortageIncCaseNo + "";
    this.closingTabModel.notes = this.closingTabModel.notes + "";
    this.closingTabModel.loanType1 = this.closingTabModel.loanType1 + "";
    this.closingTabModel.loanType2 = this.closingTabModel.loanType2 + "";
    this.closingTabModel.loanType3 = this.closingTabModel.loanType3 + "";
    this.closingTabModel.fileNumber = this.closingTabModel.fileNumber + "";
    this.closingTabModel.loanNumber = this.closingTabModel.loanNumber + "";
    this.closingTabModel.cashDetails = this.closingTabModel.cashDetails + "";
    this.closingTabModel.contractSalesPrice = parseInt(this.closingTabModel.contractSalesPrice);
    this.closingTabModel.settlementCharges = parseInt(this.closingTabModel.settlementCharges);
    this.closingTabModel.countryTax = parseInt(this.closingTabModel.countryTax);
    this.closingTabModel.cityTownTax = parseInt(this.closingTabModel.cityTownTax);
    this.closingTabModel.grossAmountBorrower = parseInt(this.closingTabModel.grossAmountBorrower);
    this.closingTabModel.depositEarnestMoney = parseInt(this.closingTabModel.depositEarnestMoney);
    this.closingTabModel.principalAmount = parseInt(this.closingTabModel.principalAmount);
    this.closingTabModel.sellerCityTownTax = parseInt(this.closingTabModel.sellerCityTownTax);
    this.closingTabModel.sellerCountryTax = parseInt(this.closingTabModel.sellerCountryTax);
    this.closingTabModel.totalForBorrower = parseInt(this.closingTabModel.totalForBorrower);
    this.closingTabModel.grossAmountDueFromBorrower = parseInt(this.closingTabModel.grossAmountDueFromBorrower);
    this.closingTabModel.lessAmountBorrower = parseInt(this.closingTabModel.lessAmountBorrower);
    this.closingTabModel.cash = parseInt(this.closingTabModel.cash);
    this.closingTabModel.borrowerDetails.borrowerStateId = parseInt(this.closingTabModel.borrowerDetails.borrowerStateId)
    this.closingTabModel.borrowerDetails.borrowerCityId = parseInt(this.closingTabModel.borrowerDetails.borrowerCityId)
    this.closingTabModel.setetlementcharges.peakReality = 0;
    this.closingTabModel.setetlementcharges.panorexRealirt = 0;

    if (this.closingTabModel.lenderDetails! = null) {
      this.closingTabModel.lenderDetails.lenderCityId = parseInt(this.closingTabModel.lenderDetails.lenderCityId)

      this.closingTabModel.lenderDetails.lenderStateId = parseInt(this.closingTabModel.lenderDetails.lenderStateId)

    }
    else {
      this.closingTabModel.lenderDetails = {

      }
    }

    // this.closingTabModel.borrowerDetails.borrowerStateId = 0;
    // this.closingTabModel.borrowerDetails.borrowerCityId = 0;
    this.closingTabModel.sellerDetails.sellerCityId = parseInt(this.closingTabModel.sellerDetails.sellerCityId)

    this.closingTabModel.sellerDetails.sellerStateId = parseInt(this.closingTabModel.sellerDetails.sellerStateId)

    for(var i =0;i<this.sellerfundValues.length ;i ++){
      console.log(  this.closingTabModel.setetlementcharges[this.sellerfundValues[i]], this.sellerfundValues[i] )
    this.closingTabModel.setetlementcharges[this.sellerfundValues[i]] =parseInt(this.closingTabModel.setetlementcharges[this.sellerfundValues[i]])
    }


    this._manageService._clientclosing_statement(this.closingTabModel).subscribe((data: any) => {

      console.log(data.reponse, "datas----post")

      if (data.reponse == true) {
        this.toastr.showSuccess("Closing statement updated successfully");
        this.closingform.form.markAsUntouched();
      } else {
        this.toastr.showError("ERROR Occured");
        this.closingform.form.markAsUntouched();
      }
    })

  }
  //**END EVENT */

  // Shortsale tab
  lienLender() {
    let lienlenderadd_extra = <FormArray>this.filestatusgroup.controls.contactLender;
    lienlenderadd_extra.push(this.formBuilder.formGroup(contactLenderModel));
  }
  lienlender_remove(index: number) {
    let lienlenderadd_extra = <FormArray>this.filestatusgroup.controls.contactLender;
    lienlenderadd_extra.removeAt(index);
  }

  addLender_extra() {
    let lenderadd_extra = <FormArray>this.filestatusgroup.controls.clientsslenderinfo;
    lenderadd_extra.push(this.formBuilder.formGroup(lender));
    this.cityListArray.push({ cityList: [] })
  }
  removeLender_extra(index: number) {
    let lenderadd_extra = <FormArray>this.filestatusgroup.controls.clientsslenderinfo;
    lenderadd_extra.removeAt(index);
  }

  addtitle_extra() {
    let titleadd_extra = <FormArray>this.filestatusgroup.controls.clientsstitleinfo;
    titleadd_extra.push(this.formBuilder.formGroup(clientsstitleinfo));
  }
  removetitle_extra(index: number) {
    let titleadd_extra = <FormArray>this.filestatusgroup.controls.clientsstitleinfo;
    titleadd_extra.removeAt(index);
  }

  addAttorney_extra() {
    let attorneyadd_extra = <FormArray>this.filestatusgroup.controls.ownerAttornermodel;
    attorneyadd_extra.push(this.formBuilder.formGroup(attornerModel));
  }
  removeAttorney_extra(index: number) {
    let attorneyadd_extra = <FormArray>this.filestatusgroup.controls.ownerAttornermodel;
    attorneyadd_extra.removeAt(index);
  }

  addSSAttorney_extra() {
    let attorneyadd_extra = <FormArray>this.filestatusgroup.controls.clientSSOwnerattorney;
    attorneyadd_extra.push(this.formBuilder.formGroup(clientSSOwnerattorney));
    this.attorneyCityListArray.push({ cityList: [] })
  }
  removeSSAttorney_extra(index: number) {
    let attorneyadd_extra = <FormArray>this.filestatusgroup.controls.clientSSOwnerattorney;
    attorneyadd_extra.removeAt(index);
  }

  addbuyerloan_offer_extra() {
    let ssattorneyadd_extra = <FormArray>this.filestatusgroup.controls.clientbuyerloanoffice;
    ssattorneyadd_extra.push(this.formBuilder.formGroup(clientbuyerloanoffice));
  }
  removebuyerloan_offer_extra(index: number) {
    let attorneyadd_extra = <FormArray>this.filestatusgroup.controls.clientbuyerloanoffice;
    attorneyadd_extra.removeAt(index);
  }

  deleteLoginHistory(index: number) {
    let deleteLoginRemove = <FormArray>this.filestatusgroup.controls.listinghistory;
    deleteLoginRemove.removeAt(index);
  }


  addbuyeriso() {
    let addbuyer_extra = <FormArray>this.filestatusgroup.controls.buyerinfomodel;
    addbuyer_extra.push(this.formBuilder.formGroup(buyerinfomodel));
  }
  removebuyeriso(index: number) {
    let addbuyer_extra = <FormArray>this.filestatusgroup.controls.buyerinfomodel;
    addbuyer_extra.removeAt(index);
  }

  deleteall_forms(index: number) {
    let lenderadd_extra = <FormArray>this.filestatusgroup.controls.clientsslenderinfo;
    lenderadd_extra.removeAt(index);

    let titleadd_extra = <FormArray>this.filestatusgroup.controls.clientsstitleinfo;
    titleadd_extra.removeAt(index);

    let deleteLoginRemove = <FormArray>this.filestatusgroup.controls.listinghistory;
    deleteLoginRemove.removeAt(index);

    let attorneyadd_extra = <FormArray>this.filestatusgroup.controls.ownerAttornermodel;
    attorneyadd_extra.removeAt(index);

    let ssattorneyadd_extra = <FormArray>this.filestatusgroup.controls.clientbuyerloanoffice;
    ssattorneyadd_extra.removeAt(index);

  }
  // END Shortsale tab




  //**GET FILE STATUS */
  get_status() {
    // static id
    this._manageService._getFile_status(this.clientid).subscribe((data: any) => {
      //  this.filestatusgroup = data.getData;
      // this.filestatusgroup = this.formBuilder.group(data.getData);     //**not showing data yet*/
      if (data.getData != null) {
        this.fileCloedStatus = data?.getData?.fileStatusId;
        this.fileCloedStatusId = data?.getData?.clientId;

        this.fileStatusTabModel = data.getData;
      }

      console.log(this.fileCloedStatus, 'filestatus')
      console.log(this.fileCloedStatusId, 'clientid')
      console.log(this.fileStatusTabModel, "datas")
      // if (data.getData) {
      //   this.fileFormReadonly = false;
      // }
    })
  }

  //**END FILE STATUS */

  //* Add client Contact */

  getClientContactTabsDetails() {
    this._manageService._get_contactDetails(this.clientid).subscribe((staffInfo: any) => {

      //  this.filestatusgroup.group(staffInfo.getData)
      console.log(staffInfo, "staffInfo")
      this.filestatusgroup.patchValue({ "borrowerFirstName": staffInfo.getData.borrowerFirstName || this.currentclientData.firstName, "borrowerLastName": staffInfo.getData.borrowerLastName || this.currentclientData.lastName, "borrowerEmail": staffInfo.getData.borrowerEmail || this.currentclientData.emailId, "cborrowFirstName": staffInfo.getData.cborrowFirstName, "cborrowLastName": staffInfo.getData.cborrowLastName, "cellNo": staffInfo.getData.cellNo || this.currentclientData.phone })
      this.contactManagerInfo = staffInfo.getData.managerInfo;

      if (staffInfo.getData && staffInfo.getData.contactLender && staffInfo.getData.contactLender.length > 0) {
        // this.filestatusgroup.controls.contactLender.controls.removeAt(0);

        staffInfo.getData.contactLender.forEach((staff: any) => {
          // this.filestatusgroup.controls.contactLender.controls.push(this.formBuilder.group({ "lenderName": staff.lenderName, "phone": staff.phone, "fax": staff.fax, "repName": staff.repName }))
          (<FormArray>this.filestatusgroup.controls.contactLender).push(this.formBuilder.group({ "lenderName": staff.lenderName, "phone": staff.phone, "fax": staff.fax, "repName": staff.repName, "lenderId": staff.lenderId }));
          console.log(this.filestatusgroup, "get")
        })
      } else {
        // this.filestatusgroup.patchValue({ "borrowerFirstName": ' ', "borrowerLastName": ' ', "borrowerEmail": ' ', "cborrowFirstName": ' ', "cborrowLastName":' ', "cellNo": ' ' })
        // this.filestatusgroup.controls.contactLender.controls.push(this.formBuilder.group({ "lenderName": "", "phone": "", "fax": "", "repName":"" }))
        (<FormArray>this.filestatusgroup.controls.contactLender).push(this.formBuilder.group({ "lenderName": "", "phone": "", "fax": "", "repName": "", "lenderId": 0 }));

        // this.clientContactsmodel.contactLender.push({});
      }
      console.log("this.filestatusgroup ", this.filestatusgroup);

      // this.clientContactsmodel = staffInfo.getData;


    })
  }
  clientContacts_get() {
    console.log(this.filestatusgroup.value, 'contactscliii');

    let contactObj: any;
    contactObj = this.filestatusgroup.value;
    contactObj['clientId'] = this.clientid;
    contactObj["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');

    this._manageService._add_clientContacts(contactObj).subscribe((data: any) => {
      console.log(data, 'contactscliiiInsert');
      if (data.reponse == true) {
        this.toastr.showSuccess(data.message);
        this.filestatusgroup.markAsUntouched();

      } else {
        this.toastr.showError(data.message);
        this.filestatusgroup.markAsUntouched();
      }
    })
  }
  //* END client Contact */

  //**hardship tab*/
  // SignaturePad
  showImage(_data: any) {

    this.signatureImage = _data;
    console.log(this.signatureImage, 'img')

  }
  showImage1(_data1: any) {
    let base64data: any;
    this.signatureImage1 = _data1;
    console.log(this.signatureImage1, 'img1');
}

  cancelReset() {
    this.nestedFormGroup.reset();
  }
  getHardShipData() {
      
    this._manageService.getHardship(this.clientid).subscribe((data: any) => {
      this.hardShipData = data.getData;
      
      console.log("data ", data);
      if (data.getData.hardShipId !=0) {
        
        this.hardshipModel = this.hardShipData;
        this.hardshipModel['hardShipId'] = this.hardShipData.hardShipId;
        this.hardshipModel['borrowerSignPath'] = this.hardShipData.borrowerSignPath;
      //  this.signatureImage1 = this.hardShipData.borrowerSignPath;
        this.hardshipModel['coBorrowerSignPath'] = this.hardShipData.coBorrowerSignPath;
        // this.signatureImage2 = this.hardShipData.coBorrowerSignPath;
     
        console.log(this.hardshipModel, "gets")
      }
      else if(data.getData.hardShipId ==0){
        
        this.hardshipModel['hardShipId'] = data.getData.hardShipId;
        this.hardshipModel['propertyAddress']=this.hardShipData.propertyAddress;
        this.hardshipModel['propertyCity']=this.hardShipData.propertyCity;
        this.hardshipModel['propertyState']=this.hardShipData.propertyState;
        this.hardshipModel['propertyZipCode']=this.hardShipData.propertyZipCode;
        this.hardshipModel['loanNumber']=this.hardShipData.loanNumber;
        this.hardshipModel['borrowerName'] = this.hardShipData.borrowerName;
        
   
      }
 

    })
  }

  clearCoBorrower(){
    if(this.signatureImage2 || this.hardshipModel.coBorrowerSignPath){
      this.signatureImage2=null;
      this.hardshipModel.coBorrowerSignPath=null
    }
  }

  addHardShipData(val?:any):any {
// alert(this.signatureImage)
// debugger
    console.log(this.hardshipModel, "hardship")
    this.hardshipModel['clientId'] = this.clientid;
    // this.hardshipModel['borrowerName'] = this.clientid;
    this.hardshipModel["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')
    this.hardshipModel["dateOfBirth"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy')

    this.hardshipModel['createdBy'] = this.userTypeId + "";
    this.hardshipModel['borrowerSignPath'] = this.signatureImage1;
    this.hardshipModel['coBorrowerSignPath'] = this.signatureImage2;
    if(val == 'form'){


this._manageService.addHardship(this.hardshipModel).subscribe((data: any) => {
  console.log(data, "created")
  this.toastr.showSuccess("Updated");
  this.getHardShipData()
  this.hardShipForm.form.markAsUntouched()
})

      // alert('1')

    }else if(val == 'doc'){
      // alert('2')
      console.log(this.hardshipModel)
      this.fileuploadService.addHardshipDoc(this.hardshipModel);
    }
    
  }
  //**end */
  //* client ShortSale */
  _get_shortsaleData() {
    let getssdata: any
    this._manageService._get_shortSale(this.clientid).subscribe((getshortsaledata: any) => {
      getssdata = getshortsaledata.getData;
      console.log("getssdata _get_shortsaleData", getssdata);

      if (getssdata) {
        // alert('if')
        this.shortSaleId = getssdata.shortSaleId;
        getssdata.clientsslenderinfo && getssdata.clientsslenderinfo.forEach((item: any, i: number) => {
          if (i > 0) {
            this.cityListArray.push({ cityList: [] })
          }
          this.getCityList(item.stateId, i, '');

          (<FormArray>this.filestatusgroup.controls.clientsslenderinfo).push(this.formBuilder.group({
            "lenderName": item.lenderName,
            "loanNo": item.loanNo,
            "loanBalance": item.loanBalance,
            "pitia": item.pitia,
            "amountBehind": item.amountBehind,
            "monthsLate": item.monthsLate,
            "repName": item.repName,
            "phone": item.phone,
            "extno": item.extno,
            "faxno": item.faxno,
            "cellNo": item.cellNo,
            "address": item.address,
            "stateName": item.stateName,
            "cityName": item.cityName,
            "zipCode": item.zipCode,
            "email": item.email,
            "notes": item.notes,
            "lenderInfoId": item.lenderInfoId,
            "wayofPay": item.wayofPay,
          }))
        })

        getssdata.clientSSOwnerattorney && getssdata.clientSSOwnerattorney.forEach((item: any, i: number) => {
          if (i > 0) {
            this.attorneyCityListArray.push({ cityList: [] })
          }
          this.getCityList(item.stateId, i, 'ssAttorney');
          (<FormArray>this.filestatusgroup.controls.clientSSOwnerattorney).push(this.formBuilder.group({
            "attorneyName": item.attorneyName,
            "formName": item.formName,
            "phone": item.phone,
            "cellNo": item.cellNo,
            "faxNo": item.faxNo,
            "email": item.email,
            "address": item.address,
            "cityId": item.cityId,
            "stateId": item.stateId,
            "zipCode": item.zipCode
          }))

        })

        getssdata.buyerinfomodel && getssdata.buyerinfomodel.forEach((item: any) => {
          (<FormArray>this.filestatusgroup.controls.buyerinfomodel).push(this.formBuilder.group({
            "wayofPay": item.wayofPay,
            "buyerName": item.buyerName,
            "coBuyerName": item.coBuyerName,
            "buyerPhone": item.buyerPhone,
            "email": item.email,
            "offerAmount": item.offerAmount,
            "contractDate": item.contractDate,
            "closingDate": item.closingDate
          }))
        })

        getssdata.clientsstitleinfo && getssdata.clientsstitleinfo.forEach((item: any) => {

          (<FormArray>this.filestatusgroup.controls.clientsstitleinfo).push(this.formBuilder.group({
            "name": item.name,
            "ordered": item.ordered,
            "updated": item.updated,
            "respresentative": item.respresentative,
            "company": item.company,
            "phone": item.phone,
            "extNo": item.extNo,
            "cellNo": item.cellNo,
            "faxNo": item.faxNo,
            "email": item.email
          }))
        })

        getssdata.clientbuyerloanoffice && getssdata.clientbuyerloanoffice.forEach((item: any) => {

          (<FormArray>this.filestatusgroup.controls.clientbuyerloanoffice).push(this.formBuilder.group({
            "name": item.name,
            "company": item.company,
            "phone": item.phone,
            "extNo": item.extNo,
            "cellNo": item.cellNo,
            "faxNo": item.faxNo,
            "email": item.email,
            "relationshiptoSeller": item.relationshiptoSeller,
            "notes": item.notes
          }))
        })

        getssdata.ownerAttornermodel && getssdata.ownerAttornermodel.forEach((item: any) => {
          (<FormArray>this.filestatusgroup.controls.ownerAttornermodel).push(this.formBuilder.group({
            "name": item.name,
            "email": item.email,
            "phone": item.phone,
            "cellNo": item.cellNo,
            "faxNo": item.faxNo,
            "notes": item.notes,
          }));
        })



        this.filestatusgroup.controls.paralegalinfo.patchValue({
          "name": getssdata.paralegalinfo ? getssdata.paralegalinfo.name : '',
          "email": getssdata.paralegalinfo ? getssdata.paralegalinfo.email : '',
          "officeNo": getssdata.paralegalinfo ? getssdata.paralegalinfo.officeNo : '',
          "extNo": getssdata.paralegalinfo ? getssdata.paralegalinfo.extNo : '',
          "cellNo": getssdata.paralegalinfo ? getssdata.paralegalinfo.cellNo : '',
          "faxNo": getssdata.paralegalinfo ? getssdata.paralegalinfo.faxNo : ''
        })

        this.filestatusgroup.controls.escrowinfo.patchValue({
          "representativeName": getssdata.escrowinfo ? getssdata.escrowinfo.representativeName : '',
          "company": getssdata.escrowinfo ? getssdata.escrowinfo.company : '',
          "phone": getssdata.escrowinfo ? getssdata.escrowinfo.phone : '',
          "extNo": getssdata.escrowinfo ? getssdata.escrowinfo.extNo : '',
          "faxNo": getssdata.escrowinfo ? getssdata.escrowinfo.faxNo : '',
          "email": getssdata.escrowinfo ? getssdata.escrowinfo.email : ''
        })

        this.filestatusgroup.controls.listinghistory.patchValue({
          "sno": getssdata.listinghistory ? getssdata.listinghistory.sno : '',
          "mlSno": getssdata.listinghistory ? getssdata.listinghistory.mlSno : '',
          "listingPrice": getssdata.listinghistory ? getssdata.listinghistory.listingPrice : '',
          "listingDate": getssdata.listinghistory ? getssdata.listinghistory.listingDate : null,
          "notes": getssdata.listinghistory ? getssdata.listinghistory.notes : ''
        })

        this.filestatusgroup.controls.clientListinginfomodel.patchValue({
          "listingDate": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.listingDate : null,
          "listingPrice": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.listingPrice : '',
          "listingAgent": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.listingAgent : '',
          "agency": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.agency : '',
          "address": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.address : '',
          "email": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.email : '',
          "phone": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.phone : '',
          "cellNo": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.cellNo : '',
          "faxNo": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.faxNo : '',
          "extNo": getssdata.clientListinginfomodel ? getssdata.clientListinginfomodel.extNo : ''
        });

        this.filestatusgroup.controls.clientSSBuyerAgentorBrokerinfo.patchValue({
          "name": getssdata.clientSSBuyerAgentorBrokerinfo ? getssdata.clientSSBuyerAgentorBrokerinfo.name : '',
          "agency": getssdata.clientSSBuyerAgentorBrokerinfo ? getssdata.clientSSBuyerAgentorBrokerinfo.agency : '',
          "realEstateLieNo": getssdata.clientSSBuyerAgentorBrokerinfo ? getssdata.clientSSBuyerAgentorBrokerinfo.realEstateLieNo : '',
          "phone": getssdata.clientSSBuyerAgentorBrokerinfo ? getssdata.clientSSBuyerAgentorBrokerinfo.phone : '',
          "extNo": getssdata.clientSSBuyerAgentorBrokerinfo ? getssdata.clientSSBuyerAgentorBrokerinfo.extNo : '',
          "cellNo": getssdata.clientSSBuyerAgentorBrokerinfo ? getssdata.clientSSBuyerAgentorBrokerinfo.cellNo : '',
          "faxNo": getssdata.clientSSBuyerAgentorBrokerinfo ? getssdata.clientSSBuyerAgentorBrokerinfo.faxNo : '',
          "email": getssdata.clientSSBuyerAgentorBrokerinfo ? getssdata.clientSSBuyerAgentorBrokerinfo.email : ''
        })
      } else {
        this.getBrokerOrAgentInfo(this.clientid);
      }
      if (!getssdata || getssdata.clientsslenderinfo && getssdata.clientsslenderinfo.length == 0) {

        const lenderinfo = <FormArray>this.filestatusgroup.controls.clientsslenderinfo;
        lenderinfo.push(this.formBuilder.group({
          "lenderName": "",
          "loanNo": "",
          "loanBalance": "",
          "pitia": "",
          "amountBehind": "",
          "monthsLate": "",
          "repName": "",
          "phone": "",
          "extno": "",
          "faxno": "",
          "cellNo": "",
          "address": "",
          "stateName": "",
          "cityName": "",
          "zipCode": "",
          "email": "",
          "notes": "",
          "lenderInfoId": 0
        }));
      }
      if (!getssdata || getssdata.ownerAttornermodel && getssdata.ownerAttornermodel.length == 0) {
        (<FormArray>this.filestatusgroup.controls.ownerAttornermodel).push(this.formBuilder.group({
          "name": "",
          "email": "",
          "phone": "",
          "cellNo": "",
          "faxNo": "",
          "notes": "",
        }));
      }

      if (!getssdata || getssdata.clientSSOwnerattorney && getssdata.clientSSOwnerattorney.length == 0) {
        (<FormArray>this.filestatusgroup.controls.clientSSOwnerattorney).push(this.formBuilder.group({
          "attorneyName": "",
          "formName": "",
          "phone": "",
          "cellNo": "",
          "faxNo": "",
          "email": "",
          "address": "",
          "cityId": 0,
          "stateId": 0,
          "zipCode": ""
        }));
      }

      if (!getssdata || getssdata.buyerinfomodel && getssdata.buyerinfomodel.length == 0) {
        // alert('buyerinfomodel');
        (<FormArray>this.filestatusgroup.controls.buyerinfomodel).push(this.formBuilder.group({
          "wayofPay": "",
          "buyerName": "",
          "coBuyerName": "",
          "buyerPhone": "",
          "email": "",
          "offerAmount": "",
          "contractDate": "",
          "closingDate": ""
        }));
      }

      if (!getssdata || getssdata.clientsstitleinfo && getssdata.clientsstitleinfo.length == 0) {
        (<FormArray>this.filestatusgroup.controls.clientsstitleinfo).push(this.formBuilder.group({
          "name": "",
          "ordered": "",
          "updated": "",
          "respresentative": "",
          "company": "",
          "phone": "",
          "extNo": "",
          "cellNo": "",
          "faxNo": "",
          "email": ""
        }));
      }

      if (!getssdata || getssdata.clientbuyerloanoffice && getssdata.clientbuyerloanoffice.length == 0) {
        (<FormArray>this.filestatusgroup.controls.clientbuyerloanoffice).push(this.formBuilder.group({
          "name": "",
          "company": "",
          "phone": "",
          "extNo": "",
          "cellNo": "",
          "faxNo": "",
          "email": "",
          "relationshiptoSeller": "",
          "notes": ""
        }));
      }
      console.log(getssdata.cmaAnalysisDocsUrl, getssdata && getssdata.cmaAnalysisDocsUrl == null, "true||false")
      this.cmaFile = getssdata && getssdata.cmaAnalysisDocsUrl != null ? JSON.parse(getssdata.cmaAnalysisDocsUrl) : [];
      this.buyerFile = getssdata && getssdata.buyerInfoDocsUrl != null ? JSON.parse(getssdata.buyerInfoDocsUrl) : [];
      console.log("this.filestatusgroup ", this.filestatusgroup, this.cmaFile, this.buyerFile);
    })
  }

  buyerCmaDocUpload(event: any, url: any) {


    console.log(event.target.files, "BUYERCMA")

    this.fileuploadService.uploadCmaBuyer_Doc(event.target.files, url, this.clientid).subscribe((resObjFile: any) => {
      console.log("resObjFile ", resObjFile);
      if (url == 'UploadCMAanaysisDocTab') {
        // this.cmaAnalysisDocsUrl = resObjFile.mutiplesFile.File1.DownloadPath;
        console.log(resObjFile.mutiplesFile);
        let result = Object.keys(resObjFile.mutiplesFile).map(key => {
          this.cmaFile.push({ "documentUrl": resObjFile.mutiplesFile[key].DownloadPath, "documentName": resObjFile.mutiplesFile[key].FileName, "actualFileName": resObjFile.mutiplesFile[key].FileName, "renderUrl": resObjFile.mutiplesFile[key].DbPath })
        })
        this.clientShortsale_get()
      } else {
        // this.buyerInfoDocsUrl = resObjFile.mutiplesFile.File1.DownloadPath;
        let result = Object.keys(resObjFile.mutiplesFile).map(key => {
          this.buyerFile.push({ "documentUrl": resObjFile.mutiplesFile[key].DownloadPath, "documentName": resObjFile.mutiplesFile[key].FileName, "actualFileName": resObjFile.mutiplesFile[key].FileName, "renderUrl": resObjFile.mutiplesFile[key].DbPath })
        })
        this.clientShortsale_get()
      }

    })
  }

  
  clientShortsale_get() {
    console.log(this.filestatusgroup.value, 'clientShortSale');
    var data = this.filestatusgroup.value;
    data.clientListinginfomodel.listingDate = data.clientListinginfomodel.listingDate ? moment(new Date(data.clientListinginfomodel.listingDate)).format('MM/DD/YYYY') : '';
    data.listinghistory.listingDate = data.listinghistory.listingDate ? moment(new Date(data.listinghistory.listingDate)).format('MM/DD/YYYY') : '';
    let shortsaleObj: any = {};

    shortsaleObj['clientId'] = this.clientid;
    shortsaleObj["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');
    shortsaleObj['createdBy'] = this.CurrentLoggedUserId;
    shortsaleObj['buyerInfoDocsUrl'] = JSON.stringify(this.buyerFile);
    shortsaleObj['cmaAnalysisDocsUrl'] = JSON.stringify(this.cmaFile);
    shortsaleObj['shortSaleId'] = this.shortSaleId;
    shortsaleObj['clientsslenderinfo'] = data.clientsslenderinfo;
    shortsaleObj['ownerAttornermodel'] = data.ownerAttornermodel;
    shortsaleObj['clientListinginfomodel'] = data.clientListinginfomodel;
    shortsaleObj['buyerinfomodel'] = data.buyerinfomodel.filter((item: any) => {
      item.closingDate = item.closingDate ? moment(new Date(item.closingDate)).format('MM/DD/YYYY') : '';
      item.contractDate = item.contractDate ? moment(new Date(item.contractDate)).format('MM/DD/YYYY') : '';
      return item;
    });
    shortsaleObj['clientSSBuyerAgentorBrokerinfo'] = data.clientSSBuyerAgentorBrokerinfo;
    shortsaleObj['clientsstitleinfo'] = data.clientsstitleinfo;
    shortsaleObj['clientbuyerloanoffice'] = data.clientbuyerloanoffice;
    shortsaleObj['clientSSOwnerattorney'] = data.clientSSOwnerattorney;
    shortsaleObj['paralegalinfo'] = data.paralegalinfo;
    shortsaleObj['escrowinfo'] = data.escrowinfo;
    shortsaleObj['listinghistory'] = data.listinghistory;
    console.log("shortsaleObj ==>", shortsaleObj);
    // return false;
    this._manageService._add_clientShortSale(shortsaleObj).subscribe((data: any) => {
      this.toastr.showSuccess("data created");
      this.filestatusgroup.markAsUntouched();
    })

  }
  //* END client ShortSale */
  checkValue(event: any) {
    console.log(event);
  }
  //**ADD FILE STATUS */
  add_status(formobj?: any) {
    console.log(formobj);
    // return false;
    //  let data :any= {};
    let data = formobj.value;
    let keysofstatus: any = [];
    let check = ["fileStatusId", "documentsCollectedFromAgent", "documentsCollectedFromHomeOwner", "listedForSale", "offerAcccepted", "bankRejectedoffer", "bankNegotiatorAssgin", "packageSubmittedtoBank", "bpocompleted", "fileSubmittedtoSetup", "departMent", "submittedwithApproval", "offerDenied", "shortsaleApproved", "inEscrow", "closed",];
    keysofstatus.push(Object.keys(data))
    console.log(keysofstatus);
    check.forEach((item: any) => {
      console.log(item, "")
      data[item] = data[item] == true ? 1 : 0;
    });

    data['createdBy'] = this.userTypeId + "";
    data['clientId'] = this.clientid;
    data["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    console.log("data ", data);

    if (data.closed == 1 && data.documentsCollectedFromAgent == 1 && data.documentsCollectedFromHomeOwner == 1 && data.listedForSale == 1 && data.offerAcccepted == 1 && data.bankNegotiatorAssgin == 1 && data.packageSubmittedtoBank == 1 && data.bpocompleted && data.fileSubmittedtoSetup == 1 && data.submittedwithApproval == 1 && data.shortsaleApproved == 1 && data.inEscrow == 1) {
      this._manageService._addFile_status(data).subscribe(data => { console.log(data); this.toastr.showSuccess("file status updated"); this.get_status(); }, err => {
        this.toastr.showError("error occured");
        this.filestatusform.form.markAsUntouched();
      })
    } else if (data.closed == 0) {
      this._manageService._addFile_status(data).subscribe(data => { console.log(data); this.toastr.showSuccess("file status updated"); this.get_status(); }, err => {
        this.toastr.showError("error occured");
        this.filestatusform.form.markAsUntouched();
      })

    } else {
      this.toastr.showError('select all to close');
      this.filestatusform.form.markAsUntouched();
    }



  }
  //**END ADD FILE STATUS */

  //**SEND SCREENSHOT */
  sendScreenshotToUsers() {


    this.screenshotModel['subject'] = "";
    this.screenshotModel['htmlBodystring'] = this.screenshots.nativeElement.innerHTML;
    this.screenshotModel['clientId'] = this.clientid;
    this.screenshotModel["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    console.log(this.screenshotModel)
    this._manageService._send_Screenshot(this.screenshotModel).subscribe((screenshotobj: any) => {
      
      if(screenshotobj.errorMessage){
        this.toastr.showError(screenshotobj.errorMessage);
      }else if(screenshotobj.message){
        this.toastr.showSuccess(screenshotobj.message); 
      }

      $('#myModal').modal('hide');
    }, err => {
      this.toastr.showError("something went wrong");
    })
  }




  //**END  */
  // getClientPropertyAddress() {
  //   this._manageService._getAutoClientAddress(this.clientid).subscribe((addressData: any) => {
  //     this.filestatusgroup.patchValue({ 'propertyAdderss': addressData.getData.address })
  //   })
  // }  
  getUpdatedClientpropertyDetails() {
    // alert(


    // )
    this._manageService._get_PropertyDetails(this.clientid || this.leadId).subscribe((getData: any) => {
      console.log("getData getUpdatedClientpropertyDetails", getData);
      this.stateNameProp = getData.getData.propertyStateName;
      // var data = getData.getData;
      this.getrealtorValueaddress(this.currentclientData.homeOwnerZipCode)
      // this.filestatusgroup = this.formBuilder.group(getData.getData)
      // console.log(this.currentclientData.homeOwnerZipCode,getData.getData.subjectZipCode  , typeof this.currentclientData.homeOwnerZipCode, typeof getData.getData.subjectZipCode)
      if (getData.getData == null) {
        this.filestatusgroup.patchValue({ 'propertyAdderss': this.currentclientData.homeOwnerAddress || this.currentclientData.homeOwner.address, 'propertyStateName': this.currentclientData.homeOwnerStateName || this.currentclientData.homeOwner.stateName, 'propertyCityName': this.currentclientData.homeOwnerCityName || this.currentclientData.homeOwner.cityName, 'subjectZipcode': this.currentclientData.homeOwner.zipCode })
      } else {

        this.filestatusgroup.patchValue({
          "propertyId": getData.getData.propertyId,
          "clientId": getData.getData.clientId,
          "branchId": getData.getData.branchId,
          "fileTypeId": getData.getData.fileTypeId,
          "leadSource": getData.getData.leadSource,
          "brokerRefPartyId": getData.getData.brokerRefPartyId,
          "receviedDate": getData.getData.receviedDate,
          "closingDate": getData.getData.closingDate,
          "actualClosingDate": getData.getData.actualClosingDate,
          "createdDate": getData.getData.createdDate,
          "propertyAdderss": getData.getData.propertyAdderss,
          "propertyCityName": getData.getData.propertyCityName,
          "propertyStateName": getData.getData.propertyStateName + '-' + getData.getData.stateCode,
          'subjectZipcode': getData.getData.subjectZipCode,
          "propertyTypeId": getData.getData.propertyTypeId,
          "zillowValue": getData.getData.zillowValue,
          "realtorValue": getData.getData.realtorValue,
          "brokerAgentValue": getData.getData.brokerAgentValue,
          "averageValue": getData.getData.averageValue,
          "interiorAccess": getData.getData.interiorAccess,
          "firstName": getData.getData.firstName,
          "lastName": getData.getData.lastName,
          "borrowerEmail": getData.getData.borrowerEmail,
          "homePhone": getData.getData.homePhone,
          "cellPhone": getData.getData.cellPhone,
          "borDOB": getData.getData.borDOB,
          "socialSecurityNo": getData.getData.socialSecurityNo,
          "maritalstatus": getData.getData.maritalstatus,
          "nameofSpouse": getData.getData.nameofSpouse,
          "isCoBorrower": getData.getData.isCoBorrower,
          "coBorFirstName": getData.getData.coBorFirstName,
          "coBorLastName": getData.getData.coBorLastName,
          "coBorEmail": getData.getData.coBorEmail,
          "coBorHomePhone": getData.getData.coBorHomePhone,
          "coBorCellPhone": getData.getData.coBorCellPhone,
          "coBorCellCarrier": getData.getData.coBorCellCarrier,
          "isSameMailaddress": getData.getData.isSameMailaddress,
          "lengthoftheTime": getData.getData.lengthoftheTime,
          "borrowerSameSeller": getData.getData.borrowerSameSeller,
          "borrowerResided": getData.getData.borrowerResided,
          "lombName": getData.getData.lombName,
          "lombCompany": getData.getData.lombCompany,
          "lombEmail": getData.getData.lombEmail,
          "lombphone": getData.getData.lombPhone,
          "lombStatus": getData.getData.lombStatus,
          "borrowerOverViewName": getData.getData.borrowerOverViewName,
          "borrowerOverviewEmail": getData.getData.borrowerOverviewEmail,
          "borrowerOverViewFirstName": getData.getData.borrowerOverViewFirstName,
          "borrowerOverViewLastName": getData.getData.borrowerOverViewLastName,
          "borrowerofDetails": getData.getData.borrowerOverviewEmail,
          "mailingZipCode": getData.getData.mailingZipCode,
          "mailingAddress": getData.getData.mailingAddress,
          "mailingStateName": getData.getData.mailingStateName,
          "mailingCityName": getData.getData.mailingCityName,
          "sellerName": getData.getData.sellerName,
          "sellerDOB": getData.getData.sellerDOB,
          "sellerEmailId": getData.getData.sellerEmailId,
          "sellerPhone": getData.getData.sellerPhone,
          "sellerSecurityNo": getData.getData.sellerSecurityNo,
          "sellerMaritalStatus": getData.getData.sellerMaritalStatus,
          "cretaedBy": getData.getData.cretaedBy
        })
      }
    }, err => {

    })

    console.log("getfoemproperty", this.filestatusgroup)
  }



  getZillowValue(addresslead: any) {
    // alert(addresslead)
    var that = this;
    var leadSubjectAddress = addresslead;

    $(document).ready(function () {
      var zillowval = `https://www.zillow.com/homedetails/${leadSubjectAddress}/303807055_zpid/`
      console.log(zillowval)
      // console.log(addresslead)
      const settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://zillow6.p.rapidapi.com/property",
        "method": "POST",
        "headers": {
          "content-type": "application/x-www-form-urlencoded",
          "x-rapidapi-key":  "74c8f75866msh3af843814ed4985p1c36d5jsn77556d9834fd",
          "x-rapidapi-host": "zillow6.p.rapidapi.com"
        },
        "data": {
          "property_url": `https://www.zillow.com/homedetails/${leadSubjectAddress}/303807055_zpid/`
        }
      };

      $.ajax(settings).done(function (response: any) {
        console.log(response[0].size);
        that.filestatusgroup.patchValue({ 'zillowValue': response[0].size });
        that.clientPropertySetAverageValue();



      });

    });
  }

  getRealtorValue(value?: any, statname?: any, city?: any, zipcode?: any) {
    console.log(this.propertyState)
    this.realtorAddress = [];
    if (value == 'zillow') {
      this.zillowpopup = true;
      console.log(statname.split("-")[1].toLowerCase())
      this.getZillowSearch(statname.split("-")[1], city)
      console.log(statname)
    } else {
      this.realtorPopup = true;
      this.getrealtorValueaddress(zipcode)
      this.filestatusgroup.patchValue({ "realtorValue": sessionStorage.getItem('propertyid') })
    }

  }

  getClientDetails(clientid: any, isRoute: boolean) {
    this._manageService.getClientDetails(clientid).subscribe((clientData: any) => {
      sessionStorage.setItem('clientData', JSON.stringify(clientData.getData));

      this.currentclientData = clientData.getData;
      if (isRoute) {
        this.route.navigate(['client-file-status'])
      }

    })
  }
  //**ADD PROPERTY */
  add_property(formdata?: any) {

    console.log(formdata, "client^property");
    let propstatecity: any;
    let porpertyObj: any

    porpertyObj = this.filestatusgroup.value;
    propstatecity = porpertyObj.propertyStateName;
    console.log(this.filestatusgroup.value)
    porpertyObj.propertyTypeId = parseInt(porpertyObj.propertyTypeId);
    porpertyObj.branchId = parseInt(porpertyObj.branchId);
    porpertyObj.clientId = parseInt(this.clientid);
    porpertyObj.brokerRefPartyId = porpertyObj.brokerRefPartyId + "";
    porpertyObj.brokerStatus = parseInt(porpertyObj.brokerStatus)
    porpertyObj.fileTypeId = parseInt(porpertyObj.fileTypeId);
    porpertyObj.interiorAccess = Number(porpertyObj.interiorAccess);
    porpertyObj.maritalstatus = parseInt(porpertyObj.maritalstatus);
    porpertyObj.sellerMaritalStatus = porpertyObj.sellerMaritalStatus + "";
    // console.log(porpertyObj.propertyStateName)
    porpertyObj.propertyStateName = propstatecity.split("-")[0];
    // alert(porpertyObj.propertyStateName.split("-")   )
    porpertyObj['stateCode'] = propstatecity.split("-")[1];

    // porpertyObj.propertyCityId = parseInt(porpertyObj.propertyCityId);
    // porpertyObj.mailingState = parseInt(porpertyObj.mailingState);
    // porpertyObj.mailingCity = parseInt(porpertyObj.mailingCity);
    // porpertyObj.propertyStateId = parseInt(porpertyObj.propertyStateId);
    porpertyObj.averageValue = parseInt(porpertyObj.averageValue);
    // if(porpertyObj.sellerDOB._d ! = undefined || null){
    //   porpertyObj.sellerDOB = this.datepipe.transform(porpertyObj.sellerDOB._d, 'MM/dd/yyyy')

    // }else{
    //   porpertyObj.sellerDOB ="";
    // }
    // porpertyObj.loanOMBId = parseInt(porpertyObj.loanOMBId);
    porpertyObj.fileStatusId = parseInt(porpertyObj.fileStatusId);
    porpertyObj.fileSubstatusId = parseInt(porpertyObj.fileSubstatusId);
    porpertyObj.clientPropertyId = parseInt(porpertyObj.BrokerAgreementSigned)
    porpertyObj['isCoBorrower'] = (/true/i).test(porpertyObj.isCoBorrower);
    porpertyObj['isSameMailaddress'] = (/true/i).test(porpertyObj.isSameMailaddress);
    porpertyObj['borrowerSameSeller'] = (/true/i).test(porpertyObj.borrowerSameSeller);
    porpertyObj['borrowerResided'] = (/true/i).test(porpertyObj.borrowerResided);
    porpertyObj["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');
    porpertyObj["cretaedBy"] = this.CurrentLoggedUserId;


    if (this.inviteHomeOwner) {
      // alert('true')

      porpertyObj["isfromScratch"] = true;
      // alert(porpertyObj['leadId'])
      porpertyObj["leadDetails"] = {
        "leadRealtorValue": "string",
        "leadAddress": "string",
        "leadState": "string",
        "leadCity": "string",
        "leadZipcode": "string",
        "homeownerZipcode": "string"
      },

        this._manageService._add_ManualClient(porpertyObj).subscribe((data: any) => {
          if (data.reponse == true) {
            this.toastr.showSuccess(data.message)
            this.getClientDetails(data.returnId, true)
            this.clienPropertyScratch = true;


          } 
          else if(data.reponse == false){
            this.toastr.showError(data.message);
            this.clienPropertyScratch = false;
          }
          else {
            this.clienPropertyScratch = false;
          }

        }, err => {
          this.toastr.showError("something went wrong")
        })
    } else if (!this.inviteHomeOwner) {
      //  alert('false')
      this._manageService._add_Updateproperty(porpertyObj).subscribe((data: any) => {
        // this.clienPropertyScratch = false;
        this.toastr.showSuccess(data.message);
        this.filestatusgroup.markAsUntouched();

      }, err => {
        this.toastr.showError("something went wrong")
      })
    }



  }
  //**END */


  _getAll_documentTab() {
    this._manageService._getAll_document(this.clientid).subscribe((data: any) => {
      this.documentTabList = data.listData;
      console.log(data.listData)
    })
  }


  //**ss Comp tab
  //**get ss Comp tab */ */
  _getAutoPopulate_data() {

    this._manageService._getSSproposaltab(this.clientid).subscribe((ssdata: any) => {
      if (ssdata.getData != null) {
        this.autopopulateData = ssdata.getData;
        this.ssproposal.datapoints.ssPrice = this.autopopulateData.shortSaleOfferPrice;
        this.ssproposal.datapoints.ss = this.autopopulateData.shortSaleOfferPrice;
        this.ssproposal.datapoints.ssReo = this.autopopulateData.shortSaleOfferPrice;
        this.ssproposal.datapoints.closingCostPrice = this.autopopulateData.closingCostPrice;
        this.ssproposal.datapoints.totalOwedPrice = this.autopopulateData.totalOwnedPrice;
        this.ssproposal.datapoints.lienssettlementTotalprice = this.autopopulateData.allotherLienssettlementTotal;
        this.calculate_ssProposal()



      }
    })
  }

  _getSSProposal_calc() {

    let ssproposalobj: any;

    this._manageService._getSSproposaltabcalc(this.clientid).subscribe((data: any) => {
      if (data.getData != null) {
        this.ssproposal.datapoints = data.getData;

        this.calculate_ssProposal();
      }


    })
  }

  exceldownload(data: any) {
    this.fileuploadService.excelInSSProposal(data, this.ssproposal)
  }
  addExcelToDocTab(data:any){
    this.fileuploadService.addDocSSComp(data,this.ssproposal,this.clientid)
  }
  
  // changeSScomp(e:any){
  //   console.log((e.value)
  //   this.ssproposal.datapoints.closingCostVal = e.value;
  // }

  populatePriceVal(){
    this.ssproposal.datapoints.closingCostSS = this.ssproposal.datapoints.closingCostPrice;
    this. ssproposal.datapoints.closingCostReo = this.ssproposal.datapoints.closingCostPrice;
    this.ssproposal.datapoints.totalOwedss = this.ssproposal.datapoints.totalOwedPrice;
    this.ssproposal.datapoints.totalOwedReo = this.ssproposal.datapoints.totalOwedPrice;
     this.ssproposal.datapoints.lienssettlementTotalSS =this.ssproposal.datapoints.lienssettlementTotalprice;
    //  this.ssproposal.datapoints.foreclosureSoldSS= this.ssproposal.datapoints. foreclosureSoldPrice;
     this.ssproposal.datapoints.foreclosureSoldREO= this.ssproposal.datapoints. foreclosureSoldPrice;
    
    //  this.ssproposal.datapoints.mortgagepaymentto1stSS =  this.ssproposal.datapoints. mortgagepaymentto1stPrice;
     this.ssproposal.datapoints.mortgagepaymentto1stReo =  this.ssproposal.datapoints. mortgagepaymentto1stPrice;
    
    //  this.ssproposal.datapoints.repairinREOSS =  this.ssproposal.datapoints. repairinREOPrice;
     this.ssproposal.datapoints.repairinREOreo =  this.ssproposal.datapoints. repairinREOPrice;
    //  this.ssproposal.datapoints. annualPropertyTaxSS = this.ssproposal.datapoints.annualPropertyTaxPrice;
     this.ssproposal.datapoints. annualPropertyTaxReo = this.ssproposal.datapoints.annualPropertyTaxPrice;
     this.ssproposal.datapoints. shortSaleOtherSS = this.ssproposal.datapoints.shortSaleOtherPrice;
    //  this.ssproposal.datapoints. shortSaleOtherReo = this.ssproposal.datapoints.shortSaleOtherPrice;
    
    //  this.ssproposal.datapoints. reoOtherSS= this.ssproposal.datapoints.reoOtherSS;
     this.ssproposal.datapoints. reoOtherReo= this.ssproposal.datapoints.reoOtherPrice;

    console.log(this.ssproposal.datapoints)
    // this.ssproposal.datapoints.closingCostSS  =  this.ssproposal.datapoints.closingCostSS ;
    // this. ssproposal.datapoints.closingCostReo =  this. ssproposal.datapoints.closingCostReo ;

  }

  populateSSREO(){
     this.ssproposal.datapoints.closingCostSS  =  this.ssproposal.datapoints.closingCostSS ;
    this. ssproposal.datapoints.closingCostReo =  this. ssproposal.datapoints.closingCostReo ;
    this.ssproposal.datapoints.totalOwedss =  this.ssproposal.datapoints.totalOwedss ;
    this.ssproposal.datapoints.totalOwedReo = this.ssproposal.datapoints.totalOwedReo;
    this.ssproposal.datapoints.lienssettlementTotalSS = this.ssproposal.datapoints.lienssettlementTotalSS;
    // this.ssproposal.datapoints.foreclosureSoldSS=  this.ssproposal.datapoints.foreclosureSoldSS;
    this.ssproposal.datapoints.foreclosureSoldREO= this.ssproposal.datapoints.foreclosureSoldREO;
    // this.ssproposal.datapoints.mortgagepaymentto1stSS= this.ssproposal.datapoints.mortgagepaymentto1stSS;
    this.ssproposal.datapoints.mortgagepaymentto1stReo = this.ssproposal.datapoints.mortgagepaymentto1stReo;
    // this.ssproposal.datapoints.repairinREOSS  = this.ssproposal.datapoints.repairinREOSS ;
    this.ssproposal.datapoints.repairinREOreo = this.ssproposal.datapoints.repairinREOreo;
    // this.ssproposal.datapoints. annualPropertyTaxSS  = this.ssproposal.datapoints. annualPropertyTaxSS ;
    this.ssproposal.datapoints. annualPropertyTaxReo =this.ssproposal.datapoints. annualPropertyTaxReo;
    this.ssproposal.datapoints. shortSaleOtherSS = this.ssproposal.datapoints. shortSaleOtherSS ;
    // this.ssproposal.datapoints. shortSaleOtherReo =  this.ssproposal.datapoints. shortSaleOtherReo ;
    // this.ssproposal.datapoints. reoOtherSS= this.ssproposal.datapoints.reoOtherSS;
    this.ssproposal.datapoints. reoOtherReo= this.ssproposal.datapoints.reoOtherReo;

console.log(this.ssproposal.datapoints)
  }

  
  calculate_ssProposal() {
    // 0-per , 1-dollar
    console.log(this.ssproposal, "dddd")
    //**assumption 1Calculation */
    // this.ssproposal.datapoints.closingCostVal = 'per';
    this.ssproposal.datapoints.ssReo = this.ssproposal.datapoints.ssReo;
    this.ssproposal.datapoints.ss = this.ssproposal.datapoints.ss;

    this.ssproposal.assumption.ssproposalss = Number(this.ssproposal.datapoints.ss);
    this.ssproposal.assumption.ssproposalreo = Number(this.ssproposal.datapoints.ssReo);



    if(this.ssproposal.datapoints.closingCostVal == '1'){
      this.ssproposal.assumption.closingcostss =  this.ssproposal.datapoints.closingCostSS;
      this.ssproposal.assumption.closingcostreo = this. ssproposal.datapoints.closingCostReo;
    }
   else if(this.ssproposal.datapoints.closingCostVal == '0'){
    this.ssproposal.assumption.closingcostss = Number(this.ssproposal.assumption.ssproposalss * this.ssproposal.datapoints.closingCostSS) / 100;
    this.ssproposal.assumption.closingcostreo = Number(this.ssproposal.assumption.ssproposalreo * this.ssproposal.datapoints.closingCostPrice) / 100;
   }
    
    this.ssproposal.assumption.totalownedss = Number(this.ssproposal.datapoints.totalOwedss);
    this.ssproposal.assumption.totalownedreo = Number(this.ssproposal.datapoints.totalOwedReo);

    this.ssproposal.assumption.leinss = Number(this.ssproposal.datapoints.lienssettlementTotalSS);
    this.ssproposal.assumption.futurelostreo = Number((this.ssproposal.datapoints.mortgagepaymentto1stPrice * this.ssproposal.datapoints.foreclosureSoldREO));
    this.ssproposal.assumption.reorepairs = Number(this.ssproposal.datapoints.repairinREOPrice);
   
    let monthlytaxreo = Number((this.ssproposal.datapoints.annualPropertyTaxReo / 12) * this.ssproposal.datapoints.foreclosureSoldREO);
    this.ssproposal.assumption.mothlytaxreo = monthlytaxreo.toFixed(2);
   
    this.ssproposal.assumption.ssotherss = Number(this.ssproposal.datapoints.shortSaleOtherSS);
    // console.log(this.ssproposal.datapoints.reoOtherPrice, "reoooooooooooo")
    this.ssproposal.assumption.reoother = Number(this.ssproposal.datapoints.reoOtherReo)
    let totalss: number;
    // console.log(this.ssproposal.assumption.ssnet, this.ssproposal.assumption.ssproposalss)
    totalss = Number(this.ssproposal.assumption.closingcostss) + Number(this.ssproposal.assumption.totalownedss) + Number(this.ssproposal.assumption.leinss) + Number(this.ssproposal.assumption.ssotherss);
    // console.log(totalss, this.ssproposal.assumption.ssnet, this.ssproposal.assumption.ssproposalss)
    this.ssproposal.assumption.ssnet = Number(this.ssproposal.assumption.ssproposalss) - Number(totalss);
    // console.log("ssnet", this.ssproposal.assumption.ssnet)
    let totalreo: number;
    totalreo = Number(this.ssproposal.assumption.closingcostreo) + Number(this.ssproposal.assumption.totalownedreo) + Number(this.ssproposal.assumption.futurelostreo) + Number(this.ssproposal.assumption.reorepairs) + Number(this.ssproposal.assumption.mothlytaxreo) + Number(this.ssproposal.assumption.reoother);
    console.log(totalreo, "totalreo")
    let reonetfixedval = Number(this.ssproposal.assumption.ssproposalreo) - totalreo;
    this.ssproposal.assumption.reonet = reonetfixedval.toFixed(2)

    console.log(this.ssproposal.assumption.ssnet, this.ssproposal.assumption.reonet)
    this.ssproposal.assumption.totalss = (Number(this.ssproposal.assumption.ssnet) - Number(this.ssproposal.assumption.reonet));
    this.ssproposal.assumption.totalreo = (Number(this.ssproposal.assumption.reonet) - Number(this.ssproposal.assumption.ssnet));
    console.log(typeof this.ssproposal.assumption.totalreo)
    //**END of the calculation */
    //**assumption 2 calculation */
    this.ssproposal.reo.ssnet = Number(this.ssproposal.assumption.reonet) + 1000;   
   if(this.ssproposal.datapoints.closingCostVal == '1'){
    this.ssproposal.reo.closingcostreo = this.ssproposal.datapoints.closingCostPrice;

   }else if(this.ssproposal.datapoints.closingCostVal == '0'){
    this.ssproposal.reo.closingcostreo = Number((this.ssproposal.assumption.ssproposalreo * this.ssproposal.datapoints.closingCostPrice) / 100);

   }
    
    this.ssproposal.reo.totalownedss = Number(this.ssproposal.datapoints.totalOwedPrice);
    this.ssproposal.reo.totalownedreo = Number(this.ssproposal.datapoints.totalOwedPrice);
    this.ssproposal.reo.lienssettlementss = Number(this.ssproposal.datapoints.lienssettlementTotalSS);
    this.ssproposal.reo.futurereo = Number((this.ssproposal.datapoints.mortgagepaymentto1stPrice * this.ssproposal.datapoints.foreclosureSoldREO));
    this.ssproposal.reo.repairsreo = Number(this.ssproposal.datapoints.repairinREOPrice);
    let reomonthlyTaxFixedVal = Number((this.ssproposal.datapoints.annualPropertyTaxReo / 12) * this.ssproposal.datapoints.foreclosureSoldREO);
    this.ssproposal.reo.monthlytaskreo =reomonthlyTaxFixedVal.toFixed(2)
    
    this.ssproposal.reo.ssother = Number(this.ssproposal.datapoints.shortSaleOtherSS);
    this.ssproposal.reo.reoother = Number(this.ssproposal.datapoints.reoOtherReo);

   
     console.log(this.ssproposal.reo.totalownedss,this.ssproposal.reo.lienssettlementss,this.ssproposal.reo.ssother ,'->>>>>>>>>>')
    let tot =  Number(this.ssproposal.reo.totalownedss) + Number(this.ssproposal.reo.lienssettlementss) + Number(this.ssproposal.reo.ssother)
   
    console.log(Number(tot), Number(this.ssproposal.reo.ssnet),"-------->")

if( this.ssproposal.datapoints.closingCostVal == '0'){
 


    if(this.ssproposal.datapoints.closingCostPrice == 0  || this.ssproposal.datapoints.closingCostPrice == null){
      
      let sspricereoFxedVal =  tot + Number(this.ssproposal.reo.ssnet)
      this.ssproposal.reo.ssofferprice =sspricereoFxedVal.toFixed(2)
      let closecostFixedVal = Number(this.ssproposal.reo.ssofferprice * this.ssproposal.datapoints.closingCostSS) / 100;
      this.ssproposal.reo.closingcostss = closecostFixedVal.toFixed(2)
    }
else{
  console.log( this.ssproposal.reo.ssofferprice,"SSOFFERPRICE , SSOFFERPRICE")
  let sspricereoFxedVal = tot + Number(this.ssproposal.reo.ssnet);
  this.ssproposal.reo.ssofferprice = sspricereoFxedVal.toFixed(2)

  let closecost = Number(100 - Number(this.ssproposal.datapoints.closingCostPrice));
  let total:Number;
  total= this.ssproposal.reo.ssofferprice  / closecost;
   let ssofferpricereofixedval = Number(total)*100
  this.ssproposal.reo.ssofferprice = ssofferpricereofixedval.toFixed(2);

  console.log(this.ssproposal.reo.ssofferprice,"closecost,,,,,>>>>>")
   let closingcostssFixedVal = Number(this.ssproposal.reo.ssofferprice * this.ssproposal.datapoints.closingCostPrice) / 100 ;
  this.ssproposal.reo.closingcostss =closingcostssFixedVal.toFixed(2)
  
  console.log(this.ssproposal.reo.closingcostss,"closecost,,,,,------>")
}

}  else if(this.ssproposal.datapoints.closingCostVal == '1'){
  this.ssproposal.reo.ssofferprice = tot + Number(this.ssproposal.reo.ssnet);
  this.ssproposal.reo.closingcostss = this.ssproposal.datapoints.closingCostSS ;
}


    this.ssproposal.reo.reoofferprice = Number(this.ssproposal.assumption.ssproposalreo);


    this.ssproposal.reo.reonet = Number(this.ssproposal.assumption.reonet);
    console.log(this.ssproposal.reo.ssnet, this.ssproposal.reo.reonet)
    this.ssproposal.reo.totalss = Number(this.ssproposal.reo.ssnet) - Number(this.ssproposal.reo.reonet);
    this.ssproposal.reo.totalreo = Number(this.ssproposal.reo.reonet) - Number(this.ssproposal.reo.ssnet);

    //**END of the calculation */

  }
  //end ssComp/*
  ssproposaladd() {
    console.log(   this.ssproposal.datapoints.closingCostVal);
    let datpointObj: any;
    datpointObj = this.ssproposal.datapoints;

Object.keys(datpointObj).forEach(function(el){
  datpointObj[el] = parseFloat(datpointObj[el])
})
 
   
    // datpointObj['closingCostSS'] = 0;
    // datpointObj['closingCostReo'] = 0;
    // datpointObj['lienssettlementTotalSS'] = 0;
    // datpointObj['lienssettlementTotalReo'] = 0;
    // datpointObj['foreclosureSoldSS'] = 0;
    // datpointObj['foreclosureSoldREO'] = 0;
    // datpointObj['mortgagepaymentto1stSS'] = 0;
    // datpointObj['mortgagepaymentto1stReo'] = 0;
    // datpointObj['repairinREOSS'] = 0;
    // datpointObj['repairinREOreo'] = 0;
    // datpointObj['annualPropertyTaxSS'] = 0;
    // datpointObj['annualPropertyTaxReo'] = 0;
    // datpointObj['shortSaleOtherSS'] = 0;
    // datpointObj['shortSaleOtherReo'] = 0;
    // datpointObj['reoOtherSS'] = 0;
    // datpointObj['reoOtherReo'] = 0;
    datpointObj['clientId'] = this.clientid;
    datpointObj["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');   
    datpointObj['createdBy'] = this.userTypeId + "";
   datpointObj['closingCostVal'] =  this.ssproposal.datapoints.closingCostVal +"";


    this._manageService._addorupdateSSproposaltab(datpointObj).subscribe((data: any) => {
      console.log(data);
      this.toastr.showSuccess(data.message);
      this.ssForm.form.markAsUntouched();
    })
  }


  //** commission BreakDown ADD/UPDATE */
  get getMisc() { return <FormArray>this.filestatusgroup.controls.referalorMisc; };
  get fee() { return <FormArray>this.filestatusgroup.controls.otherfee; };

  changeValues(e: any) {
    console.log(e.value)
    let calcpart = this.filestatusgroup.value;
    if (e.value == 'per') {

      console.log(this.filestatusgroup)
      this.filestatusgroup.patchValue({ 'sSnegotitorFee': this.filestatusgroup.value.salesPrice * this.filestatusgroup.value.sharingValue / 100 })
      this.calcNegoCommission()
      this.getTotalmisc();
      this.getTotalOthersFee();
    } else {

      this.filestatusgroup.patchValue({ 'sSnegotitorFee':  this.filestatusgroup.value.sharingValue })
      this.calcNegoCommission()
      this.getTotalmisc();
      this.getTotalOthersFee();
    }

  }


  getTotalmisc(ind?: any) {
    let total: number = 0;
    let miscarray: any
    miscarray = this.getMisc.value;
    console.log(miscarray[0].misc, miscarray)
    for (var i = 0; i < miscarray.length; i++) {
      if (parseInt(miscarray[i].misc)) {
        total = Number(total) + Number(miscarray[i].misc);
        // console.log(total)

      }
      this.getTotalMiscAmount = total;
      console.log(this.filestatusgroup.value)
      this.filestatusgroup.patchValue({ 'subTotal': Number(this.filestatusgroup.value.sSnegotitorFee) - Number(total), 'negoCommission': this.filestatusgroup.value.subTotal * (this.filestatusgroup.value.negoSplit / 100) });
      // this.filestatusgroup.patchValue({'negoCommission':Number(this.filestatusgroup.value.subTotal*Number(this.filestatusgroup.value.negoSplit/100))})
      console.log(this.netValue)

      console.log(total,this.filestatusgroup , '>>>>>>>>')
    }
    this.calcNegoCommission()


  }

  netAmountCalc(){
    let total: number = 0;
    let otherfees: any
    console.log(this.fee)

    otherfees = this.fee.value;
    console.log(otherfees)

    for (var i = 0; i < otherfees.length; i++) {

      if (parseInt(otherfees[i].otherfee)) {
        total = Number(total) + Number(otherfees[i].otherfee);
      }
    this.filestatusgroup.patchValue({ 'netAmount': Number(this.filestatusgroup.value.subTotal) - Number(this.filestatusgroup.value.negoCommission) - Number(total) })

  }}
  getTotalOthersFee() {
    let total: number = 0;
    let otherfees: any
    console.log(this.fee)

    otherfees = this.fee.value;
    console.log(otherfees)

    for (var i = 0; i < otherfees.length; i++) {

      if (parseInt(otherfees[i].otherfee)) {
        total = Number(total) + Number(otherfees[i].otherfee);
        this.netValue = total;
      }
      this.filestatusgroup.patchValue({'otherFeeTotal': total+""})
      this.filestatusgroup.patchValue({ 'netAmount': Number(this.filestatusgroup.value.subTotal) - Number(this.filestatusgroup.value.negoCommission) - Number(total) })
    }
    console.log(total)
// 
    this.calcNegoCommission()

  }

  getCommissionDetails() {
    this._manageService.getAll_commisson(this.clientid).subscribe((commissiondata: any) => {


      if (commissiondata.getData.referalorMisc != null) {
        this.getMisc.removeAt(0)
      }
      if (commissiondata.getData.otherfee != null) {
        this.fee.removeAt(0)
      }
      if (commissiondata.getData == null) {
        this.filestatusgroup = this.formBuilder.formGroup(this.comissionbreakdownmodel);
      } else {
        this.filestatusgroup.patchValue(commissiondata.getData);
        commissiondata.getData.referalorMisc.forEach((misc: any) => {
          this.getMisc.push(this.formBuilder.group({ 'misc': misc.misc,'miscTitle':misc.miscTitle  }));
        });

        commissiondata.getData.otherfee.forEach((otherfee: any) => {
          this.fee.push(this.formBuilder.group({ 'otherfee': otherfee.otherfee,'otherfeetitle':otherfee.otherfeetitle }));
        });


      }
    })
  }
  commissionaddUpdate() {
    console.log(this.filestatusgroup.value, "addcommission")
    let commissionModel: any;
    commissionModel = this.filestatusgroup.value;
    commissionModel['clientId'] = Number(this.clientid);
    commissionModel["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');
    commissionModel['createdBy'] = this.userTypeId + "";
    commissionModel['sSnegotitorFee'] = commissionModel.sSnegotitorFee + "";
    commissionModel['sharingValue'] = commissionModel.sharingValue + "";
    commissionModel['subTotal'] = commissionModel.subTotal + "";
    commissionModel['negoCommission'] = commissionModel.negoCommission + "";
    commissionModel['negoSplit'] = commissionModel.negoSplit + "";
    commissionModel['netAmount'] = commissionModel.netAmount + "";
    commissionModel['salesPrice'] = commissionModel.salesPrice + "";

    this._manageService.addUpdate_commission(commissionModel).subscribe((data: any) => {
      console.log(data, "addedcommison");
      this.toastr.showSuccess(data.message)
      this.filestatusgroup.markAsUntouched();


    })
  }
  commissionDownload(){
    console.log(this.filestatusgroup.value, "addcommission")
    let commissionModel: any;
    commissionModel = this.filestatusgroup.value;
    commissionModel['clientId'] = Number(this.clientid);
    commissionModel["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');
    commissionModel['createdBy'] = this.userTypeId + "";
    commissionModel['sSnegotitorFee'] = commissionModel.sSnegotitorFee + "";
    commissionModel['sharingValue'] = commissionModel.sharingValue + "";
    commissionModel['subTotal'] = commissionModel.subTotal + "";
    commissionModel['negoCommission'] = commissionModel.negoCommission + "";
    commissionModel['negoSplit'] = commissionModel.negoSplit + "";
    commissionModel['netAmount'] = commissionModel.netAmount + "";
    commissionModel['salesPrice'] = commissionModel.salesPrice + "";
    this._manageService.export_commission(commissionModel).subscribe((data: any) => {
      console.log(data, "addedcommison");
    if(data.extraData){
      window.open(data.extraData['ExcelUrl']);
    }

    },err=>{
      
    })
  }

  calcNegoCommission() {
    console.log(this.filestatusgroup.value.subTotal, this.filestatusgroup.value.negoCommission, this.filestatusgroup.value.netAmount)


    this.filestatusgroup.patchValue({ 'negoCommission': Number(this.filestatusgroup.value.subTotal) * Number(this.filestatusgroup.value.negoSplit / 100) })
    
    this.filestatusgroup.patchValue({ 'netAmount': Number(this.filestatusgroup.value.subTotal) - Number(this.filestatusgroup.value.negoCommission) - Number(this.netValue) })
console.log(Number(this.filestatusgroup.value.subTotal) - Number(this.filestatusgroup.value.negoCommission) - Number(this.netValue))
    // this.filestatusgroup.patchValue({ 'netAmount': Number(this.filestatusgroup.value.subTotal) - Number(this.filestatusgroup.value.negoCommission) - Number(this.filestatusgroup.value.netAmount) })
    //  let netamt =Number(this.filestatusgroup.value.subTotal) -(Number(this.filestatusgroup.value.subTotal) * Number(this.filestatusgroup.value.negoSplit / 100)) - Number(this.filestatusgroup.value.netAmount)

    //  console.log(netamt)
    // this.getTotalOthersFee()
// 
    // this.getTotalmisc();
    // this.getTotalOthersFee();
  }

  addMisc(char: any) {
    if (char == 'misc') {
      this.getMisc.push(this.formBuilder.formGroup(Misc));
      console.log(this.filestatusgroup)
      this.calcNegoCommission()
    } else if (char == 'other') {

      this.fee.push(this.formBuilder.formGroup(others));
      this.calcNegoCommission()
    }




  }
  removeMisc(index: any, char: any) {
    if (char == 'misc') {
      this.getMisc.removeAt(index)
      this.getTotalmisc()
      this.calcNegoCommission()
    } else {
      this.fee.removeAt(index)
      this.getTotalOthersFee()
      this.calcNegoCommission()

    }

  }
  //**END */

  //**SORT ORDER */
  setOrder(value: string) { if (this.order === value) { this.reverse = !this.reverse; } this.order = value; } //**END SORT ORDER */

  //**DROPDOWN SERVICE */
  dropDownlist() {

    this.dropdownService._getAll_state(1).subscribe((stateList: any) => {
      this.statelist = stateList.listData;
      console.log(this.statelist, "ssproposal")
    })
    this.dropdownService._getAll_branch().subscribe((branch: any) => {
      this.branchlist = branch.listData;
    })
    this.dropdownService._getAll_filetype().subscribe((filetype: any) => {
      this.filelist = filetype.listData;
    })
    this.dropdownService._getAll_lomb().subscribe((lomb: any) => {
      this.lomblist = lomb.listData;
    })
    // this.dropdownService._getAll_filetab().subscribe((filetab: any) => {
    //   console.log(filetab.listData, "filetablist");
    //   this.filetab = filetab.listData;
    // });
    this.dropdownService._getAll_property().subscribe((propertytype: any) => {
      this.propertytypelist = propertytype.listData;
    })
    this.dropdownService._getAll_RequiredBy(this.clientid).subscribe((requiredByList: any) => {
      this.requiresbyList = requiredByList.listData;
    })
  }

  //**END */




  onfileSelect(event: any) {
    console.log(event.target.file)
    let localvar: any = [];
    for (var i = 0; i < event.target.files.length; i++) {
      localvar.push(event.target.files[i]);
    }
    this.showFiles = localvar;
    console.log(this.showFiles);


  }

  get docArray() { return <FormArray>this.filestatusgroup.controls.docDetails; }
  multiple_UploadFiles(event: any, docid?: any) {
    this.fileuploadService.multiFile_upload(event.target.files, 'MutipleFileUpload').subscribe(resObjFile => {
      // console.log(data.mutiplesFile,"ji")
      let result = Object.keys(resObjFile.mutiplesFile).map(key => {
        this.showFiles.push({ "docmentUrl": resObjFile.mutiplesFile[key].DownloadPath, "documentName": resObjFile.mutiplesFile[key].FileName, "actualFileName": "", "renderUrl": resObjFile.mutiplesFile[key].DbPath })
        console.log(file, "added file")
        this.docArray.push(this.formBuilder.group({ 'fileName': resObjFile.mutiplesFile[key].FileName, 'actualFileName': resObjFile.mutiplesFile[key].FileName, 'fileUrl': resObjFile.mutiplesFile[key].DbPath, 'renderUrl': resObjFile.mutiplesFile[key].DbPath }))

      })
    })



  }

  sendOrUpdateDocs(event?: any, docid?: any) {
    console.log(docid)
    let upfiles: any = { docDetails: [], "createdBy": this.userTypeId + "" };

    this.fileuploadService.multiFile_uploadDoc(event.target.files).subscribe((resObjFile: any) => {
      let result = Object.keys(resObjFile.mutiplesFile).map(key => {
        upfiles.docDetails.push({ "documentUploadId": docid, "fileName": resObjFile.mutiplesFile[key].FileName, "fileUrl": resObjFile.mutiplesFile[key].DownloadPath, "actualFileName": resObjFile.mutiplesFile[key].FileName, "renderUrl": resObjFile.mutiplesFile[key].DbPath, "createdBy": this.userTypeId + "" })
        console.log(file, "added file")

      })
      this.fileuploadService.updateUploadDoc(upfiles);
    })

  }

  //**DOCUMENT TAB - CREATE DOCUMENT */
  select(item?: any, columnname?: any, obj?: any) {
    // alert("1")
    console.log(columnname, "id");

    console.log(obj)
    if (columnname != undefined && obj != undefined) {
      obj['fileName'] = columnname;
      obj['createdBy'] = this.userTypeId + "";
      this.fileuploadService.updateDocListNameById(obj).subscribe((updatesmsg: any) => {

        this.toastr.showSuccess(updatesmsg.message);
      })
    }

    this.selectedColumn = item;

  }
  isActive(item?: any) {
    // alert("2")
    this.checkItInfo = true;
    //  console.log(this.selectedColumn,this.updateDocName,"ID in selected columns")
    //  if(this.selectedColumn)
    return this.selectedColumn === item;
  };

  deleteNameInDoc(docid: any) {
    console.log(docid);
    this.fileuploadService.deleteDoclistById({ 'docUploadDetailsId': docid.docUploadDetailsId, 'createdBy': this.userTypeId + "" }).subscribe((deletedDoc: any) => {
      this.toastr.showSuccess(deletedDoc.message)
      this.getAllDocsById(docid.documentUploadId)
    })
  }
  getTaskId(id: any) {
    this.clientTaskID = id;

  }

  updateTaskNoteStatus(id: any, notesvalue: any, statusvalue: any) {
    let updateobj: any
    updateobj = {
      "clientTaskId": parseInt(id),
      "status": statusvalue,
      "notes": notesvalue,
      "createdBy": this.userTypeId + "",
      "createdOn": this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    }
    this._manageService.updateTaskDetails_inTabs(updateobj).subscribe((data: any) => {
      $("#filter_pending").modal('hide');
      this.toastr.showSuccess(data.message)
      updateobj = {}
    })
  }



  dragstarted(event: any, data: any, title: any, folder: any) {
    event.dataTransfer.setData("text", data);
    this.autogenDocListObject = { "titleId": parseInt(title), "folderId": parseInt(folder) }




  };

  ondrop(event: any, docid: any) {

    console.log(docid, "docid")
    event.preventDefault();
    var data = event.dataTransfer.getData("text");
    console.log(data, "drop")
    this._manageService.get_autogendoc(this.autogenDocListObject).subscribe((docdetails: any) => {

      this.selectAutoGenDoc = docdetails.listData[0].autogenerateddocfiles;

      if (this.selectAutoGenDoc) {
        this.IsDropping = true;
      }


    })


    this.addDoctypeDetails = {

      "clientId": parseInt(this.clientid),
      // "docTypeName": data,
      // "docStatusId": 1,
      "createdBy": this.CurrentLoggedUserId + ""
    };
    // this._manageService._AddDoc_type(addDoctype).subscribe((adddoctype: any) => {
    //   console.log(addDoctype, "DOCTYPE")
    //   this._getAll_Doclist();
    //   this.toastr.showSuccess("DOCTYPE ADDED SUCCESSFULLY")
    // }, err => {
    //   this.toastr.showError("SOMETHING ERROR")
    // })
  }
  allowDrop(event: any) { event.preventDefault(); console.log("allo"); this.checkedList = [] };

  isAllSelected(checklistData: any, docsfrom: string) { let docsList = docsfrom; this.masterSelected = checklistData.every(function (item: any) { return item.isSelected == true; }); this.getCheckedItemList(checklistData, docsList); }

  getCheckedItemList(checklist: any, docslist: any) {
    this.checkedList = [];
    for (var i = 0; i < checklist.length; i++) {
      if (checklist[i].isSelected) {
        console.log(checklist[i], "newdata")
        if (docslist == 'autoGenDoc') {
          this.checkedList.push({ "fileName": checklist[i].documentName, "fileUrl": checklist[i].docmentUrl })

        } else if (docslist == 'emails') {
          this.checkedList.push(checklist[i].fileUrl)
        }
      }
    }
    this.checkedList = this.checkedList;
    // console.log(this.checkedList)
  }
  saveAutoDocList(send: any) {
    if (send == 'true') {

      this.addDoctypeDetails.docDetails = this.checkedList
      console.log(this.checkedList);
      if (this.checkedList.length == 0) {
        this.toastr.showError('select atleast one file to add')
      } else {
        this._manageService._addDoc_typeV2(this.addDoctypeDetails).subscribe((data: any) => {
          this.toastr.showSuccess(data.message)
          console.log(data, "curretn status od doctype")
          this._getAll_Doclist();
          this.IsDropping = false;
        })
      }

    }
    else if (send == 'false') {
      this.IsDropping = false;
    }



  }


  deleteFileDoc(i: any) {
    this.docArray.removeAt(i);
  }
  getAllDocsById(docid: any) {

    console.log(docid)
    this._manageService._getdataDoc_Byid(docid).subscribe((getalldocbyid: any) => {
      this.getDocsListById = getalldocbyid.getData.docDetails;
      console.log("this.getDocsListById", this.getDocsListById);
    })
  }
  getEditDocDetails(docId?: number) {
    this.filestatusgroup.reset();
    this.docArray.clear();
    this.updateDocDetails = true;
    this._manageService._getdataDoc_Byid(docId).subscribe((getDocData: any) => {
      this.filestatusgroup.patchValue({ 'documentUploadId': getDocData.getData.documentUploadId, 'docTypeNames': getDocData.getData.doctypeName, 'docStatusId': getDocData.getData.docStatusId, 'requiredBy': getDocData.getData.requiredBy, 'notes': getDocData.getData.notes });

      getDocData.getData.docDetails.forEach((file: any) => {
        this.docArray.push(this.formBuilder.group({ 'docUploadDetailsId': file.docUploadDetailsId, 'fileName': file.fileName, 'actualFileName': file.fileName, 'fileUrl': file.fileUrl, 'renderUrl': file.fileUrl, 'createdBy': this.userTypeId + "" }))
        this.showFiles.push({ "docmentUrl": file.renderUrl, "documentName": file.fileName, "actualFileName": file.actualFileName, "renderUrl": file.fileUrl })
      });
    })
  }
  clearForm() {
    this.filestatusgroup.reset();
  }
  addDocument(update?: Boolean, docId?: number,) {
    this.submitted = true;
    let docObj: any;
    docObj = this.filestatusgroup.value;
    docObj.clientId = parseInt(this.clientid);
    docObj.docStatusId = parseInt(docObj.docStatusId);
    docObj.createdBy = this.userTypeId + "";
    docObj['docTypeName'] = docObj.docTypeNames;
    docObj["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    // this.filestatusgroup.value.docStatusId = parseInt(this.filestatusgroup.value.docTypeId);

    if (this.updateDocDetails === false) {

      console.log(this.filestatusgroup, "adddDOCument")

      this._manageService._createnew_Document(docObj).subscribe((data: any) => {
        $('#create_new').modal('hide');
        this.toastr.showSuccess(data.message);
        this.filestatusgroup.markAsUntouched();
        this.filestatusgroup.reset();
        this.docArray.clear();
        this._getAll_Doclist();
      }, err => {
        $('#create_new').modal('hide')
        this.toastr.showError("something went wrong");
        this.filestatusgroup.markAsUntouched();
      })
    } else if (this.updateDocDetails == true) {
      this._manageService._updatenew_Document(docObj).subscribe((updatedData: any) => {
        $('#create_new').modal('hide');
        this.updateDocDetails = false;
        this.filestatusgroup.markAsUntouched();
        this.toastr.showSuccess(updatedData.message);
        this.filestatusgroup.reset();
        this.docArray.clear();
        this._getAll_Doclist();

        // console.log("Doc Updated status")
      }, err => {
        this.toastr.showError("something went wrong");
        this.filestatusgroup.markAsUntouched();
      })


    }

  }


  //** DOCUMENT TAB TASK  */



  search_Document(searchword: any) {
    let searchData = {
      "clientId": this.clientid,
      "serachWord": searchword
    }
    this._manageService._search_Document(searchData).subscribe((searchdataList: any) => {
      this.docsList = searchdataList.listData;
      console.log("doclist  +", this.docList)
    })
  }

  deleteAlldoc(docid: any) {
    this._manageService._delete_DocumentList(docid, this.clientid).subscribe((deletedData: any) => {
      this.toastr.showSuccess("Data Deleted");
      this._getAll_Doclist();
    }, err => {
      this.toastr.showError("something went wrong");
    })
  }
  downloadAlldoc(docid: any) {
    this.fileuploadService.downloadzipBy_Docid(docid);
  }

  getDocstatus() {
    this.dropdownService._getAll_DocStatus().subscribe((docstatus: any) => {
      this.docsStatusList = docstatus.listData;
    })
  }
  _getAll_Doclist() {
    this._manageService._getAll_document(this.clientid).subscribe((doclist: any) => {
      this.docsList = doclist.listData;

    })
  }
  updateDocStatus(event: any, docid: any) {
    console.log(event.target.value)
    this._manageService._update_DocStatus(event.target.value, docid).subscribe((statusData: any) => {
      this.toastr.showSuccess("Status Updated");
      this._getAll_Doclist();
    }, err => {
      this.toastr.showError("something went wrong")
    })
  }

  docNotes(docid?: any, notes?: any) {
    this.DoclistId = parseInt(docid);
    this.DocListNote = notes;

  }

  updateDocNotes(notes?: any) {
    this.DocListNote = notes;
    this._manageService._update_DocNotes(notes, this.DoclistId).subscribe((notesData: any) => {
      this.toastr.showSuccess("Notes Updated");
      this._getAll_Doclist();
    }, err => {

      this.toastr.showError("something went wrong")
    })
  }

  //**END */
  select_dropdown(id: any, selecteddata: any, htmlupdate?: Boolean, i?: any, state?: string) {

    let targetid;
    if (htmlupdate) {
      targetid = parseInt(id.target.value);

    } else {
      targetid = id;
    }



    // console.log(ids)
    if (selecteddata == 'state') {
      this.dropdownService._getAll_state(targetid).subscribe((stateList: any) => {

        this.statelist = stateList.listData;
        console.log(stateList)
      })
    }
    else if (selecteddata == 'city') {
      this.dropdownService._getAll_city(targetid).subscribe((cityList: any) => {
        this.citylist = cityList.listData;
        console.log(this.citylist)
        if (state == 'ssAttorney') {
          this.attorneyCityListArray[i].cityList = cityList.listData;
        } else {

          this.cityListArray[i].cityList = cityList.listData;
        }
        // console.log("this.cityListArray ", this.cityListArray);

      })
    }

    else if (selecteddata == 'userType') {
      this.dropdownService._get_Userlist(targetid).subscribe((getuserlist: any) => {
        this.userLists = getuserlist.listData;
        console.log(this.userLists, "dsdsdsf")
      })


    }
  }


  //** Document Tab SendEMail &*/
  sendEmail(data?: any) {

    this.emailsendBody['clientId'] = this.clientid + "";
    // this.toEmailids.join(';');

    // this.emailsendBody.toMailIds += ';' + this.toEmailids.join(';').toString();
    this.emailsendBody.toMailIds +=this.toEmailids;
    console.log(this.emailsendBody.toMailIds)
    this.emailsendBody["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')
    this.emailsendBody['bodyHtml'] = data?.value;
    if (this.getDocLists.length == 0 || this.checkedList.length == 0) {

      this.emailsendBody['attachmentUrl'] = []
    } else {

      this.emailsendBody['attachmentUrl'] = this.checkedList

    }
    // this.emailsendBody.attachmentUrl.forEach((data: any) => {
    //   this.emailsendBody.attachmentUrl.push(data['DownloadPath']);
    //   console.log(data['DownloadPath'])

    // });
    // this.emailsendBody.attachmentUrl(0);
    this._manageService._email_send(this.emailsendBody).subscribe((emailobj: any) => {
      this.emailsendBody = { 'attachmentUrl': [], 'toMailIds': "" };
      this.emailsendBodyShow = { 'attachmentUrl': [], 'toMailIds': "" };
      this.checkedList = [];
      this.emailsendBody.toMailIds = "";
      this.toEmailids = []
      this.assignedUsers = [];
      this.assignedUsers = this.currentclientData.assignedUsers;
      // this.onFormReset()
      this.email_list();

      $('#create_news_send').modal('hide');
      this.toastr.showSuccess(emailobj.message)
    }, err => {
      this.toastr.showError("something went wrong")

    });
  }

  email_list() {
    this._manageService._getAll_emails(this.clientid).subscribe((emailList: any) => {
      this.emaillistings = emailList.listData;
    })
  }
  remove_mailAttachment(index: number) {

    this.emailsendBodyShow.attachmentUrl.splice(index, 1)
    this.emailsendBody.attachmentUrl.splice(index, 1)


  }
  emailAttachmentUpload(event: any) {
    console.log(event.target.files[0])
    this.fileuploadService.multiFile_upload(event.target.files, 'SendEmailAttachment').subscribe((emailfileObj: any) => {
      console.log(emailfileObj.mutiplesFile)
      let result = Object.keys(emailfileObj.mutiplesFile).map(key => {
        this.emailsendBodyShow.attachmentUrl.push({ "dbPath": emailfileObj.mutiplesFile[key].DbPath, "DownloadPath": emailfileObj.mutiplesFile[key].DownloadPath, "FileName": emailfileObj.mutiplesFile[key].FileName })
        this.emailsendBody.attachmentUrl.push(emailfileObj.mutiplesFile[key].DbPath);
      })
    })

  }

  download_Zip_doc() { this.fileuploadService.download_zip(this.clientid); }

  onFormReset(id?: any) {
    // alert()
 
    this.emailsendBodyShow = { 'attachmentUrl': [] };
    this.emailsendBody = { 'attachmentUrl': [] };
    this.emailsendBody.toMailIds = "";
    this.toEmailids = [];
    this.checkedList = [];
    this.assignedUsers = this.currentclientData.assignedUsers;
    this.openDocPopup = false;

    $('#' + id).modal('hide');
    this.emailsending.resetForm()

  }
  sendDocupload(e?: any) {
    this.emailsendBody['clientId'] = this.clientid + "";
    // this.emailsendBody.toMailIds+=this.toEmailids.join(';').toString();
    if(this.emailsendBody.toMailIds){
      this.emailsendBody.toMailIds += ';' + this.toEmailids.join(';').toString();
    }
    
console.log("this.emailsendBody.toMailIds", this.emailsendBody.toMailIds)
    this.emailsendBody['bodyHtml'] = e.value;
    this.emailsendBody['createdOn'] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');
    if (this.getDocLists.length == 0 || this.checkedList.length == 0) {
      // alert()
      this.emailsendBody['attachmentUrl'] = []
    } else {
      this.emailsendBody['attachmentUrl'] = this.checkedList

    }
    // this.emailsendBody['attachmentUrl'] = this.checkedList;
    // this.emailsendBody.attachmentUrl.forEach((data: any) => {
    //   this.emailsendBody.attachmentUrl.push(data['dbPath']);
    //   console.log(data['DownloadPath'])

    // });
    // this.emailsendBody.attachmentUrl.removeAt(0);
    if(this.emailsendBody.toMailIds && this.emailsendBody.subject){
      this._manageService._send_Docupload(this.emailsendBody).subscribe((emailobj: any) => {
        this.emailsendBodyShow = { 'attachmentUrl': [] };
        this.emailsendBody = { 'attachmentUrl': [] };
        this.emailsendBody.toMailIds = "";
        this.toEmailids = [];
        this.checkedList = [];
        this.assignedUsers = [];
        this.assignedUsers = this.currentclientData.assignedUsers;
        // this.onFormReset()
        $('#upload-request-g').modal('hide');
        this.toastr.showSuccess(emailobj.message)
      }, err => {
        this.checkedList = [];
        this.toastr.showError("something went wrong")
      });
    }
    
  }
  getallManageAutogenList() {
    this._manageService.get_allautogendocdetails().subscribe((autoGenDoc: any) => {
      this.autoGenDoclists = autoGenDoc.listData;
      console.log('autoGenDoc.listData', this.autoGenDoclists);
    })
  }
  assignedLead_list() {
    this._manageService._getall_AssignedClientbyuser().subscribe((data: any) => {

      this.clientList = data.listData;
      console.log(data.listData[0].assignedUsers, 'dataClient');

      if (this.clientList !== null) {
        this.clientContactCommon = data.listData[0].contactUs;
        this.contactDetails = this.clientContactCommon.apexContactDetails;
        // this.assignedUsersList = data.listData
      }

      console.log(this.clientContactCommon, "contactCommon")
      console.log(this.contactDetails, "contact2222")
    })
  }
  //**END */
  //LOA
  getall_LOAdetails() {
    this._manageService.getAll_LOADetails(this.leadId).subscribe((resp: any) => {
      console.log(resp, 'client_LOA');
      this.clientLOADetailsList = resp.listData[0];
      console.log(this.clientLOADetailsList, 'client_LOA__LISt');
    })

  }

  //task
  getAllClientTask() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "hi cuser ")
    this._manageService.getAllTask_Tabs(this.clientid).subscribe((res: any) => {
      console.log(res, "ttd")
      //this.leadData = res.listData;

      this.clientTask = res.listData;


    })
  }


  viewmore(nextIndex: any) { this.route.navigateByUrl('/superadmin/task-list-view', { state: { obj: nextIndex } }) }

  getclienttabList() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "hi cuser ")
    this._manageService.get_Clientfileno(CurrentUserId).subscribe((res: any) => {
      this.clienttabData = res.listData;
      this.clienttabDataBack = res.listData;
    })
  }

  getAssignedUserss(id: any, i: number) {
    let userObject = {
      //clientDataId
      "clientid": this.clientid,
      "userTypeID": parseInt(id),
      "CurrentUserId": parseInt(this.CurrentLoggedUserId)
    }
    this._manageService.get_AssignedUser(userObject).subscribe((res: any) => {
      this.assignuserData[i] = res.listData;
      this.assignuserDataBack[i] = res.listData;
    })
  }

  getAssignedUserTypeByClient() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this._manageService.get_AssignedUserTypeByClient(CurrentUserId).subscribe((res: any) => {
      this.assignedClientData = res.listData;
      this.assignedClientDataBack = res.listData;
    })
  }

  getAssignedUser(id: any) {

    let userObject = {
      "clientid": this.clientid,
      "userTypeID": parseInt(id) ? parseInt(id) : this.typeId,
      "CurrentUserId": parseInt(this.CurrentLoggedUserId)
    }
    this._manageService.get_AssignedUser(userObject).subscribe((res: any) => {
      this.assignuserData = res.listData;
      this.assignuserDataBack = res.listData;

    }, err => {
      console.log("err getAssignedUser", err);
      this.assignuserData = [];
      this.assignuserDataBack = [];
    })
  }

  get_GetTaskUsingFilters() {
    let userObject = {
      "clientid": this.clientDataId,
      "usertypeid": this.typeId,
      "userid": this.closerId,
      "createdBy": this.tokenStorage._get_userTypeId()
    }
    this._manageService.get_GetTaskUsingFilters(userObject).subscribe((res: any) => {
      this.clientTask = res.listData;
    })

  }
  getTemplatetabList(data: number) {
    this._manageService.get_tasktemplates(data).subscribe((res: any) => {
      this.templatetabData = res.listData;
      this.templatetabDataBack = res.listData;
    })
  }

  onAssign(assignlastVal: any) {
    this.closerId = parseInt(assignlastVal.target.value)
    console.log(this.closerId, "Hello3");
  }

  onselectAssign(assignedVal: any) {
    this.getAssignedUser(assignedVal.target.value);
    console.log(assignedVal, "assigned");
    this.typeId = parseInt(assignedVal.target.value)
    console.log(this.typeId, "Hello2");
  }

  onselectAssignuser(assignedVals: any, i: number) {
    //this.getAssignedUserss(assignedVals.target.value, i);
    console.log(assignedVals, "assigned");
    this.taskDetailList[i]['assingnuserid'] = parseInt(assignedVals.target.value);
  }


  onreset() {
    this.clienttabData = [];
    this.assignedClientData = [];
    this.assignuserData = [];
    this.getclienttabList();
    this.getAllClientTask();
    // this._manager_service.get_GetTaskUsingFilters(userObject).subscribe((res: any) => {
    //   this.clientTask = res.listData;
    // })
  }
  //task



  //Mailing Selected Link
  setradio(e: string): void {

    this.selectedLink = e;
  }
  isSelected(name: string): boolean {

    if (!this.selectedLink) { // if no radio button is selected, always return false so every nothing is shown
      return false;
    }
    return (this.selectedLink === name); // if current radio button is selected, return true, else return false
  }
  //Mailing Selected Link


  //AcceptorReject
  acceptLead() {
    this.acceptObj = {
      "acceptorReject": 1,
      "leadId": this.CurrentGetidLead
    }
    console.log(this.acceptObj, 'Acept')
    this._manageService.getacceptLead(this.acceptObj).subscribe((acceptLead: any) => {
      if (acceptLead.reponse == true) {

        this.toastr.showSuccess("Lead Accepted Successfully")
        this.route.navigate(['superadmin/dashboard'])
      } else {
        this.toastr.showError("ERROR Occured")
      }
      console.log(acceptLead, 'accLead')
    })

  }

  //RejectLead
  getrejectLead() {
    //return id\
    this.openPopReject = true;
  }

  get120DueAmount() {
    var self = this;
    var arr: any = document.getElementsByName('summary100');
    console.log("arr ", arr);
    self.closingTabModel['grossAmountDueFromBorrower'] = 0;
    for (var i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].value.split('$')[1]))
        self.closingTabModel['grossAmountDueFromBorrower'] += arr[i].value.split('$')[1] ? parseInt(arr[i].value.split('$')[1]) : 0;
    }
    // self.closingTabModel.sellerSummaryModel['grossAmountSeller2'] = self.closingTabModel.sellerSummaryModel['grossAmountDueFromBorrower'];
    console.log("self.closingTabModel['grossAmountDueFromBorrower'] ", self.closingTabModel['grossAmountDueFromBorrower']);
  }

  get220DueAmount() {
    var self = this;
    var arr: any = document.getElementsByName('summary200');
    console.log("arr ", arr);
    self.closingTabModel['totalForBorrower'] = 0;
    for (var i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].value.split('$')[1]))
        self.closingTabModel['totalForBorrower'] += arr[i].value.split('$')[1] ? parseInt(arr[i].value.split('$')[1]) : 0;
    }
    // self.closingTabModel.sellerSummaryModel['grossAmountSeller2'] = self.closingTabModel.sellerSummaryModel['grossAmountDueFromBorrower'];
    console.log("self.closingTabModel['totalForBorrower'] ", self.closingTabModel['totalForBorrower']);
  }

  get420DueAmount() {
    var self = this;
    var arr: any = document.getElementsByName('summary400');
    self.closingTabModel.sellerSummaryModel['grossamountdueof420'] = 0;
    for (var i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].value))
        self.closingTabModel.sellerSummaryModel['grossamountdueof420'] += parseInt(arr[i].value);
    }
    self.closingTabModel.sellerSummaryModel['grossAmountSeller2'] = self.closingTabModel.sellerSummaryModel['grossamountdueof420'];
    console.log("self.xclosingTabModel.sellerSummaryModel['grossamountdueof420'] ", self.closingTabModel.sellerSummaryModel['grossamountdueof420']);
  }

  get520DueAmount() {
    var self = this;
    var arr: any = document.getElementsByName('summary500');
    self.closingTabModel.sellerSummaryModel['dueamountof520'] = 0;
    for (var i = 0; i < arr.length; i++) {
      console.log("i ", i);
      if (parseInt(arr[i].value))
        self.closingTabModel.sellerSummaryModel['dueamountof520'] += parseInt(arr[i].value);
    }
    self.closingTabModel.sellerSummaryModel['lessRedectionSeller'] = self.closingTabModel.sellerSummaryModel['dueamountof520'];
    console.log("self.closingTabModel.sellerSummaryModel['lessRedectionSeller'] ", self.closingTabModel.sellerSummaryModel['lessRedectionSeller']);
    console.log("self.xclosingTabModel.sellerSummaryModel['dueamountof520'] ", self.closingTabModel.sellerSummaryModel['dueamountof520']);
  }

  get1400SellerFund() {
    var self = this;

    var arr: any = document.getElementsByName('total1400');
    self.closingTabModel.setetlementcharges['totalsellerfund1400'] = 0;
    for (var i = 0; i < arr.length; i++) {
      console.log("i get1400SellerFund", i);
      if (parseInt(arr[i].value))
        self.closingTabModel.setetlementcharges['totalsellerfund1400'] += parseInt(arr[i].value);
    }
    // self.closingTabModel.setetlementcharges['totalsellerfund1400'] = self.closingTabModel.setetlementcharges['totalsellerfund1400']? self.closingTabModel.setetlementcharges['totalsellerfund1400']: 0;
    // self.closingTabModel.setetlementcharges['totalsellerfund1400'] += self.closingTabModel.setetlementcharges[key]? self.closingTabModel.setetlementcharges[key]: 0;
    self.closingTabModel.sellerSummaryModel['amountof502'] = self.closingTabModel.setetlementcharges['totalsellerfund1400'];
    setTimeout(() => {
      this.get520DueAmount()
    }, 1000);
    console.log("self.closingTabModel.setetlementcharges['totalsellerfund1400'] ", self.closingTabModel.setetlementcharges['totalsellerfund1400']);
  }

  getLoanList() {
    // alert()
    var self = this;
    self._manageService.get_loanData(self.clientid).subscribe((data: any) => {
      //  this.filestatusgroup = data.getData;
      self.loanList = data.listData;      //**not showing data yet*/
      console.log("getLoanList", data)
      // for (let i = 0; i < 5; i++) {
      //   // const element = array[i];

      // }
      // if (this.closingStatementModel.sellerSummaryModel != null) {
      // alert()
      self.loanList.slice(0, 6).forEach((item: any, i: number) => {
        console.log(item.loanBalanceForClose)
        self.closingTabModel.sellerSummaryModel[self.payoffKeys[i]] = item.loanBalanceForClose ? item.loanBalanceForClose : 0

        console.log(self.closingTabModel.sellerSummaryModel[self.payoffKeys[i]])
      });
      console.log("self.closingTabModel.sellerSummaryModel ", self.closingTabModel.sellerSummaryModel);

      setTimeout(() => {
        this.get520DueAmount()
      }, 1000);
      // }

    })
  }

  getCityList(id: number, i: number, state: string) {
    var self = this;
    self.dropdownService._getAll_city(id).subscribe((cityList: any) => {
      if (state == 'ssAttorney') {
        self.attorneyCityListArray[i].cityList = cityList.listData
      } else {
        self.cityListArray[i].cityList = cityList.listData
      }

      console.log("self.cityListArray ", self.cityListArray);
    })
  }

  removeValueFormArray(i: any, state: string) {
    var self = this;
    if (state == 'buyersUpload') {
      self.buyerFile.splice(i, 1);
    } else {
      self.cmaFile.splice(i, 1);
    }

  }

  styleObject(id: number) {
    if (id == 1) {
      return { 'background': '#3498DB' };
    } else if (id == 2) {
      return { 'background': '#F39C12' };
    } else if (id == 3) {
      return { 'background': '#2ECC71' };
    } else if (id == 4) {
      return { 'background': '#D35400' };
    } else if (id == 5) {
      return { 'background': '#FD79A8' };
    } else if (id == 6) {
      return { 'background': '#E74C3C' };
    }
    return {}
  }

  getBrokerOrAgentInfo(id: any) {
    var self = this;;
    self._manageService._get_brokerOrAngentInfo(id).subscribe((res: any) => {
      console.log("res getBrokerOrAgentInfo", res);
      var data = res.getData;
      this.filestatusgroup.controls.clientSSBuyerAgentorBrokerinfo.patchValue({
        "name": data.userName,
        "agency": '',
        "realEstateLieNo": data.licenseNumber,
        "phone": data.phoneNo,
        "extNo": '',
        "cellNo": '',
        "faxNo": '',
        "email": data.emailId
      })
    })
  }

  getBuyerInfo() {
    var self = this;
    self._manageService._get_buyerInfo(this.clientid).subscribe((res: any) => {
      console.log("res ==>getBuyerInfo", res);
      self.buyerList = res.listData ? res.listData : [];
    })
  }
  selectedState(value: any) {
    return value == this.stateNameProp;
  }
  openModel(data: any) {
    var self = this;
    self.docList = data.document ? data.document : [];
  }

  getZillowSearch(statecode: any, city: any) {

    var that = this;
    const settings = {
      "async": true,
      "crossDomain": true,
      "url": "https://zillow6.p.rapidapi.com/search",
      "method": "POST",
      "headers": {
        "content-type": "application/x-www-form-urlencoded",
        "x-rapidapi-key":  "74c8f75866msh3af843814ed4985p1c36d5jsn77556d9834fd",
        "x-rapidapi-host": "zillow6.p.rapidapi.com"
      },
      "data": {
        "city": city,
        "state_code": statecode,
        "page": "1"
      }
    };

    $.ajax(settings).done(function (response: any) {
      console.log(response);
      that.realtorAddress = response.properties;
      that.realtorAddressBack = response.properties;
    });
  }


  getrealtorValueaddress(zipCode: any) {
    var self = this;
    const settings = {
      "async": true,
      "crossDomain": true,
      "url": `https://realtor.p.rapidapi.com/properties/list-for-sale?postal_code=${zipCode}&sort=relevance`,
      "method": "GET",
      "headers": {
        "x-rapidapi-key": "50db61e927msh102d1b0b29b8233p1bf4b9jsn58aed8db00b8",
        "x-rapidapi-host": "realtor.p.rapidapi.com"
      }
    };

    $.ajax(settings).done(function (response: any) {
      console.log('realtor', response);
      self.realtorAddress = response.listings;
      self.realtorAddressBack = response.listings;
    });

  }


  createTaskTemplate() {
    sessionStorage.setItem('createTabtemp', 'true')
    this.route.navigateByUrl('/superadmin/task-template-detail')
  }
  selectedrealtorAddress(value: any) {
    var self = this;
    if (value == 'realtor') {
      self.filestatusgroup.controls.realtorValue.setValue(self.selectedRealtorprice);
      self.realtorPopup = false;
      this.clientPropertySetAverageValue();

    } else {
      self.filestatusgroup.controls.zillowValue.setValue(self.selectedZillowPrice);
      self.zillowpopup = false;
    }



    console.log("self.selectedRealtorprice ", self.selectedRealtorprice);
  }

  clientPropertySetAverageValue() {
    var self = this;
    var data = self.filestatusgroup.value,
      realtorValue = data.realtorValue ? parseInt(data.realtorValue) : 0,
      zillowValue = data.zillowValue ? parseInt(data.zillowValue) : 0,
      brokerAgentValue = data.brokerAgentValue ? parseInt(data.brokerAgentValue) : 0,
      total = realtorValue + zillowValue + brokerAgentValue,
      count = 0;
    if (realtorValue != 0) {
      count += 1
    }

    if (zillowValue != 0) {
      count += 1
    }

    if (brokerAgentValue != 0) {
      count += 1
    }
    console.log("count ", count);
    var average = total / count;
    console.log("average ", average);
    console.log("self.filestatusgroup.value ", self.filestatusgroup.value);

    self.filestatusgroup.controls.averageValue.setValue(average);
  }



  onselectAssignss(assignedVal: any, i: number) {
    this.getAssignedUserss(assignedVal.target.value, i);
    console.log(assignedVal, "assigned");
    this.typeId = parseInt(assignedVal.target.value)
    console.log(this.typeId, "Hello2");
    this.taskDetailList[i]['assignUserTypeid'] = parseInt(assignedVal.target.value);

  }

  onSearchfileNo(e: any) {
    this.filestabData = this.filestabDataBack.filter((item: any) => item.fileNo.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearchTemplate(e: any) {
    this.templatetabData = this.templatetabDataBack.filter((item: any) => item.templateName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }



  getclientFileList() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    this.userloggedIn = this.tokenStorage._get_userRole();
    console.log(CurrentUserId, "hi cuser ")
    this._manageService.get_Clientfileno(CurrentUserId).subscribe((res: any) => {
      this.filestabData = res.listData;
      this.filestabDataBack = res.listData;
      //this.getAssignedUserTypeByClient(res.listData[0].Clientid);
      // console.log(this.getLeadTaskList, 'listLeadTask')

    })
  }


  editTaskTemplate(editData: any) {

    this._manageService.get_tasktemplatedetail(editData).subscribe((res: any) => {
      //this.tasktemplateForm.patchValue({ 'templateName': res.listData[0].taskTemplateName, 'taskTemplateId': parseInt(res.listData[0].taskTemplateId) });
      // this.t.removeAt(0);
      this.taskDetailList = res.listData[0].taskchilddetails;
      console.log("hhhhhhhh", this.taskDetailList)
      // res.listData[0].taskchilddetails.forEach((data: any) => {
      //   this.t.push(this.formBuilder.group(
      //     { 'day': data.day, 'description': data.description, 'priority': data.priority, 'fileTabId': data.assignedTabId, 'createdBy': sessionStorage.getItem('userTypeId') }
      //   ))
    })
  }

  OnTaskSelect(assignval: any) {
    console.log("taskselect", assignval);
    this.editTaskTemplate(assignval);
  }
  onSearchTypeName(e: any) {
    this.assignedClientData = this.assignedClientDataBack.filter((item: any) => item.userTypeName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearchUserName(e: any) {
    this.assignuserData = this.assignuserDataBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }


  onselectClient(assignClientVal: any) {
    // this.getAssignedUserTypeByClient();
    console.log(assignClientVal, "assignedClientId");
    this.clientDataId = parseInt(assignClientVal);

    this.getAssignedUser(null);

  }


  addtasktemplateformSubmit() {

    this.submitted = true;
    this.addtasktemplatefrm.value.clientId = parseInt(this.addtasktemplatefrm.value.clientId);
    this.addtasktemplatefrm.value.templateId = parseInt(this.addtasktemplatefrm.value.templateId);
    let fullList: any = [];
    console.log(this.taskDetailList);
    var date = new Date();
    this.taskDetailList.forEach((element: any, i: any) => {
      fullList.push({
        "clientId": this.clientid,
        "day": element.day,
        "description": element.description,
        "tabId": parseInt(element.assignedTabId),
        "priority": element.priority,
        "assignedUserId": element.assingnuserid,
        "assignedUserTypeId": element.assignUserTypeid,
        "completionDate": moment(new Date(date.setDate(date.getDate() + parseInt(element.day)))).format('MM/DD/YYYY'),
        "createdBy": sessionStorage.getItem('userTypeId'),
        "createdOn": this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

      })
    });

    console.log('fullList', fullList);

    // return false;
    this._manageService.add_TaskFromTaskTemplate(fullList).subscribe((addtasktemp: any) => {
      console.log(addtasktemp, "add task template");
      if (addtasktemp.reponse == true) {
        this.toastr.showSuccess('Task Template Created');
        // this.route.navigate(['/superadmin/task-list']);
        this.addtasktemplatefrm.reset();
        this.taskDetailList = [];
        this.getAllClientTask();
        $('#filterbyss').modal('hide');
      } else {
        this.toastr.showError('ERROR OCCURED');
      }
    });
  }



  docTabUpdateButton() {
    this.toastr.showSuccess('Updated SuccessFully')
  }

  editTasktab() {
    sessionStorage.setItem('edittasktab', 'true')
  }
  editTaskTemplatefromtab() {
    sessionStorage.setItem('edittemplatetab', 'true')
  }
  onSearchRealtoradd(e: any) {
    this.realtorAddress = this.realtorAddressBack.filter((item: any) => item.address.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }


  get_CancelStatus() {
    let userObject = {
      "fileStatusId": this.fileCloedStatus,
      "clientId": this.fileCloedStatusId,
      "createdBy": this.tokenStorage._get_userTypeId()
    }
    console.log(userObject, 'resp_closedfile')
    this._manageService._cancel_status(userObject).subscribe((res: any) => {
      if(res){
        this.assignedLead_list();
      }

    })

  }
  Canceltransaction() {
    this.get_CancelStatus();
    this.route.navigateByUrl('/superadmin/clients');
  }

  emailViewModel(data: any) {
    console.log("data emailViewModel", data);
    this.viewEmail = data;
  }

  textsplit(val: string) {
    var splitData = String.raw`${val && val.length > 0 ? val[0] : ''}`.split("\\")
    return splitData[splitData.length - 1]
  }

  getDocListOnPopup() {
    this._manageService.get_createUsers(this.clientid).subscribe((res: any) => {

      this.getDocLists = res.listData;
      this.openDocPopup = true;
      console.log(res, "createUser109")
    })
  }
  //** Inivite HomeOwner */

  invitehomeOwner() {
    let invite = {
      leadId: Number(this.currentclientData.leadId),
      homeOwnerId: Number(this.currentclientData.homeOwnerId),
      homeOwnerMail: this.getAllHomeOwnerList.emailId || this.currentclientData.emailId,
      isHomeOwnerInvited: true
    };
    this._manageService
      .updateInviteHomeowner(invite)
      .subscribe((respHomeowner: any) => {
        console.log(respHomeowner, 'sas');
        if (respHomeowner.reponse == true) {
          this.isInviteHomeOwnerDB = true;
          this.getClientDetails(this.clientid, false)
          this.toastr.showSuccess('User has been invited successfully');
        } else {
          this.toastr.showError('Error Occured');
        }
      });

  }

  getDocumentsUploadId(id?: any) {
    // sessionStorage.removeItem('docIdDocuSign');
    this.docIdDocuSign = id;
    // $("#require-modal").modal('hide');
    // sessionStorage.setItem('docIdDocuSign',this.docIdDocuSign)
  }
  docusignSend(docForm: any) {
    console.log("docum", docForm);
    // return false;
    this.submitted = true;
    if (docForm.form.valid) {
      if (this.docuSign.gender == "internal") {
        window.open("https://apexresolution-docusign.colanonline.net/eg011?Userid=" + this.homeOwnerId + "&DocumentID=" + this.docIdDocuSign+"&firstName="+null+"&lastName="+null)
      }
      // else if(this.docuSign.gender == "internal" && this.userTypeId==8){
      //   window.open()
      // }
      else if (this.docuSign.gender == "external") {
        window.open("https://apexresolution-docusign.colanonline.net/eg011?signermail=" + this.docuSign.signermailexternal + "&DocumentID=" + this.docIdDocuSign+"&firstName="+this.docuSign.firstName+"&lastName="+this.docuSign.lastName)
      }
      else if (this.docuSign.gender == "both") {

        window.open("https://apexresolution-docusign.colanonline.net/eg011?Userid=" + this.homeOwnerId + "&DocumentID=" + this.docIdDocuSign + "&signermail=" + this.docuSign.signermailboth+"&firstName="+this.docuSign.firstName+"&lastName="+this.docuSign.lastName)
      }

      // window.open("https://qarealestate-api.colanonline.net/eg011?Userid=" + this.clientid + "&DocumentID=" + this.docIdDocuSign + "")
      $('#radioPopupModal').modal('hide');
      $("#require-modal").modal('hide');
      docForm.resetForm();
    }
    console.log(this.docIdDocuSign);




  }

  getEnStatus(docIdDocuSigning: any, enevelopeId: any) {
    window.open("https://apexresolution-docusign.colanonline.net/eg004?DocumentID=" + docIdDocuSigning + "&EnvID=" + enevelopeId)
    
    setTimeout(() => {
      //this._getAll_Doclist();
      this.getAllDocsById(docIdDocuSigning);
    }, 5000);
    $("#require-modal").modal('hide');

  }
  downloadDoc(docIdDocuSigning: any, enevelopeId: any) {
    window.open("https://apexresolution-docusign.colanonline.net/eg007?DocumentID=" + docIdDocuSigning + "&EnvID=" + enevelopeId)
  }

  getStateCode(value: any) {
    this.propertyState = value;

  }

  getSplitEmailIdsForEmail(e: any, emailval: any) {


    if (e.target.checked) {
      this.toEmailids.push(emailval)
      // this.toEmailids.join(';')
    } else {
      this.toEmailids.pop(emailval)
      // this.toEmailids.join(';')
    }

    if (this.toEmailids.length == 0) {
      this.toEmailids = [];
    }

    console.log(this.toEmailids, "emailist", this.toEmailids.toString())
  }


  getFileNameForDocuSign(filename: any) {

    let filesnames = filename.split('.')[1];
    //  console.log(filesnames)
    let status: boolean;
    if (filesnames == 'pdf') {

      status = true;
    }
    else {
      status = false;
    }
    return status;
  }

  signDoc(id: any, envId: any) {

    this.docIdDocuSign = id;
    window.open("https://apexresolution-docusign.colanonline.net/eg001?Userid=" + this.homeOwnerId + "&DocumentID=" + this.docIdDocuSign + "&EnvID=" + envId)
    $("#require-modal").modal('hide');

  }
  mailShow(val: string) {
    if (val == 'internal') [
      this.external = false,
      this.both = false
    ]
    else if (val == 'external') {
      this.external = true;
      this.both = false
    }
    else if (val == "both") {
      this.external = false;
      this.both = true;
    }
  }

  checkFormIsTouched(tab: any, i: any) {
    
    let message1 = "Confirm";
    let message2 = "Do you want to save the changes made to this page ?";
    
    if (this.filestatusgroup.touched && [1, 2, 3, 4, 6, 9].includes(this.selectedIndex)) {

      this.confirmTabMove(message1, message2, tab, i);
      // alert("SDf")
      // return confirm("Do you want to save the changes made to this page");

    } else if (this.filestatusform.form.touched && this.selectedIndex == 0) {

      this.confirmTabMove(message1, message2, tab, i);
    }
    else if (this.closingform.form.touched && this.selectedIndex == 5) {

      this.confirmTabMove(message1, message2, tab, i);
    }
    else if (this.ssForm.form.touched && this.selectedIndex == 7) {

      this.confirmTabMove(message1, message2, tab, i);
    }
    else if (this.hardShipForm.form.touched && this.selectedIndex == 8) {

      this.confirmTabMove(message1, message2, tab, i);
    }
    else {
      this.selectedTab(tab, i);
    }

    // return true;
  }

  confirmTabMove(msg1: any, msg2: any, tab?: any, i?: any) {
  
    this.confirmationDialogService
      .confirm(msg1, msg2)
      .then((confirmed):any => {
        // debugger;
         const data:any=confirmed;
        console.log("confirmed", typeof (confirmed), confirmed);
        if (data==true) {

          this.selectedTab(tab, i)
        }
   
         if (data == 'save' && this.selectedIndex == 7) {
          // alert()
          this.ssproposaladd();
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
          
        }
        else if (data == 'save' && this.selectedIndex == 8) {
    
          this.addHardShipData('form');
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
        }
        else if (data == 'save' && this.selectedIndex == 5) {
          this.closingformsubmit();
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
        }
        else if (data == "save" && this.selectedIndex == 0) {
          this.add_status(this.filestatusform);
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
        }
        else if( data=='save' && this.selectedIndex==2){
          this.add_property();
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
        }
        else if( data=='save' && this.selectedIndex==4){
          this.clientContacts_get();
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
        }
        else if( data=='save' && this.selectedIndex==3){
          this.addDocument();
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
        }
 

        else if( data=='save' && this.selectedIndex==6){
          this.clientShortsale_get();
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
        }
        else if( data=='save' && this.selectedIndex==9){
          this.commissionaddUpdate();
          setTimeout(()=>{
            this.selectedTab(tab, i)
          },0)
        }
        
      })


  }


  ngOnDestroy() {

    // sessionStorage.removeItem('clientData')
  }


addShortSaleDoc(val:any){ 
  if(val == 'buyer'){
   
    this.fileuploadService.addShortSaleDoc({
      "clientId": this.clientid,
      "isBuyer": true,
      "createdBy": this.CurrentLoggedUserId
    });
  }else{
    this.fileuploadService.addShortSaleDoc({
      "clientId": this.clientid,
      "isBuyer": false,
      "createdBy": this.CurrentLoggedUserId
    });
  }
 
}
  
  // selectTab(index: any) {
  //   this.selectedIndex = index;

    
  // }

  scrollTab(x: number) {


    // console.log("atend initial", this.atEnd && x > 0)
    if (this.atStart && x < 0 || this.atEnd && x > 0) {
      return
    }
    this.leftTabIdx = this.leftTabIdx + x;
    this.abc = `translateX(${(this.leftTabIdx) * - 65}px)`

    this.atStart = this.leftTabIdx === 0;
    //   console.log("===", this.leftTabIdx === this.tabs.length - 1)
    this.atEnd = this.leftTabIdx === this.tabs.length - 1
    // if(this.abc=='translateX(-420px)'){
    //   this.atEnd=true
    // }else{
    //   this.atEnd=false
    // }
    //console.log("atend last", this.atEnd)
  }

  checkStatus(modalname: string) {
    let message1 = "Confirm";
    let message2 = "Do you want to save the changes made to this page ?";
    if (this.filestatusgroup.touched && modalname == "create_doc") {
      this.confirmationDialogService
        .confirm(message1, message2)
        .then((confirmed:any) => {
          if (confirmed==true) {
            // this.filestatusgroup.reset();
            $('#create_new').modal('hide');
            this.filestatusgroup.markAsUntouched();

          }
          else if(confirmed=='save'){
            this.addDocument()
          }
          else {
            this.filestatusgroup.markAsUntouched();

          }
        })
    }
    else {
      $('#create_new').modal('hide');
      this.filestatusgroup.markAsUntouched();
    }

  }


  getClass(cancelled?:any){
    var classList='';
    if(cancelled ==true){
      
       classList = 'change-btn hide btn_close_transaction'; 
    }else{
      classList="btn_close_transaction";
    }
    return classList;
  }
  targetChecked(e:any){
if(e.target.value=="ShortYes"){
  this.dTarget=false; 
}
else{
  this.dTarget=true;
}
  }
}
