<div class="authguard_wrapper">
  <div class="authdescribe_wrap">
    <h2>Going Above and Beyond To Find Your Next Home</h2>
    <img src="../../../assets/images/auth_bgm.png" alt="bgm" />
  </div>
  <div class="authforms_wrap">
    <div class="authforms_guards">
      <img src="../../../assets/images/ISHORTSALE_LOGO_HORIZONTAL_RGB.png" alt="logo" />
      <h3>Reset password</h3>
      <span>Please Create your new password and continue the application</span>
      <form name="reset_password" (ngSubmit)="reset_password.form.valid && try_resetPassword()"  #reset_password="ngForm"> 

        <div class="apex_auth_forms eyeapex_forms">
          <span><i class="fa fa-lock" aria-hidden="true"></i></span>

          <div class="apexauth_layer">
            <label>New Password</label>
            <input  [type]="tooglePassword ? 'text' : 'password'" name="password" [(ngModel)] ="reset_credentials.password" #password="ngModel"
            [ngClass]="{'is-invalid': reset_password.submitted && password.invalid}" required>
            <i (click)="clicktooglePassword()"  class="fa fa-eye" aria-hidden="true"></i>
            
            <div *ngIf="reset_password.submitted && password.invalid" class="invalid-feedback">
              <div *ngIf=" password.errors?.required">Password is Required</div>
            </div> 
          </div>
        </div>
        
        <div class="apex_auth_forms eyeapex_forms">
          <span><i class="fa fa-lock" aria-hidden="true"></i></span>
          <div class="apexauth_layer">
            <label>Confirm New Password</label>
            <input  [type]="toogleConPassword ? 'text' : 'password'"  ngValidateEqual="password" name="rePassword" [(ngModel)]="reset_credentials.rePassword" #rePassword="ngModel"
            [ngClass]="{'is-invalid': reset_password.submitted && rePassword.invalid}" required>
            <i (click)="clicktoogleConPassword()" class="fa fa-eye" aria-hidden="true"></i>
            <div *ngIf="reset_password.submitted && rePassword.invalid" class="invalid-feedback">
              <div *ngIf="rePassword.errors?.required">Confirm Password is Required</div>

              <div *ngIf="(rePassword.dirty || rePassword.touched) && rePassword.invalid">
                <p class="warning-text" *ngIf="rePassword.hasError('notEqual') && password.valid">
                  Passwords Don't Match
                </p>
            </div>
            </div>
          </div>
        </div>
        <div class="apex_auth_submitprocess">
          <button  class="btn-apex-primary">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>
