import { Injectable } from '@angular/core';
import {
HttpRequest,
HttpHandler,
HttpEvent,
HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {TokenService} from '../service/token.service';
import { NgxSpinnerService } from "ngx-spinner";
import { delay, finalize } from 'rxjs/operators';
//
@Injectable()
export class TokenAuthInterceptor implements HttpInterceptor {

constructor(private token: TokenService, private spinner: NgxSpinnerService) {}

intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
this.spinner.show();
const token = this.token._get_token();
// console.log("im interceptor", token)

// console.log("request ", request);
// console.log("request.url.indexOf('/api/V1/UserNotify/AutoNotification') ", request.url.indexOf('/api/V1/UserNotify/AutoNotification'));
// console.log("request.url.indexOf('/api/V1/UserNotify/AutoNotification')> -1 ", request.url.indexOf('/api/V1/UserNotify/AutoNotification')> -1);
var condition = (request.url.indexOf('/api/V1/UserNotify/AutoNotification')> -1)? (request.url.indexOf('/api/V1/UserNotify/AutoNotification')> -1): (request.url.indexOf('/api/V1/UserNotify/AutoNotificationAssignedBy')> -1)
// console.log("condition ", condition);
if (!condition) {
this.spinner.show();
}
if(token != null){
request = request.clone({headers : request.headers.set('Authorization','bearer '+token)})
}

// return next.handle(request);
return next.handle(request).pipe(
// delay(2000),
finalize(() =>
// console.log("hide spinner")
this.spinner.hide()
))
}
}