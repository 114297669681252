import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthServiceService } from '../service/auth-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private auth: AuthServiceService,
    private router: Router,
    private toast: ToastrService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: any) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            // alert("client")
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            // alert(error.status +'Server')


            if (error.status === 401 || error.status === 403) {
let value=false;
sessionStorage.clear();
if(value==false){
  value=true;
  errorMessage = "401 Unauthorized - Loggin please.";

              // this.toast.error(errorMessage);
              
              this.router.navigateByUrl('/login');
              
}
              
              // this.auth._refresh_Token();
            



              // alert(errorMessage)
            }
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          // alert(errorMessage);
          return throwError(errorMessage);
        })
      )
  }
}
