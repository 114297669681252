import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../service/token.service';
import { AuthServiceService } from '../../service/auth-service.service';
import { ActivatedRoute, Router, UrlSerializer } from "@angular/router";
import { ToastService } from 'src/app/service/toast.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  tooglePassword: boolean;
  toogleConPassword: boolean;
  tooglerePassword: boolean;
  changeList: any = {};
  constructor(private datePipe: DatePipe,
    private toast: ToastService, private authService: AuthServiceService, private route: Router, private tokenStorage: TokenService, private activeRoute: ActivatedRoute,
    private parsedUrl: UrlSerializer) {
    this.tooglePassword = false;
    this.toogleConPassword = false;
    this.tooglerePassword = false;

  }
  clicktooglePassword() {
    this.tooglePassword = !this.tooglePassword;
  }
  clicktoogleConPassword() {
    this.toogleConPassword = !this.toogleConPassword;
  }
  clicktooglerePassword() {
    this.tooglerePassword = !this.tooglerePassword;
  }

  ngOnInit(): void {
  }

  _change_password() {
    console.log(this.changeList.currentPassword, 'cuPassword')
    console.log(this.tokenStorage._get_email(), 'mail')
    let changedata = {
      "email": this.tokenStorage._get_email(),
      "currentPassword": this.changeList.currentPassword,
      "newPassword": this.changeList.newPassword,
      "createdOn" : this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    }
    this.authService._change_password_service(changedata).subscribe((data: any) => {
      console.log(data.errorMessage);

      if (data.reponse == true) {
        this.toast.showSuccess("Password changed successfully, Login Again");
        this.route.navigate(['/login']);
      } else {
        this.toast.showError(data.errorMessage)
      }
    })


  }


}
