import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { TokenService } from '../service/token.service'
import { first } from 'rxjs/operators';
import { ToastService } from './toast.service';
1
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  private userLoggedIn = new Subject<boolean>();
  private actionUrl: string;
  userInfo: any; 
  userInfoJSON: any;
  

  constructor(private datePipe: DatePipe,
    private toastr: ToastService, private http: HttpClient, private router: Router, private token: TokenService) {

      this.userLoggedIn.next(false);

    this.actionUrl = environment.apiUrl;
    this.userInfo = sessionStorage.getItem('userInfo');
    this.userInfoJSON = JSON.parse(this.userInfo)
    console.log(this.actionUrl, "url");
  }

  _login_service(credentials: any) {
    credentials["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    return this.http.post(this.actionUrl + '/Login', credentials);
  }


termCheck(userId:any){
  return this.http.post(this.actionUrl + `/Login/GetTermsConditionDetails?UserId=${userId}`,{});
}


  _forgot_password(email: any) {
    return this.http.post(this.actionUrl + '/Accounts/ForgotPassword', email)
  }

  _reset_password(credentials: any) {
    return this.http.post(this.actionUrl + '/Accounts/ResetPassword', credentials)
  }

  _change_password_service(credentials: any) {
    return this.http.post(this.actionUrl + '/Accounts/ChangePassword', credentials)
  }
  _register_service() {
    // register service go here
  }
  _refresh_Token() {
    //debugger;
    let email = this.token._get_email();
    console.log(email, "email")
    this.http.post(this.actionUrl + '/RefereshToken', { "email": email }).subscribe(data => {
      console.log(data)
    })
  }

  //  /Accounts/UpdateUserProfile
  updateUser_profile(profileObj: any) {
    return this.http.post(this.actionUrl + '/Accounts/UpdateUserProfile', profileObj);
  }
  add_profilepicUrl(profilepicObj: any) {
    let formdata = new FormData();
    formdata.append('formFile', profilepicObj)
    return this.http.post(this.actionUrl + '/Accounts/UploadUserProfilepic', formdata)
  }
  update_profilepicUrl(updatePicObj: any) {
    return this.http.post(this.actionUrl + '/Accounts/UpdateUserProfilepic', updatePicObj)
  }
  remove_profilepicUrl(removePicObj: any) {
    return this.http.post(this.actionUrl + '/Accounts/RemoveProfilePic', removePicObj)
  }

  logout(userguid: any) {


    // console.log(this.userInfoJSON.UserGUID)
    this.http.post(this.actionUrl + '/Logout?UserUniqueId=' + userguid, {}).subscribe((data: any) => {
      // this.token._destroy_token();
      sessionStorage.clear();
      this.router.navigateByUrl('/login');
      this.toastr.showSuccess("logged Out SuccessFully")

    })
  }

  _getBackendInfo(email: any) {
    return this.http.post(this.actionUrl + '/Accounts/BackEndInfo?EmailId=' + email, {})
  }
  _otpVerify(otpVerifyReq: any) {
    return this.http.post(this.actionUrl + '/OTPVerify', otpVerifyReq)
  }
  _otpResend(otpResendReq: any) {
    return this.http.post(this.actionUrl + '/OTPResend', otpResendReq)
  }



  // check user active or not
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
  isLoggedIn(){
    if(sessionStorage.getItem('Token')){
      return true
    }else{
      return false
    }
  }
  
}
