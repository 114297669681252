import { Component, OnInit, ViewChild } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../../service/token.service';

import { ToastService } from 'src/app/service/toast.service';
import { get } from 'lodash';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-client-reassign-task',
  templateUrl: './client-reassign-task.component.html',
  styleUrls: ['./client-reassign-task.component.css']
})
export class ClientReassignTaskComponent implements OnInit {
@ViewChild(NgForm) reassignTask:NgForm;

  routeID: any;
  myuserType: any;
  usertypeId: any;
  public specificfileData: any = [];
  public filestabData: any = [];
  userloggedIn: any;
  isReassign: Boolean = false;
  clientData: any;
  addBroker: any = {};
  getBrokerlist: any = {};
  id: any = {};
  tabsName: any;
  addTaskTab: any = {};
  updateReassignTask: any = {};
  updateBroker: Boolean = false;
  currentUserTypeId: any;
  assignedUserList: any
  tabId: any;
  tabName: any;
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true
  };


  constructor(private datepipe: DatePipe, private tokenStorage: TokenService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute, private Toastr: ToastService) {
    let clientDetails: any;
    clientDetails = sessionStorage.getItem('clientData')
    this.clientData = JSON.parse(clientDetails);
    this.tabName = sessionStorage.getItem('tabName')
    this.tabId = sessionStorage.getItem('tabId')
    this.currentUserTypeId = sessionStorage.getItem('userTypeId')

  }

  ngOnInit(): void {
    
    this.addTaskTab.fileTabId = 0;
    this.myuserType = this.tokenStorage._get_userRole();
    this.usertypeId = this.tokenStorage._get_userTypeId();
    this.getclientFileList();
    this.getGetfiletab();
    // this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // let id = this._manager_service.getId();
    this._get_fileTabName();

    // if (this.id !== null || undefined) {
    //   this.isReassign = true;

    // }
    this.activated_route.params.subscribe((params: any) => {
      console.log(params['id'])
      this.routeID = params['id'];
      if (params['id'] != undefined || null) {
        this.isReassign = true;
        this.getReassignTaskDetails(params['id']);


      }
    })

  }

  canDeactivate() {
    return !this.reassignTask.touched;
 }

  getReassignTaskDetails(clienttaskid: any) {
    this._manager_service.getReassignTask_inTabs(clienttaskid).subscribe((getassignedDetails: any) => {
      console.log(getassignedDetails, "hi")
      // this.addTaskTab = getassignedDetails.getData;
      this.addTaskTab['clientId'] = getassignedDetails.getData.clientId;

      if (this.tabName == 'tasks') {
        this.addTaskTab['tabId'] = parseInt(this.addTaskTab.tabId);
      } else {
        this.addTaskTab['tabId'] = parseInt(this.tabId);
      }
      // this.addTaskTab['tabId'] = parseInt(this.tabId);

      this.addTaskTab['userTypeId'] = getassignedDetails.getData.userTypeId;

      this.addTaskTab['description'] = getassignedDetails.getData.description;

      this.addTaskTab['priority'] = getassignedDetails.getData.priority;
      this.addTaskTab['completionDate'] = this.datepipe.transform(getassignedDetails.getData.completionDate, 'MM/dd/yyyy');


      // this.getAsssignedusers(this.addTaskTab.userTypeId, 'false')

    })
  }

  //**GETALL TABS NAME */
  _get_fileTabName() {
    this._manager_service.get_Getfiletab().subscribe((tabsname: any) => {
      this.tabsName = tabsname.listData;


    })
  }

  //**get assigned Users */

  getAsssignedusers(e?: any, isform?: any) {

    let id: any;
    if (isform == 'true') {
      // alert(1)
      id = e.target.value;
    } else {
      // alert(2)
      id = e;
    }

    let getuserobj = {
      "clientid": this.clientData.clientId,
      "currentUserId": parseInt(this.currentUserTypeId),
      "userTypeID": parseInt(id)
    }
    this._manager_service.getAssignedUser_ByClientId(getuserobj).subscribe((assignedUsers: any) => {
      this.assignedUserList = assignedUsers.listData;
    })
  }

  onChange(e: any) {
    console.log(e.target.value)
    this.addTaskTab['assignedUserId'] = parseInt(e.target.value);

  }

  addTaskintab() {

    this.addTaskTab["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm');

    if (this.isReassign) {
      //  alert(1)
      console.log(this.addTaskTab, "tabs task")

      this.addTaskTab['clientTaskId'] = Number(this.routeID);
      this.addTaskTab['assignedTabs'] = parseInt(this.tabId);
      this.addTaskTab.assignedUserId = Number(this.addTaskTab.assignedUserId);
      this.addTaskTab.userTypeId = parseInt(this.addTaskTab.userTypeId);
      this.addTaskTab['clientId'] = this.clientData['clientId'];
      this.addTaskTab['leadId'] = this.clientData['leadId'];
      this.addTaskTab['createdBy'] = sessionStorage.getItem('userTypeId');
      this.addTaskTab['reassignNotes'] = "";
      this.addTaskTab['completionDate'] = this.datepipe.transform(this.addTaskTab.completionDate, 'MM/dd/yyyy');
      this.addTaskTab['createdBy'] = this.currentUserTypeId;


      this._manager_service.reassignTask_inTabs(this.addTaskTab).subscribe((addedData: any) => {
        console.log(addedData, "addedData");
        this.reassignTask.form.untouched;
        $('#tabledel').modal('hide');
        this.route.navigateByUrl('/superadmin/client-file-status')
        this.Toastr.showSuccess(addedData.message);

      }, err => {
        this.Toastr.showError("SOMETHING WENT WRONG")
      })
    } else {
      //  alert(2)
      this.isReassign = false;
      // console.log(this.addTaskTab.taskCompletionDate._d, "tabs task")


      if (this.tabName == 'tasks') {
        this.addTaskTab['tabId'] = parseInt(this.addTaskTab.tabId);
      } else {
        this.addTaskTab['tabId'] = parseInt(this.tabId);
      }
      this.addTaskTab.assignedUserId = Number(this.addTaskTab.assignedUserId);
      this.addTaskTab.userTypeId = parseInt(this.addTaskTab.userTypeId);
      this.addTaskTab['clientId'] = this.clientData['clientId'];
      this.addTaskTab['leadId'] = this.clientData['leadId'];
      this.addTaskTab['createdBy'] = sessionStorage.getItem('userTypeId');
      this.addTaskTab['completionDate'] = this.datepipe.transform(this.addTaskTab.completionDate, 'MM/dd/yyyy');
      this._manager_service.add_clienttask(this.addTaskTab).subscribe((addedData: any) => {
        console.log(addedData, "addedData")
        $('#tabledel').modal('hide');

        this.route.navigateByUrl('/superadmin/client-file-status')

        this.Toastr.showSuccess(addedData.message)
      }, err => {
        this.Toastr.showError("something went wrong")
      })
    }

  }




  _create_broker() {
    if (this.updateBroker) {
      console.log(this.addBroker)
      this.addBroker.createdBy = "SuperAdmin";

      this._manager_service._update_broker(this.addBroker).subscribe((data: any) => {
        console.log("update", data)

        // alert(data.message)
        this.route.navigate(['/superadmin/brokers-list'])
      })
    }
    else {
      let boolValue = (/true/i).test(this.addBroker.active);
      this.addBroker['active'] = boolValue;
      this.addBroker.createdBy = "SuperAdmin";
      this._manager_service._add_broker(this.addBroker).subscribe((data: any) => {
        console.log("add", data)
        // alert(data.message)
        this.route.navigate(['/superadmin/brokers-list'])
      })
    }
  }

  _getBroker_byId(id: number) {
    this._manager_service._get_brokerByid(id).subscribe((data: any) => {
      console.log(data)
      this.addBroker = data.data;
    })


  }


  getclientFileList() {
    let CurrentUserId: any;

    CurrentUserId = this.tokenStorage._get_userTypeId();
    this.userloggedIn = this.tokenStorage._get_userRole();
    console.log(this.userloggedIn, "dropdownValyeee")
    console.log(CurrentUserId, "hi cuser ")
    this._manager_service.get_Clientfileno(CurrentUserId).subscribe((res: any) => {
      this.filestabData = res.listData;
      console.log("this.filestabData ", this.filestabData);
      //this.getAssignedUserTypeByClient(res.listData[0].Clientid);
    })
  }

  getGetfiletab() {
    this._manager_service.get_Getfiletab().subscribe((res: any) => {
      this.specificfileData = res.listData;
    })
  }

}
