<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Tasks"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Create/Edit Task</h2>
          <button class="btn_to_btitle"><a (click)="location.back()">back</a></button>
          <!-- <button class="btn_to_btitle"><a routerLink="/superadmin/task-list">back</a></button> -->
        </div>
      </div>

      <div *ngIf="myuserType != 'Agent' && myuserType != 'Broker'" class="pagecontent_wrapper padding_15">
        <form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
          <div class="apex-white_wrap">
            <div class="apex-title-lilblue">
              <h3>Assign task Detail</h3>
            </div>
            <div class="apex_group_af">
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div class="apexinput-froms block_lablels"  *ngIf="routeedittasktab != 'true'">
                      <label>Select File<sup>*</sup> </label>
                      <select  formControlName="clientId" class="form-control" (change)="onselectClient($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }">
                        <option value="">--Select--</option>
                        <option *ngFor="let filestab of filestabData" [value]="filestab.clientID">
                          {{filestab.fileNo}}
                        </option>
                      </select>
                      <!--err-->
                      <div *ngIf="submitted && f.clientId.errors" class="invalid-feedback">
                        <div *ngIf="f.clientId.errors.required">ClientId is required</div>
                      </div>
                      <!--err-->
                    </div>

                    <div class="apexinput-froms block_lablels" *ngIf="routeedittasktab == 'true'">
                      <label>Select File<sup>*</sup>  </label>
                      <select  formControlName="clientId" class="form-control" (change)="onselectClient($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.clientId.errors }">
                        <option value="">--Select--</option>
                        <option *ngFor="let filestab of filestabData" [value]="filestab.clientID" [disabled]="filestab === filestab">
                          {{filestab.fileNo}}
                        </option>
                      </select>
                      <!--err-->
                      <div *ngIf="submitted && f.clientId.errors" class="invalid-feedback">
                        <div *ngIf="f.clientId.errors.required">ClientId is required</div>
                      </div>
                      <!--err-->
                    </div>
                  </div>





                  <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                    <div class="apexinput-froms block_lablels">
                      <label>File Tab<sup>*</sup></label>
                      <select formControlName="tabId" [ngClass]="{ 'is-invalid': submitted && f.tabId.errors }">
                        <option value="">--Select--</option>
                        <option *ngFor="let specificfilestab of specificfileData" [value]="specificfilestab.fileTabId">
                          {{specificfilestab.fileTabName}}</option>
                      </select>
                      <!--err-->
                      <div *ngIf="submitted && f.tabId.errors" class="invalid-feedback">
                        <div *ngIf="f.tabId.errors.required">FileTab is required</div>
                      </div>
                      <!--err-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div class="apexinput-froms block_lablels">
                      <label>Assign To<sup>*</sup></label>

                      <select formControlName="userTypeId" name="title" id="title" (change)="onselectAssign($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.userTypeId.errors }">
                        <option value="">--Select--</option>
                        <option *ngFor="let assignclienttab of assignedClientData" [value]="assignclienttab.userTypeId">
                          {{ userloggedIn !=  assignclienttab.userTypeName ? assignclienttab.userTypeName : 'Self'}}
                        </option>
                      </select>
                      <!--err-->
                      <div *ngIf="submitted && f.userTypeId.errors" class="invalid-feedback">
                        <div *ngIf="f.userTypeId.errors.required">Assign is required</div>
                      </div>
                      <!--err-->
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                    <div class="apexinput-froms block_lablels">
                      <label>User Name <sup>*</sup></label>

                      <select formControlName="assignedUserId"
                        [ngClass]="{ 'is-invalid': submitted && f.assignedUserId.errors }">
                        <option value="">--Select--</option>
                        <option *ngFor="let assigneduser of assignuserData" [value]="assigneduser.userId">
                          {{assigneduser.userName}}</option>
                      </select>
                      <!--err-->
                      <div *ngIf="submitted && f.assignedUserId.errors" class="invalid-feedback">
                        <div *ngIf="f.assignedUserId.errors.required">User is required</div>
                      </div>
                      <!--err-->

                    </div>
                  </div>
                </div>
              </div>
              <div class="apexforms-wraps grped_label">
                <div class="apexforms-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                      <div class="apexinput-froms block_lablels">
                        <label>Select Priority<sup>*</sup> </label>
                        <select formControlName="priority" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.priority.errors }">
                          <option value="">--Select--</option>
                          <option value="High">High</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>
                        </select>
                        <!--err-->
                        <div *ngIf="submitted && f.priority.errors" class="invalid-feedback">
                          <div *ngIf="f.priority.errors.required">Priority is required</div>
                        </div>
                        <!--err-->
                      </div>
                      <div class="apexinput-froms block_lablels mt_30">
                        <label>Due Date<sup>*</sup> </label>
                        <dp-date-picker placeholder="Select Date" theme="dp-material" [config]="datePickerConfig"
                          required="true" formControlName="completionDate"
                          [ngClass]="{ 'is-invalid': submitted && f.completionDate.errors }">
                        </dp-date-picker>
                        <!--err-->
                        <div *ngIf="submitted && f.completionDate.errors" class="invalid-feedback">
                          <div *ngIf="f.completionDate.errors.required">DueDate is required</div>
                        </div>
                        <!--err-->
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                      <div class="apexinput-froms block_lablels">
                        <label>Task<sup>*</sup></label>
                        <textarea formControlName="description" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                          class="apex-textarea"></textarea>

                        <input type="hidden" formControlName="clientTaskId" />
                        <!--err-->
                        <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                          <div *ngIf="f.description.errors.required">Task is required</div>
                        </div>
                        <!--err-->
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="apexforms-group" *ngIf="updateTask">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div class="apexinput-froms block_lablels">
                      <label>Task Status</label>

                      <select formControlName="taskStatus" name="title" id="title"
                        [ngClass]="{ 'is-invalid': submitted && f.taskStatus.errors }">
                        <!-- <option value="Yet to start">Yet to start</option> -->
                        <option value="In progress">In Progress</option>
                        <option value="Completed">Completed</option>
                      </select>
                      <!--err-->
                      <div *ngIf="submitted && f.taskStatus.errors" class="invalid-feedback">
                        <div *ngIf="f.taskStatus.errors.required">Task Status is required</div>
                      </div>
                      <!--err-->
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="apxbtn_finalcenter mt_20">
              <!-- <button class="btn_grey"  routerLink="/superadmin/task-list">Cancel</button> -->
              <!-- <button class="btn_grey" type="reset">Cancel</button> -->
              <button class="btn_grey client_remove" type="reset" (click)="onReset()">Cancel</button>
              <button class="btn_dblue" >save</button>
            </div>
          </div>
        </form>

      </div>

      <!-- Agent || Broker -->
      <div *ngIf="myuserType == 'Agent' || myuserType == 'Broker'" class="pagecontent_wrapper padding_15">
        <form name="form" (ngSubmit)="LeadTask.form.valid && onSubmitLeadTask(taskTabModel.clientId)" #LeadTask="ngForm">
          <div class="apex-white_wrap">
            <div class="apex-title-lilblue">
              <h3>Assign task Detail</h3>
            </div>
            <div class="apex_group_af">
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                    <div class="apexinput-froms block_lablels">
                      <label>Select Lead</label>
                      <select class="form-control"  (change)="onselectClient($event)"  name="leadId"
                      [(ngModel)]="taskTabModel.leadId" #leadId="ngModel"
                      >
                        <option value="">--Select--</option>
                        <option *ngFor="let item of leadlandLister" [ngValue]="item.leadId">
                          {{item.leadName}}
                        </option>
                      </select>


                            </div>
                  </div>




                  <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                    <div class="apexinput-froms block_lablels select_dis" *ngIf="myuserType == 'Agent'">
                      <label>Assign To</label>
                      <select name="assignedUserId"  [(ngModel)]="taskTabModel.assignedUserId" #assignedUserId="ngModel" required>
                        <!-- <option selected disable>Select Assign To</option> -->
                        <option [ngValue]=currentid selected>Self</option>
                      </select>
                    </div>

                    <div class="apexinput-froms block_lablels" *ngIf="myuserType == 'Broker'">
                      <label>Assign To(Self/Agent) </label>

                      <select name="assignedUserId"   [(ngModel)]="taskTabModel.assignedUserId" #assignedUserId="ngModel" required>
                        <!-- <option selected disable>Select Assign To</option> -->
                        <option [ngValue]=currentid [selected]="taskTabModel.assignedUserId == currentid">Self</option>
                        <option *ngFor="let item of getListagentBroker" [ngValue]="item.agentId">
                          {{ item.agentName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                  <div class="apexinput-froms block_lablels">
                    <label>Status<sup>*</sup></label>
                    <select [ngClass]="{'is-invalid':LeadTask.submitted && taskStatus.invalid}" name="taskStatus"
                      [(ngModel)]="taskTabModel.taskStatus" #taskStatus="ngModel" required>
                      <!-- <option value="Yet to start">Yet To start</option> -->
                      <option value="In progress">In progress</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <div *ngIf="LeadTask.submitted && taskStatus.invalid" class="invalid-feedback">
                      <div *ngIf="taskStatus.errors?.required">
                        Status is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                  <div class="apexinput-froms block_lablels">
                    <label>Due Date<sup>*</sup></label>
                    <dp-date-picker placeholder="Select Date"  required="true" [ngClass]="{
                        'is-invalid':
                          LeadTask.submitted && completionDate.invalid
                      }" [(ngModel)]="taskTabModel.completionDate" #completionDate="ngModel" theme="dp-material"
                      [config]="datePickerConfig" name="recordingDate">
                    </dp-date-picker>


                    <div *ngIf="LeadTask.submitted && completionDate.invalid" class="invalid-feedback">
                      <div *ngIf="completionDate.errors?.required">
                        Due Date is required
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="apexforms-wraps grped_label">
                <div class="apexforms-group">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                      <div class="apexinput-froms block_lablels">
                        <label>Priority<sup>*</sup></label>
                        <select [ngClass]="{
                              'is-invalid':
                                LeadTask.submitted && priority.invalid
                            }" name="priority" [(ngModel)]="taskTabModel.priority" #priority="ngModel" required>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                        </select>
                        <!--err-->
                        <div *ngIf="LeadTask.submitted && priority.invalid" class="invalid-feedback">
                          <div *ngIf="priority.errors?.required">
                            Priority is required
                          </div>
                        </div>
                        <!--err-->
                      </div>

                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                      <div class="apexinput-froms block_lablels">
                        <label>Task<sup>*</sup></label>
                        <textarea name="description" [ngClass]="{
                            'is-invalid':
                              LeadTask.submitted && description.invalid
                          }" [(ngModel)]="taskTabModel.description" #description="ngModel" required></textarea>
                        <div *ngIf="LeadTask.submitted && description.invalid" class="invalid-feedback">
                          <div *ngIf="description.errors?.required">
                            Task is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="apxbtn_finalcenter mt_20">

              <!-- <button class="btn_grey" type="reset" routerLink="/superadmin/task-list">Cancel</button> -->
              <button class="btn_grey client_remove" type="reset" (click)="onReset()">Cancel</button>
              <button class="btn_dblue">save</button>
            </div>
          </div>
        </form>

      </div>
      <!-- Agent || Broker -->
    </div>
  </main>
</div>
