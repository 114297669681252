import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TokenService } from '../service/token.service';


declare var require: any



@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  private actionUrl: string;
  countrylist: any;
  currentUserLoginId: any;

  constructor(private http: HttpClient, private tokenService: TokenService) { this.actionUrl = environment.apiUrl; this.currentUserLoginId = this.tokenService._get_userTypeId() }


  //**get zillow value */
//   _get_zillow(){
//   const Zillow = require("node-zillow")

// const zillow = new Zillow('X1-ZWz1i2uiouqwi3_7q4l5')

// const parameters = {
//     address: "2114 Bigelow Ave",
//     citystatezip: "Seattle, WA",
//     rentzestimate: false
// }

// zillow.get('GetSearchResults', parameters)
//     .then((results:any) => {
//         console.log(results)
//         return results
//     })
//   }
  //**end */
  //** common dropdown for city, state and country*/
  _getAll_country() { return this.http.get(this.actionUrl + '/CommonDropDown/CountryGetAll') }

  _get_country(id: number) { return this.http.post(this.actionUrl + '/CommonDropDown/CountryGetAll?countryId=' + id, {}); }

  _getAll_state(countryId: number) { return this.http.get(this.actionUrl + '/CommonDropDown/StateGetAll?CountryId=' + countryId); }

  _get_state(stateId: number) { return this.http.post(this.actionUrl + '/CommonDropDown/StateGet?StateId=' + stateId, {}) }

  _getAll_city(stateId: number) { return this.http.get(this.actionUrl + '/CommonDropDown/CityGetAll?stateId=' + stateId); }

  _get_city(cityId: number) { return this.http.post(this.actionUrl + '/CommonDropDown/CityGet?CityId=' + cityId, {}); }

  //**END  */

//**GET TAB LIST */
 _getAll_Tabs(){return this.http.get(this.actionUrl+'/CommonDropDown/TabGetAll')};
//**END */
  //**lead screen dropdown*/
  _getuser_TypeList() { return this.http.post(this.actionUrl + '/Lead/GetUserByTypeLists', { 'CurrentUserId': this.currentUserLoginId }); }
  _get_Userlist(id: number) { return this.http.post(this.actionUrl + '/Lead/GetUsersLists?UserTypeId=' + id, {}) }
  //**END */

  //**CLIENT PROPERTY TAB DROPDOWN */
  _getAll_branch() { return this.http.get(this.actionUrl + '/ClientProperty/GetAllBranch'); }
  _getAll_filetype() { return this.http.get(this.actionUrl + '/ClientProperty/GetAllFileType'); }
  _getAll_lomb() { return this.http.get(this.actionUrl + '/ClientProperty/GetAllLOMB'); }
  _getAll_property() { return this.http.get(this.actionUrl + '/ClientProperty/GetAllPropertyType'); }
  //**END */

  //** Client Task tab */
  _getAll_filetab() { return this.http.get(this.actionUrl + '/Task/GetFileTab'); }
  _getAll_clientAddress() { return this.http.get(this.actionUrl + '/Task/GetClientAddress'); }
  _getassigned_userType() { return this.http.get(this.actionUrl + '/Task/GetAssignedUserType'); }
  _getassigned_user() { return this.http.get(this.actionUrl + '/Task/GetAssignedUser'); }
  //** End */

  //** Client File Tab Documents */

  //** Filter DropDown -- Manager*/
  _getAll_manager_UserList() { return this.http.post(this.actionUrl + '/Managers/FilterGetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_manager_StatusList() { return this.http.get(this.actionUrl + '/Managers/FilterStatuList'); }
  _getAll_manager_FilterData(filterReqObj: any) { return this.http.post(this.actionUrl + '/Managers/FilterGetAllData', filterReqObj); }
  //**End */

  //** Filter DropDown -- agent*/
  _getAll_agent_UserList() { return this.http.post(this.actionUrl + '/Agents/FilterGetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_agent_StatusList() { return this.http.get(this.actionUrl + '/Agents/FilterStatuList'); }
  _getAll_agent_FilterData(filterReqObj: any) { return this.http.post(this.actionUrl + '/Agents/FilterGetAllData', filterReqObj); }
  //**End */


  //** Filter DropDown -- Brokers*/
  _getAll_Brokers_UserList() { return this.http.post(this.actionUrl + '/Managers/FilterGetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_Brokers_StatusList() { return this.http.get(this.actionUrl + '/Managers/FilterStatuList'); }
  _getAll_Brokers_FilterData(filterReqObj: any) { return this.http.post(this.actionUrl + '/Managers/FilterGetAllData', filterReqObj); }
  //**End */

  //** Filter DropDown -- HomeOwners*/
  _getAll_homeowner_UserList() { return this.http.post(this.actionUrl + '/HomeOwners/FilterGetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_homeowner_StatusList() { return this.http.get(this.actionUrl + '/HomeOwners/FilterStatuList'); }
  _getAll_homeowner_FilterData(filterReqObj: any) { return this.http.post(this.actionUrl + '/HomeOwners/FilterGetAllData', filterReqObj); }
  //**End */

  //** Filter DropDown -- Negotiators*/
  _getAll_negotiators_UserList() { return this.http.post(this.actionUrl + '/Negotiators/FilterGetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_negotiators_StatusList() { return this.http.get(this.actionUrl + '/Negotiators/FilterStatuList'); }
  _getAll_negotiators_FilterData(filterReqObj: any) { return this.http.post(this.actionUrl + '/Negotiators/FilterGetAllData', filterReqObj); }
  //**End */

  //** Filter DropDown -- AsstNegotiators*/  
  _getAll_AsstNegotiators_UserList() { return this.http.post(this.actionUrl + '/AsstNegotiators/FilterGetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_AsstNegotiators_StatusList() { return this.http.get(this.actionUrl + '/AsstNegotiators/FilterStatuList'); }
  _getAll_AsstNegotiators_FilterData(filterReqObj: any) { return this.http.post(this.actionUrl + '/AsstNegotiators/FilterGetAllData', filterReqObj); }
  //**End */


  //** Filter DropDown -- Closers*/ 
  _getAll_Closers_UserList() { return this.http.post(this.actionUrl + '/Closers/FilterGetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_Closers_StatusList() { return this.http.get(this.actionUrl + '/Closers/FilterStatuList'); }
  _getAll_Closers_FilterData(filterReqObj: any) { return this.http.post(this.actionUrl + '/Closers/FilterGetAllData', filterReqObj); }
  //**End */

  //** Filter DropDown -- lead*/ 
  _getAll_lead_homeOwner() { return this.http.post(this.actionUrl + '/LeadFilter/GetAllHomeOwnerList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_lead_UserList() { return this.http.post(this.actionUrl + '/LeadFilter/GetAllUserTypeList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_lead_StatusList() { return this.http.post(this.actionUrl + '/LeadFilter/GetAllStatusList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_lead_FilterData(filterReqObj: any) { filterReqObj['currentUserId'] = parseInt(this.currentUserLoginId); return this.http.post(this.actionUrl + '/LeadFilter/GetAllLeadFilterdata', filterReqObj); }
  //**End */

  //** Filter DropDown -- AcceptLead*/  
  _getAll_acceptLead_UserList() { return this.http.post(this.actionUrl + '/LeadAcceptFilter/GetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_acceptLead_FilterData(filterReqObj: any) { filterReqObj['currentUserId'] = parseInt(this.currentUserLoginId); return this.http.post(this.actionUrl + '/LeadFilter/GetAllLeadFilterAccepts', filterReqObj); }
  //**End */
  //** Filter DropDown -- Rejected Lead*/  
  _getAll_RejectedLead_UserList() { return this.http.post(this.actionUrl + '/LeadRejectedFilter/GetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_RejectedLead_FilterData(filterReqObj: any) { filterReqObj['currentUserId'] = parseInt(this.currentUserLoginId); return this.http.post(this.actionUrl + '/LeadFilter/GetAllLeadFilterRejected', filterReqObj); }
  //**End */
  //**CLIENT TAB --- DOCUMENT */
  _getAll_DocumentType() { return this.http.post(this.actionUrl + '/LeadRejectedFilter/GetAllUserList?CurrentUserId=' + this.currentUserLoginId, {}); }
  _getAll_DocStatus(){  return this.http.post(this.actionUrl+'/ClientDocment/GetAllDocumentStatus',{})} ;
  // _getAll_Doctype(clientid:number){return this.http.post(this.actionUrl+'/ClientDocment/GetAllDocuemntType?ClientId='+clientid,{})}
  // https://apexresolution.colanonline.net/api/V1/ClientDocment/GetAllRequiredByUserList?ClientId=1
 _getAll_RequiredBy(clientid:number){return this.http.post(this.actionUrl+'/ClientDocment/GetAllRequiredByUserList?ClientId='+clientid,{})}
  //**End */

  //**SIGNED LOA */

  _getAll_LoaUser(usertypeid: number) {
    // https://apexresolution.colanonline.net/api/V1/Client/GetAllUserbyTypeId?UserTypeId=5

    return this.http.post(this.actionUrl + "/Client/GetAllUserbyTypeId?UserTypeId=" + usertypeid, {});
  }
  //**Search in Lead */
  search_lead(searchkey: any) {
    let reqpayload = { "serachByWord": searchkey, "currentUserId": parseInt(this.currentUserLoginId) }
    return this.http.post(this.actionUrl + '/LeadSearch/GetAllLeadFilter', reqpayload)
  }

  search_client(searchkey: any) {
    let reqpayload = { "searchWord": searchkey, "currentUserId": parseInt(this.currentUserLoginId) }
    return this.http.post(this.actionUrl + '/Client/ClientSerachdata', reqpayload)
  }
  search_leadAccept(searchkey: any) {
    let reqpayload = { "serachByWord": searchkey, "currentUserId": parseInt(this.currentUserLoginId) }
    return this.http.post(this.actionUrl + '/LeadSearch/GetSearchLeadFilterAccepts', reqpayload)
  }

  search_leadReject(searchkey: any) {
    let reqpayload = { "serachByWord": searchkey, "currentUserId": parseInt(this.currentUserLoginId) }
    return this.http.post(this.actionUrl + '/LeadSearch/GetSearchLeadFilterReject', reqpayload);
  }


  //**get lead dropdown for create client */

getLeadUniqueId(){
  // return https://apexresolution.colanonline.net/api/V1/Lead/GetLeadUniqueId?CurrentUserId=1
  return this.http.post(this.actionUrl +'/Lead/GetLeadUniqueId?CurrentUserId='+this.currentUserLoginId,{});

}
}
