import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { TokenService } from '../service/token.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  private actionUrl: string;
  currentLoginUserId: any;

  private id: any;
  leadObj: any;
  constructor(private http: HttpClient, private tokenService: TokenService) {
    this.actionUrl = environment.apiUrl;
    console.log(this.actionUrl, "url");

    this.currentLoginUserId = sessionStorage.getItem('userTypeId');
    console.log(this.currentLoginUserId, "IDDIDDIDI");
  }



  /***common user create CRUD (Manage Users)***/
  _create_User(url: any, obj: any) {
    return this.http.post(this.actionUrl + url, obj);

  }
  _update_User(url: any, obj: any) {
    return this.http.post(this.actionUrl + url, obj);


  }
  /**end */

  //** SS PROPOSAL TAB */
  _getSSproposaltab(clientid: any) {
    return this.http.get(this.actionUrl + '/ClientSSProposal/GetSSProposalByAutoPopulate?clientId=' + clientid)
  }
  _getSSproposaltabcalc(clientid: any) {
    return this.http.get(this.actionUrl + '/ClientSSProposal/GetSSProposal?clientId=' + clientid)

  }
  _addorupdateSSproposaltab(calcobj: any) {
    // https://apexresolution.colanonline.net/api/V1/ClientSSProposal/UpadateandInsertSSProposal
    return this.http.post(this.actionUrl + '/ClientSSProposal/UpadateandInsertSSProposal', calcobj)

  }
  //**END */
  _add_manager(addmanager: any) {
    return this.http.post(this.actionUrl + '/Managers/Add', addmanager);
  }

  _update_manager(editmanager: any) {
    return this.http.post(this.actionUrl + '/Managers/Update', editmanager);
  }

  _get_All_manager(): any {
    return this.http.get(this.actionUrl + '/Managers/GetAll');
  }

  _get_All_managerUsers(id: number) {
    return this.http.post(this.actionUrl + '/User/GetAllUsers?CurrentUserId=' + id, {})
  }

  _get_All_inActiveUsers(id: number) {
    return this.http.post(this.actionUrl + '/User/GetAllInActiveUsers?CurrentUserId=' + id, {})
  }

  _get_All_deleteActiveUsers(id: number) {
    return this.http.post(this.actionUrl + '/User/GetAllDeletedUsers?CurrentUserId=' + id, {})
  }

  _get_managerByid(id: number) {
    return this.http.post(this.actionUrl + '/Managers/Get?ManagerId=' + id, {});
  }
  _delete_manager(id: number) {
    return this.http.post(this.actionUrl + '/Managers/Delete', id);
  }
  //  setId(id:any){
  //    this.id = id
  //  }
  //  getId(){
  //   return this.id;
  //  }


  //Add Broker

  _add_broker(addmanager: any) {
    return this.http.post(this.actionUrl + '/Brokers/Add', addmanager);
  }

  _update_broker(editmanager: any) {
    return this.http.post(this.actionUrl + '/Brokers/Update', editmanager);
  }
  _get_brokerByid(id: number) {
    return this.http.post(this.actionUrl + '/Brokers/Get?BrokerId=' + id, {});
  }
  _delete_broker(id: number) {
    return this.http.post(this.actionUrl + '/Brokers/Delete', id);
  }
  _get_All_Broker(): any {
    return this.http.get(this.actionUrl + '/Brokers/GetAll')
  }


  //Agents
  _add_agent(addagent: any) {
    return this.http.post(this.actionUrl + '/Agents/Add', addagent);
  }

  _update_agent(editagent: any) {
    return this.http.post(this.actionUrl + '/Agents/Update', editagent);
  }
  _get_agentByid(id: number) {
    return this.http.post(this.actionUrl + '/Agents/Get?AgentId=' + id, {});
  }
  _delete_agent(id: number) {
    return this.http.post(this.actionUrl + '/Agents/Delete', id);
  }
  _get_All_agent(): any {
    return this.http.get(this.actionUrl + '/Agents/GetAll')
  }

  getallAgentCompany(): any {
    return this.http.get(this.actionUrl + '/Agents/GetAllAgentComany');
  }

  getallAgentManager(): any {
    return this.http.get(this.actionUrl + '/Agents/GetAllAgentManager');
  }

  //Agent Type

  _add_agentType(type: any) {
    return this.http.post(this.actionUrl + '/Agents/AddAgentTypes', type);
  }

  _update_agentType(type: any) {
    return this.http.post(this.actionUrl + '/Agents/UpdateAgantTypes', type);
  }
  // _get_agenttypeByid(){
  // return this.http.post(this.actionUrl+'/Agents/Get);
  // }

  _delete_agentType(id: number) {
    return this.http.post(this.actionUrl + '/Agents​/DeleteAgentTypes', id);
  }
  _get_All_agentType(): any {
    return this.http.get(this.actionUrl + '/Agents/GetAllAgentTypes')
  }



  //Negotiator
  _add_Negotiator(addnegotiator: any) {
    return this.http.post(this.actionUrl + '/Negotiators/Add', addnegotiator);
  }

  _update_Negotiator(editnegotiator: any) {
    return this.http.post(this.actionUrl + '/Negotiators/Update', editnegotiator);
  }
  _get_NegotiatorByid(id: number) {
    return this.http.post(this.actionUrl + '/Negotiators/Get?NegotiatorId=' + id, {});
  }
  _delete_Negotiator(id: number) {
    return this.http.post(this.actionUrl + '/Negotiators/Delete', id);
  }
  _get_All_Negotiator(): any {
    return this.http.get(this.actionUrl + '/Negotiators/GetAll')
  }



  //ASST.. Negotiator
  _add_asstNegotiator(add: any) {
    return this.http.post(this.actionUrl + '/AsstNegotiators/Add', add);
  }

  _update_asstNegotiator(edit: any) {
    return this.http.post(this.actionUrl + '/AsstNegotiators/Update', edit);
  }
  _get_asstNegotiatorByid(id: number) {
    return this.http.post(this.actionUrl + '/AsstNegotiators/Get?NegotiatorId=' + id, {});
  }
  _delete_asstNegotiator(id: number) {
    return this.http.post(this.actionUrl + '/AsstNegotiators/Delete', id);
  }
  _get_All_asstNegotiator(): any {
    return this.http.get(this.actionUrl + '/AsstNegotiators/GetAll')
  }

  ///HomeOwners
  _add_HomeOwners(add: any) {
    return this.http.post(this.actionUrl + '/HomeOwners/Add', add);
  }

  _update_HomeOwners(edit: any) {
    return this.http.post(this.actionUrl + '/HomeOwners/Update', edit);
  }
  _get_HomeOwnersByid(id: number) {
    return this.http.post(this.actionUrl + '/HomeOwners/Get?HomeOwnerId=' + id, {});
  }
  _delete_HomeOwners(id: number) {
    return this.http.post(this.actionUrl + '/HomeOwners/Delete', id);
  }
  _get_All_HomeOwners(): any {
    return this.http.get(this.actionUrl + '/HomeOwners/GetAll')
  }


  //Closers

  add_Closers(add: any) {
    return this.http.post(this.actionUrl + '/Closers/Add', add);
  }

  _update_Closers(edit: any) {
    return this.http.post(this.actionUrl + '/Closers/Update', edit);
  }
  _get_ClosersByid(id: number) {
    return this.http.post(this.actionUrl + '/Closers/Get?CloserId=' + id, {});
  }
  _delete_Closers(id: number) {
    return this.http.post(this.actionUrl + '/Closers/Delete', id);
  }
  _get_All_Closers(): any {
    return this.http.get(this.actionUrl + '/Closers/GetAll')
  }

  //leads

  add_lead(add: object) {
    return this.http.post(this.actionUrl + '/Lead/AddLead', add);
  }
  update_lead(update: object) {
    return this.http.post(this.actionUrl + '/Lead/UpdateLead', update);
  }
  _getAll_Accepted() {
    return this.http.post(this.actionUrl + '/Lead/GetAllAcceptLead', { 'CurrentUserId=': this.currentLoginUserId });
  }
  _getAll_Rejected() {
    return this.http.post(this.actionUrl + '/Lead/GetAllRejectLead', { 'CurrentUserId=': this.currentLoginUserId });
  }
  _getAll_disqualified() {
    return this.http.post(this.actionUrl + '/Lead/GetAllDisQualifiedLead', { 'CurrentUserId=': this.currentLoginUserId });
  }

  get_All_Lead() {
    return this.http.post(this.actionUrl + '/Lead/GetAllLead?CurrentUserId=' + this.currentLoginUserId, {});
  }
  _getLeadForBroker() {
    return this.http.post(this.actionUrl + '/Lead/GetAssingedLeadForBroker?CurrentUserid=' + this.currentLoginUserId, {});
  }
  getLead_Byid(id: number) {
    return this.http.post(this.actionUrl + '/lead/GetLead?LeadId=' + id, {})
  }
  _assign_Lead(assignLead: any) {
    return this.http.post(this.actionUrl + '/Lead/AssingLeadtoUser', assignLead);
  }

  _assigned_Agent(id: any) {
    return this.http.post(this.actionUrl + '/Lead/GetLeadAssignedToAgent?CurrentUserid=' + id, {});
  }
  brokerGetall() {
    return this.http.get(this.actionUrl + '/Brokers/GetAll', {})
  }
  agentGetall() {
    return this.http.get(this.actionUrl + '/Agents/GetAll', {})
  }

  //Agent Module
  getAll_LOADetails(id: any) {
    return this.http.post(this.actionUrl + '/Lead/GetLOADetails?LeadId=' + id, {});
  }

  get_updateLOADetails(LOAUpdate: any) {
    return this.http.post(this.actionUrl + '/Lead/UpdateLOADetails', LOAUpdate);
  }
  updateInviteHomeowner(inviteHomeWner: any) {
    return this.http.post(this.actionUrl + '/Lead/InviteHomeOwner', inviteHomeWner);
  }

  // Client
  _getall_LeadforAssign() {
    return this.http.post(this.actionUrl + '/Client/GetAllLeadforAssign', { 'CurrentUserId': this.currentLoginUserId });
  }

  _getall_AssignedClientbyuser() {
    return this.http.post(this.actionUrl + '/Client/GetAllAssignedClientByUser', { 'CurrentUserId': this.currentLoginUserId });
  }

  _getall_CancelledClientbyuser() {
    return this.http.post(this.actionUrl + '/Client/GetAllCancelledClientByUser', { 'CurrentUserId': this.currentLoginUserId });
  }

  _getall_ClosedClientbyuser() {
    return this.http.post(this.actionUrl + '/Client/GetAllClosedClientByUser', { 'CurrentUserId': this.currentLoginUserId });
  }


  _signedLoa_Adduser(userObj: any) {
    return this.http.post(this.actionUrl + '/Client/SignedLOAUserAssign', userObj);
  }


  // _signedLoa_Adduser(userObj: any) {
  //   return this.http.post(this.actionUrl + '/Client/SignedLOAUserAdding', userObj);
  // }

  _get_customColumn() {
    return this.http.post(this.actionUrl + '/Lead/CustomCloumnGetAllList', {});
  }


  //Client file status Tab
  _addFile_status(Fileobj: any) {
    return this.http.post(this.actionUrl + '/ClientFileStatus/AddFileStatus', Fileobj);
  }
  _cancel_status(Fileobj: any) {
    return this.http.post(this.actionUrl + '/ClientFileStatus/CancelStatus', Fileobj);
  }


  _getFile_status(id: number) {
    return this.http.get(this.actionUrl + '/ClientFileStatus/GetClientFileStatus?clientId=' + id);
  }

  _send_Screenshot(screenshotObj: any) {
    return this.http.post(this.actionUrl + '/ClientFileStatus/SendScreenShotEmail', screenshotObj);

  }
  //**Client Document Tab */
  _createnew_Document(FileObj: any) { return this.http.post(this.actionUrl + '/ClientDocment/AddNewDocument', FileObj); }
  _updatenew_Document(FileObj: any) { return this.http.post(this.actionUrl + '/ClientDocment/UpdateDocumentMain', FileObj); }


  _update_DocStatus(statusid: any, docid: any) {
    let statusUpdate = {
      "documentId": parseInt(docid),
      "statusId": parseInt(statusid),
      "createdBy": this.currentLoginUserId
    };
    return this.http.post(this.actionUrl + '/ClientDocment/UpdateDocumentStatus', statusUpdate);
  }
  _update_DocNotes(notes: any, docid: any) {
    let notesUpdate = {
      "documentId": parseInt(docid),
      "notes": notes,
      "createdBy": this.currentLoginUserId
    };
    return this.http.post(this.actionUrl + '/ClientDocment/UpdateDocumentNotes', notesUpdate);
  }
  //**GET Document */
  _getAll_document(clientId: number) { return this.http.post(this.actionUrl + '/ClientDocment/GetAllDocumentMain?ClientId=' + clientId, {}); }

  _email_send(emailbodyObj: any) { return this.http.post(this.actionUrl + '/ClientDocment/SendMail', emailbodyObj); }
  _getAll_Doctasklist(clientid: any) { return this.http.post(this.actionUrl + '/Task/GetTaskDetailsForDocumentTab?clientid=' + clientid, {}); }

  _AddDoc_type(doctypes: any) {

    return this.http.post(this.actionUrl + '/ClientDocment/AddDocumentType?DocumentType=' + doctypes['docTypeName'] + '&ClientId=' + doctypes['clientId'], {});
  }
  _addDoc_typeV2(docobj: any) {
    return this.http.post(this.actionUrl + '/ClientDocment/AddDocumentType', docobj);

  }
  _delete_DocumentList(docid: any, clientid: any) {
    let deletedoc = {
      "documentUploadId": docid,
      "clientId": clientid,
      "docDetails": [],
      "createdBy": this.currentLoginUserId
    };

    return this.http.post(this.actionUrl + '/ClientDocment/DeleteDocumentMain', deletedoc);
  }
  _send_Docupload(emailbodyObj: any) { return this.http.post(this.actionUrl + '/ClientDocment/SendDocumentUploadRequest', emailbodyObj); };
  _getAll_emails(clientId: any) { return this.http.post(this.actionUrl + '/ClientDocment/SendMailList?ClientId=' + clientId, {}); };
  _search_Document(searchData: any) { return this.http.post(this.actionUrl + '/ClientDocment/SearchByDataDocument', searchData) };
  _getdataDoc_Byid(docid: any) { return this.http.post(this.actionUrl + '/ClientDocment/GetDocumentMain?DocumentId=' + docid, {}) };
  // _getAutoClientAddress(clientid: any) {
  //   return this.http.get(this.actionUrl + '/CommonDropDown/GetClientPropertyAddress?ClientId=' + clientid)
  // }
  //Client property  Tab

  /* ASSIGN TASK */
  getAssignedUser_ByClientId(usertypeobj: any) {

    return this.http.post(this.actionUrl + '/Task/GetAssignedUsersByClientId', usertypeobj)
  }

  getReassignTask_inTabs(clientTaskId: any) {
    return this.http.post(this.actionUrl + '/Task/GetClientTaskByIdForReassign?ClientTaskId=' + clientTaskId, {})
  }

  reassignTask_inTabs(reassignObj: any) {
    return this.http.post(this.actionUrl + '/Task/ReassignTask ', reassignObj);
  }
  updateTaskDetails_inTabs(updateobj: any) {
    //https://apexresolution.colanonline.net/api/V1/Task/UpdateTaskDetail
    // UpdateClientTask

    return this.http.post(this.actionUrl + '/Task/UpdateTaskDetail', updateobj);
  }

  _add_Updateproperty(propertyObj: any) {
    return this.http.post(this.actionUrl + '/ClientProperty/AddorUpdateClientProperty', propertyObj);
  }
  //**add client&property for create manual client  */
  _add_ManualClient(propertyObj:any){
    return this.http.post(this.actionUrl + '/Client/AddManualClient', propertyObj);

  }
  _get_PropertyDetails(clientid: any) {
    // https://apexresolution-qa.colanonline.net/api/V1/ClientProperty/GetAllClientPropertyDetails?ClientId=1
    return this.http.get(this.actionUrl + '/ClientProperty/GetAllClientPropertyDetails?ClientId=' + clientid);

  }

  //Client Contacts
  _add_clientContacts(contactObj: any) {
    return this.http.post(this.actionUrl + '/ClientContacts/AddContacts', contactObj);
  }

  _get_contactDetails(clientId: number) {
    return this.http.get(this.actionUrl + '/ClientContacts/GetContacts?clientId=' + clientId);
  }

  //Client closing statement
  _clientclosing_statement(closingStatementObj: any) {
    return this.http.post(this.actionUrl + '/ClientClosingStatements/statements', closingStatementObj);
  }
  _getclientclosing_statement(clientid: number) {
    return this.http.get(this.actionUrl + '/ClientClosingStatements/GetClosingStatementdetails?clientid=' + clientid, {});
  }



  //Client Short Sale
  _add_clientShortSale(shortsaleObj: any) {
    return this.http.post(this.actionUrl + '/Clientshortsale/Shortsale', shortsaleObj);
  }

  _get_shortSale(clientId: any) {
    // https://apexresolution.colanonline.net/api/V1/Clientshortsale/shortsalesdetails?clientid=4
    return this.http.get(this.actionUrl + '/Clientshortsale/shortsalesdetails?clientid=' + clientId);
  }

  _get_buyerInfo(id: any) {
    return this.http.post(this.actionUrl + '/Clientshortsale/GetBrokerInfoForContact?ClientId=' + id, {})
  }

  //jeevitha/V1/ManageVideo/GetManageVideoWithFilter
  //manageautogendoc
  get_autogendoc(reqObj: any) {
    // let id = parseInt(titleid)
    return this.http.post(this.actionUrl + '/Autogendocuments/Getdocdetails', reqObj);
  }
  get_allautogendocdetails() {
    return this.http.get(this.actionUrl + '/Autogendocuments/GetAllAutogendocdetails', {});
  }
  add_autogendoc(add: any) {
    return this.http.post(this.actionUrl + '/Autogendocuments/AddAutogenerateddocuments', add);
  }
  update_autogendoc(edit: any) {
    return this.http.post(this.actionUrl + '/Autogendocuments/UpdateAutogendetails', edit);
  }
  delete_autogendoc(id: object) {
    return this.http.post(this.actionUrl + '/Autogendocuments/DeleteAutogendetails', id);
  }
  upload_autogendoc(upload: any) {
    return this.http.post(this.actionUrl + '/Autogendocuments/UploadAutogendocFiles', upload);
  }
  download_autogendoc(download: any) {
    return this.http.post(this.actionUrl + '/Autogendocuments/DownloadAutogendocFiles', download);
  }

  getautogen_dropdown() {
    return this.http.get(this.actionUrl + '/Autogendocuments/GetAutogendocdropdown');
  }

  filter_autogen(data: any) {
    return this.http.post(this.actionUrl + '/Autogendocuments/GetAutogendocuments', data);
  }

  //** HARDSHIP TAB */
  addHardship(hardshipObj: any) {
    return this.http.post(this.actionUrl + '/ClientHardShip/AddClientHardShip', hardshipObj)

  }
  getHardship(clientid: any) {
    // https://apexresolution.colanonline.net/api/V1/ClientHardShip/GetClientHardShip?clientId=4
    return this.http.get(this.actionUrl + '/ClientHardShip/GetClientHardShip?clientId=' + clientid);

  }
  //**END */
  //Login History


  get_userdropdown_list(id: number) {
    return this.http.post(this.actionUrl + '/userdropdown/UserList?CurrentUserTypeId=' + id, {});
  }

  // get_allUserLonginHistoryAudit(id: number) {
  //   return this.http.post(this.actionUrl + '/userloginaudit/GetAllUserLoginhistoryAudit', { "usertypeId": id, })
  // }


  get_allUserLonginHistoryAudit(loginObj: any) {
    return this.http.post(this.actionUrl + '/userloginaudit/GetAllUserLoginhistoryAudit', loginObj)
  }

  // get_allUserLonginHistoryAuditbydate(id: number, date: any) {
  //   return this.http.post(this.actionUrl + '/userloginaudit/GetAllUserLoginhistoryAuditbydate', {
  //     "userId": id,
  //     "date": date
  //   })
  // }

  get_allUserLonginHistoryAuditbydate(data: any) {
    return this.http.post(this.actionUrl + '/userloginaudit/GetAllUserLoginhistoryAuditbydate', data)
  }

  //Task

  get_Clientfileno(id: number) {
    //return this.http.get(this.actionUrl + '/Task/GetClientFileNo');
    return this.http.post(this.actionUrl + '/Task/GetClientFileNo?CurrentUserId=' + id, {});
  }


  get_Getfiletab() {
    return this.http.get(this.actionUrl + '/Task/GetFileTab');
  }

  get_AssignedUserType() {
    return this.http.get(this.actionUrl + '/Task/GetAssignedUserType')
  }

  get_AssignedUserTypeByClient(id: number) {
    // return this.http.get(this.actionUrl + '/Task/GetAssignedUserTypeByClientId?Clientid=' + id)
    return this.http.post(this.actionUrl + '/Task/GetAssignedUserType?CurrentUserid=' + id, {})
  }

  // get_AssignedUser(id: number) {
  //   return this.http.get(this.actionUrl + '/Task/GetAssignedUser?UserTypeID=' + id)
  // }

  get_AssignedUserid(id: number) {
    return this.http.post(this.actionUrl + '/Task/GetAssignedUser?UserTypeID=' + id, {})
  }


  get_AssignedUser(data: any) {
    return this.http.post(this.actionUrl + '/Task/GetAssignedUsersByClientId', data)
  }


  get_createUsers(id: number) {
    return this.http.post(this.actionUrl + '/ClientDocment/GetpopupDocuments?ClientId=' + id, {})
  }

  add_task(add: any) {
    return this.http.post(this.actionUrl + '/Task/AddTask', add)
  }

  add_clienttask(add: any) {
    return this.http.post(this.actionUrl + '/Task/AddClientTask', add)
  }



  //task template
  add_tasktemplate(add: any) {
    return this.http.post(this.actionUrl + '/Task/AddTaskTemplate', add)
  }

  // get_tasktemplateDetails() {
  //   return this.http.get(this.actionUrl + '/Task/GetTaskTemplateDetails', {})
  // }


  get_tasktemplateDetails(id: number) {
    return this.http.post(this.actionUrl + '/Task/GetTaskTemplateDetails?UserId=' + id, {})
  }

  update_tasktemplate(editObj: any) {
    return this.http.post(this.actionUrl + '/Task/UpdateTaskTemplate', editObj);
  }

  delete_tasktemplate(data: any) {
    return this.http.post(this.actionUrl + '/Task/DeleteTaskTemplate', data)
  }

  get_tasktemplatedetail(id: number) {
    //return this.http.get(this.actionUrl + '/Task/GetTaskTemplateById?templateid=' + id)
    return this.http.post(this.actionUrl + '/Task/GetTaskTemplateById?templateid=' + id, {})
  }


  get_allClientTask(id: number) {
    return this.http.post(this.actionUrl + '/Task/GetAllClientTask?CurrentUserId=' + id, {})
  }

  get_taskList(id: any) {
    return this.http.post(this.actionUrl + '/Task/GetListOfTask?CurrentUserId=' + id, {})
  }

  // add_TaskFromTaskTemplate(addtt: any) {
  //   return this.http.post(this.actionUrl + '/Task/AddTaskFromTaskTemplate', addtt);
  // }

  add_TaskFromTaskTemplate(addtt: any) {
    return this.http.post(this.actionUrl + '/Task/AddTaskForClientFromTemplate', addtt);
  }

  get_tasktemplate() {
    // return this.http.get(this.actionUrl + '/Task/GetTaskTemplate');
  }

  get_tasktemplates(id: number) {
    return this.http.post(this.actionUrl + '/Task/GetTaskTemplate?UserId=' + id, {});
  }


  //Task Filter
  get_GetfileStatus() {
    return this.http.get(this.actionUrl + '/Task/GetFileStatus');
  }

  get_GetNegotiatorName() {
    return this.http.get(this.actionUrl + '/Task/GetNegotiatorName');
  }

  get_GetCloserName() {
    return this.http.get(this.actionUrl + '/Task/GetCloserName');
  }

  get_GetTaskUsingFilters(data: any) {
    return this.http.post(this.actionUrl + '/Task/GetTaskUsingFilters', data)
  }

  get_loanData(id: number) {
    return this.http.get(this.actionUrl + '/ClientClosingStatements/GetSSLenderdetails?ClientId=' + id);
  }

  //dashboard

  get_DashboardSuperadmin() {
    return this.http.get(this.actionUrl + '/Dashboard/GetDashboardDetailsForSupderAdmin');
  }

  get_LeadDashboard() {
    return this.http.post(this.actionUrl + '/Lead/GetAllLead', { 'CurrentUserId': this.currentLoginUserId });
  }

  //dashboard Manager
  // get_DashboardManager() {
  //   return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForManager', { 'CurrentUserId': this.currentLoginUserId });
  // }

  get_DashboardManager(currentuserid: number) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForManager?CurrentUserId=' + currentuserid, {})
  }

  //dashboard closure
  get_DashboardClosure(currentuserid: number) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForClosure?CurrentUserId=' + currentuserid, {})
  }

  //dahboard HomeOwner
  get_DashboardHomeOwner(currentuserid: number) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForHomeowner?CurrentUserId=' + currentuserid, {})
  }

  //AsstNegotiator
  get_AsstNegotiator(currentuserid: number) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForAsstNegotiator?CurrentUserId=' + currentuserid, {})
  }

  //Negotiator
  get_Negotiator(currentuserid: number) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForNegotiator?CurrentUserId=' + currentuserid, {})
  }

  //Dashboard Agent
  get_dashboardAgent() {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForAgent', { 'CurrentUserId': this.currentLoginUserId });
  }
  // get_dashboardAgent(currentuserid: number) {
  //   return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForAgent?CurrentUserId=' + currentuserid, {})
  // }

  getall_listleadAgent() {
    return this.http.get(this.actionUrl + '/Lead/GetAllLead');
  }

  //Dashboard Broker

  get_dashboardBroker() {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardDetailsForBroker', { 'CurrentUserId': this.currentLoginUserId });
  }

  //**Tak tab  */
  getAllTask_Tabs(clientid: any) {
    return this.http.post(this.actionUrl + '/Task/GetAllTaskByClientId', {
      "clientId": parseInt(clientid),
      "currentUserId": parseInt(this.currentLoginUserId)
    })
  }


  //**END */
  //commission breakdown tab
  addUpdate_commission(commissionObj: any) {
    return this.http.post(this.actionUrl + '/ClientCommission/SaveClientCommission', commissionObj);
  }
  export_commission(commissionObj: any) {
    return this.http.post(this.actionUrl + '/ClientCommission/DownloadCommission', commissionObj);
  }
  getAll_commisson(clientId: number) {
    return this.http.get(this.actionUrl + '/ClientCommission/GetClientCommission?clientId=' + clientId);
  }

  getacceptLead(acceptObj: any) {
    return this.http.post(this.actionUrl + '/Lead/AcceptLead', acceptObj);
  }
  getacceptBulkLead(acceptBulkObj: any) {
    return this.http.post(this.actionUrl + '/Lead/AcceptLeadbulk', acceptBulkObj)
  }
  getrejectLead(rejectObj: any) {
    return this.http.post(this.actionUrl + '/Lead/RejectLead', rejectObj);
  }

  getrejectBulkLead(rejectBulkObj: any) {
    return this.http.post(this.actionUrl + '/Lead/RejectLeadbulk', rejectBulkObj)
  }
  //Login Agreement
  getPDFLink(id: any) {
    return this.http.post(this.actionUrl + '/Accounts/DownloadAgreement?UserId=' + id, {})
  }

  getInsertLead(reqObj: any) {
    
    const HttpUploadOptions = {
      headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
    }
    return this.http.post(this.actionUrl + '/Agreement/Insert', reqObj)
  }
  getUserAgreement(id: any) {
    return this.http.post(this.actionUrl + '/Agreement/GetUser?UserId=' + id, {})
  }
  getAllAgreement(id: any) {
    return this.http.post(this.actionUrl + '/Agreement/GetAll?UserId=' + id, {})
  }


  //cancel Report
  cancelfileReport_dropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/CancelFileReport/ClientDropDown?CurrentUserId=' + currentuserid, {})
  }
  cancelfileReport_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/CancelFileReport/GetAllReport', data)
  }
  cancelfileReport_excel(clientId: number) {
    return this.http.post(this.actionUrl + '/CancelFileReport/Excel', clientId)
  }

  cancel_searchReport(searchkey: any) {
    return this.http.post(this.actionUrl + '/CancelFileReport/GetAllReportsearch', searchkey)
  }


  //short sale report

  shortsale_dropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/ShortSaleReport/ClientDropDown?CurrentUserId=' + currentuserid, {})
  }

  shortsale_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/ShortSaleReports/GetAllReports', data)
  }
  shortsale_getAllReportBroker(data: any) {
    return this.http.post(this.actionUrl + '/ShortSaleReports/GetAllReportsForBroker', data)
  }

  shortsale_searchReport(searchkey: any) {
    // let reqpayload = { "word": searchkey }
    return this.http.post(this.actionUrl + '/ShortSaleReports/GetAllReportsSearch', searchkey)
  }


  //sucess Report
  successRate_dropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/SuccessrateReport/UserDropDown?CurrentUserId=' + currentuserid, {})
  }
  successRate_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/SuccessrateReport/GetAllReport', data)
  }
  leadRate_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/LeadReport/LeadSuccessReport', data)
  }
  fileDataAllReport(data: any) {
    return this.http.post(this.actionUrl + '/FileStatus/FileStatusReport', {})
  }
  user_getAll(data: any,id:any) {
    return this.http.post(this.actionUrl +`/User/GetAllUserList?CurrentUserId=${id}`, data)
  }

  sucess_searchReport(searchkey: any) {
    return this.http.post(this.actionUrl + '/SuccessrateReport/GetAllReportsearch', searchkey)
  }

  // successReport_excel(userId: number) {
  //   return this.http.post(this.actionUrl + '/CancelFileReport/Excel', userId)
  // }

  //trend Report
  trendRate_dropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/TrendPriceReport/UserDropDown?CurrentUserId=' + currentuserid, {})
  }

  trendRate_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/TrendReport/TrendReport', data)
  }
  financialReport(data: any) {
    return this.http.post(this.actionUrl + '/Financial/FinancialReport', data)
  }

  trendRatesearchReport(searchkey: any) {
    return this.http.post(this.actionUrl + '/TrendPriceReport/GetAllReportsearch', searchkey)
  }

  //user usage Report
  userClient_dropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/UserUsageReport/ClientDropDown?CurrentUserId=' + currentuserid, {})
  }

  user_dropdown(clientid: number) {
    return this.http.post(this.actionUrl + '/UserUsageReport/UserDropDown?ClientId=' + clientid, {})
  }

  userUsage_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/UserUsageReport/GetAllReport', data)
  }

  userUsageDate_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/UserUsageReport/GetAllReportDateRange', data)
  }

  userUsage_searchReport(searchkey: any) {
    return this.http.post(this.actionUrl + '/UserUsageReport/GetAllReportsearch', searchkey)
  }

  //file per  closure  Report
  filesperClosure_dropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/FilesperForclosuredate/ClientDropDown?CurrentUserId=' + currentuserid, {})
  }

  filesperClosure_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/FilesperForclosuredate/GetAllReport', data)
  }
  filesperClosure_getAllReportBroker(data: any) {
    return this.http.post(this.actionUrl + '/FilesperForclosuredate/GetAllReportForBroker', data)
  }

  filesperClosuresearchReport(searchkey: any) {
    return this.http.post(this.actionUrl + '/FilesperForclosuredate/GetAllReportsearch', searchkey)
  }

  //closed file report
  closedFileReport_dropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/ClosedFilesReport/ClientDropDown?CurrentUserId=' + currentuserid, {})
  }

  closedFileReport_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/ClosedFilesReport/GetAllReport', data);
  }
  closedFileReport_getAllReportBroker(data: any) {
    return this.http.post(this.actionUrl + '/ClosedFilesReport/GetAllReportForBroker', data)
  }

  closedFile_searchReport(searchkey: any) {
    return this.http.post(this.actionUrl + '/ClosedFilesReport/GetAllReportsearch', searchkey)
  }

  //openclose report

  opencloseReport_UserTypedropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/OpenClosedFilesReport/UserTypeDropDown?CurrentUserId=' + currentuserid, {})
  }
  opencloseReport_Userdropdown(usertypeid: number) {
    return this.http.post(this.actionUrl + '/OpenClosedFilesReport/UserDropDown?UserTypeId=' + usertypeid, {})
  }
  opencloseReport_Statusdropdown(currentuserid: number) {
    return this.http.post(this.actionUrl + '/OpenClosedFilesReport/StatusDropDown?CurrentUserId=' + currentuserid, {})
  }

  opencloseReport_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/OpenClosedFilesReport/GetAllReport', data)
  }

  openclose_searchReport(searchkey: any) {
    return this.http.post(this.actionUrl + '/OpenClosedFilesReport/GetAllReportsearch', searchkey)
  }


  //number of file report

  numberoffileReport_dropdown() {
    return this.http.post(this.actionUrl + '/FilesperStage/StatusDropDown', {})
  }

  numberoffileReport_getAllReport(data: any) {
    return this.http.post(this.actionUrl + '/FilesperStage/GetAllReport', data)
  }

  numberoffilsearchReport(searchkey: any) {
    return this.http.post(this.actionUrl + '/FilesperStage/GetAllReportsearch', searchkey)
  }

  addOrUpdateLeadTask(leadTask: any) {
    return this.http.post(this.actionUrl + '/Task/AddLeadTask', leadTask)
  }
  GetAgentsForBroker(UserId: any) {
    return this.http.post(this.actionUrl + '/Task/GetAgentsForBroker?BrokerId=' + UserId, {})
  }
  assignedLeadtoAgent(reqList: any) {
    return this.http.post(this.actionUrl + '/Lead/AssignLeadtoAgent', reqList);
  }

  updateLeadTask(leadTaskList: any) {
    return this.http.post(this.actionUrl + '/Task/UpdateLeadTask', leadTaskList)
  }
  updateClientTask(clientTaskList: any) {
    return this.http.post(this.actionUrl + '/Task/UpdateClientTask', clientTaskList)
  }

  getloaDetails(getLeadId: any) {
    return this.http.post(this.actionUrl + '/Lead/GetLOADetails?LeadId=' + getLeadId, {})
  }
  getHomeOwnersGetall() {
    return this.http.get(this.actionUrl + '/HomeOwners/GetAll', {})
  }
  getLOAsenddocu(LOAdOcumObj: any) {
    return this.http.post(this.actionUrl + '/Lead/SendForSignature', LOAdOcumObj)
  }



  searchByall(searchkey: any, userid: number) {
    let reqpayload = { "word": searchkey, "usertypeId": userid }
    return this.http.post(this.actionUrl + '/userloginaudit/SearchByall', reqpayload)
  }

  searchByuser(searchkey: any, todaydate: any, userid: number) {
    let reqpayload = { "word": searchkey, "date": todaydate, "userId": userid, }
    return this.http.post(this.actionUrl + '/userloginaudit/SearchByUser', reqpayload)
  }
  configSetting(configReq: any) {
    return this.http.post(this.actionUrl + '/Configuration/UpdateUserConfig', configReq)
  }
  _getConfig_settings() {
    return this.http.get(this.actionUrl + '/Configuration/GetUserConfig');
  }
  // searchReport(searchkey: any) {
  //   let reqpayload = { "word": searchkey }
  //   return this.http.post(this.actionUrl + '/userloginaudit/SearchByUser', reqpayload)
  // }

  //**TAB TAKS */
  getAllTab_Tasks(reqpayload: any) {
    return this.http.post(this.actionUrl + '/Task/GetTaskDetailsForBasedOnTab', reqpayload)

  }
  getAllLeadTask(UserId: any) {
    return this.http.post(this.actionUrl + '/Task/GetAllLeadTask?UserId=' + UserId, {})
  }

  getClientAndLeadTask(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Task/GetClientAndLeadTask?CurrentUserId=' + currentuserid, {})
  }

  getLandByIdLead(UserId: any) {
    return this.http.post(this.actionUrl + '/Task/GetLeadByIdForTask?UserId=' + UserId, {})
  }

  //VideoFilter
  getManageVideoWithFilter(VideoFilterList: any) {
    return this.http.post(this.actionUrl + '/ManageVideo/GetManageVideoWithFilter', VideoFilterList)
  }
  getgetManageVideoDropdown(CurrentLogID: any) {
    return this.http.post(this.actionUrl + '/ManageVideo/GetManageVideodropdown?CurrentUserId=' + CurrentLogID, {})
  }

  getProfileList(userId: any) {
    return this.http.get(this.actionUrl + '/Accounts/GetAllUserById?UserGlobalUniqueId=' + userId, {})
  }

  
  //Notes


  getSendReplyNotes(data: any) {
    return this.http.post(this.actionUrl + '/ClientNotes/SendorReplyNotes', data, {})
  }

  getAllbyConversionId(conversionId: any) {
    return this.http.post(this.actionUrl + '/ClientNotes/GetAllByConversionId?ConversionId=' + conversionId, {})
  }

  getAllnotificationByUserId(currentuseruniqueId: any) {
    return this.http.post(this.actionUrl + '/ClientNotes/GetAllNotificationByUserId', currentuseruniqueId)
  }
  //getAll clients for notes
  getAllClientbydropdown(currentuserid: any) {
    return this.http.post(this.actionUrl + '/ClientNotes/GetAllClientByDropDown?CurrentUserId=' + currentuserid, {})

  }

  getAllUserTypebydropdown(clientid: any) {
    return this.http.post(this.actionUrl + '/ClientNotes/GetAllUserTypeByDorpDown?ClientId=' + clientid, {})
  }

  getConversionByClientId(data: any) {
    return this.http.post(this.actionUrl + '/ClientNotes/GetConversionByClientId', data)
  }





  getUserType() {
    return this.http.get(this.actionUrl + '/UserUsageReport/GetAllUserType');
  }

  getUserList(id: number) {
    return this.http.post(this.actionUrl + '/UserUsageReport/GetAllUserList?UserTypeId=' + id, {});
  }



  //dashboard Superadmin stats
  getDetailsForSuperAdminLOASigned(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminLOASigned?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminAssignedLead(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminAssignedLead?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminRejectedLead(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminRejectedLead?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminOpenFiles(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminOpenFiles?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminClosedFiles(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminClosedFilesCurrentMonth?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminProjectClosedFiles(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminProjectedClosedFiles?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminClosedPreviousMonth(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminClosedPreviousMonth?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminFilesinEscrow(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminFilesInEscrow?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminshortsale(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminShortSaleApprovals?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminfileswithforeclosure(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminFilesWithForeClosure?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForSuperAdminAssigntoBrokers(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForSuperAdminAssignedToBrokers?CurrentUserid=' + currentuserid, {})
  }
  //dashboard Superadmin stats

  //common dashboard stats
  getDetailsForcommonloasigned(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForLOASigned?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonAssignedLead(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForAssignedLead?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonRejectedLead(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForRejectedLead?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonOpenFiles(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForOpenFiles?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonClosedFiles(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForClosedFilesCurrentMonth?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonprojectClosedFiles(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForProjectedClosedFiles?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonClosedPreviousMonth(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForClosedPreviousMonth?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonFilesInEscrow(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForFilesInEscrow?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonshortsale(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForShortSaleApprovals?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonfileswithforeclosure(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForFilesWithForeClosure?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonassignedtoBrokers(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsForAssignedToBrokers?CurrentUserid=' + currentuserid, {})
  }


  getDetailsForcommonDueForTasks(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsofDueForTasks?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonClosedFileFromJan(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsofClosedFileFromJan?CurrentUserid=' + currentuserid, {})
  }

  getDetailsForcommonLeadstobeAssigned(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsofLeadstobeAssigned?CurrentUserid=' + currentuserid, {})
  }
  getDetailsForcommonAgents(currentuserid: any) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDetailsofAgents?CurrentUserid=' + currentuserid, {})
  }
  //common dashboard stats

  _get_taskByid(id: number) {
    return this.http.post(this.actionUrl + '/Task/GetClientTaskById?ClientTaskId=' + id, {});
  }

  _get_taskLeadByid(id: number) {
    return this.http.post(this.actionUrl + '/Task/GetLeadTaskById?ClientTaskId=' + id, {});
  }

  _get_taskupdateByid(obj: any) {
    return this.http.post(this.actionUrl + '/Task/UpdateClientTask', obj);
  }

  _get_userType(id: any) {
    return this.http.post(this.actionUrl + '/User/GetAllUserType?CurrentUserid=' + 1, {});
  }

  _get_UsersById(id: number) {
    return this.http.post(this.actionUrl + '/User/GetUserNameByTypeId?UserTypeId=' + id, {});
  }

  _get_userFilteredData(data: any) {
    return this.http.post(this.actionUrl + '/User/GetAllUsersUsingFilter', data);
  }

  _get_brokerOrAngentInfo(id: any) {
    return this.http.post(this.actionUrl + '/Clientshortsale/GetUserInfoForShortSale?ClientId=' + id, {});
  }


  //dashboard charts
  //Negotiator
  get_DashboardChartSuperadmin(currentuserid: number) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardChartsForSuperAdmin?CurrentUserId=' + currentuserid, {})
  }

  get_DashboardChartBroker(currentuserid: number) {
    return this.http.post(this.actionUrl + '/Dashboard/GetDashboardCharts?CurrentUserId=' + currentuserid, {})
  }



  //**notes History */

  getNotesHistoryByClientId(ids: any) {
    // https://apexresolution.colanonline.net/api/V1/ClientNotes/GetConversionByClientIds
    return this.http.post(this.actionUrl + '/ClientNotes/GetConversionByClientIds', ids)
  }

  getUpdateLeadLastOpenedDetails(idsObj: any) {
    return this.http.post(this.actionUrl + '/Lead/UpdateLeadLastOpenedDetails', idsObj)
  }


  //**clients get by ID */

  getClientById(clientid:number){
    // https://apexresolution.colanonline.net/api/V1/Client/GetAssignedClientById?ClientId=57
    return this.http.post(this.actionUrl+'/Client/GetAssignedClientById?ClientId='+clientid,{});

  }

  getClientDetails(clientid:any){
    // https://apexresolution.colanonline.net/api/V1/Client/GetClientDetailsById?ClientId=1
    return this.http.post(this.actionUrl+'/Client/GetClientDetailsById?ClientId='+clientid,{})
  }


  getLienHolderName(clientid:any){
    return this.http.post(this.actionUrl+'/ClientFileStatus/GetLienHolderName?ClientId='+clientid,{} )
  }
  leadQualify(leadId:any){
    return this.http.post(this.actionUrl+'/Lead/ChangeQualified?LeadId='+leadId,{} )
  }
  openFile(clientId:any,url:any){
    return this.http.post(this.actionUrl+url+clientId,{} )
  }
 


}
