import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';
import { lessThanValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';

@Component({
  selector: 'app-report-short-sales',
  templateUrl: './report-short-sales.component.html',
  styleUrls: ['./report-short-sales.component.css']
})
export class ReportShortSalesComponent implements OnInit {

  // public firstname: any;
  // public lastname: any;
  // public filterData: any = [];
  public reportId: any = 0;
  public reportName: any = 'report-short-sales';
  //dropdown
  public shortsaleDData: any = [];
  public shortsaleDataBack: any = [];
  //list
  public sslistData: any = [];

  order: string = 'homeOwnerName';
  p: number = 1;
  reverse: boolean = false;
  
  clientId:number = 0;
  public ssObj:any = {
    "clientId": 0,
    "fromdate": '',
    "todate": ''
  }
  

  datePickerConfig:any = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    max: moment(new Date()),
    disabled:true,
    
  };


  ssFromDateData = '';
  ssToDateData = '';
  ssReportData = '';
  usertype: any;
  searchsslistData: any;
  

  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) {

  }

  ngOnInit(): void {
    
    this.usertype = this.tokenStorage._get_userRole();
    console.log(this.usertype, 'Role')
    
    this.shortsaleGetAllReport();
    this.shortsaleDropdown();

    //this.filterData = this.manageData
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;
  }

  // filterList() {
  //   this.filterData = this.manageData.filter((e: any) => {
  //     return e.managersFirstname.indexOf(this.firstname) >= 0;
  //   });
  // }

  shortsaleDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.shortsale_dropdown(CurrentUserId).subscribe((response: any) => {
      this.shortsaleDData = response.listData;
      this.shortsaleDataBack = response.listData;
      console.log(this.shortsaleDData, "sssssaaaaa")
    })
  }


  shortsaleGetAllReport() {
    this._manager_service.shortsale_getAllReport(this.ssObj).subscribe((res: any) => {
      this.sslistData = res.listData ? res.listData : [];
      this.searchsslistData = res.listData ? res.listData : [];
      console.log(this.sslistData, 'Report-Sales')
    })
  }


  ssFilter() {
    this.ssObj={
      "clientId": this.clientId ? this.clientId : 0,
      "fromdate": this.ssFromDateData?this.ssFromDateData:"",
      "todate": this.ssToDateData?this.ssToDateData:"",

    }
    
  
    console.log(this.ssObj, "list");
    this.shortsaleGetAllReport();

  }

  ssReset() {
    this.ssObj.clientId = 0;
    this.ssObj.fromdate = '';
    this.ssObj.todate = '';
    this.clientId = 0;
    this.ssFromDateData = '';
    this.ssToDateData = '';
    this.shortsaleGetAllReport();
  }

  downloadExcel() {
    var data:any={
      "clientId":this.clientId?this.clientId:0,
      "fromdate":this.ssFromDateData?this.ssFromDateData:"",
      "todate":this.ssToDateData?this.ssToDateData:""
    };

 
    this._file_upload.short_rate_report(data).subscribe((fileObj: any) => {
   
      window.open(fileObj.extraData['ExcelUrl']);
      // this.Toastr.showSuccess(fileObj.message);
    });
  }

  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  searchReport() {
    let ssObjsearch = {
      "clientId": 0,
      "fromdate": '',
      "todate": '',
      "word": this.ssReportData
    }
    this._manager_service.shortsale_searchReport(ssObjsearch).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.sslistData = [];
      }
      else {
        this.sslistData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }

  onSearchTitle(e: any) {
    this.shortsaleDData = this.shortsaleDataBack.filter((item: any) => item.clientName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.sslistData = self.searchsslistData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

}
