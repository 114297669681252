import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';

@Component({
  selector: 'app-success-rate-report',
  templateUrl: './success-rate-report.component.html',
  styleUrls: ['./success-rate-report.component.css']
})
export class SuccessRateReportComponent implements OnInit {
  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  public userType: any = [];
  p: number = 1;
  public reportName: any = 'success-rate-report';

  public userList: any = [];
  public userListBack: any = [];
  public userTypeBack: any = [];
  userTypeId = '0';
   sucessReportObj:any = {
    "userTypeId": 0,
    // "openClose": 0,
    // "fromDate": '',
    // "todate": '',
    "createdBy": this.tokenStorage._get_userTypeId()
  }
  public UserData: any = [];
  public successRateData: any = [];
  public successobj: any = {}
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    max: moment(new Date()),
  };
  sucessReport = '';
  userId = '0';
  FromDateData = '';
  ToDateData = '';
  openClose = '0';
  searchsuccessRateData: any;
  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    
    this.successRategetAllReport();
    this.successRateDropdown();
    this.getUserType();

  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }
  getUserType() {
    this._manager_service.getUserType().subscribe((response: any) => {
      this.userType = response.listData;
      this.userTypeBack = response.listData;
      console.log(this.userType, "userType")
    })
  }
  successRateDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentData")
    this._manager_service.successRate_dropdown(CurrentUserId).subscribe((res: any) => {
      this.UserData = res;
      console.log(res, "userdata")
    })
  }

  successRategetAllReport() {
    this._manager_service.successRate_getAllReport(this.sucessReportObj).subscribe((res: any) => {
      if(res)this.successRateData = res.listData;
      if(res)this.searchsuccessRateData = res.listData;
      
      console.log(this.successobj, "sucessobj");
      console.log(this.successRateData, "sucessRate")


    })
  }

  successFilter() {
    
    this.sucessReportObj={
      "userTypeId":parseInt(this.userTypeId),
//       "fromDate":this.FromDateData?this.FromDateData:"",
// "todate": this.ToDateData? this.ToDateData:""
    }

    // this.sucessReportObj.openClose = parseInt(this.openClose);
    console.log(this.sucessReportObj, "list");
    this.successRategetAllReport();
  }

  successReset() {
    this.sucessReportObj.userTypeId = 0;
    // this.sucessReportObj.openClose = 0;
    this.sucessReportObj.fromDate = '';
    this.sucessReportObj.todate = '';
    this.userTypeId = '0';
    this.openClose = '0';
    this.FromDateData = '';
    this.ToDateData = '';
    console.log(this.sucessReportObj, "list");
    this.successRategetAllReport();
  }

  downloadExcel() {
    this.sucessReportObj={
      "userTypeId":parseInt(this.userTypeId),
      "fromDate":this.FromDateData?this.FromDateData:"",
"todate": this.ToDateData? this.ToDateData:""
    }
    this._file_upload.success_rate_report(this.sucessReportObj);
  }

  onselectReport(assignVal: any) {
    // debugger
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }
  


  getUserListById(userTypeId: number) {
    this._manager_service.getUserList(userTypeId).subscribe((response: any) => {
      this.userList = response.listData;
      this.userListBack = response.listData;
    })
  }
  onSearchUsersType(e: any) {
    this.userType = this.userTypeBack.filter((item: any) => item.userTypeName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  sucessRatesearchReport() {
    
    let searchsucessReportObj = {
      "userTypeId": 0,
      "openClose": 0,
      // "fromDate": '',
      // "todate": '',
      "createdBy": this.tokenStorage._get_userTypeId(),
      "word": this.sucessReport
    }
    this._manager_service.sucess_searchReport(searchsucessReportObj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.successRateData = [];
      }
      else {
        this.successRateData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }

    onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.successRateData = self.searchsuccessRateData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }
}
