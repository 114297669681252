import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';


@Component({
  selector: 'app-user-usage-report',
  templateUrl: './user-usage-report.component.html',
  styleUrls: ['./user-usage-report.component.css']
})
export class UserUsageReportComponent implements OnInit {

  public reportId: any = 0;
  order: string = 'clientName';
  reverse: boolean = false;
  p: number = 1;
  public reportName: any = 'user-usage-report';

  datePickerConfig = {
    format: 'MM/DD/YYYY',
    max: moment(new Date()),
  };

  //DropDown
  public userUsageData: any = [];
  public userDataa: any = [];
  public userType: any = [];
  public userTypeBack: any = [];
  public userList: any = [];
  public userListBack: any = [];

  //list
  public userUsageListData: any = [];
  public userUsageobjlst: any = {};

  //obj
  public userUsageOBj:any = {
    
    "userTypeId": 0,
    // "fromdate": '',
    // "todate": '',
    "createdBy": this.tokenStorage._get_userTypeId()
  }
  userusageReport = '';
  clientIdData = '';
  userId = '0';
  //userTypeId: number;
  userTypeId = '0';
  userIdData = '0';
  userUsageFromDateData = '';
  userUsageToDateData = '';
  public userData: any = [];clientId: number;
  searechUserUsageListData: any;
;
  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userUsageGetAllReport();
    this.userClientDropdown();
    this.getUserType()
  }

  userClientDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.userClient_dropdown(CurrentUserId).subscribe((uuresponse: any) => {
      this.userUsageData = uuresponse.listData;
      console.log(this.userUsageData, "uudataaaaa")
    })
  }

  getUserType() {
    this._manager_service.getUserType().subscribe((response: any) => {
      this.userType = response.listData;
      this.userTypeBack = response.listData;
      console.log(this.userType, "userType")
    })
  }

  getUserListById(userTypeId: number) {
    this._manager_service.getUserList(userTypeId).subscribe((response: any) => {
      this.userList = response.listData;
      this.userListBack = response.listData;
    })
  }

  getUserId(assignVal: any) {
    console.log(assignVal, "helllooo")
    this.user_dropdown(assignVal.target.value)
    console.log(assignVal, "assignedClientId");
    this.clientId = parseInt(assignVal.target.value);
    console.log(this.clientId, "Hello1");
  }

  user_dropdown(id: number) {
    this._manager_service.user_dropdown(id).subscribe((res: any) => {
      this.userDataa = res.listData;
    })
  }

  userUsageGetAllReport() {
    this._manager_service.userUsage_getAllReport(this.userUsageOBj).subscribe((res: any) => {
      // this.userUsageobjlst = (res.listData != null) ? res.listData : [];
      this.userUsageListData = (res.listData != null) ? res.listData : [];
      this.searechUserUsageListData = (res.listData != null) ? res.listData : [];
      //console.log(this.userUsageobjlst, "userUsageobjlst");
      console.log(this.userUsageListData, "userUsageListData")
    })
  }

  userUsageFilter() {

    // this.userUsageOBj.clientId = parseInt(this.clientIdData);
this.userUsageOBj={
  "userTypeId": parseInt(this.userTypeId),
  // "fromdate": this.userUsageFromDateData,
  // "todate": this.userUsageToDateData,
}

    this.userUsageGetAllReport();

  }

  userUsageReset() {
    // this.userUsageOBj.clientId = 0;
    this.userUsageOBj.userTypeId = 0;
    this.clientIdData = '0';
    this.userTypeId="0";
    this.userIdData = '0';
    // this.userUsageOBj.fromdate = '';
    // this.userUsageOBj.todate = '';
    // this.userUsageFromDateData = '';
    // this.userUsageToDateData = '';
    this.userUsageGetAllReport();
  }

  downloadExcel() {
    this.userUsageOBj={
      "userTypeId": parseInt(this.userTypeId),
      // "fromdate": this.userUsageFromDateData,
      // "todate": this.userUsageToDateData,
    }
    this._file_upload.userUsage_report(this.userUsageOBj);
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }
  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  userusagesearchReport() {
    let searchuserUsageOBj = {
      // "clientId": 0,
      "userTypeId": 0,
      "createdBy": this.tokenStorage._get_userTypeId(),
      "word": this.userusageReport
    }
    this._manager_service.userUsage_searchReport(searchuserUsageOBj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.userUsageListData = [];
      }
      else {
        this.userUsageListData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }

  onSearchUsersType(e: any) {
    this.userType = this.userTypeBack.filter((item: any) => item.userTypeName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }

  onSearchUsers(e: any) {
    this.userList = this.userListBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  
  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.userUsageListData = self.searechUserUsageListData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        // self.successRateData=this.successRateData
        return false;
      }
    }));
  }

}
