export class clientClosingStatementModel {
    sattlementHudid?: 0;
    clientId?: 0;
    loanType1?: "string";
    loanType2?: "string";
    fileNumber?: "string";
    loanNumber?: "string";
    mortageIncCaseNo?: "string";
    notes?: "string";
    settlemetAgentName?: "string";
    settlementAgentPhone?: "string";
    settlementDate?: "string";
    isActive?: true;
    createdBy?: "string";
    createdOn?: "string";
    updatedBy?: "string";
    countryTax?: 0;
    cityTownTax?: 0;
    personalProperty?: "string";
    contractSalesPrice?: 0;
    settlementCharges?: 0;
    assessment?: "string";
    depositEarnestMoney?: 0;
    principalAmount?: 0;
    existingLoanSubjectTo?: "string";
    grossAmountDueFromBorrower?: 0;
    sellerCityTownTax?: 0;
    sellerCountryTax?: 0;
    sellerAssessment?: "string";
    totalForBorrower?: 0;
    cash?: 0;
    cashDetails?: "string";
    lessAmountBorrower?: 0;

    "sellerSummaryModel": {
        contractSalesPrice?: 0;
        personalproperty?: "string";
        sellerCityTax?: 0;
        sellerCountryTax?: 0;
        personnalPropertyseller?: "string";
        cityTownTax?: 0;
        countryTax?: 0;
        schoolTax?: 0;
        grossAmountSeller?: 0;
        assessment?: "string";
        excessDepsoite?: 0;
        sattlementChargeSeller?: 0;
        existingLoanSubjectTo?: 0;
        payoff1stChase?: 0;
        payoff2stChase?: 0;
        sellerAssessment?: "string";
        sellerIncentive?: 0;
        fhaincentive?: 0;
        totalRedectionSeller?: 0;
        grossAmountSeller2?: 0;
        lessRedectionSeller?: 0;
        cashDetails?: "string";
        cash?: 0;
        contSecondLienHolder?: "string";
    }

    "setetlementcharges": {
        brokerpercentage?: 0;
        brokerFee?: 0;
        peakReality?: 0;
        panorexRealirt?: 0;
        orginalCharges?: 0;
        yourCredit?: 0;
        yourAdjOrginalCharges?: 0;
        appraisalFee?: 0;
        creditFee?: 0;
        taxServieTo?: 0;
        floodCertification?: 0;
        dailyInrestChargeFrom?: "string";
        dailyInrestChargeTo?: "string";
        detailedEcrowCharges?: 0;
        dailychargePercentage?: 0;
        mortageInsurencefrom?: "string";
        mortageInsurenceTo?: "string";
        homeOwnerIncFrom?: 0;
        homeOwnerIncTo?: 0;
        escrowAccount?: 0;
        propertyTaxMonth?: "string";
        propertyTaxperMonth?: 0;
        propertyTaxMonth1?: "string";
        propertyTaxperMonth1?: 0;
        propertyTaxMonth2?: "string";
        propertyTaxperMonth2?: 0;
        aggregateAdjustment?: 0;
        lenderTitleInsurence?: 0;
        settleMentFee?: 0;
        lendersTitleIns?: 0;
        lendersTitlePolicyLimit?: 0;
        attorneyFees?: 0;
        agentPortionTotalInsurence?: 0;
        underWriterPortion?: 0;
        wireFees?: 0;
        gmrecordingFees?: 0;
        gmdeed?: 0;
        gmmortage?: 0;
        gmrealse?: 0;
        transferFees?: 0;
        cityTaxStampDeed?: 0;
        cityTaxStampMortage?: 0;
        stateTaxStampDeed?: 0;
        stateTaxStampMortage?: 0;
        comissionatsettlement?: 0;
        requiredService?: 0;
        hoadequencies?: 0;
        hoatransferFees?: 0;
        subEscrow?: 0;
        sellerClosingCost?: 0;
        totalSattlementCharge?: 0;
        updatedBy?: "string";
        homeOwnerIncMonth?: "string";
        homeOwnerIncPerMonth?: 0;
        mortageIncMonth?: "string";
        mortageIncPerMonth?: 0;
        totalAmount?: 0;
    }

    "borrowerDetails": {
        borrowerFirstName?: "string";
        borrowerLastName?: "string";
        borrowerAddress?: "string";
        borrowerCityId?: 0;
        borrowerStateId?: 0;
        borrowerZipCode?: "string";
    }

    "sellerDetails": {
        sellerCityId?: 0;
        sellerStateId?: 0;
        sellerZipCode?: "string";
        sellerFirstName?: "string";
        sellerLastName?: "string";
        sellerAddress?: "string";
    }

    "lenderDetails": {
        lenderCityId?: 0;
        lenderStateId?: 0;
        lenderZipCode?: "string";
        lenderName?: "string";
        lenderAddress?: "string";
    }
}