import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { FileuploadService } from 'src/app/service/fileupload.service'
import { TokenService } from '../../service/token.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-manager-list',
  templateUrl: './manager-list.component.html',
  styleUrls: ['./manager-list.component.css']
})
export class ManagerListComponent implements OnInit {
  listOfManager: any ;
  activeUserList: any = [];
  inActiveUserList: any = [];
  deletedUserList: any = [];
  DblistOfManager: any = [];
  order: string = 'managersFirstname';
  p: number = 1;
  reverse: boolean = false;
  deleteData: any;
  userInfo: any
  loggedUserId: any;

  userListOptions: any = [];
  userListOptionsBack: any = [];
  statusListOptions: any;
  filterRequest: any = {
    status: 2
  };
  userTypeId: any;
  userTypeList: any = [];

  activeTitle:string = 'active'
  constructor(private toast: ToastrService, private tokenStorage: TokenService, private managerService: ManagerService, private dropDownService: DropdownService, private fileupload: FileuploadService) { }

  ngOnInit(): void {
    this.userTypeId = this.tokenStorage._get_userTypeId() ;
 console.log("this.userTypeId ", this.userTypeId);
    this.userInfo = this.tokenStorage._get_userInfo();
    this.loggedUserId = JSON.parse(this.userInfo).CurrentLoggedUserId;
    // this.getfilter_details();
    //this._getManager_records();
    this.getManagerUsers(this.userTypeId);
    this.getUserType();
  }

  // _getManager_records() {
  //   this.managerService._get_All_manager().subscribe((data: any) => {
  //     this.listOfManager = data.listData;
  //     console.log(data.listData)
  //   })
  // }
  
  getManagerUsers(data: number) {
 console.log("data ", data);
    this.activeTitle = 'active';
    this.managerService._get_All_managerUsers(data).subscribe((res: any) => {
      this.listOfManager = res.listData;
      this.DblistOfManager = res.listData;
      console.log(res.listData)
    })
  }
  getAllinActiveUsers() {
    this.activeTitle = 'inActive';
    this.managerService._get_All_inActiveUsers(this.userTypeId).subscribe((res: any) => {
      this.listOfManager = res.listData;
      this.DblistOfManager = res.listData;
      console.log(res.listData)
    })
  }

  getAlldeleteActiveUsers() {
    this.activeTitle = 'delete';
    this.managerService._get_All_deleteActiveUsers(this.userTypeId).subscribe((res: any) => {
      this.listOfManager = res.listData;
      this.DblistOfManager = res.listData;
      console.log(res.listData)
    })
  }

  setId(data: any) {
    // let deleteData: any;
    // deleteData['createdBy'] = sessionStorage.getItem('userTypeId');
    this.deleteData = data;

  }
  _delete_manager() {
    this.deleteData['createdBy'] = sessionStorage.getItem('userTypeId');
    this.managerService._delete_manager(this.deleteData).subscribe((deleteData: any) => {
      console.log(deleteData)
      this.getManagerUsers(this.userTypeId);
      //this.toast.success(this.deleteData.message);
      if (deleteData.reponse == true) {
        this.toast.success('Deleted Sucessfully');
      }
      else {
        this.toast.error('ERROR OCCURED');
      }
    })


  }


  getFilterData() {
    console.log(this.filterRequest)
    var data = this.filterRequest
    data.status = parseInt(data.status);
    data.userName = data.userName ? data.userName : "0";
    // data.currentUserId = parseInt(this.loggedUserId);
    data.currentUserId = parseInt(this.loggedUserId);
    // this.dropDownService._getAll_manager_FilterData(data).subscribe((filteredData: any) => {
    this.managerService._get_userFilteredData(data).subscribe((filteredData: any) => {
      console.log("filteredData ", filteredData);
      this.listOfManager = filteredData.listData;
      this.DblistOfManager = filteredData.listData;
    });
  }

  //**Get Details for Filter *//
  getfilter_details(id: number) {
    console.log("id ", id);
    // this.dropDownService._getAll_manager_UserList().subscribe((userList: any) => {
    this.managerService._get_UsersById(id).subscribe((userList: any) => {
      console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
      this.userListOptionsBack = userList.listData;
    })
    // this.dropDownService._getAll_manager_StatusList().subscribe((statusList: any) => {
    //   console.log(statusList.listData, "sji")
    //   this.statusListOptions = statusList.listData;
    // })
  }
  //**End */

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  downloadExcel() {
  
    var data:any={};
    // data.status = parseInt(data.status);
    data.userTypeId = data.userTypeId ? data.userTypeId : 0;
    data.fromDate ="";
    data.toDate ="";
    // data.currentUserId = parseInt(this.loggedUserId);
    data.curentUserId = parseInt(this.loggedUserId);
    this.fileupload.download_alluser(data);
  }

  downloadInactiveUsers() {
    this.fileupload.download_inActiveuser();
  }
  downloaddeletedUsers() {
    this.fileupload.download_deleteduser();
  }
  manageReset() {
    this.getManagerUsers(this.userTypeId);
  }

  onSearchUsers(e: any) {
    this.userListOptions = this.userListOptionsBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
    
  }

  getUserType() {
    var self = this;
    self.managerService._get_userType(self.loggedUserId).subscribe((res: any) => {
      console.log("res ==>_get_userType", res);
      self.userTypeList = res.listData ? res.listData : [];
    })
  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.listOfManager = self.DblistOfManager.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }
}
