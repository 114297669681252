import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from '../../service/dropdown.service';
import { FileuploadService } from '../../service/fileupload.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
// import { leadmodel } from '../../models/lead.model';
import { validation } from '../../models/lead.validation';

@Component({
  selector: 'app-lead-disqualified-list',
  templateUrl: './lead-disqualified-list.component.html',
  styleUrls: ['./lead-disqualified-list.component.css']
})
export class LeadDisqualifiedListComponent implements OnInit {

  public userListBack: any = [];
  public homeOwnerListBack: any = [];
  public leadData: any = [];
  public DbleadData: any = [];
  order: string = '';
  public userTypes: any;
  public userLists: any;
  getDropdownUser: any
  getUserlist: any;
  p: number = 1;
  reverse: boolean = false;
  masterSelected: boolean;
  checkedList: any;
  myuserType: any;
  userListsAgent: any
  userLeadId: any
  searchKey: any;
  showCross: boolean = false;
  userListOptions: any;
  homeOwnerList: any;
  usertypedropdown: any
  mangeDropdown: any
  Lastusertypedropdown: any
  checkedListForAgent: any
  filterRequest: any = {};
  getListagentBroker: any
  usertypeId: any
  userInfo: any
  currentLoggedID: any
  userInfoList: any
  leadId: any;
  constructor(private _manager_service: ManagerService,
    private activated_route: ActivatedRoute,
    private dropDown: DropdownService,
    private _file_upload: FileuploadService,
    private tokenStorage: TokenService,
    private _dropdown: DropdownService,
    private toastr: ToastService,) {
    this.masterSelected = false;
    this.getCheckedItemList();
  }


  ngOnInit(): void {
   
    this.myuserType = this.tokenStorage._get_userRole();
    this.usertypeId = this.tokenStorage._get_userTypeId();
    this.userInfo = this.tokenStorage._get_userInfo();

    this.userInfoList = JSON.parse(this.userInfo);

    this.currentLoggedID = this.userInfoList.CurrentLoggedUserId;
    this.getAgentsForBroker();
    this.userType_dropDown();
    this.getfilter_details();
    this.getAll_disqualifiedlead();
    this.usertypes_Dropdown();
    this._agent_Dropdown();

  }

  //**GetAll Rejected Lead */
  getAll_disqualifiedlead() {
    this.showCross = false;
    this.searchKey = "";
    this._manager_service._getAll_disqualified().subscribe((disqualifiedData: any) => {
      this.leadData = disqualifiedData.listData ? disqualifiedData.listData : [];
      this.DbleadData = disqualifiedData.listData ? disqualifiedData.listData : [];
      console.log(this.leadData, '999')
    })
  }
  //**END */


  assign_lead() {
    console.log(this.checkedList.length);
    if (this.checkedList.length <= 0) {
      this.toastr.showError(" please select atleast one lead to assign")
    } else {

      this._manager_service._assign_Lead(this.checkedList).subscribe((data: any) => {

        if (data.reponse == true) {
          this.getAll_disqualifiedlead();
          this.toastr.showSuccess(data.message);
        } else {
          this.toastr.showError(data.message);
        }


        //  this.toastr.showSuccess("Lead Assigned")
      })
    }
  }

  getFilterData() {
    console.log(this.filterRequest);
    this.filterRequest['homeOwnerId'] = parseInt(this.filterRequest.homeOwnerId);
    this.filterRequest['userId'] = parseInt(this.filterRequest.userId);
    this._dropdown._getAll_RejectedLead_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.leadData = filteredData.listData ? filteredData.listData : [];
      this.DbleadData = filteredData.listData ? filteredData.listData : [];
    });
  }

  //**Get Details for Filter */
  getfilter_details() {
    this._dropdown._getAll_RejectedLead_UserList().subscribe((userList: any) => {
      console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
    })
    this._dropdown._getAll_lead_homeOwner().subscribe((homeowner: any) => {
      console.log(homeowner.listData, "sji")
      this.homeOwnerList = homeowner.listData;
      this.homeOwnerListBack = homeowner.listData;
    })
  }

  //**Enddon tpuch here ts*/

  userType_dropDown() {
    this._dropdown._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      //  console.log(this.userTypes,"ji")
    })

  }
  //**Usertype Dropdown */
  usertypes_Dropdown(id?: any) {
    // this._dropdown._getuser_TypeList().subscribe((typelist: any) => {
    //   this.userTypes = typelist['listData'];
    // })

    console.log(this.getDropdownUser, "ji")

    this._dropdown._get_Userlist(this.getDropdownUser.userTypeId).subscribe((getuserlist: any) => {
      this.userLists = getuserlist.listData;
      console.log(this.userLists, "dsdsdsf")
    })

  }
  manger_Dropdown(e: any) {
    this.usertypedropdown = e.target.value;
    console.log(this.usertypedropdown, 'typs')
    console.log(e, 'typsssssssssssss')
    if (this.usertypedropdown == 'Broker') {
      //   alert('1');
      this._manager_service.brokerGetall().subscribe((data: any) => {
        console.log(data, 'res')
        this.Lastusertypedropdown = 3;
        this.mangeDropdown = data.listData
      })
    } else if (this.usertypedropdown == 'Agent') {
      //  alert('2');
      this._manager_service.agentGetall().subscribe((data: any) => {
        console.log(data, 'res')
        this.Lastusertypedropdown = 4;
        this.mangeDropdown = data.listData
      })
    }
  }

  _agent_Dropdown() {
    this._dropdown._get_Userlist(4).subscribe((getuserlist: any) => {
      this.userListsAgent = getuserlist.listData;
      console.log(this.userListsAgent, "dsdsdsf")
      console.log(getuserlist, "dsdsdsf")
    })
  }

  //**END */


  //**SORT ORDER */
  setOrder(value: string) { if (this.order === value) { this.reverse = !this.reverse; } this.order = value; }
  //**END SORT */

  //**CheckBox in lead list */
  checkUncheckAll() {
    for (var i = 0; i < this.leadData.length; i++) { this.leadData[i].isSelected = this.masterSelected; } this.getCheckedItemList()
  }

  isAllSelected() { this.masterSelected = this.leadData.every(function (item: any) { return item.isSelected == true; }); this.getCheckedItemList(); }

  getCheckedItemList() {
    let assignlead: any = []
    this.checkedList = [];
    this.checkedListForAgent = [];
    if (this.myuserType != 'Broker') {
      for (var i = 0; i < this.leadData.length; i++) {
        if (this.leadData[i].isSelected)

          this.checkedList.push({
            'leadId': this.leadData[i].leadId,
            'createdBy': sessionStorage.getItem('userTypeId'),
            'userId': this.getUserlist.userId,
            'userTypeId': Number(this.getDropdownUser.userTypeId)
          });
      }
    }
    else if (this.myuserType == 'Broker') {
      //alert('broker')
      for (var i = 0; i < this.leadData.length; i++) {
        if (this.leadData[i].isSelected)
          this.checkedListForAgent.push({
            "leadId": this.leadData[i].leadId,
            "userId": Number(this.userLeadId),
            "userTypeId": 4,
            "isReassigned": true,
            "createdBy": sessionStorage.getItem('userTypeId')



          });
      }


    }
    // for (var i = 0; i < this.leadData.length; i++) {
    //   if (this.leadData[i].isSelected)
    //     this.checkedList.push({
    //       'leadId': this.leadData[i].leadId,
    //       'userId': this.getUserlist.userId,
    //       'userTypeId': this.getDropdownUser.userTypeId,
    //       "isReassigned": true,
    //       "createdBy": this.currentLoggedID
    //     });
    // }
    this.checkedList = this.checkedList;
    // console.log(this.checkedList)
  }
  //**END */

  //**Bulk File Upload */
  uploadFile(fileEvent: any) { this._file_upload.uploadFile(fileEvent) }
  //**END */
  downloadExcel() {
    this._file_upload.download_template();
  }
  downloadExcellist() {
    this._file_upload.download_list_lead();
  }
  downloadExcellistdisqlaify() {
    this._file_upload.download_list_lead_disqualified();
  }
  showCrossicon() {
    this.showCross = true;

  }
  // leadSearch() {
  //   console.log(this.searchKey)
  //   this._dropdown.search_lead(this.searchKey).subscribe((searchData: any) => {
  //     this.leadData = searchData.listData;
  //     console.log(searchData.listData)
  //   })
  // }
  leadSearch(searchkey: any) {
    console.log(this.searchKey)
    this._dropdown.search_lead(searchkey).subscribe((searchData: any) => {
      this.leadData = searchData.listData;
      console.log(searchData.listData)
    })
  }

  getAgentsForBroker() {
    this._manager_service.GetAgentsForBroker(this.usertypeId).subscribe((resp: any) => {
      //alert('getAgentsForBroker')
      this.getListagentBroker = resp.listData;

      this.userListBack = resp.listData;
      console.log(this.getListagentBroker, 'List agentfor broker')
    })
  }
  agentList(e: any) {
    
    console.log(e, 'selectDropdown')
    //let value = e.target.value
    this.userLeadId = e;
  }
  _sentassignedLeadtoAgent() {
    console.log(this.checkedListForAgent, 'fpr, agte')
    this._manager_service.assignedLeadtoAgent(this.checkedListForAgent).subscribe((data: any) => {
      console.log(data, '_sentassignedLeadtoAgent')
      if (data.reponse == true) {
        this.getAll_disqualifiedlead();
        this.toastr.showSuccess(data.message);
      } else {
        this.toastr.showError(data.message);
      }
    })
  }

  onSearchUsers(e: any) {
    this.userLists = this.userListBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
  }
  onSearchOwner(e: any) {
    this.homeOwnerList = this.homeOwnerListBack.filter((item: any) => item.ownerName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.leadData = self.DbleadData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

  setId(id:any){
    this.leadId=id
  }
  qualifyLead(){
 this._manager_service.leadQualify(this.leadId).subscribe((res:any)=>{
   console.log(res)
   if(res && res.response==true){
     this.toastr.showSuccess( res.message);
   }
 },
 err=>{

 })
  }

}
