import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { greaterThanValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
import { update } from 'lodash';
import { TokenService } from '../../service/token.service';
import { DatePipe } from '@angular/common';
import { ComponentCanDeactivate } from 'src/app/can-deactivate.guard';
import { Observable } from 'rxjs';
import { requiredTrue } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-task-template-detail',
  templateUrl: './task-template-detail.component.html',
  styleUrls: ['./task-template-detail.component.css']
})
export class TaskTemplateDetailComponent implements OnInit ,ComponentCanDeactivate {
  public specificfileData: any = [];
  public assigntabData: any = [];
  public list: any;
  public leadlist: any;
  public updateTemplateData: Boolean = false;
  tasktemplateForm: any = FormGroup;
  userTypeId: any;
  createTempFromTab:any= false;
  //


  submitted = false;
  constructor(private datePipe: DatePipe,    private tokenStorage: TokenService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute, private toastr: ToastService) {
    this.createTempFromTab = sessionStorage.getItem('edittemplatetab')
   }
  canDeactivate(): boolean | Observable<boolean> {
    return !this.tasktemplateForm.touched;
  }

  ngOnInit(): void {
    this.userTypeId = this.tokenStorage._get_userTypeId();
    this.activated_route.params.subscribe((param: any) => {
      let id: number;


      if (param['id'] != null || undefined) {
        id = param['id'];
        this.editTaskTemplate(id);
        this.updateTemplateData = true;
      } else { }


    });
    this.tasktemplateForm = this.formBuilder.group({
      templateName: ['', Validators.required],
      taskTemplateId: '',
      createdBy: sessionStorage.getItem('userTypeId'),
      templatechild: new FormArray([]),
      createdOn :this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

      // day: ['', Validators.required],
      // description: ['', Validators.required],
      // priority: ['', Validators.required],
      // fileTabId: ['', Validators.required],
      // userTypeId: ['', Validators.required],

    })

    this.getGetfiletab();
    this.getAssignedUserType();
    this.updateField();
  }

  get f() { return this.tasktemplateForm.controls; }
  get t() { return this.f.templatechild as FormArray; }
  increment(i: any) {
    this.updateField(i + 1);
  }

  decrement(i: number) {
    // let i = (this.t.length > 1) ? this.t.length : 2;
    // this.updateField(i - 1);
    this.t.removeAt(i);
  }


  updateField(total = 1) {
    // const numberOfTemplatechild = total;
//  console.log("this.t.length < numberOfTemplatechild ", this.t.length < numberOfTemplatechild);
    // if (this.t.length < numberOfTemplatechild) {
    //   for (let i = this.t.length; i < numberOfTemplatechild; i++) {
        this.t.push(this.formBuilder.group({
          day: ['', Validators.required],
          description: ['', Validators.required],
          priority: ['', Validators.required],
          fileTabId: ['', Validators.required],
          //userTypeId: ['', Validators.required],
          createdBy: sessionStorage.getItem('userTypeId')
        }));
    //   }
    // } else {
    //   for (let i = this.t.length; i >= numberOfTemplatechild; i--) {
    //     this.t.removeAt(i);
    //   }
    // }
  }


  tasktemplateSubmit() {
    // alert()
    // alert('hi');
    this.submitted = true;
    if (this.tasktemplateForm.invalid) {
      return;
    }
    // this.tasktemplateForm.value.userTypeId = parseInt(this.tasktemplateForm.value.userTypeId);

    let data = this.tasktemplateForm.value;

    data.templatechild = data.templatechild.map(function (i: any) { i.userTypeId = parseInt(i.userTypeId); return i; })
    console.log(data);
    // data["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')
    if (!this.updateTemplateData) {
      // alert("create")
      this._manager_service.add_tasktemplate(data).subscribe((tasktemplateinfo: any) => {
        console.log(tasktemplateinfo, "add task template");
        this.tasktemplateForm.markAsUntouched();
        if (tasktemplateinfo.reponse == true) {
          this.toastr.showSuccess('Task Created');

          if(this.createTempFromTab  == 'true'){
            this.route.navigate(['/superadmin/client-file-status']);
           
          }else{
            this.route.navigate(['/superadmin/task-template']);
          }
        


        } else {
          this.toastr.showError('ERROR OCCURED');
        }

      })
      // alert('create').

    } else {
      // alert("update")
      this.updateTemplateData = false;
      // alert('update')

      this.updateTaskTemplate(data);
    }

  }


  onReset() {
    this.submitted = false;
    this.tasktemplateForm.reset();
  }

  getGetfiletab() {
    this._manager_service.get_Getfiletab().subscribe((res: any) => {
      this.specificfileData = res.listData;
    })
  }

  getAssignedUserType() {
    this._manager_service.get_AssignedUserType().subscribe((res: any) => {
      this.assigntabData = res.listData;

    })
  }



  updateTaskTemplate(updateData: any) {
    updateData['createdBy'] = sessionStorage.getItem('userTypeId')
     
    this._manager_service.update_tasktemplate(updateData).subscribe((updatedList: any) => {
      console.log(updatedList, "Updatedtasktemplate");
      this.tasktemplateForm.markAsUntouched()
      this.route.navigate(['/superadmin/task-template']);
    })
  }




  editTaskTemplate(editData: any) {


    this._manager_service.get_tasktemplatedetail(editData).subscribe((res: any) => {
      //this.list = res.listData;
      this.tasktemplateForm.patchValue({ 'templateName': res.listData[0].taskTemplateName, 'taskTemplateId': parseInt(res.listData[0].taskTemplateId) });
      this.t.removeAt(0);


      res.listData[0].taskchilddetails.forEach((data: any) => {
        this.t.push(this.formBuilder.group(
          { 'day': data.day, 'description': data.description, 'priority': data.priority, 'fileTabId': data.assignedTabId, 'createdBy': sessionStorage.getItem('userTypeId') }


        ))
      });




    })
  }


  ngOnDestroy(){
    sessionStorage.removeItem('edittemplatetab')

  }




}
