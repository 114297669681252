import { Component, OnInit } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
import { toBoolean } from '@rxweb/reactive-form-validators';
@Component({
  selector: 'app-assitnegotiators-edit',
  templateUrl: './assitnegotiators-edit.component.html',
  styleUrls: ['./assitnegotiators-edit.component.css']
})
export class AssitnegotiatorsEditComponent implements OnInit {
  addAsstNegotiator: any = {};
  getAsstNegotiator: any = {};
  id: any = {};
  updateAsstNegotiator: Boolean = false;
  message: any;
  constructor(private toast: ToastService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }
  titleDetail = ['Title', 'Title-1']
  statusActive = ['Active', 'InActive'];


  ngOnInit(): void {
    this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // let id = this._manager_service.getId();
    if (this.id !== null || undefined) {
      this.updateAsstNegotiator = true;
      this._getAsstNegotiator_byId(this.id)
    }
  }

  _create_asstNegotiator() {
    console.log(this.addAsstNegotiator)

    var boolValue = (/true/i).test(this.addAsstNegotiator.active)
    this.addAsstNegotiator['active'] = boolValue;
    if (this.updateAsstNegotiator) {

      console.log(this.addAsstNegotiator)
      this.addAsstNegotiator.createdBy = "SuperAdmin";
      this._manager_service._update_asstNegotiator(this.addAsstNegotiator).subscribe((data: any) => {
        // console.log("update", data)
        // { "reponse": true, "message": "Invaild Domail mail" }

        if (data.reponse == true && data.message == "Invaild Domail mail") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id")
        } else {
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/assitnegotiators-list'])
        }

      })
    }
    else {
      console.log(this.addAsstNegotiator)
      this.addAsstNegotiator.createdBy = "SuperAdmin";
      this._manager_service._add_asstNegotiator(this.addAsstNegotiator).subscribe((data: any) => {
        this.message = data;

        if (data.reponse == true && data.message == "Invaild Domail mail") {
          this.toast.showError("Kindly Enter Valid Domain Mail Id")
        } else {
          this.toast.showSuccess(data.message)
          this.route.navigate(['/superadmin/assitnegotiators-list'])
        }

      })
    }
  }

  _getAsstNegotiator_byId(id: number) {
    this._manager_service._get_asstNegotiatorByid(id).subscribe((data: any) => {
      console.log(data)
      this.addAsstNegotiator = data['getData'];
    })


  }


}
