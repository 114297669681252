import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { FormBuilder, FormGroup, FormArray, Validators, } from '@angular/forms';
import { ManagerService } from 'src/app/service/manager.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cancel-file-report',
  templateUrl: './cancel-file-report.component.html',
  styleUrls: ['./cancel-file-report.component.css']
})
export class CancelFileReportComponent implements OnInit {
  public reportId: any = 0;
  p: number = 1;
  public cancelReportData: any = [];
  public cancelfileReportData: any = []
  public CancelFileId: any;
  public reportName: any = 'cancel-file-report';


  date: any;
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true,
    max: moment(new Date()),
  };
   reportObj:any = {
    "clientId": 0,
    "fromDate": '',
    "toDate": '',
    "createdBy": this.tokenStorage._get_userTypeId()
  }
  cancelFileReport = '';
  fromDate = '';
  toDate = '';
  clientId = '0';



  order: string = 'managersFirstname';
  reverse: boolean = false;
  shortsaleDData: any;
  shortsaleDataBack: any;
  searchcancelReportData: any;
  constructor(private _file_upload: FileuploadService, private tokenStorage: TokenService, private toastr: ToastService, private formBuilder: FormBuilder, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) {
    for (let i = 1; i <= 100; i++) {
      this.cancelReportData.push(`item ${i}`);
    }
  }

  ngOnInit(): void {
    this.cancelfileReportgetAllReport();
    this.cancelfileReportDropdown();

    // this.cancelReportData = [
    //   { "managersid": '01', "managersFirstname": 'Georges', "managersLastname": 'Bailey', "managersEmail": 'samplemailid123@email.com', "managerPhone": '+1 9876 54 3210', "managersTilename": 'Title Name 0120', "managersStatus": 'Active' },
    //   { "managersid": '02', "managersFirstname": 'deorge', "managersLastname": 'Bailey', "managersEmail": 'samplemailid123@email.com', "managerPhone": '+1 9876 54 3210', "managersTilename": 'Title Name 0120', "managersStatus": 'Active' },
    //   { "managersid": '03', "managersFirstname": 'deorge', "managersLastname": 'Bailey', "managersEmail": 'samplemailid123@email.com', "managerPhone": '+1 9876 54 3210', "managersTilename": 'Title Name 0120', "managersStatus": 'Active' },
    //   { "managersid": '04', "managersFirstname": 'deorge', "managersLastname": 'Bailey', "managersEmail": 'samplemailid123@email.com', "managerPhone": '+1 9876 54 3210', "managersTilename": 'Title Name 0120', "managersStatus": 'Active' },
    //   { "managersid": '05', "managersFirstname": 'George', "managersLastname": 'Bailey', "managersEmail": 'samplemailid123@email.com', "managerPhone": '+1 9876 54 3210', "managersTilename": 'Title Name 0120', "managersStatus": 'Active' },
    //   { "managersid": '06', "managersFirstname": 'George', "managersLastname": 'Bailey', "managersEmail": 'samplemailid123@email.com', "managerPhone": '+1 9876 54 3210', "managersTilename": 'Title Name 0120', "managersStatus": 'Active' },
    //   { "managersid": '07', "managersFirstname": 'George', "managersLastname": 'Bailey', "managersEmail": 'samplemailid123@email.com', "managerPhone": '+1 9876 54 3210', "managersTilename": 'Title Name 0120', "managersStatus": 'Active' },
    //   { "managersid": '08', "managersFirstname": 'George', "managersLastname": 'Bailey', "managersEmail": 'samplemailid123@email.com', "managerPhone": '+1 9876 54 3210', "managersTilename": 'Title Name 0120', "managersStatus": 'Active' }
    // ];
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }
    this.order = value;

  }

  cancelfileReportDropdown() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "currentuserid");
    this._manager_service.shortsale_dropdown(CurrentUserId).subscribe((response: any) => {
      this.shortsaleDData = response.listData;
      this.shortsaleDataBack = response.listData;
      console.log(this.shortsaleDData, "sssssaaaaa")
    })
  }

  // OncancelFileDropdown(cancelfileVal: any) {
  //   this.CancelFileId = parseInt(cancelfileVal.target.value);
  //   this.reportObj.clientId = this.CancelFileId;
  //   console.log(this.CancelFileId, "CancelFileId");
  //   this.cancelfileReportgetAllReport();

  // }

  // updateCalcs(event: any) {
  //   console.log(event.target.value, "dddd");
  //   this.FromDate = event.target.value;
  // }

  filterData() {
    //this.CancelFileId = parseInt(filterVal.target.value);
    // this.reportObj.clientId = parseInt(this.clientId);
this.reportObj={
  "clientId":parseInt(this.clientId),
  'fromDate':this.fromDate?this.fromDate:"",
  'toDate':this.toDate?this.toDate:""
}

    // this.reportObj.fromDate = this.fromDate;
    // this.reportObj.toDate = this.toDate;
    console.log(this.reportObj, "list");
    this.cancelfileReportgetAllReport();

  }
  ResetData() {
    // //this.CancelFileId = parseInt(filterVal.target.value);
    this.reportObj.clientId = 0;
    this.reportObj.fromDate = '';
    this.reportObj.toDate = '';
    this.fromDate = '';
    this.toDate = '';
    this.clientId = '0'
    console.log(this.reportObj, "list");
    this.cancelfileReportgetAllReport();

  }

  cancelfileReportgetAllReport() {
    // let reportObj = {
    //   "clientId": this.CancelFileId,
    //   "createdBy": this.tokenStorage._get_userTypeId()
    // }

    this._manager_service.cancelfileReport_getAllReport(this.reportObj).subscribe((res: any) => {
      this.cancelReportData = res.listData ? res.listData : [];
      this.searchcancelReportData = res.listData ? res.listData : [];
    })
  }



  downloadExcel() {
    this.reportObj={
      "clientId":parseInt(this.clientId),
      'fromDate':this.fromDate?this.fromDate:"",
      'toDate':this.toDate?this.toDate:"",
      "createdBy": this.tokenStorage._get_userTypeId(),
    }
    this._file_upload.download_report(this.reportObj);
  }

  onselectReport(assignVal: any) {
    this.reportId = assignVal.target.value;
    this.route.navigate(['/superadmin/' + this.reportId]);

  }

  cancelfilesearchReport() {
    let searchcancelObj = {
      "clientId": 0,
      "fromDate": '',
      "toDate": '',
      "createdBy": this.tokenStorage._get_userTypeId(),
      "word": this.cancelFileReport
    }

    this._manager_service.cancel_searchReport(searchcancelObj).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.cancelReportData = [];
      }
      else {
        this.cancelReportData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }
  onSearchTitle(e: any) {
    this.shortsaleDData = this.shortsaleDataBack.filter((item: any) => item.clientName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
  }
  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    self.cancelReportData = self.searchcancelReportData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

}
