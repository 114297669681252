import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { FileuploadService } from '../../service/fileupload.service';
import { DropdownService } from '../../service/dropdown.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';

import * as _ from 'lodash';
import * as $ from 'jquery';
import { DatePipe } from '@angular/common';




@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.css']
})
export class LeadListComponent implements OnInit {

  public leadData: any = [];
  public DbleadData: any = [];
  public userTypes: any;
  public userLists: any=[];
  public userListBack: any = [];
  order: string = 'leadowner';
  p: number = 1;
  reverse: boolean = false;
  checkItInfo: boolean = false;
  masterSelected: boolean;
  checkedList: any;
  getDropdownUser: any;
  getUserlist: any;
  assigntolead: any;
  myuserType: any;
  usertypedropdown: any;
  public usertype: any;
  searchKey: any
  columnsList: any;
  selectedColumn: any;
  showCross: boolean = false;
  userListOptions: any;
  homeOwnerList: any = [];
  homeOwnerListBack: any = [];
  filterRequest: any = {
    homeOwnerId: 0
  };
  statusList: any;
  myuserTypeId: any;
  mangeDropdown: any;
  assignedAgentList: any;
  Lastusertypedropdown: any;
  public showPagelist: number | undefined
  public selectNumber: number | undefined

  constructor(private _manager_service: ManagerService,
    private _file_upload: FileuploadService,
    private tokenStorage: TokenService,
    private _dropdown: DropdownService,
    private toastr: ToastService,
    private datepipe: DatePipe
  ) {
    this.masterSelected = false;
    this.getCheckedItemList();
  }
  pageListNumber(e: any) {
    this.selectNumber = e.target.value;
    this.showPagelist = this.selectNumber;
    console.log(this.selectNumber)
  }
  ngOnInit(): void {
   
    this.myuserType = this.tokenStorage._get_userRole();
    this.myuserTypeId = this.tokenStorage._userTypeId();
    this.userType_dropDown();
    this.usertypes_Dropdown();
    this.getCustomColumns();
    this.getfilter_details();
    this.getCustomColumns();
    this.getLeadlist();
    this._getallAssigned_Agent();
    this.usertype = this.tokenStorage._get_userRole();
    this.filterRequest.status = 2;
  }


  //** GET LEAD LIST **/
  getLeadlist() {
    this.showCross = false;
    this.searchKey = "";
    this._manager_service.get_All_Lead().subscribe((leadlist: any) => {
      this.leadData = leadlist.listData;
      this.DbleadData = leadlist.listData;
      console.log(this.leadData)
    })
  }


  //**END */

  userType_dropDown() {

    this._dropdown._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      console.log(this.userTypes, "ji")
    })

  }

  //**Usertype Dropdown */
  usertypes_Dropdown(id?: any) {
    // console.log("id" , id);
    debugger;
    console.log(this.getDropdownUser)
    let usertypeid = this.getDropdownUser;
    this._dropdown._get_Userlist(usertypeid).subscribe((getuserlist: any) => {
      this.userLists = getuserlist.listData;
      this.userListBack = getuserlist.listData;
      console.log(this.userLists, "dsdsdsf")
    })

  }

  manger_Dropdown(e: any) {
    this.usertypedropdown = e.target.value;
    console.log(this.usertypedropdown, 'typs')
    console.log(e, 'typsssssssssssss')
    if (this.usertypedropdown == 'Broker') {
      //   alert('1');
      this._manager_service.brokerGetall().subscribe((data: any) => {
        console.log(data, 'res')
        this.Lastusertypedropdown = 3;
        this.mangeDropdown = data.listData
      })
    } else if (this.usertypedropdown == 'Agent') {
      //  alert('2');
      this._manager_service.agentGetall().subscribe((data: any) => {
        console.log(data, 'res')
        this.Lastusertypedropdown = 4;
        this.mangeDropdown = data.listData
      })
    }
  }
  getTaskCompletionStatus(data: any) {
    // console.log(data)
    let currentDate = new Date();
    data = new Date(data);
    console.log(Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(data.getFullYear(), data.getMonth(), data.getDate()) ) /(1000 * 60 * 60 * 24)))
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(data.getFullYear(), data.getMonth(), data.getDate())) / (1000 * 60 * 60 * 24));


  }



  //**END */
  // Assigned to Agent
  _getallAssigned_Agent() { 
    let sendUserId = this.myuserTypeId;
    this._manager_service._assigned_Agent(sendUserId).subscribe((resp: any) => {
      this.assignedAgentList = resp.listData;

      console.log(this.assignedAgentList.length, 'assigned_agent')
      console.log(sendUserId, 'assigned_agentIDDDDd')
    })
  }

  //** leadAssign to User */
  assign_lead() {

    debugger
    let assignlead = [];
    console.log(this.checkedList)
    console.log(this.checkedList.length, 'aa');
    if (this.checkedList.length <= 0) {
      this.toastr.showError(" please select atleast one lead to assign")
    }
    
    else if(!this.getDropdownUser && !this.getUserlist){
      this.toastr.showError(" please select atleast one user to assign")
    }
    else {


      console.log(this.checkedList, this.getUserlist, this.getDropdownUser, "assifn by user")
      if (this.myuserType != 'Manager') {
        for (var i = 0; i < this.checkedList.length; i++) {
          assignlead.push({
            'leadId': this.checkedList[i].leadId,
            'createdBy': sessionStorage.getItem('userTypeId'),
            'userId': this.getUserlist,
            'userTypeId': this.getDropdownUser
          })
        }

        console.log(assignlead, "newlychecking")

        this._manager_service._assign_Lead(assignlead).subscribe((data: any) => {
          if (data.reponse == true) {
            this.getLeadlist();
            this.toastr.showSuccess(data.message);
          } else {
            this.toastr.showError(data.message);
          }
        }, err => { this.toastr.showError("something went wrong") })

      } else if (this.myuserType == 'Manager') {
        for (var i = 0; i < this.checkedList.length; i++) {
          assignlead.push({
            'leadId': this.checkedList[i].leadId,
            'createdBy': sessionStorage.getItem('userTypeId')?sessionStorage.getItem('userTypeId'):0,
            'userId': this.getUserlist,
            'userTypeId':this.getDropdownUser? Number(this.getDropdownUser):0
          })
        }

        console.log(assignlead, "newlychecking")

        this._manager_service._assign_Lead(assignlead).subscribe((data: any) => {


          if (data.reponse == true) {
            this.getLeadlist();
            this.toastr.showSuccess(data.message);
          } else {
            this.toastr.showError(data.message);
          }
        }, err => { this.toastr.showError("something went wrong") })

      }

    }
  }
  //**END */

  //**SORT */
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  //**END */

  getFilterData() {
    console.log(this.filterRequest);
    this.filterRequest['homeOwnerId'] = parseInt(this.filterRequest.homeOwnerId);
    this.filterRequest['assignedUserTypeId'] = parseInt(this.filterRequest.assignedUserTypeId);
    this.filterRequest['status'] = parseInt(this.filterRequest.status);
    this._dropdown._getAll_lead_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.leadData = filteredData.listData;
      this.DbleadData = filteredData.listData;
    });
  }

  //**Get Details for Filter */
  getfilter_details() {
    // this._dropdown._getAll_lead_UserList().subscribe((userList: any) => {
    //   console.log(userList.listData, "ji");
    //   this.userListOptions = userList.listData;
    // })
    this._dropdown._getAll_lead_homeOwner().subscribe((homeowner: any) => {
      console.log(homeowner.listData, "sji")
      this.homeOwnerList = homeowner.listData;
      this.homeOwnerListBack = homeowner.listData;
    })

    this._dropdown._getAll_lead_StatusList().subscribe((statuslist: any) => {
      console.log(statuslist.listData, "sji")
      this.statusList = statuslist.listData;
    })
  }

  //**End */
  //**CheckBox in lead list */
  checkUncheckAll() {
    // alert(1)
    for (var i = 0; i < this.leadData.length; i++) { this.leadData[i].isSelected = this.masterSelected; } this.getCheckedItemList()
  }

  isAllSelected() {
    // alert(2)
    this.masterSelected = this.leadData.every(function (item: any) { return item.isSelected == true; }); this.getCheckedItemList();
  }

    getCheckedItemList() {
    // alert(3)
    console.log(this.getDropdownUser, this.getUserlist, "checkbox")
    let assignlead: any = []
    this.checkedList = [];
    for (var i = 0; i < this.leadData.length; i++) {

      if (this.leadData[i].isSelected) {
        console.log(this.leadData[i], "newdata")
        this.checkedList.push(this.leadData[i])
      }

    }
    this.checkedList = this.checkedList;
    // console.log(this.checkedList)
  }
  //**END */

  //**Bulk File Upload */
  uploadFile(fileEvent: any) { this._file_upload.uploadFile(fileEvent); this.getLeadlist(); }
  //**END */

  downloadExcel() {
    this._file_upload.download_template();
  }
  downloadExcellist() {
    this._file_upload.download_list_lead();
  }
  getCustomColumns() {
    this._manager_service._get_customColumn().subscribe((columns: any) => {

      this.columnsList = columns.listData;
      console.log(columns, "col")
    })
  }

  select(item?: any) {
    // alert("1")
    // console.log(item,"id")
    this.selectedColumn = item;
  }
  isActive(item?: any) {
    // alert("2")
    this.checkItInfo = true;
    //  console.log(this.selectedColumn,"ID in selected columns")
    //  if(this.selectedColumn)
    return this.selectedColumn === item;
  };
  addnewCol() {
    var table = $("#addinputcol");
    console.log(table, "tables")
    table.append(table.find("input:eq(0)").clone());
  }

  showCrossicon() {
    this.showCross = true;
  }
  leadSearch(searchkey: any) {
    console.log(this.searchKey)
    this._dropdown.search_lead(searchkey).subscribe((searchData: any) => {
      this.leadData = searchData.listData;
      console.log(searchData.listData)
    })
  }

  onSearchOwner(e: any) {
    this.homeOwnerList = this.homeOwnerListBack.filter((item: any) => item.ownerName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
    console.log("e.target.value ", e.target.value);
  }

  onSearchUsers(e: any) {
    this.userLists = this.userListBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    console.log(self.leadData)
    self.leadData = self.DbleadData.filter((a: any) => Object.keys(a).some((k: any) => {
       console.log(a ,k )
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }


}
