import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
@Component({
  selector: 'app-manage-auto-gen',
  templateUrl: './manage-auto-gen.component.html',
  styleUrls: ['./manage-auto-gen.component.css']
})
export class ManageAutoGenComponent implements OnInit {

  public manageAuto: any = [];
  public DbmanageAuto: any = [];
  manageAutoBack: any = [];
  order: string = 'managersFirstname';
  p: number = 1;
  reverse: boolean = false;
  titleDropdown: any = [];
  titleDropdownBack: any = [];
  fileDropdown: any = [];
  fileDropdownBack: any = [];
  filterObj:any = {
    // titleId:0,
    folderId: 0
  }
  constructor(private toast: ToastService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) {
    // for (let i = 1; i <= 100; i++) {
    //   this.manageAuto.push(`item ${i}`);
    // }
  }

  ngOnInit(): void {
    this.getallautogendocdetails();
    this.getlistDropdown();
    // this.manageAuto = [
    //   { "manageAutoid": '01', "manageAutoTitle": 'Short Sale', "manageAutoFilename": 'LOA' },
    //   { "manageAutoid": '02', "manageAutoTitle": 'Short Sale', "manageAutoFilename": 'Financial Statement' },
    //   { "manageAutoid": '03', "manageAutoTitle": 'Short Sale', "manageAutoFilename": 'Bank Statement' },
    //   { "manageAutoid": '04', "manageAutoTitle": 'Short Sale', "manageAutoFilename": 'W2' },
    //   { "manageAutoid": '05', "manageAutoTitle": 'Short Sale', "manageAutoFilename": 'Tax Returns' },
    //   { "manageAutoid": '06', "manageAutoTitle": 'Short Sale', "manageAutoFilename": 'Affiliated Business Disclosure Form' },
    //   { "manageAutoid": '07', "manageAutoTitle": 'Short Sale', "manageAutoFilename": 'Short Sale Service Agreement' }

    // ];
  }





  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }


  getallautogendocdetails() {
    this._manager_service.get_allautogendocdetails().subscribe((res: any) => {
      console.log('getallautogendocdetails',res);
      this.manageAuto = res.listData? res.listData: [];
      this.DbmanageAuto = res.listData? res.listData: [];
      this.manageAutoBack = res.listData? res.listData: [];
      this.fileDropdown = res.listData ? res.listData: [];
      this.fileDropdownBack = res.listData ? res.listData: [];
    });
  }

  DeleteAutogendown(index: any, fileobj: any) {
    console.log(fileobj)
    this._manager_service.delete_autogendoc(fileobj).subscribe(data => {
      // alert("deleted")
    })
  }


  Deletevent(data: any) {
    this._manager_service.delete_autogendoc(data).subscribe(res => {
      console.log(res);
      this.getallautogendocdetails();
      this.toast.showSuccess('Data Deleted Successfully');
    })
  }

  getlistDropdown() {
    var self = this;
    self._manager_service.getautogen_dropdown().subscribe((res: any) => {
      self.titleDropdown = res.listData ? res.listData: [];
      self.titleDropdownBack = res.listData ? res.listData: [];
      self.fileDropdown = res.listData ? res.listData: [];
      self.fileDropdownBack = res.listData ? res.listData: [];
      console.log("res getlistDropdown", self.titleDropdown);
    })
  }

  filterAutoGen() {
    var self = this;
    self._manager_service.filter_autogen(self.filterObj).subscribe((res:any)=> {
      console.log("res filterAutoGen", res);
      self.manageAuto = res.listData? res.listData: [];
      self.DbmanageAuto = res.listData? res.listData: [];
    })
  }

  manageReset() {
    var self = this;
    self.filterObj = {
      // titleId:0,
      folderId: 0
    }
    self.filterAutoGen();
  }

  onSearchTitle(e:any) {
    this.titleDropdown = this.titleDropdownBack.filter((item: any) => item.titleName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
  }

  onSearchFile(e:any) {
    this.fileDropdown = this.fileDropdownBack.filter((item: any) => item.folderName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
  }

  filterTaskList() {
    var self = this;
    !self.filterObj.folderId? delete self.filterObj.folderId: '';
    // !self.filterObj.titleId? delete self.filterObj.titleId: '';
    console.log("self.filterObj ", self.filterObj);
    self.manageAuto = self.manageAutoBack.filter((x: any) => {
      for (let key in self.filterObj) {
        if (x[key] === undefined || x[key] != self.filterObj[key])
          return false;
      }
      return true;
    });
    console.log("self.clientTask ", self.manageAuto);
  }

  resetFilter() {
    var self = this;
    self.filterObj = {}
    self.manageAuto = self.manageAutoBack
  }

  onSearch(e: any){
    console.log("value ", e);
    var self = this;
    self.manageAuto = self.DbmanageAuto.filter((a:any)=> Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
          return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
        
      }
      else{
        return false;
      }
    }));
  }

}
