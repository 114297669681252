import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ReactiveFormConfig } from '@rxweb/reactive-form-validators';
import { BsModalRef, BsModalService, ModalDirective, ModalModule } from 'ngx-bootstrap/modal';
import { AuthServiceService } from './service/auth-service.service';

//declare var $: any;
// var that = this;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  title = 'Apex';
  role: any = 'agent;';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null || undefined;
  title1 = 'angular-idle-timeout';

  public modalRef: BsModalRef;
  currentlogindata: any;
  currentuseruniqueId: any;
constructor(private idle:Idle, private keepalive:Keepalive,
  private router:Router, public ngbModal:NgbModal,
  private authService:AuthServiceService,
  
  public modalService:BsModalService ){
    
 // sets an idle timeout of 5 seconds, for testing purposes.
 idle.setIdle(600);
 // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
 idle.setTimeout(5);
 // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
 idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

 idle.onIdleEnd.subscribe(() => { 
   this.idleState = 'No longer idle.'
   console.log(this.idleState);
   this.reset();
 });
 
 idle.onTimeout.subscribe(() => {
   this.childModal.hide();
   this.idleState = 'Timed out!';
   this.timedOut = true;
   console.log(this.idleState);
   this.router.navigate(['/login']);
   sessionStorage.clear();
 });
 
 idle.onIdleStart.subscribe(() => {
     this.idleState = 'You\'ve gone idle!'
     console.log(this.idleState);
     this.childModal.show();
 });
 
 idle.onTimeoutWarning.subscribe((countdown:any) => {
   this.idleState = 'You will time out in ' + countdown + ' seconds!'
   console.log(this.idleState);
 });

 // sets the ping interval to 15 seconds
 keepalive.interval(15);

 keepalive.onPing.subscribe(() => this.lastPing = new Date());

 this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
   if (userLoggedIn) {
     idle.watch()
     this.timedOut = false;
   } else {
     idle.stop();
   }
 })

 // this.reset();
}

   ngOnInit() {
    // this.openModalWithClass(this.template)
    ReactiveFormConfig.set({ "validationMessage": {} });

  }
  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }
  logout() {
    this.childModal.hide();
    this.authService.setUserLoggedIn(false);
  var   currentdata:any = sessionStorage.getItem('userInfo')
    this.currentlogindata = JSON.parse(currentdata)
    this.currentuseruniqueId = this.currentlogindata.UserGUID;
    // this.childModal.hide();
    console.log("Logout clicked")
    
    this.authService.logout(this.currentuseruniqueId)
    this.router.navigate(['/login']);
  }



}
 