import { Component, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// @Injectable({
//   providedIn: 'root'
// })

/// for all module it will required this purpose of making very widely use in case

@Component({
  selector: 'app-confirmation-dialog',
  template: `
<div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
  
    </div>
    <div class="modal-body">
    Do you want to save the changes made to this page?
    </div>
    <div class="modal-footer">
      <button type="button" title="Stay this page" class="btn btn-danger" (click)="onClickCancel()"> Cancel</button>
      <button type="button" title="Leave the page without saving " class="btn btn-primary" (click)="onClickConfirm()">Don't save</button>
      <!-- <button type="button" title="Save & Leave " class="btn btn-success">Save</button> -->
    </div>


  `,
 
})
export class ConfirmationComponent  {
  data: any;

comp:any ="app-manager-list-edit"

  constructor(
  private  dialogRef: MatDialogRef<ConfirmationComponent, boolean>,
  ) {
    
  }

  onClickCancel() {

    this.dialogRef.close(false);
  }



  onClickConfirm() {
    this.dialogRef.close(true);
  }
  save(){

  } 

}
