<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Login History"></app-header>
    <div class="container-fluid contentpage_wrapper">

      <div class="apexforms-group lead-group prev-lead-group  mb_0">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="apexinput-froms mb_0">
              <h3>{{userName}}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap newheight-apx">

          <div class="apexforms-group lead-group prev-lead-group">
            <div class="apx_clients_wrapper">
              <div class="apx_clients_title">
                <h2>List Of History</h2>
                <button class="btn_to_btitle"><a routerLink="/superadmin/login-history">back</a></button>
                <button class="btn_to_btitle" (click)="downloadExcelByUserId()">Download</button>
              </div>
            </div>


            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 login_history_view">
              <div class="row custom_borders login_details_comp">
                <h3>Filter By:</h3>
                <div class="data_login_stepdude">
                  <div class="apexinput-froms">
                    <select class="form-control" [(ngModel)]="filterObj.days">
                      <option [ngValue]=0>--All--</option>
                      <option [ngValue]=1>30</option>
                      <option [ngValue]=2>90</option>
                      <option [ngValue]=3>180</option>
                      <option [ngValue]=4>240</option>
                      <option [ngValue]=5>Date Range</option>
                    </select>
                  </div>
                </div>
                <div class="date_logindetails_view">
                  <div class="apexinput-froms " *ngIf="filterObj.days == 5">
                    <label class="report_date">From Date</label>
                    <dp-date-picker theme="dp-material" [(ngModel)]="filterObj.fromdate" [config]="datePickerConfig"
                      required="true">
                    </dp-date-picker>
                  </div>
                  <div class="apexinput-froms " *ngIf="filterObj.days == 5">
                    <label class="report_date">To date</label>
                    <dp-date-picker theme="dp-material" [(ngModel)]="filterObj.todate" [config]="datePickerConfig"
                      required="true">
                    </dp-date-picker>
                  </div>
                </div>

              </div>
              <div class="filter_setting">
                <ul>
                  <!-- <li data-toggle="modal" data-target="#filterby" class="filter_apx"><img
                    src="../../../assets/images/filter.png" alt="filter"></li> -->
                  <li (click)="getdetailsByid()" class="filter_apx"><img src="../../../assets/images/filter.png"
                      alt="filter"></li>
                  <li (click)="onReset()" class="setting_reset"><img src="../../../assets/images/setting_reset.png"
                      alt="filter"></li>
                </ul>
              </div>
            </div>
            <div class="login_detrails">
              <div class="sidebar-search">
                <div>
                  <div class="input-group">
                    <div class="input-group-append">
                    </div>
                    <input type="search" class="form-control search-menu" placeholder="Search..."
                      (input)="searchLoginHistoryview(searchkey.value)" #searchkey>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div class="appointed_scheduler">
            <!-- <div class="apexforms-group col-lg-12" *ngIf="detailst !== 'null'"> -->
            <div class="apexforms-group col-lg-12" *ngFor="let detailst of detailData">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                  <div class="apexinput-froms lable_customwt">
                    <label>Task Updated Date & Time</label>
                    <span class="value_label">{{detailst.date}}</span>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                  <div class="apexinput-froms lable_customwt">
                    <label>Last Updated Task</label>
                    <span class="value_label">{{detailst.information}}</span>
                  </div>
                </div>
              </div>

            </div>
            <div class="nodata_found_components no_data_found" *ngIf="detailData.length == 0">
              <div>No Data found</div>
            </div>
          </div>





        </div>
      </div>
    </div>
  </main>
</div>
