<header class="login_header">
  <div class="container-fluid">
    <div class="row">

      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 logtext_wrap">
        <div class="logtext">
          <img id="menuburger" src="../../../../assets/images/mneubar.png" alt="icons">
          <h2>{{headerMainTitle}} </h2>
          <!-- - {{today | date:'dd-MMMM-yyyy hh:mm a Z'}} ---{{timedifference}} -->
        </div>
      </div>

      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 head_rgt_wrap">

        <div class="head_rgt dropdown" id="notification_profile">
          <!-- <div class="msg_notify">
            <i class="fas fa-envelope" (click)="bellMsgButton()"></i>
            <div class="notifi_wrapper" *ngIf="messageNoticationshow">
              <div class="notification_list">
                <div class="close_btn" (click)="closebutton()">
                  <img src="../../../../assets/images/close_btn.png">
                </div>
                <h2>Message Notifications</h2>
                <ul class="notification_wrap">
                  <li *ngFor="let item of MessageData" (click)="notifyreaded(item.notifydetailsId)">
                    <div class="notification_details">
                      <i class="fa fa-bell"
                        [ngClass]="item.isReaded == false? 'bg-active-color' : 'bg-inactive-color'"></i>
                      <h1 [ngClass]="item.isReaded == false? 'activebold' : 'activeunbold'">{{item.senduserName}}</h1>
                      <span>{{item.sendDate}}</span>
                    </div>
                    <div class="msg-bdy-g">
                      <p>{{item.messageBody}}</p>
                      <button _ngcontent-ccm-c65="" (click)="reply()" data-toggle="modal" data-target="#replypopup"
                        class="btn-create-manage reply-btn">Reply</button>
                    </div>

                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="">

              <div class="user-info">
                <img [src]=profilepicUrl />
                <div class="content-user-tlt">
                  <span class="user-name"> <strong>{{profileName}}</strong> <img src="../../../assets/images/arw.png" />

                  </span>
                  <span class="user-role">{{usertype}}</span>
                </div>

              </div>
            </div>

            <div class="newhead_room dropdown-menu" aria-labelledby="dropdownMenuButton">

              <ul>
                <li class="room_profiles">
                  <a class="dropdown-item" routerLink="/superadmin/manager-profile">
                    <div class="head_profiles">
                      <div class="head_profiles_img">
                        <img [src]=profilepicUrl />
                      </div>
                      <div class="head_profiles_content">
                        <h3>{{profileName}}</h3>
                        <span>View Profile Information</span>
                      </div>

                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/superadmin/manager-profile">Change Password</a>
                </li>
                <li>
                  <a class="dropdown-item"   (click)="logout()">Logout <i class='fas fa-sign-out-alt'></i></a>
                </li>

              </ul>
            </div>
          </button>
        </div>


        <div class="bell-notificaton" [ngClass]="helpnotify == true? 'new_notification_active' : ''">
          <i class="fa fa-bell" (click)="bellButton()"></i>
          <span class="badge badge-pill badge-warning notification">{{notificationCount}}</span>
          <div class="notifi_wrapper" *ngIf="noficationshow">
            <div class="notification_list">
              <div class="close_btn" (click)="closeButton()"><img src="../../../../assets/images/close_btn.png"></div>
              <h2>Notifications<span (click)="clearallNotifi()">Clear All</span></h2>
              <ul class="notification_wrap">
                <li *ngFor="let item of notificationList" (click)="notifyreaded(item.notifydetailsId)">
                  <div class="notification_details">
                    <i class="fa fa-bell"
                      [ngClass]="item.isReaded == false? 'bg-active-color' : 'bg-inactive-color'"></i>
                    <h1 [ngClass]="item.isReaded == false? 'activebold' : 'activeunbold'">{{item.sendBy}} </h1>
                    <span>{{item.createdDate}}</span>
                  </div>
                  <p>{{item.message}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div class="noticontact_us" *ngIf="usertype == 'Broker'">
          <div class="bell-notificaton contact_notifi">
            <a routerLink="/superadmin/contact-us">
              <i class="fa fa-phone" aria-hidden="true"></i></a>
          </div>
        </div>

        <!-- <button class="btn-create-manage notes-btn" data-toggle="modal" data-target="#notespopup">Notes</button> -->
        <button *ngIf="usertype!='HomeOwner'" data-toggle="modal" (click)="resetNotes()" data-target="#notespopupNew" class="btn_to_notes notes-btn-g"><svg
            xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
              d="M12.3906 7.73438C12.3906 7.34604 12.0758 7.03125 11.6875 7.03125H4.44531C4.05698 7.03125 3.74219 7.34604 3.74219 7.73438C3.74219 8.12271 4.05698 8.4375 4.44531 8.4375H11.6875C12.0758 8.4375 12.3906 8.12271 12.3906 7.73438Z"
              fill="white" />
            <path
              d="M4.44531 9.84375C4.05698 9.84375 3.74219 10.1585 3.74219 10.5469C3.74219 10.9352 4.05698 11.25 4.44531 11.25H8.84374C9.23208 11.25 9.54687 10.9352 9.54687 10.5469C9.54687 10.1585 9.23208 9.84375 8.84374 9.84375H4.44531Z"
              fill="white" />
            <path
              d="M6.09766 16.5938H3.74609C2.97069 16.5938 2.33984 15.9629 2.33984 15.1875V2.8125C2.33984 2.03709 2.97069 1.40625 3.74609 1.40625H12.3906C13.166 1.40625 13.7969 2.03709 13.7969 2.8125V7.13672C13.7969 7.52505 14.1117 7.83984 14.5 7.83984C14.8883 7.83984 15.2031 7.52505 15.2031 7.13672V2.8125C15.2031 1.26169 13.9414 0 12.3906 0H3.74609C2.19528 0 0.933594 1.26169 0.933594 2.8125V15.1875C0.933594 16.7383 2.19528 18 3.74609 18H6.09766C6.486 18 6.80079 17.6852 6.80079 17.2969C6.80079 16.9085 6.486 16.5938 6.09766 16.5938Z"
              fill="white" />
            <path
              d="M16.4486 10.1802C15.6262 9.3578 14.288 9.35776 13.4661 10.1797L9.60579 14.0314C9.52381 14.1132 9.46334 14.214 9.42966 14.3248L8.58897 17.0924C8.51444 17.3378 8.57937 17.6043 8.75846 17.7878C8.89261 17.9253 9.07486 18 9.26179 18C9.32433 18 9.38747 17.9916 9.44945 17.9744L12.2874 17.1883C12.4042 17.1559 12.5107 17.094 12.5965 17.0083L16.4486 13.1634C17.2711 12.3409 17.2711 11.0028 16.4486 10.1802ZM11.7339 15.8824L10.3061 16.2779L10.7239 14.9023L13.3286 12.3034L14.3232 13.298L11.7339 15.8824ZM15.4548 12.1686L15.3185 12.3046L14.3241 11.3102L14.4599 11.1747C14.7341 10.9005 15.1801 10.9005 15.4543 11.1747C15.7284 11.4488 15.7284 11.8949 15.4548 12.1686Z"
              fill="white" />
            <path
              d="M11.6875 4.21875H4.44531C4.05698 4.21875 3.74219 4.53354 3.74219 4.92188C3.74219 5.31021 4.05698 5.625 4.44531 5.625H11.6875C12.0758 5.625 12.3906 5.31021 12.3906 4.92188C12.3906 4.53354 12.0758 4.21875 11.6875 4.21875Z"
              fill="white" />
          </svg> Notes <span class="notes-val"  *ngIf="notesList.length>0">{{notesList.length}}</span></button>
      </div>

    </div>
  </div>
</header>
<!-- The Modal 1 -->

<!-- Notification Details -->
<!-- <div class="notification_details" id="impnotify">
  <div class="modal" id="notificatonpopup">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Notification Alert</h4>
            <div class="filterbt_btnn">

            </div>
          </div>
          <div class="modal-body">
            <div class="filterforms_setting">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="apxgroup-filter">
                    <label>Notification Send Successfully</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
  </div>
      </div>
    </div>
  </div> -->
<!-- Notification Details -->


<!-- The customise column Modal -->
<div class="modal" id="notespopup">
  <div class="modal-dialog modal-dialog-centered  modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <div class="cust-modal-head">
          <h4 class="modal-title">Notes</h4>

          <button type="button" class="Cls-btn-g" data-dismiss="modal">
            <img src="../../../assets/images/clients/pop-close.png" alt="close">
          </button>
        </div>
      </div>
      <!-- Modal body -->
      <div class="modal-body chat-modal-body">
        <div class="require-modal-inner">
          <form class="form" [formGroup]="notesForm">
            <div class="chat-head">
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" class="form-group">
                    <div class="apexinput-froms block_labells">
                      <label>Select File </label>
                      <select name="text" id="clientId" formControlName='clientId' class="form-control" (change)="selectClientFile($event)">
                        <option value="">Select a File</option>
                        <option value="{{filedata.clientId}}" *ngFor="let filedata of selectFileData">
                          {{filedata.fileno}}</option>
                        <!-- <option value="manager">CABOA20001 (Johnny Depp)</option>
                      <option value="Title-1">CABOA20001 (Robert De Niro)</option>
                      <option value="Title-1">CABOA20001 (Kevin Spacey)</option> -->
                      </select>
                      <div *ngIf="submitted && !notesForm.controls['clientId'].valid">
                        <span class="text-danger" *ngIf="notesForm.controls['clientId'].hasError('required') ">
                          Select File is required.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="chat-head" >
              <span>Notes</span>
              <input type="text" id="messageBody" formControlName='messageBody' class="form-control"
                class="send-note-txt" value="Lorem ipsum is a dummy text content in a pragraph...">
              <div *ngIf="submitted && !notesForm.controls['messageBody'].valid">
                <span class="text-danger" *ngIf="notesForm.controls['messageBody'].hasError('required') ">
                  Filed is required
                </span>
              </div>
            </div>
            <div class="chat-head">
              <span>Notify</span>
              <div class="apexinput-froms block_labells">
                <div class="checkedpan_grp">
                  <span class="checkbox checkbox_row">
                    <div class="form-group check-form">
                      <div *ngFor="let usertypeData of SelectuserTypeData">
                        <input type="checkbox" id="{{usertypeData.userTypeId}}" formControlName="toUserType"
                          value="{{usertypeData.userTypeId}}" (change)="selectUserType($event,usertypeData)" />
                        <label for="{{usertypeData.userTypeId}}"></label>
                        <small style="text-transform: capitalize;">{{usertypeData.userTypeName}}</small>
                      </div>
                      <!-- <div *ngIf="submitted && !notesForm.controls['toUserType'].valid">
                        <span class="text-danger" *ngIf="notesForm.controls['toUserType'].hasError('required') ">
                          Please select any users
                        </span>
                      </div> -->

                      <!-- <input type="checkbox" formControlName="toUserType" *ngFor="let usertypeData of SelectuserTypeData.controls; let i = index" id="html1"  value="{{usertypeData.userTypeId}}" >
                      {{usertypeData.userTypeName}} -->


                    </div>
                  </span>
                  <!-- <span class="checkbox">
                    <div class="form-group check-form">
                      <input type="checkbox" id="html1" checked>
                      <label for="html1"></label>
                      <small>Agent</small>
                    </div>
                  </span> -->
                  <!-- <span class="checkbox">
                    <div class="form-group check-form">
                      <input type="checkbox" id="html1">
                      <label for="html1"></label>
                      <small>HomeOwner</small>
                    </div>
                  </span> -->
                </div>
              </div>
              <button class="send-notes-btn" (click)="onSubmit()">Send Notes</button>
            </div>
          </form>
          <div class="chat-body">
            <div>
              <button *ngFor="let item of tabName" class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == item ? '#085589': '', 'color': activeTitle == item ? '#FFFFFF': ''}" (click)="activeTitle = item">{{item}}</button>
              <!-- <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'agent' ? '#085589': '', 'color': activeTitle == 'agent' ? '#FFFFFF': ''}" (click)="activeTitle = 'agent'">AGENT</button>
              <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'manager' ? '#085589': '', 'color': activeTitle == 'manager' ? '#FFFFFF': ''}" (click)="activeTitle = 'manager'">MANAGER</button>
              <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'negotiator' ? '#085589': '', 'color': activeTitle == 'negotiator' ? '#FFFFFF': ''}" (click)="activeTitle = 'negotiator'">NEGOTIATOR</button>
              <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'assnegotiator' ? '#085589': '', 'color': activeTitle == 'assnegotiator' ? '#FFFFFF': ''}" (click)="activeTitle = 'assnegotiator'">ASSTNEGOTIATOR</button>
              <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'closer' ? '#085589': '', 'color': activeTitle == 'closer' ? '#FFFFFF': ''}" (click)="activeTitle = 'closer'">CLOSER</button> -->
            </div>
            <div class="chat-list" *ngFor="let history of notesHistory;let i = index">
              <!-- <img src="./assets/images/dp-1.png"> -->
              <!-- <p>{{history.broker | json}}</p> -->
            <div *ngIf="history.broker && activeTitle == 'broker'">
              <!-- <span class="msg_title">BROKER</span> -->
              <div class="chat-msg" *ngFor="let brokerhist of history.broker.conversions; let j = index">
                <span class="profile-pic-text">{{ getShortName(brokerhist.senduserName)}}</span>
                <p class="chat-msg-txt">{{brokerhist.messageBody}}
                </p>
                <!-- <button *ngIf="j == 0" _ngcontent-ccm-c65="" (click)="Notesreply(brokerhist);" data-toggle="modal" data-target="#replypopup"
                        class="btn-create-manage reply-btn">Reply</button> -->
                <p class="chat-user-name">{{brokerhist.senduserName}}
                  <span class="chat-msg-time">{{brokerhist.sendDate}}</span>
                </p>
              </div>
            </div>
            <div *ngIf="history.agent && activeTitle == 'agent'">
              <!-- <span class="msg_title">AGENT</span> -->
              <div class="chat-msg" *ngFor="let brokerhist of history.agent.conversions; let j = index">
                <span class="profile-pic-text">{{ getShortName(brokerhist.senduserName)}}</span>
                <p class="chat-msg-txt">{{brokerhist.messageBody}}
                </p>
                <!-- <button *ngIf="j == 0" _ngcontent-ccm-c65="" (click)="Notesreply(brokerhist);" data-toggle="modal" data-target="#replypopup"
                        class="btn-create-manage reply-btn">Reply</button> -->
                <p class="chat-user-name">{{brokerhist.senduserName}}
                  <span class="chat-msg-time">{{brokerhist.sendDate}}</span>
                </p>
              </div>
            </div>
            <div *ngIf="history.manager && activeTitle == 'manager'">
              <!-- <span class="msg_title">MANAGER</span> -->
              <div class="chat-msg" *ngFor="let brokerhist of history.manager.conversions; let j = index">
                <span class="profile-pic-text">{{ getShortName(brokerhist.senduserName)}}</span>
                <p class="chat-msg-txt">{{brokerhist.messageBody}}
                </p>
                <!-- <button *ngIf="j == 0" _ngcontent-ccm-c65="" (click)="Notesreply(brokerhist);" data-toggle="modal" data-target="#replypopup"
                        class="btn-create-manage reply-btn">Reply</button> -->
                <p class="chat-user-name">{{brokerhist.senduserName}}
                  <span class="chat-msg-time">{{brokerhist.sendDate}}</span>
                </p>
              </div>
            </div>
            <div *ngIf="history.negotiator && activeTitle == 'negotiator'">
              <!-- <span class="msg_title">NEGOTIATOR</span> -->
              <div class="chat-msg" *ngFor="let brokerhist of history.negotiator.conversions; let j = index">
                <span class="profile-pic-text">{{ getShortName(brokerhist.senduserName)}}</span>
                <p class="chat-msg-txt">{{brokerhist.messageBody}}
                </p>
                <!-- <button *ngIf="j == 0" _ngcontent-ccm-c65="" (click)="Notesreply(brokerhist);" data-toggle="modal" data-target="#replypopup"
                        class="btn-create-manage reply-btn">Reply</button> -->
                <p class="chat-user-name">{{brokerhist.senduserName}}
                  <span class="chat-msg-time">{{brokerhist.sendDate}}</span>
                </p>
              </div>
            </div>
            <div *ngIf="history.asstnegotiator && activeTitle == 'asstnegotiator'">
              <!-- <span class="msg_title">ASSTNEGOTIATOR</span> -->

              <div class="chat-msg" *ngFor="let brokerhist of history.asstnegotiator.conversions; let j = index">
                <span class="profile-pic-text">{{ getShortName(brokerhist.senduserName)}}</span>
                <p class="chat-msg-txt">{{brokerhist.messageBody}}
                </p>
                <!-- <button *ngIf="j == 0" _ngcontent-ccm-c65="" (click)="Notesreply(brokerhist);" data-toggle="modal" data-target="#replypopup"
                        class="btn-create-manage reply-btn">Reply</button> -->
                <p class="chat-user-name">{{brokerhist.senduserName}}
                  <span class="chat-msg-time">{{brokerhist.sendDate}}</span>
                </p>
              </div>
            </div>

            <div *ngIf="history.closer && activeTitle == 'closer'">
              <span class="msg_title">CLOSER</span>

              <div class="chat-msg" *ngFor="let brokerhist of history.closer?.conversions; let j = index">
                <span class="profile-pic-text">{{ getShortName(brokerhist.senduserName)}}</span>
                <p class="chat-msg-txt">{{brokerhist.messageBody}}
                </p>
                <!-- <button *ngIf="j == 0" _ngcontent-ccm-c65="" (click)="Notesreply(brokerhist);" data-toggle="modal" data-target="#replypopup"
                        class="btn-create-manage reply-btn">Reply</button> -->
                <p class="chat-user-name">{{brokerhist.senduserName}}
                  <span class="chat-msg-time">{{brokerhist.sendDate}}</span>
                </p>
              </div>
            </div>

            </div>


            <!-- <div class="chat-list">
              <img src="./assets/images/dp-2.png">
              <div class="chat-msg">
                <p class="chat-msg-txt">At vero eos et accusamus et iusto odio dignissimos duci</p>
                <p class="chat-user-name">Asst. Negotiator (Chris Hemsworth)
                  <span class="chat-msg-time">Today 03:24 PM
                  </span>
                </p>
              </div>
            </div>


            <div class="chat-list">
              <img src="./assets/images/dp-3.png">
              <div class="chat-msg">
                <p class="chat-msg-txt">It is a long established fact that a reader</p>
                <p class="chat-user-name">Negotiator (Steve Jobs)
                  <span class="chat-msg-time">Today 03:24 PM</span>
                </p>
              </div>
            </div>

            <div class="chat-list">
              <img src="./assets/images/dp-4.png">
              <div class="chat-msg">
                <p class="chat-msg-txt our-msg">Lorem ipsum is a dummy text content in a paragraph...</p>
                <p class="chat-user-name">You
                  <span class="chat-msg-time">Today 03:24 PM</span>
                </p>
              </div>
            </div> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- end  customise column Modal -->



<!-- New Notes PopUp -->

<div class="modal" id="notespopupNew">
  <div class="modal-dialog modal-dialog-centered  modal-lg">
    <div class="modal-content">

<!-- header -->
      <div class="modal-header">
        <div class="cust-modal-head">
          <h4 class="modal-title">Notes</h4>

          <button type="button" class="Cls-btn-g" data-dismiss="modal">
            <img src="../../../assets/images/clients/pop-close.png" alt="close">
          </button>
        </div>
      </div>

  <!-- Body -->
      <div class="modal-body chat-modal-body notes-popup">
        <div class="require-modal-inner">
          <form class="form" [formGroup]="notesForm">
            <div class="chat-head">
              <div class="apexforms-group">
                <div class="row">

  
                 <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" class="form-group">
                  
                  <label>Select File </label> 
                <ng-select 
                  bindLabel="fileno"
                  placeholder="Select file"
                  appendTo="body"
                  [searchable]="true" 
                  [clearable]="true" formControlName='clientId'  (change)="selectClientFile($event)"
                >
                <ng-option  *ngFor="let filedata of selectFileData" [value]="filedata.clientId" class="bg-dark">
                 <p [ngClass]="check(filedata.clientId)"> {{filedata.fileno}}</p></ng-option>
       </ng-select>
                  <!-- <div class="apexinput-froms block_labells">
                      <label>Select File</label>
                      <select name="text" id="clientId" formControlName='clientId' class="form-control"   (change)="selectClientFile($event)">
                        <option value="">Select a File</option>
                      <ng-container  *ngFor="let filedata of selectFileData">
                     
      
                        <option   [value]="filedata.clientId" [ngClass]="check(filedata.clientId)">
                          {{filedata.fileno}}</option>
                      </ng-container>  
                   <option value="manager">CABOA20001 (Johnny Depp)</option>
                      <option value="Title-1">CABOA20001 (Robert De Niro)</option>
                      <option value="Title-1">CABOA20001 (Kevin Spacey)</option>   
                      </select>
                      <div *ngIf="submitted && !notesForm.controls['clientId'].valid">
                        <span class="text-danger" *ngIf="notesForm.controls['clientId'].hasError('required') ">
                          Select File is required.
                        </span>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>

            </div>

            <div class="chat-head">
              <span>Notes *</span>
              <input type="text" id="messageBody" formControlName='messageBody' class="form-control"
                class="send-note-txt" value="Lorem ipsum is a dummy text content in a pragraph...">
              <div *ngIf="submitted && !notesForm.controls['messageBody'].valid">
                <span class="text-danger" *ngIf="notesForm.controls['messageBody'].hasError('required') ">
                  Filed is required
                </span>
              </div>
            </div>
            <div class="chat-head">
              <span>Notify</span>
              <div class="apexinput-froms block_labells">
                <div class="checkedpan_grp">
                  <span class="checkbox checkbox_row">
                    <div class="form-group check-form">
                      <div *ngFor="let usertypeData of SelectuserTypeData">
                        <input type="checkbox" id="{{usertypeData.userUniqueId}}" formControlName="toUserType"
                          value="{{usertypeData.userUniqueId}}" (change)="selectUserType($event,usertypeData)" />
                        <label for="{{usertypeData.userUniqueId}}"></label>
                        <small style="text-transform: capitalize;">{{usertypeData.userName}} ({{usertypeData.userTypeName}}) </small>
                      </div>
                      <!-- <div *ngIf="submitted && !notesForm.controls['toUserType'].valid">
                        <span class="text-danger" *ngIf="notesForm.controls['toUserType'].hasError('required') ">
                          Please select any users
                        </span>
                      </div> -->
                    </div>
                  </span>

                </div>
              </div>
              <button class="send-notes-btn" (click)="onSubmit()">Send Notes</button>
            </div>


          </form>


          <div class="chat-body">
            <div>
              <button *ngFor="let item of tabName" class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == item ? '#085589': '', 'color': activeTitle == item ? '#FFFFFF': ''}" (click)="activeTitle = item">{{item}}</button>
              <!-- <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'agent' ? '#085589': '', 'color': activeTitle == 'agent' ? '#FFFFFF': ''}" (click)="activeTitle = 'agent'">AGENT</button>
              <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'manager' ? '#085589': '', 'color': activeTitle == 'manager' ? '#FFFFFF': ''}" (click)="activeTitle = 'manager'">MANAGER</button>
              <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'negotiator' ? '#085589': '', 'color': activeTitle == 'negotiator' ? '#FFFFFF': ''}" (click)="activeTitle = 'negotiator'">NEGOTIATOR</button>
              <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'assnegotiator' ? '#085589': '', 'color': activeTitle == 'assnegotiator' ? '#FFFFFF': ''}" (click)="activeTitle = 'assnegotiator'">ASSTNEGOTIATOR</button>
              <button class="btn brd-btn btn-create-manage " style="margin: 2px;display: inline-block;" [ngStyle]="{'background': activeTitle == 'closer' ? '#085589': '', 'color': activeTitle == 'closer' ? '#FFFFFF': ''}" (click)="activeTitle = 'closer'">CLOSER</button> -->
            </div>
            <div>  <span class="msg_title">Notes History </span></div>
            <div class="chat-list" *ngFor="let history of notesHistory;let i = index">
              <!-- <img src="./assets/images/dp-1.png"> -->
              <!-- <p>{{history.broker | json}}</p> -->



            <div >


              <div class="chat-msg" >
                <span class="profile-pic-text">{{ getShortName(history.sendUser)}}</span>
                <p class="chat-user-name"> {{history.sendUser}}</p>

                <p class="chat-msg-txt">{{history.message	}}
                </p>
                <!-- <button *ngIf="j == 0" _ngcontent-ccm-c65="" (click)="Notesreply(brokerhist);" data-toggle="modal" data-target="#replypopup"
                        class="btn-create-manage reply-btn">Reply</button> -->
                <p class="chat-user-name">{{history.toUserName}}
                  <span class="chat-msg-time">{{history.createdOn}}</span>
                </p>
              </div>
            </div>

            </div>


                   </div>
        </div>
</div>
</div>
</div>
  </div>

<!-- END -->

<!-- Thereply column Modal -->
<div class="modal" id="replypopup">
  <div class="modal-dialog modal-dialog-centered  modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <div class="cust-modal-head">
          <h4 class="modal-title">Notes</h4>

          <button type="button" class="Cls-btn-g" data-dismiss="modal">
            <img src="../../../assets/images/clients/pop-close.png" alt="close">
          </button>
        </div>
      </div>
      <!-- Modal body -->
      <div class="modal-body chat-modal-body">
        <div class="require-modal-inner">
          <form class="form" [formGroup]="messageForm">
            <div class="chat-head">
              <div class="apexforms-group">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" class="form-group">
                    <div class="apexinput-froms block_labells">
                      <label>Select File </label>
                      <select name="text" id="clientId" formControlName='clientId' class="form-control"
                        [attr.disabled]=true>
                        <option value="">Select a File</option>
                        <option value="{{filedata.clientId}}" *ngFor="let filedata of selectFileData">
                          {{filedata.fileno}}</option>
                        <!-- <option value="manager">CABOA20001 (Johnny Depp)</option>
                      <option value="Title-1">CABOA20001 (Robert De Niro)</option>
                      <option value="Title-1">CABOA20001 (Kevin Spacey)</option> -->
                      </select>
                    </div>
                  </div>
                </div>
              </div>



            </div>



            <div class="chat-head">
              <span>Notes</span>
              <input type="text" id="messageBody" formControlName='messageBody' class="form-control"
                class="send-note-txt" value="Lorem ipsum is a dummy text content in a pragraph...">
              <div *ngIf="messageForm.controls['messageBody'].touched && !messageForm.controls['messageBody'].valid">
                <span class="text-danger" *ngIf="messageForm.controls['messageBody'].hasError('required') ">
                  Filed is required
                </span>
              </div>
            </div>
            <div class="chat-head">

              <button class="send-notes-btn" (click)="onsubmitList()">Send Notes</button>
            </div>

          </form>
          <div class="chat-body">
            <!-- <div class="coversion-align">
            <li *ngFor="let item of TotalData" (click)="notifyreaded(item.notifydetailsId)">
              <div class="notification_details">
                <h1 [ngClass]="item.isReaded == false? 'activebold' : 'activeunbold'">{{item.senduserName}}</h1>
                <span>{{item.sendDate}}</span>
              </div>
              <div class="msg-bdy-g">
                <p>{{item.messageBody}}</p>
              </div>

            </li>
          </div> -->
            <div class="chat-list">

              <div class="chat-msg">
                <li *ngFor="let item of TotalData" (click)="notifyreaded(item.notifydetailsId)">
                  <p class="chat-msg-txt">{{item.messageBody}}
                  </p>
                  <p class="chat-user-name">{{item.senduserName}}
                    <span class="chat-msg-time">{{item.sendDate}}</span>
                  </p>
                </li>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- end reply column Modal -->
