<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Users">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Homeowner List</h2>
          <div class="sidebar-search lead_glo global-search">
            <div>
                <div class="input-group">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <input type="search" placeholder="Search..." (input)="onSearch($event)" #searchword>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagecontent_wrapper">
        <!-- <div class="apex-white_wrap">
          <form (ngSubmit)="getFilterData()">

            <div class="filterapx_wrap">
              <div class="filter_setting_forms">
                <h3>Filter By</h3>
                <div class="apxsilsetting_group">
                  <label>UserList</label>
                  <mat-form-field appearance="fill" *ngIf="usertype != 'Agent'">
                    <mat-select name="username" [(ngModel)]="filterRequest.username">
                      <mat-option>--Select--</mat-option>
                      <input type="text" (keyup)="onSearchuserName($event)" (keydown)="$event.stopPropagation()">
                      <mat-option *ngFor="let item of userListOptions" [value]="item.userName">
                        {{item.userName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="fill" *ngIf="usertype == 'Agent'">
                    <mat-select name="username" [(ngModel)]="filterRequest.fullName">
                      <mat-option>--Selecsst--</mat-option>
                      <input type="text" (keyup)="onSearchuserhome($event)" (keydown)="$event.stopPropagation()">
                      <mat-option *ngFor="let item of getHomeownerList" [value]="item.fullName">
                        {{item.fullName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="apxsilsetting_group">
                  <label>Status</label>
                  <select class="form-control" name="status" [(ngModel)]="filterRequest.status">
                    <option>--Select--</option>
                    <option *ngFor="let statuslist of statusListOptions" [value]=statuslist.statusId>
                      {{statuslist.status}}</option>
                  </select>
                </div>
              </div>
              <div class="filter_setting">
                <ul>
                  <li class="filter_apx"><button type="submit"><img src="../../../assets/images/filter.png"
                        alt="filter"></button></li>
                  <li class="setting_reset"><button type="reset" (click)="_getHomeOwner_records()"><img
                        src="../../../assets/images/setting_reset.png" alt="filter"></button></li>
                </ul>
              </div>
            </div>
          </form>

        </div> -->
        <div class="apxtable_grids_wrapper">
          <div class="apxtable_grid_head">
            <h2>List of Home owners</h2>
            <div class="apxtable_headbtn">
              <button class="btn-export-down" (click)="downloadExcel()"><svg width="20" height="20" viewBox="0 0 20 20"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.525 8.45391C16.116 8.05039 15.5746 7.82812 15 7.82812C14.4199 7.82812 13.8746 8.05391 13.4645 8.46445L12.1875 9.74102V2.1875C12.1875 0.98125 11.2062 0 10 0C8.79375 0 7.8125 0.98125 7.8125 2.1875V9.74102L6.55508 8.48359C6.14492 8.07344 5.575 7.83867 4.99062 7.83867H4.9832C4.39961 7.84062 3.85664 8.0668 3.45391 8.475C3.05039 8.88398 2.82812 9.42539 2.82812 10C2.82812 10.5801 3.05391 11.1254 3.46445 11.5355L8.7293 16.8008C9.07969 17.1512 9.53984 17.3262 10 17.3262C10.4602 17.3262 10.9203 17.1512 11.2707 16.8008L16.5164 11.5551C16.9281 11.1434 17.1633 10.5703 17.1613 9.9832C17.1594 9.39961 16.9332 8.85664 16.525 8.45391Z"
                    fill="#9B59B6" />
                  <path
                    d="M19.375 20H18.4375C18.0923 20 17.8125 19.7202 17.8125 19.375C17.8125 19.0298 18.0923 18.75 18.4375 18.75H19.375C19.7202 18.75 20 19.0298 20 19.375C20 19.7202 19.7202 20 19.375 20Z"
                    fill="#9B59B6" />
                  <path
                    d="M1.5625 20H0.625C0.279844 20 0 19.7202 0 19.375C0 19.0298 0.279844 18.75 0.625 18.75H1.5625C1.90766 18.75 2.1875 19.0298 2.1875 19.375C2.1875 19.7202 1.90766 20 1.5625 20Z"
                    fill="#9B59B6" />
                  <path
                    d="M16.25 20H3.75C3.40484 20 3.125 19.7202 3.125 19.375C3.125 19.0298 3.40484 18.75 3.75 18.75H16.25C16.5952 18.75 16.875 19.0298 16.875 19.375C16.875 19.7202 16.5952 20 16.25 20Z"
                    fill="#9B59B6" />
                </svg>
                Download / Export</button>
            </div>
          </div>
          <div class="apxtable_grid_table" *ngIf="homeownerList && homeownerList.length>0">
            <div class="table-responsive apx-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th [class.active]="order === 'i'" (click)="setOrder('i')">#<span [hidden]="reverse"><img
                          src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('firstName')">First Name <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('lastName')">Last Name <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('emailId')">Email Address <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('phone')">Phone Number <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'userid'" (click)="setOrder('address')">Address <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th *ngIf="usertype == 'Agent' || usertype == 'Broker'" [class.active]="order === 'userid'"
                      (click)="setOrder('address')">
                      Status <span [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let list of homeownerList | orderBy: order:reverse | paginate: { itemsPerPage: 45, currentPage: p } ; let i = index">
                    <td>{{ i+1}}</td>
                    <td>{{ list.firstName || '--'}}</td>
                    <td>{{ list.lastName || '--' }}</td>
                    <td>{{ list.emailId || '--' }}</td>
                    <td *ngIf="usertype != 'Broker'">{{ list.phone1 | mask: '(000) 000-0000' || "--" }}</td>
                    <td *ngIf="usertype == 'Broker'">{{ list.phone1  | mask: '(000) 000-0000' || "--" }}</td>
                    <td>{{ list.address || '--' }}</td>
                    <td *ngIf="usertype == 'Agent' || usertype == 'Broker'">
                      <button *ngIf="list.active == true" class="btn-w-img"> <img
                          src="../../../assets/images/clients/tick.png" alt="filter">Active</button>
                      <button *ngIf="list.active == false" class="btn-w-img-red"> InActive<img
                          src="../../../assets/images/clients/reject-x.png" alt="filter"></button>
                      <!-- <td>{{ list.phone || '--' }}</td>
                    <td>{{ list.address || '--' }}</td> -->
                      <!-- <td>{{ list.firstName}}</td>
                    <td>{{ list.lastName }}</td>
                    <td>{{ list.emailId }}</td>
                    <td>{{ list.phone1  }}</td>
                    <td>{{ list.address  }}</td> -->
                    <td>
                      <div class="apx-actions">
                        <span class="pencil"><a [routerLink]="['/superadmin/houseowner-detail-edit']"
                            [queryParams]="{id:list.userId }"><i class="fas fa-pencil-alt"></i></a></span>
                        <span *ngIf="usertype != 'Agent' && usertype != 'Broker'" data-toggle="modal"
                          data-target="#tabledel" class="trash"><i (click)="setId(list)"
                            class="far fa-trash-alt"></i></span>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="apxtable_pagination_wrapper" *ngIf="homeownerList.length>0 else elseBlock">
          <!-- <div class="apxtable_pagelist">
            <div class="apxlist_number_page">
              <span>Show</span>
              <input value="7" type="number" />
              <span>Entries</span>
            </div>
            <div class="pagination_gopage">
              <span>Go to Page</span>
              <input type="text" value="1" />
            </div>
          </div> -->
          <div class="apxtable_pagination">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
        </div>
        <ng-template #elseBlock>
          <div class="nodata_found_components">
            <h2>No Data found</h2>
          </div>
        </ng-template>
      </div>
    </div>
  </main>

</div>



<!-- The Modal -->
<div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel">Cancel</button>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="filterforms_setting">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>First Name</label>
                <input type="text" placeholder="First name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Last Name</label>
                <input type="text" placeholder="Last name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Status</label>
                <select>
                  <option value="">All</option>
                  <option value="">All-1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>



<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <img src="../../../assets/images/danger.png" alt="danger" />
          <p>Are you sure want to delete the data?</p>
          <div class="filterbt_btnn btn_deletecard">
            <button data-dismiss="modal" (click)="_delete_houseOwner()" class="btnfilter-cancel">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
