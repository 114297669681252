import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../service/auth-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  email: any = {};
  constructor(private auth_service: AuthServiceService, private route: Router, private toastrs: ToastrService,) { }

  ngOnInit(): void {
  }



  _forgot_password() {
    this.auth_service._forgot_password(this.email).subscribe((data: any) => {
      console.log(data);
      if (data.reponse == true) {
        this.toastrs.success(data.message)
        this.route.navigate(['\login']);
      }else{
        this.toastrs.error(data.message);        
      }
     
    }, err => {
      // alert("SOMETHING WENT WRONG");
    })

  }
}
