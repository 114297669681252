import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { ToastService } from '../../service/toast.service';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-configuratin-setting',
  templateUrl: './configuratin-setting.component.html',
  styleUrls: ['./configuratin-setting.component.css']
})
export class ConfiguratinSettingComponent implements OnInit {
  myConfigSetting: any = { "sms": false, "email": false, "twoFactorAuth": false, 'createdBy': sessionStorage.getItem('userTypeId') };
  configList: any;

  @ViewChild(NgForm) notificationform:NgForm;
  twofactor: Boolean = false;
  mail: Boolean = false;
  phone: Boolean = false;
  constructor(private datePipe: DatePipe,
    private _manager_service: ManagerService, private toastr: ToastService) { }
  datePickerConfig = {
    format: 'MM-DD-YYYY', disableKeypress: true
  };
  ngOnInit(): void {
    this.myConfigSetting['sms'] = false;
    this.myConfigSetting['email'] = true;
    this._getConfig();
  }


  _getConfig() {
    this._manager_service._getConfig_settings().subscribe((authdata: any) => {
      this.myConfigSetting = authdata.getData;
      if (this.myConfigSetting['sms'] == true) {
        this.myConfigSetting.authType = 'sms';
      } else if (this.myConfigSetting['email'] == true) {
        this.myConfigSetting.authType = 'email';
      }
      // console.log(authdata.getData['sms'], authdata.getData['email'])
      // this.myConfigSetting['twoFactorAuth'] =  authdata.getData['twoFactorAuth'];
      // this.myConfigSetting.sms = authdata.getData['sms'];
      // this.myConfigSetting.email = authdata.getData['email'];
      this.myConfigSetting['createdBy'] = sessionStorage.getItem('userTypeId');
      console.log(this.myConfigSetting)

    })
  }

  changeConfig(formdetails: any) {
   this.myConfigSetting['createdBy'] = sessionStorage.getItem('userTypeId');
    this.myConfigSetting["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    if (this.myConfigSetting.authType == "sms") {
      this.myConfigSetting['sms'] = true;
      this.myConfigSetting['email'] = false;
    } else if (this.myConfigSetting.authType == "email") {
      this.myConfigSetting['sms'] = false;
      this.myConfigSetting['email'] = true;
    }

    //  return false;
    //  console.log(this.myConfigSetting,"authdatss")
    this._manager_service.configSetting(this.myConfigSetting).subscribe((data: any) => {
      this.toastr.showSuccess(data.message)
      this.notificationform.form.markAsUntouched();
    }, err => {
      this.notificationform.form.markAsUntouched();
      this.toastr.showError("something went wrong")
    })
  }
  // onconfigSetting(event: any) {
  //   // alert('changes');
  //   this.configList = event.target.value
  //   if (event.target.value == 'Email') {
  //     this.myConfigSetting['email'] = true;
  //     this.myConfigSetting['sms'] = false;
  //   } else if (event.target.value == 'sms') {
  //     this.myConfigSetting['email'] = false;
  //     this.myConfigSetting['sms'] = true;
  //   }
  //   console.log(event.target.value, 'sms Email')

  // }

  resetType() {
    var self = this;
    setTimeout(() => {
      if (self.myConfigSetting.twoFactorAuth == false) {
        self.myConfigSetting['authType'] = '';
        self.myConfigSetting['email'] = false;
        self.myConfigSetting['sms'] = false;
      }
    }, 1000);

  }


 
canDeactivate() {
  return !this.notificationform.form.touched;
}
}
