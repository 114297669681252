<router-outlet>
</router-outlet>

<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fe5723" type="ball-atom" [fullScreen]="true">
</ngx-spinner> -->
<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#eb5f06" type = "line-scale" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->

<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#eb5f06" type = "line-scale" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>-->
<app-lead-rejected-list [hidden]="role === 'agent'"></app-lead-rejected-list>


<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">You Have Been Idle!</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         {{idleState}}
        <div class="row mrgbtm">
          <div class="col-md-6">
              <button type="button" (click)="logout()" class="btn btn-danger">Logout</button>
          </div>
          <div class="col-md-6">
              <button type="button" (click)="stay()" class="btn btn-success">Stay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <ng-template #template>  

  <div class="modal-header">  

    <h4 class="modal-title pull-left">You have been Idle!</h4>  

    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">  
     <span aria-hidden="true">×</span>  

    </button>  

  </div>  

  <div class="modal-body">  
      {{idleState}}
  </div>  

  <div class="modal-footer">  

    <button type="button" class="btn btn-default" (click)="modalRef.hide()">Stay</button>  
    <button type="button" class="btn btn-default" (click)=" logout();modalRef.hide()">Logout</button>  

  </div>  

</ng-template>  -->
