import { Component, OnInit, ViewChild } from '@angular/core';
import { ManagerService } from '../../service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../service/toast.service';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-houseowner-detail-edit',
  templateUrl: './houseowner-detail-edit.component.html',
  styleUrls: ['./houseowner-detail-edit.component.css']
})
export class HouseownerDetailEditComponent implements OnInit {
  @ViewChild(NgForm) addEdit:NgForm
  addHomeowner: any = {};
  getHomeowner: any = {};
  id: any = {};
  updateHomeowner: Boolean = false;
  message: any;
  titleDetail = ['Title', 'Title-1']
  statusActive = ['Active', 'Inactive'];
  constructor(private datePipe: DatePipe,
   private toast: ToastService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }
  ngOnInit(): void {
    this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // let id = this._manager_service.getId();
    if (this.id !== null || undefined) {
      this.updateHomeowner = true;
      this._get_Homeowner_byId(this.id)
    }
  }

  _create_homeowner() {
    console.log(this.addHomeowner)
    this.addHomeowner["createdOn"] = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    if (this.updateHomeowner) {
      console.log(this.addHomeowner)
      this.addHomeowner.createdBy = "SuperAdmin";
      this._manager_service._update_HomeOwners(this.addHomeowner).subscribe(data => {
        this.message = data;
        this.addEdit.form.markAsUntouched();
        // console.log("update", data)
        this.toast.showSuccess(this.message['message'])
        this.route.navigate(['/superadmin/houseowner-detail-list'])
      })
    }
    else {
      console.log(this.addHomeowner)
      this.addHomeowner.createdBy = "SuperAdmin";
      this._manager_service._add_HomeOwners(this.addHomeowner).subscribe(data => {
        this.message = data;
        this.addEdit.form.markAsUntouched();
        this.toast.showSuccess(this.message['message'])
        this.route.navigate(['/superadmin/houseowner-detail-list'])

      })
    }
  }

  _get_Homeowner_byId(id: number) {
    this._manager_service._get_HomeOwnersByid(id).subscribe((data: any) => {
      //  console.log(data['getData'])
      this.addHomeowner = data['getData'];
    })


  }
  canDeactivate() {
    return !this.addEdit.form.touched;
  }

}
