import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { FileuploadService } from '../../service/fileupload.service';
import { DropdownService } from '../../service/dropdown.service';
import { ToastService } from '../../service/toast.service';
import { TokenService } from '../../service/token.service';

@Component({
  selector: 'app-lead-accepted-list',
  templateUrl: './lead-accepted-list.component.html',
  styleUrls: ['./lead-accepted-list.component.css']
})
export class LeadAcceptedListComponent implements OnInit {


  public leadData: any = [];
  public DbleadData: any = [];
  public userListBack: any = [];
  homeOwnerListBack: any = [];
  public userTypes: any;
  public userLists: any;
  order: string = 'leadowner';
  p: number = 1;
  reverse: boolean = false;
  masterSelected: boolean;
  checkedList: any;
  getDropdownUser: any
  getUserlist: any
  myuserType: any;
  assigntolead: any;
  searchKey: any;
  getListagentBroker: any
  userListOptions: any;
  homeOwnerList: any;
  userLeadId: any
  filterRequest: any = {};
  usertypeId: any
  userInfo: any
  userInfoList: any
  checkedListForAgent: any
  currentLoggedID: any

  constructor(private _manager_service: ManagerService,
    private _file_upload: FileuploadService,
    private _dropdown: DropdownService,
    private tokenStorage: TokenService,
    private toastr: ToastService,

  ) {
    this.masterSelected = false;
    this.getCheckedItemList();
  }

  ngOnInit(): void {
    this.myuserType = this.tokenStorage._get_userRole();
    this.usertypeId = this.tokenStorage._get_userTypeId();



    this.userInfo = this.tokenStorage._get_userInfo();

    this.userInfoList = JSON.parse(this.userInfo);

    this.currentLoggedID = this.userInfoList.CurrentLoggedUserId;

    this.getfilter_details();
    this.getAgentsForBroker();
    this.getLeadlist();
    this.usertypes_Dropdown();

  }

  //** GET LEAD LIST */
  getLeadlist() {
    this._manager_service._getAll_Accepted().subscribe((leadlist: any) => {
      this.leadData = leadlist.listData;
      this.DbleadData = leadlist.listData;
      console.log(this.leadData)
    })
  }
  //**END */

  //**Usertype Dropdown */
  usertypes_Dropdown() {
    this._dropdown._getuser_TypeList().subscribe((typelist: any) => {
      this.userTypes = typelist['listData'];
      //  console.log(this.userTypes,"ji")
    })

    if (this.getDropdownUser.userTypeId) {
      this._dropdown._get_Userlist(this.getDropdownUser.userTypeId).subscribe((getuserlist: any) => {
        this.userLists = getuserlist.listData;
        this.userListBack = getuserlist.listData;
        console.log(this.userLists, "dsdsdsf")
      })
    }
  }
  //**END */
  getAgentsForBroker() {
    this._manager_service.GetAgentsForBroker(this.usertypeId).subscribe((resp: any) => {
      this.getListagentBroker = resp.listData;
      console.log(this.getListagentBroker, 'List agentfor broker')
    })
  }
  getFilterData() {
    console.log(this.filterRequest);
    this.filterRequest['homeOwnerId'] = parseInt(this.filterRequest.homeOwnerId);
    this.filterRequest['userId'] = parseInt(this.filterRequest.userId);
    this._dropdown._getAll_acceptLead_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.leadData = filteredData.listData;
      this.DbleadData = filteredData.listData;
    });
  }

  //**Get Details for Filter */
  getfilter_details() {
    this._dropdown._getAll_acceptLead_UserList().subscribe((userList: any) => {
      console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
    })
    this._dropdown._getAll_lead_homeOwner().subscribe((homeowner: any) => {
      console.log(homeowner.listData, "sji")
      this.homeOwnerList = homeowner.listData;
    })
  }

  //**End */
  //** leadAssign to User */
  assign_lead() {
    console.log(this.checkedList.length);
    if (this.checkedList.length <= 0) {
      this.toastr.showError(" please select atleast one lead to assign")
    } else {
      this.checkedList = this.checkedList.filter((item: any) => {
        item.userId = this.getUserlist.userId;
        return item;
      })
      this._manager_service._assign_Lead(this.checkedList).subscribe((data: any) => {

        if (data.reponse == true) {
          this.getLeadlist();
          this.toastr.showSuccess(data.message);
        } else {
          this.toastr.showError(data.message);
        }
        //this.toastr.showSuccess("Lead Assigned")
      })
    }
  }
  //**END */

  //**SORT */
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  //**END */

  //**CheckBox in lead list */
  checkUncheckAll() {
    for (var i = 0; i < this.leadData.length; i++) { this.leadData[i].isSelected = this.masterSelected; } this.getCheckedItemList()
  }

  isAllSelected() { this.masterSelected = this.leadData.every(function (item: any) { return item.isSelected == true; }); this.getCheckedItemList(); }
  agentList(e: any) {
    console.log(e.target.value, 'selectDropdown')
    //let value = e.target.value
    this.userLeadId = e.target.value;
  }
  getCheckedItemList() {
    let assignlead: any = []
    this.checkedList = [];
    this.checkedListForAgent = [];
    console.log('myuser type', this.myuserType);
    if (this.myuserType != 'Broker') {
      for (var i = 0; i < this.leadData.length; i++) {
        if (this.leadData[i].isSelected)
          this.checkedList.push({
            'leadId': this.leadData[i].leadId,
            'userId': this.getUserlist.userId,
            'userTypeId': this.getDropdownUser.userTypeId,
            "createdBy": this.currentLoggedID
          });
      }
    }
    else if (this.myuserType == 'Broker') {
      console.log('myuser typesttpelead', this.leadData);
      console.log('myuser typesttpe', this.myuserType);
      for (var i = 0; i < this.leadData.length; i++) {
        console.log('this.leadData[i].isSelected', this.leadData[i].isSelected);
        if (this.leadData[i].isSelected) {
          this.checkedListForAgent.push({
            "leadId": this.leadData[i].leadId,
            "userId": Number(this.userLeadId),
            "userTypeId": Number(this.currentLoggedID),
            "isReassigned": true,
            "createdBy": this.currentLoggedID
          });
        }

      }


    }
    this.checkedList = this.checkedList;
    console.log(this.checkedListForAgent)
  }
  //**END */

  //**Bulk File Upload */
  uploadFile(fileEvent: any) { this._file_upload.uploadFile(fileEvent) }
  //**END */

  downloadExcel() {
    this._file_upload.download_template();
  }
  downloadExcellist() {
    this._file_upload.download_list_lead();
  }
  downloadExcellistAccept() {
    this._file_upload.download_list_lead_accept();
  }

  // leadSearch() {
  //   console.log(this.searchKey)
  //   this._dropdown.search_lead(this.searchKey).subscribe((searchData: any) => {
  //     this.leadData = searchData.listData;
  //     console.log(searchData.listData)
  //   })
  // }
  leadSearch(searchkey: any) {
    console.log(this.searchKey)
    this._dropdown.search_lead(searchkey).subscribe((searchData: any) => {
      this.leadData = searchData.listData;
      console.log(searchData.listData)
    })
  }

  _sentassignedLeadtoAgent() {
    if (this.checkedListForAgent.length <= 0) {
      this.toastr.showError(" please select atleast one lead to assign")
    } else {
      this.checkedListForAgent = this.checkedListForAgent.filter((item: any) => {
        item.userId = Number(this.userLeadId);
        return item;
      })
      this._manager_service.assignedLeadtoAgent(this.checkedListForAgent).subscribe((data: any) => {
        console.log(data, '_sentassignedLeadtoAgent')
        if (data.reponse == true) {
          this.getLeadlist();
          this.toastr.showSuccess(data.message);
        } else {
          this.toastr.showError(data.message);
        }
      })
    }
  }

  onSearchOwner(e: any) {
    this.homeOwnerList = this.homeOwnerListBack.filter((item: any) => item.ownerName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
    console.log("e.target.value ", e.target.value);
  }
  onSearchUsers(e: any) {
    this.userLists = this.userListBack.filter((item: any) => item.userName.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
  }

  onSearch(e: any) {
    console.log("value ", e);
    var self = this;
    console.log("self.leadData ", self.leadData);
    self.leadData = self.DbleadData.filter((a: any) => Object.keys(a).some((k: any) => {
      if (a[k]) {
this.p=1;
        return a[k].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;

      }
      else {
        return false;
      }
    }));
  }

}
