<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapperr  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Users">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Negotiators List</h2>
        </div>
      </div>
      <div class="pagecontent_wrapper">
        <div class="apex-white_wrap">
          <form (ngSubmit)="getFilterData()">

            <div class="filterapx_wrap">
              <div class="filter_setting_forms">
                <h3>Filter By</h3>
                <!-- <div class="apxsilsetting_group">
                  <label>UserList</label>
                  <select class="form-control" name="username" [(ngModel)]="filterRequest.username">
                    <option>--Select--</option>
                    <option *ngFor="let userlist of userListOptions" [value]=userlist.userName>{{userlist.userName}}
                    </option>
                  </select>
                </div> -->
                <!-- <div class="apxsilsetting_group">
                  <label>Last Name</label>
                  <input type="text" value="Bailey">
                </div> -->
                <div class="apxsilsetting_group">
                  <label>Status</label>
                  <select class="form-control" name="status" [(ngModel)]="filterRequest.status">
                    <option>--Select--</option>
                    <option *ngFor="let statuslist of statusListOptions" [value]=statuslist.statusId>
                      {{statuslist.status}}</option>
                  </select>
                </div>
              </div>
              <div class="filter_setting">
                <ul>
                  <!-- <li data-toggle="modal" data-target="#filterby" class="filter_apx"></li> -->
                  <li class="filter_apx"><button type="submit"><img src="../../../assets/images/filter.png"
                        alt="filter"></button></li>
                  <li class="setting_reset"><button type="reset" (click)="_getNegotiator_records()"><img
                        src="../../../assets/images/setting_reset.png" alt="filter"></button></li>
                </ul>
              </div>
            </div>
          </form>

        </div>
        <div class="apxtable_grids_wrapper">
          <div class="apxtable_grid_head">
            <h2>List of Negotiators</h2>
            <div class="apxtable_headbtn">
              <button class="btn-export-down" (click)="download_excel()"><svg width="20" height="20" viewBox="0 0 20 20"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.525 8.45391C16.116 8.05039 15.5746 7.82812 15 7.82812C14.4199 7.82812 13.8746 8.05391 13.4645 8.46445L12.1875 9.74102V2.1875C12.1875 0.98125 11.2062 0 10 0C8.79375 0 7.8125 0.98125 7.8125 2.1875V9.74102L6.55508 8.48359C6.14492 8.07344 5.575 7.83867 4.99062 7.83867H4.9832C4.39961 7.84062 3.85664 8.0668 3.45391 8.475C3.05039 8.88398 2.82812 9.42539 2.82812 10C2.82812 10.5801 3.05391 11.1254 3.46445 11.5355L8.7293 16.8008C9.07969 17.1512 9.53984 17.3262 10 17.3262C10.4602 17.3262 10.9203 17.1512 11.2707 16.8008L16.5164 11.5551C16.9281 11.1434 17.1633 10.5703 17.1613 9.9832C17.1594 9.39961 16.9332 8.85664 16.525 8.45391Z"
                    fill="#9B59B6" />
                  <path
                    d="M19.375 20H18.4375C18.0923 20 17.8125 19.7202 17.8125 19.375C17.8125 19.0298 18.0923 18.75 18.4375 18.75H19.375C19.7202 18.75 20 19.0298 20 19.375C20 19.7202 19.7202 20 19.375 20Z"
                    fill="#9B59B6" />
                  <path
                    d="M1.5625 20H0.625C0.279844 20 0 19.7202 0 19.375C0 19.0298 0.279844 18.75 0.625 18.75H1.5625C1.90766 18.75 2.1875 19.0298 2.1875 19.375C2.1875 19.7202 1.90766 20 1.5625 20Z"
                    fill="#9B59B6" />
                  <path
                    d="M16.25 20H3.75C3.40484 20 3.125 19.7202 3.125 19.375C3.125 19.0298 3.40484 18.75 3.75 18.75H16.25C16.5952 18.75 16.875 19.0298 16.875 19.375C16.875 19.7202 16.5952 20 16.25 20Z"
                    fill="#9B59B6" />
                </svg>
                Download / Export</button>

              <button class="btn-create-manage"><a routerLink="/superadmin/negotiators-edit">
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.61931 10.1372C10.4355 10.137 11.4488 7.31722 11.7141 5.00095C12.0409 2.14755 10.6915 0 7.61931 0C4.54748 0 3.19744 2.14739 3.52447 5.00095C3.78999 7.31722 4.803 10.1374 7.61931 10.1372Z"
                      fill="#085589" />
                    <path
                      d="M13.7773 11.311C13.8679 11.311 13.958 11.3137 14.0474 11.3187C13.9134 11.1275 13.7582 10.9589 13.5767 10.8233C13.0355 10.4194 12.3347 10.2869 11.7151 10.0435C11.4135 9.92501 11.1435 9.80733 10.8899 9.67334C10.0342 10.6117 8.91838 11.1026 7.61897 11.1028C6.31999 11.1028 5.20423 10.6119 4.34865 9.67334C4.09512 9.80736 3.82499 9.92501 3.52345 10.0435C2.90387 10.287 2.20306 10.4194 1.66188 10.8233C0.725999 11.5219 0.484136 13.0933 0.294104 14.1652C0.137274 15.05 0.0319142 15.953 0.00116256 16.8517C-0.022661 17.5479 0.321065 17.6455 0.903483 17.8557C1.63273 18.1187 2.38571 18.314 3.14374 18.474C4.6077 18.7832 6.11672 19.0207 7.61923 19.0313C8.34727 19.0261 9.07678 18.9674 9.80198 18.8734C9.26502 18.0954 8.95008 17.153 8.95008 16.1383C8.95012 13.4765 11.1156 11.311 13.7773 11.311Z"
                      fill="#085589" />
                    <path
                      d="M13.7773 12.2764C11.6446 12.2764 9.91553 14.0054 9.91553 16.1382C9.91553 18.2709 11.6445 20 13.7773 20C15.9101 20 17.6392 18.2709 17.6392 16.1382C17.6391 14.0054 15.9101 12.2764 13.7773 12.2764ZM15.4669 16.8007H14.4399V17.8276C14.4399 18.1936 14.1433 18.4903 13.7774 18.4903C13.4114 18.4903 13.1147 18.1936 13.1147 17.8276V16.8007H12.0878C11.7219 16.8007 11.4252 16.5041 11.4252 16.1381C11.4252 15.7722 11.7218 15.4755 12.0878 15.4755H13.1147V14.4486C13.1147 14.0826 13.4114 13.7859 13.7774 13.7859C14.1433 13.7859 14.4399 14.0826 14.4399 14.4486V15.4755H15.4669C15.8328 15.4755 16.1295 15.7722 16.1295 16.1381C16.1294 16.5041 15.8328 16.8007 15.4669 16.8007Z"
                      fill="#085589" />
                  </svg>
                  Create Negotiator</a></button>
            </div>
          </div>
          <div class="apxtable_grid_table" *ngIf="negotiatorList && negotiatorList.length>0">
            <div class="table-responsive apx-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th [class.active]="order === 'i'" (click)="setOrder('i')"># <span [hidden]="reverse"><img
                          src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'firstName'" (click)="setOrder('firstName')">FirstName <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'lastName'" (click)="setOrder('lastName')">LastName <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'emailId'" (click)="setOrder('emailId')">EmailAddress <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th [class.active]="order === 'phone'" (click)="setOrder('phone')">PhoneNumber <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <!-- <th [class.active]="order === 'title'" (click)="setOrder('title')">Title <span
                    [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                  <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th> -->
                    <th [class.active]="order === 'active'" (click)="setOrder('active')">Status <span
                        [hidden]="reverse"><img src="../../../assets/images/sort.png"></span>
                      <span [hidden]="!reverse"><img src="../../../assets/images/sort.png"></span></th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let list of negotiatorList | orderBy: order:reverse | paginate: { itemsPerPage: 45, currentPage: p } ; let i = index;">

                    <td>{{45 *(p-1)+i+1}}</td>
                    <td>{{list.firstName || "--"}}</td>
                    <td>{{list.lastName || "--" }}</td>
                    <td>{{list.emailId || "--" }}</td>
                    <td>{{list.phone || "--"}}</td>
                    <!-- <td>{{list.tittle }}</td> -->
                    <td><span class="active_status"
                        [ngClass]="{'active_status':list.active === true, 'inactive_status':list.active === false}">{{list.active === true ? 'Active' : (list.active === false ? 'InActive' : '--')}}</span>
                      <!-- <span class="inactive_status">InActive</span> -->
                      <!-- superadmin/negotiators-edit -->
                    </td>
                    <td>
                      <div class="apx-actions">
                        <span class="pencil"><a [routerLink]="['/superadmin/negotiators-edit']"
                            [queryParams]="{id:list.userId }"><i class="fas fa-pencil-alt"></i></a></span>
                        <span data-toggle="modal" data-target="#tabledel" class="trash"><i (click)="setId(list)"
                            class="far fa-trash-alt"></i></span>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
        <div class="apxtable_pagination_wrapper" *ngIf="negotiatorList.length>0 else elseBlock">
          <!-- <div class="apxtable_pagelist">
            <div class="apxlist_number_page">
              <span>Show</span>
              <input value="7" type="number" />
              <span>Entries</span>
            </div>
            <div class="pagination_gopage">
              <span>Go to Page</span>
              <input type="text" value="1" />
            </div>
          </div> -->
          <div class="apxtable_pagination">

            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
        </div>
        <ng-template #elseBlock>
          <div class="nodata_found_components">
            <h2>No Data found</h2>
          </div>
        </ng-template>
      </div>
    </div>
  </main>

</div>



<!-- The Modal -->
<div class="modal" id="filterby">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Filter By</h4>
        <div class="filterbt_btnn">
          <button class="btnfilter-apply">Apply</button>
          <button class="btnfilter-cancel">Cancel</button>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="filterforms_setting">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>First Name</label>
                <input type="text" placeholder="First name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Last Name</label>
                <input type="text" placeholder="Last name" />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="apxgroup-filter">
                <label>Status</label>
                <select>
                  <option value="">All</option>
                  <option value="">All-1</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>



<!-- The Modal -->
<div class="modal" id="tabledel">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal body -->
      <div class="modal-body">
        <div class="maintable_delete">
          <img src="../../../assets/images/danger.png" alt="danger" />
          <p>Are you sure want to delete the data?</p>
          <div class="filterbt_btnn btn_deletecard">
            <button data-dismiss="modal" class="btnfilter-cancel" (click)="_delete_negotiator()">Yes</button>
            <button data-dismiss="modal" class="btnfilter-apply">No</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
