import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  public data: any = null;
  public id: any = null;

  constructor(public http: HttpClient,

  ) {

  }

  setData(formvalue: any) {

    this.data = formvalue;
  }
  setId(id: any) {
    this.id = id;
  }


}
