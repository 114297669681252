
// modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';

import { OrderModule } from 'ngx-order-pipe';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';





// components
import { AppComponent } from './app.component';
//interceptor
import { TokenAuthInterceptor } from './_middleware/token-auth.interceptor';
import { HttpErrorInterceptor } from './_middleware/http-error.interceptor';
import { FormatTimePipe } from './pipes/format-time.pipe';

import { NgxSpinnerModule } from "ngx-spinner";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { ModalModule } from 'ngx-bootstrap/modal';
import { MomentModule} from "angular2-moment";
@NgModule({
  declarations: [
    AppComponent,
    FormatTimePipe,
    


  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    OrderModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    ModalModule.forRoot(),
    MomentModule,

    ],




  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenAuthInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    DatePipe,
    NgForm,



  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
