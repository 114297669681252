import { Component, OnInit, ViewChild } from '@angular/core';
import { TokenService } from '../../service/token.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from '../../service/auth-service.service';
import { ToastService } from '../../service/toast.service';

import { DatePipe } from '@angular/common';

//Service
import { ManagerService } from 'src/app/service/manager.service';
import { NgForm } from '@angular/forms';
import { json } from '@rxweb/reactive-form-validators';
import { ComponentCanDeactivate } from 'src/app/can-deactivate.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.css']
})
export class ManageProfileComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(NgForm) updatepro:NgForm; 
  @ViewChild(NgForm) change_password:NgForm; 
  changeList: any = {};
  public usertype: any;
  changepassword_Response: any = { "userId": 0 };
  userProfileupdate: any = {};
  getprofileData: any;
  propic: any
  email: any;
  getPdfLinkDownload: any;
  CurrentLoggedId: any;
  profileUrlName: any;
  token: any;
  UserGUI: any;
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true
  };
  CurrentLoggedUserId: any
  constructor(private datepipe: DatePipe, private _manager_service: ManagerService, private auth_service: AuthServiceService, private tokenStorage: TokenService, private route: Router, private activeRoute: ActivatedRoute, private toastr: ToastService) { }
  canDeactivate(): boolean | Observable<boolean> {
    return !this.updatepro.touched;
  }

  

  ngOnInit(): void {
    this.getprofileData = sessionStorage.getItem('userInfo');
    this.getprofileData = JSON.parse(this.getprofileData);
    this.UserGUI = this.getprofileData.UserGUID;
    this.CurrentLoggedUserId = this.getprofileData.CurrentLoggedUserId;
    console.log(this.UserGUI, 'GUIIIIIIIII')
    this.getProileDetails();

    console.log(this.getprofileData)
    this.userProfileupdate['userId'] = Number(this.CurrentLoggedUserId);
    this.userProfileupdate['createdBy'] = this.getprofileData.UserTypeId;
    this.userProfileupdate['email'] = this.getprofileData.EmailId;
    this.userProfileupdate['firstName'] = this.getprofileData.FullName;
    this.userProfileupdate['profilePicURL'] = sessionStorage.getItem('propic');
    this.propic = sessionStorage.getItem('propic');
    sessionStorage.setItem('propic', this.propic)
    this.usertype = this.tokenStorage._get_userRole();

    this.email = this.activeRoute.snapshot.queryParamMap.get('Email');

    this.token = this.activeRoute.snapshot.queryParamMap.get('token');
    console.log(this.token)
    this.getPDFLinkdownloadList();
    console.log(this.updatepro.dirty);
  }



  _change_password() {
    console.log(this.changeList.currentPassword)
    console.log(this.tokenStorage._get_email())
    let changedata = {
      "email": this.tokenStorage._get_email(),
      "currentPassword": this.changeList.currentPassword,
      "newPassword": this.changeList.newPassword,
      "createdOn": this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    }
    this.auth_service._change_password_service(changedata).subscribe((data: any) => {
      console.log(data.errorMessage);

      if (data.reponse == true) {
        this.toastr.showSuccess("Password changed successfully, Login Again");
        this.route.navigate(['/login']);
      } else {
        this.toastr.showError(data.errorMessage)
      }
    })


  }
  getProileDetails() {
    console.log(this.UserGUI, 'profileeeee');
    this._manager_service.getProfileList(this.UserGUI).subscribe((resp: any) => {
      console.log(resp.getData, 'resp');
      this.userProfileupdate['firstName'] = resp.getData.firstName
      this.userProfileupdate['lastName'] = resp.getData.lastName
      this.userProfileupdate['phonenumber'] = resp.getData.phonenumber
      this.userProfileupdate['email'] = resp.getData.email
      this.userProfileupdate['dateofBirth'] = resp.getData.dateofBirth
      //console.log(this.userProfileupdate, 'listData');
    })
  }
  updateProfile() {

    // this.userProfileupdate['dateofBirth'] = this.datepipe.transform(this.userProfileupdate.dateofBirth._d, 'MM/dd/yyyy')?.toString();
    console.log(this.userProfileupdate.dateofBirth, 'dob')
    this.userProfileupdate['dateofBirth'] = this.userProfileupdate.dateofBirth
    this.userProfileupdate["createdOn"] = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm')

    this.auth_service.updateUser_profile(this.userProfileupdate).subscribe((updatedData: any) => {
      if (updatedData.reponse === true) {
        this.getProileDetails();
        this.toastr.showSuccess("Update profile SuccessFully");
        this.updatepro.form.markAsUntouched()
      } else {
        this.toastr.showError(updatedData.errorMessage);
        this.updatepro.form.markAsUntouched()
      }


      console.log(updatedData, " pro");

    }, err => {
      this.toastr.showError("Something went wrong")
    })

  }

  getPDFLinkdownloadList() {
    this.CurrentLoggedId = Number(this.getprofileData.CurrentLoggedUserId);
    console.log(this.CurrentLoggedId, 'currentId')
    this._manager_service.getPDFLink(this.CurrentLoggedId).subscribe((resp: any) => {
      console.log(resp, 'pdfLinker')
      this.getPdfLinkDownload = resp.extraData['Agreement']
      console.log(this.getPdfLinkDownload, 'pdfLinke2222222r')
    })

    this.profileUrlName = this.getPdfLinkDownload.split('/').pop().split('?')[0];
    console.log(this.profileUrlName, 'asdnam')
  }

  getPDFLinkdownload() {
    window.open(this.getPdfLinkDownload);
  }

  removepic() {
    let reqpayload = {
      "userId": Number(this.CurrentLoggedUserId),
      "profilePicURL": this.propic
    }

    // this.auth_service.remove_profilepicUrl(reqpayload).subscribe((data:any)=>{

    this.userProfileupdate['profilePicURL'] = "../../../assets/images/dp-1.png"
    this.propic = "../../../assets/images/dp-1.png"

    sessionStorage.setItem('propic', this.userProfileupdate['profilePicURL'])
    this.updateProfilePic(this.propic)
    // })
  }
  uploadFile(event: any) {
    this.auth_service.add_profilepicUrl(event.target.files[0]).subscribe((propic: any) => {
      console.log(propic.mutiplesFile['File']['DownloadPath']);
      this.propic = propic.mutiplesFile['File']['DownloadPath'];
      sessionStorage.setItem('propic', this.propic)
      this.updateProfilePic(this.propic)
    })
  }


  updateProfilePic(url?: any) {
    let propic = {
      "userId": parseInt(this.CurrentLoggedUserId),
      "profilePicURL": url
    }
    this.auth_service.update_profilepicUrl(propic).subscribe((data: any) => {
      this.toastr.showSuccess('Profilepic updated');
    })
  }
}
