<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Users"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop mld-edit">
        <div class="apxpage-title_wrap">
          <h2>Add / edit User List</h2>
          <button class="btn_to_btitle" routerLink="/superadmin/manager-list">back</button>
        </div>
      </div>

      <form #addEdit="ngForm" (ngSubmit)=addEdit.form.valid&&_create_manager()>
        <div class="pagecontent_wrapper">
          <div class="apex-white_wrap">
            <div class="apex-title-lilblue">
              <h3>basic details</h3>
            </div>

            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>First Name<sup>*</sup></label>
                    <input type="text" name="firstName" [(ngModel)]="addManager.firstName" #firstName="ngModel"
                      placeholder="First Name" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && firstName.invalid}" required />
                    <div *ngIf="addEdit.submitted && firstName.invalid" class="invalid-feedback">
                      <div *ngIf="firstName.errors?.required">Firstname is required</div>

                    </div>

                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Last Name<sup>*</sup></label>
                    <input type="text" name="lastName" [(ngModel)]="addManager.lastName" #lastName="ngModel"
                      placeholder="Last Name" value="" [ngClass]="{'is-invalid': addEdit.submitted && lastName.invalid}"
                      required />
                    <div *ngIf="addEdit.submitted && lastName.invalid" class="invalid-feedback">
                      <div *ngIf="lastName.errors?.required">Lastname is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  *ngIf="updateManager && myuserType == 'SuperAdmin'">
                  <div class="apexinput-froms">
                    <label>Password</label>
                    <input [type]="toogleConPassword ? 'text' : 'password'" name="password"
                      [(ngModel)]="addManager.password" #password="ngModel" />
                    <i (click)="clicktoogleConPassword()" class="fa fa-eye" aria-hidden="true"
                      style="margin: 0 0 0 -22px;"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Email Address<sup>*</sup></label>
                    <input type="email" name="emailId" [(ngModel)]="addManager.emailId" #emailId="ngModel"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" placeholder="Email Address" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && emailId.invalid}" required />
                    <div *ngIf="addEdit.submitted && emailId.invalid" class="invalid-feedback">
                      <div *ngIf=" emailId.errors?.required">Email is required</div>
                      <div *ngIf="emailId.errors?.pattern">Email must be a valid email address</div>
                    </div>
                  </div>
                  <div class="apexinput-froms mt_10">
                    <label>Second Email Address</label>
                    <input type="email" name="secondayEmailId" [(ngModel)]="addManager.secondayEmailId"
                      #secondayEmailId="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                      placeholder="Email Address" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && secondayEmailId.invalid}" />
                    <div *ngIf="addEdit.submitted && secondayEmailId.invalid" class="invalid-feedback">
                      <!-- <div *ngIf=" emailId.errors?.required">Email is required</div> -->
                      <div *ngIf="emailId.errors?.pattern">Email must be a valid email address</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Phone Number<sup>*</sup></label>
                    <input type="text" placeholder="Phone Number" mask="(000) 000-0000" id="phone" maxlength="15"
                      name="phone" [(ngModel)]="addManager.phone" #phone="ngModel" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && phone.invalid}" required />
                    <div *ngIf="addEdit.submitted && phone.invalid" class="invalid-feedback">
                      <div *ngIf="phone.errors?.required">Phone Number is required</div>
                      <div *ngIf="phone.errors?.pattern">Phone Number must be a valid</div>
                    </div>
                  </div>
                  <div class="apexinput-froms mt_10">
                    <label>Second Phone Number</label>
                    <input type="text" placeholder="Second Phone Number" mask="(000) 000-0000" id="secondayPhone"
                      maxlength="15" name="secondayPhone" [(ngModel)]="addManager.secondayPhone"
                      #secondayPhone="ngModel" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && secondayPhone.invalid}" />
                    <div *ngIf="addEdit.submitted && secondayPhone.invalid" class="invalid-feedback">

                      <div *ngIf="secondayPhone.errors?.pattern">Phone Number must be a valid</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>License NO. </label>
                    <input name="licenseNo" placeholder="License No" [(ngModel)]="addManager.licenseNo"
                      #licenseNo="ngModel" />


                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms" *ngIf="updateManager == false ;else elseblock10">
                    <label>Role <sup>*</sup></label>
                    <!-- [(ngModel)]="addManager.role" #role="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && role.invalid}" required -->
                    <div *ngIf="myuserType != 'Broker'">
                      <select name="role" (input)="onSelectRole($event)" [(ngModel)]="addManager.role"
                        #role="ngModel" [ngClass]="{'is-invalid': addEdit.submitted && role?.invalid}" required>
                        <option value="" selected disabled>---Select Role---</option>
                        <option *ngFor="let roles of optionsSelectRole" [value]="roles.link">{{roles.usertype}}</option>
                      </select>
                      <span *ngIf="addEdit.submitted && role?.invalid" class="invalid-feedback role">
                        <div *ngIf="role.errors?.required">Role is required</div>
                      </span>

                    </div>
                    <div *ngIf="myuserType == 'Broker'">
                      <select name="role"  (input)="onSelectRole($event)" [(ngModel)]="addManager.role"
                      #role="ngModel" [ngClass]="{'is-invalid': addEdit.submitted && role?.invalid}" required>
                        <option value="" selected disabled>---Select Role---</option>
                        <option value="/Agents/Add">Agent</option>
                        <!-- <option *ngFor="let roles of optionsSelectRole" [value]="roles.link">{{roles.usertype}}</option> -->
                      </select>
                      <div *ngIf="addEdit.submitted && role?.invalid" class="invalid-feedback">
                        <div *ngIf="role.errors?.required">Role is required</div>
                      </div>

                    </div>

                    <!-- <div *ngIf="addEdit.submitted && role.invalid" class="invalid-feedback">
                      <div *ngIf="role.errors?.required">Role is required</div>
                    </div> -->

                  </div>
                  <ng-template #elseblock10>
                    <div class="apexinput-froms">
                      <label>User Role </label>

                      <input name="UseruserTypeId" [(ngModel)]="UseruserTypeId" readonly>

                    </div>
                    <br>

      
                    <div class="apexinput-froms" *ngIf="this.myuserType == 'SuperAdmin'">
                      <label> Terms & Condition Accepted On </label>

                    <span id="trms">{{termsacceptedDate}}</span>

                    </div>
                  </ng-template>

                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms mt_10">
                    <label>Notes</label>
                    <input name="notes" placeholder="Notes" [(ngModel)]="addManager.notes" #notes="ngModel">
                  </div>
                </div>


              </div>
            </div>
            <div class="apexbm-wrapper">

              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb_10">
                <div class="apexinput-froms">
                  <label>Status</label>
                  <select name="active" id="active" [(ngModel)]="addManager.active" #active="ngModel"
                    [ngClass]="{'is-invalid': addEdit.submitted && active.invalid}" required>
                    <!-- <option disabled selected>Select the status</option> -->
                    <option [value]=true selected>Active</option>
                    <option [value]=false>InActive</option>
                  </select>
                  <div *ngIf="addEdit.submitted && active.invalid" class="invalid-feedback">
                    <div *ngIf="active.errors?.required">Status is required</div>
                  </div>
                </div>

              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="addManagerforBroker">


                <div class="apexinput-froms">
                  <label>Manager <sup>*</sup> </label>
                  <!-- <select name="managerId" [(ngModel)]="addManager.managerId" #managerId="ngModel"
                    [ngClass]="{'is-invalid': addEdit.submitted && managerId.invalid}" required>
                    <option disabled selected>Select Manager</option>
                    <option *ngFor="let item of agentListManager" [value]="item.managerId">{{item.managerName}}
                    </option>
                  </select> -->


                  <mat-form-field appearance="fill" class="manager-list-sel">
                    <!-- <mat-label>--Select--</mat-label>-->
                    <mat-select name="managerId" [(ngModel)]="addManager.managerId" #managerId="ngModel"
                      [ngModelOptions]="{standalone: true}"
                      [ngClass]="{'is-invalid': addEdit.submitted && managerId.invalid}" required>
                      <!-- <mat-option>--Select--</mat-option> -->
                      <input type="text" (keyup)="onSearchManager($event)"  (keydown)="$event.stopPropagation()">
                      <mat-option *ngFor="let item of agentListManager" [value]="item.managerId">
                        {{item.managerName}}
                      </mat-option>
                    </mat-select>
             
    
                 
                  </mat-form-field>

                  <div *ngIf="addEdit.submitted && managerIdvalue==false && !addManager.managerId" class="invalid">
                    <div>Manager is required</div>
                  </div>

                
                </div>


              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="agentTypeshow">
                <div class="apexinput-froms">
                  <label>Agent Type <sup>*</sup> </label>
                  <select [(ngModel)]='addManager.agentTypeId' (change)="agentTypeList($event)" name="agentTypeId"
                    #agentTypeId="ngModel" [ngClass]="{'is-invalid': addEdit.submitted && agentTypeId.invalid}"
                    required>
                    <option disabled selected>Select Agent Type</option>
                    <option *ngFor="let item of agentTypes" [value]="item.agentTypeId">{{item.agentTypeName}}
                    </option>
                  </select>
                  <div *ngIf="addEdit.submitted && agentTypeId.invalid" class="invalid-feedback">
                    <div *ngIf="agentTypeId.errors?.required">Agent Type is required</div>

                  </div>

                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="titleListShow">
                  <div class="apexinput-froms">
                    <label>Title</label>
                    <!-- <select name="title" id="title" [(ngModel)]="addManager.title" #title="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && title.invalid}" required>
                      <option value="">Select</option>
                      <option value="manager">manager</option>
                      <option value="Title-1">Title-1</option>
                    </select> -->
                    <input type="text" name="title" id="title" [(ngModel)]="addManager.title" #title="ngModel"
                      placeholder="Title" value="" />
                    <!-- <div *ngIf="addEdit.submitted && title.invalid" class="invalid-feedback">
                      <div *ngIf="title.errors?.required">Title is required</div>
                    </div> -->
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="companyNameShow">
                  <div class="apexinput-froms">
                    <label>Company Name <sup>*</sup> </label>
                    <input type="text" [(ngModel)]="addManager.companyName" #companyName="ngModel" name="companyName"
                      placeholder="Company name" [ngClass]="{'is-invalid': addEdit.submitted && companyName.invalid}"
                      required>
                    <div *ngIf="addEdit.submitted && companyName.invalid" class="invalid-feedback">
                      <div *ngIf="companyName.errors?.required">CompanyName is required</div>
                    </div>
                  </div>
                </div>


                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 wrapper_newfeatures mt_10"
                  *ngIf="getRoleval == '/Agents/Add'">

                  <div class="apexinput-froms">
                    <label>Website </label>
                    <input name="website" [(ngModel)]="addManager.website" #website="ngModel" />

                  </div>

                  <div class="apexinput-froms">
                    <label>Prefered Communication </label>

                    <select name="preferedCommunication" [(ngModel)]="addManager.preferedCommunication"
                      #preferedCommunication="ngModel">
                      <option value="text">Text</option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>

                    </select>

                  </div>
                  <div class="apexinput-froms">
                    <label>License NO. </label>
                    <input name="licenseNo" [(ngModel)]="addManager.licenseNo" #licenseNo="ngModel"/>


                  </div>
                </div> -->

                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 wrapper_newfeatures mt_10"
                  *ngIf="getRoleval == '/Agents/Add'"> -->

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="getRoleval == '/Agents/Add'">
                  <div class="apexinput-froms">
                    <label>Website </label>
                    <input name="website" [(ngModel)]="addManager.website" #website="ngModel" />

                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt_10" *ngIf="getRoleval == '/Agents/Add'">
                  <div class="apexinput-froms">
                    <label>Prefered Communication </label>
                    <!-- <input name="agentCompanyId" [(ngModel)]="addManager.preferedCommunication" #preferedCommunication="ngModel"/> -->
                    <select name="preferedCommunication" [(ngModel)]="addManager.preferedCommunication"
                      #preferedCommunication="ngModel">
                      <option value="text">Text</option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>

                    </select>

                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt_10" *ngIf="getRoleval == '/Agents/Add'">
                  <!-- <div class="apexinput-froms">
                    <label>License NO. </label>
                    <input name="licenseNo" [(ngModel)]="addManager.licenseNo" #licenseNo="ngModel"/>
                  </div> -->
                </div>
                <!-- </div> -->

                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 wrapper_newfeatures mt_10"
                  *ngIf="getRoleval == '/Brokers/Add'"> -->

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="getRoleval == '/Brokers/Add'">
                  <div class="apexinput-froms">
                    <label>Website </label>
                    <input name="agentCompanyId" [(ngModel)]="addManager.website" #website="ngModel" />
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt_10" *ngIf="getRoleval == '/Brokers/Add'">
                  <div class="apexinput-froms">
                    <label>Prefered Communication </label>
                    <!-- <input name="agentCompanyId" [(ngModel)]="addManager.preferedCommunication" #preferedCommunication="ngModel"/> -->
                    <select name="preferedCommunication" [(ngModel)]="addManager.preferedCommunication"
                      #preferedCommunication="ngModel">
                      <option value="text">Text</option>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>

                    </select>

                  </div>
                </div>
                <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt_10"   *ngIf="getRoleval == '/Brokers/Add'">
                  <div class="apexinput-froms">
                    <label>License NO. </label>
                    <input name="licenseNo" [(ngModel)]="addManager.licenseNo" #licenseNo="ngModel"/>

</div>
                  </div> -->

                <!-- </div> -->

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt_10"
                *ngIf="this.addManager.userTypeId == '3' || this.addManager.userTypeId == '4'">
                <div class="apexinput-froms">
                  <label>LOGIN AGREEMENT FILE</label>
  
                  <a *ngIf="addManager.agreementDownloadPath" [href]="addManager.agreementDownloadPath"
                    target="_blank">Download</a>
  
                </div>
              </div>


              </div>
            </div>

            <div class="apexforms-group" *ngIf="addManagerforAgent">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="addManager.agentTypeId == 1">
                  <div class="apexinput-froms">
                    <label>Manager <sup>*</sup></label>
                    <!-- <select name="agentManagerId" [(ngModel)]="addManager.agentManagerId"
                      (change)="agentManager($event)" #agentManagerId="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && agentManagerId.invalid}" required>
                      <option>Select Manager</option>
                      <option *ngFor="let item of agentListManager" [value]="item.managerId">{{item.managerName}}
                      </option>
                    </select> -->


                    <mat-form-field appearance="fill" class="manager-list-sel">
                      <!-- <mat-label>--Select--</mat-label>-->
                      <mat-select name="agentManagerId" [(ngModel)]="addManager.agentManagerId"
                        (selectionChange)="agentManager($event)" #agentManagerId="ngModel"
                        [ngClass]="{'is-invalid': addEdit.submitted && agentManagerId.invalid}" required>
                        <!-- <mat-option>--Select--</mat-option> -->
                        <input type="text" (keyup)="onSearchManager($event)" (keydown)="$event.stopPropagation()">
                        <mat-option *ngFor="let item of agentListManager" [value]="item.managerId">
                          {{item.managerName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    
                  <div *ngIf="addEdit.submitted && agentmanagerIdvalue==false && !this.addManager['agentManagerId']" class="invalid">
                    <div>Manager is required</div>
                  </div>
               
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="addManager.agentTypeId == 1">
                  <div class="apexinput-froms">
                    <label>Broker(Free text)</label>
                    <input type="text" name="Brokername" [(ngModel)]="addManager.brokerName" #brokerName="ngModel"
                      placeholder="Broker Name" value="" />

                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="addManager.agentTypeId == 2">
                  <div class="apexinput-froms">
                    <label>Broker</label>
                    <!-- <select name="agentCompanyId" [(ngModel)]="addManager.agentCompanyId" #agentCompanyId="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && agentCompanyId.invalid}" required>
                      <option disabled selected>Select Broker</option>
                      <option *ngFor="let item of agentListCompany" [value]="item.companyId">{{item.name}}
                      </option>
                    </select> -->


                    <mat-form-field appearance="fill" class="manager-list-sel">
                      <!-- <mat-label>--Select--</mat-label>-->
                      <mat-select [(ngModel)]="addManager.agentCompanyId" [ngModelOptions]="{standalone: true}"
                        #agentCompanyId="ngModel"
                        [ngClass]="{'is-invalid': addEdit.submitted && agentCompanyId.invalid}" required>
                        <!-- <mat-option>--Select--</mat-option> -->
                        <input type="text" (keyup)="onSearchUsers($event)" (keydown)="$event.stopPropagation()">
                        <mat-option *ngFor="let item of agentListCompany" [value]="item.companyId">
                          {{item.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>


                    <div *ngIf="addEdit.submitted && agentCompanyId.invalid" class="invalid-feedback">
                      <div *ngIf="agentCompanyId.errors?.required">Broker is required</div>
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div class="apexforms-group">
              <div class="row" *ngIf="addManager.agentTypeId == 1 || getRoleval == '/Brokers/Add'">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Company Address</label>
                    <input id="agreementAddress" name="agreementAddress" [(ngModel)]="addManager.agreementAddress"
                      #agreementAddress="ngModel">


                    <!-- <div *ngIf="addEdit.submitted && stateId.invalid" class="invalid-feedback">
                      <div *ngIf="stateId.errors?.required">State is required</div>
                    </div> -->
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt_10">
                  <div class="apexinput-froms">
                    <label>City</label>
                    <!-- <select id="city" name="city" [(ngModel)]="addManager.city" #city="ngModel">

                      <option *ngFor="let cities of cityList" [value]="cities.cityId">{{cities.cityName}}</option>
                    </select> -->
                    <input name="city" [(ngModel)]="addManager.city" #city="ngModel" />

                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>State</label>
                    <select id="state" name="state" [(ngModel)]="addManager.state" #state="ngModel"
                      (change)="dropdown_city($event)">

                      <option *ngFor="let states of stateList " [value]="states.stateName">{{states.stateName}}</option>
                    </select>

                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt_10">
                  <div class="apexinput-froms">
                    <label>ZipCode</label>
                    <input name="zipCode" [(ngModel)]="addManager.zipCode" #zipCode="ngModel" />
                  </div>
                </div>
              </div>
            </div>

       

            <div class="apxbtn_finalcenter">
              <!-- <button routerLink="/superadmin/manager-list" class="btn_grey">Cancel</button> -->

              <button type="reset" class="btn_grey client_remove">Cancel</button>

              <button class="btn_dblue">save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</div>