import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FileuploadService } from 'src/app/service/fileupload.service';
@Component({
  selector: 'app-login-history-detail',
  templateUrl: './login-history-detail.component.html',
  styleUrls: ['./login-history-detail.component.css'],
  providers: [DatePipe]
})
export class LoginHistoryDetailComponent implements OnInit {
  id: any;
  date: any;
  myDate: any = new Date();
  public detailData: any = [];
  filterObj: any = {
    "userId": 0,
    "days": 0,
    "fromdate": "",
    "todate": ""
  }
  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true
  };
  userName: any;
  constructor(private fileuploadservice:FileuploadService,private datePipe: DatePipe, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) {
    this.myDate = this.datePipe.transform(this.myDate, 'MM/dd/yyyy');
    console.log(this.myDate, "jeevitha")
  }


  ngOnInit(): void {
    /**GET ROUTE PARAM ID */
    // this.activated_route.params.subscribe((param: any) => {
    //   this.id = parseInt(param['id']);
    //   this.date = param['date'];
    //   if (param['id'] != null || undefined) {
    //     this.getdetailsByid(parseInt(param['id']), param['date'])

    //   }

    // });


    this.activated_route.queryParams.subscribe((param: any) => {
      this.id = parseInt(param['id']);
      this.filterObj['userId'] = this.id
      this.userName=param['userName']
      this.filterObj.userId = this.id;
      this.date = param['date'];
      if (param['id'] != null || undefined) {
        //this.getdetailsByid(parseInt(param['id']))
        this.getdetailsByid()

      }

    });


    // this.id = this.activated_route.snapshot.queryParamMap.get('id');
    // if (this.id !== null || undefined) {
    //   this.getdetailsByid(parseInt(this.id))
    // }
  }

  getdetailsByid() {
    this._manager_service.get_allUserLonginHistoryAuditbydate(this.filterObj).subscribe((res: any) => {
      // console.log(res)
 console.log("res ", res);
      this.detailData = res.listData ? res.listData: [];

    })
  }

  onReset() {
    this.filterObj = {
      "userId": this.id,
      "days": 0,
      "fromdate": "",
      "todate": ""
    }
    this.getdetailsByid();
  }

  // getdetailsByid(id: number) {
  //   this._manager_service.get_allUserLonginHistoryAuditbydate(id).subscribe((res: any) => {
  //     console.log(res)
  //     this.detailData = res.listData;

  //   })
  // }


  updateCalcs(event: any) {
    console.log(event.target.value, "dddd");
  }
  searchLoginHistoryview(searchkey: any) {
    this._manager_service.searchByuser(searchkey, this.myDate, this.id).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.detailData = [];
      }
      else {
        this.detailData = searchedData.listData;
      }
      //this.detailData = searchedData.listData;
      console.log(searchedData, "latest data")
    })
  }

  downloadExcelByUserId(){
    this.fileuploadservice.downloadAuditDataByUserId(this.filterObj);
  }
}
