import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/service/manager.service';
import { TokenService } from '../../service/token.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-task-list-view',
  templateUrl: './task-list-view.component.html',
  styleUrls: ['./task-list-view.component.css']
})
export class TaskListViewComponent implements OnInit {
  public clientTask: any;

  getCurrentList: any;
  constructor(private tokenStorage: TokenService, private _manager_service: ManagerService, private route: Router, private activated_route: ActivatedRoute) { }

  ngOnInit(): void {


    this.getCurrentList = window.history.state;
    console.log(this.getCurrentList.obj);
    this.clientTask = this.getCurrentList.obj;
    // this.state = this.activated_route.paramMap.pipe(
    //   map((data: any) => {
    //     console.log("hijijijij", window.history.state)
    //     console.log(data);
    //   })
    // );
    // this.getAllClientTask();
  }

  // getAllClientTask() {
  //   let CurrentUserId: any;
  //   CurrentUserId = this.tokenStorage._get_userTypeId();
  //   console.log(CurrentUserId, "hi cuser ")
  //   this._manager_service.get_allClientTask(CurrentUserId).subscribe((res: any) => {
  //     console.log(res, "ttd")
  //     //this.leadData = res.listData;
  //     if (res.listData == null) {
  //       this.clientTask = [];
  //     }
  //     else {
  //       this.clientTask = res.listData;

  //     }
  //   })
  // }

}
