export class ssProposal {

    shortSalesProposalId?: number;
    clientId?: number;
    tpcContractsalesPrice?: number;
    tpcCommission?: number;
    tpcAttorneyFee?: number;
    tpcSellerTitEscrowfee?: number;
    tpCsubLeinpay?: number;
    tpcRecordingFee?: number;
    tpcRealPropTax?: number;
    tpcRepair?: number;
    tpcBrrowRelocAssit?: number;
    tpcOthers?: number;
    tpcNettoServicer?: number;
    tpcMoneyDeposite?: number;
    tpcDownPay?: number;
    documentAttch?: string;
    hafaNetProceeds?: number;
    inAgmhIncome?: number;
    inAnmhIncome?: number;
    inAtotalMonthlyexp?: number;
    inAnetcashflow?: number;
    inAdeficit?: number;
    inAbackendDTI?: number;
    maCurrentValue?: number;
    maBalFirstMortage?: number;
    maMorPymts?: number;
    mAattorneyfee?: number;
    maEstcostSecProperty?: number;
    maEstMaintenance?: number;
    maTotalCost?: number;
    cofareoSales?: number;
    cofamonthlyMoratagePay?: number;
    cofamortagePayatSales?: number;
    cofaattorneyFees?: number;
    cofasecureProperty?: number;
    cofamaintenanceCost?: number;
    cofatotalCost?: number;
    fceltotalSales?: number;
    fcelbalanceof1stMortage?: number;
    fcelestLender?: number;
    fcelloss?: number;
    alacurrentValue?: number;
    alaforeCloseEstPrice?: number;
    alarealEstate?: number;
    alacommision?: number;
    alaestForeCloseCost?: number;
    alatotalSalesProceedLenders?: number;
    ssoaorginalAmount?: number;
    ssoacureRate?: number;
    ssoabpo?: number;
    ssoahomePriceForcasteDesc?: number;
    ssoareostigmaDiscount?: number;
    ssoaliquidationValue?: number;
    ssoaescrowAccumulatedFees?: number;
    ssoareodispositionValue?: number;
    ssoareoValue?: number;
    ssoashortSaleValue?: number;
    ssoainvBenifitAcceptingSs?: number;
    sssborrowers?: string;
    sssproperty?: string;
    sss1stLienLoan?: string;
    isActive?: boolean;

}