<div class="authguard_wrapper">
  <div class="authdescribe_wrap">
    <h2>Going Above and Beyond To Find Your Next Home</h2>
    <img src="../../../assets/images/auth_bgm.png" alt="bgm" />
  </div>
  <div class="authforms_wrap">
    <div class="authforms_guards">
      <img src="../../../assets/images/ISHORTSALE_LOGO_HORIZONTAL_RGB.png" alt="logo" />
      <h3>change password</h3>
      <span>Please Create your new password and continue the application</span>
      <form name="change_password" (ngSubmit)="change_password.form.valid && _change_password()"
        #change_password="ngForm">

        <div class="apex_auth_forms eyeapex_forms">
          <span><i class="fa fa-lock" aria-hidden="true"></i></span>

          <div class="apexauth_layer">
            <label>Current Password</label>


            <input [(ngModel)]="changeList.currentPassword" [type]="tooglerePassword ? 'text' : 'password'"
              placeholder="Current Password" name="currentPassword" #currentPassword="ngModel"
              [ngClass]="{'is-invalid': change_password.submitted && currentPassword.invalid}" required />
            <i (click)="clicktooglerePassword()" class="fa fa-eye" aria-hidden="true"></i>
            <div *ngIf="change_password.submitted && currentPassword.invalid" class="invalid-feedback">
              <div *ngIf=" currentPassword.errors?.required">currentPassword is Required</div>
            </div>

          </div>
        </div>
        <div class="apex_auth_forms eyeapex_forms">
          <span><i class="fa fa-lock" aria-hidden="true"></i></span>

          <div class="apexauth_layer">
            <label>Create Password</label>
            <input [(ngModel)]="changeList.createPassword" #createPassword="ngModel"
              [type]="tooglePassword ? 'text' : 'password'" placeholder="New Password" value="" name="createPassword"
              [ngClass]="{'is-invalid': change_password.submitted && createPassword.invalid}" required />
            <i (click)="clicktooglePassword()" class="fa fa-eye" aria-hidden="true"></i>
            <div *ngIf="change_password.submitted && createPassword.invalid" class="invalid-feedback">
              <div *ngIf=" createPassword.errors?.required">Password is Required</div>
            </div>



          </div>
        </div>

        <div class="apex_auth_forms eyeapex_forms">
          <span><i class="fa fa-lock" aria-hidden="true"></i></span>
          <div class="apexauth_layer">
            <label>Confirm New Password</label>
            <input ngValidateEqual="createPassword" [(ngModel)]="changeList.newPassword" #newPassword="ngModel"
              [type]="toogleConPassword ? 'text' : 'password'" placeholder="Confirm Password" value=""
              name="newPassword" [ngClass]="{'is-invalid': change_password.submitted && newPassword.invalid}"
              required />
            <div *ngIf="change_password.submitted && newPassword.invalid" class="invalid-feedback">
              <div *ngIf="newPassword.errors?.required">Confirm Password is Required</div>

              <div *ngIf="(newPassword.dirty || newPassword.touched) && newPassword.invalid">
                <p class="warning-text" *ngIf="newPassword.hasError('notEqual') && createPassword.valid">
                  Passwords Don't Match
                </p>
              </div>
            </div>

            <i (click)="clicktoogleConPassword()" class="fa fa-eye" aria-hidden="true"></i>
          </div>
        </div>
        <div class="apex_auth_submitprocess">
          <button class="btn-apex-primary">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>
