<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>

  <main class="page-content">
    <app-header headerMainTitle="Tasks">
    </app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>Task List</h2>
        </div>
      </div>
      <div class="pagecontent_wrapper">


        <div class="apex-white_wrap pd-0">
          <div class="apx_clients_wrapper">
            <div class="apx_clients_title">
              <h2>List of Task</h2>

            </div>
            <div class="apx_clients-filemangement">
              <div class="apx_client-lister-details" *ngFor="let items of clientTask">
                <div class="apx_client-file-contents" id="apx_client-file-contents">
                  <div class="apx-new">
                    <div
                      class="apx_client-content-grid d-flex flex-filescontent apx_client-file-status clientfile_Status clientdesstatus">
                      <img src="../../../assets/images/clients/files.png" alt="icon" />
                      <div class="apxfile_index_names">
                        <p>{{ items.fileNo }}</p>
                        <span>{{ items.clientName }}</span>
                      </div>
                    </div>
                    <div class="apx_client-file-status clientfile_Status clientdesstatus">
                      <div class="apx_client-content-grid"><label>{{items.usertypename}}</label>
                        <span>{{items.username}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="apx-new" id="apex-new">
                    <div class="apx_client-file-status clientfile_Status clientdesstatus">
                      <div class="apx_clients-file-grid">
                        <label>Current Status</label>
                        <span class="fileapxstatus">{{items.fileStatus}}</span>
                      </div>
                      <div class="apx_clients-file-grid">
                        <label>Assigned Date</label>
                        <span>{{items.assignedDate}}</span>
                      </div>
                      <div class="apx_clients-file-grid">
                        <label>Due Date</label>
                        <span>{{items.completionDate}}</span>
                      </div>
                      <div class="apx_clients-file-grid">
                        <label>Specific File Tab</label>
                        <span>{{items.specificFileTab}}</span>
                      </div>
                      <div class="apx_clients-file-grid">
                        <label>Priority</label>
                        <span>{{items.priority}}</span>
                      </div>
                      <div class="apx_clients-file-grid width-100">
                        <label>Task</label>
                        <span>{{items.description}}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </main>

</div>
