//modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


// components
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ManagerListComponent } from './manager-list/manager-list.component';
import { ManagerListEditComponent } from './manager-list-edit/manager-list-edit.component';
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { BrokersListComponent } from './brokers-list/brokers-list.component';
import { BrokersListEditComponent } from './brokers-list-edit/brokers-list-edit.component';
import { AgentsDetailComponent } from './agents-detail/agents-detail.component';
import { AgentsDetailEditComponent } from './agents-detail-edit/agents-detail-edit.component';
import { HouseownerDetailComponent } from './houseowner-detail/houseowner-detail.component';
import { HouseownerDetailEditComponent } from './houseowner-detail-edit/houseowner-detail-edit.component';
import { NegotiatorsListComponent } from './negotiators-list/negotiators-list.component';
import { NegotiatorsEditComponent } from './negotiators-edit/negotiators-edit.component';
import { AssitnegotiatorsListComponent } from './assitnegotiators-list/assitnegotiators-list.component';
import { AssitnegotiatorsEditComponent } from './assitnegotiators-edit/assitnegotiators-edit.component';
import { CloserListComponent } from './closer-list/closer-list.component';
import { CloserEditComponent } from './closer-edit/closer-edit.component';
import { LeadAgentLoaComponent } from './lead-agent-loa/lead-agent-loa.component'
import { LeadListComponent } from './lead-list/lead-list.component';
import { LeadListEditComponent } from './lead-list-edit/lead-list-edit.component';
import { LeadAcceptedListComponent } from './lead-accepted-list/lead-accepted-list.component';
import { LeadAcceptedEditComponent } from './lead-accepted-edit/lead-accepted-edit.component';
import { LeadRejectedListComponent } from './lead-rejected-list/lead-rejected-list.component';
import { LeadRejectedEditComponent } from './lead-rejected-edit/lead-rejected-edit.component';
import { LoginAgreementComponent } from './login-agreement/login-agreement.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientSignedLoaComponent } from './client-signed-loa/client-signed-loa.component';
import { ClientFileStatusComponent } from './client-file-status/client-file-status.component';
import { ClientReassignTaskComponent } from './client-reassign-task/client-reassign-task.component';
import { ClientAssignTaskComponent } from './client-assign-task/client-assign-task.component';
import { ManageAutoGenComponent } from './manage-auto-gen/manage-auto-gen.component';
import { ManageAutoEditComponent } from './manage-auto-edit/manage-auto-edit.component';
import { ReportShortSalesComponent } from './report-short-sales/report-short-sales.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskListViewComponent } from './task-list-view/task-list-view.component';
import { TaskdetailsComponent } from './taskdetails/taskdetails.component';
import { ManageNotificationComponent } from './manage-notification/manage-notification.component';
import { ManageVideoComponent } from './manage-video/manage-video.component';
import { ManageUploadComponent } from './manage-upload/manage-upload.component';
import { LoginHistoryComponent } from './login-history/login-history.component';
import { ConfiguratinSettingComponent } from './configuratin-setting/configuratin-setting.component';
import { TaskTemplateComponent } from './task-template/task-template.component';
import { TaskTemplateDetailComponent } from './task-template-detail/task-template-detail.component';
import { LoginHistoryDetailComponent } from './login-history-detail/login-history-detail.component';
import { LoginAuthenticationComponent } from './login-authentication/login-authentication.component';

import { CancelFileReportComponent } from './cancel-file-report/cancel-file-report.component';
import { SuccessRateReportComponent } from './success-rate-report/success-rate-report.component';
import { TrendReportComponent } from './trend-report/trend-report.component';
import { UserUsageReportComponent } from './user-usage-report/user-usage-report.component';
import { FilesperforClosureReportComponent } from './filesperfor-closure-report/filesperfor-closure-report.component';
import { ClosedFileReportComponent } from './closed-file-report/closed-file-report.component';
import { OpencloseReportComponent } from './openclose-report/openclose-report.component';
import { NumberoffileReportComponent } from './numberoffile-report/numberoffile-report.component';
import { ContactusComponent } from './contactus/contactus.component';
import { UserUsageDateReportComponent } from './user-usage-date-report/user-usage-date-report.component';
import { TaskCompleteListComponent } from './task-complete-list/task-complete-list.component';
import { AssignedToAgentComponent } from './assigned-to-agent/assigned-to-agent.component'
import { ChangePasswordComponent } from './change-password/change-password.component'
import {CheckFormService} from  '../../app/gurad/check-form.service' //form chck while route
import { CanDeactivateGuard } from '../can-deactivate.guard';
import { LeadSuccessRateReportComponent } from './lead-success-rate-report/lead-success-rate-report.component';
import { FinancialReportComponent } from './financial-report/financial-report.component';
import { UserReportComponent } from './user-report/user-report.component';

import { FileDataReportComponent } from './file-data-report/file-data-report.component';
import { BrokerShortSaleComponent } from './broker-short-sale/broker-short-sale.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { LeadDisqualifiedListComponent } from './lead-disqualified-list/lead-disqualified-list.component';
import { LeadDisqualifiedListEditComponent } from './lead-disqualified-list-edit/lead-disqualified-list-edit.component';
import { AuthGuardService } from "../service/auth-guard.service"


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'dashboard', component: DashboardComponent , canActivate:[AuthGuardService] },
  { path: 'manager-profile', component: ManageProfileComponent ,  canDeactivate: [CanDeactivateGuard ],},
  { path: 'manager-list', component: ManagerListComponent },
  { path: 'manager-list-edit', component: ManagerListEditComponent   , canDeactivate: [CanDeactivateGuard ],},
  { path: 'brokers-list', component: BrokersListComponent },
  { path: 'brokers-list-edit', component: BrokersListEditComponent , canDeactivate: [CanDeactivateGuard ],},
  { path: 'agents-details', component: AgentsDetailComponent, canDeactivate: [CanDeactivateGuard ], },
  { path: 'agents-details-edit', component: AgentsDetailEditComponent , canDeactivate: [CanDeactivateGuard ],},
  { path: 'houseowner-detail-list', component: HouseownerDetailComponent },
  { path: 'houseowner-detail-edit', component: HouseownerDetailEditComponent, canDeactivate: [CanDeactivateGuard ], },
  { path: 'negotiators-list', component: NegotiatorsListComponent, canDeactivate: [CanDeactivateGuard ], },
  { path: 'negotiators-edit', component: NegotiatorsEditComponent , canDeactivate: [CanDeactivateGuard ],},
  { path: 'assitnegotiators-list', component: AssitnegotiatorsListComponent,  },
  { path: 'assitnegotiators-edit', component: AssitnegotiatorsEditComponent, canDeactivate: [CanDeactivateGuard ], },
  { path: 'closer-list', component: CloserListComponent },
  { path: 'closer-edit', component: CloserEditComponent, canDeactivate: [CanDeactivateGuard ], },
  { path: 'lead-loa-list', component: LeadAgentLoaComponent },
  { path: 'assigned-to-agent', component: AssignedToAgentComponent },
  { path: 'lead-loa-list/:id', component: LeadAgentLoaComponent ,canDeactivate: [CanDeactivateGuard ], },
  { path: 'lead-list', component: LeadListComponent },
  { path: 'lead-list-edit', component: LeadListEditComponent , canDeactivate: [CanDeactivateGuard ]},
  { path: 'lead-list-edit/:id', component: LeadListEditComponent, canDeactivate: [CanDeactivateGuard ] },
  { path: 'lead-accepted-list', component: LeadAcceptedListComponent },
  { path: 'lead-disqualified-list', component: LeadDisqualifiedListComponent },
  { path: 'lead-disqualified-list-edit', component: LeadDisqualifiedListEditComponent },
  { path: 'lead-disqualified-list-edit/:id', component: LeadDisqualifiedListEditComponent },
  { path: 'lead-accepted-edit', component: LeadAcceptedEditComponent },
  { path: 'lead-accepted-edit/:id', component: LeadAcceptedEditComponent,canDeactivate: [CanDeactivateGuard ] },
  { path: 'lead-rejected-list', component: LeadRejectedListComponent },
  { path: 'lead-rejected-list-edit', component: LeadRejectedEditComponent,canDeactivate: [CanDeactivateGuard ] },
  { path: 'lead-rejected-list-edit/:id', component: LeadRejectedEditComponent,canDeactivate: [CanDeactivateGuard ] },
  { path: 'login-agreement', component: LoginAgreementComponent },
  { path: 'two-factor-authentication', component: LoginAuthenticationComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'clients-signed-loa', component: ClientSignedLoaComponent, canDeactivate: [CanDeactivateGuard ] },
  // { path: 'clients-signed-loa/:id', component: ClientSignedLoaComponent },
  { path: 'client-file-status', component: ClientFileStatusComponent },
  { path: 'client-file-status/:id', component: ClientFileStatusComponent },

  { path: 'client-reassign-task', component: ClientReassignTaskComponent,canDeactivate: [CanDeactivateGuard ] },
  { path: 'client-reassign-task/:id', component: ClientReassignTaskComponent,canDeactivate: [CanDeactivateGuard ] },
  { path: 'client-assign-task', component: ClientAssignTaskComponent },
  { path: 'contact-us', component: ContactusComponent },
  { path: 'manage-auto-gen', component: ManageAutoGenComponent },
  { path: 'manage-auto-edit', component: ManageAutoEditComponent ,canDeactivate: [CanDeactivateGuard ],},
  { path: 'report-short-sales', component: ReportShortSalesComponent },
  { path: 'broker-report-short-sales', component: BrokerShortSaleComponent },
  { path: 'task-list', component: TaskListComponent },
  { path: 'task-completed-list', component: TaskCompleteListComponent },

  { path: 'task-list-view', component: TaskListViewComponent },
  { path: 'task-details', component: TaskdetailsComponent , canDeactivate: [CanDeactivateGuard ],},
  
  { path: 'manage-notification', component: ManageNotificationComponent , 
  canDeactivate: [CanDeactivateGuard ],
},
  { path: 'manage-video', component: ManageVideoComponent },
  { path: 'manage-upload', component: ManageUploadComponent, canDeactivate: [CanDeactivateGuard ], },
  { path: 'login-history', component: LoginHistoryComponent },
  // { path: 'login-history-detail/:id/:date', component: LoginHistoryDetailComponent },
  { path: 'login-history-detail', component: LoginHistoryDetailComponent },
  { path: 'configuration-setting', component: ConfiguratinSettingComponent , canDeactivate: [CanDeactivateGuard ],},
  { path: 'task-template', component: TaskTemplateComponent },
  { path: 'task-template-detail', component: TaskTemplateDetailComponent , canDeactivate: [CanDeactivateGuard ],},
  { path: 'task-template-detail/:id', component: TaskTemplateDetailComponent, canDeactivate: [CanDeactivateGuard ], },
  { path: 'cancel-file-report', component: CancelFileReportComponent },
  { path: 'success-rate-report', component: SuccessRateReportComponent },
  { path: 'lead-success-rate-report', component: LeadSuccessRateReportComponent },
  { path: 'trend-report', component: TrendReportComponent },
  { path: 'user-usage-report', component: UserUsageReportComponent },
  { path: 'filesperfor-closure-report', component: FilesperforClosureReportComponent },
  { path: 'closed-file-report', component: ClosedFileReportComponent },
  
  { path: 'openclose-report', component: OpencloseReportComponent },
  { path: 'numberoffile', component: NumberoffileReportComponent },
  { path: 'user-usage-date-report', component: UserUsageDateReportComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'financial-reportt', component: FinancialReportComponent },
  { path: 'user-report', component: UserReportComponent },
  { path: 'file-data-report', component: FileDataReportComponent },
  { path: 'terms-condition', component: TermsAndConditionComponent },
  
  

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers:[CheckFormService]
})
export class SuperadminRoutingModule { }
