import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';

import { TokenService } from '../../service/token.service';

import { DropdownService } from 'src/app/service/dropdown.service';
import { FileuploadService } from 'src/app/service/fileupload.service'
@Component({
  selector: 'app-agents-detail',
  templateUrl: './agents-detail.component.html',
  styleUrls: ['./agents-detail.component.css']
})
export class AgentsDetailComponent implements OnInit {

  public manageData: any = [];
  order: string = 'managersFirstname';
  p: number = 1;
  reverse: boolean = false;
  agentList: any = [];
  deleteData: any;
  usertype: any;
  userListOptions: any;
  statusListOptions: any;
  filterRequest: any = {};
  constructor(private managerService: ManagerService, private tokenStorage: TokenService, private dropDownService: DropdownService, private fileupload: FileuploadService) {

  }

  ngOnInit(): void {
    this.usertype = this.tokenStorage._get_userRole();
    this.getfilter_details();
    this._getAgent_records();
  }


  _getAgent_records() {
    this.managerService._get_All_agent().subscribe((data: any) => {
      this.agentList = data.listData;
      console.log(this.agentList)
    })
  }

  getFilterData() {
    console.log(this.filterRequest)
    this.filterRequest.status = parseInt(this.filterRequest.status);
    this.dropDownService._getAll_agent_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.agentList = filteredData.listData;
    });
  }

  //**Get Details for Filter */
  getfilter_details() {
    this.dropDownService._getAll_agent_UserList().subscribe((userList: any) => {
      console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
    })
    this.dropDownService._getAll_agent_StatusList().subscribe((statusList: any) => {
      console.log(statusList.listData, "sji")
      this.statusListOptions = statusList.listData;
    })
  }
  //**End */

  setId(data: any) {
    this.deleteData = data;
  }
  _delete_agents() {

    this.managerService._delete_agent(this.deleteData).subscribe(deleteData => {
      console.log(deleteData)
      this._getAgent_records();
    })


  }


  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }


  downloadExcel() {
    this.fileupload.download_agent();
  }

}
