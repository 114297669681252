

export class taskmodel {

    clientId: number = 0;
    assignedUserId: number = 0;
    assignedTabs: string = "";
    completionDate: string = "08-25-2020";
    priority: string = "";
    taskTemplateId: number = 0;

}
