import { Component, OnInit, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { AuthServiceService } from '../../../service/auth-service.service';
import { TokenService } from '../../../service/token.service';
import { NotificationService } from '../../../service/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from '../../../service/manager.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastService } from 'src/app/service/toast.service';
import { NotesService } from 'src/app/service/notes.service';


// import * as moment from 'moment-timezone';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  //notes

  selectedItems: any = [];
  cities: any = [];

  dropdownSettings: any = {};
  closeDropdownSelection = false;
  disabled = false;
  notesForm: FormGroup;
  messageForm: FormGroup;
  public submitted = false;
  today: Date = new Date();
  timedifference: any;
  //notes

  public noficationshow: boolean = false;
  public messageNoticationshow: boolean = false;
  public notificationList: any;
  public notificationCount: any;

  //notes
  public selectfileArray: any;
  public selectuserTypeArray: any;
  selectFileData: any = [];
  SelectuserTypeData: any;
  userTypeid: any;
  UserTypeData: any = [];
  conversions: any = [];
  MessageArray: any = [];
  MessageData: any = [];
  allconversion: any = [];
  allconversionData: any = [];
  toUserType: any;
  TotalData: any = [];
  replyData: any = [];
  data: any;
  notesHistory: any;
  //notes
  notesHistoryOfAll: {
    broker: [],
    agent: [],
    nego: [],
    assnego: [],
    homeowner: [],
    closer: [],
    manager: []
  }

  public readnotify: any;
  public helpnotify: boolean | undefined;
  public importantMessage: any = [];
  isImportant: Boolean = false;
  public notificationLead: any;
  @Input() headerMainTitle: string | undefined;
  profilepicUrl: any;
  public usertype: any;
  profileName: any;
  public notifiClearAll: any = [];
  //notes
  currentlogindata: any;
  currentuseruniqueId: any;
  ConversionId: any;
  Conversionid: any;
  toUser: any;
  messageBody: any;
  sendUser: any;
  clientId: any;
  createdBy: any;
  notesId: any;
  activeTitle: any;
  tabName: any;
  notesList: any = [];
  countslist: any = [];
  sUser: any;
  //notes



  constructor(private toastrservice: ToastService, private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private notesService: NotesService,
    private formBuilder: FormBuilder, private route: Router, private managerService: ManagerService, private auth: AuthServiceService, private _notificationService: NotificationService, private tokenStorage: TokenService) {
    this.notesForm = this.formBuilder.group({
      clientId: ['', [Validators.required]],
      messageBody: ['', [Validators.required]],
      toUserType: ['']
    });
    this.messageForm = this.formBuilder.group({
      clientId: ['', [Validators.required]],
      messageBody: ['', [Validators.required]],
    });
    let currentdata: any;

    currentdata = sessionStorage.getItem('userInfo')
    this.currentlogindata = JSON.parse(currentdata)
    this.currentuseruniqueId = this.currentlogindata?.UserGUID;
    // this.currentuseruniqueId = currentdata.UserGUID;
  }


  ngOnInit(): any {




    // return false; 
    this.getAllNotes();
    // return false;
    // this.timedifference = moment.tz.guess();
    this.usertype = this.tokenStorage._get_userRole();
    this.profilepicUrl = sessionStorage.getItem('propic');
    this.profileName = sessionStorage.getItem('proname');
    // console.log(usertype, "UserRole");
    // let CurrentUserId: any;
    // CurrentUserId = this.tokenStorage._get_userTypeId();
    this.getAllNotificationByUser();
    //Notes
    this.getSelectfile();
    // this.getuserypeName();
    // this.getMessagelist();
    this.getnotesList();

    this.dropdownSettings = {
      singleSelection: true,
      // text:"Select File",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,

      labelKey: "fileno",
      primaryKey: "clientId",
      showCheckbox: false,
    classes:""

      // limitSelection:true
    };



  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  ngAfterViewInit() {



    // const results = this.selectFileData.filter(({ clientId: id1,
    //  }) => this.notesList.some(({ clientId: id2 }) => id2 === id1));

    // console.log("results",results);
  }

  onItemSelect(item: any) {
    console.log('item', item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  getAllNotes() {
    let currentuserid = sessionStorage.getItem('userTypeId');
    this.notesService.getNotes(currentuserid).subscribe((res: any) => {
      console.log("res notes", res);
      if(res.listData){
        this.notesList = res.listData;
        // this.sUser=this.notesList[0]?.sendUser
      }
      
   })
  }

  onSubmitNotes(formValues: object) {
    console.log(formValues)
  }
  // reply() {
  //   this.ConversionId = this.MessageData[0].conversionId
  //   this.managerService.getAllbyConversionId(this.ConversionId).subscribe((data: any) => {
  //     this.replyData = data;
  //     this.TotalData = this.replyData.getData.conversions;
  //     console.log(this.TotalData, "--->");
  //     this.clientId = this.replyData.getData.clientId;
  //     this.messageForm.controls.clientId.setValue(this.replyData.getData.clientId);
  //     console.log(this.replyData.getData.clientId, "clientId <--");
  //     console.log(this.TotalData)
  //   })
  // }

  // Notesreply(data: any) {
  //   $("#notespopup").modal("hide");
  //   this.ConversionId = data.conversionId
  //   this.managerService.getAllbyConversionId(this.ConversionId).subscribe((data: any) => {
  //     this.replyData = data;
  //     this.TotalData = this.replyData.getData.conversions;
  //     console.log(this.TotalData, "--->");
  //     this.clientId = this.replyData.getData.clientId;
  //     this.messageForm.controls.clientId.setValue(this.replyData.getData.clientId);
  //     console.log(this.replyData.getData.clientId, "clientId <--");
  //     console.log(this.TotalData)
  //     setTimeout(() => {
  //       this.messageForm.reset();
  //     }, 1000);
  //   })
  // }
  //Allconversation
  getnotesList() {
    // let ConversionId = sessionStorage.getItem('userTypeId');
    // debugger;
    console.log("this.MessageData ", this.MessageData);
    this.ConversionId = this.MessageData[0] ? this.MessageData[0].conversionId : 0
    this.managerService.getAllbyConversionId(this.ConversionId).subscribe((data: any) => {
      this.allconversion = data;
      console.log(this.allconversion)
      if (this.allconversion.reponse == true) {
        this.allconversionData = this.allconversion.getData.conversions;
        this.clientId = this.allconversion.getData.clientId;
        this.notesId = this.allconversion.getData.notesId;
        console.log(this.notesId);
        this.Conversionid = this.allconversion.getData.conversions[0].conversionId;
        this.toUser = this.allconversion.getData.conversions[0].sendUser;
        this.sendUser = this.allconversion.getData.conversions[0].toUser;
        this.messageBody = this.allconversion.getData.conversions[0].messageBody;
        this.createdBy = this.currentlogindata['CurrentLoggedUserId'],
          console.log(this.replyData.getData.clientId, "------>this.replyData.getData.clientId");
        console.log(this.replyData.getData, "-----------------------")
        this.messageForm.controls['clientId'].setValue(this.clientId);
        console.log("ClientId => reply", this.clientId);
        console.log(this.toUser);
        console.log(this.sendUser);
      }

    })
  }
  getMessagelist() {
    this.currentuseruniqueId = this.currentlogindata?.UserGUID;

    this.managerService.getAllnotificationByUserId(this.currentuseruniqueId).subscribe((data: any) => {
      this.MessageArray = data;
      this.MessageData = this.MessageArray.listData[0]?.conversions
      this.ConversionId = this.MessageData ? this.MessageData[0].conversionId : null;
      console.log(this.ConversionId);
    })
  }


  getSelectfile() {
    let currentuserid = sessionStorage.getItem('userTypeId');
    this.managerService.getAllClientbydropdown(currentuserid).subscribe((data: any) => {
      this.selectfileArray = data;

      this.selectFileData = this.selectfileArray.listData;


      console.log(this.selectFileData,"hi");


    });



  }



  getuserypeName(clientid: number) {
    let currentuserid = sessionStorage.getItem('userTypeId')
    this.managerService.getAllUserTypebydropdown(clientid).subscribe((data: any) => {
      console.log(currentuserid);
      this.selectuserTypeArray = data;
      // this.SelectuserTypeData = this.selectuserTypeArray.listData;
      this.SelectuserTypeData = this.selectuserTypeArray.listData.filter((list: any) => list.userTypeId != this.currentlogindata.UserTypeId);
      this.UserTypeData = [];
      this.notesForm.value.toUserType = "";
      this.notesForm.controls.toUserType.setValue('');
      this.notesForm.controls.messageBody.setValue('');
      this.notesForm.value.messageBody = '';
      console.log(this.SelectuserTypeData, "sampleUserType data");
      if (this.SelectuserTypeData.length == 0) {
        this.toastrservice.showInformation("No User Assigned")
      }
    })
  }



  selectUserType(event: any, usertypeid: any) {
    // this.userTypeid =  usertypeid.userTypeId;
    if (event.target.checked) {
      let createdBy = sessionStorage.getItem('userTypeId')
      console.log(createdBy);
      console.log(event.target.value)
      this.userTypeid = usertypeid.userUniqueId;

      this.UserTypeData.push(this.userTypeid);
      console.log(this.UserTypeData, "Second userTypeData");
    }
    else {
      this.UserTypeData.pop(this.userTypeid);
      console.log(this.UserTypeData);
    }
    console.log("this.UserTypeData ", this.UserTypeData);

    if (this.UserTypeData.length == 0) {
      this.notesForm.controls.toUserType.setValue('');
    }

    //  this.UserTypeData.push(this.userTypeid);
    //  console.log(this.UserTypeData);
    //  let userTypedata.push()
    // this.toUserType = {
    //   "toUserType":usertypeid.userTypeId

    // }
    //  console.log(this.toUserType);
    //  this.UserTypeData.push(this.toUserType);
    //  console.log(this.UserTypeData);

  }



  getAllNotificationByUser() {
    let important: any;
    this._notificationService.getAllNotify().subscribe((getnotifydata: any) => {

      this.notificationList = getnotifydata.listData;
      console.log(this.notificationList, 'NotiData')
      console.log(getnotifydata.listData.length, 'NotiData-fil')
      var count = getnotifydata.listData.filter((item: any) => {
        return item.isReaded == false ? true : false;
      })
      var lengthNoti = getnotifydata.listData.length;
      //this.notifiClearAll = [];
      for (let i = 0; i < lengthNoti; i++) {
        this.notifiClearAll.push({
          'notifydetailsId': getnotifydata.listData[i].notifydetailsId,
          'isReaded': true

        })

      }

      console.log("notifiClearAll ", this.notifiClearAll);
      console.log("count ", count);
      this.notificationCount = count.length;

      // for(var i=0; i< this.notificationCount;i++ ){
      //   if( this.notificationList[i].isImportant == true){
      //     important = true;
      //     this.importantMessage.push( this.notificationList[i])
      //   //  console.log(important,"imp notify")
      //   }

      // }
      //  this.isImportant= important;

      //  if(this.isImportant == true && sessionStorage.getItem('user_Role') !== 'SuperAdmin'){
      //    alert("Alert notification")
      //   // $("#impnotify").modal('show');

      //  }else{
      //   // $("#impnotify").modal('hide');
      //  }
      // console.log(this.importantMessage,this.isImportant,"imp notify")


      // let lengthArray = getnotifydata.listData.length;
      // for (var i = 0; i <= lengthArray; i++) {
      //   if (getnotifydata.listData[i].isReaded == 'false') {
      //     this.helpnotify = true;
      //     console.log(i, 'true');
      //     break;
      //   } else {
      //     this.helpnotify = false;
      //     //alert('fasle');
      //   }
      // }
    })
  }
  clearallNotifi() {
    this._notificationService.updateReadnotify(this.notifiClearAll).subscribe((readSinglenotify: any) => {
      console.log(readSinglenotify, 'BulkData');
      if (readSinglenotify.reponse == true) {
        this.getAllNotificationByUser();
        this.closeButton();
      }
      else {

      }

    })
  }
  notifyreaded(readId: number) {
    this._notificationService.updateReadSinglenotify({ 'notifydetailsId': readId, 'isReaded': true, "isImportant": false, }).subscribe((readSinglenotify: any) => {
      console.log(readSinglenotify, 'singleData');
      this.getAllNotificationByUser();
    })

  }



  // getupdateReadSinglenotify() {

  // }

  bellButton() {
    this.noficationshow = !this.noficationshow
  }
  bellMsgButton() {
    this.messageNoticationshow = !this.messageNoticationshow
  }
  closeButton() {
    this.noficationshow = false;
  }
  closebutton() {
    this.messageNoticationshow = false;
  }

  logout() {
    this.auth.logout(this.currentlogindata.UserGUID);
    // this.route.navigateByUrl('/login');
  }


  onSubmit(): any {
    this.submitted = true;
    if (!this.notesForm.valid) {
      return false;
    }
    this.conversions = [];
    console.log(this.UserTypeData, "UserTypeData");
    this.UserTypeData.forEach((element: any) => {
      let userid = {
        "toUserType": element,
        "messageBody": this.notesForm.value.messageBody,
      }
      this.conversions.push(element);
    });
    console.log("this.notesForm.value ", this.notesForm.value);
    if (this.UserTypeData.length == 0) {
      this.UserTypeData.push(this.currentlogindata.UserGUID)
    }
    let reqPayload = {
      "clientId": parseInt(this.notesForm.value.clientId),
      "currentuserid": sessionStorage.getItem('userTypeId'),
      "sendUser": this.currentuseruniqueId,
      "toUser": this.UserTypeData,
      "createdBy": this.currentlogindata['CurrentLoggedUserId'],
      "createdOn": this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm'),
      // "createdBy"  :  this.currentlogindata['CurrentLoggedUserId'],
      // "conversions": this.conversions,
      "message": this.notesForm.value.messageBody,

      // "tabsId": 0,
      // "isActive": true,
    }
    console.log(JSON.stringify(reqPayload), "reqPayload");
    this.managerService.getSendReplyNotes(reqPayload).subscribe(res => {
      console.log(res);
      $("#notespopupNew").modal("hide");
      this.submitted = false;
      this.toastrservice.showSuccess('Notes Sent Successfully');
      this.getAllNotes();
      this.getSelectfile()
      // setTimeout(() => {
      //   // this.notesForm.reset();
      //   this.notesHistory = [];
      // }, 1000);
    },
      (error) => {
        console.log(error)
      });
  }

  resetNotes() {
    this.notesForm.reset();
    this.notesHistory = [];
  }
  // OLd Function for notes
  onsubmitList() {
    this.submitted = true;
    this.conversions = [];
    console.log(this.UserTypeData, "UserTypeData");
    let userid = {
      "Conversionid": this.TotalData[0].conversionId,
      "messageBody": this.messageForm.value.messageBody,
      "sendUser": this.TotalData[0].toUser,
      "toUser": this.TotalData[0].sendUser,
    }
    this.conversions.push(userid);
    console.log(this.conversions);

    let reqPayload = {
      "notesId": this.replyData.getData.notesId,
      "clientId": parseInt(this.messageForm.value.clientId),
      // "currentuserid": sessionStorage.getItem('userTypeId'),
      "tabsId": 0,
      "isActive": true,
      "createdBy": this.currentlogindata['CurrentLoggedUserId'],
      "conversions": this.conversions,
      "messageBody": this.messageForm.value.messageBody,
    }
    console.log(JSON.stringify(reqPayload), "reqPayload");
    this.managerService.getSendReplyNotes(reqPayload).subscribe(res => {
      console.log(res);
      this.getMessagelist();

      $("#replypopup").modal("hide");
    },
      (error) => {
        console.log(error)
      });
  }

  // ** -- OLD Function for selectFile for Notes **/
  // selectClientFile(e: any) {
  //   console.log(e.target.value)

  //   let noteObj = {

  //     "clientId": parseInt(e.target.value),
  //     "sendUserId": this.currentuseruniqueId
  //   }

  //   this.getuserypeName(e.target.value)

  //   this.managerService.getNotesHistoryByClientId(noteObj).subscribe((historydata: any) => {
  //     // console.log(historydata)
  //     if(historydata.getData != null){
  //       this.notesHistory = historydata.getData.notesHistory;
  //       console.log("this.notesHistory ", this.notesHistory);
  //       this.tabName = this.notesHistory.map((item:any)=>{
  //         return Object.keys(item)[0]
  //       })
  //       this.activeTitle = this.tabName[0] ? this.tabName[0]: '';
  //       // console.log(this.notesHistory[0].broker)
  //     }

  //   })

  // }

  //* New  function **//
  // selectClientFile(){

  selectClientFile(e: any) {
    console.log(this.notesForm.value)
    console.log(e)


    let noteObj = {

      "clientId": parseInt(e),
      "sendUserId": this.currentuseruniqueId
    }

    this.getuserypeName(parseInt(e))

    this.managerService.getAllnotificationByUserId(noteObj).subscribe((historydata: any) => {
       // console.log(historydata)
      if (historydata.listData != null) {
        this.notesHistory = historydata.listData;
        console.log("this.notesHistory ", this.notesHistory);


      };
      this.countslist = [];
      if (this.notesHistory) {
        this.notesHistory.forEach((element: any) => {
          this.countslist.push(element.notesId)
        });
      };
      var json: any = []

      this.countslist.forEach((element: any) => {
        json.push(
          { "notesId": element }
        )
      });
      console.log("Json", json)

      this.notesService.UpdateAsReadNotes(json).subscribe(res => {
        this.getSelectfile();
          this.getAllNotes();
        console.log(res);
      })


    })
  }

  // }


  getShortName(name: string) {
    return name.charAt(0)
  }

check(val:any){

  var classList='';
this.notesList.filter((va:any)=>{
  if(val ==va.clientId){
    classList = 'message';
    // this.dropdownSettings.classes="message" 
 }
});

  return classList;

//   var selectedRows=[]
//  selectedRows= this.selectFileData.filter((e:any)=>{
//  return e.clientId==val;
// })

// selectedRows.length>0?true:false;
}


}
