import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { DropdownService } from 'src/app/service/dropdown.service';
import { FileuploadService } from 'src/app/service/fileupload.service';

@Component({
  selector: 'app-assitnegotiators-list',
  templateUrl: './assitnegotiators-list.component.html',
  styleUrls: ['./assitnegotiators-list.component.css']
})
export class AssitnegotiatorsListComponent implements OnInit {



  order: string = 'managersFirstname';
  p: number = 1;
  reverse: boolean = false;
  asstnegotiatorList: any = [];
  deleteData: any;
  userListOptions: any;
  statusListOptions: any;
  filterRequest: any = {};
  constructor(private managerService: ManagerService, private dropDownService: DropdownService, private fileupload: FileuploadService) { }


  ngOnInit(): void {
    this._getasstNegotiator_records();
  }



  _getasstNegotiator_records() {
    this.managerService._get_All_asstNegotiator().subscribe((data: any) => {
      console.log(data)
      this.asstnegotiatorList = data.listData;
      // console.log(data)
    })
  }
  getFilterData() {
    console.log(this.filterRequest)
    this.filterRequest.status = parseInt(this.filterRequest.status);
    this.dropDownService._getAll_AsstNegotiators_FilterData(this.filterRequest).subscribe((filteredData: any) => {
      this.asstnegotiatorList = filteredData.listData;
    });
  }

  //**Get Details for Filter */
  getfilter_details() {
    this.dropDownService._getAll_AsstNegotiators_UserList().subscribe((userList: any) => {
      console.log(userList.listData, "ji");
      this.userListOptions = userList.listData;
    })
    this.dropDownService._getAll_AsstNegotiators_StatusList().subscribe((statusList: any) => {
      console.log(statusList.listData, "sji")
      this.statusListOptions = statusList.listData;
    })
  }
  //**End */

  setId(data: any) {
    this.deleteData = data;
  }
  _delete_asstnegotiator() {

    this.managerService._delete_asstNegotiator(this.deleteData).subscribe(deleteData => {
      console.log(deleteData)
      this._getasstNegotiator_records()
    })


  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }


  downloadExcel() {
    this.fileupload.download_assnegotiator();
  }


}
