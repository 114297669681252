<section class="tabs-container apxtabs_container"
  [ngClass]="usertype == 'Agent' || usertype == 'Broker' ? 'Agent_module-Activated':''">
  <span *ngIf="usertype != 'Agent' && usertype != 'Broker'" class="change-btn prev" (click)="scrollTab(-1)"
    [class.hide]="atStart">
    <i class="fas fa-chevron-left"></i></span>

  <ul class="tabs apx-tabsparallel" id="agent_module" [ngStyle]="{'transform': abc }">

    <ng-container *ngFor="let tab of tabs; let i=index">
      <li class="tab" [class.active]="selectedIndex===i" (click)="selectTab(i)" [ngClass]="tab === 'client & property' ? 'clientsProperty': 'clientsDefault'">{{tab}}
      </li>
    </ng-container>
  </ul>
  <span *ngIf="usertype != 'Agent' && usertype != 'Broker'" class="change-btn next" [class.hide]="atEnd"
    (click)="scrollTab(1)"><i class="fas fa-chevron-right"></i></span>
</section>
