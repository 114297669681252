<div class="page-wrapper chiller-theme">
  <a id="show-sidebar" class="btn btn-sm btn-dark">
    <i class="fas fa-bars"></i>
  </a>
  <app-sidebar></app-sidebar>
  <!-- sidebar-wrapper  -->
  <main class="page-content">
    <app-header headerMainTitle="Manage Users"></app-header>
    <div class="container-fluid contentpage_wrapper">
      <div class="apexpage-titletop">
        <div class="apxpage-title_wrap">
          <h2>add / edit closer</h2>
          <button class="btn_to_btitle"><a routerLink="/superadmin/closer-list">back</a></button>
        </div>
      </div>

      <form (ngSubmit)=addEdit.form.valid&&_create_closers() #addEdit="ngForm" novalidate>
        <div class="pagecontent_wrapper">
          <div class="apex-white_wrap newheight-apx">
            <div class="apex-title-lilblue">
              <h3>basic details</h3>
            </div>

            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>First Name</label>
                    <input type="text" [(ngModel)]="addCloser.firstName" #firstName="ngModel" placeholder="First Name"
                      value="" name="First Name" [ngClass]="{'is-invalid': addEdit.submitted && firstName.invalid}"
                      required />
                    <div *ngIf="addEdit.submitted && firstName.invalid" class="invalid-feedback">
                      <div *ngIf="firstName.errors?.required">Firstname is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Last Name</label>
                    <input type="text" [(ngModel)]="addCloser.lastName" #lastName="ngModel" placeholder="Last Name"
                      value="" name="Last Name" [ngClass]="{'is-invalid': addEdit.submitted && lastName.invalid}"
                      required />
                    <div *ngIf="addEdit.submitted && lastName.invalid" class="invalid-feedback">
                      <div *ngIf="lastName.errors?.required">Lastname is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Email Address</label>
                    <input type="email" name="emailId" [(ngModel)]="addCloser.emailId" #emailId="ngModel"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Email Address" value=""
                      [ngClass]="{'is-invalid': addEdit.submitted && emailId.invalid}" required />
                    <div *ngIf="addEdit.submitted && emailId.invalid" class="invalid-feedback">
                      <div *ngIf=" emailId.errors?.required">Email is required</div>
                      <div *ngIf="emailId.errors?.pattern">Email must be a valid email address</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Phone Number</label>
                    <input type="text" [(ngModel)]="addCloser.phone" #phone="ngModel" placeholder="Phone Number"
                      value="" minlength="10" maxlength="15" name="Phone Number"
                      [ngClass]="{'is-invalid': addEdit.submitted && phone.invalid}" required />
                    <div *ngIf="addEdit.submitted && phone.invalid" class="invalid-feedback">
                      <div *ngIf="phone.errors?.required">Phone Number is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apexforms-group">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Title</label>
                    <select [(ngModel)]="addCloser.tittle" name="tittle" #tittle="ngModel"
                      [ngClass]="{'is-invalid': addEdit.submitted && tittle.invalid}" required>
                      <!-- <option disabled selected>Selected</option> -->
                      <option *ngFor="let item of titleDetail">{{item}}</option>
                    </select>
                    <div *ngIf="addEdit.submitted && tittle.invalid" class="invalid-feedback">
                      <div *ngIf="tittle.errors?.required">Title is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="apexinput-froms">
                    <label>Status</label>
                    <select [(ngModel)]="addCloser.active" name="active" [(ngModel)]="addCloser.active"
                      #active="ngModel" [ngClass]="{'is-invalid': addEdit.submitted && active.invalid}" required>
                      <option [value]=true selected>Active</option>
                      <option [value]=false>InActive</option>
                    </select>
                    <div *ngIf="addEdit.submitted && active.invalid" class="invalid-feedback">
                      <div *ngIf="active.errors?.required">Status is required</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="apxbtn_finalcenter apx-mt-15">
              <button type="reset" class="btn_grey">Cancel</button>
              <button class="btn_dblue">save</button>
            </div>
          </div>
        </div>
      </form>
    </div>

  </main>
</div>
