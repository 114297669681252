import { email, unique, alpha, compare, prop, propArray, propObject, required } from "@rxweb/reactive-form-validators"





export class docuDocument {
  @prop()
  docUploadDetailsId: number = 0;
  @prop()
  fileName?: string;
  @prop()
  actualFileName?: string;
  @prop()
  fileUrl?: string;
  @prop()
  renderUrl?: string;
}

export class documentTabModel {

  @propArray(docuDocument)
  docDetails?: docuDocument[];


  @prop()
  documentUploadId: number = 0;
  @prop()
  clientId?: number;

  @prop() @required()
  docStatusId: number;
  requiredBy?: string = '';
  @prop()
  isReuired?: boolean = true;
  @prop()
  notes?: "string";

  @prop()
  createdBy: string;
  @prop() @required()
  docTypeNames?: string = '';


}
