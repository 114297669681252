import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/service/manager.service';
import { TokenService } from '../../service/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FileuploadService } from '../../service/fileupload.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.css'],
  providers: [DatePipe]
})
export class LoginHistoryComponent implements OnInit {
  //searchbyuser Begins
  public SearchList: any;
  //searchbyuser Begins
  usertypeid: any;

  //usertypeid: any;
  public summaries: any = [];
  public leadData: any = [];
  order: string = 'leadowner';
  p: number = 1;
  reverse: boolean = false;
  checkedList: any;
  myDate: any = new Date();

  datePickerConfig = {
    format: 'MM/DD/YYYY', disableKeypress: true
  };
  filterObj: any;
  constructor(private datePipe: DatePipe, private _file_upload: FileuploadService, private _manager_service: ManagerService, private tokenStorage: TokenService, private route: Router, private activated_route: ActivatedRoute) {
    this.myDate = this.datePipe.transform(this.myDate, 'MM/dd/yyyy');
    //console.log(this.myDate, "jeevitha")


  }
  ngOnInit() {
    this.filterObj = {
      "usertypeId": 1,
      "days": 0,
      "fromdate": "",
      "todate": ""
    };

    this.filterObj.usertypeId = 0;
    console.log(this.filterObj.usertypeId, "valuees");
    this.filterObj.days = 1;
    console.log(this.filterObj.days, "valuee");
    this.myDate = this.datePipe.transform(this.myDate, 'MM/dd/yyyy');
    this.usertypeid = this.tokenStorage._get_userTypeId();
    // this.getallUserLonginHistoryAuditList(this.usertypeid);
    this.getuserdropdownlist();
    this.getallUserLonginHistoryAuditList();

    // this.leadData = [
    //   { "usertypeId": '1', "leadowner": 'Agent', "leadunits": 'In LOA detail updated the status as contacted', "leademail": 'George Bailey', "date": '03-09-2020' },
    //   { "usertypeId": '2', "leadowner": 'Agent', "leadunits": 'In LOA detail updated the status as contacted', "leademail": 'George Bailey', "date": '03-09-2020' },
    //   { "usertypeId": '3', "leadowner": 'Agent', "leadunits": 'In LOA detail updated the status as contacted', "leademail": 'George Bailey', "date": '03-09-2020' },
    //   { "usertypeId": '4', "leadowner": 'Agent', "leadunits": 'In LOA detail updated the status as contacted', "leademail": 'George Bailey', "date": '03-09-2020' },
    //   { "usertypeId": '5', "leadowner": 'Agent', "leadunits": 'In LOA detail updated the status as contacted', "leademail": 'George Bailey', "date": '03-09-2020' },
    //   { "usertypeId": '6', "leadowner": 'Agent', "leadunits": 'In LOA detail updated the status as contacted', "leademail": 'George Bailey', "date": '03-09-2020' },
    //   { "usertypeId": '7', "leadowner": 'Agent', "leadunits": 'In LOA detail updated the status as contacted', "leademail": 'George Bailey', "date": '03-09-2020' },
    //   { "usertypeId": '8', "leadowner": 'Agent', "leadunits": 'In LOA detail updated the status as contacted', "leademail": 'George Bailey', "date": '03-09-2020' }
    // ];
  }

  //obj



  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }



  filterForeCasts(filterVal: any) {
    // if (filterVal == "0")
    //   this.usertypeid = this.cacheForecasts;
    // else
    //   this.leadData = this.cacheForecasts.filter((item) => item.summary == filterVal);
    console.log(this.usertypeid, "this.usertypeid")
    this.usertypeid = parseInt(filterVal.target.value);

    console.log(parseInt(filterVal), "filter");
    this.getallUserLonginHistoryAuditList()

  }

  getuserdropdownlist() {
    let CurrentUserId: any;
    CurrentUserId = this.tokenStorage._get_userTypeId();
    console.log(CurrentUserId, "hi cuser ")
    this._manager_service.get_userdropdown_list(CurrentUserId).subscribe((res: any) => {
      this.summaries = res.listData;
    })
  }

  onReset() {
    this.filterObj = {
      "usertypeId": 0,
      "days": 0,
      "fromdate": "",
      "todate": ""
    }
    this.getallUserLonginHistoryAuditList();
  }

  getallUserLonginHistoryAuditList() {
    if (this.filterObj['days'] != 5) {
      this.filterObj['fromdate'] = "";
      this.filterObj['todate'] = "";
    }
    this.filterObj['usertypeId'] = parseInt(this.filterObj['usertypeId']);
    this._manager_service.get_allUserLonginHistoryAudit(this.filterObj).subscribe((res: any) => {
      console.log(res, "test");
      //this.leadData = res.listData;

      if (res.listData == null) {
        this.leadData = [];
        console.log('hi')
      }
      else {
        this.leadData = res.listData;
        console.log('bye')
      }
      console.log(this.leadData, "new")
    })

  }

  loginHistory(userid: number, date: any) {
    console.log(userid);
    this.route.navigate(['/superadmin/login-history-detail/' + userid + '/' + date])
    //this.route.navigate(['/superadmin/login-history-detail/' + userid])
  }

  // loginHistory() {
  //   let UserTypeId: any;
  //   UserTypeId = this.tokenStorage._get_userTypeId();
  //   console.log(UserTypeId, "loginhistory")
  //   this.route.navigate(['/superadmin/login-history-detail/' + UserTypeId])
  // }

  downloadExcelHistory() {
    this._file_upload.download_history({ usertypeId: parseInt(this.filterObj.usertypeId) });
  }

  searchLoginHistory(searchkey: any) {
    this._manager_service.searchByall(searchkey, this.usertypeid).subscribe((searchedData: any) => {
      if (searchedData.listData == null) {
        this.leadData = [];
      }
      else {
        this.leadData = searchedData.listData;
      }
      console.log(searchedData, "latest data")

    })
  }


}
